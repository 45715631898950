<modal-header [title]="headerTemplate" [activeModal]="activeModal">
    <ng-template #headerTemplate>
      <h4 class="modal-title">Reactions</h4>
    </ng-template>
  </modal-header>
  <modal-body>
    <div class="reaction-container" *ngIf="reactions?.length > 0">
      <div class="reaction-item" *ngFor="let reaction of reactions">
        <div class="user-information">
          <app-avatar [size]="20" [avatarSource]="reaction?.avatarUrl" [avatarText]="reaction?.fullName">
          </app-avatar>
          <span class="name">{{reaction?.fullName}}</span>
        </div>
        <div class="position">{{reaction?.positionName}}</div>
      </div>
    </div>

    <div class="reaction-container" *ngIf="reactionComments?.length > 0">
      <div class="reaction-item" *ngFor="let reactionComment of reactionComments">
        <div class="user-information">
          <app-avatar [size]="20" [avatarSource]="reactionComment?.avatarUrl" [avatarText]="reactionComment?.fullName">
          </app-avatar>
          <span class="name">{{reactionComment?.fullName}}</span>
        </div>
        <div class="position">{{reactionComment?.positionName}}</div>
      </div>
    </div>
  </modal-body>