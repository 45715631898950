import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Employee, EmployeeService, ResizeFile, Department, DepartmentService, MetaDataPaging, Skill, SkillService, AvailableEmployee, Position } from '@intranet/lib/data-access';
import { NgForm } from '@angular/forms';
import { Toastr, Loading, GeneratePassword, Constants } from '@intranet/index';
import { MasterDataService } from '@intranet/lib/data-access/services/master-data.service';
import { PositionService } from '@intranet/lib/data-access/services/position.service';
import { GroupUserService } from '@intranet/lib/data-access/services/group-user.service';
import { NzTreeSelectComponent } from 'ng-zorro-antd/tree-select';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { EmployeeConstants } from '@intranet/lib/config/employee.constants';
import { HeadcountTypeEnum } from '@intranet/lib/data-access/models/enums/headcount-type.enum';
@Component({
  selector: 'app-new-employee-modal',
  templateUrl: './new-employee-modal.component.html',
  styleUrls: ['./new-employee-modal.component.scss'],
})
export class NewEmployeeModalComponent implements OnInit {
  @Input() employeeId: string;
  @Input() availableEmployee: AvailableEmployee;
  @ViewChild('frm', { static: false }) public formGroup: NgForm;
  @ViewChild('nzTreeSelect', { static: false }) nzTreeComponent!: NzTreeSelectComponent;
  meteritalStatus = Constants.MARITAL_STATUS;

  images: ResizeFile = new ResizeFile();
  activeModal: NgbActiveModal;
  newEmployee: Employee = new Employee();
  isLoading = false;
  genders: any;
  positions: Position[];
  groups: Department[] = [];
  departmentSelected: Department[] = [];
  skills: Skill[] = [];
  empSkillIds: string[] = [];
  hasAccount: boolean;

  // for creating user tab
  groupUsers = [];
  newUser = {
    fullName: '',
    userName: '',
    passWord: '',
    isActive: true,
    isSendMail: true,
    groupUserId: null,
  };
  passwordConfirmation = '';
  cropOptions = {
    hideResizeSquares: true,
    aspectRatio: 1 / 1,
    cropperStaticHeight: 170,
    cropperStaticWidth: 170,
    roundCropper: true,
  };
  headcountTypeEnum = HeadcountTypeEnum;

  replacementEmplpyees: any;
  headcountTypes = EmployeeConstants.HeadcountType.Array;

  constructor(
    modalRef: NgbActiveModal,
    private employeeSvc: EmployeeService,
    private masterDataService: MasterDataService,
    private positionSvc: PositionService,
    private depSvc: DepartmentService,
    private skillSvc: SkillService,
    private generatePassword: GeneratePassword,
    private groupUserService: GroupUserService,
  ) {
    this.activeModal = modalRef;
  }

  ngOnInit() {
    this.initData();
    if (this.employeeId) {
      this.getEmployeeById();
    } else if (this.availableEmployee) {
      this.mergeAvailableEmployee();
    }
  }


  // onChangeHeadcountType() {
  //   this.newEmployee.headcountType = event;
  // }

  // onChangeReplacementFor() {
  //   this.newEmployee.replacementEmployeeId = event;
  // }


  getEmployeeById() {
    this.isLoading = true;
    Loading.show();

    this.employeeSvc.getEmployeeById(this.employeeId)
      .pipe(
        finalize(() => {
          Loading.hide();
          this.isLoading = false;
        })
      )
      .subscribe((emp: Employee) => {
        this.newEmployee = new Employee(emp);
        this.empSkillIds = JSON.parse(this.newEmployee.skills).map(x => x.id);
      },
        (err) => {
          ErrorHelper.displayMessage(err);
        });
  }

  deptChange() {
    const nodes = this.nzTreeComponent.getSelectedNodeList();
    if (nodes && nodes.length) {
      (this.newEmployee.deparments as any) = nodes.map((item: NzTreeNode) => {
        return { id: item.origin.id, name: item.origin.title };
      });
    }
  }

  onSubmit() {
    this.newEmployee.avatarUrl = this.images.base64;
    this.newEmployee.account.fullName = this.newEmployee.lastName + ' ' + this.newEmployee.firstName;
    if (this.empSkillIds) {
      // get selected skill objects
      const selectedSkills = [];
      this.empSkillIds.forEach(x => {
        selectedSkills.push(this.skills.find(skill => skill.id === x));
      });
      this.newEmployee.skills = JSON.stringify(selectedSkills);
    }
    if (this.employeeId) {
      this.updateEmployee();
    } else {
      this.addNewEmployee();
    }
  }

  closeModal() {
    this.activeModal.close(null);
  }

  generatePw() {
    const pw = this.generatePassword.generatePw({
      length: 10,
      numbers: true,
    });
    this.newUser.passWord = pw;
    this.passwordConfirmation = pw;
  }

  //#region private methods

  private addNewEmployee() {
    if (this.formGroup.invalid) {
      return;
    }
    this.isLoading = true;
    Loading.show();
    this.employeeSvc.add(
      this.newEmployee,
      () => {
        this.activeModal.close(this.newEmployee);
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }

  private updateEmployee() {
    if (this.formGroup.invalid) {
      return;
    }
    if (!this.hasAccount) {
      this.newEmployee.account = null;
    }
    this.isLoading = true;
    Loading.show();
    this.employeeSvc.update(
      this.newEmployee,
      () => {
        this.activeModal.close(this.newEmployee);
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }

  activeChange(state: any) {
    this.hasAccount = !this.hasAccount;
  }

  private initData() {
    // get genders
    this.masterDataService.getGender().then(data => {
      this.genders = data;
    });
    this.masterDataService.getFullEmployees().then(data => {
      this.replacementEmplpyees = data;
    });
    const paging = new MetaDataPaging({ pageNumber: 1 });
    // get positions
    this.positionSvc.getAll(paging, (resp: Position[]) => {
      this.positions = resp;
    });
    // get skills
    this.skillSvc.getAll(paging, (resp: Skill[]) => {
      if (resp) {
        this.skills = resp.map(x => {
          return { id: x.id, name: x.name } as Skill; // remove unnecessary fields
        });
      }
    });
    // get groups (departments)
    this.depSvc.getTree({}, (resp: Department[]) => {
      this.groups = resp;
      Loading.hide();
    });
    // get user groupss
    this.groupUserService.getDropdown().then(res => {
      this.groupUsers = res;
    });

    if (this.newEmployee.maritalStatus === '1') {
      this.newEmployee.isMaritalStatus = true;
    } else {
      this.newEmployee.isMaritalStatus = false;
    }
  }

  mergeAvailableEmployee() {
    if (!this.availableEmployee || !Object.entries(this.availableEmployee).length) {
      return;
    }
    this.newEmployee = new Employee({ ...this.newEmployee, ...this.availableEmployee });
  }
  //#endregion private methods

  maritalStatusChange(event) {
    this.newEmployee.maritalStatus = event ? '1' : '2';
  }

  onDateOfBirthChange(event: Date) {
    const d = moment(event);
    const dateString = d.format('YYYY-MM-DD').toString();
    const date = new Date(dateString);
    this.newEmployee.dateOfBirth = date;
  }
  onJoinDateChange(event: Date) {
    const d = moment(event);
    const dateString = d.format('YYYY-MM-DD').toString();
    const date = new Date(dateString);
    this.newEmployee.joinedDate = date;
  }
  onIssuedDateChange(event: Date) {
    const d = moment(event);
    const dateString = d.format('YYYY-MM-DD').toString();
    const date = new Date(dateString);
    this.newEmployee.issuedDate = date;
  }
}
