import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-poster-display-modal',
  templateUrl: './poster-display-modal.component.html',
  styleUrls: ['./poster-display-modal.component.scss']
})
export class PosterDisplayModalComponent implements OnInit {
  @Input() posterInfo: any = null;

  @Output() clickLink: EventEmitter<void> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();

  isClickOutside: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  clickClose() {
    this.close.emit();
  }

  clickImg() {
    this.clickLink.emit();

    if (!this.posterInfo?.link) {
      return;
    }

    window.open(this.posterInfo.link, this.posterInfo.openTarget);
  }

  clickOutside() {
    this.isClickOutside = true;

    setTimeout(() => this.isClickOutside = false, 200);
  }
}
