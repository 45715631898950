<ng-container *ngIf="listMonthly">
  <nz-collapse [nzAccordion]="false">
    <nz-collapse-panel *ngFor="let dataMonthly of listMonthly" [nzHeader]="headerMonthlyPanel" [nzActive]="dataMonthly.active">
      <ng-template #headerMonthlyPanel>
        <ng-container *ngIf="dataMonthly">
          <nz-descriptions [nzTitle]="'Month ' + (dataMonthly.startDate | date : 'MM/yyyy')" [nzColumn]="4" nzBordered="false" nzLayout="vertical" class="lg-screen">
            <nz-descriptions-item nzTitle="Date">
              {{dataMonthly.startDate | date : 'dd/MM/yyyy'}} - {{dataMonthly.endDate | date : 'dd/MM/yyyy'}}
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Accumulated hours">{{dataMonthly.accumulatedHours}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Standard hours">{{dataMonthly.totalAllHoursWorking}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Difference (+/-)">
              <span class="text-danger" *ngIf="dataMonthly.totalRemainHoursWorking < 0">{{dataMonthly.totalRemainHoursWorkingData}}</span>
              <span class="text-success" *ngIf="dataMonthly.totalRemainHoursWorking >= 0"><i nz-icon nzType="check-circle" nzTheme="outline"></i></span>
              <span class="ml-1" *ngIf="dataMonthly.totalMissingHoursWorking > 0" nz-popover nzPopoverTrigger="hover" [nzPopoverContent]="explainMissingHoursFieldTemplate" >
          <i nz-icon nzType="info-circle" nzTheme="outline"></i>
          <ng-template #explainMissingHoursFieldTemplate>
             {{dataMonthly.totalRemainHoursWorking}} ({{dataMonthly.totalRemainHoursWorkingData}} + {{dataMonthly.totalMissingHoursWorking}})
          </ng-template>
          </span>
            </nz-descriptions-item>
          </nz-descriptions>

          <div class="sm-screen sm-header-information">
            <div>
              <span>Month:</span>
              <span>{{dataMonthly.startDate | date : 'MM/yyyy'}}</span>
            </div>
            <div>
              <span>Accumulated:</span>
              <span>{{dataMonthly.accumulatedHours}}</span>
            </div>
            <div>
              <span>Standard:</span>
              <span>{{dataMonthly.totalAllHoursWorking}}</span>
            </div>
            <div>
              <span>Difference (+/-):</span>
              <span>
            <span class="text-danger" *ngIf="dataMonthly.totalRemainHoursWorking < 0">{{dataMonthly.totalRemainHoursWorkingData}}</span>
              <span class="text-success" *ngIf="dataMonthly.totalRemainHoursWorking >= 0"><i nz-icon nzType="check-circle" nzTheme="outline"></i></span>
              <span class="ml-1" *ngIf="dataMonthly.totalMissingHoursWorking > 0" nz-popover nzPopoverTrigger="hover" [nzPopoverContent]="explainMissingHoursFieldTemplate" >
            <i nz-icon nzType="info-circle" nzTheme="outline"></i>
            <ng-template #explainMissingHoursFieldTemplate>
              {{dataMonthly.totalRemainHoursWorking}} ({{dataMonthly.totalRemainHoursWorkingData}} + {{dataMonthly.totalMissingHoursWorking}})
            </ng-template>
            </span>
              </span>
            </div>
          </div>
        </ng-container>
      </ng-template>
      <app-widget-tracking-weekly [(listWeekly)]="dataMonthly.listDetails"></app-widget-tracking-weekly>
    </nz-collapse-panel>
  </nz-collapse>
</ng-container>
