import { BaseModel } from './base-model';
import { PurchaseOrderDetail } from './purchase-order-detail';

export class PurchaseRequest extends BaseModel {
  title: string;
  ownerId: string;
  poId: string;
  departmentId: string;
  equipmentCategoryId?: string;
  equipmentCategoryName?: string;
  equipmentId?: string;
  equipmentName?: string;
  purchaseRequestId: string;
  purchasePoStatus: number;
  deliveryStatus: number;
  totalAmount: number;
  groupName: string;
  groupDepartments: string;
  purchaseOrderNo: string;
  purchaseRequestNo: string;
  details: PurchaseOrderDetail[];
  cancelledBy: string;
  cancelledByName: string;
  approvalBy: string;
  approvalByName: string;
  approvalDate: Date;
  cancelledDate: Date;
  expaned: boolean;
  receiptNumber: string;
  receiptAmount: string;
  reason: string;
  receiptDate: Date;
  description?: string;
  hasMissing?: boolean;
  showRejectBtn?: boolean;
  detailsDisplay: PurchaseOrderDetail[];
  constructor() {
    super();
    this.details = [];
  }
}
