<div class="rt-panel-item announcement-container">
  <div class="d-flex align-items-center title-panel">
    <div class="flex-column mr-auto">
      <p>Annoucements</p>
    </div>
    <div class="flex-column">
      <div class="input-group rt-search">
        <div class="input-group-prepend">
          <span class="input-group-text" id="rt-search-announcement"><img src="assets/images/front/shortcut/search.svg"
              alt="restaff" /></span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="queryFeeds.keyWord" placeholder="Search"
          aria-label="Default" aria-describedby="rt-search-announcement" (keyup.enter)="getAnnoucements()">
      </div>
    </div>
    <div class="flex-column">
      <div class="dropdown show rt-header-option">
        <a class="btn btn-link dropdown-toggle rt-sort" href="#" role="button" id="rt-sort-announcement"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="user-logo" src="assets/images/front/shortcut/sort.svg" alt="restaff" /> Sort: Due day
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="rt-sort-announcement">
          <a class="dropdown-item" href="#">None</a>
          <a class="dropdown-item checked" href="#">Due day <img src="assets/images/front/shortcut/checked.svg" /></a>
        </div>
      </div>
    </div>
  </div>
  <div class="rt-panel-item-content">
    <button *hasPermission="['wallnewsfeed','createannouncement']" type="button"
      class="btn btn-primary btn-new-announcement" data-toggle="modal" data-target="#create_announcement"
      (click)="createAnnouncement()">
      <img src="assets/images/front/shortcut/add.svg" alt="add announcement" />
      New Announcement
    </button>
    <div class="mt-2" *ngIf="!isLoading && (announcements && announcements.length > 0)">
      <div class="row align-items-center" *ngFor="let feed of announcements">
        <div class="col annoucement-item">
          <div class="recent-notification announcements" [ngClass]="feed.isImportant ? 'active': ''">
            <div class="img-notify"
              [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.ANNOUNCEMENT_DETAILS), feed.id]">
              <img class="user-photo" src="assets/images/front/shortcut/annoucements-active.svg" alt="announcements" />
            </div>
            <div [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.ANNOUNCEMENT_DETAILS), feed.id]">
              <p>
                {{feed.title}}</p>
              <div class="time-post">
                <div>
                  <img src="assets/images/front/shortcut/post-icon.svg" /><span>Posted:
                    {{ feed.createdDate | amFromUtc | amDateFormat: 'DD MMM YYYY' }}</span>
                </div>
                <div *ngIf="feed.endDate">
                  <img src="assets/images/front/shortcut/post-icon.svg" /><span>Due day:
                    {{ feed.endDate | amDateFormat: 'DD MMM YYYY' }}</span>
                </div>
              </div>
            </div>
            <div class="dropdown show rt-notification-option"
              *hasOneOfPermissions="['wallnewsfeed',['updateannouncement', 'deleteannouncement']]">
              <a class="rt-account" href="#" role="button" id="rt-account-notification-{{feed.id}}"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span></span>
              </a>
              <div class="dropdown-menu dropdown-menu-right"
                [attr.aria-labelledby]="'rt-account-notification-' + feed.id">
                <a *hasPermission="['wallnewsfeed','updateannouncement']" class="dropdown-item"
                  (click)="editAnnouncement(feed)"><img src="assets/images/front/shortcut/mark.svg" /> Edit
                  announcement</a>
                <a *hasPermission="['wallnewsfeed','deleteannouncement']" class="dropdown-item"
                  (click)="deleteAnnouncement(feed)"><img src="assets/images/front/shortcut/delete.svg" /> Delete this
                  announcement</a>
              </div>
            </div>
            <div class="user-create">
              <app-avatar *ngIf="feed && feed.user" [avatarSource]="feed.user.avatarUrl"
                [avatarText]="feed.user.fullName" [size]="24"></app-avatar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p *ngIf="!isLoading && (!announcements || announcements.length <= 0)">
      <span>There are no announcement at this time.</span>
    </p>
    <div class="p-2" *ngIf="isLoading">
      <nz-skeleton [nzActive]="true">
        <ul class="p-b-none">
          <li class="announcement-item">
            <div>Title</div>
            <p>Short Description</p>
          </li>
        </ul>
      </nz-skeleton>
    </div>
  </div>
  <nz-pagination *ngIf="!isLoading" [nzPageIndex]="queryFeeds.pageNumber" [nzTotal]="queryFeeds.totalItems"
    [nzPageSize]="queryFeeds.pageSize" (nzPageIndexChange)="onPageChange($event)"></nz-pagination>
</div>