<div class="rt-panel-item event-calendar-component">
  <div class="row align-items-center title-panel" *ngIf="displayType === displayTypeEnum.DASHBOARD">
    <div class="col-8">
      <p>Events & Calendar</p>
    </div>
    <div class="col-4 text-right">
      <a href="" class="view-all" (click)="clickOnViewAll()">View all</a>
    </div>
  </div>

  <div class="event-calendar-container" [ngClass]="displayType === displayTypeEnum.DASHBOARD ? 'event-calendar-dashboard' : ''">
    <div class="calendar">
      <app-shared-calendar [events]="events" (dayClickedEvent)="dayClicked($event)" (viewChangedEvent)="onViewDateChange($event)">
      </app-shared-calendar>
      <div class="event-types">
        <div>
          <div class="circle event-type-holiday"></div>
          Holiday
        </div>
        <div>
          <div class="circle event-type-party"></div>
          Party
        </div>
        <div>
          <div class="circle event-type-company-sponsor"></div>
          Sponsor
        </div>
      </div>
    </div>

    <div class="information">
      <div>
        <ul class="nav nav-tabs" id="booking-leave" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link"
              id="leave-tab"
              data-toggle="tab"
              href="#leave"
              role="tab"
              aria-controls="leave"
              [ngClass]="{ active: currentSelectedTab === LeaveTabIndex }"
              aria-selected="true"
              (click)="tabIndexChanges(LeaveTabIndex)"
            >
              Leave ({{ currentLeaves?.length }})
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="booking-tab"
              data-toggle="tab"
              href="#booking"
              role="tab"
              aria-controls="booking"
              [ngClass]="{ active: currentSelectedTab === MeetingTabIndex }"
              aria-selected="false"
              (click)="tabIndexChanges(MeetingTabIndex)"
            >
              Meeting ({{ currentMeetings?.length }})
            </a>
          </li>
        </ul>

        <div class="tab-content" id="booking-leave-content">
          <div
            *ngIf="currentSelectedTab === LeaveTabIndex"
            class="tab-pane fade active show"
            id="leave"
            role="tabpanel"
            aria-labelledby="leave-tab"
          >
            <app-display-member-template
              [memberData]="memberData"
              [template]="leaveType"
              [isNewsFeedPage]="true"
              additionalTemplateClass="text-right"
              maxHeight="250px"
            ></app-display-member-template>
            <ng-template #leaveType let-event="member.meta">
              <span class="event-tag">{{ event?.meta?.leaveType?.halfDay }}</span>
            </ng-template>
          </div>

          <div
            class="tab-pane fade"
            id="booking"
            role="tabpanel"
            aria-labelledby="booking-tab"
            class="active show"
            *ngIf="currentSelectedTab === MeetingTabIndex"
          >
            <ul class="list-data scroll-bar scroll-bar-widget" *ngIf="currentMeetings?.length > 0; else empty">
              <li class="row align-items-center event-item" *ngFor="let event of currentMeetings">
                <div class="col">
                  <label class="employee-name" *ngIf="!event.sameDay">
                    [{{ event?.meta?.departmentGroup?.name }}] {{ event.title }}:
                    {{ event.start | amDateFormat : 'DD/MM/YYYY HH:mm' }}
                    to
                    {{ event.end | amDateFormat : 'DD/MM/YYYY HH:mm' }} at
                    <span [ngStyle]="{ color: event.meta?.room?.colorInCalendar || '#000' }">{{ event.meta?.room?.name }}</span>
                  </label>
                  <label class="employee-name" *ngIf="event.sameDay"
                    >[{{ event?.meta?.departmentGroup?.name }}] {{ event.title }}: {{ event.start | amDateFormat : 'HH:mm' }} to
                    {{ event.end | amDateFormat : 'HH:mm' }}
                    at
                    <span [ngStyle]="{ color: event.meta?.room?.colorInCalendar || '#000' }">
                      {{ event?.meta?.room?.name }}
                    </span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div *ngIf="isLoading" class="widget-body-container p-2">
          <nz-skeleton [nzActive]="true"> </nz-skeleton>
        </div>

        <div class="rt-option-group action-group" *ngIf="currentUser.hasEmployee">
          <div class="left">
            <button
              type="button"
              class="btn btn-primary btn-block btn-add-leave"
              data-toggle="modal"
              data-target="#leave_option"
              (click)="createLeave()"
            >
              <img class="user-photo" src="assets/images/front/shortcut/add.svg" alt="announcements" />
              Add Leave
            </button>
          </div>
          <div class="right text" *ngIf="currentUser.hasEmployee">
            <button
              type="button"
              class="btn btn-primary btn-block btn-add-booking"
              (click)="createBooking()"
              [disabled]="viewDate < today"
            >
              <img class="user-photo" src="assets/images/front/shortcut/add-booking.svg" alt="announcements" />
              Book Meeting
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #empty>
  <nz-empty></nz-empty>
</ng-template>
