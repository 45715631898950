<modal-header [activeModal]="modal" title="Change password">
</modal-header>
<modal-body>
  <div class="container">
    <!-- <nz-alert *ngIf="isShow" nzType="warning" nzMessage="{{ 'page.common.warning-check-company' | translate }}"> </nz-alert> -->
    <div class="row justify-content-center">
      <form class="col-12" (ngkSubmit)="submit()" novalidate #frm="ngForm" [ngkForm]="true">
        <div class="form-group">
          <label>Name</label>
          <input disabled type="text" class="form-control" name="fullName" placeholder="Name" [ngModel]="user.fullName" />
        </div>

        <div class="form-group">
          <label>Old password</label>
          <ngk-password name="password" placeholder="page.common.password" required [(ngModel)]="user.oldPassword" minlength="6"></ngk-password>
        </div>
        <div class="form-group">
          <label>New Password</label>
          <ngk-password name="password" placeholder="New Password" required [(ngModel)]="user.passWord" minlength="6"></ngk-password>
        </div>

        <div class="form-group">
          <label>New Password Confirmation</label>
          <ngk-password name="passwordConfirm" placeholder="New Password Confirmation" required validateEqual="password" [(ngModel)]="passwordConfirmation" minlength="6">
          </ngk-password>
        </div>
        <div class="form-group text-right">
          <create-update-button [isCancel]="true" content="Submit" (cancel)="modal.close(false)"></create-update-button>
        </div>
      </form>
    </div>
  </div>
</modal-body>
