import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'ngk-password',
  template: `
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input [type]="type" autocomplete="new-password" [(ngModel)]="value" nz-input [placeholder]="placeholder | translate" />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon [nzType]="eyeType" nzTheme="outline" class="pointer" (click)="changeType()"></i>
    </ng-template>
  `,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: NgkPassworkComponent, multi: true }],
})
export class NgkPassworkComponent implements OnInit, ControlValueAccessor {
  constructor() { }
  @Input() placeholder: any;
  private onTouchedCallback: () => {};
  private onChangeCallback: (_: any) => {};
  innerValue: string;
  type = 'password';
  eyeType = 'eye-invisible';
  ngOnInit() { }

  changeType() {
    if (this.type === 'password') {
      this.type = 'text';
      this.eyeType = 'eye';
    } else {
      this.type = 'password';
      this.eyeType = 'eye-invisible';
    }
  }

  get value() {
    return this.innerValue;
  }

  set value(value: string) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.onChangeCallback(value);
      this.onTouchedCallback();
    }
  }

  writeValue(value: string): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
