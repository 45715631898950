import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'grid-button',
  templateUrl: './grid-button.component.html',
  styles: [],
})
export class GridButtonComponent implements OnInit {
  // @Input() data: any;
  constructor() { }
  classcss = ['no-button', 'pointer', 'fs16', 'mr5'];
  @Input() confirmTitle: string;
  @Input() tooltip: string;
  @Input() set className(value: string) {
    if (value) {
      const vals = value.split(' ');
      this.classcss = [...this.classcss, ...vals];
    }
  }
  @Output() okEvent = new EventEmitter();
  @Input() set customClass(value: string) {
    if (value) {
      const vals = value.split(' ');
      this.classcss = vals;
    }
  }

  ngOnInit() { }

  ok($event) {
    if ($event) {
      $event.stopPropagation();
    }
    this.okEvent.emit();
  }
}
