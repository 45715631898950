const ApiBase = {
  Widget: '/api/widget',
  Dashboard: '/api/dashboard',
  Application: '/api/applications',
  File: '/cdn/file',
  User: '/api/user',
  Employee: '/api/employee',
  Setting: {
    PdfTemplate: '/api/pdf-templates',
  },
  Review: {
    FormMember: '/api/review-form-members',
    FormLevel: '/api/review-form-level',
    ViewToken: '/api/review-view-tokens',
    RootSection: '/api/review-sections',
    StatusForm: '/api/review-status-forms',
  },
  ClientWidget: '/api/client-widgets',
  Benefit: {
    Management: '/api/benefits',
    Criteria: '/api/benefit-criteria',
    Bonus: '/api/benefit-bonus',
    MemberRegistration: '/api/benefit-requests',
    Category: '/api/benefit-categories',
    Result: '/api/benefit-results',
  },
  LeaveSetting: '/api/leave-settings',
  LeaveTransfer: '/api/leave-transfers',
  AnnualLeave: '/api/annual_leave',
  LeaveRequest: '/api/leave_request',
  DepartmentGroup: '/api/department_group',
  ChristmasGame: '/api/christmas-game',
  PurchaseRequest: '/api/purchase-request',
  CheckInCheckOut: {
    Management: '/api/checkinout-request',
    GetById: '/api/checkinout-request/get-by-id',
    Approve: '/api/checkinout-request/approve',
    Reject: '/api/checkinout-request/reject',
  },
  UserDepartment: '/api/userdepartment',
  EquipmentCategory: '/api/equipment_category',
  Warehouse: '/api/warehouse',
  Product: '/api/products',
};

export const ApiEndpoint = {
  Widget: {
    GetWidgetAttendance: () => `${ApiBase.Widget}/widget-attendance`,
    GetWidgetAsset: () => `${ApiBase.Widget}/widget-asset`,
    GetWidgetTodayLeave: () => `${ApiBase.Widget}/widget-today-leave`,
    GetWidgetAttendanceHour: () => `${ApiBase.Widget}/widget-attendance-hour`,
    GetWidgetTrackingTime: () => `${ApiBase.Widget}/widget-tracking-time`,
    GetWidgetLeave: () => `${ApiBase.Widget}/widget-leave`,
    GetWidgetCompany: () => `${ApiBase.Widget}/widget-company`,
    GetWidgetWorkExperience: () => `${ApiBase.Widget}/widget-work-experience`,
    GetWidgetEquipment: () => `${ApiBase.Widget}/widget-equipment`,
    GetHRInformation: () => `${ApiBase.Widget}/widget-hr-information`,
    GetWidgetEmployeeGender: () => `${ApiBase.Widget}/widget-employee-gender`,
    GetWidgetSkillTeam: () => `${ApiBase.Widget}/widget-skill-team`,
    GetWidgetMemberByTeam: () => `${ApiBase.Widget}/widget-member-by-skill`,
    GetWidgetMemberForLeave: () => `${ApiBase.Widget}/widget-member-for-leave`,
    GetWidgetTodo: () => `${ApiBase.Widget}/widget-todo`,
    GetWidgetSurvey: () => `${ApiBase.Widget}/widget-survey`,
    GetWidgetNewcomer: () => `${ApiBase.Widget}/widget-newcomer`,
    GetWidgetReviewForms: () => `${ApiBase.Widget}/review-performance-forms`,
    GetWidgetReviewGoal: () => `${ApiBase.Widget}/review-goals`,
    GetWidgetBenefits: () => `${ApiBase.Widget}/widget-benefits`,
    GetWidgetBenefitMember: (benefitId: string) => `${ApiBase.Widget}/widget-benefits/${benefitId}/members`,
  },
  Dashboard: {
    GetWidget: (id: string) => `${ApiBase.Dashboard}/${id}/widgets`,
  },
  Application: {
    GetInformation: () => `${ApiBase.Application}/informations`,
  },
  File: {
    GetFileById: (id: string) => `${ApiBase.File}/${id}`,
    GetPrivateFileById: (id: string) => `${ApiBase.File}/privates/${id}`,
  },
  User: {
    Review: {
      UpdateGoalValueAnnualMonth: (userId: string, data: any) => `${ApiBase.User}/${userId}/review-goal-values/${data?.id}`,
      UpdateProgressPercentGoalRowAnnualMonth: (userId: string, data: any) =>
        `${ApiBase.User}/${userId}/review-goal-rows/${data?.reviewPerformanceRowId}`,
      UpdateGoalSectionAnnualMonth: (userId: string, reviewSectionByMemberId: string) =>
        `${ApiBase.User}/${userId}/review-goal-sections/${reviewSectionByMemberId}`,
    },
    HasAnnualLeave: () => `${ApiBase.User}/has-annual-leave`,
  },
  Setting: {
    PdfTemplate: {
      GetList: () => `${ApiBase.Setting.PdfTemplate}`,
      Create: () => `${ApiBase.Setting.PdfTemplate}`,
      Update: (id: string) => `${ApiBase.Setting.PdfTemplate}/${id}`,
      Delete: (id: string) => `${ApiBase.Setting.PdfTemplate}/${id}`,
    },
  },
  Review: {
    FormLevel: {
      ExportGuideline: () => `${ApiBase.Review.FormLevel}/export-guideline`,
    },
    FormMember: {
      SaveSummary: (id: string) => `${ApiBase.Review.FormMember}/${id}/save-summary`,
      GetSummary: (id: string) => `${ApiBase.Review.FormMember}/${id}/get-summary`,
      GetMembersToCloneRow: (id: string) => `${ApiBase.Review.FormMember}/${id}/members`,
      GenerateTokenToView: (id: string) => `${ApiBase.Review.FormMember}/${id}/tokens`,
      Get: (id: string) => `${ApiBase.Review.FormMember}/${id}`,
      GeneratePdfFileId: (id: string) => `${ApiBase.Review.FormMember}/${id}/pdfs`,
      GeneratePdfFileZip: () => `${ApiBase.Review.FormMember}/pdfs`,
      UpdateAffectSalary: (id: string, noLoading: boolean = true) =>
        `${ApiBase.Review.FormMember}/${id}/affect-salary${noLoading ? '?noLoading=true' : ''}`,
      UpdateIsSelfVerifying: (id: string, noLoading: boolean = true) =>
        `${ApiBase.Review.FormMember}/${id}/is-self-verifying${noLoading ? '?noLoading=true' : ''}`,
      Delete: (id: string) => `${ApiBase.Review.FormMember}/?id=${id}`,
    },
    ViewToken: {
      GetFormMemberByToken: (tokenId: string) => `${ApiBase.Review.ViewToken}/${tokenId}/review-form-members`,
    },
    RootSection: {
      PreviewPrivateSection: (reviewSectionId: string, noLoading: boolean = false) =>
        `${ApiBase.Review.RootSection}/${reviewSectionId}${noLoading ? '?noLoading=true' : ''}`,
      CloneRowInsidePrivateSection: (reviewSectionId: string) => `${ApiBase.Review.RootSection}/${reviewSectionId}/clone-rows`,
      UpdateSectionColumnDetail: (reviewSectionId: string) =>
        `${ApiBase.Review.RootSection}/${reviewSectionId}/section-column-details`,
      GetRecentGoalSection: () => `${ApiBase.Review.RootSection}/recent-goals`,
    },
    StatusForm: {
      GetList: () => ApiBase.Review.StatusForm,
      GetDropdown: () => `${ApiBase.Review.StatusForm}/get-dropdown`,
      Create: () => ApiBase.Review.StatusForm,
      Update: (id: string) => `${ApiBase.Review.StatusForm}/${id}`,
      Delete: (id: string) => `${ApiBase.Review.StatusForm}/${id}`,
    },
  },
  ClientWidget: {
    GetReview: () => `${ApiBase.ClientWidget}/review-forms`,
    GetAsset: () => `${ApiBase.ClientWidget}/assets`,
  },

  Benefit: {
    Management: {
      Get: (id: string) => `${ApiBase.Benefit.Management}/${id}`,
      GetList: () => `${ApiBase.Benefit.Management}`,
      Create: () => `${ApiBase.Benefit.Management}`,
      Update: (id: string) => `${ApiBase.Benefit.Management}/${id}`,
      Delete: (id: string) => `${ApiBase.Benefit.Management}/${id}`,
      SendToApprove: (id: string) => `${ApiBase.Benefit.Management}/${id}/approves`,
      UpdateStatus: (id: string) => `${ApiBase.Benefit.Management}/${id}/status`,
      UpdateActiveStatus: (id: string) => `${ApiBase.Benefit.Management}/${id}/active-status`,
      Recreate: (id: string) => `${ApiBase.Benefit.Management}/${id}/recreates`,
      GetBenefitUserData: () => `${ApiBase.Benefit.Management}/user-data`,
    },
    Bonus: {
      GetList: () => `${ApiBase.Benefit.Bonus}`,
      Create: () => `${ApiBase.Benefit.Bonus}`,
      Update: (id: string) => `${ApiBase.Benefit.Bonus}/${id}`,
      Delete: (id: string) => `${ApiBase.Benefit.Bonus}/${id}`,
      SearchMember: (id: string) => `${ApiBase.Benefit.Bonus}/${id}/members`,
      UpdateMemberActiveState: () => `${ApiBase.Benefit.Bonus}/active-state`,
      ViewMembers: () => `${ApiBase.Benefit.Bonus}/view-members`,
    },
    Criteria: {
      GetList: () => `${ApiBase.Benefit.Criteria}`,
      Create: () => `${ApiBase.Benefit.Criteria}`,
      Update: (id: string) => `${ApiBase.Benefit.Criteria}/${id}`,
      Delete: (id: string) => `${ApiBase.Benefit.Criteria}/${id}`,
    },
    MemberRegistration: {
      GetList: () => `${ApiBase.Benefit.MemberRegistration}`,
      Export: () => `${ApiBase.Benefit.MemberRegistration}/export`,
      GetRegistrationsList: () => `${ApiBase.Benefit.MemberRegistration}/benefit-registrations`,
      GetHistories: () => `${ApiBase.Benefit.MemberRegistration}/histories`,
      UpdateStatus: (id: string) => `${ApiBase.Benefit.MemberRegistration}/${id}`,
      UpdateRefund: () => `${ApiBase.Benefit.MemberRegistration}/refund`,
      UpdatePaid: () => `${ApiBase.Benefit.MemberRegistration}/paid`,
    },
    Category: {
      View: () => `${ApiBase.Benefit.Category}`,
      GetById: (id: string) => `${ApiBase.Benefit.Category}/${id}`,
      Create: () => `${ApiBase.Benefit.Category}`,
      Update: () => `${ApiBase.Benefit.Category}`,
      Delete: (id: string) => `${ApiBase.Benefit.Category}/${id}`,
    },
    Result: {
      View: () => `${ApiBase.Benefit.Result}`,
      GetById: (id: string) => `${ApiBase.Benefit.Result}/${id}`,
      Create: () => `${ApiBase.Benefit.Result}`,
      Update: () => `${ApiBase.Benefit.Result}`,
      Delete: (id: string) => `${ApiBase.Benefit.Result}/${id}`,
    },
  },
  LeaveSetting: {
    Get: () => `${ApiBase.LeaveSetting}`,
    Create: () => `${ApiBase.LeaveSetting}`,
    Update: (id: string) => `${ApiBase.LeaveSetting}/${id}`,
  },
  LeaveTransfer: {
    GetList: () => `${ApiBase.LeaveTransfer}`,
    Preview: () => `${ApiBase.LeaveTransfer}/previews`,
    Update: (id: string) => `${ApiBase.LeaveTransfer}/${id}`,
    Generate: () => `${ApiBase.LeaveTransfer}/generates`,
    Complete: () => `${ApiBase.LeaveTransfer}/completes`,
    EmailTemplates: () => `${ApiBase.LeaveTransfer}/email-templates`,
    Confirm: () => `${ApiBase.LeaveTransfer}/confirms`,
    CheckDisplayPopup: () => `${ApiBase.LeaveTransfer}/check-display-popup`,
    Employee: () => `${ApiBase.LeaveTransfer}/employee`,
    Exception: (noLoading: boolean = false) => `${ApiBase.LeaveTransfer}/exception?noLoading=${noLoading}`,
    Export: () => `${ApiBase.LeaveTransfer}/export`,
  },
  AnnualLeave: {
    Export: () => `${ApiBase.AnnualLeave}/export`,
    Departments: () => `${ApiBase.AnnualLeave}/dropdown`,
    Years: () => `${ApiBase.AnnualLeave}/years`,
    UpdateSponsorDayNumber: (annualLeaveId: string) => `${ApiBase.AnnualLeave}/${annualLeaveId}/sponsor-day-numbers`,
    GetSponsorDays: (annualLeaveId: string) => `${ApiBase.AnnualLeave}/${annualLeaveId}/sponsor-day-settings`,
    UpdateSponsorDay: (annualLeaveId: string) => `${ApiBase.AnnualLeave}/${annualLeaveId}/sponsor-day-settings`,
  },
  LeaveRequest: {
    Export: () => `${ApiBase.LeaveRequest}/export`,
  },
  Employee: {
    Export: () => `${ApiBase.Employee}/export`,
    ContractTypes: () => `${ApiBase.Employee}/contract-types`,
  },
  DepartmentGroup: {
    Members: () => `${ApiBase.DepartmentGroup}/get-members`,
  },
  ChristmasGame: {
    getOrnaments: () => `${ApiBase.ChristmasGame}`,
    redeemOrnament: (id: string) => `${ApiBase.ChristmasGame}/${id}/redeems`,
  },
  PurchaseRequest: {
    Export: () => `${ApiBase.PurchaseRequest}/export`,
    StatusDropdown: () => `${ApiBase.PurchaseRequest}/status-dropdown`,
  },
  CheckInCheckOutRequest: {
    GetList: () => `${ApiBase.CheckInCheckOut.Management}`,
    GetById: () => `${ApiBase.CheckInCheckOut.GetById}`,
    Approve: () => `${ApiBase.CheckInCheckOut.Approve}`,
    Reject: () => `${ApiBase.CheckInCheckOut.Reject}`,
  },
  UserDepartment: {
    MyTeams: () => `${ApiBase.UserDepartment}/get-my-teams`,
    PagingMembers: () => `${ApiBase.UserDepartment}/get-paging-members`,
  },
  EquipmentCategory: {
    PagingList: () => `${ApiBase.EquipmentCategory}/dropdown-paging-list`,
  },
  Warehouse: {
    PagingList: () => `${ApiBase.Warehouse}/paging-list`,
  },
  Product: {
    PagingList: () => `${ApiBase.Product}/dropdown-paging-list`,
  },
};
