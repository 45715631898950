import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@intranet/lib/data-access';
import { Toastr } from '@intranet/lib/settings/services/toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  hasSent = false;
  formGroup: FormGroup;

  get username() { return this.formGroup.get('username'); }

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.formGroup = new FormGroup({
      username: new FormControl('', Validators.required)
    });
  }

  async forgotPassword() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    const request = {
      username: this.username.value
    } as any;
    const response = await this.userService.sendVerificationEmailForgotPassword(request);
    if (response?.id === -1) {
      Toastr.error(response.message);
      return;
    }
    this.hasSent = true;
  }
}
