import { Component, OnInit, ViewChild, ElementRef, TemplateRef, ContentChild, Input } from '@angular/core';
import { BaseAdminComponent } from '@intranet/lib/components';
import { NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NewsFeedTypeEnum, NewsFeed } from '@intranet/lib/data-access';
import { NewsFeeds as NewsFeedsConstant, Constants } from '@intranet/index';
import { CreateFeedBaseComponent } from '../create-feed-base/create-feed-base.component';

@Component({
  selector: 'app-newsfeed-create-post-modal',
  templateUrl: './create-post-modal.component.html',
  styles: [],
})
export class CreatePostModalComponent extends BaseAdminComponent implements OnInit {
  @ViewChild('ce', { static: false }) createFeedComponent: CreateFeedBaseComponent;

  @Input() activeModal: NgbActiveModal;
  @Input() set data(value: NewsFeed) {
    this.feed = value;
    this.typeId = value.newsFeedTypeId;
  }

  constants = NewsFeedsConstant;
  types = NewsFeedsConstant.FEED_TYPES;

  typeId: NewsFeedTypeEnum;
  feed: NewsFeed;

  canBack = false;
  title: string;

  public now: Date = new Date();

  constructor(modalRef: NgbActiveModal) {
    super();
    this.title = '';
  }

  ngOnInit() { }

  changeType(typeId) {
    this.typeId = typeId;
  }

  back() {
    this.canBack = false;
  }

  onSubmit() {
    this.createFeedComponent.onSubmit();
  }
}
