import { Component, OnInit, Input, Injectable, ChangeDetectorRef, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter, OnChanges, SimpleChange, OnDestroy } from '@angular/core';
import { CalendarEvent, CalendarEventTitleFormatter, CalendarDateFormatter, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { CalendarOptions } from './calendar-options';
import { CustomDateFormatter } from './custom-date-formatter/custom-date-formatter.component';
import { colors } from './colors';
import { addHours, startOfDay, endOfWeek, addDays, addMinutes, subDays, compareAsc, startOfWeek } from 'date-fns';
import { WeekViewHourSegment } from 'calendar-utils';
import { fromEvent, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CustomEventTitleFormatter } from './custom-date-formatter/custom-event-title-formatter';
import { CalendarEventTypeEnum, CalendarView } from '@intranet/lib/data-access';
import * as moment from 'moment';
import { UrlConstant } from '@intranet/index';
import { differenceInMinutes } from 'date-fns';
import { EVENT_CATEGORY, EVENT_NAME } from '@intranet/lib/config/google-analytics.constants';
import { AngularFireAnalytics } from '@angular/fire/analytics';
export class ViewDateChange {
  startDate: Date;
  endDate: Date;
  constructor(start?: Date, end?: Date) {
    this.startDate = start;
    this.endDate = end;
  }
}

function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

@Component({
  selector: 'calendar-component',
  templateUrl: './calendar-component.component.html',
  styleUrls: ['./calendar-component.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponentComponent implements OnInit, OnDestroy {
  _options: CalendarOptions = new CalendarOptions();
  _classEvents: any;

  CalendarEventTypeEnum = CalendarEventTypeEnum;
  urlConstant = UrlConstant;

  @Input() set options(value: CalendarOptions) {
    if (value) {
      this._options = { ...this._options, ...value };

      if (this._options.view === CalendarView.Year) {
        this.months = [];
        this.eventsByMonth = {};
        this.monthsOfYear.map((month, index) => {
          const beginOfMonth = moment(this._options.viewDate).toDate();
          beginOfMonth.setMonth(index);
          this.months.push(beginOfMonth);
          this.eventsByMonth[index] = [];
        });

      }
    }
  }

  @Input() viewDate?: any = this._options.viewDate;

  @Input()
  viewChangeSub?: Subject<any>;

  @Input() set eventData(value: CalendarEvent[]) {
    if (value) {
      this.events = [];
      value.map((event) => {
        const item = Object.create(event);
        if (item.meta?.calendarType === CalendarEventTypeEnum.BOOKING) {
          item.color = {
            primary: item.meta?.room?.colorInCalendar,
            secondary: item.meta?.room?.colorInCalendar
          };
        }
        this.events.push(item);
      });
    }
  }

  @Input() set yearEventData(value: CalendarEvent[]) {
    if (value) {
      this._classEvents = {};
      this.yearEvents = value;
    }

  }
  @Input() dataHeader: any;
  @Input() showFilter?= true;
  @Input() durationFilters?: any;

  countEvent = 0;
  singleClickTimer: any;
  monthsOfYear: any;
  months: any;
  eventsByMonth: any;

  @Output() eventClick = new EventEmitter();
  @Output() eventResize = new EventEmitter();
  @Output() eventDelete = new EventEmitter();
  @Output() eventChangeDate = new EventEmitter();
  @Output() eventFilter = new EventEmitter();
  @Output() eventEdit = new EventEmitter();

  @Output() dayHeaderClicked = new EventEmitter();
  @Output() hourSegmentClicked = new EventEmitter();

  dragToCreateActive = false;
  visible = false;

  events: CalendarEvent[] = [];
  yearEvents: CalendarEvent[] = [];

  refresh: Subject<any> = new Subject();

  constructor(private cdr: ChangeDetectorRef, private analytics: AngularFireAnalytics) {
    this.monthsOfYear = moment.months();
    this.months = [];
    this.eventsByMonth = {};
    this.monthsOfYear.map((month, index) => {
      const beginOfMonth = moment(this._options.viewDate).toDate();
      beginOfMonth.setMonth(index);
      this.months.push(beginOfMonth);
      this.eventsByMonth[index] = [];
    });
  }

  ngOnInit() {
    if (this.viewChangeSub) {
      this.viewChangeSub.subscribe(_ => {
        this.resetCalendarView();
      });
    }
  }

  ngOnDestroy() {
    if (this.viewChangeSub) {
      this.viewChangeSub.unsubscribe();
    }
  }

  startDragToCreate(segment: WeekViewHourSegment, mouseDownEvent: MouseEvent, segmentElement: HTMLElement) {
    if (!this.events) {
      this.events = [];
    }
    const index = this.events.findIndex(x => x.meta && x.meta.tmpEvent);
    if (index === 0 && this.events.length === 1) {
      this.events = [];
    } else if (index !== -1) {
      this.events.splice(index, 1);
    }
    const dragToSelectEvent: CalendarEvent = {
      id: this.events.length,
      title: 'New event',
      start: segment.date,
      color: colors.red,
      end: addMinutes(segment.date, 30),
      draggable: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      meta: {
        tmpEvent: true,
      },
      cssClass: 'new-event',
    };
    this.events = [...this.events, dragToSelectEvent];
    const segmentPosition = segmentElement.getBoundingClientRect();
    this.dragToCreateActive = true;
    const endOfView = endOfWeek(this._options.viewDate, {
      weekStartsOn: this._options.weekStartsOn,
    });

    fromEvent(document, 'mousemove')
      .pipe(
        finalize(() => {
          // delete dragToSelectEvent.meta.tmpEvent;
          this.dragToCreateActive = false;
          this.refreshView();
        }),
        takeUntil(fromEvent(document, 'mouseup')),
      )
      .subscribe((mouseMoveEvent: MouseEvent) => {
        const minutesDiff = ceilToNearest(mouseMoveEvent.clientY - segmentPosition.top, 30);

        const daysDiff = floorToNearest(mouseMoveEvent.clientX - segmentPosition.left, segmentPosition.width) / segmentPosition.width;

        const newEnd = addDays(addMinutes(segment.date, minutesDiff), daysDiff);
        if (newEnd > segment.date && newEnd < endOfView) {
          dragToSelectEvent.end = newEnd;
        }
        this.refreshView();
      });
  }

  private refreshView() {
    this.events = [...this.events];
    this.cdr.detectChanges();
  }

  calculateHeight(event: CalendarEvent) {
    let height = 75;
    height = differenceInMinutes(event.end, event.start);
    return height + 'px';
  }

  changeDay(day: any) {
    if (day.events && day.events.length) {
      this._options.viewDate = day.date;
      this._options.view = CalendarView.Day;
      this.viewDate = day.date;
      this.eventChangeDate.emit(this.viewDate);
    }
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    const result = compareAsc(event.start, newStart);
    const result2 = compareAsc(event.end, newEnd);
    if (result !== 0 || result2 !== 0) {
      event.start = newStart;
      event.end = newEnd;
      if (event.meta && event.meta.id) {
        this.eventResize.emit(event);
      }
      this.refresh.next();
    }
  }

  eventClicked({ event }: { event: any }): void {
    this.countEvent++;

    if (this.countEvent === 1) {
      if (event.meta && !event.meta.tmpEvent) {
        const index = this.events.findIndex(x => x.meta && x.meta.tmpEvent);
        if (index === 0 && this.events.length === 1) {
          this.events = [];
        } else if (index !== -1) {
          this.events.splice(index, 1);
          this.refreshView();
        }
      }
      this.singleClickTimer = setTimeout(() => {
        this.countEvent = 0;
      }, 400);
    } else if (this.countEvent === 2 && event.eventType && event.eventType === 2) {
      this.analytics.logEvent(EVENT_NAME.BookMeeting, { event_category: EVENT_CATEGORY.Calendar });
      clearTimeout(this.singleClickTimer);
      this.countEvent = 0;
      const clone = { ...event };
      this.eventClick.emit(clone);
    }
  }

  delete(event: CalendarEvent) {
    this.eventDelete.emit(event);
  }

  edit(event: CalendarEvent) {
    this.closeOver(event);
    this.eventEdit.emit(event);
  }

  viewChange(view: string) {
    const index = this.events.findIndex(x => x.meta && x.meta.tmpEvent);
    if (index === 0 && this.events.length === 1) {
      this.events = [];
    } else if (index !== -1) {
      this.events.splice(index, 1);
    }
  }

  viewDateChange(viewDate: Date) {
    // this.resetCalendarView();
    this.viewDate = viewDate;
    this.eventChangeDate.emit(viewDate);
  }

  resetCalendarView() {
    this._options.view = CalendarView.Day;
  }

  filter(data) {
    this.eventFilter.emit(data);
  }

  closeOver(event) {
    event.visible = false;
  }

  navigate(event) {
    window.open(this.urlConstant.mapNewsFeed(this.urlConstant.NewsFeedUrl.ANNOUNCEMENT_DETAILS) + '/' + event.meta?.newsFeedId);
  }

  onClickHourSegment(event: any) {
    this.analytics.logEvent(EVENT_NAME.BookMeeting, { event_category: EVENT_CATEGORY.Calendar });
    this.hourSegmentClicked.emit(event);
  }
}
