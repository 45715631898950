import { BaseModel } from './base-model';
import { EventTypeEnum } from './enums/event.enum';
import { Constants } from '@intranet/lib/config';
import * as moment from 'moment';

export class EventCalendar extends BaseModel {
  id?: string;
  title?: string;
  fromDate: Date;
  toDate: Date;
  isAnnual: boolean;
  annualFromDate?: Date;
  annualToDate?: Date;
  eventType: string;
  newsFeedId?: string;
  reminder?: number;
  departments?: string;
  toDateString?: string;
  repeatType?: number;
  constructor() {
    super();
    this.eventType = EventTypeEnum.EVENT.toString();
  }
}