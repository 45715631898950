<div class="rt-panel-item" *ngIf="isInEventTime">
  <div class="title-panel">
    <p>Sponsored Working Hour</p>
  </div>

  <div class="rt-panel-item-content">
    <ng-container *ngIf="!loading; else loadingTemplate">
      <p style="font-size: 13px">
        Please select your health check-up date to get 4 working hours updated into your timesheet. Your request will be processed
        at the end of the day.
      </p>

      <div class="d-flex align-items-center my-3">
        <nz-date-picker
          *ngIf="!claimedDate || isEditMode; else claimedText"
          [(ngModel)]="claimDate"
          [nzFormat]="dateFormat"
          [nzDisabledDate]="disabledDateFn"
          [nzAllowClear]="false"
          class="flex-grow-1 w-auto"
        ></nz-date-picker>
        <ng-template #claimedText>
          <p
            class="d-flex flex-grow-1 justify-content-center align-items-center p-2 rounded"
            style="color: #1890ff; background-color: #e9f5ff"
          >
            Claimed for {{ claimedDate | date : dateFormat }}
          </p>
        </ng-template>
        <ng-container *ngIf="!isEffected">
          <ng-container *ngIf="!isEditMode; else editActions">
            <button *ngIf="!claimedDate; else editButton" class="btn btn-primary ml-2" nz-button (click)="claimHealthCheck()">
              Claim
            </button>
            <ng-template #editButton>
              <button nz-button class="btn-icon ml-2" (click)="isEditMode = true">
                <i nz-icon nzType="edit"></i>
              </button>
            </ng-template>
          </ng-container>
          <ng-template #editActions>
            <button nz-button nzType="primary" class="btn-icon btn-icon-primary ml-2">
              <i nz-icon nzType="check" (click)="claimHealthCheck()"></i>
            </button>
            <button nz-button class="btn-icon ml-1"><i nz-icon nzType="close" (click)="cancel()"></i></button>
          </ng-template>
        </ng-container>
      </div>

      <p class="font-italic pb-1" style="font-size: 12px">
        Note: The last day to claim this sponsor is
        {{ endEventDate | date : dateFormat }}.
      </p>
    </ng-container>

    <ng-template #loadingTemplate>
      <nz-skeleton></nz-skeleton>
    </ng-template>
  </div>
</div>
