export enum SKILL_STATUS {
  APPROVED,
  PENDING,
  REJECTED,
}

export enum SKILL_RATING {
  EXCELLENT,
  GOOD,
  FAIR,
}

export class EmployeeSkill {
  empId: string;
  empName: string;
  skill: string;
  skillRating: SKILL_RATING;
  requestedDate: Date;
  status: SKILL_STATUS;
  totalCount: number;
}

export class Skill {
  id: string;
  name: string;
  description: string;
  totalCount: number;
}
