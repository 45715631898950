export enum GenderEnum {
    Male = 1,
    Female = 2,
    Others = 3
}

export enum GenderConstEnum {
    Male = 'Male',
    Female = 'Female',
    Others = 'Others'
}