import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { CalendarView, CalendarEvent } from 'angular-calendar';
import { BaseAdminComponent } from '@intranet/lib/components';
import { ModalService, ModalSize, Loading } from '@intranet/index';
import { CreateLeaveComponent } from 'projects/intranet/hrm-user/components';
import { CreateBookingComponent } from 'projects/intranet/hrm-user/pages/user-booking/create-booking/create-booking.component';
import * as moment from 'moment';
import { CalendarService } from '@intranet/lib/data-access';

@Component({
  selector: 'app-newsfeed-events-calendar-full',
  templateUrl: './events-calendar-full.component.html',
  styleUrls: ['./events-calendar-full.component.scss'],
  styles: [],
})
export class EventCalendarFullComponent extends BaseAdminComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  selectedDate: any;
  today: Date = moment().startOf('day').toDate();
  viewDate: Date = moment().startOf('day').toDate();
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[];
  currentEvents: any;
  currentMeetings: any;
  currentLeaves: any;

  constructor(private modalSvc: ModalService, private calendarService: CalendarService) {
    super();
  }

  ngOnInit() {
    this.getEvents();
  }

  getEvents() {
    this.events = [];
    this.currentEvents = [];
    this.currentMeetings = [];
    this.currentLeaves = [];

    Loading.show();
    this.calendarService.getEventsByDate(
      {
        fromDate: moment(this.viewDate).startOf('month').format('YYYY-MM-DD'),
        toDate: moment(this.viewDate).endOf('month').format('YYYY-MM-DD'),
      },
      (res: any) => {
        Loading.hide();
        const events = [];
        for (const event of res) {
          const eventData = {
            start: moment(event.fromDate).toDate(),
            end: moment(event.toDate).toDate(),
            title: event.name,
            data: event.data,
            eventType: event.eventType,
            sameDay: moment(event.fromDate).format('YYYY-MM-DD') === moment(event.toDate).format('YYYY-MM-DD'),
          };

          if (moment(eventData.start).startOf('day').toDate() <= this.viewDate && moment(eventData.end).endOf('day').toDate() >= this.viewDate) {
            if (event.eventType === 1) {
              this.currentLeaves.push(eventData);
            } else if (event.eventType === 2) {
              this.currentMeetings.push(eventData);
            } else {
              this.currentEvents.push(eventData);
            }
          }

          events.push(eventData);
        }
        this.events = events;
      },
      error => { },
      () => {
        Loading.hide();
      },
    );
  }

  dayClicked(event): void {
    if (this.viewDate.getMonth() !== event.day.date.getMonth()) {
      this.viewDate = event.day.date;

      this.getEvents();
    } else {
      this.viewDate = event.day.date;
    }

    const events = event.day.events;
    const currentMeetings = [];
    const currentEvents = [];
    const currentLeaves = [];

    for (const event of events) {
      if (event.eventType === 1) {
        currentLeaves.push(event);
      } else if (event.eventType === 2) {
        currentMeetings.push(event);
      } else {
        currentEvents.push(event);
      }
    }

    this.currentLeaves = currentLeaves;
    this.currentEvents = currentEvents;
    this.currentMeetings = currentMeetings;
  }

  onViewDateChange(date) {
    this.viewDate = date;
    this.getEvents();
  }

  createLeave() {
    const modalRef = this.modalSvc.open(CreateLeaveComponent, { size: ModalSize.lg });
    const d = moment(this.viewDate);
    const dateString = d.format('YYYY-MM-DD').toString();
    const date = new Date(dateString);
    modalRef.componentInstance.data = {
      fromDate: date,
      toDate: date,
    };
    modalRef.result.then(res => {
      if (res) {
        this.getEvents();
      }
    });
  }

  createBooking() {
    const modalRef = this.modalSvc.open(CreateBookingComponent, { size: ModalSize.lg });
    modalRef.componentInstance.event = {
      start: this.viewDate,
      end: this.viewDate,
      meta: {
        createdBy: this.userId,
      },
    };
    modalRef.result.then(res => {
      this.getEvents();
    });
  }
}
