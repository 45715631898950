<div class="avatar-container" [ngStyle]="containerStyle">
  <img *ngIf="src; else textAvatar" [src]="src" [width]="size" [height]="size" [ngStyle]="avatarImageStyle"
    default="M_ReferUser-1.png" class="avatar-grid" type="local" nz-popover [nzPopoverContent]="zoomAvatar"/>
  <ng-template #textAvatar>
    <div *ngIf="placeholder" class="avatar-content" [ngStyle]="avatarStyle">
      <span [ngStyle]="spanStyle"> {{ placeholder }}</span>
    </div>
  </ng-template>
  <ng-template #zoomAvatar>
    <img *ngIf="src" [src]="src" [width]="128" [height]="128" [ngStyle]="avatarImageStyle"
    default="M_ReferUser-1.png" class="image-popover-chrome-bug" type="local"/>
    </ng-template>
</div>
