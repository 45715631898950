<nz-page-header *ngIf="empDetails" class="site-page-header">
  <!--avatar-->
  <nz-avatar *ngIf="!isMobileView" nz-page-header-avatar [nzSrc]="avatarUrl" nz-popover [nzPopoverContent]="zoomAvatar"> </nz-avatar>

  <!--title-->
  <nz-page-header-title class="employee-name">{{ empDetails?.fullName }}</nz-page-header-title>

  <!--subtitle-->
  <nz-page-header-subtitle>
    <p class="header-profile-info-container">
      <span class="header-profile-info-birthday"><img src="/assets/images/icons/birthday-cake-black-icon.svg" />{{ empDetails.dateOfBirth | date : 'MMM dd' }}</span>

      <ng-container *ngIf="!isMobileView">
        <div class="separate-dot"></div>
        <ng-container [ngTemplateOutlet]="employeeInfoTpl"></ng-container>
      </ng-container>
    </p>
  </nz-page-header-subtitle>

  <!--tags-->
  <!-- <nz-page-header-tags>
    <nz-tag [nzColor]="'blue'">{{empDetails?.position}}</nz-tag>
  </nz-page-header-tags> -->
</nz-page-header>

<div *ngIf="isMobileView && empDetails" class="employee-info">
  <ng-container [ngTemplateOutlet]="employeeInfoTpl"></ng-container>
</div>

<ng-template #zoomAvatar>
  <img class="rounded-circle" *ngIf="avatarUrl" [src]="avatarUrl" [width]="128" [height]="128" default="M_ReferUser-1.png" type="local" />
</ng-template>

<ng-template #employeeInfoTpl>
  <span class="header-profile-info-position">
    <strong class="position">{{ empDetails?.position || 'Member' }}</strong> since {{ empDetails?.joinedDate | date : 'MMM dd, yyyy' }}
  </span>
  <div class="separate-dot"></div>
  <span
    class="header-profile-action-icon"
    nz-tooltip
    nzTooltipTitle="Copy email"
    nz-icon
    nzType="mail"
    nzTheme="outline"
    (click)="copyToClipBoard(targetCopy.email)"
  ></span>
  <a
    *ngIf="empDetails?.msTeamsAccount"
    [attr.href]="msTeamLink + empDetails?.msTeamsAccount"
    target="_blank"
    class="header-profile-info-msTeams"
    nz-tooltip
    nzTooltipTitle="Start chat on MS Teams"
  >
    <svg width="18" height="18" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.625 8.127q-.55 0-1.025-.205-.475-.205-.832-.563-.358-.357-.563-.832Q18 6.053 18 5.502q0-.54.205-1.02t.563-.837q.357-.358.832-.563.474-.205 1.025-.205.54 0 1.02.205t.837.563q.358.357.563.837.205.48.205 1.02 0 .55-.205 1.025-.205.475-.563.832-.357.358-.837.563-.48.205-1.02.205zm0-3.75q-.469 0-.797.328-.328.328-.328.797 0 .469.328.797.328.328.797.328.469 0 .797-.328.328-.328.328-.797 0-.469-.328-.797-.328-.328-.797-.328zM24 10.002v5.578q0 .774-.293 1.46-.293.685-.803 1.194-.51.51-1.195.803-.686.293-1.459.293-.445 0-.908-.105-.463-.106-.85-.329-.293.95-.855 1.729-.563.78-1.319 1.336-.756.557-1.67.861-.914.305-1.898.305-1.148 0-2.162-.398-1.014-.399-1.805-1.102-.79-.703-1.312-1.664t-.674-2.086h-5.8q-.411 0-.704-.293T0 16.881V6.873q0-.41.293-.703t.703-.293h8.59q-.34-.715-.34-1.5 0-.727.275-1.365.276-.639.75-1.114.475-.474 1.114-.75.638-.275 1.365-.275t1.365.275q.639.276 1.114.75.474.475.75 1.114.275.638.275 1.365t-.275 1.365q-.276.639-.75 1.113-.475.475-1.114.75-.638.276-1.365.276-.188 0-.375-.024-.188-.023-.375-.058v1.078h10.875q.469 0 .797.328.328.328.328.797zM12.75 2.373q-.41 0-.78.158-.368.158-.638.434-.27.275-.428.639-.158.363-.158.773 0 .41.158.78.159.368.428.638.27.27.639.428.369.158.779.158.41 0 .773-.158.364-.159.64-.428.274-.27.433-.639.158-.369.158-.779 0-.41-.158-.773-.159-.364-.434-.64-.275-.275-.639-.433-.363-.158-.773-.158zM6.937 9.814h2.25V7.94H2.814v1.875h2.25v6h1.875zm10.313 7.313v-6.75H12v6.504q0 .41-.293.703t-.703.293H8.309q.152.809.556 1.5.405.691.985 1.19.58.497 1.318.779.738.281 1.582.281.926 0 1.746-.352.82-.351 1.436-.966.615-.616.966-1.43.352-.815.352-1.752zm5.25-1.547v-5.203h-3.75v6.855q.305.305.691.452.387.146.809.146.469 0 .879-.176.41-.175.715-.48.304-.305.48-.715t.176-.879Z"
      ></path>
    </svg>
  </a>
  <div *ngIf="empDetails?.phoneMobile" class="separate-dot"></div>
  <span
    *ngIf="empDetails?.phoneMobile"
    class="header-profile-action-icon"
    (click)="copyToClipBoard(targetCopy.phoneNumber)"
    nz-tooltip
    nzTooltipTitle="Copy phone number"
  >
    <span class="phone-icon" nz-icon nzType="phone" nzTheme="outline"></span>
    <span>{{ empDetails?.phoneMobile }}</span>
  </span>
</ng-template>
