import { Injectable } from '@angular/core';
import * as randomBytes from 'randombytes';

@Injectable({
  providedIn: 'root',
})
export class GeneratePassword {
  RANDOM_BATCH_SIZE = 256;
  randomIndex;
  randomBytes;

  lowercase = 'abcdefghijklmnopqrstuvwxyz';
  uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  numbers = '0123456789';
  symbols = '!@#$%^&*()+_-=}{[]|:;"/?.><,`~';
  similarCharacters = /[ilLI|`oO0]/g;
  strictRules = [
    { name: 'lowercase', rule: /[a-z]/ },
    { name: 'uppercase', rule: /[A-Z]/ },
    { name: 'numbers', rule: /[0-9]/ },
    { name: 'symbols', rule: /[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~]/ },
  ];

  generatePw(options) {
    options = options || {};
    if (!options.hasOwnProperty('length')) {
      options.length = 10;
    }
    if (!options.hasOwnProperty('numbers')) {
      options.numbers = false;
    }
    if (!options.hasOwnProperty('symbols')) {
      options.symbols = false;
    }
    if (!options.hasOwnProperty('exclude')) {
      options.exclude = '';
    }
    if (!options.hasOwnProperty('uppercase')) {
      options.uppercase = true;
    }
    if (!options.hasOwnProperty('excludeSimilarCharacters')) {
      options.excludeSimilarCharacters = false;
    }
    if (!options.hasOwnProperty('strict')) {
      options.strict = false;
    }

    if (options.strict) {
      const minStrictLength = 1 + (options.numbers ? 1 : 0) + (options.symbols ? 1 : 0) + (options.uppercase ? 1 : 0);
      if (minStrictLength > options.length) {
        throw new TypeError('Length must correlate with strict guidelines');
      }
    }

    // Generate character pool
    let pool = this.lowercase;

    // uppercase
    if (options.uppercase) {
      pool += this.uppercase;
    }
    // numbers
    if (options.numbers) {
      pool += this.numbers;
    }
    // symbols
    if (options.symbols) {
      pool += this.symbols;
    }

    // similar characters
    if (options.excludeSimilarCharacters) {
      pool = pool.replace(this.similarCharacters, '');
    }

    // excludes characters from the pool
    let i = options.exclude.length;
    while (i--) {
      pool = pool.replace(options.exclude[i], '');
    }

    const password = this.generate(options, pool);

    return password;
  }

  generateMultiple(amount, options) {
    const passwords = [];

    for (let i = 0; i < amount; i++) {
      passwords[i] = this.generatePw(options);
    }

    return passwords;
  }

  private getNextRandomValue() {
    if (this.randomIndex === undefined || this.randomIndex >= this.randomBytes.length) {
      this.randomIndex = 0;
      // console.log('randombytes', randombytes);
      this.randomBytes = randomBytes(this.RANDOM_BATCH_SIZE);
    }

    const result = this.randomBytes[this.randomIndex];
    this.randomIndex += 1;
    return result;
  }

  private randomNumber(max) {
    // gives a number between 0 (inclusive) and max (exclusive)
    let rand = this.getNextRandomValue();
    while (rand >= 256 - (256 % max)) {
      rand = this.getNextRandomValue();
    }
    return rand % max;
  }

  // Possible combinations

  private generate(options, pool) {
    let password = '';
    const optionsLength = options.length;
    const poolLength = pool.length;

    for (let i = 0; i < optionsLength; i++) {
      password += pool[this.randomNumber(poolLength)];
    }

    if (options.strict) {
      // Iterate over each rule, checking to see if the password works.
      const fitsRules = this.strictRules.reduce((result, rule) => {
        // Skip checking the rule if we know it doesn't match.
        if (result === false) {
          return false;
        }

        // If the option is not checked, ignore it.
        if (options[rule.name] === false) {
          return result;
        }

        // Run the regex on the password and return whether
        // or not it matches.
        return rule.rule.test(password);
      }, true);

      // If it doesn't fit the rules, generate a new one (recursion).
      if (!fitsRules) {
        return this.generate(options, pool);
      }
    }

    return password;
  }
}
