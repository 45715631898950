import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckInCheckOutInfo, CheckInCheckOutStatusEnum, CreateCheckInCheckOutRequest, UserService } from '@intranet/lib/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateHelper } from '@intranet/lib/helpers/dateHelper';
import { CheckInCheckOutConstants } from '@intranet/lib/config/checkincheckout.constant';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
@Component({
  selector: 'app-checkin-checkout-claim-modal',
  templateUrl: './checkin-checkout-claim-modal.component.html',
  styleUrls: ['./checkin-checkout-claim-modal.component.scss']
})
export class CheckinCheckoutClaimModalComponent implements OnInit {

  title: string;
  @Input() date: string;
  data: CheckInCheckOutInfo;
  checkInCheckOutConstants = CheckInCheckOutConstants;
  checkInCheckOutStatusEnum = this.checkInCheckOutConstants.Status;
  checkInCheckOutStatusDisplay = this.checkInCheckOutConstants.CheckInCheckOutStatus.DisplayClient;

  constructor(public activeModal: NgbActiveModal, private userService: UserService) { }

  ngOnInit() {
  }

  getInfo() {

  }

  async save() {

    const model = new CreateCheckInCheckOutRequest();
    model.startTime = DateHelper.formatServerRemoveISO(this.date, 'YYYY/MM/DD') + ' ' + DateHelper.formatServerRemoveISO(this.data.startTime, 'HH:mm:ss');
    model.endTime = DateHelper.formatServerRemoveISO(this.date, 'YYYY/MM/DD') + ' ' + DateHelper.formatServerRemoveISO(this.data.endTime, 'HH:mm:ss');

    try {
      const result = await this.userService.registerCheckInOutRequest(model);
      this.activeModal.close({ data: true, isSuccess: true });
    } catch (err) {
      ErrorHelper.displayMessage(err);
    }
  }

  closeModal() {
    this.activeModal.close({ isSuccess: true });
  }

}
