import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ModalService, ModalSize, CommonHelper } from 'projects/intranet/hrm-lib';
import { CropImageComponent } from '../crop-image/crop-image.component';
import { ResizeFile, CropOptions } from '../../data-access';

@Component({
  selector: 'resize-image',
  templateUrl: './resize-image.component.html',
  styles: ['.hrm-avatar { max-width: 104px;}'],
  styleUrls: ['./resize-image.component.scss']
})
export class ResizeImageComponent implements OnInit {
  @Input() cropOptions: CropOptions;
  @Input() isHidden: boolean;
  @Input() isDisable = false;
  //
  @Input() imageUrl: string;
  @Input() placeholder: string;
  @Input() cropFile: ResizeFile;
  @Input() file: ResizeFile;
  @Input() format: any = 'base64';
  @Output() eventCompelete = new EventEmitter();

  constructor(private modalService: ModalService) { }

  ngOnInit() { }
  customReq = () => {
    return Subscription.EMPTY;
    // tslint:disable-next-line: semicolon
  };

  handleAvatarChange(info: { file: NzUploadFile }) {
    if (this.cropFile) {
      this.cropFile.fileName = info.file.name;
      this.cropFile.mimetype = info.file.type;
      const modalRef = this.modalService.open(CropImageComponent, { size: ModalSize.percent60, noHeader: true });
      modalRef.componentInstance.imageChangedEvent = info.file.originFileObj;
      modalRef.componentInstance.options = this.cropOptions;
      modalRef.componentInstance.cropButtonText = 'Save';
      modalRef.result.then(res => {
        if (res && res.id > 0) {
          this.imageUrl = res.base64;
          this.cropFile.base64 = res.base64;
          this.eventCompelete.emit(this.cropFile);
        }
      });
    } else {
      this.file.fileName = info.file.name;
      this.file.mimetype = info.file.type;
      CommonHelper.getBase64(info.file!.originFileObj!, (img: string) => {
        this.imageUrl = img;
        if (this.format === 'base64') {
          this.file.base64 = this.imageUrl;
          this.eventCompelete.emit(this.file);
        }
      });
      if (!this.format || this.format !== 'base64') {
        this.file.file = info.file.originFileObj;
        this.eventCompelete.emit(this.file);
      }
    }
  }
}
