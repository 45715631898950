import { Injectable } from '@angular/core';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { PaginationResponse } from '../models';
import { BenefitMemberRegistration } from '../models/benefit.model';

@Injectable({
  providedIn: 'root'
})
export class BenefitMemberRegistrationService {

  constructor(private httpClient: HttpClientService) { }

  getList(request: any) {
    const url = ApiEndpoint.Benefit.MemberRegistration.GetList();
    return this.httpClient.getObservable(url, request).toPromise();
  }
  getRegistrationsList(request: any) {
    const url = ApiEndpoint.Benefit.MemberRegistration.GetRegistrationsList();
    return this.httpClient.postObservable(url, request).toPromise();
  }

  export(searchData: any): Promise<any> {
    const url = ApiEndpoint.Benefit.MemberRegistration.Export();
    return this.httpClient.getObservable(url, searchData).toPromise();
  }

  getHistories(searchData: any): Promise<PaginationResponse<BenefitMemberRegistration>> {
    const url = ApiEndpoint.Benefit.MemberRegistration.GetHistories();

    return this.httpClient.getObservable(url, searchData).toPromise();
  }

  updateStatus(request: any) {
    const url = ApiEndpoint.Benefit.MemberRegistration.UpdateStatus(request.benefitMemberRegistrationId);
    return this.httpClient.putObservable(url, request).toPromise();
  }

  updatePaid(request: any) {
    const url = ApiEndpoint.Benefit.MemberRegistration.UpdatePaid();
    return this.httpClient.putObservable(url, request).toPromise();
  }

  updateRefund(request: any) {
    const url = ApiEndpoint.Benefit.MemberRegistration.UpdateRefund();
    return this.httpClient.putObservable(url, request).toPromise();
  }
}
