<modal-header [(title)]="title" [activeModal]="activeModal" [(canBack)]="canBack">
</modal-header>
<modal-body>
  <div [ngSwitch]="typeId" class="composer">
    <app-newsfeed-create-multiple-poll [data]="feed" *ngSwitchCase="types.POLL" #ce [(title)]="title"
      (closeModel)="activeModal.dismiss()" [(canBack)]="canBack">
    </app-newsfeed-create-multiple-poll>
    <app-newsfeed-create-standard-post [data]="feed" *ngSwitchDefault #ce [(title)]="title"
      (closeModel)="activeModal.dismiss()" [(canBack)]="canBack">
    </app-newsfeed-create-standard-post>
  </div>
</modal-body>
<modal-footer>
  <div class="w-100" *ngIf="canBack">
    <div class="form-group">
      <div class="float-right">
        <button (click)="onSubmit()" class="ml-2 btn btn-primary">
          <i class="fas fa-save mr5"></i> Confirm</button>
      </div>
    </div>
  </div>
  <div class="w-100" *ngIf="!canBack">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <div class="form-group">
        <a *ngIf="typeId != types.POLL" (click)="changeType(types.POLL)"><img src="assets/images/icons/poll-icon.svg"
            type="local" /> Poll</a>
        <a *ngIf="typeId != types.WALL" (click)="changeType(types.WALL)"><img src="assets/images/icons/post-icon.svg"
            type="local" /> Post</a>
      </div>
      <div class="form-group">
        <div class="float-right">
          <button (click)="activeModal.close('Close')" class="btn btn-cancel">
            <i class="far fa-times-circle mr5"></i>Close</button>
          <button (click)="onSubmit()" class="ml-2 btn btn-primary">
            <i class="fas fa-save mr5"></i> Post</button>
        </div>
      </div>
    </div>
  </div>
</modal-footer>