import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { WallNewsFeedService, SearchWallNewsFeed, CDNService, FileExtensionType } from '@intranet/lib/data-access';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { PubSubEvent, ModalService, ModalSize, UrlConstant } from '@intranet/index';
import { CreateAnnouncementComponent } from '../../components/create-post/create-announcement/create-announcement.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from '@intranet/lib/components';
@Component({
  selector: 'app-newsfeed-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
})
export class AnnouncementPageComponent implements OnInit {
  announcements: any;
  hasNextPage: boolean;
  queryFeeds: SearchWallNewsFeed = new SearchWallNewsFeed();
  isLoading: boolean;
  activeModal: NgbModalRef;

  urlConstant = UrlConstant;
  FileExtensionType = FileExtensionType;

  constructor(private route: ActivatedRoute, private wallNewsFeedService: WallNewsFeedService,
    private pubsub: PubSubService, private modalService: ModalService, private cdnService: CDNService) { }

  ngOnInit() {
    this.queryFeeds.noLoading = true;
    this.queryFeeds.pageSize = 10;
    this.queryFeeds.pageNumber = 1;
    this.announcements = [];
    this.getAnnoucements();

    this.pubsub.$sub(PubSubEvent.EVENTS.ON_CREATE_ANNOUNCEMENT).subscribe(feed => {
      this.announcements.unshift(feed);
    });
  }

  getAnnoucements() {
    this.isLoading = true;
    this.wallNewsFeedService.getAnnoucements(
      this.queryFeeds,
      (res: any) => {
        this.isLoading = false;

        if (res && res.length > 0) {
          this.queryFeeds.totalItems = res[0].totalCount;
          this.announcements = res;
          this.announcements.sort((a, b) => {
            return a.isImportant === b.isImportant ? (a.createdDate > b.createdDate ? -1 : 1) : (a.isImportant > b.isImportant ? -1 : 1);
          });
        }
      },
      error => { },
      () => {
        this.isLoading = false;
      },
    );
  }

  onPageChange(pageIndex) {
    this.queryFeeds.pageNumber = pageIndex;
    this.getAnnoucements();
  }

  deleteAnnouncement(announcement) {
    const modal = this.modalService.open(ModalConfirmComponent, {
      size: ModalSize.lg,
    });
    modal.componentInstance.lbConfirm = 'Delete';
    modal.componentInstance.title = 'Delete announcement?';
    modal.componentInstance.description = 'Are you sure you want to delete this announcement?';

    modal.result.then(result => {
      if (result) {
        this.wallNewsFeedService.deleteByUrl('delete-announcement', { id: announcement.id }, () => {
          this.getAnnoucements();
        });
      }
    });
  }

  createAnnouncement() {
    this.activeModal = this.modalService.open(CreateAnnouncementComponent, { windowClass: 'create-post-modal', size: ModalSize.xl });

    this.activeModal.result.then(
      result => {
        if (result) {
          this.getAnnoucements();
        }
      },
      reason => { },
    );
  }

  editAnnouncement(announcement) {
    this.wallNewsFeedService.getFeed(announcement.id, res => {
      this.activeModal = this.modalService.open(CreateAnnouncementComponent, { windowClass: 'create-post-modal', size: ModalSize.xl });
      this.activeModal.componentInstance.feed = res;

      this.activeModal.result.then(
        result => {
          if (result) {
            this.getAnnoucements();
          }
        },
        reason => { },
      );
    });
  }
}
