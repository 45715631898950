<nz-input-group [nzSuffix]="suffixTemplate" [nzPrefix]="prefixTemplateUser">
  <input type="text" nz-input [(ngModel)]="value" [placeholder]="placeholder">
</nz-input-group>
<ng-template #suffixTemplate>
  <i nz-icon
        nz-tooltip
        class="ant-input-clear-icon"
        nzTheme="fill"
        nzType="close-circle"
        *ngIf="value"
        (click)="resetText()"></i>
</ng-template>
<ng-template #prefixTemplateUser><i nz-icon nzType="search"></i></ng-template>
