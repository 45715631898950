<div class="modal-confirm">
  <modal-header [title]="title" [activeModal]="activeModal">
  </modal-header>
  <modal-body>
    <p class="mb-3">{{description}}</p>
  </modal-body>
  <modal-footer>
    <div class="w-100">
      <div class="form-group">
        <div class="float-right">
          <button (click)="cancel()" class="btn btn-cancel">{{ lbCancel}}</button>
          <button (click)="confirm()" class="ml-2 btn btn-primary">{{ lbConfirm }}</button>
        </div>
      </div>
    </div>
  </modal-footer>
</div>
