import { Component, Input, OnInit } from '@angular/core';
import { FileExtensionType } from '@intranet/lib/data-access';

@Component({
    selector: 'app-file-icon',
    templateUrl: './file-icon.component.html',
    styleUrls: ['./file-icon.component.scss'],
})
export class FileIconComponent implements OnInit {
    @Input() documentType: number;
    constructor() { }

    FileExtensionType = FileExtensionType;

    ngOnInit() { }
}
