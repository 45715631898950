import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DepartmentGroupProfile, DepartmentService } from '@intranet/lib/data-access';

@Component({
  selector: 'app-front-group',
  templateUrl: './front-group.component.html',
  styleUrls: ['./front-group.component.scss']
})
export class FrontGroupComponent implements OnInit, OnChanges {
  @Input() groupId = null;
  data: DepartmentGroupProfile;
  avatarUrl = '';

  constructor(private departmentService: DepartmentService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      const groupId = params.groupId;
      if (groupId) {
        this.loadDepartmentProfile(groupId);
      }
    });
  }

  ngOnChanges() {
    if (this.groupId) {
      this.loadDepartmentProfile(this.groupId);
    }
  }


  ngOnInit() {
    this.avatarUrl = '';
  }

  async loadDepartmentProfile(id) {
    this.data = await this.departmentService.getProfile({ id });
  }

}
