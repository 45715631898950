<modal-header [title]="modalHeaderTpl" [activeModal]="activeModal" (close)="closeModal()">
  <ng-template #modalHeaderTpl>
    <div class="modal-title">{{title}}</div>
  </ng-template>
</modal-header>

<modal-body>
  <div class="view-body">
    <div class="section">
      <div class="section-title">
        {{ displayMode === displayModeEnum.Claim ? benefitTitle : "Bonus Value" }}
      </div>

      <div class="benefit-result-summary">
        <div class="decorative-line" [style.color]="benefit?.color"></div>
        <div class="summary">
          <!-- <div class="summary-row">
            <div class="name">Bonus:</div>
            <div class="value benefit-value">
              {{benefitBonus?.name}}
              <ng-container [ngSwitch]="benefitResult.dataType">
                <ng-container *ngSwitchCase="dataTypeEnum.Number">{{benefitResult?.value | currencyFormat}}
                </ng-container>
                <ng-container *ngSwitchDefault>{{benefitResult?.value}}</ng-container>
              </ng-container>
        </div>
      </div> -->
          <div class="summary-row">
            <div class="name">Value:</div>
            <div class="value benefit-value">
              {{benefitResult?.name}}
              <!-- <ng-container [ngSwitch]="benefitResult.dataType">
                <ng-container *ngSwitchCase="dataTypeEnum.Number">{{benefitResult?.value | currencyFormat}}
                </ng-container>
                <ng-container *ngSwitchDefault>{{benefitResult?.value}}</ng-container>
              </ng-container> -->
            </div>
          </div>
          <!-- <div class="summary-row">
            <div class="name">Catelog:</div>
            <div class="value">
              <nz-tag class="mr0">{{benefitResult?.benefitCategory?.name}}</nz-tag>
            </div>

            <ng-container *ngIf="isMobileView; then policyLinkTpl"></ng-container>
          </div> -->
        </div>

        <ng-template #policyLinkTpl let-mobile>
          <div class="policy" *ngIf="benefitResult?.policy?.policyId" [ngClass]="{
            'mr-auto': !isMobileView, 'ml-auto': isMobileView}">
            <a href="#" (click)="openPolicy()" nz-tooltip [nzTooltipTitle]="benefitResult.policy.title">Policy Link</a>
          </div>
        </ng-template>
        <ng-container *ngIf="!isMobileView; then policyLinkTpl"></ng-container>
      </div>

      <div class="view-detail-link" *ngIf="displayMode === displayModeEnum.Claim">
        <a href="#" (click)="setView(displayModeEnum.View)">View Benefit Detail</a>
      </div>
    </div>

    <ng-container *ngIf="displayMode === displayModeEnum.Claim" [ngTemplateOutlet]="claimBenefitTpl"></ng-container>
    <ng-container *ngIf="displayMode === displayModeEnum.View" [ngTemplateOutlet]="viewBenefitTpl"></ng-container>
  </div>
</modal-body>

<modal-footer>
  <button class="close-btn btn btn-white" (click)="closeModal()" nz-tooltip nzTooltipTitle="Close">
    <i nz-icon nzType="close-circle" nzTheme="outline"></i> Close
  </button>

  <button *ngIf="displayMode === displayModeEnum.Claim" class="submit-btn btn btn-primary" (click)="claim()" nz-tooltip nzTooltipTitle="Claim this benefit" nz-button
    [disabled]="!benefitResult?.registrable">
    <i nz-icon nzType="save" nzTheme="outline"></i> Submit
  </button>

  <button *ngIf="displayMode === displayModeEnum.View && benefitResult?.registrable" class="claim-btn btn btn-primary" (click)="setView(displayModeEnum.Claim)" nz-tooltip
    nzTooltipTitle="Switch to claim benefit page" nz-button [disabled]="!benefitResult?.registrable">
    <i nz-icon nzType="form" nzTheme="outline"></i> Claim Benefit
  </button>
</modal-footer>

<ng-template #claimBenefitTpl>
  <div class="section">
    <!-- <div class="input-row">
      <label>Attached file <span class="optional"></span></label>
      <app-internal-upload-file #uploadFiles [model]="modelUpload" [disabled]="!benefitResult?.registrable">
      </app-internal-upload-file>
    </div> -->

    <div class="input-row">
      <label>Bill/Invoice value <span class="optional"></span></label>
      <nz-input-number [nzPlaceHolder]="'1,000,000'" [nzFormatter]="formatterMoney" [nzParser]="parserMoney" [(ngModel)]="userValue"
        [ngModelOptions]="{ standalone: true }" [nzMin]="0" [nzStep]="1">
      </nz-input-number>
    </div>
    <div class="input-row">
      <label>Note <span class="optional"></span></label>
      <textarea nz-input [nzAutosize]="noteTextAreaAutosize" name="user-note" [(ngModel)]="userNote" placeholder="Note" class="user-note"
        [disabled]="!benefitResult?.registrable"></textarea>
    </div>
  </div>
</ng-template>

<ng-template #viewBenefitTpl>
  <div class="section" *ngIf="benefitResult?.description">
    <div class="section-title">Benefit Description</div>
    <!-- <p class="desc-text benefit-desc" *ngIf="benefit?.description">{{benefit.description}}</p> -->
    <!-- <p class="desc-text benefit-bonus-desc" *ngIf="benefitBonus?.description">{{benefitBonus.description}}</p> -->
    <p class="desc-text benefit-result-desc" *ngIf="benefitResult?.description">{{benefitResult.description}}</p>
    <div class="benefit-img" *ngIf="benefit?.imageUrl">
      <img [src]="benefit.imageUrl" [alt]="benefitTitle">
    </div>
    </div>
    <ng-container *ngIf="benefit?.registrationType != registrationTypeEnum.None">

      <div class="section">
        <div class="section-title">
          Registration Histories
        </div>

        <nz-table #basicTable [nzData]="histories" [nzShowPagination]="false" *ngIf="!isMobileView; else viewHistoriesMobileTpl" class="history-desktop">
          <thead>
            <tr class="history-header-row">
              <th class="history-header-cell" nzWidth="150px">Registration No.</th>
              <th class="history-header-cell">Date Created</th>
              <th class="history-header-cell">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of histories; let idx = index" class="history-data-row">
              <td class="history-id">#{{ idx + 1 | number:'3.0-0' }}</td>
              <td class="history-date">{{ row.createdDate | date:'EEEE, MMMM dd, yyy HH:mm:ss' }}</td>
              <td class="history-status">
                <div class="dot {{requestStatusDisplayEnum[row.status].colorClass}}"></div>
                {{ requestStatusDisplayEnum[row.status].name }}
              </td>
            </tr>
          </tbody>
        </nz-table>

        <ng-template #viewHistoriesMobileTpl>
          <div class="history-mobile">
            <div class="history-record" *ngFor="let row of histories; let idx = index">
              <div class="history-row">
                <div class="key">Registration No.</div>
                <div class="value registration-no">#{{ idx + 1 | number:'3.0-0' }}</div>
              </div>
              <div class="history-row">
                <div class="key">Status</div>
                <div class="value">
                  <div class="dot {{requestStatusDisplayEnum[row.status].colorClass}}"></div>
                  {{ requestStatusDisplayEnum[row.status].name }}
                </div>
              </div>
              <div class="history-sep-line"></div>
              <div class="history-date">{{ row.createdDate | date:'EEEE, MMMM dd, yyy HH:mm:ss' }}</div>
            </div>

            <nz-empty *ngIf="!histories?.length"></nz-empty>
          </div>
        </ng-template>
      </div>
    </ng-container>

</ng-template>
