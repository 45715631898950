import { Component, OnInit, Input, EventEmitter, OnDestroy, HostListener, AfterViewInit } from '@angular/core';
import { WidgetSearchDto, WidgetWorkExperienceDto, WidgetService, MemberDto, MemberDisplayMode, MultiChartDto, DepartmentGroupChartDto } from '@intranet/lib/data-access';
import { Subscription } from 'rxjs';
import { GridsterItem } from 'angular-gridster2';
import { WebsiteEvent } from '@intranet/lib/settings/event-store/website.event';
import * as moment from 'moment';
import { CommonHelper } from '../../../helpers/commonHelper';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
@Component({
  selector: 'app-widget-work-experience',
  templateUrl: './widget-work-experience.component.html',
  styleUrls: ['./widget-work-experience.component.scss'],
})
export class WidgetWorkExperienceComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() widget;
  @Input() resizeEvent: EventEmitter<GridsterItem>;
  resizeSub: Subscription;
  @Input() displayMode: MemberDisplayMode = MemberDisplayMode.UserWidget;
  @Input() widgetLoaded: EventEmitter<any>;

  loading = true;
  dataSelect: any;
  searchData: WidgetSearchDto = new WidgetSearchDto();
  data: WidgetWorkExperienceDto = new WidgetWorkExperienceDto();
  dataDetail: MemberDto[];
  isDetail = false;
  title: string;
  MemberDisplayMode = MemberDisplayMode;

  isViewAll = false;
  allMembers: MemberDto[] = [];
  seniorityName = 'Seniority';
  legendColor = {
    seniority: '',
    experience: '',
  };

  isMobileView = CommonHelper.isMobileView();
  widgetContentId = 'widget-work-experience-content-id';
  widgetContentEl: HTMLElement;
  widgetDisplayData: MultiChartDto<DepartmentGroupChartDto>;

  get chartMobileView() {
    return this.widgetContentEl
      ? [this.widgetContentEl.offsetWidth, this.widgetDisplayData?.view?.[1]]
      : this.widgetDisplayData?.view;
  }

  selectGroupSubscription: Subscription;

  get isClientPage() {
    return this.displayMode === this.MemberDisplayMode.ClientPage;
  }

  constructor(private _widgetService: WidgetService,
    private websiteEvent: WebsiteEvent) { }

  ngOnDestroy() {
    if (this.selectGroupSubscription) {
      this.selectGroupSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.searchData.clientMode = this.isClientPage;
    this.searchData.noLoading = this.isClientPage;
    this.selectGroupSubscriptionEvent();
  }

  ngAfterViewInit() {
    CommonHelper.scheduleToExecute(() => this.updateMobileViewData());
  }

  selectGroupSubscriptionEvent(callback: () => void = null) {
    this.selectGroupSubscription = this.websiteEvent.groupSourceDataEvent.subscribe((departmentId: any) => {
      if (!this.searchData.departmentIds || departmentId === '') {
        this.searchData.departmentIds = departmentId !== '' ? departmentId : null;
        this.loadData();
      }
    });
  }

  onSelect(item) {
    this.viewDetail(item);
  }
  setData(data) {
    const dataConvert = JSON.parse(JSON.stringify(data));
    this.dataSelect = dataConvert.entries ? dataConvert.value : dataConvert;
  }

  async loadData() {
    this.loading = true;
    try {
      this.data = await this._widgetService.getWidgetWorkExperience(this.searchData);
      // if (innerWidth < 640) {
      //   this.data.companyExperience.view = [innerWidth / 1.2, 400];
      //   this.data.companyExperience.legendPosition = 'below';
      // }
      this.backupWidgetDisplayData();
      this.updateMobileViewData();

      this.loading = false;
      this.widgetLoaded?.emit(this.widget);

      if (this.isClientPage) {
        this.filterLegendData();
        this.legendColor.experience = this.data.companyExperience.colorScheme?.domain[0];
        this.legendColor.seniority = this.data.companyExperience.colorScheme?.domain[1];
        this.viewAll();
      }
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
    finally {
      this.loading = false;
    }
  }

  modelChanged(event) {
    this.searchData.departmentIds = event;
    this.loadData();
    if (event !== null) {
      this.websiteEvent.setGroupSourceDataEvent(event);
    }
  }
  viewDetail(item) {
    if (item.data) {
      this.isViewAll = false;
      this.dataDetail = JSON.parse(item.data);

      this.dataDetail.forEach(d => {
        d.joinedDate = moment(d.joinedDate).format('DD/MM/YYYY');
        (d.totalYearsOfExperience as any) = d.totalYearsOfExperience + (d.totalYearsOfExperience > 1 ? ' years' : ' year');
      });

      this.dataDetail = CommonHelper.sort(this.dataDetail, d => d.fullName.removeVietnamese());

      this.title = item.name + ' ' + item.series + ' (' + item.value + ')';
      this.isDetail = true;
    }
  }
  hideDetail() {
    this.isDetail = false;
    CommonHelper.scheduleToExecute(() => this.updateMobileViewData());
  }

  viewAll() {
    this.isViewAll = true;
    this.title = `all members (${this.allMembers?.length || 0})`;
    this.dataDetail = this.allMembers;
  }

  filterLegendData() {
    const chartData = this.data.companyExperience.chartDatas;
    const seniorityList: MemberDto[] = CommonHelper.flatten(chartData.map(cd =>
      CommonHelper.flatten(cd.series
        .filter(s => s.name === this.seniorityName)
        .map(s => JSON.parse(s.data))
        .filter(s => s?.length > 0)
      )
    ));

    this.allMembers = [];
    for (const data of seniorityList) {
      const isExist = this.allMembers.find(s => s.employeeId === data.employeeId);
      if (!isExist) {
        this.allMembers.push(this.formatMemberDto(data));
      }
    }

    this.allMembers = CommonHelper.sort(this.allMembers, m => m.fullName.removeVietnamese());
  }

  private formatMemberDto(member: MemberDto): MemberDto {
    member.joinedDate = moment(member.joinedDate).format('DD/MM/YYYY');
    (member.totalYearsOfExperience as any) = member.totalYearsOfExperience + (member.totalYearsOfExperience > 1 ? ' years' : ' year');
    return member;
  }

  @HostListener('window:resize')
  updateMobileViewData() {
    this.isMobileView = CommonHelper.isMobileView();
    if (!this.isMobileView || !this.data) {
      this.restoreWidgetDisplayData();
      return;
    }

    CommonHelper.scheduleToExecute(() => {
      if (!this.widgetContentEl) {
        this.widgetContentEl = document.getElementById(this.widgetContentId);
      }

      if (this.data?.companyExperience) {
        this.data.companyExperience.view = this.chartMobileView;
        this.data.companyExperience.legendPosition = 'bottom';
      }
    });
  }

  private backupWidgetDisplayData() {
    if (!this.data?.companyExperience) {
      return;
    }

    this.widgetDisplayData = { ...this.data.companyExperience };
  }

  private restoreWidgetDisplayData() {
    if (!this.widgetDisplayData || !this.data?.companyExperience) {
      return;
    }

    this.data.companyExperience = { ...this.widgetDisplayData };
  }
}
