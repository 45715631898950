export enum DocumentTypeEnum {
  PROJECT = 1,
}

export enum FileExtensionType {
  UNKNOWN = 0,
  IMAGE = 1,
  PDF = 2,
  EXCEL = 3,
  WORD = 4,
  POWERPOINT = 5,
  VIDEO = 6,
}
