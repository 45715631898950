import { AppInjector } from './app-injector';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { WebHelper } from '@intranet/lib/helpers';
import { Subject } from 'rxjs';

export class Toastr {
  static message(code: number, messsage: string) {
    if (code > 0) {
      Toastr.success(messsage);
    } else {
      Toastr.error(messsage);
    }
  }

  static showMessageData(result, actionSuccess = null, actionFail = null) {
    if (result.id > -1) {
      Toastr.success(result.message);
      if (actionSuccess) {
        actionSuccess();
      }
      return;
    }
    if (actionFail) {
      actionFail();
    }
    Toastr.error(result.message);
  }
  static success(msg, position = 'topRight') {
    if (msg && typeof msg === 'string') {
      const translateService = AppInjector.getService<TranslateService>(TranslateService);
      const notification = AppInjector.getService<NzNotificationService>(NzNotificationService);
      // toastr.options = {
      //   positionClass: position,
      // };
      const translatedMessage = translateService.instant(msg);
      if (msg !== translatedMessage) {
        notification.create('success', 'Success', translatedMessage);
      } else {
        notification.create('success', 'Success', msg);
      }
    }
  }

  static showMessage(title, msg, link = '') {
    if (msg && typeof msg === 'string') {
      const translateService = AppInjector.getService<TranslateService>(TranslateService);
      const notification = AppInjector.getService<NzNotificationService>(NzNotificationService);
      const translatedMessage = translateService.instant(msg);
      let result;
      if (msg !== translatedMessage) {
        result = notification.create('success', title, translatedMessage);
      } else {
        result = notification.create('success', title, msg);
      }
      if (result) {
        if (link) {
          result.onClick.subscribe(value => {
            window.open(link, '_blank');
          });
        }
      }
    }
  }

  static warning(msg, position = 'topRight') {
    if (msg && typeof msg === 'string') {
      const translateService = AppInjector.getService<TranslateService>(TranslateService);
      const notification = AppInjector.getService<NzNotificationService>(NzNotificationService);
      // toastr.options = {
      //   positionClass: position,
      // };
      const translatedMessage = translateService.instant(msg);
      if (msg !== translatedMessage) {
        notification.create('warning', 'Warning', translatedMessage);
      } else {
        notification.create('warning', 'Warning', msg);
      }
    }
  }
  static info(msg, position = 'topRight') {
    if (msg && typeof msg === 'string') {
      const translateService = AppInjector.getService<TranslateService>(TranslateService);
      const notification = AppInjector.getService<NzNotificationService>(NzNotificationService);
      // toastr.options = {
      //   positionClass: position,
      // };
      const translatedMessage = translateService.instant(msg);
      if (msg !== translatedMessage) {
        notification.create('info', 'Infomation', translatedMessage);
      } else {
        notification.create('info', 'Infomation', msg);
      }
    }
  }
  static error(msg, position = 'topRight') {
    msg = WebHelper.getErrorMessage(msg);
    if (msg && typeof msg === 'string') {
      const translateService = AppInjector.getService<TranslateService>(TranslateService);
      const notification = AppInjector.getService<NzNotificationService>(NzNotificationService);
      const translatedMessage = translateService.instant(msg);
      if (msg !== translatedMessage) {
        notification.create('error', 'Error', translatedMessage);
      } else {
        notification.create('error', 'Error', msg);
      }
    }
  }
}
