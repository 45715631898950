export class PubSubEvent {
  static readonly EVENTS = {
    ON_CREATE_FEED: 'on_create_feed',
    ON_DELETE_FEED: 'on_delete_feed',
    ON_UPDATE_FEED: 'on_update_feed',

    ON_CREATE_ANNOUNCEMENT: 'on_create_announcement',
    ON_CREATE_TICKET: 'on_create_ticket',

    ON_CREATE_COMMENT: 'on_create_comment',
    ON_DELETE_COMMENT: 'on_delete_comment',
    ON_UPDATE_COMMENT: 'on_update_comment',

    ON_UPDATE_POLL: 'on_update_poll',

    ON_REFRESH_SURVEY: 'on_refresh_surveys',

    TOGGLE_LEFT_MENU: 'toggle_left_menu',
    TOGGLE_RIGHT_MENU: 'toggle_right_menu',

    TOGGLE_LEFT_PANEL: 'toggle_left_panel',
    TOGGLE_RIGHT_PANEL: 'toggle_right_panel',

    ON_CLAIM_DAILY_POINTS: 'on_claim_daily_points',
    ON_SELECT_MISSING_HOURS: 'on_select_missing_hours',
  };
}
