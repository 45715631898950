import { Supplier } from './supplier';

export class SupplierEquipment {
  id: string;
  key: string;
  name: string;
  note: string;
  supplierId: string;
  supplier: Supplier;
  equipmentCategoryId: string;
  equipmentCategory: string;
  price: number;
  productId: string;
}

export class EquipmentQuotationModel {
  supplierId: string;
  file: File;
}
