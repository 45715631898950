import { ProjectItemRelationEnum, ProjectItemTypeEnum } from './enums/project.enum';

export class ProjectItemRelationDto {
  projectItemRelationId: string;
  mainItemId: string;
  relatedItemId: string;
  relationType: ProjectItemRelationEnum;
  comment: string;
}

export class ProjectItemRelationListDto extends ProjectItemRelationDto {
  mainItemTitle: string;
  mainItemType: ProjectItemTypeEnum;
  mainItemState: number;
  mainItemStateLabel: string;
  relatedItemTitle: string;
  relatedItemType: ProjectItemTypeEnum;
  relatedItemState: number;
  relatedItemStateLabel: string;
}
