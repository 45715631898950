import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { UserTier } from '../models/user-model';
import { CDNService } from './cdn-server.service';

@Injectable({
  providedIn: 'root',
})
export class UserTierService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/user-tier';

  constructor(httpClient: HttpClientService, private cdnService: CDNService) {
    super(httpClient);
  }

  view(scb?: any, fcb?: any, ccb?: any) {
    return this.getAll({}, scb, fcb, ccb);
  }

  getImageUrl(tier: UserTier) {
    if (tier && tier.imageId) {
      tier.imageLocation = `${this.cdnService.hostUrl}/download/${tier.imageId}`;
      return tier;
    }

    return tier;
  }
}
