import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { SecurityHelper } from '../helpers/securityHelper';

class Role {
  controller: string;
  action: string;
  expect: boolean;
  department: string;
}
@Directive({
  selector: '[hasOneOfPermissions]',
})
export class HasOneOfPermissionsDirective {
  private controller: string;
  private action: string;
  private department: string;
  private expect: boolean;
  private actions: string[];

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) { }

  @Input()
  set hasOneOfPermissions(val) // val need to follow format: ['controller', ['action1, action 2,...']]
  {
    if (typeof val !== 'object' || !val || val.length === 0) {
      return;
    }
    this.controller = val[0];
    this.actions = val[1];
    if (!this.actions) {
      return;
    }

    const result = this.actions.some(item => {
      this.action = item;
      return this._checkPermission();
    });

    if (result) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private _checkPermission() {
    if (this.controller && this.action) {
      const result = SecurityHelper.checkPermission(this.controller, this.action, this.department);
      if (this.expect) {
        if (result === this.expect) {
          return false;
        }
        return true;
      } else {
        return result;
      }
    }
    return true;
  }
}
