import { CompensationHourTypeEnum } from './enums/compensation-hour.enum';

export class CompensationHour {
  id: string;
  date: Date;
  startTime: string;
  endTime: string;
  minimumWorkTime: number;    // float
  type: CompensationHourTypeEnum;
  isDeletable: boolean;       // DTO
  hasUserRegistered: boolean; // DTO
  departments: any[];         // DTO: List<CommonModel>
  compensatingMonth?: Date;
  totalCount?: number;
}

export class CompensationView {
  data: CompensationHour[];
  departments: any[];
}

export class SearchCompensationHour {
  startDate: Date;
  endDate: Date;
  pageNumber: number;
  pageSize: number;
}

export class SearchCompensationUser {
  compensationHourId: string;
  pageNumber: number;
  pageSize: number;

  constructor() {
    this.pageNumber = 1;
    this.pageSize = 15;
  }
}
