import { BaseModel } from './base-model';
import { EmployeeEquipment } from './employee-equipment';
import { UserModel } from './user-model';

export class Deliverform extends BaseModel {
  public userId?: string;
  public purchaseRequestId?: string;
  public purchaseRequestNo?: string;
  public purchaseRequestDetailId?: string;
  public no?: string;
  public quantity?: number;
  public to?: number;
  public isSignature?: boolean;
  public qrCode?: string;
  public employeeEquipments?: EmployeeEquipment[];
  public user?: UserModel;
  public deliveryNo?: string;
  public totalItem?: number;
  public avaliable?: number;
  public file?: string;
  public isPublic?: boolean;
  public expaned?: boolean;
  public status?: number;
  public note?: string;
}
