<div class="rt-panel-item">
  <div class="row align-items-center title-panel">
    <div class="col">
      <p>Org Chart</p>
    </div>
  </div>
  <div class="rt-panel-item-content">
    <nz-skeleton *ngIf="!item "></nz-skeleton>
    <div *ngIf="item">
      <organization-chart chartClass="myChart" [groupScale]="groupScale" [datasource]="item" [nodeTemplate]="myNodeTemplate">
      </organization-chart>
      <ng-template #myNodeTemplate let-nodeData="datasource">
        <div class="box" (click)="selectNode(nodeData)">
          <div [style]="{
                width: '100%',
                height: '4px',
                borderRadius: '5px',
                backgroundColor: nodeData.color
             }">

          </div>
          <div class="img">
            <app-avatar [size]="60" [avatarSource]="nodeData?.imageUrl" [avatarText]="nodeData?.name">
            </app-avatar>
          </div>
          <div class="fullname">
            <a *ngIf="nodeData.type === 1" [routerLink]="['/groups/discussion']"
              [queryParams]="{groupId: nodeData.data.departmentId}"> {{nodeData.name}}</a>
            <a *ngIf="nodeData.type === 2" [routerLink]="[urlConstant.PROFILE_PAGE]"
              [queryParams]="{employeeId: nodeData.data.employeeId}"> {{nodeData.name}}</a>
          </div>
          <div *ngIf="nodeData.type === 1" class="position">
            {{nodeData.countChild}} Members
          </div>
          <div *ngIf="nodeData.type === 2" class="position">
            {{nodeData.title}}
          </div>
        </div>
      </ng-template>

    </div>
  </div>
</div>
