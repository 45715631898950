import { Component, Input } from '@angular/core';
import { UrlConstant } from '@intranet/lib/config';
import { MemberDto, WidgetSearchDto, MemberDisplayMode } from '@intranet/lib/data-access';

@Component({
  selector: 'app-widget-work-experience-detail',
  templateUrl: './widget-work-experience-detail.component.html',
  styleUrls: ['./widget-work-experience-detail.component.scss']
})
export class WidgetWorkExperienceDetailComponent {
  @Input()
  searchData: WidgetSearchDto = new WidgetSearchDto();
  @Input()
  data: MemberDto[];
  @Input() displayMode = MemberDisplayMode.UserWidget;
  @Input() mobileView = false;
  loading = true;
  isDetail = false;
  urlConstant = UrlConstant;

  get isClientPage() {
    return this.displayMode === MemberDisplayMode.ClientPage;
  }

  openInformation(item) {
    if (this.isClientPage) {
      return;
    }

    const url = this.urlConstant.PROFILE_PAGE + '?employeeId=' + item.employeeId;
    window.open(
      url,
      '_blank'
    );
  }
}
