import * as _ from 'lodash';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  FeedRealtimeService,
  PollRealtimeService,
  NewsFeedTypeEnum,
  NewsFeed, Survey,
  SearchWallNewsFeed, SearchModel, WallNewsFeedService, SurveyService, LeaveTransferService,
} from '@intranet/lib/data-access';
import { ModalService, ModalSize, UrlConstant } from '@intranet/index';
import { SurveyModalComponent } from '../../components/survey-modal/survey-modal.component';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Constants, PubSubEvent } from '@intranet/index';
import { PubSubService } from '@intranet/lib/services';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import { LeaveTransferModalComponent } from '@intrafront/components/leave-transfer-modal/leave-transfer-modal.component';
import * as moment from 'moment';
import { ChristmasGameService } from '@intranet/lib/data-access/services/christmas-game.service';
import { PosterDisplayModalComponent } from '@intranet/lib/components/poster-display-modal/poster-display-modal.component';
import { GameHelper } from '@intranet/lib/helpers/gameHelper';

@Component({
  selector: 'app-newsfeed-wall',
  templateUrl: './wall.component.html',
  styleUrls: ['./wall.component.scss'],
})
export class WallPageComponent implements OnInit {
  @ViewChild('surveyModal') surveyModal: ElementRef;

  openingSurveyModal: NgbModalRef;

  year = new Date().getFullYear();

  feeds: NewsFeed[];
  stickies: NewsFeed[];
  surveys: Survey[];
  requiredSurvey: Survey;

  queryFeeds: SearchWallNewsFeed = new SearchWallNewsFeed();
  queryStickies: SearchWallNewsFeed = new SearchWallNewsFeed();
  querySurveys: SearchModel = new SearchModel();
  disabledLoading: boolean;
  posterDisplayInfo: any;

  feedIsLoading: boolean;
  stickyIsLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private wallNewsFeedService: WallNewsFeedService,
    private surveyService: SurveyService,
    private feedRealtimeService: FeedRealtimeService,
    private pollRealtimeService: PollRealtimeService,
    private modalService: ModalService,
    private pubsub: PubSubService,
    private leaveTransferService: LeaveTransferService,
    private christmasGameService: ChristmasGameService,
  ) {
    this.disabledLoading = true;
    this.route.queryParams.subscribe(params => {
      if (params && params.keyWord && params.keyWord !== '') {
        this.queryFeeds.keyWord = params.keyWord;
        this.queryStickies.keyWord = params.keyWord;
      }
    });
  }

  ngOnInit() {
    this.pubsub.$sub(PubSubEvent.EVENTS.ON_CREATE_FEED).subscribe((feed: NewsFeed) => {
      if (!this.feeds) {
        this.feeds = [];
      }
      const feedIndex = _.findIndex(this.feeds, item => feed.id === item.id);
      if (feedIndex < 0) {
        this.feeds.unshift(feed);
      }
    });

    this.feedRealtimeService.startConnection();
    this.registerFeedRealtime();
    this.registerCommentRealtime();

    this.pollRealtimeService.startConnection();
    this.pollRealtimeService.addTransferResultListener((data: any) => {
      this.pubsub.$pub(PubSubEvent.EVENTS.ON_UPDATE_POLL, data);
    });

    this.queryFeeds.noLoading = true;
    this.queryFeeds.pageSize = 3;
    this.queryFeeds.pageNumber = 1;

    this.queryStickies.noLoading = true;
    this.queryStickies.pageSize = 5;
    this.queryStickies.pageNumber = 1;

    this.querySurveys.pageNumber = 1;
    this.querySurveys.pageSize = 10;

    this.load();
  }

  registerCommentRealtime() {
    this.feedRealtimeService.addTransferCommentListener((comment: any) => {
      this.pubsub.$pub(PubSubEvent.EVENTS.ON_CREATE_COMMENT + comment.newsFeedId, comment);
    });

    this.feedRealtimeService.addTransferCommentUpdatedListener((comment: any) => {
      this.pubsub.$pub(PubSubEvent.EVENTS.ON_UPDATE_COMMENT + comment.newsFeedId, comment);
    });

    this.feedRealtimeService.addTransferCommentDeletedListener((comment: any) => {
      this.pubsub.$pub(PubSubEvent.EVENTS.ON_DELETE_COMMENT + comment.newsFeedId, comment);
    });
  }

  registerFeedRealtime() {
    this.feedRealtimeService.addTransferResultListener((feed: NewsFeed) => {
      if (
        feed.newsFeedTypeId === NewsFeedTypeEnum.POLL ||
        feed.newsFeedTypeId === NewsFeedTypeEnum.POLL_MULTIPLE_QUESTION ||
        feed.newsFeedTypeId === NewsFeedTypeEnum.WALL
      ) {
        const feedIndex = _.findIndex(this.feeds, item => feed.id === item.id);

        if (feedIndex < 0) {
          const feedIndex = _.findIndex(this.feeds, item => !item.isImportant);
          if (feedIndex >= 0) {
            this.feeds.splice(feedIndex, 0, feed);
          }
        }
      } else if (feed.newsFeedTypeId === NewsFeedTypeEnum.ANNOUNCEMENT) {
        this.pubsub.$pub(PubSubEvent.EVENTS.ON_CREATE_ANNOUNCEMENT, feed);
      }
    });

    this.feedRealtimeService.addTransferResultUpdatedListener((feed: NewsFeed) => {
      if (
        feed.newsFeedTypeId === NewsFeedTypeEnum.POLL ||
        feed.newsFeedTypeId === NewsFeedTypeEnum.POLL_MULTIPLE_QUESTION ||
        feed.newsFeedTypeId === NewsFeedTypeEnum.WALL
      ) {
        const feedIndex = _.findIndex(this.feeds, item => feed.id === item.id);
        if (feedIndex < 0) {
          this.feeds[feedIndex] = feed;
        }
      } else if (feed.newsFeedTypeId === NewsFeedTypeEnum.ANNOUNCEMENT) {
        this.pubsub.$pub(PubSubEvent.EVENTS.ON_UPDATE_FEED, feed);
      }
    });

    this.feedRealtimeService.addTransferResultDeletedListener((feed: NewsFeed) => {
      if (
        feed.newsFeedTypeId === NewsFeedTypeEnum.POLL ||
        feed.newsFeedTypeId === NewsFeedTypeEnum.POLL_MULTIPLE_QUESTION ||
        feed.newsFeedTypeId === NewsFeedTypeEnum.WALL
      ) {
        _.remove(this.feeds, item => feed.id === item.id);
      } else if (feed.newsFeedTypeId === NewsFeedTypeEnum.ANNOUNCEMENT) {
        this.pubsub.$pub(PubSubEvent.EVENTS.ON_DELETE_FEED, feed);
      }
    });
  }

  onCreateFeed(feed: NewsFeed) {
    this.feeds.unshift(feed);
  }

  getWall() {
    this.feedIsLoading = true;
    this.disabledLoading = true;

    this.wallNewsFeedService.getWall(
      this.queryFeeds,
      (resp: any) => {
        if (resp && resp.length) {
          this.disabledLoading = false;
          if (!this.feeds) {
            this.feeds = resp;
          } else {
            this.feeds = this.feeds.concat(resp);

            // resp.forEach(feed => {
            //   this.feeds.push(feed);
            // });
          }
          this.feeds.sort((a, b) => {
            return a.isImportant === b.isImportant ? (a.createdDate > b.createdDate ? -1 : 1) : (a.isImportant > b.isImportant ? -1 : 1);
          });
          this.queryFeeds.totalItems = resp[0].totalCount;
        } else {
          this.disabledLoading = true;
        }
      },
      (error: any) => { },
      () => {
        // this.disabledLoading = true;
        this.feedIsLoading = false;
      },
    );
  }

  getStickies() {
    this.stickyIsLoading = true;
    this.wallNewsFeedService.getStickies(
      this.queryStickies,
      (resp: any) => {
        if (resp && resp.length) {
          if (!this.stickies) {
            this.stickies = resp;
          } else {
            resp.forEach(feed => {
              this.stickies.push(feed);
            });
          }
        }
      },
      error => { },
      () => {
        this.stickyIsLoading = false;
      },
    );
  }

  load() {
    this.getWall();
    // this.getStickies();
    this.getSurveys();
    this.leaveTransferPopup();
    this.checkAndDisplayChristmasGamePopup();
  }

  async leaveTransferPopup() {
    try {
      const displayInfo = await this.leaveTransferService.checkDisplayPopup();

      if (displayInfo?.isShow) {
        const modal = this.modalService.open(LeaveTransferModalComponent, { size: Constants.LeaveTransferModalSize });
        modal.componentInstance.displayInfo = displayInfo;
      }
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
  }

  async checkAndDisplayChristmasGamePopup() {
    try {
      const options = {
        eventTimeOnly: true,
      };
      const displayInfo = await this.christmasGameService.getOrnaments(options);

      this.posterDisplayInfo = GameHelper.getPosterDisplayInfo(displayInfo);
    } catch (err) {
      ErrorHelper.displayMessage(err);
    }
  }

  closePoster() {
    this.posterDisplayInfo = null;
  }

  onLoadMore() {
    this.queryFeeds.pageNumber++;
    this.getWall();
  }

  getSurveys() {
    if (!this.surveys) {
      this.surveyService.getUserSurveys(
        this.querySurveys,
        res => {
          if (res && res.length) {
            this.surveys = res;
            this.showSurveySelection(res);
          }
        },
        err => { },
        () => { },
      );
    }
  }

  showSurveySelection(surveys) {
    this.requiredSurvey = surveys.find(item => item.requireAnswer);
    if (this.requiredSurvey) {
      this.openingSurveyModal = this.modalService.open(SurveyModalComponent, {
        windowClass: 'survey-modal',
        size: ModalSize.xl,
      });
      this.openingSurveyModal.componentInstance.survey = this.requiredSurvey;
      this.openingSurveyModal.componentInstance.onClose = () => {
        this.openingSurveyModal.close();
      };
      this.openingSurveyModal.result.then((resp: any) => { });
    } else {
      // TODO: no require answer
    }
  }
}
