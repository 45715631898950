<ng-container *ngIf="isClientPage else userTemplate">
  <app-members-view class="client-widget-item" [memberData]="data" noDataText="Please select a column to view details." 
    [extraInfoColumnTemplate]="memberSkillsTpl" extraInfoColumnName="Tech stack">
  </app-members-view>
</ng-container>

<ng-template #userTemplate>
  <ng-container *ngIf="!mobileView">
    <app-display-member-template [memberData]="data" [template]="experience"></app-display-member-template>
  </ng-container>
  <ng-container *ngIf="mobileView">
    <div class="widget-work-experience-detail-wrapper">
      <div class="title">
        <ng-container *ngIf="data?.length > 0">
          <div class="title-experience">Experience</div>
          <div class="title-seniority">Seniority</div>
        </ng-container>
      </div>
    
      <div class="member-data">
        <div class="no-member" *ngIf="!data?.length">
          <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="noMemberTpl"></nz-empty>
          <ng-template #noMemberTpl>
            <span class="no-member-text">No data</span>
          </ng-template>
        </div>
    
        <virtual-scroller *ngIf="data?.length > 0" #scroll [items]="data" class="scroller" [style.height.px]="data.length * 80" [bufferAmount]="7">
          <div class="member-row-data" *ngFor="let member of scroll.viewPortItems; let idx = index">
            <div class="member-avatar">
              <app-avatar [size]="40" [(avatarSource)]="member.avatarUrl" [(avatarText)]="member.fullName"> </app-avatar>
            </div>
    
            <div (click)="openInformation(member)" class="font-12 member-information">
              <p class="font-weight-bold">{{member.fullName}}</p>
              <p class="font-weight-bold">{{member.positionName}}</p>
              <div *ngIf="member.departments">
                <nz-tag *ngFor="let department of member.departments" [nzColor]="'geekblue'">
                  <span [innerHTML]="department.name"></span>
                </nz-tag>
              </div>
            </div>
    
            <div class="member-experience">
              <div class="experience">{{member.totalWorkExperience}}</div>
              <div class="seniority">{{member.totalSeniority}}</div>
            </div>
          </div>
        </virtual-scroller>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #experience let-member="member">
  <p><span class="font-weight-bold">Experience</span> {{member.totalWorkExperience}}</p>
  <p><span class="font-weight-bold">Seniority</span> {{member.totalSeniority}}</p>
</ng-template>

<ng-template #memberSkillsTpl let-member>
  <nz-tag *ngFor="let skill of member.skills" nzColor="green">{{skill}}</nz-tag>
</ng-template>