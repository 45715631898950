import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Loading, ModalOption, ModalService, ModalSize, WebsiteEvent } from '@intranet/lib/settings';
import { SecurityHelper } from '@intranet/lib/helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants, UrlConstant } from '@intranet/index';
import { UserService, SearchNotification, UserModel, NewsFeed, WallNewsFeedService, SearchWallNewsFeed, Department, EventCalendar, NewsFeedTypeEnum, MenuTypeEnum, MenuEventAction, MenuEvent, MenuEventTypeEnum } from '@intranet/lib/data-access';
import { NotificationModel } from '@intranet/lib/data-access/models/notification.model';
import { Subject, Subscription } from 'rxjs';
import { MeetingBookingRoom } from '@intranet/lib/data-access/models/meeting-booking-room';
import { CreateAnnouncementComponent, CreatePostModalComponent } from 'projects/intranet/front/components';
import { EventDetailComponent } from 'projects/intranet/hrm-admin/src/app/components/news-feed/event/event-detail.component';
import { CreateBookingComponent } from 'projects/intranet/hrm-user/pages/user-booking/create-booking/create-booking.component';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { PubSubEvent } from '@intranet/index';
import { PolicyDetail } from '../../../../data-access/models/policy.model';
import { CreateLeaveComponent } from 'projects/intranet/hrm-user/components';
import { ResignFormModalComponent } from '@intranet/lib/components/resign-form-modal/resign-form-modal.component';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-header-front',
  templateUrl: './header-front.component.html',
  styleUrls: ['./header-front.component.scss'],
})
export class HeaderFrontComponent implements OnInit, OnDestroy {
  @Input() isDisplaySearchControl = true;
  @Input() currentUser: any;
  subscriptions: Subscription[] = [];
  search: SearchNotification = new SearchNotification();
  listNotification: NotificationModel[];
  listMessage: NotificationModel[];
  isLoadingSearch = true;

  urlConstant = UrlConstant;
  MenuTypeEnum = MenuTypeEnum;
  menuEventTable: MenuEventAction[] = [
    new MenuEventAction('openResignModal', () => this.openResignModal()),
    new MenuEventAction('logout', () => this.logout()),
  ];

  users: UserModel[];
  feeds: NewsFeed[];
  annoucements: NewsFeed[];
  departments: Department[];
  events: EventCalendar[];
  bookings: MeetingBookingRoom[];
  policies: PolicyDetail[];
  keyWord: string;
  searchData: SearchWallNewsFeed = new SearchWallNewsFeed();
  showSearchResult: boolean;
  delayTimer: any;
  showLeftPanel = false;
  showRightPanel = false;
  resignModalOption: ModalOption = {
    size: ModalSize.lg
  };
  cachedKeyword: string = this.searchData?.keyWord;
  searchSubject$: Subject<void> = new Subject();

  get isExpandedFront() {
    return !!localStorage.getItem('isExpanedFront');
  }

  get isMobileView() {
    return window.innerWidth <= Constants.SmallViewMaxWidthInPixel;
  }

  get isOnPageSearch() {
    return this.router.url.startsWith(UrlConstant.SEARCH_PAGE);
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, private websiteEvent: WebsiteEvent,
    private newsFeedService: WallNewsFeedService, private modalService: ModalService, private pubsub: PubSubService) {
    this.searchData.pageNumber = 1;
    this.searchData.pageSize = Constants.PAGE_SIZE.FEED_SEARCH;

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.keyword) {
        this.searchData.keyWord = params.keyword.trim() ?? '';
      }
    });
  }

  async ngOnInit() {
    this.showLeftPanel = this.isExpandedFront;
    this.isLoadingSearch = false;
    await Promise.all([this.loadNotificationList(), this.loadMessageList()]);
    this.subscriptions.push(this.websiteEvent.notificationHeaderEvent.subscribe(async () => {
      await this.loadNotificationList();
    }));
    this.subscriptions.push(this.websiteEvent.messageHeaderEvent.subscribe(async () => {
      await this.loadMessageList();
    }));
    this.pubsub.$sub(PubSubEvent.EVENTS.TOGGLE_LEFT_PANEL).subscribe(result => {
      this.showLeftPanel = result;
    });

    this.pubsub.$sub(PubSubEvent.EVENTS.TOGGLE_RIGHT_PANEL).subscribe(result => {
      this.showRightPanel = result;
    });

    const searchSubscription = this.searchSubject$
      .pipe(debounceTime(500))
      .subscribe(() => this.searchKeyword());
    this.subscriptions.push(searchSubscription);
  }

  onSearchEnter(e) {
    this.router.navigate(['/search'], { queryParams: { keyword: this.searchData.keyWord } });
  }

  onSearch(): void {
    const isKeywordEmpty = !this.searchData?.keyWord?.length || !this.searchData?.keyWord?.trim().length;

    if (isKeywordEmpty || this.isOnPageSearch) {
      return;
    }

    this.searchSubject$.next();
  }
  logout() {
    Loading.show();
    this.userService.logout().then(res => {
      SecurityHelper.destroyAuthen();
      Loading.hide();
      this.router.navigate(['/login']);
    });
  }

  async loadNotificationList() {
    this.search.pageNumber = 0;
    this.search.pageSize = 10;
    this.search.notificationType = 1;
    this.listNotification = await this.userService.getNotificationList(this.search);
  }
  async loadMessageList() {
    this.search.pageNumber = 0;
    this.search.pageSize = 10;
    this.search.notificationType = 2;
    this.listMessage = await this.userService.getNotificationList(this.search);
  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onAddFeed() {
    this._resetSearchResult();
    // show modal add new feed
    const modal = this.modalService.open(CreatePostModalComponent, {
      size: ModalSize.lg
    });
    modal.componentInstance.activeModal = modal;
    modal.result.then(result => { });
  }

  onAddAnnoucement() {
    this._resetSearchResult();
    // show modal add new annoucement
    const modal = this.modalService.open(CreateAnnouncementComponent, {
      size: ModalSize.lg
    });
    modal.componentInstance.activeModal = modal;
    modal.result.then(resul => {

    });
  }

  onAddEvent() {
    this._resetSearchResult();
    // show modal add new event
    const modal = this.modalService.open(EventDetailComponent, {
      size: ModalSize.lg
    });
    modal.componentInstance.isWallCreating = true;

    modal.result.then(resul => {

    });
  }

  onAddBooking() {
    this._resetSearchResult();
    // show modal add new booking
    const modal = this.modalService.open(CreateBookingComponent, {
      size: ModalSize.lg
    });
    modal.result.then(resul => {

    });
  }

  onAddLeave() {
    this._resetSearchResult();
    // show modal add new leave
    const modal = this.modalService.open(CreateLeaveComponent, {
      size: ModalSize.lg
    });
    modal.result.then(resul => {

    });
  }
  private _resetSearchResult() {
    this.searchData.keyWord = '';
    this.users = [];
    this.feeds = [];
    this.events = [];
    this.bookings = [];
    this.departments = [];
    this.annoucements = [];
  }

  handleToggleLeftPanel() {
    this.showLeftPanel = !this.isExpandedFront;
    if (this.showLeftPanel && this.showRightPanel) {
      this.showRightPanel = !this.showRightPanel;
    }
    this.pubsub.$pub(PubSubEvent.EVENTS.TOGGLE_LEFT_PANEL, this.showLeftPanel);
    this.pubsub.$pub(PubSubEvent.EVENTS.TOGGLE_RIGHT_PANEL, this.showRightPanel);
  }

  handleToggleRightPanel() {
    this.showRightPanel = !this.showRightPanel;
    if (this.showLeftPanel && this.showRightPanel) {
      this.showLeftPanel = !this.showLeftPanel;
    }
    this.pubsub.$pub(PubSubEvent.EVENTS.TOGGLE_LEFT_PANEL, this.showLeftPanel);
    this.pubsub.$pub(PubSubEvent.EVENTS.TOGGLE_RIGHT_PANEL, this.showRightPanel);
  }

  showRight() {
    return this.router.url === '/news-feed';
  }

  openResignModal() {
    const modal = this.modalService.open(ResignFormModalComponent, this.resignModalOption);
    modal.componentInstance.currentUser = this.currentUser;
    modal.result.then((resp: any) => { });
  }

  onClickEvent(menuEvent: MenuEvent) {
    if (menuEvent && menuEvent.onClick) {
      this.menuEventTable
        .filter(et => et.action === menuEvent.onClick && et.eventType === MenuEventTypeEnum.Click)
        .forEach(event => event.fn());
    }
  }

  searchKeyword() {
    if (this.cachedKeyword === this.searchData.keyWord) {
      return;
    }

    this.cachedKeyword = this.searchData.keyWord;

    this.isLoadingSearch = true;

    const unsetLoadingSearch = () => this.isLoadingSearch = false;

    this.newsFeedService.search(this.searchData, (res) => {
      if (res && res.newsFeeds && res.users) {
        this.users = res.users;
        this.feeds = res.newsFeeds.filter(item => item.newsFeedTypeId !== NewsFeedTypeEnum.ANNOUNCEMENT);
        this.annoucements = res.newsFeeds.filter(item => item.newsFeedTypeId === NewsFeedTypeEnum.ANNOUNCEMENT);
        this.events = res.events;
        this.bookings = res.bookings;
        this.departments = res.departments;
        this.policies = res.policies;
        this.showSearchResult = true;
        unsetLoadingSearch();
      }
    },
      unsetLoadingSearch,
      unsetLoadingSearch);
  }

  openEmployeePage(employeeId: string) {
    if (employeeId) {
      const url = `${UrlConstant.PROFILE_PAGE}?employeeId=${employeeId}`;
      window.open(url, '_blank');
    }
  }
}
