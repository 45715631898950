
import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { ReviewRating } from '../../models/review/review-rating-score.model';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class ReviewRatingService extends BaseService {
  private readonly rootUrl = '/api/review-ratings';
  hostUrl: string = AppConfig.settings.API_URL + this.rootUrl;

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getScores() {
    const url = `${this.rootUrl}/scores`;
    return this.httpClient.getObservable(url).toPromise();
  }

  getById(id: string): Promise<ReviewRating> {
    const url = `${this.hostUrl}/${id}`;
    return this.httpClient.getSync(url);
  }

  view(searchData: any): Promise<any> {
    return this.httpClient.getSync(this.hostUrl, { params: searchData });
  }

  create(data: ReviewRating): Promise<ReviewRating> {
    return this.httpClient.postSync(this.hostUrl, data);
  }

  update(data: ReviewRating): Promise<ReviewRating> {
    return this.httpClient.putSync(this.hostUrl, data);
  }

  delete(id: string): Promise<void> {
    const url = `${this.hostUrl}/${id}`;
    return this.httpClient.deleteSync(url);
  }
}

