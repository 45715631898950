import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { Loading } from '@intranet/index';

@Injectable({
  providedIn: 'root',
})
export class EmployeeEquipmentService extends BaseService {
  private readonly rootUrl = '/api/employeeequipment';
  hostUrl: string = AppConfig.settings.API_URL + this.rootUrl;

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getEmployeeEquipments(request: any, isLoading: boolean) {
    return this.httpClient.getObservable(this.rootUrl, request, isLoading).toPromise();
  }

  generateQRCode(params?: any, successCallback?: (params?: any) => void) {
    Loading.show();
    const url = 'generate-qrcode';
    return this.updateUrl(url, params, undefined, successCallback);
  }
  removeEquipment(params?: any, successCallback?: (params?: any) => void) {
    Loading.show();
    const url = 'remove-equipment';
    return this.updateUrl(url, params, undefined, successCallback);
  }
  getByDeliveryForm(params?: any, successCallback?: (params?: any) => void) {
    const url = 'get-delivery-form';
    return this.getByUrl(url, params, successCallback);
  }
  getByDeliveryId(params?: any, successCallback?: (params?: any) => void) {
    const url = 'get-by-deliveryid';
    return this.getByUrl(url, params, successCallback);
  }

  getDeliveryFormByPR(params?: any, successCallback?: (params?: any) => void) {
    const url = 'get-delivery-form-pr';
    return this.getByUrl(url, params, successCallback);
  }

  transfer(request: any) {
    const url = `${this.rootUrl}/${request.id}/transfers`;
    return this.httpClient.postObservable(url, request).toPromise();
  }

  getHistories(employeeEquipmentId: string) {
    const url = `${this.rootUrl}/${employeeEquipmentId}/histories`;
    return this.httpClient.getObservable(url).toPromise();
  }

  addAsset(data: any) {
    const url = `${this.rootUrl}/assets`;
    return this.httpClient.postObservable(url, data).toPromise();
  }

  updateCode(data: any) {
    const url = `${this.rootUrl}/update-code`;
    return this.httpClient.postObservable(url, data).toPromise();
  }
}
