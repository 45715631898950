<modal-body>
  <div class="row">
    <div class="col-12">
      <image-cropper [resizeToWidth]="option.resizeToWidth" [resizeToHeight]="option.resizeToHeight" [hideResizeSquares]="option.hideResizeSquares"
        [transform]="transform" [imageFile]="imageChangedEvent" [maintainAspectRatio]="true" [roundCropper]="option.roundCropper" alignImage="center"
        [aspectRatio]="option.aspectRatio" [resizeToWidth]="option.resizeToWidth" [cropperMinHeight]="option.cropperMinHeight" [cropperMinWidth]="option.cropperMinWidth"
        format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
      </image-cropper>
    </div>
    <!-- <div class="col-6 text-center">
      <img *ngIf="croppedImage" [src]="croppedImage" />
    </div> -->
  </div>
</modal-body>
<modal-footer>
  <div class="row">
    <div class="col-lg-12 text-right">
      <button class="mr-1" type="button" nz-button nzType="primary" (click)="zoomIn()">Zoom in</button>
      <button class="mr-1" type="button" nz-button nzType="primary" (click)="zoomOut()">Zoom out</button>
      <button class="mr-1" type="button" nz-button nzType="primary" (click)="crop()">
        <ng-container *ngIf="cropButtonText">{{cropButtonText}}</ng-container>
        <ng-container *ngIf="!cropButtonText">{{ 'page.common.crop' | translate }}</ng-container>
      </button>
      <button type="button" nz-button nzType="default" (click)="close()">{{ 'page.common.cancel' | translate }}</button>
    </div>
  </div>
</modal-footer>
