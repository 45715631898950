
export enum MenuTypeEnum {
  TopMenu,
  UserSideMenu,
  ClientHeadMenu,
  AdminMainMenu,
}

export enum MenuEventTypeEnum {
  Click
}
