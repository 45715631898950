import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CDNService } from '@intranet/lib/data-access';

@Component({
  selector: 'app-preview-image-modal',
  templateUrl: './preview-image-modal.component.html',
  styleUrls: ['./preview-image-modal.component.scss'],
})
export class PreviewImageModalComponent implements OnInit {
  activeModal: NgbActiveModal;
  @Input() imageId: string;
  constructor(modalRef: NgbActiveModal) {
    this.activeModal = modalRef;
  }

  ngOnInit(): void {}

  closeModal() {
    this.activeModal.close(null);
  }
}
