<section style="background: #262b31;" class="min-height-100vh">
  <app-toolbar></app-toolbar>
  <div class="container-fluid">
    <!-- Navigation start -->
    <app-sidebar [menu]="menu"></app-sidebar>
    <!-- Navigation end -->
    <ng-content></ng-content>
    <footer class="main-footer">© {{ systemName }} {{ currentYear }}. All rights reserved.</footer>
  </div>
</section>
