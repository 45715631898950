import { Component, Input } from '@angular/core';
import { ColumnTemplateTypeEnum } from '@intranet/lib/data-access';
import { ReviewSectionType } from '@intranet/lib/data-access/models/enums/review-section-type.enum';
import { GetReviewSection, UserInformationAssociateReviewSummarily } from '@intranet/lib/data-access/models/review/review-form-member.model';

@Component({
  selector: 'app-review-section-result',
  templateUrl: './review-section-result.component.html',
  styleUrls: ['./review-section-result.component.scss']
})
export class ReviewSectionResultComponent {
  @Input() isChild = false;
  @Input() section: GetReviewSection = null;
  @Input() formPersonInformation: { [formPersonId: string]: UserInformationAssociateReviewSummarily } = {};

  columnTemplateTypeEnum = ColumnTemplateTypeEnum;
  sectionTypeEnum = ReviewSectionType;

  constructor() { }
}
