import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { BaseAdminComponent } from '@intranet/lib/components';
import { SurveyService, SearchModel, Survey } from '@intranet/lib/data-access';
import { Router, ActivatedRoute } from '@angular/router';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService, ModalSize, DateHelper, PubSubEvent } from '@intranet/index';
import { SurveyModalComponent } from '../survey-modal/survey-modal.component';
import { IPageInfo } from 'ngx-virtual-scroller';
import * as _ from 'lodash';
@Component({
  selector: 'app-newsfeed-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: [],
  styles: [],
})
export class SurveysComponent extends BaseAdminComponent implements OnInit {

  openingSurveyModal: NgbModalRef;
  public now: Date = new Date();
  @Input() view: string;
  @Input() title = 'Surveys';
  @Input() pageSize = 5;
  @Input() canRemove: boolean;

  search: SearchModel = new SearchModel();
  avatarSize = 32;
  isLoading: boolean;
  currentItems: number;
  hasNextPage: boolean;
  surveys: Survey[];
  currentSurvey: Survey;

  constructor(
    private surveyService: SurveyService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private pubsub: PubSubService,
    private modalService: ModalService
  ) {
    super();

    this.pubsub.$sub(PubSubEvent.EVENTS.ON_REFRESH_SURVEY).subscribe(() => {
      this.surveys = [];
      this.loadData();
    });
  }

  ngOnInit() {
    this.search.pageNumber = 1;
    this.search.pageSize = 5;
    this.search.noLoading = true;

    this.surveys = [];
    this.isLoading = false;

    this.loadData();
  }

  loadMore() {
    this.search.pageNumber++;
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this.surveyService.getUserSurveys(
      this.search,
      (res: Survey[]) => {
        res.forEach(survey => {
          const existedSurveys = this.surveys.filter((item: Survey) => {
            return item.id === survey.id;
          });

          if (!existedSurveys || existedSurveys.length === 0) {
            this.surveys.push(survey);
          }
        });

        if (res && res.length > 0) {
          this.search.totalItems = this.surveys[0].totalCount;
        }
        this.hasNextPage = res && res.length > 0;

      },
      error => { },
      () => {
        this.isLoading = false;
      },
    );
  }

  openSurvey(survey: Survey) {
    this.currentSurvey = survey;
    this.openingSurveyModal = this.modalService.open(SurveyModalComponent, {
      windowClass: 'survey-modal',
      size: ModalSize.xl,
    });
    this.openingSurveyModal.componentInstance.onClose.subscribe(() => {
      this.openingSurveyModal.close();
    });
    this.openingSurveyModal.componentInstance.survey = this.currentSurvey;
    this.openingSurveyModal.result.then((resp: any) => { });
  }

  navigateTo(survey: Survey): void {

  }

  remainingDate(survey: Survey) {
    return DateHelper.getDistanceWithToday(survey.endDate);
  }
}
