import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SecurityHelper } from '../../../helpers/securityHelper';
import { TitleService } from '../title.service';
import { NgkTranslateService } from 'ngk-validate-form';
@Injectable({
  providedIn: 'root',
})
export class I18nService {
  private languageKey = 'aluha.ezcode.language';

  constructor(public translate: TranslateService, private titleService: TitleService, private ngkTranslate: NgkTranslateService) {
    translate.addLangs(['en', 'vi']);
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // do something
      this.titleService.setTitleTranslate();
    });
  }

  get currentLocale() {
    const currentLocale = this.translate.currentLang;
    if (currentLocale === 'nb-NO') {
      return 'en';
    }
    return this.translate.currentLang;
  }

  getValue(key: string) {
    const text = this.translate.instant(key);
    return text;
  }

  getHeaderName(params: any) {
    const key = params.colDef.headerName;
    return this.getValue(key);
  }

  init() {
    const lang = SecurityHelper.getStore(this.languageKey);
    if (!lang) {
      this.translate.use('en');
      this.ngkTranslate.use('en');
    } else {
      this.translate.use(lang.value);
      this.ngkTranslate.use(lang.value);
    }
  }

  setLanguage(lang: any) {
    SecurityHelper.createStore(this.languageKey, lang);
    this.translate.use(lang.value);
    this.ngkTranslate.use(lang.value);
  }
}
