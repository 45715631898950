import { Injectable } from '@angular/core';
import { HttpClientService, Toastr, Loading } from '@intranet/index';
import { ApiResponse } from '../models/api-response.model';
import { Constants } from '@intranet/lib/config';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseService {
  abstract hostUrl: string;
  constructor(protected httpClient: HttpClientService) { }

  clearAllLoading() {
    Loading.hideAll();
    Constants.countRequest = 0;
  }

  showHideLoading(params: any, isHide: boolean) {
    if (params && params.noLoading) {
    } else {
      if (isHide) {
        Constants.countRequest--;
      } else {
        Loading.show();
        Constants.countRequest++;
      }
    }
  }

  getAll(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = this.hostUrl;
    this.showHideLoading(params, false);
    return new Promise((resolve, reject) => {
      this.httpClient
        .getSync(url, { params })
        .then(
          (resp: any) => {
            if (resp.id && resp.id < 0) {
              Toastr.error(resp.message || resp.Message);
            } else {
              // in case service return an array of objects
              if (successCallback) {
                successCallback(resp);
              }
              resolve(resp);
            }
          },
          (error: any) => {
            this.handleException(error, failedCallback);
          },
        )
        .finally(() => {
          this.showHideLoading(params, true);
          if (Constants.countRequest <= 0) {
            Loading.hide();
          }
          if (completeCallback) {
            completeCallback();
          }
        });
    });
  }
  getDropdown(params?: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    Loading.show();
    Constants.countRequest++;
    const url = this.hostUrl + '/get-dropdown';
    return new Promise((resolve, reject) => {
      this.httpClient
        .getSync(url, { params })
        .then(
          (resp: any) => {
            if (resp.id && resp.id < 0) {
              Toastr.error(resp.message || resp.Message);
            } else {
              if (successCallback) {
                successCallback(resp);
              }
              resolve(resp);
            }
          },
          (error: any) => {
            if (error && error.status === 403) {
              Toastr.error(error.statusText);
            }
            else if (failedCallback) {
              failedCallback(error);
              reject(error);
            } else {
              Toastr.error(error.message);
            }
          },
        )
        .finally(() => {
          Constants.countRequest--;
          if (Constants.countRequest <= 0) {
            Loading.hide();
          }
          if (completeCallback) {
            completeCallback();
          }
        });
    });
  }

  getById(guid: string, successCallback: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    Loading.show();
    Constants.countRequest++;
    return new Promise((resolve, reject) => {
      const requestUrl = `${this.hostUrl}/get_by_id?guidId=${guid}`;
      this.httpClient
        .getSync(requestUrl)
        .then(
          (resp: any) => {
            if (resp.hasError) {
              Toastr.error(resp.errors);
            }
            if (successCallback) {
              successCallback(resp);
            }
            resolve(resp);
          },
          (error: any) => {
            this.handleException(error, failedCallback);
          },
        )
        .finally(() => {
          Constants.countRequest--;
          if (Constants.countRequest <= 0) {
            Loading.hide();
          }
          if (completeCallback) {
            completeCallback();
          }
        });
    });
  }

  add(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    Loading.show();
    Constants.countRequest++;
    this.httpClient
      .postSync(this.hostUrl, data)
      .then(
        (resp: ApiResponse) => {
          if (resp && resp.id && resp.id > 0) {
            if (successCallback) {
              successCallback(resp);
            } else {
              Toastr.success('Successfully');
            }
          } else {
            Toastr.error(resp.message || resp.Message);
          }
        },
        (error: any) => {
          this.handleException(error, failedCallback);
        },
      )
      .finally(() => {
        Constants.countRequest--;
        if (Constants.countRequest <= 0) {
          Loading.hide();
        }
        if (completeCallback) {
          completeCallback();
        }
      });
  }

  update(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    return this.updateUrl('', data, undefined, successCallback, failedCallback, completeCallback);
  }

  updateUrl(
    url: string,
    data?: any,
    params?: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    this.showHideLoading(params, false);
    let xurl = this.hostUrl;
    if (url) {
      xurl += '/' + url;
    }
    this.httpClient
      .putSync(xurl, data, params)
      .then(
        (resp: ApiResponse) => {
          if (resp && resp.id && resp.id > 0) {
            if (successCallback) {
              successCallback(resp);
            } else {
              Toastr.success('Successfully');
            }
          } else {
            Toastr.error(resp.message || resp.Message);
          }
        },
        (error: any) => {
          this.handleException(error, failedCallback);
        },
      )
      .finally(() => {
        this.showHideLoading(params, true);
        if (Constants.countRequest <= 0) {
          Loading.hide();
        }
        if (completeCallback) {
          completeCallback();
        }
      });
  }

  deleteById(id: string, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    this.deleteByUrl(``, { id }, successCallback, failedCallback, completeCallback);
  }

  deleteByIntergerId(id: number, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    this.deleteByUrl(``, { id }, successCallback, failedCallback, completeCallback);
  }

  deleteByUrl(apiUrl: string, params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    this.showHideLoading(params, false);
    const requestUrl = new URL(`${this.hostUrl}/${apiUrl}`);

    if (params) {
      Object.keys(params).map((key) => {
        if (key !== 'noLoading') {
          requestUrl.searchParams.append(key, params[key]);
        }
      });
    }

    this.httpClient
      .deleteSync(requestUrl.href)
      .then(
        (resp: ApiResponse) => {
          if (resp && resp.id && resp.id > 0) {
            if (successCallback) {
              successCallback(resp);
            } else {
              Toastr.success('Successfully');
            }
          } else {
            Toastr.error(resp.message || resp.Message);
          }
        },
        (error: any) => {
          this.handleException(error, failedCallback);
        },
      )
      .finally(() => {
        this.showHideLoading(params, true);
        if (Constants.countRequest <= 0) {
          setTimeout(() => {
            Loading.hide();
          }, 300);
        }
        if (completeCallback) {
          completeCallback();
        }
      });
  }

  delete(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    this.showHideLoading(params, false);
    const requestUrl = `${this.hostUrl}`;
    this.httpClient
      .deleteSync(requestUrl, { params })
      .then(
        (resp: ApiResponse) => {
          if (resp && resp.id && resp.id > 0) {
            if (successCallback) {
              successCallback(resp);
            } else {
              Toastr.success('Successfully');
            }
          } else {
            Toastr.error(resp.message || resp.Message);
          }
        },
        (error: any) => {
          this.handleException(error, failedCallback);
        },
      )
      .finally(() => {
        this.showHideLoading(params, true);
        if (Constants.countRequest <= 0) {
          setTimeout(() => {
            Loading.hide();
          }, 300);
        }
        if (completeCallback) {
          completeCallback();
        }
      });
  }

  getByUrl(apiUrl: string, params: any, successCallback: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    this.showHideLoading(params, false);
    apiUrl = apiUrl.replace('//','/');
    const requestUrl = `${this.hostUrl}/${apiUrl}`;
    return new Promise((resolve, reject) => {
      this.httpClient
        .getSync(requestUrl, { params })
        .then(
          (resp: any) => {
            if (resp.id && resp.id < 0) {
              Toastr.error(resp.message || resp.Message);
            } else {
              // in case service return an array of objects
              if (successCallback) {
                successCallback(resp);
              } else {
                resolve(resp);
              }
            }
          },
          (error: any) => {
            this.handleException(error, failedCallback);
          },
        )
        .finally(() => {
          this.showHideLoading(params, true);
          if (Constants.countRequest <= 0) {
            setTimeout(() => {
              Loading.hide();
            }, 300);
          }
          if (completeCallback) {
            completeCallback();
          }
        });
    });
  }

  handleException(error, failedCallback?: (error?: any) => void) {
    if (error && error.status === 403) {
      Toastr.error(error.statusText);
    }
    else if (failedCallback) {
      failedCallback(error);
    } else {
      Toastr.error(error.message);
    }
  }

  postByUrl(apiUrl: string, params: any, successCallback: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const requestUrl = `${this.hostUrl}/${apiUrl}`;

    this.showHideLoading(params, false);
    const data = params;
    if (data && data.noLoading) {
      delete data.noLoading;
    }

    this.httpClient
      .postSync(requestUrl, data)
      .then(
        (resp: any) => {
          if (successCallback) {
            successCallback(resp);
          } else {
            Toastr.success('Successfully');
          }
        },
        (error: any) => {
          this.handleException(error, failedCallback);
        },
      )
      .finally(() => {
        this.showHideLoading(params, true);
        if (Constants.countRequest <= 0) {
          setTimeout(() => {
            Loading.hide();
          }, 300);
        }
        if (completeCallback) {
          completeCallback();
        }
      });
  }
}
