import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import {
  PurchaseOrderDetail,
  PurchaseOrder,
  PurchaseRequestDetailService,
  PurchaseRequestService,
  PurchaseOrderService,
  PurchaseRequest,
  InternalUploadFileDto,
  ColumnMapping,
  COLUMN_TYPE,
} from '@intranet/lib/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants, Loading, Toastr, Sweetalert2Service } from '@intranet/index';
import { BaseAdminComponent } from '@intranet/lib/components';
import * as linq from 'linq';
import { DatatableComponent } from '@swimlane/ngx-datatable';
@Component({
  selector: 'app-confirm-detail-modal',
  templateUrl: './confirm-detail-modal.component.html',
  styleUrls: ['./confirm-detail-modal.component.scss'],
})
export class ConfirmDetailModalComponent extends BaseAdminComponent implements OnInit, AfterViewInit {
  @ViewChild('myTable') table: any;
  @ViewChild(DatatableComponent) private tableComponent: DatatableComponent;
  modelUpload: InternalUploadFileDto = new InternalUploadFileDto();
  @Output() reject: EventEmitter<any> = new EventEmitter();
  purchseRequestStatus = Constants.PRUCHASE_REQUEST_STATUS_ID;
  purchaseOrderStatus = Constants.PURCHASE_ORER_STATUS_IDS;
  purchaseRequest: PurchaseRequest;
  purchaseId: string;
  reason = '';
  productLists: PurchaseOrderDetail[];
  activeModal: NgbActiveModal;
  isLoading = false;
  enumPurchaseRequestStatus = Constants.PRUCHASE_REQUEST_DETAIL_STATUS_ID;
  amount: number;
  columnMapping = [
    new ColumnMapping(COLUMN_TYPE.STRING, 'productName', 'Name', false, false, 200),
    new ColumnMapping(COLUMN_TYPE.STRING, 'equipmentCategoryName', 'Category', true, false),
    new ColumnMapping(COLUMN_TYPE.STRING, 'supplierName', 'Supplier', true, false),
    new ColumnMapping(COLUMN_TYPE.STRING, 'option', 'Option', true, false),
    new ColumnMapping(COLUMN_TYPE.NUMBER, 'quantity', 'Quantity', false, false, 100),
    new ColumnMapping(COLUMN_TYPE.NUMBER, 'buyQuantity', 'Buy quantity', false, false, 100),
    new ColumnMapping(COLUMN_TYPE.NUMBER, 'quantityInWarehouse', 'Take in warehouse', false, false, 130),
    new ColumnMapping(COLUMN_TYPE.NUMBER, 'price', 'Price', true, false),
    new ColumnMapping(COLUMN_TYPE.NUMBER, 'totalAmount', 'Total Amount', true, false),
  ];
  isShowSweet = true;
  show = false; // add one more property
  constructor(
    modalRef: NgbActiveModal,
    private purchaseRequestDetailSvc: PurchaseRequestDetailService,
    private purchaseRequestSvc: PurchaseRequestService,
    private purchaseOrderSvc: PurchaseOrderService,
    private sweetalert2Service: Sweetalert2Service
  ) {
    super();
    this.activeModal = modalRef;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.table.rowDetail.expandAllRows();
    }, 800);
  }

  ngOnInit() {
    this.loadPurchase();
    this.loadOrderDetail();

  }

  loadOrderDetail() {
    Loading.show();
    this.purchaseRequestDetailSvc.getAll({ guid: this.purchaseId }, (res: PurchaseOrderDetail[]) => {
      if (res) {
        this.productLists = res;
        this.calculatorAmount();
        let count = 0;
        const brokenList = res.filter(x => x.parentId);
        res
          .filter(x => !x.parentId)
          .forEach(element => {
            const childs = brokenList.filter(x => x.parentId === element.id);
            element.order = count;
            count++;
            if (childs && childs.length) {
              element.hasChildrens = true;
              childs.forEach(child => {
                child.order = count;
                count++;
              });
            }
          });
        this.productLists = linq
          .from(res)
          .orderBy(x => x.order)
          .toArray();
      }
      Loading.hide();
    });
  }

  loadPurchase() {
    Loading.show();
    this.purchaseRequestSvc.getById(this.purchaseId, (res: PurchaseRequest) => {
      if (res) {
        this.purchaseRequest = res;
        this.modelUpload.entityId = this.purchaseRequest.id;
        this.modelUpload.entityType = Constants.ENTITY_TYPE.PURCHASE_REQUEST;
        if (
          this.purchaseRequest.purchasePoStatus === this.purchseRequestStatus.New ||
          this.purchaseRequest.purchasePoStatus === this.purchseRequestStatus.WaitingVerify
        ) {
          this.modelUpload.isReadonly = false;
        } else {
          this.modelUpload.isReadonly = true;
        }
      }
      Loading.hide();
    });
  }

  calculatorAmount() {
    this.amount = this.productLists.reduce((total, item) => {
      total = item.buyQuantity * item.price + total;
      return total;
    }, 0);
  }

  updateStatus(status: number) {
    this.purchaseRequestSvc.updateStatus({ id: this.purchaseId, status, reason: this.reason }, () => {
      this.activeModal.close(1);
      const dataNoti = {
        title: status === this.purchseRequestStatus.Approve ? 'PR have been Approved' : 'Success',
        text:
          status === this.purchseRequestStatus.Approve
            ? 'You can go to page Purchase order to continue.'
            : status === this.purchseRequestStatus.Verify
              ? 'PR have been Verified.'
              : 'PR have been Rejected.',
        icon: 'success',
      };
      if (this.isShowSweet) {
        this.sweetalert2Service.showSlow(dataNoti);
      }
    });
  }

  rejectPR(item: PurchaseRequest) {
    this.reject.emit({ item, isShowSweet: this.isShowSweet });
  }

  changePrice() {
    this.purchaseRequest.totalAmount = 0;
    this.productLists.forEach(item => {
      this.purchaseRequest.totalAmount += item.buyQuantity * item.price;
    });
  }

}

