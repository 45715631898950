<div class="newsflash-confirmation-container">
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h5 class="newsflash-title">{{ newsflashConfirmation?.title }}</h5>
    <!-- <button *ngIf="!previewMode" nz-button nz-dropdown [nzDropdownMenu]="menu" nzType="link">Remind me later</button> -->
  </div>

  <div class="newsflash-confirmation-body">
    <div *ngIf="documents.length > 1" class="document-list">
      <h6>Document List ({{ documents.length }})</h6>
      <nz-steps [nzCurrent]="currentIndex" nzDirection="vertical" nzSize="small" (nzIndexChange)="onIndexChange($event)">
        <nz-step
          *ngFor="let document of documents; index as i"
          [nzTitle]="document.name"
          [class.checked-document]="document.isConfirmed && !previewMode"
          [nzStatus]="currentIndex === i ? 'process' : 'finish'"
        ></nz-step>
      </nz-steps>
    </div>

    <div class="document-content">
      <ngx-extended-pdf-viewer
        [src]="previewPdf"
        language="en-us"
        height="500px"
        [zoom]="'page-width'"
        [textLayer]="false"
        [showHandToolButton]="false"
        [handTool]="false"
        [showDownloadButton]="false"
        [showOpenFileButton]="false"
        [showRotateButton]="false"
        [enablePrint]="false"
        (pageChange)="onPageChange($event)"
        (pagesLoaded)="onPagesLoaded($event)"
        [showZoomButtons]="false"
        [showSidebarButton]="false"
        [showSecondaryToolbarButton]="false"
        [showFindButton]="false"
        [showDrawEditor]="false"
        [showTextEditor]="false"
      ></ngx-extended-pdf-viewer>
      <button
        *ngIf="!previewMode"
        class="btn confirm-btn"
        nz-button
        (click)="confirm()"
        [disabled]="currentDocument?.isConfirmed || !currentDocument?.hasReadAll"
        [nzLoading]="isLoading"
      >
        <span nz-icon nzType="check" nzTheme="outline"></span>
        {{
          currentDocument?.isConfirmed ? 'Seen on ' + (currentDocument.confirmedDate | date : dateFormat) : 'Got it. Thank you'
        }}
      </button>

      <button *ngIf="previewMode" nz-button type="button" class="btn btn-white mt-3 ml-auto" (click)="modalRef.close()">
        Cancel
      </button>
    </div>
  </div>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li *ngFor="let option of remindTimeOptions" nz-menu-item (click)="remindMeLater(option)">
      <span>{{ option | i18nPlural : textMapping }}</span>
    </li>
  </ul>
</nz-dropdown-menu>
