Object.defineProperty(Object.prototype, 'copyValue', {
  value(source, ...ignores) {
    const arrIgnore = ignores;
    if (this && source.isNotNullOrEmpty()) {
      // tslint:disable-next-line: forin
      for (const prop in this) {
        const index = arrIgnore.findIndex(x => x === prop);
        if (index < 0) {
          if (this.hasOwnProperty(prop) && source.hasOwnProperty(prop)) {
            this[prop] = source[prop] || '';
          }
        } else {
          delete this[prop];
        }
      }
    }
    return this;
  },
});

Object.defineProperty(Object.prototype, 'compareArray', {
  value(array) {
    if (!array) {
      return false;
    }
    if (this.length !== array.length) {
      return false;
    }
    for (let i = 0, l = this.length; i < l; i++) {
      if (this[i] instanceof Array && array[i] instanceof Array) {
        if (!this[i].compare(array[i])) {
          return false;
        }
      } else if (this[i] !== array[i]) {
        return false;
      }
    }
    return true;
  },
});
Object.defineProperty(Array.prototype, 'move', {
  value(from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
    return this;
  },
});
Object.defineProperty(Object.prototype, 'getFileName', {
  value(type: string) {
    if (this && this.length) {
      const file = this.find(x => x.type === type);
      if (file && file.constructor === Object) {
        return file.fileName;
      }
      return undefined;
    }
    return undefined;
  },
});

Object.defineProperty(Object.prototype, 'getFileNames', {
  value(type: string) {
    if (this && this.length) {
      const files = this.filter(x => x.type === type);
      if (files && files.length) {
        const fileNames = files.map(x => x.fileName);
        return fileNames;
      }
      return undefined;
    }
    return undefined;
  },
});

Object.defineProperty(Object.prototype, 'isNotNullOrEmpty', {
  value() {
    if (this && Object.keys(this).length) {
      return true;
    }
    return false;
  },
});
Object.defineProperty(Object.prototype, 'addPlacholder', {
  value(str: string) {
    this.unshift({ id: 0, name: str });
  },
});

Object.defineProperty(Object.prototype, 'compareObject', {
  value(obj2: any) {
    return JSON.stringify(this) === JSON.stringify(obj2);
  },
});
Object.defineProperty(document.body.style, 'transform', {
  value: () => {
    return {
      enumerable: true,
      configurable: true,
    };
  },
});
Object.defineProperty(document.body.style, 'transformOrigin', {
  value: () => {
    return {
      enumerable: true,
      configurable: true,
    };
  },
});

Object.defineProperty(Array.prototype, 'replaceMany', {
  value(params: any[], ...options) {
    let data = JSON.stringify(this);
    params.forEach(element => {
      data = data.replace(new RegExp(element.oldvalue, 'g'), element.newvalue);
    });
    const objData = JSON.parse(data);
    if (options && options.length) {
      options.forEach(opt => {
        objData.forEach(item => {
          addOption(item, opt);
        });
      });
    }
    return objData;
  },
});

function addOption(item: any, opt: any) {
  if (item.children) {
    item.children.forEach(child => {
      if (child.children) {
        addOption(child, opt);
      } else {
        child[opt.name] = opt.value;
      }
    });
  } else {
    item[opt.name] = opt.value;
  }
}

declare interface String {
  removeVietnamese(): string;
  clear(): string;
  toUrl(): string;
  trimLft(charlist: string): string;
  trimRht(charlist: string): string;
  trym(charlist: string): string;
}
String.prototype.removeVietnamese = function() {
  if (this) {
    return accentsTidy(this.toString());
  }
  return '';
};
String.prototype.toUrl = function() {
  if (this) {
    return friendlyUrl(this.toString());
  }
  return '';
};
// tslint:disable-next-line:only-arrow-functions
String.prototype.clear = function() {
  return '';
};
String.prototype.trimLft = function(charlist: string) {
  if (charlist === undefined) {
    charlist = 's';
  }

  return this.replace(new RegExp('^[' + charlist + ']+'), '');
};
String.prototype.trimRht = function(charlist: string) {
  if (charlist === undefined) {
    charlist = 's';
  }

  return this.replace(new RegExp('[' + charlist + ']+$'), '');
};
String.prototype.trym = function(charlist) {
  return this.trimLft(charlist).trimRht(charlist);
};
function accentsTidy(str) {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'| |\"|\&|\#|\[|\]|~|$|_/g, '-');

  // str = str.replace(/-+-/g, '-'); //thay thế 2- thành 1-
  // str = str.replace(/^\-+|\-+$/g, '');

  return str;
}

function friendlyUrl(str) {
  str = accentsTidy(str);
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeeiiiiooooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}
