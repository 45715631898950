<div *ngIf="list && list.length > 0" class="dropdown show rt-header-option dropdown-custom">
  <a class="rt-account" href="#" id="rt-account-message" data-toggle="dropdown" aria-haspopup="true"
    aria-expanded="false">
    <span><img src="assets/images/front/shortcut/notification.svg" alt="restaff" /><span
        *ngIf="numberRead">{{numberRead}}</span></span>
  </a>

  <div class="dropdown-menu dropdown-menu-right notifi-box custom-scrollbar message" aria-labelledby="rt-account-message">
    <div class="dropdown-item notifi-item notifi-header">
      <span class="bold fs-17">Notifications</span>
      <a class="dropdown-item"  nzPopconfirmPlacement="top" href="#" (click)="markAllReadNotification()" 
        nz-popover nzPopoverContent="Mark as all read">
        <img src="assets/images/front/shortcut/mark.svg" />
      </a>
    </div>
    <div class="notifi-scroll">
      <div (click)="onLink(item)" *ngFor="let item of list" [class]="item.readTime ? 'dropdown-item notifi-item  mt-1' : 'dropdown-item notifi-item  mt-1 active'">
        <div class="notifi-box-left">
          <div class="notifi-avatar">
            <app-avatar class="notifi-img" [style]="{width: '100%'}" [size]="55" [avatarSource]="item?.avatarUrl" [avatarText]="item?.createdByName"> </app-avatar>
          </div>
        </div>
        <div class="notifi-content">
          <p class="notifi-des fs-14"><b>{{item.title}}</b></p>
          <p class="notifi-message">{{item.description}}</p>
          <span class="notifi-time fs-13">{{ item.createdDate  | amTimeAgo }}</span>
        </div>
        <!-- <a class="notifi-action">
              ...
            </a> -->
      </div>
    </div>
    <div (click)="viewAll('/notifications')" class="dropdown-item notifi-item notifi-footer text-center">
      <a class="semi-bold">View all</a>
    </div>
  </div>
</div>
