import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateMonthSelectModel, DateMonthSelectSettingModel } from '@intranet/lib/data-access';

@Component({
  selector: 'app-search-date-month',
  templateUrl: './search-date-month.component.html',
  styleUrls: ['./search-date-month.component.scss']
})
export class SearchDateMonthComponent implements OnInit {
  years = [];
  months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  @Input()
  setting: DateMonthSelectSettingModel = new DateMonthSelectSettingModel();
  @Input()
  model: DateMonthSelectModel = new DateMonthSelectModel();
  @Output() change = new EventEmitter<DateMonthSelectModel>();


  constructor() {
    this.loadYears();
  }

  ngOnInit() {
  }


  loadYears() {
    const d = new Date();
    const n = d.getFullYear();
    for (let i = 0; i < 10; i++) {
      this.years.push(n - i);
    }
  }
  changeDate($event, type) {
    if (type === 1) {
      this.model.fromMonth = $event;
    } else if (type === 2) {
      this.model.fromYear = $event;
    } else if (type === 3) {
      this.model.toMonth = $event;
    } else if (type === 4) {
      this.model.toYear = $event;
    }
    this.change.emit(this.model);
  }
}
