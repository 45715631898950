import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UrlConstant } from '@intranet/lib/config';
import { MemberDisplayMode, MemberLeaveDto, WidgetSearchDto, WidgetService } from '@intranet/lib/data-access';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
@Component({
  selector: 'app-widget-leave-detail',
  templateUrl: './widget-leave-detail.component.html',
  styleUrls: ['./widget-leave-detail.component.scss']
})
export class WidgetLeaveDetailComponent implements OnChanges {
  @Input() searchData: WidgetSearchDto = new WidgetSearchDto();
  @Input() reloadData: boolean;
  @Input() displayMode: MemberDisplayMode = MemberDisplayMode.UserWidget;
  @Input() set mobileView(value: boolean) {
    this.isMobileView = value;
    this.avatarSize = value ? 40 : 70;
  }

  isMobileView: boolean;
  avatarSize: number;

  loading = true;
  data: MemberLeaveDto[];
  isDetail = false;
  urlConstant = UrlConstant;

  isNotOnInitFirstRun = false;

  get isClientPage() {
    return this.displayMode === MemberDisplayMode.ClientPage;
  }

  constructor(private _widgetService: WidgetService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.searchData) {
      this.loadData();
    }
  }

  async loadData() {
    this.searchData ??= new WidgetSearchDto();
    this.searchData.noLoading = this.isClientPage;
    this.loading = true;

    try {
      this.data = await this._widgetService.getWidgetMemberForLeave(this.searchData);
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
    finally {
      this.loading = false;
    }
  }

  openInformation(item) {
    if (this.isClientPage) {
      return;
    }

    const url = this.urlConstant.PROFILE_PAGE + '?employeeId=' + item.employeeId;
    window.open(
      url,
      '_blank'
    );
  }

}
