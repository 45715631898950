<nz-select
  [nzDisabled]="disabled"
  [nzSize]="size"
  [class]="nzSelectClass"
  nzShowSearch
  [nzAllowClear]="allowClear"
  [(ngModel)]="value"
  [nzServerSearch]="true"
  [nzPlaceHolder]="placeholder | translate"
  (nzOnSearch)="onSearch($event)"
  [nzMode]="mode"
  (ngModelChange)="valueChange.next($event)"
  [nzMaxTagCount]="maxTagCount"
  [nzOptionHeightPx]="optionHeight"
>
  <ng-container *ngFor="let item of optionList">
    <nz-option *ngIf="!isLoading" [nzLabel]="fillLabel(item)" [nzValue]="fillValue(item)" [nzCustomContent]="customContent">
      <ng-container [ngTemplateOutlet]="customContent" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </nz-option>
  </ng-container>
  <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
    <i nz-icon nzType="loading" class="loading-icon"></i> Loading Data...
  </nz-option>
</nz-select>
