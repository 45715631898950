<ng-container *ngIf="widget">
  <nz-card [nzBordered]="false" [nzLoading]="loading">
    <div class="widget-header">
      <div class="widget-title">{{widget.data.title}}</div>
      <div class="widget-control widget-control-sm-fully">
        <div *ngIf="data && data.departmentGroups && data.departmentGroups.length > 1">
          <nz-select (ngModelChange)="modelChanged($event)" nzShowSearch nzAllowClear nzPlaceHolder="Select a groups"
            [(ngModel)]="searchData.departmentIds">
            <nz-option *ngFor="let option of data.departmentGroups" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
          </nz-select>
        </div>
        
      </div>
    </div>
    <div id="pie-chart-parent" *ngIf="data && data.chartGenders">
      <ngx-charts-pie-chart [scheme]="data.chartGenders.colorScheme" [results]="data.chartGenders.chartDatas" [legend]="data.chartGenders.showLegend"
        [labels]="data.chartGenders.showLabels" (window:resize)="onResizePie($event)">
      </ngx-charts-pie-chart>
    </div>
  </nz-card>
</ng-container>
