import { Component, OnInit } from '@angular/core';
import { SurveyService } from '@intranet/lib/data-access';
import { ActivatedRoute } from '@angular/router';
import { Survey } from '@intranet/lib/data-access/models/survey.model';

@Component({
  selector: 'app-newsfeed-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
  styles: [],
})
export class SurveyPageComponent implements OnInit {
  isLoading: boolean;
  constructor() {
  }

  ngOnInit() {
  }
}
