import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageOption, NgkMessageService } from '@intranet/lib/settings/services/ngkMessage.service';
import { WebHelper } from '@intranet/index';

@Component({
  selector: 'page-main',
  templateUrl: './page-main.component.html',
  styles: [],
})
export class PageMainComponent implements OnInit, OnDestroy {
  messageData: MessageOption = {};
  eventSubcribe: any;
  constructor(private ngkMessageService: NgkMessageService) {
    WebHelper.unSubscribe(this.eventSubcribe);
    this.eventSubcribe = this.ngkMessageService.sourceShowMessage.subscribe(data => {
      if (data) {
        this.messageData = data;
      }
    });
  }
  ngOnDestroy(): void {
    WebHelper.unSubscribe(this.eventSubcribe);
  }
  ngOnInit() {}
}
