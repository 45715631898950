import { Component, OnInit, Input } from '@angular/core';
import { CDNService, InternalUploadFileResponseModel } from '@intranet/lib/data-access';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { IMasonryGalleryImage } from 'ngx-masonry-gallery';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-news-feed-image-avatar',
  templateUrl: './news-feed-image-avatar.component.html',
  styleUrls: ['./news-feed-image-avatar.component.scss']
})
export class NewsFeedImageAvatarComponent implements OnInit {
  @Input() images: InternalUploadFileResponseModel[];
  @Input() src: string;
  galleryItem: GalleryItem;
  galleryId: string;
  masonryItems: IMasonryGalleryImage;

  maxImageCount = 4;

  constructor(
    public gallery: Gallery, public lightbox: Lightbox, private cdnService: CDNService) { }

  ngOnInit() {
    this.src = this.cdnService.getFileUrl(this.src);
    this.galleryItem = new ImageItem({ src: this.src, thumb: this.src });
    this.masonryItems = {
      imageUrl: this.src,
      width: 150,
      height: 150
    } as IMasonryGalleryImage;

    // Get a lightbox gallery ref
    const guid = uuidv4();
    this.galleryId = 'box' + guid;
    const lightboxRef = this.gallery.ref(this.galleryId);

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Top
    });

    // Load items into the lightbox gallery ref
    lightboxRef.load([this.galleryItem]);
  }

  // onClickImage($event) {
  //   const index = this.images.findIndex(item => item.url === $event.imageUrl) || 0;
  //   this.lightbox.open(index, this.galleryId);
  // }

  onClickImage() {
    this.lightbox.open(0, this.galleryId);
  }
}
