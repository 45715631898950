import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
const noop = () => {};

@Component({
  selector: 'select-search',
  templateUrl: './select-search.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: SelectSearchComponent, multi: true }],
})
export class SelectSearchComponent implements ControlValueAccessor {
  @Input() placeholder: any;
  @Input() textField: string;
  @Input() valueField: any; // only get id value
  @Input() keyField: any; // use it binding object
  @Input() allowClear = true;
  @Input() size = 'default';
  @Input() mode = 'default';
  @Input() maxTagCount = 1;
  @Input() disabled: boolean;
  @Input() nzSelectClass? = 'mb-2';
  @Input() customContent: TemplateRef<unknown>;
  @Input() optionHeight = 32;
  @Output() valueChange = new EventEmitter<unknown>();

  isLoading = false;
  rootData = [];
  optionList: any[] = [];
  // The internal data model
  private innerValue: any = [];

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input()
  set data(value: any) {
    this.rootData = value;
    this.optionList = value;
  }

  fillLabel(value: any) {
    return value[this.textField];
  }
  fillValue(value: any) {
    if (this.valueField) {
      const val = value[this.valueField];

      return isNaN(val) ? val : +val;
    }
    return value;
  }

  onSearch(value: string): void {
    this.isLoading = true;
    this.searchSelect(value);
  }
  filterByRegEx(columnValue, value) {
    if (columnValue !== null) {
      value = value.removeVietnamese().toLowerCase();
      const regEx = new RegExp(value, 'i');
      const colValue = columnValue.removeVietnamese().toLowerCase();
      return regEx.test(colValue);
    }
    return false;
  }
  searchSelect(value: string) {
    if (this.rootData) {
      this.optionList = this.rootData.filter(x => this.filterByRegEx(x[this.textField], value));
      setTimeout(() => {
        this.isLoading = false;
      }, 200);
    }
  }

  // get accessor
  // this will biding select value for dropdown. when choose or when biding again to edit.
  // don't forget set keyFiled
  get value(): any {
    if (this.innerValue && this.keyField && this.rootData && this.innerValue.constructor === Object) {
      const bindingData = this.rootData.find(x => x[this.keyField] === this.innerValue[this.keyField]);
      return bindingData;
    } else {
      return this.innerValue;
    }
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
