import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NewsFeed, WallNewsFeedService, NewsFeedTypeEnum, PublicityLevel, Department, NewsFeedMedia, InternalUploadFileDto } from '@intranet/lib/data-access';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { BaseAdminComponent } from '@intranet/lib/components';
import { PubSubService } from '@intranet/lib/services/pubsub.service';

import { Toastr } from '@intranet/lib/settings/services/toastr';
import { NewsFeeds as NewsFeedsConstant, PubSubEvent, Constants, SecurityHelper } from '@intranet/index';
import { NzUploadFile } from 'ng-zorro-antd/upload';

import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { v4 as uuidv4, v4 as uuid } from 'uuid';

export interface CreateFeedType {
  getServiceMethod(): (params: any, successCallback?: (params: any) => void, failCallback?: (params: any) => void, completeCallback?: (params: any) => void) => void;
  // tslint:disable-next-line: max-line-length
  getServiceUpdateMethod(): (params: any, successCallback?: (params: any) => void, failCallback?: (params: any) => void, completeCallback?: (params: any) => void) => void;
}
@Component({
  template: 'NOT DEFINE HERE',
  styles: [],
})
export class CreateFeedBaseComponent extends BaseAdminComponent implements OnInit, CreateFeedType {
  dateFormat = 'dd/MM/yyyy';

  @ViewChild('editor') editorContainer;
  modelUpload: InternalUploadFileDto = new InternalUploadFileDto();

  @Input() set data(value: NewsFeed) {
    this.originalFeed = value;
    this.feed = value;
    if (this.feed) {
      this.feed.departments = JSON.parse(this.feed?.departments);
      this.initHighlightDateRanges(this.feed.beginHighlightDate, this.feed.endHighlightDate);
      this.initPollResultDateRanges(this.feed.beginResultDate, this.feed.endResultDate);
      this.initDepartmentGroups();
    }

  }

  originalFeed: NewsFeed;
  feed: NewsFeed;
  @Input() title: string;
  @Output() titleChange = new EventEmitter<string>();

  @Input() isModel: boolean;

  @Input() canBack: boolean;
  @Output() canBackChange = new EventEmitter<boolean>();

  @Output()
  files: NzUploadFile[];

  @Output()
  closeModel = new EventEmitter();
  formGroup: FormGroup;

  editorConfig = {
    placeholderHeight: 150,
    height: 250,
    toolbar: 'templateButton | formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat',
    plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help',
  };

  editorContent: string;
  editorPreview: string;
  editorRawContent: string;
  dateRanges: Date[];
  dateResultRanges: Date[];

  publicityLevels = NewsFeedsConstant.PUBLICITY_LEVELS;
  departmentGroups: Department[];

  formError: any = {};

  constructor(protected newsFeedService: WallNewsFeedService, protected fb: FormBuilder, protected pubsub: PubSubService) {
    super();

    this.isModel = true;
    this.isLoading = false;
  }

  onChangeRange(event) {
    this.feed.beginDate = this.dateRanges[0];
    if (this.dateRanges[1]) {
      this.feed.endDate = this.dateRanges[1];
    }
  }

  onChangeResultRange(event) {
    this.feed.beginResultDate = this.dateResultRanges[0];
    if (this.dateResultRanges[1]) {
      this.feed.endResultDate = this.dateResultRanges[1];
    }
  }

  ngOnInit() {
    this.reset();
    if (!this.feed.id) {
      this.feed.id = uuidv4();
      this.feed.isCreate = true;
      this.feed.isShow = true;
      this.feed.allowComment = true;
      this.feed.allowLike = true;
      this.modelUpload.entityId = this.feed.id;
      this.modelUpload.entityType = Constants.ENTITY_TYPE.NewsFeed;
    }

    if (this.feed?.id) {
      this.checkAndInitEditPost();
    }

    // this._validatePublicityLevel();
  }

  initDepartmentGroups(departments?: string) {
    if (this.currentUser) {
      const userDepartments = JSON.parse(this.currentUser.departments);

      this.departmentGroups = [];
      this.departmentGroups = userDepartments
        ? userDepartments.map(dept => {
          return { id: dept.Id, name: dept.Name } as Department;
        })
        : [];
    }
  }

  initPollResultDateRanges(fromDate?: Date, toDate?: Date) {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 15);

    this.dateResultRanges = [fromDate ? fromDate : startDate, toDate ? toDate : endDate];
  }

  initHighlightDateRanges(fromDate?: Date, toDate?: Date) {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 15);

    this.dateRanges = [fromDate ? fromDate : startDate, toDate ? toDate : endDate];
  }

  setTitle(title) {
    setTimeout(() => {
      this.title = title;
      this.titleChange.emit(this.title);
    });
  }

  showPostConfiguration() {
    this.canBack = true;
    this.canBackChange.emit(this.canBack);
  }

  validate(): boolean {
    let result = true;
    if (!this.feed.description || this.feed.description === '') {
      // throw new Error('Please share your thinking');
      this.formError.description = true;
      result = false;
    }

    if (!this.feed.title || this.feed.title === '') {
      // throw new Error('Please share your mind');
      this.formError.title = true;
      result = false;
    }

    if (this.feed.beginDate && this.feed.endDate && this.feed.endDate < this.feed.beginDate) {
      // throw new Error('End date happens before begin date');
      this.formError.date = true;
      result = false;
    }

    return result;
  }

  onCreate() {
    this.isLoading = true;
    const api = this.getServiceMethod();
    // feed created on wall should be in New status
    this.feed.status = Constants.NEWSFEED_STATUS_ID.New;
    api(
      this.feed,
      res => {
        if (res && res.data) {
          this.closeModal(res.data.id);
          if (!res.data.isShow && res.data.status === Constants.NEWSFEED_STATUS_ID.New) {
            Toastr.success('You just have posted your status successfully. Please wait for approval!');
          } else {
            this.newsFeedService.getFeed(
              res.data.id,
              (res: NewsFeed) => {
                if (res) {
                  this.pubsub.$pub(PubSubEvent.EVENTS.ON_CREATE_FEED, res);
                  Toastr.success('You just have posted your status successfully!');
                }
              },
              error => {
                Toastr.error(error.toString());
                this.closeModal(null);
              },
            );
          }

        } else {
          this.closeModal(null);
        }
      },
      e => {
        Toastr.error(e.toString());
      },
      () => {
        this.reset();
      },
    );
  }

  onUpdate() {
    this.isLoading = true;
    const api = this.getServiceUpdateMethod();
    api(
      this.feed,
      res => {
        if (res && res.data) {
          this.closeModal(res.data.id);
          this.newsFeedService.getFeed(
            res.data.id,
            (res: NewsFeed) => {
              if (res) {
                this.pubsub.$pub(PubSubEvent.EVENTS.ON_CREATE_FEED, res);
                Toastr.success('You just have posted your status successfully!');
              }
            },
            error => {
              Toastr.error(error.toString());
              this.closeModal(null);
            },
          );
        } else {
          this.closeModal(null);
        }
      },
      e => {
        Toastr.error(e.toString());
      },
      () => {
        this.reset();
      },
    );
  }
  async onSubmit() {
    if (this.canBack) {
      this.canBack = false;
      return false;
    }

    if (this.isLoading) {
      return false;
    }

    if (this.feed.departments) {
      this.feed.departments = JSON.stringify(this.feed.departments);
    }

    try {
      if (!this.validate()) {
        return;
      }
      if (this.files && this.files.length) {
        const results = (await this.newsFeedService.uploadImages(this.files)) as NewsFeedMedia[];
        this.feed.media = results;
      }

      if (this.feed.id && !this.feed.isCreate) {
        this.onUpdate();
      }
      else {
        this.onCreate();
      }
    } catch (e) {
      Toastr.error(e.toString());
    }
  }

  public getServiceMethod(): (
    params: any,
    successCallback?: (params: any) => void,
    failCallback?: (params: any) => void,
    completeCallback?: (params: any) => void,
  ) => void {
    throw Error('Please implement getServiceMethod');
  }

  public getServiceUpdateMethod(): (
    params: any,
    successCallback?: (params: any) => void,
    failCallback?: (params: any) => void,
    completeCallback?: (params: any) => void,
  ) => void {
    throw Error('Please implement getServiceUpdateMethod');
  }

  reset() {
    this.isLoading = false;

    if (!this.originalFeed) {
      this.feed = new NewsFeed();
      this.feed.newsFeedTypeId = NewsFeedTypeEnum.WALL;
      this.feed.publicityLevel = PublicityLevel.PUBLIC;
      this.feed.beginDate = new Date();
      this.feed.allowComment = true;
      this.feed.allowLike = true;

      this.files = [];
      if (this.editorContainer) {
        this.editorContainer.reset();
        this.editorContent = '';
        this.editorPreview = '';
        this.editorRawContent = '';
      }

      this.modelUpload.entityId = this.feed.id;
      this.modelUpload.entityType = Constants.ENTITY_TYPE.NewsFeed;

      this.initDepartmentGroups();
      this.initHighlightDateRanges();
      this.initPollResultDateRanges();
    }
  }

  closeModal(data) {
    this.closeModel.emit(data);
  }

  isPrivateGroup() {
    return this.feed.publicityLevel === PublicityLevel.PRIVATE_GROUP && this.departmentGroups && this.departmentGroups.length > 0;
  }

  onChangeHighlightBeginDate(date) {
    this.feed.beginHighlightDate = date;
    this.feed.endHighlightDate = null;
  }

  onChangeHighlightEndDate(date) {
    this.feed.endHighlightDate = date;
  }

  onChangeResultBeginDate(date) {
    this.feed.beginResultDate = date;
    this.feed.endResultDate = null;
  }

  onChangeResultEndDate(date) {
    this.feed.endResultDate = date;
  }

  disableEndHighlighDate = (current: Date): boolean => {
    if (!this.feed || !this.feed.beginHighlightDate) {
      return true;
    }

    return differenceInCalendarDays(this.feed.beginHighlightDate, current) > 0;
  }

  disableEndResultDate = (current: Date): boolean => {
    if (!this.feed || !this.feed.beginResultDate) {
      return true;
    }

    return differenceInCalendarDays(this.feed.beginResultDate, current) >= 0;
  }

  checkAndInitEditPost() {
    if (this.feed?.id) {
      this.feed.isCreate = false;
      this.modelUpload.entityId = this.feed.id;
      this.modelUpload.entityType = Constants.ENTITY_TYPE.NewsFeed;
    }
  }
}
