import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WallNewsFeedService, ReactionService, Comment, ReactionComment } from '@intranet/lib/data-access';
import { ModalService, ModalSize } from '@intranet/lib/settings/services/modal.service';
import { BaseUserComponent, ModalConfirmComponent } from '@intranet/lib/components';
import { UrlConstant } from '@intranet/index';
import * as _ from 'lodash';
import { NewsFeedReactionModalComponent } from '../../news-feed-reaction/news-feed-reaction-modal/news-feed-reaction-modal.component';

@Component({
  selector: 'app-news-feed-comment-item',
  templateUrl: './news-feed-comment-item.component.html',
  styleUrls: ['./news-feed-comment-item.component.scss']
})
export class NewsFeedCommentItemComponent extends BaseUserComponent implements OnInit {
  @Input() comment: Comment;

  @Input() activeReply: string;
  @Output() activeReplyChange = new EventEmitter<string>();

  @Output() replyComment = new EventEmitter<Comment>();
  @Output() editComment = new EventEmitter<Comment>();
  @Output() deleteComment = new EventEmitter<Comment>();
  @Output() reactComment = new EventEmitter<Comment>();

  activeModal: NgbModalRef;
  public userLiked: boolean;
  urlConstant = UrlConstant;
  isSummiting = false;

  isLoadingReaction = false;
  commentReactions: ReactionComment[];

  constructor(
    private newsFeedService: WallNewsFeedService,
    private reactionService: ReactionService,
    private modalService: ModalService,
  ) {
    super();
    this.userLiked = false;
  }

  ngOnInit() {
    if (
      this.comment.reactions &&
      _.findIndex(this.comment.reactions, reaction => {
        return reaction.reaction === 1;
      }) >= 0
    ) {
      this.userLiked = true;
    }
  }

  reply() {
    this.activeReply = this.comment.parentId || this.comment.id;
    this.activeReplyChange.emit(this.activeReply);
    this.replyComment.emit(this.comment);
  }

  like() {
    if (this.isSummiting) {
      return;
    }
    this.isSummiting = true;
    const params = {
      noLoading: true,
      commentId: this.comment.id,
    };

    this.reactionService.toggleComment(
      params,
      res => {
        this.isSummiting = false;
        if (res && res.id > 0) {
          this.userLiked = !this.userLiked;
          if (res.status > 0) {
            this.userLiked = true;
            this.comment.totalLikes++;
          } else {
            this.userLiked = false;
            this.comment.totalLikes--;
            this.comment.totalLikes = Math.max(0, this.comment.totalLikes);
          }

          this.reactComment.emit(this.comment);
        }
      },
      error => { },
      () => {

      },
    );
  }

  onEditComment(comment: Comment) {
    this.editComment.emit(comment);
  }

  onDeleteComment(comment: Comment) {
    const modal = this.modalService.open(ModalConfirmComponent, {
      size: ModalSize.lg,
    });
    modal.componentInstance.lbConfirm = 'Delete';
    modal.componentInstance.title = 'Delete comment?';
    modal.componentInstance.description = 'Are you sure you want to delete this comment?';

    modal.result.then(result => {
      if (result) {
        this.newsFeedService.deleteComment(comment.id, (res) => {
          if (res && res.id && res.id > 0) {
            this.deleteComment.emit(this.comment);
          }
        });
      }
    });
    // Bootbox.confirm('Delete comment?', 'Are you sure you want to delete this comment?').subscribe(res => {
    //   if (res) {
    //     this.newsFeedService.deleteComment(comment.id, (res) => {
    //       if (res && res.id && res.id > 0) {
    //         this.deleteComment.emit(this.comment);
    //       }
    //     });
    //   }
    // });
  }

  showReactions() {
    if (this.comment && this.comment.id) {
      const modal = this.modalService.open(NewsFeedReactionModalComponent, {
        size: ModalSize.md,
      });
      modal.componentInstance.commentId = this.comment.id;
    }
  }
}
