import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CommonDepartmentDto, DepartmentGroupProfile, MemberDto, SearchBenefitWidget, SearchBenefitWidgetMembers, UserService, WidgetService } from '@intranet/lib/data-access';
import { WidgetBenefit } from '@intranet/lib/data-access/models/benefit.model';
import { BenefitDataTypeEnum } from '@intranet/lib/data-access/models/enums/benefit-data-type.enum';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import { WebsiteEvent } from '@intranet/lib/settings/event-store/website.event';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-widget-benefit',
  templateUrl: './widget-benefit.component.html',
  styleUrls: ['./widget-benefit.component.scss']
})
export class WidgetBenefitComponent implements OnChanges, OnInit, OnDestroy {
  @Input() widget: any;
  @Input() resizeEvent: any;
  @Input() widgetLoaded: EventEmitter<any>;

  departmentGroups: DepartmentGroupProfile[] = [];
  departmentGroups$: Subscription = new Subscription();
  selectGroup$: Subscription;

  benefitDataTypeEnum = BenefitDataTypeEnum;

  searchBenefitWidget: SearchBenefitWidget = new SearchBenefitWidget();

  totalBenefitMemberWidget = 0;
  searchBenefitMemberWidget: SearchBenefitWidgetMembers = new SearchBenefitWidgetMembers();

  benefits: WidgetBenefit[] = [];
  members: any[] = [];

  constructor(private userService: UserService,
    private websiteEvent: WebsiteEvent,
    private widgetService: WidgetService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.widget?.previousValue !== changes?.widget?.currentValue) {
      if (this.widget?.data?.isDisplayDataManage) {
        this.selectGroupSubscription();
        return;
      }
      this.loadBenefit();
    }
  }

  ngOnInit() {
    this.departmentGroups$ = this.userService.getDepartmentGroupBehavior().subscribe(departmentGroups => {
      this.departmentGroups = departmentGroups;
    });
  }

  selectGroupSubscription() {
    this.selectGroup$ = this.websiteEvent.groupSourceDataEvent.subscribe((departmentId: any) => {
      if (!this.searchBenefitWidget.departmentId || departmentId === '') {
        this.searchBenefitWidget.departmentId = departmentId !== '' ? departmentId : null;
        this.loadBenefit();
      }
    });
  }

  ngOnDestroy() {
    if (this.departmentGroups$) {
      this.departmentGroups$.unsubscribe();
    }
    if (this.selectGroup$) {
      this.selectGroup$.unsubscribe();
    }
  }

  modalChanged(data: any) {
    this.searchBenefitWidget.departmentId = data;
    this.searchBenefitMemberWidget = new SearchBenefitWidgetMembers();
    this.loadBenefit();
    if (data !== null) {
      this.websiteEvent.setGroupSourceDataEvent(data);
    }
  }

  widgetLoadedCb() {
    this.widgetLoaded?.emit(this.widget);
  }

  private async loadBenefit() {
    try {
      // this.benefits = await this.widgetService.getWidgetBenefits(this.searchBenefitWidget);
      // this.widgetLoaded?.emit(this.widget);
    }
    catch (res) {
      ErrorHelper.displayMessage(res);
    }
  }
}
