import { Constants } from '.';
import { CheckInCheckOutStatusEnum } from '../data-access';
const TextColor = Constants.TextColor;

export const CheckInCheckOutConstants = {
  Status: CheckInCheckOutStatusEnum,
  CheckInCheckOutStatus: {
    Display: {
      [CheckInCheckOutStatusEnum.New]: {
        name: 'Waiting for approval',
        color: TextColor.Warning
      },
      [CheckInCheckOutStatusEnum.Approved]: {
        name: 'Approved',
        color: TextColor.Success
      },
      [CheckInCheckOutStatusEnum.Rejected]: {
        name: 'Rejected',
        color: TextColor.Danger
      }
    },
    DisplayClient: {
      [CheckInCheckOutStatusEnum.None]: {
        name: 'New',
        color: TextColor.Default,
      },
      [CheckInCheckOutStatusEnum.New]: {
        name: 'Waiting for approval',
        color: TextColor.Warning
      },
      [CheckInCheckOutStatusEnum.Approved]: {
        name: 'Approved',
        color: TextColor.GreenSuccess
      },
      [CheckInCheckOutStatusEnum.Rejected]: {
        name: 'Rejected',
        color: TextColor.Danger
      }
    },
    Array: [
      { id: CheckInCheckOutStatusEnum.New, name: 'New', color: TextColor.Info },
      { id: CheckInCheckOutStatusEnum.Approved, name: 'Approved', color: TextColor.Success },
      { id: CheckInCheckOutStatusEnum.Rejected, name: 'Rejected', color: TextColor.Danger }
    ]
  }
};
