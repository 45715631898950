export enum ReviewFormStatusEnum {
  New = 1,
  Reviewing = 2,
  Completed = 3,
}

export enum ReviewFormDisplayEnum {
  Status = 1,
  Level = 2,
}

export enum ReviewFormMemberStatusFormEnum {
  New = 1,
  Verifying = 2,
  Verified = 3,
  Finalizing = 4,
  Completed = 5,
}

export enum ReviewFormMemberStatusEnum {
  New = 1,
  Submitted = 2,
  Completed = 3,
  Rejected = 4,
}

export enum ReviewFormAppraiserStatusFormEnum {
  New = 1,
  Verifying = 2,
  Verified = 3,
}

export enum ReviewFormAppraiserStatusEnum {
  New = 1,
  Submitted = 2,
  Completed = 3,
  Rejected = 4,
}

export enum ReviewFormSelectMemberDisplayStateEnum {
  All,
  CheckedOnly,
  UncheckedOnly,
  UnfilledAppraiser,
}

export enum ReviewFormPeerStatusEnum {
  New = 1, // to indicate a peer has NOT rated the form
  Submitted = 2, // to indicate a peer has rated the form
}

export enum ReviewFormRoleEnum {
  Appraiser = 1,
  Appraisee = 2,
  Peer = 3,
}
