import { ProjectItemTypeEnum, ProjectItemPriorityEnum } from './enums/project.enum';
import { ProjectMember } from './project-member.model';
import { ITreeNode } from './bases/tree-node.model';
import { ProjectItemDocumentDto } from './project-item-document.model';
import { ProjectItemRelationListDto } from './project-relation.model';
import { ProjectItemHistoryDto } from './project-item-history.model';

export class SearchDataModelProjectItem {
  keyWord: string;
  sprintId: string;
  projectId: string;
  isCurrentSprint: boolean;
  itemTypes: ProjectItemTypeEnum[];
}

export class ProjectItemFilterDto {
  searchTitle: string;
  searchMemberId: string[] = [];
  searchStates: number[];
  searchTypes: ProjectItemTypeEnum[];
}

export class ProjectItemDto {
  projectItemId: string;
  projectItemCode: string;
  projectId: string;
  title: string;
  description: string;
  stateId: number;
  stateLabel: string;
  projectItemType: ProjectItemTypeEnum;
  priority: ProjectItemPriorityEnum;
  estimatedHours: number;
  remainingHours: number;
  totalHours: number;
  projectIterationId?: string;
  order?: number;
  parentProjectItemId?: string;
  projectMemberId: string;
  isVirtualItem: boolean; // is used to handle in UI only
}

export class ProjectItemDetailDto {
  info: ProjectItemDto;
  documents: ProjectItemDocumentDto[];
  relations: ProjectItemRelationListDto[];
  history: ProjectItemHistoryDto;
}

export class ProjectItemListDto extends ProjectItemDto {
  projectMember: ProjectMember;
  iterationName: string;
  totalChildren: number;
  totalFinishedChildren: number;
  totalFinishedPercentage: number;
}

export class BackLogKanbanDto {
  projectItemId: string;
  projectItemType: ProjectItemTypeEnum;
  title: string;
  status: number;
  statusLabel: string;
  projectMember: string;
  totalTask: number;
  totalBug: number;
}

export class ProjectItemTreeDto implements ITreeNode {
  key: string;
  order: number;
  title: string;
  state: string;
  member: string;
  type: ProjectItemTypeEnum;
  sprintId: string;
  sprint: string;
  level?: number;
  expand?: boolean;
  totalChildren: number;
  totalFinishedChildren: number;
  totalFinishedPercentage: number;
  children?: ProjectItemTreeDto[];
  parent?: ProjectItemTreeDto;
}

export class NewItemDropdownModel {
  type: ProjectItemTypeEnum;
  display: string;
  icon: string;

  constructor(iType: ProjectItemTypeEnum, iDisplay: string, iIcon: string) {
    this.type = iType;
    this.display = iDisplay;
    this.icon = iIcon;
  }
}
