import { ReviewTemplateStatusEnum, ReviewTemplateTypeEnum } from '../enums/review-template.enum';
import { ReviewSection } from './review-section.model';

export class ReviewTemplate {
  id: string;
  title: string;
  type: ReviewTemplateTypeEnum;
  status: ReviewTemplateStatusEnum;
  reviewPerformanceSections: ReviewSection[];
  isEdit = true;
  note: string;
  hasPeer: boolean;

  constructor() {
    this.type = ReviewTemplateTypeEnum.Annual;
    this.status = ReviewTemplateStatusEnum.New;
  }
}
