export const EVENT_NAME = {
  ViewCalendar: 'ViewCalendar',
  ViewLeave: 'ViewLeave',
  AddLeave: 'AddLeave',
  BookMeeting: 'BookMeeting',
  MiniCalendar_SwitchMonth: 'MiniCalendar_SwitchMonth',
  MiniCalendar_PickDate: 'MiniCalendar_PickDate',
  Calendar_Leave: 'Calendar_Leave',
  Calendar_Meeting: 'Calendar_Meeting',
  PickToday: 'PickToday',
  Calendar_Event: 'Calendar_Event',
  Filter_MeetingRoom_Open: 'Filter_MeetingRoom_Open',
  Filter_Group_Open: 'Filter_Group_Open',
  Filter_MeetingRoom_ClickOption: 'Filter_MeetingRoom_ClickOption',
  View_ByDay: 'View_ByDay',
  View_ByWeek: 'View_ByWeek',
  View_ByMonth: 'View_ByMonth',
  Filter_Group_ClickOption: 'Filter_Group_ClickOption',
  SwitchTimeline: 'SwitchTimeline',

  Leave: 'SectionNav_Leaves',
  Attendance: 'SectionNav_SelfManagement',
  TrackingTimeWeekPersonal: 'SectionNav_Timesheet',
  Calendar: 'SectionNav_EventsAndCalendar',
  Benefit: 'SectionNav_Benefit',
  Newcomer: 'SectionNav_Newcomer',
  Todo: 'SectionNav_TodoList',
  EquipmentPersonal: 'SectionNav_Asset',
  Review: 'SectionNav_Review',
  Team: 'SectionNav_TechnicalSkills',
  WorkExperience: 'SectionNav_WorkExperience',
  Equipment: 'SectionNav_PurchasingCenter',
  TrackingTimeWeekCompany: 'SectionNav_TrackingTimesheetWeek',
  Company: 'SectionNav_CompanyMembers',
  HRInformation: 'SectionNav_HrStatistics',
};

export const EVENT_CATEGORY = {
  Dashboard: 'Dashboard',
  Calendar: 'Calendar',
  Menu: 'Menu',
};

export const DASHBOARD_LIST = {
  '00000000-0000-0000-0000-000000000001': 'Personal',
  '00000000-0000-0000-0000-000000000003': 'Team',
  '00000000-0000-0000-0000-000000000004': 'Company',
};
