import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { IndexdbModel } from './indexdb.model';
import { PurchaseOrderDetail } from '../models';

@Injectable({
  providedIn: 'root',
})
export class IndexDbPurchaseService {
  constructor(private dbService: NgxIndexedDBService) {}

  // async insert(item: PurchaseOrderDetail) {
  //   const check = await this.getByValue('userKey', item.userKey);
  //   if (!check) {
  //     this.add(item);
  //   } else {
  //     check.payload = item.payload;
  //     await this.update(check);
  //   }
  // }

  async add(item: PurchaseOrderDetail) {
    return new Promise((resolve, reject) => {
      this.dbService.add('purchase_order', item).then(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        },
      );
    });
  }

  async update(item: PurchaseOrderDetail): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dbService.update('purchase_order', item).then(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        },
      );
    });
  }

  async delete(key: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dbService.delete('purchase_order', key).then(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        },
      );
    });
  }
  async getAll(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dbService.getAll('purchase_order').then(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        },
      );
    });
  }

  async getById(value: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dbService.getByKey('purchase_order', value).then(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        },
      );
    });
  }
  async clearAll(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dbService.clear('purchase_order').then(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        },
      );
    });
  }
}
