import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { WidgetEmployeeGenderDto, WidgetSearchDto, WidgetService } from '@intranet/lib/data-access';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import { WebsiteEvent } from '@intranet/lib/settings/event-store/website.event';
import { GridsterItem } from 'angular-gridster2';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-widget-employee-gender',
  templateUrl: './widget-employee-gender.component.html',
  styleUrls: ['./widget-employee-gender.component.scss']
})
export class WidgetEmployeeGenderComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;
  @Input() widgetLoaded: EventEmitter<any>;
  resizeSub: Subscription;
  selectGroupSubscription: Subscription;

  loading = true;

  searchData: WidgetSearchDto = new WidgetSearchDto();
  data: WidgetEmployeeGenderDto = new WidgetEmployeeGenderDto();
  constructor(private _widgetService: WidgetService,
    private websiteEvent: WebsiteEvent) { }

  ngOnDestroy() {
    if (this.selectGroupSubscription) {
      this.selectGroupSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.selectGroupSubscriptionEvent();

    this.resizeSub =
      this.resizeEvent &&
      this.resizeEvent.subscribe(widget => {
        if (widget === this.widget) {
          this.data = null;
          setTimeout(() => {
            this.loadData();
          }, 100);
        }
      });
  }

  selectGroupSubscriptionEvent() {
    this.selectGroupSubscription = this.websiteEvent.groupSourceDataEvent.subscribe((departmentId: any) => {
      if (!this.searchData.departmentIds || departmentId === '') {
        this.searchData.departmentIds = departmentId !== '' ? departmentId : null;
        this.loadData();
      }
    });
  }

  onResizePie(event) {
    this.data = null;
    setTimeout(() => {
      this.loadData();
    }, 100);

  }

  async loadData() {
    this.loading = true;
    try {
      this.data = await this._widgetService.getWidgetEmployeeGender(this.searchData);
      this.widgetLoaded?.emit(this.widget);
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
    finally {
      this.loading = false;
    }
  }

  modelChanged(event) {
    this.searchData.departmentIds = event;
    this.loadData();
    if (event !== null) {
      this.websiteEvent.setGroupSourceDataEvent(event);
    }
  }
}
