import { Injectable } from '@angular/core';
import { HttpClientService } from '../../http/http-client';
import { Constants } from '../../config';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  hostUrl = AppConfig.settings.API_URL + '/api/system/';
  constructor(private httpClient: HttpClientService) {}
  getListSettingAll() {
    const url = this.hostUrl + `get-list-setting`;
    return this.httpClient.getSync(url);
  }

  updateSetting(row) {
    const url = this.hostUrl + `update-setting`;
    return this.httpClient.putSync(url, row);
  }
  senEmailVerifyRegister(data: any) {
    const url = this.hostUrl + `send-mail-verify-register`;
    return this.httpClient.putSync(url, data);
  }

  getImageBase64(imgPath: string): Promise<string> {
    const requestUrl = `${this.hostUrl}get_img_base64?image_path=${imgPath}`;
    return this.httpClient.getSync(requestUrl);
  }
}
