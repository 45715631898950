import { Action } from '@ngrx/store';

export enum UserActionTypes {
  Login = '[User Component] Login',
  Info = '[User Component] Info',
}
export class ActionEx implements Action {
  readonly type;
  payload: any;
}
export class UserLogin implements ActionEx {
  readonly type = UserActionTypes.Login;
  constructor(public payload: any) {}
}
export class UserInfo implements ActionEx {
  readonly type = UserActionTypes.Info;
  constructor(public payload: any) {}
}
