import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class TemplateHtmlService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/template-html';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getByKey(key: any) {
    const url = this.hostUrl + `/get_by_key?key=` + key;
    return this.httpClient.get(url);
  }
}
