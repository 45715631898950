import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseAdminComponent } from '@intranet/lib/components';
import { SearchModel, SurveyService, Survey } from '@intranet/lib/data-access';
import { NotificationModel } from '@intranet/lib/data-access/models/notification.model';
import { IPageInfo } from 'ngx-virtual-scroller';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-surveys-page',
  templateUrl: './surveys-page.component.html',
  styleUrls: ['./surveys-page.component.scss']
})
export class SurveysPageComponent extends BaseAdminComponent implements OnInit, OnDestroy {
  search: SearchModel = new SearchModel();
  list: Survey[];
  isBuffer = true;
  subscriptions: Subscription[] = [];
  isShowToolbox = false;
  constructor(private surveyService: SurveyService) {
    super();
    this.search.pageNumber = 0;
    this.search.pageSize = 10;
    this.search.totalItems = 0;
  }

  fetchMore(event: IPageInfo) {
    if (this.list) {
      if (event.endIndex >= this.list.length - 1) {
        this.search.pageNumber = this.search.pageNumber + this.search.pageSize;
        this.search.pageSize = 1;
        this.loadList();
      }
    }
  }

  ngOnInit() {
    this.loadList();
  }

  loadList() {
    this.surveyService.getUserSurveys(this.search).then((res: Survey[]) => {
      if (res && res.length > 0) {
        if (this.list) {
          this.list = this.list.concat(res);
        } else {
          this.list = res;
        }

      } else {
        this.isBuffer = false;
      }
    });

  }

  onOpenSurvey(item) {

  }

  loadResetList() {
    this.isBuffer = true;
    this.search.pageNumber = 0;
    this.search.pageSize = 10;
    this.list = [];
    this.loadList();
  }
}
