export class ModalDto {
  title: string;
  textOk = 'Ok';
  textCancel = 'Cancel';
  data: any;
  description: string;
  isLoading = false;
  size: string;
  btnClass = 'btn-danger';
  isRequired = false;
  reason = '';
  id = '';
  actionOk: any;
}
