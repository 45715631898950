<header class="header">
  <div class="logo-wrapper">
    <a [routerLink]="['/' + urlConstant.NewsFeedUrl.ROOT]" class="logo">
      <img width="100" src="assets/images/front/logo.png" type="local">
    </a>
  </div>
  <div class="header-items">
    <!-- Header actions start -->
    <ul class="header-actions">
      <li class="dropdown">
        <a nz-tooltip nzTooltipTitle="New Feeds" nzTooltipPlacement="bottom" routerLink="/news-feed">
          <i class="text-white" nz-icon nzType="appstore-add" nzTheme="outline"></i>
        </a>
      </li>
      <!-- Notify icon -->
      <li class="dropdown">
        <!-- <a href="#" id="notifications" data-toggle="dropdown" aria-haspopup="true">
          <i class="icon-bell"></i>
          <span class="count-label">8</span>
        </a> -->
        <div class="dropdown-menu dropdown-menu-right lrg" aria-labelledby="notifications">
          <div class="dropdown-menu-header">
            Notifications (40)
          </div>
          <ul class="header-notifications">
            <li>
              <a href="#">
                <div class="user-img away">
                  <img src="img/user21.png" alt="User">
                </div>
                <div class="details">
                  <div class="user-title">Abbott</div>
                  <div class="noti-details">Membership has been ended.</div>
                  <div class="noti-date">Oct 20, 07:30 pm</div>
                </div>
              </a>
            </li>
            <li>
              <a href="#">
                <div class="user-img busy">
                  <img src="img/user10.png" alt="User">
                </div>
                <div class="details">
                  <div class="user-title">Braxten</div>
                  <div class="noti-details">Approved new design.</div>
                  <div class="noti-date">Oct 10, 12:00 am</div>
                </div>
              </a>
            </li>
            <li>
              <a href="#">
                <div class="user-img online">
                  <img src="img/user6.png" alt="User">
                </div>
                <div class="details">
                  <div class="user-title">Larkyn</div>
                  <div class="noti-details">Check out every table in detail.</div>
                  <div class="noti-date">Oct 15, 04:00 pm</div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li class="dropdown">
        <a href="#" id="userSettings" class="user-settings" data-toggle="dropdown" aria-haspopup="true">
          <span class="user-name">{{fullName}}</span>
          <span class="avatar">{{cutName}}<span class="status busy"></span></span>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userSettings">
          <div class="header-profile-actions">
            <div class="header-user-profile">
              <div class="header-user">
                <img [src]="avatarUrl" type="cdn" default="M_ReferUser-1.png" type="cdn" alt="user" />
              </div>
                <h5>{{fullName}}</h5>
                <p><i class="fas fa-user-secret mr5"></i>{{currentUser.groupName}}</p>
              </div>
              <ng-container>
                <a routerLink="/dashboard"><i  class="ti-user"></i> My Dashboard</a>
                <a *ngIf="currentUser.hasEmployee" routerLink="/user/profile"><i class="icon-user"></i> Account Settings</a>
              </ng-container>
              <ng-container>
                <a (click)="openChangePassword()"><i class="icon-settings"></i> Change Password</a>
              </ng-container>

              <a (click)="logout()"><i class="icon-log-out1"></i> Sign Out</a>
            </div>
          </div>
      </li>
    </ul>
    <!-- Header actions end -->
  </div>
</header>
<div class="quick-links-box" [ngClass]="{'quick-links-box-show': showQuickLink}" id="clickbox">
  <div class="quick-links-header">
    Quick Links
    <a href="#" (click)="toggleQuickLink(false)" class="quick-links-box-close">
      <i class="icon-circle-with-cross"></i>
    </a>
  </div>

  <div class="quick-links-wrapper">
    <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 100%;">
      <div class="fullHeight" style="overflow: hidden; width: auto; height: 100%;">
        <div class="quick-links-body">
          <div class="container-fluid p-0">
            <div class="row less-gutters">
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                <a href="user-profile.html" class="quick-tile">
                  <i class="icon-account_circle"></i>
                  Profile
                </a>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                <a href="contacts.html" class="quick-tile">
                  <i class="icon-phone"></i>
                  Contacts
                </a>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                <a href="account-settings.html" class="quick-tile">
                  <i class="icon-settings1"></i>
                  Settings
                </a>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                <a href="login.html" class="quick-tile">
                  <i class="icon-lock2"></i>
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
