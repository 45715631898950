import { BaseModel } from '../base-model';
import { ColumnTemplateStatusEnum } from '../enums/review-column-template-status.enum';
import { ColumnTemplateTypeEnum } from '../enums/review-column-template-type.enum';

export class ReviewColTemplate extends BaseModel {
  id: string;
  title: string;
  status: ColumnTemplateStatusEnum;
  reviewPerformanceColumnTemplateDetails: ReviewColTemplateDetail[];
}

export class ReviewColTemplateDetail extends BaseModel {
  id: string;
  reviewPerformanceColumnTemplateId: string;
  title: string;
  order: number;
  groupOrder: number;
  level: number;
  maxLevel: number;
  hasFillDataMember: boolean;
  reviewPerformanceCriteriaTypeId: string | null;
  reviewPerformanceCriteriaTypeTitle: string;
  type: ColumnTemplateTypeEnum;
  isRequired: boolean;
  width: number;
  widthDisplay: string;
  hasFixWidth: boolean;
}
