<modal-header [title]="headerTemplate" [activeModal]="activeModal">
  <ng-template #headerTemplate>
    <h4 class="modal-title">[Update] Form Appraisers for {{ member?.user?.fullName }}</h4>
  </ng-template>
</modal-header>
<modal-body>
  <div class="row mb-2">
    <div class="col-3">
      <input
        nz-input
        placeholder="Name, email, ..."
        type="text"
        [(ngModel)]="keyword"
        (ngModelChange)="search()"
        name="search-keyword"
      />
    </div>
    <div class="col-3">
      <nz-select nzAllowClear nzPlaceHolder="Select a department" [(ngModel)]="selectedDepartment" (ngModelChange)="search()">
        <nz-option *ngFor="let dept of displayTable.departmentList" [nzLabel]="dept.name" [nzValue]="dept.id"></nz-option>
      </nz-select>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <nz-table
        #selectAppraiserTable
        [nzData]="filteredAppraisers"
        [nzPageSize]="displayTable.pageSize"
        [nzPageIndex]="displayTable.pageNumber"
        nzSize="middle"
        [nzShowPagination]="false"
      >
        <thead>
          <tr>
            <th
              class="table-header"
              nzWidth="50px"
              [nzChecked]="displayTable.checked"
              [nzIndeterminate]="displayTable.indeterminate"
              (nzCheckedChange)="selectAllAppraiser($event)"
            ></th>
            <th class="table-header" nzWidth="300px">Name</th>
            <th class="table-header" nzWidth="300px">Email</th>
            <th class="table-header header-select" nzWidth="150px" *ngIf="!restrictedMode">
              Main Appraiser
              <i
                class="tooltip-icon"
                nz-icon
                nzTheme="twotone"
                nzType="info-circle"
                nz-tooltip
                [nzTooltipTitle]="displayTable.tooltip.MainAppraiser"
              ></i>
            </th>
            <th class="table-header header-select" nzWidth="150px">
              Rating Permission
              <i
                class="tooltip-icon"
                nz-icon
                nzTheme="twotone"
                nzType="info-circle"
                nz-tooltip
                [nzTooltipTitle]="displayTable.tooltip.RatingPermission"
              ></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let appraiser of selectAppraiserTable.data; let appraiserIndex = index">
            <tr [ngClass]="{ 'not-selected': !appraiser.isChecked }">
              <td class="text-center">
                <label
                  *ngIf="!restrictedMode || !appraiser.isFreezed"
                  nz-checkbox
                  [(ngModel)]="appraiser.isChecked"
                  (ngModelChange)="selectAppraiser(appraiser, $event)"
                  class="inline"
                >
                </label>

                <span class="selected-icon">
                  <i
                    *ngIf="restrictedMode && appraiser.isFreezed"
                    nz-icon
                    nzType="check-circle"
                    nzTheme="outline"
                    nz-tooltip
                    [nzTooltipTitle]="appraiser.user.fullName + ' has already added'"
                  ></i>
                </span>
              </td>
              <td>{{ appraiser.user.fullName }}</td>
              <td>{{ appraiser.user.userName }}</td>
              <td *ngIf="!restrictedMode" class="header-select">
                <label
                  nz-radio
                  class="check-input"
                  (click)="setMainAppraiser(appraiser)"
                  [ngModel]="appraiser.isMainAppraiser && appraiser.isChecked"
                  [nzDisabled]="!appraiser.isChecked || restrictedMode"
                ></label>
              </td>
              <td class="header-select">
                <label
                  nz-checkbox
                  [ngModel]="!appraiser.isViewOnly && appraiser.isChecked"
                  (ngModelChange)="setHasRatingPermission(appraiser, $event)"
                  [nzDisabled]="!appraiser.isChecked"
                  class="check-input"
                >
                </label>

                <i
                  *ngIf="appraiser.statusForm === displayTable.reviewFormAppraiserStatusFormEnum.Verified"
                  class="tooltip-icon ml-2 check-input"
                  nz-icon
                  nzTheme="twotone"
                  nzType="info-circle"
                  nz-tooltip
                  [nzTooltipTitle]="appraiser.user.fullName + ' has already verified the form.'"
                ></i>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>
</modal-body>
<modal-footer>
  <nz-pagination
    class="footer-pagination"
    [(nzPageIndex)]="displayTable.pageNumber"
    [nzTotal]="filteredAppraisers?.length || 0"
    nzSize="small"
    [nzShowSizeChanger]="true"
    [(nzPageSize)]="displayTable.pageSize"
    [nzPageSizeOptions]="displayTable.pageSizeOptions"
    [nzShowTotal]="totalTemplate"
  ></nz-pagination>
  <ng-template #totalTemplate let-total> Selected {{ displayTable.totalSelectedAppraisers }} </ng-template>
  <create-update-button
    [isCancel]="true"
    (cancel)="close()"
    (submit)="updateAppraisers()"
    content="Save"
    [disabled]="false && displayTable.totalSelectedAppraisers <= 0"
    [isConfirm]="restrictedMode"
    [confirmText]="
      (isVerifiedAppraiserRoleChanged ? 'You have changed role for some verfied appraisers. ' : '') +
      'You can not remove appraisers after saving. Proceed?'
    "
  >
  </create-update-button>
</modal-footer>
