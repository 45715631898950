import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { DateHelper } from '../helpers';
import { I18nService } from '../settings';

@Pipe({
  name: 'ngkDate',
  pure: false,
})
export class NgkDatePipe implements PipeTransform {
  constructor(private translateService: I18nService) {}
  transform(value: any, pattern?: string): any {
    let format = this.translateService.currentLocale === 'vi' ? 'dd/MM/yyyy HH:mm:ss a' : 'yyyy/MM/dd HH:mm:ss a';
    if (!value) {
      return '';
    }
    if (pattern) {
      format = pattern;
    }
    value = DateHelper.removeISO(value);
    const tm = formatDate(value, format, this.translateService.currentLocale, 'Asia/Ho_Chi_Minh');
    return tm;
  }
}
