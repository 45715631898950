import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AnnualLeave, AnnualLeaveService, LeaveTransferService } from '@intranet/lib/data-access';
import { Toastr } from '@intranet/lib/settings';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '@intranet/index';

@Component({
  selector: 'app-leave-transfer-modal',
  templateUrl: './leave-transfer-modal.component.html',
  styleUrls: ['./leave-transfer-modal.component.scss']
})
export class LeaveTransferModalComponent implements OnInit {
  @Input() displayInfo: any;

  activeModal: NgbActiveModal;
  userAnnualLeave: any;
  currentYear = new Date().getFullYear();

  formGroup: FormGroup;

  get sellDays() { return this.formGroup.get('sellDays'); }
  get transferDays() { return this.formGroup.get('transferDays'); }

  get isValidSellDays() {
    const sell = +this.sellDays?.value;
    return sell % 0.5 === 0 && sell >= 0;
  }

  get isValidTransferDays() {
    const transfer = +this.transferDays?.value;
    return transfer % 0.5 === 0 && transfer >= 0;
  }

  get totalMissingHoursInDayUnit(): number {
    return +(Math.abs(this.displayInfo?.totalMissingHours) / Constants.HoursPerWorkDay).toFixed(1);
  }

  get totalAnnualLeavesNextYear(): number {
    return +this.transferDays?.value + this.displayInfo.totalBaseAnnualLeaveNextYear;
  }

  get showMissingHoursConfirm() {
    return this.totalMissingHoursInDayUnit > 0
      && this.userAnnualLeave?.remainingDay - (+this.sellDays.value + +this.transferDays.value) < this.totalMissingHoursInDayUnit;
  }

  get canSubmit() {
    const sell = +this.sellDays?.value;
    const transfer = +this.transferDays?.value;
    return sell + transfer <= this.userAnnualLeave?.remainingDay
      && sell <= this.displayInfo.maxSellDays
      && transfer <= this.displayInfo.maxTransferDays
      && this.isValidSellDays
      && this.isValidTransferDays
      && this.totalAnnualLeavesNextYear <= this.displayInfo.maxTotalAnnualLeaves;
  }

  get remainingDays() {
    const remaining = (this.userAnnualLeave?.remainingDay - (+this.sellDays?.value + +this.transferDays?.value)) || 0;

    if (remaining % 1 !== 0) {
      return remaining.toFixed(1);
    }

    return remaining;
  }

  constructor(modalRef: NgbActiveModal,
    private annualLeaveService: AnnualLeaveService,
    private leaveTransferService: LeaveTransferService,
  ) {
    this.activeModal = modalRef;
  }

  async ngOnInit() {
    this.currentYear = this.displayInfo.year;
    this.initForm();
    await this.loadUserAnnualLeave();
  }

  initForm() {
    this.formGroup = new FormGroup({
      sellDays: new FormControl(0),
      transferDays: new FormControl(0)
    });

    if (this.displayInfo) {
      this.sellDays.setValue(this.displayInfo.registeredSellDays || 0);
      this.transferDays.setValue(this.displayInfo.registeredTransferDays || 0);
    }
  }

  async loadUserAnnualLeave() {
    await this.annualLeaveService.getByCurrentEmp('', (resp: AnnualLeave) => {
      this.userAnnualLeave = resp;

      if (!this.userAnnualLeave) {
        Toastr.error('Could not get your annual leave data.');
      }
    }, null, null, this.currentYear);
  }

  async submit() {
    try {
      const data = {
        transferDays: this.transferDays.value || 0,
        sellDays: this.sellDays.value || 0,
      };

      await this.leaveTransferService.confirm(data);

      Toastr.success('Registered sell days and transfer days successfully');
      this.close();
    }
    catch (err) {
      Toastr.error(err);
    }
  }

  close() {
    this.activeModal.close();
  }
}
