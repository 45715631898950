import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'create-update-button',
  template: `
    <ng-container *ngIf="isCancel">
      <button type="button" class="btn btn-white mr10" (click)="eventCancel()" nz-button>
        <i class="far fa-times-circle mr5"></i>Close
      </button>
    </ng-container>
    <button
      *ngIf="!isConfirm"
      type="submit"
      class="btn btn-primary waves-effect {{ class }}"
      [nzLoading]="nzLoading"
      nz-button
      nzType="primary"
      (click)="eventSubmit()"
      [disabled]="disabled"
    >
      <i class="fas fa-save mr5"></i> {{ content | translate }}
    </button>
    <button
      *ngIf="isConfirm"
      type="submit"
      class="btn btn-primary waves-effect {{ class }}"
      [nzLoading]="nzLoading"
      nz-button
      nzType="primary"
      nz-popconfirm
      [nzPopconfirmTitle]="confirmText"
      (nzOnConfirm)="eventSubmit()"
      [disabled]="disabled"
    >
      <i class="fas fa-save mr5"></i> {{ content | translate }}
    </button>
  `,
  styles: [],
})
export class CreateUpdateButtonComponent implements OnInit {
  @Input() content = 'page.common.save';
  @Input() class: string;
  @Input()
  set isUpdate(value: any) {
    if (value) {
      this.content = 'page.common.update';
    }
  }
  @Input() nzLoading = false;
  @Input() isCancel = false;
  @Input() isConfirm = false;
  @Input() confirmText = null;
  @Input() disabled = false;
  @Output() cancel = new EventEmitter();
  @Output() submit = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}

  eventCancel() {
    this.cancel.emit(true);
  }

  eventSubmit() {
    this.submit.emit();
  }
}
