import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService, Loading, Toastr } from '@intranet/index';
import { ApiResponse } from '../models/api-response.model';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class SupplierService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/supplier';
  httpClientt: HttpClientService;

  constructor(httpClient: HttpClientService) {
    super(httpClient);
    this.httpClientt = httpClient;
  }

  updateStatus(params: any, successCallback?: (params?: any) => void) {
    Loading.show();
    const url = this.hostUrl + `/approval`;
    this.httpClientt
      .putSync(url, params)
      .then(
        (resp: ApiResponse) => {
          if (resp && resp.id && resp.id > 0) {
            if (successCallback) {
              successCallback(resp);
            } else {
              Toastr.success('Successfully');
            }
          } else {
            Toastr.error(resp.message);
          }
        },
        (error: any) => {
          Toastr.error(error);
        },
      )
      .finally(() => {
        Loading.hide();
      });
  }
}
