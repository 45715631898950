<div class="calendar-box">
  <calendar-header
    [(view)]="_options.view"
    [durationFilters]="durationFilters"
    [showFilter]="showFilter"
    [dataHeader]="dataHeader"
    [viewDate]="viewDate"
    (viewDateChange)="viewDateChange($event)"
    class="full-width"
    (viewChange)="viewChange($event)"
    (eventFilter)="filter($event)"
  >
  </calendar-header>
  <div [ngSwitch]="_options.view" class="d-block full-width">
    <div *ngSwitchCase="'year'" class="d-flex flex-wrap">
      <div *ngFor="let month of months; let i = index" class="cal-year-view d-flex col-md-4 col-12 flex-column">
        <h3 class="text-center">{{ month | date : 'MMMM' }}</h3>
        <mwl-calendar-month-view [viewDate]="months[i]" [activeDayIsOpen]="true" [refresh]="refresh" [events]="yearEvents" [cellTemplate]="customYearCellTemplate">
        </mwl-calendar-month-view>
      </div>
    </div>

    <mwl-calendar-month-view
      *ngSwitchCase="'month'"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventTimesChanged)="eventTimesChanged($event)"
      (hourSegmentClicked)="hourSegmentClicked.emit($event)"
      (eventClicked)="eventClicked($event)"
      [excludeDays]="_options.excludeDays"
      [cellTemplate]="customCellTemplate"
      (dayClicked)="changeDay($event.day)"
    >
    </mwl-calendar-month-view>

    <mwl-calendar-week-view
      *ngSwitchCase="'week'"
      [dayStartHour]="_options.startHour"
      (eventClicked)="eventClicked($event)"
      [dayEndHour]="_options.endHour"
      [excludeDays]="_options.excludeDays"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventTimesChanged)="eventTimesChanged($event)"
      [hourSegmentTemplate]="weekViewHourSegmentTemplate"
      [hourSegments]="3"
      [eventTitleTemplate]="customTemplate"
      [weekStartsOn]="_options.weekStartsOn"
    >
    </mwl-calendar-week-view>

    <mwl-calendar-day-view
      *ngSwitchCase="'day'"
      [viewDate]="viewDate"
      [refresh]="refresh"
      [dayStartHour]="_options.startHour"
      [dayEndHour]="_options.endHour"
      (hourSegmentClicked)="onClickHourSegment($event)"
      (eventTimesChanged)="eventTimesChanged($event)"
      (eventClicked)="eventClicked($event)"
      [events]="events"
      [eventTitleTemplate]="customTemplate"
      [hourSegmentTemplate]="weekViewHourSegmentTemplate"
    >
    </mwl-calendar-day-view>
  </div>

  <ng-template
    #customYearCellTemplate
    let-day="day"
    let-openDay="openDay"
    let-locale="locale"
    let-tooltipPlacement="tooltipPlacement"
    let-highlightDay="highlightDay"
    let-unhighlightDay="unhighlightDay"
    let-eventClicked="eventClicked"
    let-tooltipTemplate="tooltipTemplate"
    let-tooltipAppendToBody="tooltipAppendToBody"
    let-tooltipDelay="tooltipDelay"
    let-trackByEventId="trackByEventId"
    let-currentDate="day.date.toDateString()"
  >
    <div
      class="cal-cell-top"
      [ngClass]="day.events | pluck : 'cssClass'"
      [attr.aria-label]="{ day: day, locale: locale } | calendarA11y : 'monthCell'"
      nz-tooltip
      [nzTooltipTitle]="day.events | pluck : 'title'"
    >
      <span aria-hidden="true">
        <span class="cal-day-number">{{ day.date | calendarDate : 'monthViewDayNumber' : locale }}</span>
      </span>
    </div>

    <div class="cal-events" *ngIf="day.events.length > 0">
      <ng-container *ngFor="let event of day.events">
        <ng-container [ngSwitch]="event.meta?.calendarType">
          <ng-container *ngSwitchCase="CalendarEventTypeEnum.BOOKING">
            <div
              class="cal-event"
              [ngStyle]="{ backgroundColor: event.meta?.room?.colorInCalendar }"
              [ngClass]="event.cssClass"
              [attr.aria-hidden]="{} | calendarA11y : 'hideMonthCellEvents'"
            ></div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div
              class="cal-event"
              [ngStyle]="{ backgroundColor: event.color?.primary }"
              [ngClass]="event.cssClass"
              [attr.aria-hidden]="{} | calendarA11y : 'hideMonthCellEvents'"
            ></div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #weekViewHourSegmentTemplate let-segment="segment" let-locale="locale" let-segmentHeight="segmentHeight" let-isTimeLabel="isTimeLabel">
    <div
      #segmentElement
      class="cal-hour-segment"
      [style.height.px]="segmentHeight"
      [class.cal-hour-start]="segment.isStart"
      [class.cal-after-hour-start]="!segment.isStart"
      [ngClass]="segment.cssClass"
    >
      <div class="cal-time" *ngIf="isTimeLabel">
        {{ segment.date | calendarDate : 'weekViewHour' : locale }}
      </div>
    </div>
  </ng-template>

  <ng-template #customTemplate let-locale="locale" let-event="event" let-segmentHeight="segmentHeight">
    <ng-container *ngIf="event.meta && !event.meta.tmpEvent">
      <ng-container [ngSwitch]="event.meta?.calendarType">
        <ng-container *ngSwitchCase="CalendarEventTypeEnum.BOOKING">
          <div
            class="event-content"
            nz-popover
            nzPopoverTrigger="click"
            [nzPopoverContent]="popoverContent"
            [(nzPopoverVisible)]="event.visible"
            [style.height]="calculateHeight(event)"
          >
            <span class="icon" *ngIf="event?.meta?.occurType" nz-icon nzType="retweet" nzTheme="outline"></span>
            <span class="text"> [{{ event?.meta?.departmentGroup?.name }}] at {{ event?.meta?.room?.name }}</span>
            <span class="text"> {{ event.title }}: {{ event.start | amDateFormat : 'HH:mm' }} to {{ event.end | amDateFormat : 'HH:mm' }} </span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="CalendarEventTypeEnum.LEAVE">
          <span class="time">{{ event.title }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="CalendarEventTypeEnum.EVENT">
          <span class="time" *ngIf="!event.meta?.newsFeedId">{{ event.title }}</span>
          <a (click)="navigate(event)" target="_blank" class="time" *ngIf="event.meta?.newsFeedId">{{ event.title }}</a>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span class="time">{{ event.title }}</span>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #popoverContent>
      <div class="calendar-popover">
        <div class="action-popover">
          <a class="m-l-sm event-over-link" *ngIf="event.meta && event.meta.owner" (click)="edit(event)"
            ><img src="assets/images/front/shortcut/edit.svg" type="local"
          /></a>
          <a
            class="m-l-sm event-over-link"
            *ngIf="event.meta && event.meta.owner"
            nz-popconfirm
            nzPopconfirmTitle="Are you sure delete this item?"
            (nzOnConfirm)="delete(event)"
            ><img src="assets/images/front/shortcut/delete.svg" type="local"
          /></a>
          <a class="m-l-sm event-over-link" (click)="closeOver(event)"><img src="assets/images/icons/icon-close.svg" type="local" /></a>
        </div>
        <span class="title-popover">{{ event.title }}</span>

        <span
          >Created by
          <span class="color-primary">{{ event.meta.createdByName }}</span>
          for {{ event.meta?.createdDate | amTimeAgo }}
        </span>

        <div class="information-item">
          <div class="title">
            <b>Date Time</b>
          </div>
          <div class="content">
            <img src="assets/images/front/shortcut/calendar.svg" type="local" />
            <span>{{ event.start | ngkDate : 'HH:mm a' }} - {{ event.end | ngkDate : 'HH:mm a' }}</span>
          </div>
        </div>

        <div class="information-item">
          <div class="title">
            <b>Group / Department</b>
          </div>
          <div class="content">
            <img src="assets/images/icons/department.svg" type="local" />
            <span>{{ event.meta?.departmentGroup?.name }}</span>
          </div>
        </div>

        <div class="information-item">
          <div class="title">
            <b>Room</b>
          </div>
          <div class="content">
            <img src="assets/images/icons/room.svg" type="local" />
            <span>{{ event.meta?.roomName }}</span>
          </div>
        </div>

        <div class="information-item">
          <div class="title">
            <b>Description</b>
          </div>
        </div>
        <div class="description">
          <span>{{ event.meta.description }}</span>
        </div>
      </div>
    </ng-template>
  </ng-template>

  <ng-template #customCellTemplate let-day="day" let-locale="locale">
    <div class="cal-cell-top">
      <span class="cal-day-number">{{ day.date | calendarDate : 'monthViewDayNumber' : locale }}</span>
    </div>
    <small *ngIf="day.events && day.events.length" style="margin: 5px">
      <ul>
        <li *ngFor="let event of day.events">
          <ng-template [ngIf]="event.meta?.calendarType === CalendarEventTypeEnum.BOOKING">
            <span [ngStyle]="{ color: event.meta?.room?.colorInCalendar }"
              >[{{ event?.meta?.departmentGroup?.name }}] at
              {{ event?.meta?.room?.name }}
              {{ event.title }}: {{ event.start | amDateFormat : 'HH:mm' }} to
              {{ event.end | amDateFormat : 'HH:mm' }}
            </span>
          </ng-template>
          <ng-template [ngIf]="event.meta?.calendarType === CalendarEventTypeEnum.LEAVE">
            <span [ngStyle]="{ color: event.color?.primary }">{{ event.title }}</span>
          </ng-template>

          <ng-template [ngIf]="event.meta?.calendarType === CalendarEventTypeEnum.EVENT">
            <span [ngStyle]="{ color: event.color?.primary }" *ngIf="!event.meta?.newsFeedId">{{ event.title }}</span>
            <a
              href="#"
              [ngStyle]="{ color: event.color?.primary }"
              *ngIf="event.meta?.newsFeedId"
              [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.ANNOUNCEMENT_DETAILS), event.meta?.newsFeedId]"
              >{{ event.title }}</a
            >
          </ng-template>
        </li>
      </ul>
    </small>
  </ng-template>
</div>
