<li *ngIf="feed.allowComment">
    <div class="comment-control-container">
      <div class="comment-avatar-container" [routerLink]="[urlConstant.PROFILE_PAGE]" [queryParams]="{ employeeId :currentUser?.id }">
        <app-avatar [size]="parent ? 24 : 40" [avatarSource]="currentUser?.avatarUrl" [avatarText]="currentUser?.fullName">
        </app-avatar>
      </div>
  
      <div class="comment-editor-container">
  
        <app-feed-editor #editor [(content)]="comment.content" [editorConfig]="editorConfig" [isInline]="true"
            [ngEditorStyle]="getEditorStyle()" [disablePlaceHolder]="true" placeholder="Write your comment" [isLoading]="isLoading"
            [disablePreview]="false" [(hashtags)]="comment.hashtags" [(mentions)]="comment.mentions">
        </app-feed-editor>
        
        <div class="comment-actions">
          <button type="button" class="text-primary no-button fs16 pointer" (click)="onSubmit()" [disabled]="!comment?.content || comment?.content?.length === 0">
            <i nz-icon nzType="send" nzTheme="outline"></i>
          </button>
        </div>
        
      </div>
    </div>
  </li>
    