<modal-header [title]="headerTemplate" [activeModal]="activeModal">
  <ng-template #headerTemplate>
    <h4 class="modal-title" *ngIf="!employeeId">Add New Employee</h4>
    <h4 class="modal-title" *ngIf="employeeId">Update Employee</h4>
  </ng-template>
</modal-header>
<form *ngIf="newEmployee" class="user-info" (ngkSubmit)="onSubmit()" #frm="ngForm" [ngkForm]="true">
  <modal-body>
    <nz-collapse>
      <nz-collapse-panel nzHeader="Personal Information" nzActive="true">
        <div class="p-sm">
          <div class="form-row">
            <div class="form-group col-xl">
              <resize-image [imageUrl]="newEmployee.avatarUrl" [cropFile]="images" [cropOptions]="cropOptions" placeholder="page.edit-profile.upload-image">
              </resize-image>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-xl-3">
              <label class="required">Last Name</label>
              <input class="form-control" type="text" name="l-name" [(ngModel)]="newEmployee.lastName" placeholder="Last name" required />
            </div>
            <div class="form-group col-xl-3">
              <label class="required">First Name</label>
              <input class="form-control" type="text" name="f-name" [(ngModel)]="newEmployee.firstName" placeholder="First name" required />
            </div>
            <div class="form-group col-xl-3">
              <label class="required">Email</label>
              <input class="form-control" type="email" name="email" email [(ngModel)]="newEmployee.email" placeholder="Email" required />
            </div>
            <div class="form-group col-xl-3">
              <label class="required">Gender</label>
              <select-search name="gender" [(ngModel)]="newEmployee.gender" [data]="genders" required textField="name" valueField="name" placeholder="page.common.gender">
              </select-search>
            </div>
            <div class="form-group col-xl-3">
              <label class="required">Birthdate</label>
              <div>
                <nz-date-picker required name="b-date" (ngModelChange)="onDateOfBirthChange($event)" [ngModel]="newEmployee.dateOfBirth"></nz-date-picker>
              </div>
            </div>
            <div class="form-group col-xl-3">
              <label>Phone Number</label>
              <input class="form-control" placeholder="Phone number" type="text" name="phone-num" [(ngModel)]="newEmployee.phoneMobile" />
            </div>
            <div class="form-group col-xl-3">
              <label>Private Email</label>
              <input class="form-control" type="email" name="privateEmail" email [(ngModel)]="newEmployee.privateEmail" />
            </div>
            <div class="form-group col-xl-3">
              <label>Years of Exp before</label>
              <input class="form-control" type="number" name="numberWorkExperience" [(ngModel)]="newEmployee.numberWorkExperience" />
            </div>
            <div class="form-group col-xl-3">
              <label class="required">Position</label>
              <select-search name="position" [(ngModel)]="newEmployee.positionId" [data]="positions" required textField="name" valueField="id" placeholder="Position ...">
              </select-search>
            </div>
            <div class="form-group col-xl-3">
              <label class="required">Groups</label>
              <nz-tree-select
                #nzTreeSelect
                name="parent-id"
                [nzMaxTagCount]="3"
                [nzMultiple]="true"
                [nzDefaultExpandAll]="true"
                [nzNodes]="groups"
                nzShowSearch
                nzPlaceHolder="Please select"
                [(ngModel)]="departmentSelected"
                (ngModelChange)="deptChange()"
                required
              >
              </nz-tree-select>
            </div>
            <div class="form-group col-xl-3">
              <label>Skype ID</label>
              <input class="form-control" type="text" name="socialAccount" [(ngModel)]="newEmployee.socialAccount" />
            </div>
            <div class="form-group col-xl-3">
              <label>Microsoft Teams</label>
              <input class="form-control ms-team-account" type="text" name="msTeamsAccount" placeholder="MS Teams account" [(ngModel)]="newEmployee.msTeamsAccount" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-xl-3">
              <label>Skills</label>
              <nz-select class="form-select" name="skills" nzMode="multiple" [nzMaxMultipleCount]="4" nzPlaceHolder="Skills" [(ngModel)]="empSkillIds">
                <nz-option *ngFor="let item of skills" [nzLabel]="item.name" [nzValue]="item.id"> </nz-option>
              </nz-select>
            </div>
            <div class="form-group col-xl-3">
              <label>Hired Date</label>
              <div>
                <nz-date-picker name="joined-date" (ngModelChange)="onJoinDateChange($event)" [ngModel]="newEmployee.joinedDate"></nz-date-picker>
              </div>
            </div>
            <div class="form-group col-xl-3">
              <label
                class="mt-4"
                nz-checkbox
                (ngModelChange)="maritalStatusChange($event)"
                [(ngModel)]="newEmployee.isMaritalStatus"
                name="isMaritalStatus"
                [ngModelOptions]="{ standalone: true }"
                >Married</label
              >
              <!-- <label class="required">Marital Status</label>
              <select-search name="marrital-stt" required [(ngModel)]="newEmployee.maritalStatus" [data]="meteritalStatus" required textField="name" valueField="name"
                placeholder="Meterital status ...">
              </select-search> -->
            </div>
            <div class="form-group col-xl-12">
              <label>Address</label>
              <textarea class="form-control" name="address" [(ngModel)]="newEmployee.address"></textarea>
            </div>
            <div class="form-row full-width">
              <div class="form-group col-xl-3">
                <label class="required">Headcount Type</label><br />
                <ng-container>
                  <nz-select nzPlaceHolder="Headcount Type" [ngModelOptions]="{ standalone: true }" [(ngModel)]="newEmployee.headcountType">
                    <nz-option nzCustomContent *ngFor="let item of headcountTypes" [nzLabel]="item.name" [nzValue]="item.id">
                      <div class="text-select-level">
                        {{ item.name }}
                      </div>
                    </nz-option>
                  </nz-select>
                </ng-container>
              </div>
              <div class="form-group col-xl-3" *ngIf="newEmployee.headcountType == headcountTypeEnum.Replacement">
                <label class="required">Replacement for</label><br />
                <ng-container>
                  <nz-select nzPlaceHolder="Replacement for" [ngModelOptions]="{ standalone: true }" nzShowSearch [(ngModel)]="newEmployee.replacementEmployeeId">
                    <nz-option nzCustomContent *ngFor="let item of replacementEmplpyees" [nzLabel]="item.name" [nzValue]="item.id">
                      <div class="text-select-level">
                        <app-avatar [size]="40" [avatarSource]="item?.avatarUrl" [avatarText]="item?.fullName"> </app-avatar>
                        {{ item.name }}
                      </div>
                    </nz-option>
                  </nz-select>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </nz-collapse-panel>
      <nz-collapse-panel nzHeader="About" nzActive="true">
        <div class="p-sm">
          <div class="form-row">
            <div class="form-group col-xl-3">
              <label>Nationality</label>
              <input class="form-control" type="text" name="nationality" [(ngModel)]="newEmployee.nationlity" />
            </div>
            <div class="form-group col-xl-3">
              <label>Identify/Passport number</label>
              <input class="form-control" type="text" name="social-num" [(ngModel)]="newEmployee.passportNo" />
            </div>
            <div class="form-group col-xl-3">
              <label>Issued Date</label>
              <div>
                <nz-date-picker name="issued-date" (ngModelChange)="onIssuedDateChange($event)" [ngModel]="newEmployee.issuedDate"></nz-date-picker>
              </div>
            </div>
            <div class="form-group col-xl-3">
              <label>Issued Place</label>
              <input class="form-control" type="text" name="issued-place" />
            </div>
            <div class="form-group col-xl-3">
              <label>Social Insurance No.</label>
              <input class="form-control" type="text" name="ins-no" [(ngModel)]="newEmployee.socialSecurityNo" />
            </div>
            <!-- <div class="form-group col-xl-3">
              <label>Social Issued Date</label>
              <div>
                <nz-date-picker name="ins-issued-date" [(ngModel)]="newEmployee.socialIssueDate"></nz-date-picker>
              </div>
            </div> -->
            <div class="form-group col-xl-3">
              <label>Tax Code</label>
              <input class="form-control" type="text" name="tax-code" [(ngModel)]="newEmployee.taxNo" />
            </div>
            <div class="form-group col-xl-3">
              <label>No. of Dependents</label>
              <input class="form-control" type="number" name="no-of-dependents" [(ngModel)]="newEmployee.noOfDependents" />
            </div>
            <div class="form-group col-xl-3">
              <label>No. of Kids</label>
              <input class="form-control" type="number" name="no-of-kids" [(ngModel)]="newEmployee.noOfKids" />
            </div>
            <div class="form-group col-xl-3">
              <label>Last Working Date</label>
              <div>
                <nz-date-picker name="terminated-date" [(ngModel)]="newEmployee.terminatedDate"></nz-date-picker>
              </div>
            </div>
          </div>
        </div>
      </nz-collapse-panel>
      <nz-collapse-panel nzHeader="Account" (nzActiveChange)="activeChange($event)">
        <div class="form-row justify-content-xl-center p-sm" *ngIf="hasAccount">
          <div class="col-xl-6">
            <div class="form-group">
              <label for="email" class="sr-only" translate="page.edit-profile.fullname"></label>
              <nz-select
                class="mb-2"
                name="jobType"
                nzShowSearch
                nzAllowClear
                [nzPlaceHolder]="'group.name' | translate"
                [(ngModel)]="newEmployee.account.groupUserId"
                required
              >
                <nz-option *ngFor="let item of groupUsers" [nzLabel]="item.groupName" [nzValue]="item.id"></nz-option>
              </nz-select>
            </div>
            <div class="form-group">
              <label for="email" class="sr-only" translate="page.edit-profile.email"></label>
              <input
                type="text"
                class="form-control m-input"
                name="u-name"
                [placeholder]="'page.edit-profile.email' | translate"
                required
                [(ngModel)]="newEmployee.account.userName"
              />
            </div>
            <div class="form-group">
              <label for="password" class="sr-only" translate="page.common.password"></label>
              <ngk-password name="u-pw" placeholder="page.common.password" required [(ngModel)]="newEmployee.account.password" minlength="6" autocomplete="new-password">
              </ngk-password>
            </div>
            <div class="form-group">
              <label for="passwordConfirmation" class="sr-only" translate="page.common.password-confirm"></label>
              <ngk-password name="c-pw" placeholder="page.common.password-confirm" required validateEqual="password" [(ngModel)]="passwordConfirmation" minlength="6">
              </ngk-password>
            </div>
            <div class="form-group">
              <label nz-checkbox [ngModelOptions]="{ standalone: true }" [(ngModel)]="newEmployee.account.isActive">Active this account</label>
              <label nz-checkbox [ngModelOptions]="{ standalone: true }" [(ngModel)]="newEmployee.account.isSendMail">
                {{ 'page.common.send-mail-user-info' | translate }}
              </label>
              <a (click)="generatePw()" class="btn-normal small float-right"> <i class="fa fa-random fs11" aria-hidden="true"></i> Random Password </a>
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
  </modal-body>
  <modal-footer>
    <button type="button" class="btn btn-white" (click)="closeModal()">Cancel</button>
    <create-update-button [nzLoading]="isLoading" [isUpdate]="employeeId"></create-update-button>
  </modal-footer>
</form>
