import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'ngkDate2',
  pure: false,
})
export class NgkDatePipe2 implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(value: any, pattern = 'fullDate'): any {
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    return datePipe.transform(value, pattern);
  }
}
