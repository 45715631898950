<div class="benefit-user">
  <nz-collapse nzGhost class="benefit-panel" *ngFor="let benefit of benefits; let idx = index">
    <nz-collapse-panel [nzHeader]="benefitBonusHeaderTpl" [nzShowArrow]="false" [(nzActive)]="benefit.isPanelActive" [nzExtra]="benefitBonusExtraTpl">
      <div class="panel-content">
        <div class="decorative-line"></div>
        <ng-container *ngFor="let bonus of benefit.benefitBonus">
          <div class="benefit-result" *ngFor="let result of bonus.benefitResults">
            <div class="info">
              <div class="desc">
                <div class="name">{{result.name}}</div>
                <!-- <nz-tag class="category mr0">{{result.benefitCategory?.name}}</nz-tag> -->
              </div>
              <div class="action">
                <ng-container *hasPermission="['user', 'RegisterBenefit']">
                  <i nz-icon nzType="form" nzTheme="outline" class="claim-button text-green-success" nz-tooltip
                    nzTooltipTitle="Claim benefit" (click)="claimBenefitResult(bonus, result)" *ngIf="result.registrable">
                  </i>
                </ng-container>
                <i nz-icon nzType="eye" nzTheme="outline" class="view-button text-info" nz-tooltip
                  nzTooltipTitle="View benefit" (click)="viewBenefitResult(bonus, result)"></i>
              </div>
            </div>
            <div class="policy">
              <a *ngIf="result?.policy" href="#" (click)="openPolicyLink(result)" class="policy-link" nz-tooltip
                [nzTooltipTitle]="result.policy.title">Policy link</a>
            </div>
          </div>
        </ng-container>
      </div>
    </nz-collapse-panel>

    <ng-template #benefitBonusHeaderTpl>
      <div class="panel-header">
        <div class="bonus-title">
          <div class="bonus-color" [style.color]="benefit?.color ?? benefitConstants.DefaultColor"></div>
          <div class="bonus-title-text">{{benefit?.title}} <span  class="bonus-title-desc">{{benefit?.description}}</span></div>
        </div>
        <div class="result-count">
          {{benefit.countValue}} Benefit{{benefit.countValue > 1 ? 's' : ''}}</div>
      </div>
    </ng-template>

    <ng-template #benefitBonusExtraTpl>
      <div class="panel-header-extra">
        <div class="panel-expand-icon">
          <i nz-icon nzType="right" nzTheme="outline" class="panel-arrow"
            [ngClass]="{ 'expanded': benefit.isPanelActive }"></i>
        </div>
      </div>
    </ng-template>
  </nz-collapse>
</div>
