import { SingleChartDto, MultiChartDto, BaseChartDto } from './bases/chart.model';
import { BaseModel } from './base-model';
import { UserModel, WidgetSearchModeEnum } from '@intranet/lib/data-access';
import { PagingSortenum } from './enums/sortType.enum';
import { NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { MonthlyMissingHours } from './monthly-missing-hours.model';
import { Constants } from '../../config/constants';
import { Benefit } from './benefit.model';

export class WidgetBaseDto {
  departmentGroups: any;
  users: UserModel[];
  isDisplayGroups: boolean;
}
export class WidgetAttendanceDto extends WidgetBaseDto {
  widgetAttendanceDetails: SingleChartDto<WidgetAttendanceDetailDto>;
  numberLeaveRequest: number;
  avgCheckInTime: string;
  avgCheckOutTime: string;
  totalTimeWorking: number;
  numberIntime: number;
  numberMissingHours: number;
  numberAbsents: number;
  percentIntime: number;
  percentMissingHours: number;
  percentAbsents: number;
  dataMember: GroupUserWorkTimeDto[];
}

export class WidgetWeeklyTrackingTimeDto {
  weekOfYear: number;
  weekOfMonth: number;
  year: number;
  month: number;
  startDate: string;
  endDate: string;
  active: boolean;
  accumulatedHours: number;
  totalAllHoursWorking: number;
  totalRemainHoursWorking: number;
  totalHoursWorking: number;
  totalMissingHoursWorking: number;
  totalRemainHoursWorkingData: number;
  listDetails: WidgetTrackingTimeDetailDto[];
}

export class WidgetMonthlyTrackingTimeDto {
  month: number;
  year: number;
  active: boolean;
  startDate: string;
  endDate: string;
  totalMissingHoursWorking: number;
  totalAllHoursWorking: number;
  accumulatedHours: number;
  totalRemainHoursWorking: number;
  totalRemainHoursWorkingData: number;
  totalHoursWorking: number;
  listDetails: WidgetWeeklyTrackingTimeDto[];
}
export class WidgetTrackingTimeDto {
  listDetails: WidgetWeeklyTrackingTimeDto[];
  listDetailMonths: WidgetMonthlyTrackingTimeDto[];
  users: UserModel[];
  fullName: string;
  avatarUrl: string;
  weeks: SelectDto[];
  thisWeek: number;
  isDisplayMonth: boolean;
}

export class WidgetTrackingTimeSearchDto {
  searchMode: WidgetSearchModeEnum;
  searchMissingHours: boolean;
  month: number;
  year: number;
}

export class SelectDto {
  title: string;
  value: number;
}


export class WidgetTrackingTimeDetailDto {
  day: string;
  subTitle: string;
  title: string;
  color: string;
  startTime: string | null;
  endTime: string | null;
  startTimeAccumulated: string | null;
  endTimeAccumulated: string | null;
  totalWorkingSetting: number;
  totalHours: number;
  totalLeaveHours: number;
  totalConfirmHoursWorking: number;
  hoursGap: number;
  isLeave: boolean;
  classUnderline: string;
  classNameHoursGap: string;
  hasClaimTime: boolean;
  hasCheckInCheckOutRequest: boolean;
  checkInCheckOutStatus: number;
}
export class WidgetAttendanceHourDto {
  widgetAttendanceDetails: MultiChartDto<WidgetAttendanceDetailDto>;
  users: UserModel[];
}
export class WidgetAttendanceDetailDto {
  key: string;
  totalValue: string;
}

export class WidgetCompanyDto {
  companyMembers: SingleChartDto<DepartmentGroupChartDto>;
  departmentGroups: any;
}

export class WidgetWorkExperienceDto {
  companyExperience: MultiChartDto<DepartmentGroupChartDto>;
  departmentGroups: any;
}

export class DepartmentGroupChartDto {
  firstName: string;
  lastName: string;
  departmentName: string;
  totalWorkExperience: number;
}

export class WidgetEquipmentDto {
  totalPrice: number;
  detailDtos: WidgetEquipmentDetailDto[];
  departmentGroups: any;
}

export class WidgetEquipmentDetailDto {
  productName: string;
  supplierName: string;
  numberPRRequest: number;
  totalAmount: number;
}

export class WidgetHrInformationDto {
  numberNewEmployee: number;
  percentNewEmployee: number;
  totalEmployee: number;
  averageAgeInTheCompany: number;
  averageSeniorityInTheCompany: number;
  employeeRetention: number;
  employeeTurnover: number;
  departmentGroups: any;
}

export class WidgetEmployeeGenderDto {
  chartGenders: SingleChartDto<WidgetHrInformationDto>;
  departmentGroups: any;
}

export class WidgetLeaveDto extends WidgetBaseDto {
  avatarUrl: string;
  fullName: string;
  totalDay: number;
  usedDay: number;
  remainingDay: number;
  numberMember: number;
  usedDayPercent: number;
  departmentGroups: any;
  unpaidDay: number;
  legalEntitledDay: number;
  prevYearTranferDay: number;
  tranferDay: number;
  sponsorDay: number;
  sellDay: number;
  sponsorDayUsed: number;
  unpaidDaySocialInsurance: number;
  sickLeave: number;
  sickLeaveUsed: number;
}

export class WidgetSkillTeamDto {
  totalCount: number;
  teamSkills: SkillTeamChartDto[];
  departmentGroups: any;
  chartData: BaseChartDto;
  allMembers: MemberDto[];
}

export class SkillTeamChartDto {
  skillId: string;
  skillName: string;
  count: number;
  total: number;
  percent: number;
  color: string;
  name: string;
  value: number;
  members: MemberDto[];
}

export class WidgetTodoDto {
  detailDtos: WidgetTodoDetailDto[];
  departmentGroups: any;
}
export class WidgetTodoDetailDto {
  title: string;
  count: number;
  statusText: string;
  description: string;
  entityType: string;
  entityId: string;
  imageUrl: string;
  color: string;
  status: number;
  link: string;
  createdDate: Date | null;
}

export class WidgetSearchDto {
  isSearchUser: boolean | null;
  fromDate: string | null;
  toDate: string | null;
  fromMonth: number | null;
  toMonth: number | null;
  isDisplayDataManage: boolean;
  departmentIds: string[];
  userIds: string[];
  employeeId: string;
  thisWeek?: number;
  skillId?: string;
  isMissingHours = false;
  noLoading = false;
  sortField: string;
  sortFieldType: PagingSortenum;
  benefitIds: string[];
  pageSize: number;
  pageNumber: number;
  clientMode = false;
  searchMode: WidgetSearchModeEnum = Constants.WidgetSearchModeDefault;
}

export class Widget extends BaseModel {
  key: string;
  title: string;
  content: string;
  imageUrl: string;
  minWidth: number;
  minHeight: number;
  maxWidth: number;
  maxHeight: number;
  isResize: boolean;
  isUser: boolean;
  isManage: boolean;
  type: number;
}



export class WidgetAssetDto extends WidgetBaseDto {
  details: WidgetAssetDetailDto[];
}

export class WidgetAssetDetailDto {
  userId: string;
  employeeId: string | null;
  fullName: string;
  qrCode: string;
  productName: string;
  categoryName: string;
  quantity: number;
  createdDate: string;
}

export class WidgetTodayLeaveDto extends WidgetBaseDto {
  details: WidgetTodayLeaveDetailDto[];
  leaveRequestApprove: any;
}

export class WidgetTodayLeaveDetailDto {
  leaveRequestId: string;
  userId: string;
  employeeId: string | null;
  fullName: string;
  avatarUrl: string;
  fromDate: string;
  toDate: string;
  totalDate: number;
  totalTime: number;
  departmentsJson?: string;
  departments?: CommonDepartmentDto[];
  positionName?: string;
}
export class MemberDto {
  employeeId: string | null;
  userId: string | null;
  fullName: string;
  avatarUrl: string;
  positionName: string;
  departmentsJson: string;
  departments: CommonDepartmentDto[];
  totalWorkExperience: number;
  totalSeniority: number;
  skills?: string[];
  meta?: any;
  additionalData?: any;
  joinedDate?: string;
  seniority: string;
  totalYearsOfExperience: number;
}

export class MemberLeaveDto {
  employeeId: string | null;
  userId: string | null;
  fullName: string;
  avatarUrl: string;
  positionName: string;
  departmentsJson: string;
  usedDay: number;
  remainingDay: number;
  totalDay: number;
  tranferDay: number;
  sellDay: number;
  prevYearTranferDay: number;
  departments: CommonDepartmentDto[];
  totalWorkExperience: number;
  totalSeniority: number;
  unpaidDay: number;
  legalEntitledDay: number;
  sponsorDay: number;
  sponsorDayUsed: number;
  unpaidDaySocialInsurance: number;
}

export class WidgetNewcomerDetailDto {
  name: string;
  avatarUrl?: string;
  departments?: string[];
  skills?: string[];
  employeeId?: string;
  positionName?: string;
}

export class WidgetNewcomerDto {
  details: WidgetNewcomerDetailDto[];
}
export class CommonDepartmentDto {
  id: string;
  name: string;
}
export class GroupUserWorkTimeDto {
  userId: string;
  employeeId: string;
  fullName: string;
  avatarUrl: string;
  positionName: string;
  departmentsJson: string;
  departments: CommonDepartmentDto[];
  totalHoursWorkingSetting: number;
  totalRealHoursWorking: number;
  totalHoursWorking: number;
  totalConfirmHoursWorking: number;
  totalLeaveHours: number;
  totalMissingHoursYearly: number;
  hoursGap: number;
  classNameHoursGap: string;
  year: number;
  missingHoursMonthlyData: MonthlyMissingHours[];
}
export interface TableColumnItem {
  name: string;
  class: string;
  width: string | null;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null;
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn | null;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}


export class WidgetReviewGoalDto {
  formMembers: WidgetReviewGoalFormMemberDto[];
}

export class WidgetReviewGoalFormMemberDto {
  id: string;
  formMonth: number;
  formYear: number;
  formTitle: string;
  sections: WidgetReviewGoalSectionDto[];
}

export class WidgetReviewGoalSectionDto {
  id: string;
  reviewPerformanceSectionByMemberId: string;
  progressPercent: number;
  title: string;
  isAllowUpdate: boolean;
  columnNames: string[];
  rows: WidgetReviewGoalRowDto[];
}

export class WidgetReviewGoalRowDto {
  id: string;
  progressPercent: number;
  columns: WidgetReviewGoalColumnDto[];
}

export class WidgetReviewGoalColumnDto {
  id: string;
  columnName: string;
  values: WidgetReviewGoalValueDto[];
}

export class WidgetReviewGoalValueDto {
  id: string;
  reviewPerformanceColumnId: string;
  reviewPerformanceFormMemberId: string;
  value: string;
}
