import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortcodeToHtml',
})
export class ShortcodeToHtmlPipe implements PipeTransform {
  transform(input: any, args?: any): any {
    if (!input) {
      return input;
    }

    input = this.transformVideo(input);
    input = this.transformWebsite(input);
    input = this.transformHashtag(input);
    input = this.transformMention(input);
    input = this.transformEmoji(input);

    return input;
  }

  transformHashtag(input) {
    const regex = /h\[id=\'([A-Za-z0-9]+)\'\]/im;

    const match = input.match(regex);
    if (!match) {
      return input;
    }

    let result;
    do {
      result = regex.exec(input);
      if (result) {
        const id = result[1];
        const html = `<a class="hashtag" type="hashtag">${id}</span>`;
        // replace string matched
        input = input.replace(result[0], html);
      }
    } while (result);

    return input;
  }

  transformMention(input) {
    return input;
  }

  transformEmoji(input) {
    const regex = /e\[id=\'([A-Za-z0-9]+)\'\]/im;
    let result;

    const match = input.match(regex);
    if (!match) {
      return input;
    }
    do {
      result = regex.exec(input);
      if (result) {
        const id = result[1];
        const html = `<span class="emoji emoji-${id}" type="emoji">${this.toEmoji(id)}</span>`;
        // replace string matched
        input = input.replace(result[0], html);
      }
    } while (result);

    return input;
  }

  toEmoji(code) {
    return String.fromCodePoint(code);
  }

  transformWebsite(input) {
    const regex = /website\[url=\'(.*)\'\s+title=\'(.*)\'\s+short-desc=\'(.*)\'\s+placeholder=\'(.*)\'\]/im;
    let result;

    const match = input.match(regex);
    if (!match) {
      return input;
    }

    do {
      result = regex.exec(input);
      if (result) {
        const url = match[1];
        const title = match[2];
        const shortDescription = match[3] ? match[3] : '';
        const placeholder = match[4];

        const imagePlaceholder = placeholder ? `<img class="preview-img" src="${placeholder}"/>` : '';
        const shortDescriptionPlaceholder = shortDescription ? `<p class="preview-short-description">${shortDescription}</p>` : '';

        const html = `<a href="${url}" class="preview-website"><div class="preview-container"><h3 class="preview-title">${title}</h3>${shortDescriptionPlaceholder}${imagePlaceholder}</div></a>`;
        input = input.replace(match[0], html);
      }
    } while (result);

    return input;
  }

  transformVideo(input) {
    const regex = /video\[id=\'([A-Z a-z 0-9 \: \/ \. \# \? \=]+)\'\s+type=\'([A-Z a-z 0-9 \: \/ \. \# \?]+)\'\]/im;
    const match = input.match(regex);
    let result;
    if (!match) {
      return input;
    }

    do {
      result = regex.exec(input);

      if (result) {
        const id = match[1];

        const html = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
        input = input.replace(match[0], html);
      }
    } while (result);

    return input;
  }
}
