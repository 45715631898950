<div *ngIf="authToken?.hasEmployee" class="rt-panel-item">
  <div class="row align-items-center title-panel">
    <div class="col-8">
      <p>Work time today</p>
    </div>
    <div class="col-4 text-right">
    </div>
  </div>
  <div class="rt-panel-item-content">
    <form [formGroup]="formGroup" (ngSubmit)="updateWorkTime()">
      <div class="form-row">
        <div class="form-group col-6">
          <label>Start Time</label>
          <nz-time-picker [nzClearText]="false" name="startTime" formControlName="startTime" placeholder="Start Time" nzFormat="HH:mm"></nz-time-picker>
        </div>
        <div class="form-group col-6">
          <label>End Time</label>
          <nz-time-picker [nzClearText]="false" name="endTime" formControlName="endTime" placeholder="End Time" nzFormat="HH:mm"></nz-time-picker>
        </div>
      </div>
      <div class="form-row">
        <button type="submit" class="btn btn-primary" nz-button>Update</button>
      </div>
    </form>
  </div>
</div>
