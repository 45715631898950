import { Injectable } from '@angular/core';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { HttpClientService } from '@intranet/index';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectItemStateService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/projectitemstate';
  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getStateList(projectId: string) {
    const url = this.hostUrl + '/get_state_list?projectId=' + projectId;
    return this.httpClient.getSync(url);
  }

  updateStateOrder(data?: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    this.updateUrl('update_order', data, null, successCallback, failedCallback, completeCallback);
  }
}
