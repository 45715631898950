<div class="calendar-header-container">
  <div class="actions">
    <button
      nz-button
      class="calendar-button btn-today"
      mwlCalendarToday
      [(viewDate)]="viewDate"
      (viewDateChange)="viewDateChange.emit(viewDate)"
      (click)="onClickToday()"
    >
      Today
    </button>

    <div class="date-action">
      <div
        class="previous-button"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="viewDateChange.emit($event)"
        (click)="switchTimeline()"
      >
        <i class="fas fa-chevron-left"></i>
      </div>
      <div>
        <span class="date-text">{{ viewDate | date : 'EEEE, MMMM dd ,yyyy' }}</span>
      </div>
      <div
        class="next-button"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="viewDateChange.emit($event)"
        (click)="switchTimeline()"
      >
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>
  </div>

  <div class="filters">
    <ng-container *ngIf="dataHeader.rooms && showFilter">
      <div class="room-filter">
        <button
          type="button"
          class="calendar-button"
          nz-button
          nz-dropdown
          [nzClickHide]="false"
          [nzDropdownMenu]="menuRoom"
          [nzOverlayClassName]="'user-custom-dropdown custom-scrollbar'"
          (nzVisibleChange)="nzVisibleChangeCalendar($event)"
        >
          <img class="filter-img" src="assets/images/icons/room.svg" type="local" />
          <span class="fs-13 m-l-xs">
            Room(s)
            <i nz-icon nzType="down"></i>
          </span>
          <span *ngIf="selectedRooms?.length" class="total-filter">{{ selectedRooms.length }}</span>
        </button>
      </div>

      <div class="group-filter">
        <button
          type="button"
          class="calendar-button"
          nz-button
          nz-dropdown
          [nzClickHide]="false"
          [nzDropdownMenu]="menuDepartment"
          (nzVisibleChange)="nzVisibleChangeGroup($event)"
        >
          <img class="filter-img" src="assets/images/icons/department.svg" type="local" />
          <span class="fs-13 m-l-xs">
            Group
            <i nz-icon nzType="down"></i>
          </span>
          <span *ngIf="selectedDepartments?.length" class="total-filter">{{ selectedDepartments.length }}</span>
        </button>
      </div>
    </ng-container>

    <div class="calendar-type">
      <button class="calendar-button" [nzDropdownMenu]="menuCalendarType" nz-button nz-dropdown [nzPlacement]="'bottomRight'">
        <ng-container *ngFor="let filter of durationFilters">
          <span *ngIf="view == filter.value">{{ filter.label }}</span>
        </ng-container>
        <i nz-icon nzType="down"></i>
      </button>
    </div>
  </div>
</div>

<nz-dropdown-menu #menuRoom="nzDropdownMenu" class="scroll-dropdown">
  <ul nz-menu>
    <ng-container *ngFor="let room of dataHeader.rooms">
      <li nz-menu-item (click)="trackSelectFilter('room')">
        <label nz-checkbox [name]="room.id" [(ngModel)]="room.isChecked" (ngModelChange)="changeRoom()">
          <span [style.color]="room.colorInCalendar">
            {{ room.name }}
          </span>
        </label>
      </li>
    </ng-container>
  </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #menuDepartment="nzDropdownMenu" class="scroll-dropdown">
  <ul nz-menu>
    <ng-container *ngFor="let department of dataHeader.departments">
      <li nz-menu-item (click)="trackSelectFilter('department')">
        <label nz-checkbox [name]="department.id" [(ngModel)]="department.isChecked" (ngModelChange)="changeDepartment()">
          <span>
            {{ department.name }}
          </span>
        </label>
      </li>
    </ng-container>
  </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #menuCalendarType="nzDropdownMenu" class="scroll-dropdown">
  <ul nz-menu class="menu-view-list">
    <li nz-menu-item class="view-item" *ngFor="let filter of durationFilters" (click)="changeCalendarType(filter.value)">
      {{ filter.label }}
    </li>
  </ul>
</nz-dropdown-menu>
