<modal-header [title]="headerTemplate" [activeModal]="activeModal">
  <ng-template #headerTemplate>
    <h4 class="modal-title" *ngIf="!projectItemId">Add New {{ ProjectItemTypeEnum[projectItemType] }}</h4>
    <h4 class="modal-title" *ngIf="projectItemId">Update {{ ProjectItemTypeEnum[projectItemType] }}</h4>
  </ng-template>
</modal-header>
<form *ngIf="projectItemDto" class="project-item-info" (ngkSubmit)="onSubmit()" #frm="ngForm" [ngkForm]="true">
  <modal-body>
    <nz-page-header class="page-item-heading border-left-item-type-{{ projectItemDto.projectItemType }}">
      <nz-page-header-title>
        <div>
          <i class="color-item-type-{{ projectItemDto.projectItemType }}" *ngIf="projectItemDto.projectItemType == 2" nz-icon [nzType]="'project'"></i>
          <i class="color-item-type-{{ projectItemDto.projectItemType }}" *ngIf="projectItemDto.projectItemType == 3" nz-icon [nzType]="'bars'"></i>
          <i class="color-item-type-{{ projectItemDto.projectItemType }}" *ngIf="projectItemDto.projectItemType == 4" nz-icon [nzType]="'bug'"></i>
          
          <span class="ml10">
            <ng-container *ngIf="!projectItemId">New {{ ProjectItemTypeEnum[projectItemType] }}</ng-container>
            <ng-container *ngIf="projectItemId">{{ projectItemDto.projectItemCode }}</ng-container>
          </span>
        </div>
      </nz-page-header-title>
      <nz-page-header-extra>
        <button *ngIf="!isEditing" class="btn btn-primary mr5 fs14" (click)="isEditing = true"><i nz-icon nzType="edit" nzTheme="outline"></i> Edit</button>
        <create-update-button *ngIf="isEditing" [nzLoading]="isLoading" [isUpdate]="projectItemId"></create-update-button>
        <button *ngIf="projectItemId" nz-popconfirm nzPopconfirmTitle="Are you sure delete this item?" nzPopconfirmPlacement="top" (nzOnConfirm)="deleteProjectItem()"
          class="btn btn-danger text-danger mr5 fs14">
          <i nz-icon nzType="delete" theme="outline"></i> Delete
        </button>
        <button type="button" *ngIf="projectItemId" class="btn btn-default text-info mr5 fs14" (click)="refreshProjectData()">
          <i class="fa fa-refresh pointer"></i> Refresh
        </button>
      </nz-page-header-extra>
      <nz-page-header-content>
        <div class="row">
          <div class="col-9">
            <input nz-input placeholder="Enter Title" name="projectItem-title" [(ngModel)]="projectItemDto.title" nzSize="large" [disabled]="!isEditing" required />
          </div>
          <div class="col-3">
            <nz-select class="form-select" name="projectmember" nzPlaceHolder="Unassigned" [(ngModel)]="projectItemDto.projectMemberId" nzSize="large"
              [disabled]="!isEditing">
              <nz-option *ngFor="let member of projectMemberList" [nzLabel]="member.fullName" [nzValue]="member.projectMemberId"> </nz-option>
            </nz-select>
          </div>
        </div>
      </nz-page-header-content>
    </nz-page-header>
    <div class="form-row mt10 pl25 pr25 item-sub-detail">
      <div class="form-group col-md-4 col-12 row">
        <label for="item-state" class="col-md-4 col-2 col-form-label">State</label>
        <div class="col-md-8 col-10">
          <nz-select class="form-select" name="item-state" [(ngModel)]="projectItemDto.stateId" nzSize="large" [disabled]="!isEditing">
            <nz-option *ngFor="let state of projectItemStateList" [nzLabel]="state.name" [nzValue]="state.itemStateId"> </nz-option>
          </nz-select>
        </div>
      </div>
      <div class="form-group col-md-4 col-12 row">
        <label for="item-iteration" class="col-md-4 col-2 col-form-label">Iteration</label>
        <div class="col-md-8 col-10">
          <nz-select class="form-select" name="item-iteration" [(ngModel)]="projectItemDto.projectIterationId" nzSize="large" [disabled]="!isEditing">
            <nz-option *ngFor="let iteration of projectIterationList" [nzLabel]="iteration.text" [nzValue]="iteration.value"> </nz-option>
          </nz-select>
        </div>
      </div>
      <div class="form-group col-md-4 col-12 row">
        <label for="item-priority" class="col-md-4 col-2 col-form-label">Priority</label>
        <div class="col-md-8 col-10">
          <nz-select class="form-select" name="item-priority" [(ngModel)]="projectItemDto.priority" nzSize="large" [disabled]="!isEditing">
            <nz-option *ngFor="let priority of priorityItemList" [nzLabel]="ProjectItemPriorityEnum[priority]" [nzValue]="priority"> </nz-option>
          </nz-select>
        </div>
      </div>
    </div>
    <nz-tabset>
      <nz-tab nzTitle="Details">
        <div class="row">
          <div class="col-12 col-md-8">
            <div class="detail-heading">Description</div>
            <div class="detail-content">
              <div *ngIf="isEditing">
                <div class="form-row">
                  <div class="form-group col-12">
                    <editor [init]="editorConfig" name="item-description" [(ngModel)]="projectItemDto.description"></editor>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!isEditing">
                <div *ngIf="projectItemDto.description; else emptyDescription" [innerHTML]="projectItemDto.description"></div>
                <ng-template #emptyDescription>
                  <nz-empty nzNotFoundContent="Click edit to add description"></nz-empty>
                </ng-template>
              </ng-container>
            </div>
            <ng-container *ngIf="projectItemId">
              <div class="detail-heading">Discussion</div>
              <div class="detail-content">
                <div class="row gutters mt10 mb10 mln">
                  <button type="button" class="text-primary no-button fs16 pointer" (click)="openCommentModal()">
                    <i nz-icon nzType="plus" theme="outline"></i>&nbsp;&nbsp;Add Discussion
                  </button>
                </div>
                <ng-container *ngIf="discussionItemList && discussionItemList.length; else emptyDiscussionList">
                  <div class="discussion-list">
                    <div class="discussion-item" *ngFor="let discussion of discussionItemList">
                      <div class="row col-12 discussion-item-by">
                        <span class="discussion-user">{{ discussion.createdBy }}</span>
                        <span class="discussion-date">commented on {{ discussion.createdOn }}</span>
                      </div>
                      <div class="row col-12 discussion-item-content" [innerHTML]="discussion.description"></div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #emptyDiscussionList>
                  <nz-empty nzNotFoundContent="No Discussion"></nz-empty>
                </ng-template>
              </div>
            </ng-container>
          </div>
          <div class="col-12 col-md-4">
            <div class="detail-heading">Effort (Hours)</div>
            <div class="detail-content">
              <div class="form-row">
                <div class="form-group col-12">
                  <label>Original Estimate</label>
                  <input class="form-control" type="number" name="project-estimateHour" [disabled]="!isEditing" [(ngModel)]="projectItemDto.estimatedHours" />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <label>Remaining</label>
                  <input class="form-control" type="number" name="project-remainingHour" [disabled]="!isEditing" [(ngModel)]="projectItemDto.remainingHours" />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <label>Completed</label>
                  <input class="form-control" type="number" name="project-totalHour" [disabled]="!isEditing" [(ngModel)]="projectItemDto.totalHours" />
                </div>
              </div>
            </div>
            <div class="detail-heading">Related Work</div>
            <div class="detail-content">
              <div class="form-row">
                <div class="form-group col-12">
                  <label>Parent</label>
                  <nz-select class="form-select" name="item-parent" [(ngModel)]="projectItemDto.parentProjectItemId" nzSize="large" [disabled]="!isEditing">
                    <nz-option *ngFor="let pItem of projectItemList" [nzLabel]="pItem.text" [nzValue]="pItem.value"> </nz-option>
                  </nz-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-tab>
      <nz-tab nzTitle="History" [nzDisabled]="!projectItemId">
        <div class="detail-heading">State Graph</div>
        <div class="detail-content detail-content-stateGraph">
          <ng-container *ngIf="historyStateList && historyStateList.length; else emptyHistoryState">
            <nz-steps [nzCurrent]="historyStateList.length" nzProgressDot>
              <nz-step *ngFor="let hState of historyStateList" [nzTitle]="hState.stateLabel" [nzSubtitle]="hState.createdBy"
                [nzDescription]="hState.createdDate | date: 'dd/MM/yyyy'"></nz-step>
            </nz-steps>
          </ng-container>
          <ng-template #emptyHistoryState>
            <nz-empty nzNotFoundContent="No State History"></nz-empty>
          </ng-template>
        </div>
        <div class="detail-heading">History</div>
        <div class="detail-content">
          <ng-container *ngIf="historyChangeList && historyChangeList.length; else emptyHistoryChange">
            <div class="row gutters">
              <div class="col-6">
                <div class="row col-12 pointer history-change-list" *ngFor="let hChange of historyChangeList" (click)="onSelectedChangeItem(hChange.historyLogId)">
                  <div class="history-change-user">
                    <span>{{ hChange.createdBy }}</span>
                    <span>&nbsp;({{ HistoryLogActionEnum[hChange.historyAction] }})</span>
                  </div>
                  <div class="history-change-date">
                    <span>{{ hChange.createdDate | date: 'dd/MM/yyyy hh:mm' }}</span>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <ng-container
                  *ngIf="selectedChangeHistory && selectedChangeHistory.historyDetails && selectedChangeHistory.historyDetails.length; else emptySelectedChangeItem">
                  <div class="row col-12 history-detail-container" *ngFor="let pHDetail of selectedChangeHistory.historyDetails">
                    <span class="col-4 history-detail-fieldname">{{ pHDetail.fieldDescription }}</span>
                    <ng-container *ngIf="pHDetail.fieldDescription == 'Description'">
                      <div class="col-8 history-detail-fieldData-description" [innerHTML]="pHDetail.fieldDataDisplay"></div>
                    </ng-container>
                    <span class="col-8 history-detail-fieldData" *ngIf="pHDetail.fieldDescription != 'Description'">{{ pHDetail.fieldDataDisplay }}</span>
                  </div>
                </ng-container>
                <ng-template #emptySelectedChangeItem>
                  <nz-empty *ngIf="!selectedChangeHistory" nzNotFoundContent="Select history to view detail"></nz-empty>
                  <div class="history-detail-empty-data"
                    *ngIf="selectedChangeHistory && !(selectedChangeHistory.historyDetails && selectedChangeHistory.historyDetails.length)">
                    <span>
                    {{ selectedChangeHistory.createdBy }} {{ HistoryLogActionEnum[selectedChangeHistory.historyAction] }} on
                    {{ selectedChangeHistory.createdDate | date: 'dd/MM/yyyy hh:mm' }}
                    </span>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-container>
          <ng-template #emptyHistoryChange>
            <nz-empty nzNotFoundContent="No Change History"></nz-empty>
          </ng-template>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Relation" [nzDisabled]="!projectItemId">
        <div class="detail-heading">Links</div>
        <div class="detail-content">
          <div class="row gutters mb10 mln">
            <button type="button" class="text-primary no-button fs16 pointer" (click)="showCreateRelationModal()">
              <i nz-icon nzType="plus" theme="outline"></i>&nbsp;&nbsp;Add Link
            </button>
          </div>
          <ng-container *ngIf="projectRelationList && projectRelationList.length; else emptyRelationList">
            <nz-collapse>
              <nz-collapse-panel *ngFor="let map of mapOfProjectRelation | keyvalue" [nzHeader]="ProjectItemRelationEnum[map.key] + ' (' + map.value.length + ')'"
                nzActive="true">
                <div class="row gutters related-item-container" *ngFor="let item of map.value">
                  <div class="col-4 related-item">
                    <a (click)="openItemModal(item.relatedItemId)">
                      <i *ngIf="item.relatedItemType == 1" class="color-item-type-{{ item.relatedItemType }}" nz-icon [nzType]="'container'"></i>
                      <i *ngIf="item.relatedItemType == 2" class="color-item-type-{{ item.relatedItemType }}" nz-icon [nzType]="'project'"></i>
                      <i *ngIf="item.relatedItemType == 3" class="color-item-type-{{ item.relatedItemType }}" nz-icon [nzType]="'bars'"></i>
                      <i *ngIf="item.relatedItemType == 4" class="color-item-type-{{ item.relatedItemType }}" nz-icon [nzType]="'bug'"></i>
                      <span class="work-item-title ml5">{{ item.relatedItemTitle }}</span>
                    </a>
                  </div>
                  <div class="col-2 related-state">
                    {{ item.relatedItemStateLabel }}
                  </div>
                  <div class="col-4 related-comment">
                    {{ item.comment }}
                  </div>
                  <div class="col-2 related-action">
                    <button type="button" class="text-primary no-button fs16 pointer" (click)="showEditRelationCommentModal(item.projectItemRelationId)">
                      <i nz-icon nzType="edit" nzTheme="outline"></i>
                    </button>
                    <button type="button" *ngIf="projectItemId" nz-popconfirm nzPopconfirmTitle="Are you sure delete this relation?" nzPopconfirmPlacement="top"
                      (nzOnConfirm)="deleteProjectRelation(item.projectItemRelationId)" class="text-danger no-button fs16 pointer">
                      <i nz-icon nzType="delete" theme="outline"></i>
                    </button>
                  </div>
                </div>
              </nz-collapse-panel>
            </nz-collapse>
          </ng-container>
          <ng-template #emptyRelationList>
            <nz-empty nzNotFoundContent="No Relation Item(s)"></nz-empty>
          </ng-template>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Attachment" [nzDisabled]="!projectItemId">
        <div class="detail-heading">Attachments</div>
        <div class="detail-content">
          <nz-upload nzName="uploadFile" nzType="drag" [nzShowUploadList]="'false'" [nzCustomRequest]="handleUploadDocument">
            <p class="ant-upload-drag-icon">
              <i nz-icon nzType="inbox"></i>
            </p>
            <p class="ant-upload-text">Click or drag file to this area to upload</p>
          </nz-upload>
          <ng-container *ngIf="documentItemList && documentItemList.length; else emptyDocumentList">
            <div class="row gutters document-item-container" *ngFor="let document of documentItemList">
              <div class="col-10 document-item-name">
                <div class="document-icon">
                  <i *ngIf="document.extensionType == FileExtensionType.IMAGE" class="icon-image" nz-icon nzType="file-image" nzTheme="outline"></i>
                  <i *ngIf="document.extensionType == FileExtensionType.PDF" class="icon-pdf" nz-icon nzType="file-pdf" nzTheme="outline"></i>
                  <i *ngIf="document.extensionType == FileExtensionType.EXCEL" class="icon-excel" nz-icon nzType="file-excel" nzTheme="outline"></i>
                  <i *ngIf="document.extensionType == FileExtensionType.WORD" class="icon-word" nz-icon nzType="file-word" nzTheme="outline"></i>
                  <i *ngIf="document.extensionType == FileExtensionType.UNKNOWN" class="icon-unknown" nz-icon nzType="file-unknown" nzTheme="outline"></i>
                </div>
                <div class="document-name">
                  <span class="ml5">{{ document.documentName }}</span>
                </div>
              </div>
              <div class="col-2 document-item-action">
                <button type="button" class="text-primary no-button fs16 pointer" *ngIf="document.extensionType == FileExtensionType.IMAGE"
                  (click)="previewProjectDocument(document.itemDocumentId)">
                  <i nz-icon nzType="eye" nzTheme="outline"></i>
                </button>
                <button type="button" class="text-primary no-button fs16 pointer" (click)="downloadProjectDocument(document.itemDocumentId)">
                  <i nz-icon nzType="download" nzTheme="outline"></i>
                </button>
                <button type="button" *ngIf="projectItemId" nz-popconfirm nzPopconfirmTitle="Are you sure delete this attachment?" nzPopconfirmPlacement="top"
                  (nzOnConfirm)="deleteProjectDocument(document.itemDocumentId)" class="text-danger no-button fs16 pointer">
                  <i nz-icon nzType="delete" theme="outline"></i>
                </button>
              </div>
            </div>
          </ng-container>
          <ng-template #emptyDocumentList>
            <nz-empty nzNotFoundContent="No Attachment(s)"></nz-empty>
          </ng-template>
        </div>
      </nz-tab>
    </nz-tabset>
  </modal-body>
</form>
<nz-modal [(nzVisible)]="isShowEditRelationComment" nzTitle="Edit Comment" (nzOnCancel)="isShowEditRelationComment = false" (nzOnOk)="handleOkEditRelationComment()">
  <div class="form-row">
    <div class="form-group col-12">
      <label>Comment</label>
      <input class="form-control" type="text" name="relation-comment" [(ngModel)]="projectRelationComment" placeholder="Enter comment here" />
    </div>
  </div>
</nz-modal>
<nz-modal [(nzVisible)]="isShowCreateRelationModal" nzTitle="Add link" (nzOnCancel)="isShowCreateRelationModal = false" (nzOnOk)="handleOkCreateNewRelation()">
  <div class="form-row">
    <div class="form-group col-12">
      <label for="relation-link-type">Link type</label>
      <nz-select class="form-select" name="relation-link-type" [(ngModel)]="newProjectRelation.relationType" nzSize="large">
        <nz-option *ngFor="let relationType of relationTypeList" [nzLabel]="ProjectItemRelationEnum[relationType]" [nzValue]="relationType"> </nz-option>
      </nz-select>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-12">
      <label for="relation-item-id">Work Item</label>
      <nz-select class="form-select" name="relation-item-id" [(ngModel)]="newProjectRelation.relatedItemId" nzSize="large">
        <nz-option *ngFor="let pItem of projectItemList" [nzLabel]="pItem.text" [nzValue]="pItem.value"> </nz-option>
      </nz-select>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-12">
      <label>Comment</label>
      <input class="form-control" type="text" name="relation-comment" [(ngModel)]="newProjectRelation.comment" placeholder="Enter comment here" />
    </div>
  </div>
</nz-modal>
