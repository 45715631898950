<ng-template #indicatorTpl>
  <i nz-icon nzType="loading" class="loading-indicator"></i>
</ng-template>

<nz-spin [nzIndicator]="indicatorTpl" [nzSpinning]="loading && isClientPage">
  <div *ngIf="data" [ngClass]="{ 'custom-scrollbar': !isClientPage, 'client-scrollbar': isClientPage }">
    <div *ngFor="let item of data" class="row mt-2 pb-2 item-wrapper" [ngClass]="{ 'employee-row': isClientPage }">
      <div class="col-12 col-md-3 d-flex font-12">
        <app-avatar [size]="avatarSize" [(avatarSource)]="item.avatarUrl" [(avatarText)]="item.fullName"> </app-avatar>
        <div class="employee-information" (click)="openInformation(item)">
          <p class="font-weight-bold">{{item.fullName}}</p>
          <p class="font-weight-bold">{{item.positionName}}</p>
          <div *ngIf="!isClientPage && item.departments">
            <nz-tag *ngFor="let department of item.departments" [nzColor]="'geekblue'">
              <span *ngIf="department" [innerHTML]="department.name"></span>
            </nz-tag>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9">
        <div class="summary">
          <div class="row">
            <div class="col-6 sm-col summary-item">
              <div class="summary-item-detail" nz-popover nzType="primary" nzPopoverTrigger="click" [nzPopoverContent]="totalLeavePopoverTemplate">
                <p class="font-12">
                  Total leaves
                  <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                </p>
                <div class="amount">
                  <span>{{item.totalDay + item.prevYearTranferDay + item.sponsorDay}}</span>
                </div>
              </div>
              <ng-template #totalLeavePopoverTemplate>
                <app-leave-tooltip [data]="item" [isTotalLeave]="true"></app-leave-tooltip>
              </ng-template>
            </div>
            <div class="col-6 sm-col summary-item">
              <div class="summary-item-detail">
                <p class="font-12">Remaining</p>
                <div class="amount">
                  <span>{{item.remainingDay}}</span>
                </div>
              </div>
            </div>
            <div class="col-6 sm-col summary-item">
              <div class="summary-item-detail" nz-popover nzType="primary" nzPopoverTrigger="click" [nzPopoverContent]="userLeavePopoverTemplate">
                <p class="font-12">
                  Used
                  <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                </p>
                <div class="amount">
                  <span>{{item.usedDay + item.sellDay + item.tranferDay + item.sponsorDayUsed}}</span>
                </div>
                <ng-template #userLeavePopoverTemplate>
                  <app-leave-tooltip [data]="item"></app-leave-tooltip>
                </ng-template>
              </div>
            </div>
            <div class="col-6 sm-col summary-item">
              <div class="summary-item-detail">
                <p class="font-12">Paid leaves</p>
                <div class="amount">
                  <span>{{item.legalEntitledDay}}</span>
                </div>
              </div>
            </div>
            <div class="col-6 sm-col summary-item">
              <div class="summary-item-detail used-day-popover-template" nz-popover nzType="primary" nzPopoverTrigger="click"
                [nzPopoverContent]="unpaidDayPopoverTemplate">
                <p class="font-12">
                  Unpaid leaves
                  <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                </p>
                <div class="amount">
                  <span>{{item.unpaidDay + item.unpaidDaySocialInsurance}}</span>
                </div>
              </div>
              <ng-template #unpaidDayPopoverTemplate>
                <app-leave-tooltip [isUpaidLeave]="true" [data]="item"></app-leave-tooltip>
              </ng-template>
            </div>
            <div class="col-6 sm-col summary-item only-phone">
              <div class="summary-item-detail">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nz-empty *ngIf="data && data.length == 0"></nz-empty>
  </div>
</nz-spin>
