<div class="text-center rt-info-account">
  <div class="profile-pic">
    <app-avatar [spanStyle]="{position: 'absolute',top: '35%',right: '25%'}" [sizePercent]="100" [avatarSource]="data?.imageUrl" [avatarText]="data?.name"> </app-avatar>
  </div>
  <div class="info">
    <p>{{data?.name}}</p>
    <div class="info-group d-flex flex-row justify-content-center align-items-center">
      <p><img src="assets/images/front/shortcut/private.svg" ><span>Private group</span></p>
      <p>{{data?.users.length}} members</p>
      <p>Group since {{data?.createdDate | date: 'MMM dd, yyyy'}}</p>
    </div>
    <p></p>
  </div>
</div>
