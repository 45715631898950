<modal-header [title]="headerTemplate" [activeModal]="activeModal">
  <ng-template #headerTemplate>
    <h4 class="modal-title">[Confirm] Leave transfer {{displayInfo.year}}</h4>
  </ng-template>
</modal-header>
<modal-body>
  <div class="summary-info mb-2">
    <div class="summary" *ngIf="userAnnualLeave">
      <div class="row">
        <div class="col summary-item">
          <div class="summary-item-detail" nz-popover nzType="primary" nzPopoverTrigger="click" [nzPopoverContent]="totalLeavePopoverTemplate">
            <p>
              Total annual leaves
              <i nz-icon nzType="info-circle" nzTheme="outline"></i>
            </p>
            <div class="amount">
              <div class="icon leave"><img src="assets//images/front/shortcut/leave.svg" /></div>
                <span>{{userAnnualLeave.totalDay + userAnnualLeave.prevYearTranferDay + userAnnualLeave.sponsorDay}}</span>
              </div>
            </div>
            <ng-template #totalLeavePopoverTemplate>
              <app-leave-tooltip [data]="userAnnualLeave" [isTotalLeave]="true"></app-leave-tooltip>
            </ng-template>
          </div>

          <div class="col summary-item">
            <div class="summary-item-detail">
              <p>Remaining annual leaves</p>
              <div class="amount">
                <div class="icon remaining"><img src="assets//images/front/shortcut/remaining.svg" /></div>
                  <span [ngClass]="{ 'text-danger': remainingDays < 0 }">{{remainingDays}}</span>
                </div>
              </div>
            </div>

            <div class="col summary-item">
              <div class="summary-item-detail">
                <p>Total missing hours</p>
                <div class="amount">
                  <div class="icon used"><img src="assets//images/front/shortcut/used.svg" /></div>
                    <span>{{totalMissingHoursInDayUnit}} day{{totalMissingHoursInDayUnit > 1 ? 's' : ''}}</span>
                  </div>
                </div>
              </div>

              <div class="col summary-item">
                <div class="summary-item-detail">
                  <p>Total annual leaves {{displayInfo.year + 1}}</p>
                  <div class="amount">
                    <div class="icon sponsor"><img src="assets//images/front/shortcut/sponsor.svg" /></div>
                      <span [ngClass]="{
                        'text-danger': totalAnnualLeavesNextYear > displayInfo.maxTotalAnnualLeaves
                      }">{{totalAnnualLeavesNextYear}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form class="feed-form" [formGroup]="formGroup">
            <div *ngIf="displayInfo" class="row mb-2">
              <div class="col-6">
                <label>Your max sell days</label>
                <p class="setting-value">{{displayInfo.maxSellDays}}</p>
              </div>

              <div class="col-6">
                <label>Your max transfer days</label>
                <p class="setting-value">{{displayInfo.maxTransferDays}}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label>Sell days</label>
                <input nz-input placeholder="Number of days to sell" name="sellDays" formControlName="sellDays" autocomplete="off" />
                <div *ngIf="!isValidSellDays" class="text-danger fs13 mt-1">
                  Sell days must be equal or greater than 0 and rounded up to 0.5
                </div>
                <div *ngIf="sellDays?.value > displayInfo.maxSellDays" class="text-danger fs13 mt-1">
                  Sell days must be equal or smaller than your max sell days.
                </div>
              </div>

              <div class="col-6">
                <label>Transfer days</label>
                <input nz-input placeholder="Number of days to transfer" name="transferDays" formControlName="transferDays" autocomplete="off" />
                <div *ngIf="!isValidTransferDays" class="text-danger fs13 mt-1">
                  Transfer days must be equal or greater than 0 and rounded up to 0.5.
                </div>
                <div *ngIf="transferDays?.value > displayInfo.maxTransferDays" class="text-danger fs13 mt-1">
                  Transfer days must be equal or smaller than your max transfer days.
                </div>
                <div *ngIf="totalAnnualLeavesNextYear > displayInfo.maxTotalAnnualLeaves" class="text-danger fs13 mt-1">
                  Total annual leaves next year can't be no more than {{displayInfo.maxTotalAnnualLeaves}} days.
                </div>
              </div>
            </div>
            <div class="row mt-2 mb-2 mr-0">
              <div class="col-12 pr-0">
                <button type="submit" class="btn btn-primary float-right" nz-button nz-popconfirm [nzPopconfirmTitle]="confirmTitleTpl" (nzOnConfirm)="submit()"
                  [disabled]="!canSubmit" [ngClass]="{'btn-primary': !displayInfo.isConfirmed, 'btn-danger': displayInfo.isConfirmed }">
                  <i nz-icon nzType="check-circle" nzTheme="outline"></i> {{ displayInfo.isConfirmed ? 'Update' : 'Confirm' }}
                </button>
                <button type="button" (click)="close()" class="btn btn-secondary float-right mr10" nz-button>
                  <i nz-icon nzType="close-circle" nzTheme="outline"></i> Close
                </button>

                <ng-template #confirmTitleTpl>
                  <ng-container *ngIf="showMissingHoursConfirm">Your remaining days after transfering and selling are
                    <strong class="text-danger text-uppercase">less than</strong> missing hours amount in days. Proceed?
                  </ng-container>
                  <ng-container *ngIf="!showMissingHoursConfirm">Are you sure to confirm transfering and selling leave days?</ng-container>
                </ng-template>
              </div>
            </div>
          </form>
</modal-body>
