export enum ProjectStatusEnum {
  NEW = 0,
  APPROVAL = 5,
  COMMITED = 6,
  'New' = ProjectStatusEnum.NEW,
  'Approval' = ProjectStatusEnum.APPROVAL,
  'Commited' = ProjectStatusEnum.COMMITED,
}

export enum ProjectDocumentTypeEnum {
  COMMENT = 1,
  ATTACHMENT = 2,
  DOCUMENT = 3,
  WIKI = 4,
}

export enum ProjectIterationStateEnum {
  CURRENT = 1,
  FUTURE = 2,
  PAST = 3,
  UNKNOWN = 4,
  'Current' = ProjectIterationStateEnum.CURRENT,
  'Future' = ProjectIterationStateEnum.FUTURE,
  'Past' = ProjectIterationStateEnum.PAST,
  'Unknow' = ProjectIterationStateEnum.UNKNOWN,
}

// export enum ProjectItemStatusEnum {
//   NEW = 1,
//   ACTIVE = 2,
//   IN_PROGRESS = 3,
//   TESTING = 4,
//   BUG = 5,
//   CLOSED = 6,
//   REMOVED = 7,
//   'New' = ProjectItemStatusEnum.NEW,
//   'Active' = ProjectItemStatusEnum.ACTIVE,
//   'In Progress' = ProjectItemStatusEnum.IN_PROGRESS,
//   'Testing' = ProjectItemStatusEnum.TESTING,
//   'Bug' = ProjectItemStatusEnum.BUG,
//   'Closed' = ProjectItemStatusEnum.CLOSED,
//   'Removed' = ProjectItemStatusEnum.REMOVED,
// }

export enum ProjectItemTypeEnum {
  FEATURE = 1,
  PBI = 2,
  TASK = 3,
  BUG = 4,
  'Feature' = ProjectItemTypeEnum.FEATURE,
  'Task' = ProjectItemTypeEnum.TASK,
  'Bug' = ProjectItemTypeEnum.BUG,
}

export enum ProjectItemPriorityEnum {
  URGENT = 1,
  IMPORTANT = 2,
  MEDIUM = 3,
  LOW = 4,
  Urgent = ProjectItemPriorityEnum.URGENT,
  Important = ProjectItemPriorityEnum.IMPORTANT,
  Medium = ProjectItemPriorityEnum.MEDIUM,
  Low = ProjectItemPriorityEnum.LOW,
}

export enum ProjectMenuToolbarEnum {
  SPRINT = 1,
  WORK_ITEM = 2,
  BACKLOG = 3,
}

export enum ProjectItemRelationEnum {
  PARENT = 1,
  CHILD = 2,
  RELATED = 3,
  DUPLICATED = 4,
  REFERENCE = 5,
  AFFECT = 6,
  'Parent' = ProjectItemRelationEnum.PARENT,
  'Child' = ProjectItemRelationEnum.CHILD,
  'Related' = ProjectItemRelationEnum.RELATED,
  'Duplicated' = ProjectItemRelationEnum.DUPLICATED,
  'Reference' = ProjectItemRelationEnum.REFERENCE,
  'Affect' = ProjectItemRelationEnum.AFFECT,
}
