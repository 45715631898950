
import { AnnualLeaveRequestStatusEnum, AnnualLeaveRequestTypeEnum } from './enums/annual-leave-request.enum';
import { Department } from './department.model';

export class AnnualLeaveRequest {
  id: string;
  fullName: string;
  month: number;
  year: number;
  usedDay: number;
  unpaidDay: number;
  sponsorDayUsed: number;
  totalTime: number;
  type: AnnualLeaveRequestTypeEnum;
  status: AnnualLeaveRequestStatusEnum;
  totalCount: number;
}

export class AnnualLeaveSearchData {
  departments: Department[];
}
