import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class NewsFeedService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/news-feed';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getFeed(guidId: string, successCallback?: (resp?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = 'get-feed-by-id';
    const params = {
      noLoading: true,
      guidId,
    };

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  createPoll(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `create-poll`;

    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }

  createPost(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = ``;

    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }

  updatePost(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = ``;

    return this.updateUrl(url, data, undefined, successCallback, failedCallback, completeCallback);
  }

  getAnnoucements(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `get-announcements`;

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getTickets(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `get-tickets`;
    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getStickies(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `get-stickies`;

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getPolls(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `get-polls`;

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getFeeds(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `get-feeds`;

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  deletePost(id: string, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = ``;
    const noLoading = true;
    const params = {
      noLoading, id
    };

    return this.deleteByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  approve(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `approve`;

    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }
  // reject(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
  //   const url = `reject`;

  //   return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  // }
  createNewcomerPost(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `newcomer-post`;

    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }
}
