import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClientService } from '@intranet/lib/http/http-client';

@Injectable({
  providedIn: 'root',
})
export class ReviewFormLevelAdminService extends BaseService {
  hostUrl = '/api/review-form-level-admin';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getDropdownLevel(): Promise<any> {
    return this.httpClient.getObservable(this.hostUrl + '/get-info-level').toPromise();
  }

  updateLevel(data: any): Promise<any> {
    return this.httpClient.putObservable(this.hostUrl, data).toPromise();
  }

  updateDisplayClient(data: any): Promise<any> {
    return this.httpClient.putObservable(this.hostUrl + '/update-display-client', data).toPromise();
  }
  updateInfoProfile(data: any): Promise<any> {
    return this.httpClient.putObservable(this.hostUrl + '/update-info-profile', data).toPromise();
  }
}

