import { Component, Input, OnInit } from '@angular/core';
import { Constants } from '@intranet/index';
import {
  CDNService,
  FileExtensionType,
  InternalUploadFileDto,
  InternalUploadFileRequestModel,
  InternalUploadFileResponseModel,
} from '@intranet/lib/data-access';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { Toastr } from '@intranet/lib/settings';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';
@Component({
  selector: 'app-internal-upload-file',
  templateUrl: './internal-upload-file.component.html',
  styleUrls: ['./internal-upload-file.component.scss'],
})
export class InternalUploadFileComponent implements OnInit {
  hostFileUrl: string = AppConfig.settings.API_URL + '/cdn/file';
  @Input() model: InternalUploadFileDto;
  @Input() multiple = true;
  @Input() imageOnly;
  @Input() maxFiles? = null;
  @Input() disabled: boolean = false;
  previewVisible = false;
  previewImage = '';
  FileExtensionType = FileExtensionType;
  files: InternalUploadFileResponseModel[] = [];
  fileTypeExts = Constants.FILE_EXT;
  limitFileType: string;
  ImageFileExtensions = 'image/png,image/jpeg,image/gif,image/bmp,image.jpg';

  constructor(private cdnService: CDNService) {}

  async ngOnInit() {
    await this.getFile();
    this.limitFileType = this.imageOnly ? this.ImageFileExtensions : '';
  }

  handleUploadFiles = async (uploadFile: NzUploadFile) => {
    const uploadModel = new InternalUploadFileRequestModel();
    uploadModel.id = uuid();
    uploadModel.file = uploadFile.file;
    uploadModel.entityId = this.model.entityId;
    uploadModel.entityType = this.model.entityType;
    this.cdnService.uploadFiles(
      uploadModel,
      async resp => {
        // await this.getFile();
        if (resp.id) {
          this._uploadedFileHandler(resp.data);
        }
        return Subscription.EMPTY;
      },
      null,
      null,
    );
    return Subscription.EMPTY;
    // tslint:disable-next-line: semicolon
  };
  async downloadFile(model) {
    await this.cdnService.downloadFileEntity(model.fileId, model.fileName);
  }
  async deleteFile(id) {
    await this.cdnService.deleteFileEntity(id);
    this.getFile();
  }

  async getFile() {
    const search = {
      entityType: this.model.entityType,
      itemId: this.model.entityId,
    };
    this.files = await this.cdnService.getFileEntity(search);
  }

  click(document) {
    if (document.fileType === FileExtensionType.IMAGE) {
      this.preview(document);
    } else {
      this.downloadFile(document);
    }
  }

  preview(file: any) {
    if (file?.fileId) {
      this.previewImage = this.hostFileUrl + '/download/' + file.fileId + '?' + new Date().getTime();
      this.previewVisible = true;
    }
  }

  getImages() {
    return this.files.filter(item => item.fileType === FileExtensionType.IMAGE);
  }

  getNoneImages() {
    const files = this.files.filter(item => item.fileType !== FileExtensionType.IMAGE);
    return files;
  }

  getFileUrl(id: string) {
    if (!id) return '';

    return this.hostFileUrl + '/download/' + id;
  }

  remove(file) {
    this.files.splice(this.files.indexOf(file), 1);
  }

  private _uploadedFileHandler(fileResponse: InternalUploadFileResponseModel) {
    // TODO: reduce request get file agains
    if (!fileResponse) {
      Toastr.error('Error happened during uploading file');
      return;
    }
    if (this.multiple) {
      this.files.push(fileResponse);
    } else {
      this.files[0] = fileResponse;
    }
  }
}
