<div class="create-feed-container">
  <form (ngkSubmit)="onSubmit()" [ngkForm]="true">
    <div class="new-postbox">
      <div class="w-100">
        <ng-template *ngIf="!canBack then generalConfiguration"></ng-template>
        <ng-template *ngIf="canBack then postConfiguration"></ng-template>
      </div>
    </div>
  </form>
</div>
<ng-template #generalConfiguration>
  <div class="d-flex flex-row">
    <div class="flex-avatar">
      <app-avatar *ngIf="currentUser" [size]="50" [avatarSource]="currentUser?.avatarUrl"
        [avatarText]="currentUser?.fullName"></app-avatar>
    </div>
    <div class="flex-privacy d-flex flex-column flex-grow-1 ml-2">
      <h5>{{ currentUser?.fullName }}</h5>
      <div class="d-flex flex-row">
        <div class="form-group publicity-level">
          <select-search name="publicity-level" required [(ngModel)]="feed.publicityLevel" [data]="publicityLevels"
            required textField="name" valueField="id" placeholder="Publicity level ...">
          </select-search>
        </div>
        <div class="form-group department-groups">
          <nz-select *ngIf="isPrivateGroup()" class="form-select" name="department-groups" nzMode="multiple"
            [nzMaxMultipleCount]="4" nzPlaceHolder="Group/Department" [(ngModel)]="feed.departments">
            <nz-option *ngFor="let item of departmentGroups" [nzLabel]="item.name" [nzValue]="item.id">
            </nz-option>
          </nz-select>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <input nz-input placeholder="What's on your mind, bro?" required [(ngModel)]="feed.title" nzSize="large"
          name="title" [ngModelOptions]="{ standalone: true }" #title="ngModel" />
        <div *ngIf="(title.invalid && (title.dirty || title.touched)) || (formError && formError.title)"
          class="alert alert-danger">
          What's on your mind, bro?
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col form-group">
      <app-feed-editor #editor [(preview)]="feed.contentHtml" [(content)]="feed.description"
        [editorConfig]="editorConfig" placeholder="Describe something more" [isLoading]="isLoading"
        [(hashtags)]="feed.hashtags" [(mentions)]="feed.mentions">
      </app-feed-editor>
      <div *ngIf="formError && formError.description" class="alert alert-danger">
        Please share your thinking!
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col form-group">
      <app-internal-upload-file [model]="modelUpload"></app-internal-upload-file>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col mt-2">
      <div class="option-add">
        <button type="button" class="btn btn-primary btn-configuration w-100" (click)="showPostConfiguration()">
          <img src="assets/images/front/shortcut/setting.svg" type="local" />
          Post Configuration</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #postConfiguration>
  <div class="form-row">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.allowLike" name="allowLike" [ngModelOptions]="{ standalone: true }">Allow
        like</label>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.allowComment" name="allowComment"
        [ngModelOptions]="{ standalone: true }">Allow comment</label>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.isShow" name="isShow" [ngModelOptions]="{ standalone: true }">Visible</label>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.isSendNotification" name="isSendNotification"
        [ngModelOptions]="{ standalone: true }">Send Email Notification</label>
    </div>
  </div>

  <div class="form-row" *hasPermission="['wallnewsfeed','createannouncement']">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.isImportant" name="isImportant"
        [ngModelOptions]="{ standalone: true }">Highlight</label>
    </div>
  </div>

  <div class="form-row" *hasPermission="['wallnewsfeed','createannouncement']">
    <div class="form-group col" *ngIf="feed.isImportant">
      <label for="period">Highlight Begin Date</label>
      <nz-date-picker [ngModelOptions]="{ standalone: true }" [nzFormat]="dateFormat" (ngModelChange)="onChangeHighlightBeginDate($event)" [(ngModel)]="feed.beginHighlightDate" [nzShowToday]="true"></nz-date-picker>
    </div>

    <div class="form-group col" *ngIf="feed.isImportant">
      <label for="period">Highlight End Date</label>
      <nz-date-picker [ngModelOptions]="{ standalone: true }" [nzFormat]="dateFormat" (ngModelChange)="onChangeHighlightEndDate($event)" [(ngModel)]="feed.endHighlightDate" [nzDisabledDate]="disableEndHighlighDate"  [nzShowToday]="true"></nz-date-picker>
    </div>
  </div>
</ng-template>
