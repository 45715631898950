import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/index';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ApplicationService {
    private readonly ApplicationInformationKey = 'application-information';

    constructor(private httpClient: HttpClientService) { }

    get applicationInformationStorage(): any {
        const stringValue = localStorage.getItem(this.ApplicationInformationKey);
        if (stringValue) {
            return JSON.parse(stringValue);
        }
        return null;
    }
    set applicationInformationStorage(value: any) {
        const stringValue = JSON.stringify(value);
        localStorage.setItem(this.ApplicationInformationKey, stringValue);
    }

    async getInformation() {
        const url = ApiEndpoint.Application.GetInformation();

        return this.httpClient.getObservable(url, false)
            .pipe(
                tap(res => {
                    const currentApplicationInformationStorage = this.applicationInformationStorage;

                    if (!currentApplicationInformationStorage || res?.version !== currentApplicationInformationStorage.version) {
                        this.applicationInformationStorage = res;
                    }
                    // TODO more action when version application change on backend side
                })
            )
            .toPromise();
    }
}
