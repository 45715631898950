import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class ProductPackageService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/packages';
  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getByPackageId(packageId) {
    const url = this.hostUrl + `/${packageId}/detail`;
    return this.httpClient.getSync(url);
  }

  getItemsByPackageId(packageId) {
    const url = this.hostUrl + `/${packageId}/lines`;
    return this.httpClient.getSync(url);
  }

  createProductPackageItem(packageId, data) {
    const url = this.hostUrl + `/${packageId}/lines`;
    return this.httpClient.postSync(url, data);
  }

  updateProductPackage(packageId, dataUpdate) {
    const url = this.hostUrl + `/${packageId}`;
    return this.httpClient.putSync(url, dataUpdate);
  }

  updateProductPackageItem(packageId, lineId, dataUpdate) {
    const url = this.hostUrl + `/${packageId}/lines/${lineId}`;
    return this.httpClient.putSync(url, dataUpdate);
  }

  deleteProductPackageItem(packageId, lineId) {
    const url = this.hostUrl + `/${packageId}/lines/${lineId}`;
    return this.httpClient.deleteSync(url);
  }
}
