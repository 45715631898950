import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class ReactionService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/reactions';

  toggleComment(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `react-comment`;
    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }

  toggleFeed(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `react-feed`;
    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }

  getByFeedId(guid: string, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `get-by-feed?id=${guid}`;
    const params = {
      noLoading: true,
      url,
    };

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getByCommentId(guid: string, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `get-by-comment?id=${guid}`;
    const params = {
      noLoading: true,
      url,
    };

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }
}
