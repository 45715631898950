import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class MeetingRoomService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/meeting-room';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getListByEmployee() {
    const url = this.hostUrl + '/get-personal-dashboard';
    return this.httpClient.getSync(url);
  }
}
