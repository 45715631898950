import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseAdminComponent } from '@intranet/lib/components';
import { SurveyService, Survey, QuestionType } from '@intranet/lib/data-access';

import { Router, ActivatedRoute } from '@angular/router';
import { Toastr, UrlConstant } from '@intranet/index';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-newsfeed-answer-survey',
  templateUrl: './answer-survey.component.html',
  styleUrls: ['./answer-survey.component.scss'],
  styles: [],
})
export class SurveyAnswerComponent extends BaseAdminComponent implements OnInit {
  survey: Survey;
  activeModal: NgbActiveModal;
  enableAnswer: boolean;
  enableApprove: boolean;
  questionTypeIds = {
    single: QuestionType.SingleChoice,
    multiple: QuestionType.MultipleChoice,
    freetext: QuestionType.Freetext,
    singleWithText: QuestionType.SingleChoiceWithText,
    multipleWithText: QuestionType.MultipleChoiceWithText,
  };
  isLoading: boolean;
  surveyId: string;
  guessId: string;

  constructor(modalRef: NgbActiveModal, private surveyService: SurveyService, private router: Router,
    private route: ActivatedRoute) {
    super();
    this.activeModal = modalRef;
    this.survey = { name: '' };
    this.route.queryParams.subscribe(params => {
      this.guessId = params.hash;
    });
    this.route.params.subscribe(params => {
      this.surveyId = params.id;
      this.getDetail(this.surveyId);
    });
  }

  ngOnInit() {
    this.survey = this.survey = this.survey || { name: '', questions: [] };
  }

  getDetail(surveyId: string) {
    this.isLoading = true;
    this.surveyService.getSurvey(
      surveyId,
      res => {
        this.survey = res;
        this._checkActionPermission();
      },
      error => { },
      () => {
        this.isLoading = false;
      },
    );
  }

  onSubmit() {
    const request = {
      id: Guid.create().toString(),
      userId: this.userId || this.guessId,
      surveyId: this.survey.id,
      answers: [],
    };
    let userAnswers = [];
    this.survey.questions.forEach(question => {
      switch (question.questionTypeId) {
        case QuestionType.Freetext:
          userAnswers.push(this._initFreetextAnswer(question));
          break;
        case QuestionType.SingleChoice:
          userAnswers.push(this._initSingleChoiceAnswer(question));
          break;
        case QuestionType.MultipleChoice:
          userAnswers = userAnswers.concat(this._initMultipleChoiceAnswer(question));
          break;
        case QuestionType.SingleChoiceWithText:
          userAnswers.push(this._initSingleChoiceAnswer(question));
          userAnswers.push(this._initFreetextAnswer(question));
          break;
        case QuestionType.MultipleChoiceWithText:
          userAnswers = userAnswers.concat(this._initMultipleChoiceAnswer(question));
          userAnswers.push(this._initFreetextAnswer(question));
          break;
        default:
          break;
      }
    });
    request.answers = userAnswers;
    this.isLoading = true;
    this.surveyService.createUserSurveyAnswer(
      request,
      (res) => {
        if (res && res.guidId) {
          Toastr.success('We received your answers! Thank you for your supporting.');
          return this.router.navigate(['/news-feed']);
        } else {
          Toastr.error('You already submitted answers! Thank you for your supporting');
        }
      });
  }

  onApprove(isApproved) {
    this.survey.isApproved = isApproved;
    this.survey.rejected = !isApproved;
    this.isLoading = true;
    this.surveyService.approveSurvey(this.survey, (res) => {
      if (res) {
        Toastr.success('You approved survey sucessfully! Thank you for your supporting.');
        return this.router.navigate(['/news-feed']);
      }
    }, (error) => {
      Toastr.error(error?.error?.message);
    }, () => {
      this.isLoading = false;
    });
  }

  onCancel() {
    this.router.navigate([UrlConstant.NewsFeedUrl.ROOT]);
  }

  private _checkActionPermission() {
    this.enableAnswer = (!this.survey.rejected && this.survey.participants && this._isUserPariticipant());
    this.enableApprove = (!this.survey.rejected && !this.survey.isApproved);
  }

  private _initFreetextAnswer(question) {
    return {
      id: Guid.create().toString(),
      questionId: question.id,
      userId: this.userId,
      result: question.result,
      isFreetext: true,
    };
  }

  private _isUserPariticipant() {
    const participants = JSON.parse(this.survey.participants);
    const participant = participants.find(item => item.userId === this.userId || item.userId === this.guessId);
    return (participant !== undefined && participant !== null);
  }

  private _initSingleChoiceAnswer(question) {
    return {
      id: Guid.create().toString(),
      questionId: question.id,
      userId: this.userId,
      result: question.userChoice,
      isFreetext: false,
    };
  }

  private _initMultipleChoiceAnswer(question) {
    const userAnswers = [];
    question.options.forEach(option => {
      if (option.isSelected) {
        userAnswers.push({
          id: Guid.create().toString(),
          questionId: question.id,
          userId: this.userId,
          result: option.id,
          isFreetext: false,
        });
      }
    });
    return userAnswers;
  }
}
