import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Constants, DateHelper, Loading, Toastr } from '@intranet/index';
import {
  AnnualLeave,
  LEAVE_ACTION,
  LeaveRequest,
  LeaveRequestService,
  LeaveType,
  LeaveTypeService,
  StatusModel,
} from '@intranet/lib/data-access';
import { ApiResponse } from '@intranet/lib/data-access/models/api-response.model';
import { AnnualLeaveService } from '@intranet/lib/data-access/services/annual-leave.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as dfs from 'date-fns';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-create-leave',
  templateUrl: './create-leave.component.html',
  styles: [],
})
export class CreateLeaveComponent implements OnInit {
  dateFormat = 'dd/MM/yyyy';
  editorConfig = Constants.TINY_CONFIG;
  @ViewChild('frm', { static: false }) public formGroup: NgForm;
  userAnnualLeave: AnnualLeave;
  leaveRequest: LeaveRequest = new LeaveRequest();
  leaveTypes: LeaveType[] = [];
  dateRange: Date[];
  totalDays: number;
  totalTimes: number;
  halfs: any;
  isLoading = false;
  remainingDays: number;
  limitDays = 0;
  message: string;
  exceedLimitDay: boolean;
  data: any;
  isRequired = true;
  modal: NgbActiveModal;
  tagOptionData: any;
  constructor(
    private leaveTypeSvc: LeaveTypeService,
    private leaveRequestSvc: LeaveRequestService,
    activeModal: NgbActiveModal,
    private annualLeaveSvc: AnnualLeaveService,
  ) {
    this.modal = activeModal;
  }

  async ngOnInit() {
    await this.initData();
    this.totalTimes = this.leaveRequest.halfDay.time;
  }

  onSubmit() {
    if (!this.formGroup.valid) {
      return;
    }
    this.isLoading = true;
    this.leaveRequest.leaveType = {
      id: this.leaveRequest.leaveType.id,
      leaveRule: this.leaveRequest.leaveType.leaveRule,
      limitDay: this.leaveRequest.leaveType.limitDay,
      halfDay: this.leaveRequest.halfDay.name,
      tagOptions: this.leaveRequest.leaveType.tagOptions,
      hasSocialInsurance: this.leaveRequest.leaveType.hasSocialInsurance,
      permissionApproveId: this.leaveRequest.leaveType.permissionApproveId,
      isAllowProbationTime: this.leaveRequest.leaveType.isAllowProbationTime,
      isAllowMaternityTime: this.leaveRequest.leaveType.isAllowMaternityTime,
    };
    this.leaveRequest.totalDate = this.totalDays;
    this.leaveRequest.totalTime = this.totalTimes;
    this.leaveRequest.leaveTypeId = this.leaveRequest.leaveType.id;
    this.leaveRequest.fromDate = DateHelper.formatServerRemoveISO(this.leaveRequest.fromDate);
    this.leaveRequest.toDate = DateHelper.formatServerRemoveISO(this.leaveRequest.toDate);
    this.leaveRequestSvc.add(
      this.leaveRequest,
      () => {
        Toastr.success('Submit successfully!');
        this.modal.close(true);
      },
      (resp: ApiResponse) => {
        Toastr.error(resp.message);
      },
    );
  }

  //#region EVENT
  onChangeLeaveType() {
    if (this.leaveRequest.leaveType.limitDay) {
      this.countLimitDay();
    } else {
      this.calculateTimeAndDay(this.leaveRequest.halfDay);
    }
    if (this.leaveRequest.leaveType.leaveRule === LEAVE_ACTION.SICK_LEAVE) {
      this.leaveRequest.fromDate = new Date();
      this.leaveRequest.toDate = new Date();
      this.calculateTotalDays();
    }
    if (this.leaveRequest.leaveType.leaveRule !== LEAVE_ACTION.REDUCED_LEAVE_DATE) {
      this.isRequired = true;
    } else {
      this.isRequired = false;
    }
    this.tagOptionData = null;
    if (this.leaveRequest.leaveType.tagOptions) {
      this.tagOptionData = JSON.parse(this.leaveRequest.leaveType.tagOptions);
    }

    this.leaveRequest.reason = '';
  }
  onChangeLeaveOptions(event) {
    this.leaveRequest.reason = event;
  }
  onChangeFromDate(date) {
    this.leaveRequest.fromDate = date;
    if (!this.leaveRequest.toDate || (this.leaveRequest.toDate && this.leaveRequest.fromDate > this.leaveRequest.toDate)) {
      this.leaveRequest.toDate = date;
    }
    this.calculateTotalDays();
    this.calculateTimeAndDay(this.leaveRequest.halfDay);
  }
  onChangeToDate(date) {
    this.leaveRequest.toDate = date;
    this.calculateTotalDays();
    this.calculateTimeAndDay(this.leaveRequest.halfDay);
  }

  onChangeDateRange(result: any): void {
    this.leaveRequest.fromDate = result[0];
    this.leaveRequest.toDate = result[1];
    this.calculateTotalDays();
    this.calculateTimeAndDay(this.leaveRequest.halfDay);
  }

  onChangeHalf(half: any) {
    this.leaveRequest.halfDay = half;
    this.calculateTimeAndDay(half);
  }

  disabledDate = (current: Date): boolean => {
    if (this.leaveRequest.leaveType && this.leaveRequest.leaveType?.leaveRule === Constants.LEAVE_ACTION_IDS.None) {
      // check current date
      const result1 = differenceInCalendarDays(current, new Date());
      if (result1 < 0) {
        return true;
      }
      // Can not select days before today and today
      let limitDay = this.leaveRequest.leaveType?.limitDay ?? this.remainingDays;
      if (limitDay > 0) {
        limitDay = limitDay - 1;
      }
      const date = dfs.addDays(this.leaveRequest.fromDate, limitDay);
      const result = differenceInCalendarDays(date, current);
      return result < 0;
    } else {
      // check current date
      const result1 = differenceInCalendarDays(current, new Date());
      if (result1 < 0) {
        return true;
      } else {
        return false;
      }
    }
    // tslint:disable-next-line: semicolon
  };

  disableFromDate = (current: Date): boolean => {
    // const now = new Date();
    // if (differenceInCalendarDays(now, current) >= 0) {
    //   return true;
    // }
    if (this.leaveRequest.leaveType && this.leaveRequest.leaveType?.leaveRule === Constants.LEAVE_ACTION_IDS.Sick_Leave) {
      const now = new Date();
      const result = new Date(now);
      result.setDate(result.getDate() + this.leaveRequest.leaveType?.limitDay);
      return differenceInCalendarDays(result, current) < 0;
    }
    return false;
    // tslint:disable-next-line: semicolon
  };

  disableToDate = (current: Date): boolean => {
    const now = new Date();
    // if (differenceInCalendarDays(now, current) >= 0) {
    //   return true;
    // }

    if (this.leaveRequest.leaveType && this.leaveRequest.leaveType?.leaveRule === Constants.LEAVE_ACTION_IDS.None) {
      const limitDay = this.leaveRequest.leaveType?.limitDay ?? this.remainingDays;
      const date = dfs.addDays(this.leaveRequest.fromDate, 0);
      return differenceInCalendarDays(date, current) > 0;
    }

    return differenceInCalendarDays(this.leaveRequest.fromDate, current) > 0;
    // tslint:disable-next-line: semicolon
  };

  //#endregion

  //#region private methods
  private async initData() {
    this.leaveRequest = new LeaveRequest();
    if (this.data) {
      this.leaveRequest.fromDate = this.data.fromDate;
      this.leaveRequest.toDate = this.data.toDate;
    }

    this.dateRange = [this.leaveRequest.fromDate, this.leaveRequest.toDate];
    this.getLeaveTypeList();
    await this.getAnnualLeave();
    await this.getLeaveHaftDay();
    this.calculateTotalDays();
    this.calculateTimeAndDay(this.leaveRequest.halfDay);
  }

  private calculateTotalDays() {
    const diffTime = DateHelper.getDistanceBetweenDate(this.leaveRequest.fromDate, this.leaveRequest.toDate) + 1;
    this.totalDays = diffTime;
  }

  private calculateTimeAndDay(halfDay: StatusModel) {
    this.exceedLimitDay = false;
    if (halfDay.time === 4 && Number.isInteger(this.totalDays)) {
      this.totalDays = this.totalDays - 0.5;
      this.totalTimes = this.totalDays * 8;
    } else if (!Number.isInteger(this.totalDays) && halfDay.time === 8) {
      this.calculateTotalDays();
      this.totalTimes = this.totalDays * 8;
    } else {
      this.totalTimes = 8 * this.totalDays;
    }
    if (this.leaveRequest) {
      if (this.leaveRequest!.leaveType && this.leaveRequest.leaveType!.leaveRule === Constants.LEAVE_ACTION_IDS.Reduced_Date) {
        if (this.totalDays > this.remainingDays) {
          this.exceedLimitDay = true;
          Toastr.error(`Number of days leave greater than the remain day`);
        } else {
          this.userAnnualLeave.remainingDay = this.remainingDays - this.totalDays;
        }
      } else if (
        this.leaveRequest.leaveType &&
        this.leaveRequest.leaveType.leaveRule === Constants.LEAVE_ACTION_IDS.None &&
        this.limitDays >= 0
      ) {
        if (this.totalDays > this.limitDays) {
          Toastr.error(`For this policy you only have ${this.limitDays} days`);
          this.exceedLimitDay = true;
        }
        this.userAnnualLeave.remainingDay = this.remainingDays;
      } else {
        this.userAnnualLeave.remainingDay = this.remainingDays;
      }
    } else {
      this.userAnnualLeave.remainingDay = this.remainingDays;
    }
  }

  private getLeaveTypeList() {
    Loading.show();
    this.leaveTypeSvc.getDropdown(undefined, (resp: LeaveType[]) => {
      this.leaveTypes = resp;
      // this.leaveRequest.leaveTypeId = this.leaveTypes.find(x => x.leaveRule === LEAVE_ACTION.REDUCED_LEAVE_DATE).id; // init default value for leave request
      Loading.hide();
    });
  }

  private async getAnnualLeave() {
    const data = await this.annualLeaveSvc.getByCurrentEmp('');
    if (data) {
      this.userAnnualLeave = data as AnnualLeave;
      this.remainingDays = this.userAnnualLeave.remainingDay;
    }
  }
  private async getLeaveHaftDay() {
    const data = await this.leaveTypeSvc.getHaftLeaveDropdown().toPromise();
    if (data) {
      this.halfs = data;
      this.leaveRequest.halfDay = data[0];
    }
  }
  countLimitDay() {
    this.leaveRequestSvc.countLimit({ type: this.leaveRequest.leaveType.id }, (resp: any) => {
      // check only use one time
      if (resp > 0 && this.leaveRequest.leaveType.useForOneTime) {
        this.limitDays = 0;
      } else {
        this.limitDays = this.leaveRequest.leaveType.limitDay - resp;
      }
      this.calculateTimeAndDay(this.leaveRequest.halfDay);
    });
  }

  //#endregion private methods
}
