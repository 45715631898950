import { Component, Input, OnInit } from '@angular/core';
import { BaseAdminComponent } from '@intranet/lib/components';
import { ColumnMapping, COLUMN_TYPE, PolicyDetail, SearchPolicyDetail } from '@intranet/lib/data-access';
import { PolicyDetailService } from '@intranet/lib/data-access/services/policy-detail.service';
import { Loading, ModalOption, ModalService, ModalSize } from '@intranet/lib/settings';
import { PolicyDetailModalComponent } from '..';

@Component({
  selector: 'app-policy-detail-list',
  templateUrl: './policy-detail-list.component.html',
  styleUrls: ['./policy-detail-list.component.scss']
})
export class PolicyDetailListComponent extends BaseAdminComponent implements OnInit {

  _policyId: string;

  get policyId(): string {
    return this._policyId;
  }

  @Input() set policyId(value: string) {
    this._policyId = value;
    this.getPolicyDetailList();
  }

  keyWord = '';
  columnMapping = [
    new ColumnMapping(COLUMN_TYPE.STRING, 'title', 'Title', true, true),
    new ColumnMapping(COLUMN_TYPE.STRING, 'description', 'Description', true, true),
  ];
  modalOptionTwo: ModalOption = {
    size: ModalSize.xl,
  };
  searchData: SearchPolicyDetail = new SearchPolicyDetail();

  data: PolicyDetail[] = [];

  constructor(private policyDetailSvc: PolicyDetailService, private modalDataSvc: ModalService) {
    super();
  }

  ngOnInit() {
  }

  openNewModal() {
    const modal = this.modalDataSvc.open(PolicyDetailModalComponent, this.modalOptionTwo);
    modal.componentInstance.policyDetailId = null;
    modal.componentInstance.policyId = this._policyId;
    modal.result.then((resp: any) => {
      if (resp) {
        this.getPolicyDetailList();
      }
    });
  }

  editPolicyDetail(id) {
    const modal = this.modalDataSvc.open(PolicyDetailModalComponent, this.modalOptionTwo);
    modal.componentInstance.policyId = this._policyId;
    modal.componentInstance.policyDetailId = id;
    modal.result.then((resp: any) => {
      if (resp) {
        this.getPolicyDetailList();
      }
    });
  }

  deletePolicyDetail(id) {
    Loading.show();
    this.policyDetailSvc.deleteById(id, () => {
      this.getPolicyDetailList();
    });
  }


  private getPolicyDetailList() {
    this.isLoading = true;
    this.searchData.policyId = this._policyId;
    this.policyDetailSvc.getAll(this.searchData, (resp: PolicyDetail[]) => {
      this.data = resp;
      if (resp && resp.length) {
        this.searchData.totalItems = this.data[0].totalCount;
      } else {
        this.searchData.totalItems = 0;
      }
      this.isLoading = false;
    });
  }

}
