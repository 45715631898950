import { NewsFeed } from './news-feed.model';
import { TicketType } from './ticket-type.model';

export class Ticket extends NewsFeed {
  ticketTypeId?: string;
  ticketType?: TicketType;
  canUpdate?: boolean;
  canDelete?: boolean;
  canClose?: boolean;
}
