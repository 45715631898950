import * as _ from 'lodash';
import { Router } from '@angular/router';
import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { PubSubService } from '@intranet/lib/services';
import { NewsFeed, NewsFeedTypeEnum, WallNewsFeedService, PollQuestion, } from '@intranet/lib/data-access';
import { BaseAdminComponent } from '@intranet/lib/components';
import { DateHelper, ModalService, ModalSize } from '@intranet/index';
import { PubSubEvent, UrlConstant } from '@intranet/index';

import { CreatePostModalComponent } from '../../create-post/create-post-modal/create-post-modal.component';
import { ModalConfirmComponent } from '@intranet/lib/components';
import { NewsFeeds as NewsFeedConstants } from '@intranet/index';
import * as moment from 'moment';
import { NewsFeedPollComponent } from './news-feed-poll/news-feed-poll.component';
import { CommonHelper } from '@intranet/lib/helpers/commonHelper';

@Component({
  selector: 'app-news-feed-detail',
  templateUrl: './news-feed-detail.component.html',
  styleUrls: ['./news-feed-detail.component.scss']
})
export class NewsFeedDetailComponent extends BaseAdminComponent implements OnInit, AfterViewInit {
  @ViewChild('generalInformation') generalInformation: ElementRef;
  @ViewChild('feedDescription') feedDescription: ElementRef;

  maxQuestions = NewsFeedConstants.MAX_QUESTIONS;
  feed: NewsFeed;
  myConfig = {};
  isPollExpired = false;

  @Input() set data(value: NewsFeed) {
    this.feed = new NewsFeed();
    Object.assign(this.feed, value);
    if (this.feed && this.feed.newsFeedTypeId === NewsFeedTypeEnum.POLL && this.feed.pollQuestions) {
      if (this.feed.beginResultDate) {
        this.feed.beginResultDate = moment.utc(this.feed.beginResultDate).toDate();
      }
      if (this.feed.endResultDate) {
        this.feed.endResultDate = moment.utc(this.feed.endResultDate).toDate();
      }
      // this.feed.isExpired = this.feed.endDate && DateHelper.getDistanceWithToday(this.feed.endDate) < 0;
      const now = new Date();
      this.feed.isExpired = (this.feed.endDate && now <= this.feed.endDate)
        || (this.feed.endResultDate && now <= this.feed.endResultDate);

      this.checkIsPollExpired();

      this.loadQuestions(this.feed.pollQuestions);
    }
  }

  avatarUrl: string;
  fullName: string;
  constants = NewsFeedConstants;
  types = NewsFeedConstants.FEED_TYPES;

  totalVotes = {};
  totalUserVotes = {};
  originalUserNotes = {};

  constructor(
    private router: Router,
    private host: ElementRef,
    private newsFeedService: WallNewsFeedService,
    private modalService: ModalService,
    private pubsub: PubSubService
  ) {
    super();
  }

  ngAfterViewInit() {
    const element = this.host.nativeElement.querySelector('.rt-news-feed-post-desc');
    if (element) {
      element.classList[element.scrollHeight > element.clientHeight ? 'add' : 'remove']('truncated');
    }
  }

  ngOnInit() {
    this.avatarUrl = this.feed?.user?.avatarUrl || '';
    this.fullName = this.feed?.user?.fullName || '';

    if (this.feed && this.feed.newsFeedTypeId === NewsFeedTypeEnum.POLL) {
      this.pubsub.$sub(PubSubEvent.EVENTS.ON_UPDATE_POLL).subscribe(res => {
        const items = res.items;
        const userId = res.userId;
        const questionId = res.questionId;
        const userPollOptionId = res.userPollOptionId;
        if (this.feed && this.feed.pollQuestions) {
          const notifiedQuestion = this.feed.pollQuestions.find(q => q.id === questionId);
          const nonNotifiedQuestions = _.filter(this.feed.pollQuestions, q => q.id !== questionId);

          this.totalVotes = {};
          if (notifiedQuestion) {
            items.map(item => {
              this.totalVotes[notifiedQuestion.id] = this.totalVotes[notifiedQuestion.id] || 0;
              if (item.questionId === notifiedQuestion.id) {
                this.totalVotes[notifiedQuestion.id] += item.totalVoted;
              }
            });

            _.forEach(nonNotifiedQuestions, nonNotifiedQuestion => {
              this.totalVotes[nonNotifiedQuestion.id] = this.totalVotes[nonNotifiedQuestion.id] || 0;

              const { options } = nonNotifiedQuestion;
              const totalVoted = _.sumBy(options, 'totalChoice');

              this.totalVotes[nonNotifiedQuestion.id] = totalVoted;
            });

            if (!notifiedQuestion.isMultipleChoice && userId === this.userId) {
              notifiedQuestion.userChoice = userPollOptionId;
            }
            notifiedQuestion.options.map(option => {
              const selectedOption = items.find(r => r.userPollOptionId === option.id);

              if (option.id === userPollOptionId && userId === this.userId) {
                option.isSelected = res.isSelect;
              }
              if (!selectedOption) {
                option.totalChoice = 0;
              } else {
                option.totalChoice = selectedOption.totalVoted;
              }
            });
            this._checkAllowAddMoreOption(notifiedQuestion);
          }
        }
      });
    }
  }

  checkIsPollExpired() {
    this.isPollExpired = !this.feed.endResultDate;

    if (this.feed.endResultDate) {
      const delta = this.feed.endResultDate.valueOf() - Date.now();
      const diff = DateHelper.dateDiffFromNumber(delta, {
        disableDays: true,
        disableHours: true,
      });

      this.isPollExpired = delta < 0 || diff.minutes <= (this.feed.durationChange || 0);
    }

    return this.isPollExpired;
  }

  getPollResultText(questionId: string, optionQty: number) {
    return `${optionQty}/${this.totalVotes && this.totalVotes[questionId] ? this.totalVotes[questionId] : '-'}`;
  }

  getOptionPercent(questionId: string, optionQty: number) {
    if (!this.totalVotes || !this.totalVotes[questionId]) {
      return 0;
    }

    return (optionQty * 100) / this.totalVotes[questionId];
  }

  getImagePath(images: string[]) {
    const results = [];
    images.forEach(image => {
      results.push(this.newsFeedService.getFileUrl(image));
    });

    return results;
  }

  loadQuestions(questions: PollQuestion[]) {
    questions.forEach(question => {
      this.originalUserNotes[question.id] = question.userNote?.description;
      this.totalVotes[question.id] = 0;
      this.totalUserVotes[question.id] = 0;
      if (!question.isMultipleChoice) {
        question.userChoice = question.userChoices[0]?.userPollOptionId;
      } else {
        question.options.forEach(option => {
          option.isSelected = !!(question.userChoices || []).find(choice => choice.userPollOptionId === option.id && choice.createdBy === this.currentUser?.id);
          if (option.isSelected) {
            this.totalUserVotes[question.id] += 1;
          }
        });
        this._checkAllowAddMoreOption(question);
      }
      question.options.forEach(option => {
        this.totalVotes[question.id] += option.totalChoice;
      });
    });
  }

  onPollSelect(question: PollQuestion, userChoice: string) {
    if (this.checkIsPollExpired()) {
      const currentChoice = question.userChoice;
      question.userChoice = userChoice;

      CommonHelper.scheduleToExecute(() => {
        question.userChoice = currentChoice;
      });
      return;
    }

    question.userChoice = userChoice;

    if (question.userChoice) {
      // add choice
      this.newsFeedService.createUserPoll({
        questionId: question.id,
        userPollOptionId: question.userChoice,
        newsFeedId: this.feed.id
      });
    }
  }

  canSaveUserNote(question: PollQuestion) {
    return question && question.userNote.description && this.originalUserNotes && this.originalUserNotes[question.id] !== question.userNote.description;
  }

  onSubmitUserNote(question) {
    if (question.userNote) {
      this.originalUserNotes[question.id] = question.userNote?.description;
      this.newsFeedService.saveUserPollNote(question.userNote, () => {
        this._checkAllowAddMoreOption(question);
      });
    }
  }

  onPollMultipleChoiceSelect(option, question) {
    if (this.checkIsPollExpired()) {
      return;
    }

    option.isSelected = !option.isSelected;
    if (option.isSelected) {
      // add choice
      this.newsFeedService.createUserPoll(
        {
          questionId: option.pollQuestionId,
          userPollOptionId: option.id,
          isMultipleChoice: true,
          newsFeedId: this.feed.id
        },
        () => {
          this._checkAllowAddMoreOption(question);
        },
      );
    } else {
      // remove choice
      this.newsFeedService.removeUserPoll(
        {
          questionId: option.pollQuestionId,
          userPollOptionId: option.id,
          isMultipleChoice: true,
        },
        () => {
          this._checkAllowAddMoreOption(question);
        },
      );
    }
  }

  onShowInstanceQuestionResult(questionId: string) {
    const modal = this.modalService.open(NewsFeedPollComponent, {
      size: ModalSize.lg,
    });

    modal.componentInstance.poll = this.feed;
    modal.componentInstance.questionId = questionId;

    modal.result.then((resp: any) => { });
  }

  onShowInstanceOptionResult(questionId: string, optionId: string, question: any) {
    const modal = this.modalService.open(NewsFeedPollComponent, {
      size: ModalSize.lg,
    });

    modal.componentInstance.poll = this.feed;
    modal.componentInstance.optionId = optionId;
    modal.componentInstance.questionId = questionId;
  }

  onShowInstanceResult() {
    const modal = this.modalService.open(NewsFeedPollComponent, {
      size: ModalSize.lg,
    });
    modal.componentInstance.poll = this.feed;
  }

  private _checkAllowAddMoreOption(question) {
    const maxVote = question.maxVote;
    const userSelected = question.options.filter(item => item.isSelected).length;
    if (maxVote) {
      question.options.map(item => {
        item.disabled = userSelected >= maxVote && !item.isSelected;
      });
    }
    if (this.feed.durationChange) {
      question.isExpired = this.feed.endResultDate && DateHelper.getDistanceBetweenDate(
        this.feed.endResultDate, new Date(), 'minutes'
      ) > this.feed.durationChange;
    }
  }

  toggleViewMore(feed: NewsFeed) {
    feed.expandFeed = !feed.expandFeed;
  }

  onDeleteFeed(feed: NewsFeed) {
    const modal = this.modalService.open(ModalConfirmComponent, {
      size: ModalSize.lg,
    });
    modal.componentInstance.lbConfirm = 'Delete';
    modal.componentInstance.title = 'Delete post?';
    modal.componentInstance.description = 'Are you sure you want to delete this post?';

    modal.result.then(result => {
      if (result) {
        this.newsFeedService.deletePost(feed.id, () => { });
      }
    });
  }

  onEditFeed(feed: NewsFeed) {
    const activeModal = this.modalService.open(CreatePostModalComponent, { windowClass: 'create-post-modal', size: ModalSize.xl });
    activeModal.componentInstance.data = feed;
    activeModal.componentInstance.activeModal = activeModal;
  }

  navigateNewFeedDetailPage(newFeed: NewsFeed) {
    this.router.navigate([`/${UrlConstant.NewsFeedUrl.ROOT}/${UrlConstant.NewsFeedUrl.DETAIL}/${newFeed.id}`]);
  }
}
