import { BaseModel } from './base-model';

export class SurveyQuestion extends BaseModel {
    surveyId?: string;
    questionTypeId?: QuestionType;
    options?: SurveyQuestionOption[];
    result?: string;
    isFreetext?: boolean;
    order?: number;
    fromLibraryQuestion?: string;
    required?: boolean;
    userChoice?: string;
    visibleMove?: any;
}

export class SurveyQuestionOption extends BaseModel {
    questionId?: string;
    isSelected?: boolean;
    order?: number;
}

export class UserSurveyQuestionAnswer {
    id?: string;
    questionId?: string;
    userId?: string;
    fullname?: string;
    result?: string;
    isFreetext?: boolean;
}

export enum QuestionType {
    SingleChoice = 1,
    MultipleChoice = 2,
    Freetext = 3,
    SingleChoiceWithText = 4,
    MultipleChoiceWithText = 5,
}