import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { MenuDto, MenuOptions, MenuTypeEnum } from '@intranet/lib/data-access';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  constructor(private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const urls = event.url;
        this.paramLink = urls;
      }
    });
  }
  MenuTypeEnum = MenuTypeEnum;
  menuOptions: MenuOptions = {
    processMenuFn: SidebarComponent.initMenuMeta,
    onNavigatingFn: SidebarComponent.checkActiveMenu
  };
  badgeStyle = { 'font-size': '11px', 'box-shadow': 'none' };
  submenuBadgeStyle = { 'font-size': '11px', 'box-shadow': 'none' };
  @Input() menu: MenuDto[];
  paramLink: string;

  static initMenuMeta(menuList: MenuDto[], router: Router): MenuDto[] {
    SidebarComponent.checkActiveMenuRecursively(window.location.pathname, menuList);
    return menuList;
  }

  static checkActiveMenu(event: any, menuList: MenuDto[]): MenuDto[] {
    if (event instanceof NavigationStart) {
      const pathname = event.url.split('?')[0];

      SidebarComponent.checkActiveMenuRecursively(pathname, menuList);
    }
    return menuList;
  }

  static checkActiveMenuRecursively(url: string, menuList: MenuDto[]): boolean {
    const initAndResetMeta = (menu: MenuDto) => {
      if (menu) {
        if (!menu.meta) {
          menu.meta = {};
        }
        menu.meta.isActivated = false;
      }
    };

    // If a child return true, a whole path from that child to parent nodes will have isActivated = true.
    let hasMatchedUrl = false;
    for (const menu of menuList) {
      initAndResetMeta(menu);
      if (menu.url && menu.url === url) {
        menu.meta.isActivated = true;
        // This menu has already activated since its URL == URL in browser.
        // Calling children to activate them if they have the same URL, but we don't care about children's result.
        if (menu.children && menu.children.length > 0) {
          SidebarComponent.checkActiveMenuRecursively(url, menu.children);
        }
        hasMatchedUrl = true;
      }
      else if (!menu.url && menu.children && menu.children.length > 0) {
        // If children has matched URL with browser, the parent menu will be activated too.
        menu.meta.isActivated = SidebarComponent.checkActiveMenuRecursively(url, menu.children);
        hasMatchedUrl = hasMatchedUrl || menu.meta.isActivated;
      }
    }

    return hasMatchedUrl;
  }

  ngOnInit() {
    if (!this.paramLink) {
      this.paramLink = this.router.url;
    }
  }

  ngAfterViewInit(): void {
    $.MainApp.init();
  }
}
