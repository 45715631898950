export enum COLUMN_TYPE {
  STRING,
  ARRAY,
  NUMBER,
  DATE,
  TIME,
  DATETIME,
  IMAGE,
  ENUM,
  BOOLEAN,
  FILE,
  CUSTOM,
  SPECIAL,
  COLOR,
}

export class ColumnMapping {
  ColumnType: COLUMN_TYPE;
  ColumnName: string;
  DisplayName: string;
  AutoResize: boolean;
  Witdh: number;
  Sortable: boolean;
  IsChecked: boolean;
  Hidden: boolean;
  constructor(colType: COLUMN_TYPE, colName: string, displayName: string, autoResize: boolean = false, sortable: boolean = false, width = 200) {
    this.ColumnType = colType;
    this.ColumnName = colName;
    this.DisplayName = displayName;
    this.AutoResize = autoResize;
    this.Sortable = sortable;
    this.IsChecked = true;
    this.Witdh = width;
    this.Hidden = false;
  }
}
export class GirdColumn {
  prop: string;
  name: string;
  sortable?: boolean;
  canAutoResize?: boolean;
}
