import { BaseModel } from './base-model';

export class EquipmentCategory extends BaseModel {
  id: string;
  key: string;
  name?: string;
  title: string;
  parentId?: string;
  parentName?: string;
  parentLevel?: number;
  level?: number;
  children?: any[];
  isLeaf?: boolean;
}
