import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { SearchModel } from '../models/search.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeeTNMService {
  hostUrl = AppConfig.settings.API_URL + '/api/employee-tnm';
  constructor(private httpClient: HttpClientService) {}

  list(data: SearchModel) {
    const start = Math.round(data.startDate.getTime() / 1000);
    const end = Math.round(data.endDate.getTime() / 1000);
    const url = this.hostUrl + '?Start=' + start + '&End=' + end + '&PageNumber=' + data.pageNumber + '&PageSize=' + data.pageSize;
    return this.httpClient.getSync(url);
  }

  used(resumeId: number, jobcvId: number, jobId: number) {
    const url = this.hostUrl + '/used/' + resumeId + '/jobcv/' + jobcvId + '/job/' + jobId;
    return this.httpClient.postSync(url);
  }
}
