<div class="rt-panel-item">
  <div class="row" *ngIf="isLoading">
    <nz-skeleton [nzActive]="true"></nz-skeleton>
  </div>

  <nz-empty *ngIf="!feed && !isLoading" [nzNotFoundContent]="contentTpl" [nzNotFoundFooter]="footerTpl">
    <ng-template #contentTpl>
      <span> Post you are looking for which has been deleted. Please try other information </span>
    </ng-template>
    <ng-template #footerTpl>
      <button nz-button nzType="primary" (click)="onBack()">Go Back</button>
    </ng-template>
  </nz-empty>

  <div class="row rt-news-feed" *ngIf="!isLoading && feed">
    <div class="col-lg-9 col-md-8 col-12 news-feed-detail">
      <ng-container *ngTemplateOutlet="generalInformation"></ng-container>
      <div class="rt-news-feed-post">
        <ng-template *ngIf="feed.newsFeedTypeId === types.POLL then pollInformation"></ng-template>
      </div>

      <div class="rt-news-feed-post-photo" *ngIf="feed.files && feed.files.length > 0">
        <app-news-feed-images *ngIf="!feed.isNewcomerAnnouncement" [images]="feed.files"></app-news-feed-images>
        <app-avatar *ngIf="feed.isNewcomerAnnouncement" [avatarSource]="feed.contentHtml" [size]="128"></app-avatar>
      </div>

      <div class="comment-area" id="commentBox">
        <app-news-feed-comment [feed]="feed"></app-news-feed-comment>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-12 news-feed-lastest">
      <ng-container *ngTemplateOutlet="latestInformation"></ng-container>
    </div>
  </div>
</div>

<!-- <ng-template #surveyModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Surveys</h4>
    <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-newsfeed-survey-selection [activeModal]="activeModal" [surveys]="surveys"></app-newsfeed-survey-selection>
  </div>
  <div class="modal-footer">
    <div class="flex-row">
      <div class="form-group">
        <div class="float-right">
          <button (click)="c('Close')" class="btn btn-cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</ng-template> -->

<ng-template #latestInformation>
  <div class="news-feed-lastest-title">Latest News Feed</div>
  <div class="row" *ngIf="isLoading">
    <nz-skeleton [nzActive]="true"></nz-skeleton>
  </div>
  <ng-container *ngIf="!isLoading">
    <div class="news-feed-lastest-item" *ngFor="let item of latestFeeds; let i = index">
      <p class="btn-link" [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.DETAIL), item.id]">
        {{ item.title }}</p>
      <div class="news-feed-lastest-item-info">
        <app-avatar *ngIf="item.user" [size]="24" [avatarUser]="item?.user">
        </app-avatar>
        <span [routerLink]="[urlConstant.PROFILE_PAGE]" [queryParams]="{ employeeId :item?.user?.employeeId}"
          class="btn-link">{{ item?.user?.fullName }}</span>
        <div class="group-action">
          <a href="#">
            <img src="assets/images/front/shortcut/likes.svg" /> {{ item.totalLikes | thousandSuff }}
          </a>
          <a href="#">
            <img src="assets/images/front/shortcut/comment-post.svg" /> {{ item.totalComments | thousandSuff }}
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #generalInformation>
  <h3>{{ feed.title }}</h3>
  <div class="recent-notification news-feed">
    <div class="img-notify">
      <app-avatar *ngIf="feed.user" [avatarSource]="feed?.user?.avatarUrl" [avatarText]="feed?.user?.fullName">
      </app-avatar>
    </div>

    <div>
      <p>
        <b>{{feed?.user?.fullName}}</b>
        <ng-container *ngIf="feed.departmentGroups && feed.departmentGroups.length > 0">
          <img class="share-to-group-arrow" type="local" src="assets/images/icons/share-to-icon.svg" alt="" />
          <b *ngFor="let department of feed.departmentGroups; let first = first; let last = last">
            <span [routerLink]="[urlConstant.mapGroup(urlConstant.GroupUrl.NEWSFEED)]"
              [queryParams]="{ groupId :department.id }">{{ department.name }}</span>
            <span *ngIf="feed.departmentGroups && feed.departmentGroups.length > 1 && !last"> , </span>

          </b>
        </ng-container>
      </p>
      <p>{{ feed?.createdDate | amFromUtc | amTimeAgo }}</p>
    </div>


    <div class="dropdown show rt-notification-option" *ngIf="feed.isAllowUpdate || feed.isAllowDelete">
      <a class="rt-account" href="#" role="button" id="rt-account-notification-{{feed.id}}" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <span></span>
      </a>

      <div class="dropdown-menu dropdown-menu-right" [attr.aria-labelledby]="'rt-account-notification-' + feed.id">
        <a *ngIf="feed.isAllowUpdate" class="dropdown-item" (click)="onEditFeed(feed)">
          <img src="assets/images/front/shortcut/mark.svg"/>
          Edit message
        </a>

        <a *ngIf="feed.isAllowDelete" class="dropdown-item" (click)="onDeleteFeed(feed)">
          <img src="assets/images/front/shortcut/delete.svg"/>
          Delete this message
        </a>
      </div>
    </div>

    <div class="rt-option-group">
      <div class="left">
        <ul class="reactions-component d-flex">
          <li *ngIf="feed.allowLike">
            <a (click)="onLike(feed)">
              <span [ngClass]="(userLiked ? 'liked' : 'not-like') + ' like'" data-toggle="tooltip" title=""
                data-original-title="like">
                <ins *ngIf="feed.totalLikes">{{ feed.totalLikes | thousandSuff }}</ins><span
                  class="label">{{ feed.totalLikes > 1 ? 'Likes' : 'Like' }}</span>
              </span>
            </a>
          </li>
          <li *ngIf="feed.allowComment">
            <a (click)="scrollAuto('commentBox')">
              <span class="comment" data-toggle="tooltip" title="" data-original-title="Comments">
                <ins *ngIf="feed.totalComments">{{ feed.totalComments | thousandSuff }}</ins><span
                  class="label">{{ feed.totalComments > 1 ? 'Comments' : 'Comment' }}</span>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>


  <div class="description-newsfeed-detail">
    <!-- <div *ngIf="!feed.isNewcomerAnnouncement" class="preview" [innerHTML]="feed.contentHtml | shortcodeToHtml | safeHtml"></div> -->
    <p [innerHTML]="feed.description | shortcodeToHtml | safeHtml"></p>
    <app-news-feed-image-avatar *ngIf="feed.isNewcomerAnnouncement" [src]="feed.contentHtml"></app-news-feed-image-avatar>
  </div>

</ng-template>

<ng-template #pollInformation>
  <div *ngFor="let question of feed.pollQuestions; let i = index">
    <div *ngIf="i < maxQuestions" class="poll-question">
      <div class="poll-question-content">
        <span [innerHTML]="question.content | shortcodeToHtml | safeHtml"></span>
      </div>
      <div class="poll-question-options">
        <div *ngIf="question.isMultipleChoice">
          <div *ngFor="let option of question.options" class="poll-question-option">
            <div class="poll-question-option-container">
              <div class="poll-question-option-content">
                <div>
                  <label *ngIf="!isPollExpired" nz-checkbox [nzDisabled]="option.disabled || question.isExpired"
                    (ngModelChange)="onPollMultipleChoiceSelect(option, question)" [(ngModel)]="option.isSelected">{{ option.title }}</label>
                  <label *ngIf="isPollExpired" nz-checkbox [nzDisabled]="true" [ngModel]="option.isSelected">{{ option.title }}</label>
                </div>
                <nz-progress *ngIf="isPollExpired || feed.allowViewInstanceResult" [nzPercent]="getOptionPercent(question.id, option.totalChoice)" [nzShowInfo]="false">
                </nz-progress>
              </div>
              <ng-container *ngIf="(isPollExpired || feed.allowViewInstanceResult)">
                <ng-container *ngTemplateOutlet="voteAmount; context: {$implicit: question, option: option }">
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="!question.isMultipleChoice">
          <nz-radio-group [ngModel]="question.userChoice" (ngModelChange)="onPollSelect(question, $event)">
            <div *ngFor="let option of question.options" class="poll-question-option">
              <div class="poll-question-option-container">
                <div class="poll-question-option-content">
                  <div>
                    <label nz-radio [nzDisabled]="isPollExpired" nzValue="{{ option.id }}">
                      <span class="radio-label">{{ option.title }}</span>
                    </label>
                  </div>
                  <nz-progress *ngIf="isPollExpired || feed.allowViewInstanceResult" [nzPercent]="getOptionPercent(question.id, option.totalChoice)" [nzShowInfo]="false">
                  </nz-progress>
                </div>
                <ng-container *ngIf="(isPollExpired || feed.allowViewInstanceResult)">
                  <ng-container *ngTemplateOutlet="voteAmount; context: {$implicit: question, option: option }">
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </nz-radio-group>
        </div>
        <div class="poll-question-note-container">
          <div class="poll-question-note" *ngIf="question.allowNote">
            <textarea nz-input placeholder="Short description" [disabled]="isPollExpired" [(ngModel)]="question.userNote.description" nzSize="large"
              class="poll-usernote-input" name="userNote" [ngModelOptions]="{ standalone: true }"></textarea>
            <button *ngIf="!isPollExpired" [disabled]="!canSaveUserNote(question)" class="user-note-submit-button" (click)="onSubmitUserNote(question)" type="button"
              nz-button nzType="primary" nzShape="circle">
              <i nz-icon nzType="check" nzTheme="outline"></i>
            </button>
          </div>
          <div class="poll-question-option-vote" *ngIf="isPollExpired || feed.allowViewInstanceResult"></div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isPollExpired || feed.allowViewInstanceResult" class="text-center mb-2">
    <a class="more-link view-result text-center" (click)="onShowInstanceResult()"> View All Results </a>
  </div>
  <div *ngIf="maxQuestions < feed.pollQuestions.length" class="text-center">
    <button class="more-button" (click)="maxQuestions = feed.pollQuestions.length">
      View more ({{ feed.pollQuestions.length - maxQuestions }}) questions <i class="fas fa-chevron-down"></i>
    </button>
  </div>
</ng-template>

<ng-template #voteAmount let-question let-option="option">
  <div class="poll-question-option-vote">
    <ng-container *ngIf="option.totalChoice">
      <a class="view-result-options view-result" (click)="onShowInstanceOptionResult(question.id, option.id, question)">{{
        getPollResultText(question.id, option.totalChoice)
      }}</a>
      <span>{{ option.totalChoice > 1 ? 'Votes' : 'Vote' }}</span>
    </ng-container>
  </div>
</ng-template>
