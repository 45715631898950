import { Component, Input, OnInit } from '@angular/core';
import { UrlConstant } from '@intranet/lib/config';
import { CommonDepartmentDto, MemberDto, WidgetSearchDto, WidgetService } from '@intranet/lib/data-access';

@Component({
  selector: 'app-widget-company-detail',
  templateUrl: './widget-company-detail.component.html',
  styleUrls: ['./widget-company-detail.component.scss']
})
export class WidgetCompanyDetailComponent implements OnInit {
  @Input()
  searchData: WidgetSearchDto = new WidgetSearchDto();
  @Input()
  data: MemberDto[];
  loading = true;
  isDetail = false;
  urlConstant = UrlConstant;

  constructor(private _widgetService: WidgetService) { }

  ngOnInit() {
    this.data.map(this.transformMemberData);
  }

  openInformation(item) {
    const url = this.urlConstant.PROFILE_PAGE + '?employeeId=' + item.employeeId;
    window.open(
      url,
      '_blank'
    );
  }

  transformMemberData(data: any) {
    const member: MemberDto = data;
    const dep = new CommonDepartmentDto();
    dep.id = data.departmentId;
    dep.name = data.departmentName;
    member.departments = [dep];
    return member;
  }
}

