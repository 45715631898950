<ng-container *ngIf="widget">
  <nz-card [nzBordered]="false" [nzLoading]="loading">
    <div class="widget-header">
      <div class="widget-title">
        <ng-container *ngIf="isDetail">
          <div>
            <span (click)="hideDetail()" class="vector-right-icon btn-link"><img src="assets/images/front/shortcut/vector-right.svg" class="detail-back-btn"/></span>
            {{title}}
          </div>
        </ng-container>
        <ng-container *ngIf="!isDetail">
          <div>{{widget.data.title}}</div>
          <div class="only-phone">
            <a *ngIf="!isClientPage" class="view-detail-button" [routerLink]="'/user/leave'">Go to details</a>
          </div>
        </ng-container>
      </div>
      <div class="widget-control widget-control-sm-fully">
        <div class="widget-controll-wrapper">
          <ng-container *ngIf="searchData.isDisplayDataManage && !isDetail && data && data.departmentGroups && data.departmentGroups.length > 1">
            <nz-select class="widget-dashboard-select" (ngModelChange)="modelChanged($event)" nzShowSearch nzAllowClear nzPlaceHolder="Select a groups"
              [(ngModel)]="searchData.departmentIds">
              <nz-option *ngFor="let option of data.departmentGroups" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
            </nz-select>
          </ng-container>
          <ng-container *ngIf="searchData.isDisplayDataManage && isDetail && data && data.users && data.users.length > 1" [ngTemplateOutlet]="detailSortButtonsTpl">
          </ng-container>
          <a *ngIf="!isClientPage" class="view-detail-button ml-2 not-phone" [routerLink]="'/user/leave'" nz-tooltip nzTooltipTitle="Detail">
            <i nz-icon nzType="eye" nzTheme="outline"></i>
          </a>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!isDetail || isClientPage">
      <nz-card-meta [nzTitle]="titleTemplate" [nzDescription]="descriptionTemplate" [ngClass]="{ 'mb-3': isClientPage}"></nz-card-meta>
    </ng-container>
    <ng-container *ngIf="isClientPage" [ngTemplateOutlet]="clientPageDetailHeaderTpl"></ng-container>
    <ng-container *ngIf="isDetail || isClientPage">
      <app-widget-leave-detail #leaveDetail [(reloadData)]="reloadDetail" [(searchData)]="searchData" [displayMode]="displayMode" [mobileView]="isMobileView">
      </app-widget-leave-detail>
    </ng-container>
  </nz-card>
  <ng-template #titleTemplate>
    <div class="summary">
      <div class="row">
        <div class="col-12">
          <span [ngClass]="{'view-details': !isClientPage}" (click)="viewDetail()" *ngIf="searchData.isDisplayDataManage">{{data.numberMember}} Member(s)
          <span *ngIf="!isClientPage" class="only-tablet-and-phone view-members-button"><img src="assets/images/front/shortcut/vector-right.svg"/></span>
          </span>
          <span *ngIf="!searchData.isDisplayDataManage">{{data.fullName}}</span>
        </div>
      </div>
      <div class="row" [ngClass]="{ 'mobile-view-data-row': isMobileView }">
        <div class="col summary-item">
          <div class="summary-item-detail">
            <p class="font-12 d-inline-block">
              Total annual leaves
              <span class="lg-screen-icon" nz-popover nzType="primary" nzPopoverTrigger="click" [nzPopoverContent]="totalLeavePopoverTemplate">
                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
              </span>
              <span class="sm-screen-icon" (click)="openModalOnMobileScreen(descriptionLeaveType.Total)">
                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
              </span>
            </p>
            <div class="amount">
              <span>{{data.totalDay + data.prevYearTranferDay + data.sponsorDay}}</span>
            </div>
          </div>
        </div>
        <div class="col summary-item">
          <div class="summary-item-detail">
            <p class="font-12 d-inline-block">Remaining annual leaves</p>
            <div class="amount">
              <span>{{data.remainingDay}}</span>
            </div>
          </div>
        </div>
        <div class="col summary-item">
          <div class="summary-item-detail used-day-popover-template">
            <p class="font-12 d-inline-block">
              Used annual leaves
              <span class="lg-screen-icon" nz-popover nzType="primary" nzPopoverTrigger="click" [nzPopoverContent]="usedDayPopoverTemplate">
                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
              </span>

              <span class="sm-screen-icon" nz-popover nzType="primary" (click)="openModalOnMobileScreen(descriptionLeaveType.Used)">
                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
              </span>

            </p>
            <div class="amount">
              <span>{{data.usedDay + data.sellDay + data.tranferDay + data.sponsorDayUsed}}</span>
            </div>
          </div>
        </div>
        <div class="col summary-item">
          <div class="summary-item-detail used-day-popover-template">
            <p class="font-12 d-inline-block">
              Remaining sick leaves
              <span class="lg-screen-icon" nz-popover nzType="primary" nzPopoverTrigger="click" [nzPopoverContent]="skickLeavePopoverTemplate">
                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
              </span>

              <span class="sm-screen-icon" nz-popover nzType="primary" (click)="openModalOnMobileScreen(descriptionLeaveType.Used)">
                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
              </span>

            </p>
            <div class="amount">
              <span>{{data.sickLeave - data.sickLeaveUsed}}</span>
            </div>
          </div>
        </div>
        <div class="col summary-item">
          <div class="summary-item-detail">
            <p class="font-12 d-inline-block">Paid leaves</p>
            <div class="amount">
              <span>{{data.legalEntitledDay}}</span>
            </div>
          </div>
        </div>

        <div class="col summary-item">
          <div class="summary-item-detail used-day-popover-template">
            <p class="font-12 d-inline-block">
              Unpaid leaves
              <span class="lg-screen-icon" nz-popover nzType="primary" nzPopoverTrigger="click" [nzPopoverContent]="unpaidDayPopoverTemplate">
                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
              </span>

              <span class="sm-screen-icon" nz-popover nzType="primary" (click)="openModalOnMobileScreen(descriptionLeaveType.Unpaid)">
                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
              </span>
            </p>
            <div class="amount">
              <span>{{data.unpaidDay + data.unpaidDaySocialInsurance}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #descriptionTemplate>
    <!-- <nz-progress [nzPercent]="data.usedDayPercent" nzSize="small"></nz-progress> -->
    <div class="widget-progress-wrapper mt-1">
      <div class="progress-bar-sm progress-bar-animated-alt progress">
        <div role="progressbar" [style]="{width : data.usedDayPercent +'%',backgroundColor : '#2473d6' }" aria-valuenow="data.usedDayPercent" aria-valuemin="0"
          aria-valuemax="100" class="progress-bar"></div>
      </div>
    </div>
  </ng-template>
  <ng-template #titleDetailTemplate>
    <span *ngIf="searchData.isDisplayDataManage">{{data.numberMember}} Members</span>
    <span *ngIf="!searchData.isDisplayDataManage">{{data.fullName}}</span>
  </ng-template>

</ng-container>

<ng-template #detailSortButtonsTpl>
  <nz-select class="widget-dashboard-select" (ngModelChange)="modelChangedUsers($event)" nzShowSearch nzAllowClear nzPlaceHolder="Select a user"
    [(ngModel)]="searchData.userIds">
    <nz-option *ngFor="let option of data.users" [nzLabel]="option.fullName" [nzValue]="option.id"></nz-option>
  </nz-select>

  <nz-select class="widget-dashboard-sort-name" (ngModelChange)="modelChangedSortFieldUsers($event)" nzShowSearch nzPlaceHolder="Select a user"
    [(ngModel)]="searchData.sortField">
    <nz-option *ngFor="let option of sortNames" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
  </nz-select>

  <nz-select class="widget-dashboard-sort-by" (ngModelChange)="modelChangedSortByUsers($event)" nzShowSearch nzPlaceHolder="Select a user"
    [(ngModel)]="searchData.sortFieldType">
    <nz-option *ngFor="let option of sortBys" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
  </nz-select>
</ng-template>

<ng-template #clientPageDetailHeaderTpl>
  <div class="client-page-detail-header">
    <div class="sort-inputs">
      <ng-container [ngTemplateOutlet]="detailSortButtonsTpl"></ng-container>
    </div>
  </div>
</ng-template>


<nz-modal [(nzVisible)]="isVisibleDescription" [nzTitle]="descriptionLeaveTypeTitle[descriptionLeaveTypeSelected]" (nzOnCancel)="cancelModalOnMobileScreen()">
  <ng-container *nzModalContent>
    <ng-container [ngSwitch]="descriptionLeaveTypeSelected">
      <ng-container *ngSwitchCase="descriptionLeaveType.Total" [ngTemplateOutlet]="totalLeavePopoverTemplate"></ng-container>
      <ng-container *ngSwitchCase="descriptionLeaveType.Used" [ngTemplateOutlet]="usedDayPopoverTemplate"></ng-container>
      <ng-container *ngSwitchCase="descriptionLeaveType.Unpaid" [ngTemplateOutlet]="unpaidDayPopoverTemplate"></ng-container>
    </ng-container>
  </ng-container>
  <ng-container *nzModalFooter></ng-container>
</nz-modal>

<ng-template #totalLeavePopoverTemplate>
  <app-leave-tooltip [data]="data" [isTotalLeave]="true"></app-leave-tooltip>
</ng-template>


<ng-template #usedDayPopoverTemplate>
  <app-leave-tooltip [data]="data"></app-leave-tooltip>
</ng-template>

<ng-template #unpaidDayPopoverTemplate>
  <app-leave-tooltip [isUpaidLeave]="true" [data]="data"></app-leave-tooltip>
</ng-template>

<ng-template #skickLeavePopoverTemplate>
  <app-leave-tooltip [isSickLeave]="true" [data]="data"></app-leave-tooltip>
</ng-template>
