import { Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NgkMessageService {
  sourceShowMessage = new Subject<any>();
  constructor() {}

  show(data: MessageOption) {
    this.sourceShowMessage.next(data);
    if (data.duration > 0) {
      setTimeout(() => {
        this.close();
      }, data.duration || 3000);
    }
  }
  close() {
    this.sourceShowMessage.next({ isShow: false });
  }
}

export enum MessageType {
  warning = 'warning',
  success = 'success',
  info = 'info',
  error = 'error',
}
export class MessageOption {
  isShow?: boolean;
  title?: string;
  type?: 'warning' | 'success' | 'info' | 'error';
  content?: string | TemplateRef<void>;
  duration?: number;
}
