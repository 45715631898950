import { Injectable } from '@angular/core';
import { HttpClientService, Constants, Toastr } from '@intranet/index';
import { BaseService } from './base.service';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { FormAction, ResignForm, ResignStatus } from '@intranet/lib/data-access/models';

const LEADER = 'leader';
const HR = 'hr';

@Injectable({
  providedIn: 'root',
})
export class ResignFormService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/resign-form';
  statusValue = {
    approval: [ResignStatus.LeaderApproved, ResignStatus.HRApproved],
    rejection: [ResignStatus.LeaderRejected, ResignStatus.HRRejected],
    neutral: [ResignStatus.PendingApproval],
  };
  formAction: [ResignStatus, FormAction][] = [
    [ResignStatus.PendingApproval, FormAction.NeedLeader],
    [ResignStatus.LeaderApproved, FormAction.NeedHR],
    [ResignStatus.LeaderRejected, FormAction.NeedLeaderDelete],
    [ResignStatus.HRApproved, FormAction.NeedHRDelete],
    [ResignStatus.HRRejected, FormAction.NeedHRDelete],
  ];
  updateStatusRoute = [
    [ResignStatus.LeaderApproved, '/leader-approve'],
    [ResignStatus.LeaderRejected, '/leader-reject'],
    [ResignStatus.HRApproved, '/hr-approve'],
    [ResignStatus.HRRejected, '/hr-reject'],
  ];

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  view(searchData: any) {
    const url = `${this.hostUrl}/view`;
    return this.httpClient.get(url, { params: searchData });
  }

  getForm() {
    const url = this.hostUrl + '/get-by-user-id';
    return this.httpClient.getSync(url);
  }

  createResignationForm(request: any) {
    return this.httpClient.post(this.hostUrl, request);
  }

  updateResignationForm(request: any) {
    return this.httpClient.put(this.hostUrl, request);
  }

  updateStatus(resignFormData: ResignForm) {
    const updateRoute = this.updateStatusRoute.find(route => route[0] === resignFormData.status);
    if (!updateRoute) {
      Toastr.error(`Resign update status: Can't find route for ${resignFormData.status}`);
      return;
    }
    const url = this.hostUrl + updateRoute[1];
    return this.httpClient.putSync(url, resignFormData);
  }

  getByUserId(userId: string, successCb?: any) {
    const url = '/get-by-user-id';
    return this.getByUrl(url, { userId }, successCb);
  }

  getHistoryByUserId(userId: string, successCb?: any) {
    const url = 'get-history-by-user-id';
    return this.getByUrl(url, { userId }, successCb);
  }

  getStatusColor(status: ResignStatus) {
    let color = 'text-info';
    if (this.statusValue.approval.includes(status)) {
      color = 'text-success';
    } else if (this.statusValue.rejection.includes(status)) {
      color = 'text-danger';
    } else if (this.statusValue.neutral.includes(status)) {
      color = 'text-info';
    }
    return `<span class="${color}">${ResignStatus[status]}</span>`;
  }

  deleteResignationForm(resignFormId: string) {
    const url = `${this.hostUrl}/${resignFormId}`;
    return this.httpClient.delete(url);
  }
}
