export enum BenefitCriteriaOperatorEnum {
  Equal = 1,
  NotEqual = 2,
  GreaterThan = 3,
  LessThan = 4,
  GreaterThanEqual = 5,
  LessThanOrEqual = 6
}

export enum BenefitCriteriaOperatorConstEnum {
  Equal = '=',
  NotEqual = '!=',
  GreaterThan = '>',
  LessThan = '<',
  GreaterThanEqual = '>=',
  LessThanOrEqual = '<='
}
