import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NewsFeed, NewsFeedTypeEnum, SearchNewsFeed, WallNewsFeedService } from '@intranet/lib/data-access';
@Component({
  selector: 'app-newsfeed-hashtag',
  templateUrl: './hashtag.component.html',
  styleUrls: ['./hashtag.component.scss'],
})
export class HashtagPageComponent implements OnInit {
  year = new Date().getFullYear();

  feeds: [NewsFeed];
  searchData: SearchNewsFeed = new SearchNewsFeed();
  isLoading: boolean;

  code: string = null;

  constructor(private wallNewsFeedService: WallNewsFeedService, private route: ActivatedRoute) {
    this.isLoading = false;
    this.route.queryParams.subscribe(params => {
      this.code = params.id;
    });
  }

  ngOnInit() {
    this.code = this.route.snapshot.params.id;
    this.searchData.pageSize = 20;
    this.searchData.pageNumber = 1;
    this.searchData.keyWord = this.code;

    this.getFeeds();
  }

  getFeeds() {
    this.wallNewsFeedService.getHashtagFeed(
      this.searchData,
      (resp: any) => {
        this.feeds = resp;
        if (resp && resp.length) {
          this.searchData.totalItems = resp[0].totalCount;
        }
        this.isLoading = false;
      },
      (error: any) => {
        // console.error(error);
        this.isLoading = false;
      },
    );
  }
}
