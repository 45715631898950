import { Component, OnInit } from '@angular/core';
import { WebsiteData, SecurityHelper, Constants, UrlConstant } from '@intranet/index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-permission',
  templateUrl: './not-permission.component.html',
  styles: [],
})
export class NotPermissionComponent implements OnInit {
  myStyle: object = {};
  myParams: object = {};
  width = 100;
  height = 100;
  constructor(private router: Router) {}

  ngOnInit() {
    this.myStyle = WebsiteData.myStyle;
    this.myParams = WebsiteData.myParams;
  }

  goHome() {
    let url = SecurityHelper.detectFirstPageAllow();
    if (url === '/user/leave') {
      url = UrlConstant.USER_FIRSTPAGE;
    }
    this.router.navigateByUrl(url);
  }
}
