import { Injectable } from '@angular/core';
@Injectable()
export class CommonService {

  constructor() { }

  pad(num: number, size: number): string {
    let s = `${num}`;
    while (s.length < size) {
      s = `0${s}`;
    }
    return s;
  }
}
