import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppInjector } from './app-injector';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
declare var bootbox: any;

@Injectable({
  providedIn: 'root',
})
export class Bootbox {
  static confirm(title: any = 'page.common.warning', content: any = 'page.common.ask-delete', haveText?: boolean, options?: any): Observable<any> {
    const translateService = AppInjector.getService<TranslateService>(TranslateService);
    const modalService = AppInjector.getService<NzModalService>(NzModalService);
    // const btnCancel = translateService.instant('page.common.cancel');
    // const btnOk = translateService.instant('page.common.ok');
    // const theme = document.querySelector('#theme');
    const ztitle = translateService.instant(title);
    const zmessage = translateService.instant(content);
    // const className = theme ? theme.className : '';
    return new Observable(observer => {
      const confirmModal = modalService.confirm({
        nzTitle: ztitle,
        nzContent: zmessage,
        nzOnOk: () => observer.next(true),
        nzOnCancel: () => observer.next(false),
      });
      // if (!haveText) {
      //   bootbox.confirm({
      //     title: translateService.instant(title),
      //     message: translateService.instant(content),
      //     className: ' ' + className,
      //     buttons: {
      //       cancel: {
      //         className: 'btn btn-default waves-effect',
      //         label: '<i class="far fa-times-circle"></i> ' + btnCancel,
      //       },
      //       confirm: {
      //         className: 'btn btn-primary waves-effect',
      //         label: '<i class="fas fa-check"></i> ' + btnOk,
      //       },
      //     },
      //     callback(result) {
      //       observer.next(result);
      //     },
      //     onEscape: true,
      //     backdrop: true,
      //     ...options,
      //   });
      // } else {
      //   bootbox.prompt({
      //     title: translateService.instant(title),
      //     message: translateService.instant(content),
      //     className: ' ' + className,
      //     buttons: {
      //       cancel: {
      //         className: 'btn btn-default waves-effect btn-sm',
      //         label: '<i class="far fa-times-circle"></i> ' + btnCancel,
      //       },
      //       confirm: {
      //         className: 'btn btn-primary waves-effect btn-sm',
      //         label: '<i class="fas fa-check"></i> ' + btnOk,
      //       },
      //     },
      //     callback(result) {
      //       observer.next(result);
      //     },
      //     onEscape: true,
      //     backdrop: true,
      //     ...options,
      //   });
      // }
    });
  }
}
