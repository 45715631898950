export const environment = {
  name: 'qc',
  production: true,
  iswithCredentials: true,
  firebase: {
    apiKey: 'AIzaSyAXREL6VvPXVaR9JEqY3Bh09lSRY036d2I',
    authDomain: 'intranet-development-7b7da.firebaseapp.com',
    projectId: 'intranet-development-7b7da',
    storageBucket: 'intranet-development-7b7da.appspot.com',
    messagingSenderId: '710831789472',
    appId: '1:710831789472:web:e7b418019152300b27c0c5',
    measurementId: 'G-ZD06NEMEJ0'
  },
};
