import { Injectable } from '@angular/core';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { HttpClientService } from '@intranet/index';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectMemberService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/projectmember';
  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getByProjectId(projectId) {
    const url = this.hostUrl + '/get_by_project/' + projectId;
    return this.httpClient.getSync(url);
  }
}
