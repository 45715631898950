import { Component, OnInit } from '@angular/core';
import { NewsFeed, WallNewsFeedService, NewsFeedTypeEnum, SearchNewsFeed } from '@intranet/lib/data-access';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-newsfeed-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarPageComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {}
}
