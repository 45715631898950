<p class="title text-center">Forgot password</p>
<p class="sub-title text-center" [innerHTML]="!hasSent ? 'Verification will sent into your email' : 'Verification email has sent, please check your email'">
</p>
<form [formGroup]="formGroup" (submit)="forgotPassword()" *ngIf="!hasSent">
  <div class="form-group">
    <label for="username">Email / Username</label>
    <input type="text" class="form-control" formControlName="username" id="username" placeholder="Enter email / Username" required>
    <span class="text-danger" *ngIf="username?.errors?.required && username.touched">Email / Username is required</span>
  </div>
  <button type="submit" class="btn btn-primary btn-block">Send verification</button>
</form>