import { Injectable } from '@angular/core';
import { UrlConstant } from '../config';
import { Permission, UserModel } from '../data-access';
import { AppInjector } from '../settings/services/app-injector';
import { CookieHelper } from './cookieHelper';
import { LocalStorage } from './localStorage';

@Injectable({
  providedIn: 'root',
})
export class SecurityHelper {
  static secrectKey = 'YWtlcyFAIzYxNmI2NTczMjE0MDIzQCMkIyUyNDAyMzI0MjMyNTMyNDAyNDQwMjM0MEAjJCMlMjIzMjQ3MzY0NjY1M0AjJCMlMjQ0NDY1';
  static authenKey = 'aluha.intranet.pro';

  static createStore(key: string, data: any) {
    if (data !== null && data !== undefined) {
      const storage = AppInjector.getService<LocalStorage>(LocalStorage);
      storage.createStore(key, data);
      if (data.token) {
        CookieHelper.createStore(key, data.token);
      }
    }
  }

  static getStore(key: string) {
    const storage = AppInjector.getService<LocalStorage>(LocalStorage);
    const result = storage.getStore(key);
    return result;
  }

  static createCookieToken(key: string, token: any) {
    if (!key) {
      key = this.authenKey;
    }
    if (token) {
      CookieHelper.createStore(key, token);
    }
  }

  static removeStore(key: string) {
    const injector = AppInjector.getInjector();
    const local = injector.get(LocalStorage);
    if (key === this.authenKey) {
      CookieHelper.removeStore(key);
    }
    return local.removeStore(key);
  }

  static createStoreAuthen(data: any, key?: string) {
    let finalKey = this.authenKey;
    if (key) {
      finalKey = key + this.authenKey;
    }
    this.createStore(finalKey, data);
  }
  static getStoreAuthen(key?: string) {
    let finalKey = this.authenKey;
    if (key) {
      finalKey = key + this.authenKey;
    }
    return this.getStore(finalKey);
  }

  static get department() {
    const user = SecurityHelper.getStoreAuthen();
    if (user && user.departments) {
      return JSON.parse(user.departments);
    }
    return undefined;
  }

  static get token() {
    const authToken = SecurityHelper.getStoreAuthen();
    if (authToken && authToken.token) {
      return authToken.token;
    }
    return undefined;
  }

  static destroyAuthen() {
    // this.removeStore('hrm-timeout');
    this.removeStore(this.authenKey);
  }

  static get isLogin() {
    const user = SecurityHelper.getStoreAuthen();
    if (user && user.user && user.token) {
      return true;
    }
    return false;
  }

  static get isAuthenticated(): boolean {
    const credentials = SecurityHelper.getStoreAuthen();

    return credentials?.token;
  }

  static get getRoles(): Permission[] {
    const user = SecurityHelper.getStoreAuthen();
    if (user && user.roles) {
      let allRoles;
      if (typeof user.roles === 'string') {
        allRoles = JSON.parse(user.roles);
      } else {
        allRoles = user.roles;
      }
      return allRoles;
    }
    return undefined;
  }

  static checkPermission(controller: string, action: string, department?: any) {
    const allRoles = SecurityHelper.getRoles;
    const deparmentData = SecurityHelper.department;
    if (allRoles) {
      if (department) {
        let permissionFound;
        if (department instanceof String) {
          permissionFound = allRoles.find(
            x =>
              x.controller.toUpperCase() === controller.toUpperCase() &&
              x.action.toUpperCase() === action.toUpperCase() &&
              deparmentData.some(p => p.Id === department),
          );
        } else if (department instanceof Array) {
          permissionFound = allRoles.find(
            x =>
              x.controller.toUpperCase() === controller.toUpperCase() &&
              x.action.toUpperCase() === action.toUpperCase() &&
              deparmentData.some(p => department.some(a => a.id === p.Id)),
          );
        }
        if (permissionFound) {
          return true;
        }
      } else {
        const permissionFound = allRoles.find(
          x => x.controller.toUpperCase() === controller.toUpperCase() && x.action.toUpperCase() === action.toUpperCase(),
        );
        if (permissionFound) {
          return true;
        }
      }
    }
    return false;
  }

  static checkPermissionUrl(url: string) {
    const allRoles = SecurityHelper.getRoles;
    if (allRoles) {
      const permissionFound = allRoles.find(x => x.url && x.url.toUpperCase() === url.toUpperCase());
      if (permissionFound) {
        return true;
      }
    }
    return false;
  }

  static getStateUrl(path: string, url: string) {
    const index = url.indexOf(path);
    if (index >= 0) {
      return url;
    }
    return undefined;
  }

  static detectFirstPageAllow(url?: string) {
    const roles = SecurityHelper.getRoles;
    const user = SecurityHelper.User;
    if (roles) {
      const firstPage = roles.find(x => x.url);
      const havePermission = roles.find(x => x.url === url);
      if (havePermission && url) {
        return url;
      } else if (firstPage) {
        // let dashboard;
        // if (user.isAdminAccess) {
        //   dashboard = roles.find(x => x.url === UrlConstant.ADMIN_FIRSTPAGE);
        // } else if (user.isPersonalAccess) {
        //   dashboard = roles.find(x => x.url === UrlConstant.USER_FIRSTPAGE);
        // }
        return UrlConstant.NEWFEEDS_FIRSTPAGE; // dashboard ? dashboard.url : firstPage.url;
      } else {
        return '/not-permission';
      }
    }
    return '/not-permission';
  }

  static detectAdblock() {
    // tslint:disable-next-line:only-arrow-functions
    document.addEventListener('DOMContentLoaded', function () {
      setTimeout(() => {
        if (document.getElementById('wrapper').offsetHeight) {
          SecurityHelper.removeStore('adblock');
        } else {
          SecurityHelper.createStore('adblock', true);
        }
      }, 1000);
    });
  }

  static get isAdblock() {
    return this.getStore('adblock');
  }

  static getFristPageForModule(modl: number): string {
    const roles = SecurityHelper.getRoles;
    if (roles) {
      const firstPage = roles.find(x => x.url && x.accessModules && x.accessModules.some(p => p === modl));
      return firstPage ? firstPage.url : '/user/settings';
    }
    return '/user/settings';
  }

  static get User(): UserModel {
    const authToken = SecurityHelper.getStoreAuthen();
    return authToken;
  }
}
