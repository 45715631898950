import { Component, OnInit, Input, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { WidgetSearchDto, WidgetService, WidgetLeaveDto, PagingSortenum, MemberDisplayMode } from '@intranet/lib/data-access';
import { combineLatest, Subscription } from 'rxjs';
import { GridsterItem } from 'angular-gridster2';
import { ActivatedRoute } from '@angular/router';
import { WebsiteEvent } from '@intranet/lib/settings/event-store/website.event';
import { WidgetLeaveDetailComponent } from './widget-leave-detail/widget-leave-detail.component';
import { CommonHelper } from '@intranet/index';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';

enum DescriptionLeaveType {
  Total = 1,
  Used = 2,
  Unpaid = 3
}

@Component({
  selector: 'app-widget-leave',
  templateUrl: './widget-leave.component.html',
  styleUrls: ['./widget-leave.component.scss'],
})
export class WidgetLeaveComponent implements OnInit, OnDestroy {
  @ViewChild('leaveDetail') leaveDetailComponent: WidgetLeaveDetailComponent;
  @Input() widget;
  @Input() resizeEvent: EventEmitter<GridsterItem> = null;
  @Input() displayMode: MemberDisplayMode = MemberDisplayMode.UserWidget;
  @Input() widgetLoaded: EventEmitter<any>;
  MemberDisplayMode = MemberDisplayMode;
  resizeSub: Subscription;
  loading = true;
  searchData: WidgetSearchDto = new WidgetSearchDto();
  data: WidgetLeaveDto = new WidgetLeaveDto();
  isDetail = false;
  reloadDetail = false;
  title: string;
  sortBys = [
    { id: PagingSortenum.ASC, name: 'ASC' }
    , { id: PagingSortenum.DESC, name: 'DESC' }
  ];

  isMobileView: boolean = CommonHelper.isMobileView();
  subscriptions: Subscription[] = [];

  sortNames = [
    { id: 'FullName', name: 'Name' }
    , { id: 'TotalDay', name: 'Total annual leaves' }
    , { id: 'UsedDay', name: 'Used annual leaves' }
    , { id: 'RemainingDay', name: 'Remaining annual leaves' }
    , { id: 'UnpaidDay', name: 'Unpaid leaves' }
    , { id: 'LegalEntitledDay', name: 'Paid leaves' }
  ];

  descriptionLeaveTypeTitle = {
    [DescriptionLeaveType.Total]: 'Total annual leaves',
    [DescriptionLeaveType.Used]: 'Used annual leaves',
    [DescriptionLeaveType.Unpaid]: 'Unpaid leaves',
  };
  isVisibleDescription = false;
  descriptionLeaveType = DescriptionLeaveType;
  descriptionLeaveTypeSelected = null;

  get isClientPage() {
    return this.displayMode === this.MemberDisplayMode.ClientPage;
  }

  constructor(private _widgetService: WidgetService,
    private activatedRoute: ActivatedRoute,
    private websiteEvent: WebsiteEvent) {
    this.searchData.sortFieldType = this.sortBys[0].id;
    this.searchData.sortField = this.sortNames[0].id;
  }

  ngOnDestroy() {
    this.subscriptions?.forEach(s => s?.unsubscribe());
  }

  ngOnInit() {
    this.searchData.noLoading = this.isClientPage;
    this.subscribeDataSearch();

    const resizeSub = this.websiteEvent.eventChangeHeight.subscribe(() => this.updateMobileViewData());
    this.subscriptions.push(resizeSub);
  }

  subscribeDataSearch() {
    const dataSearchSub = combineLatest([
      this.activatedRoute.queryParams,
      this.websiteEvent.groupSourceDataEvent
    ])
      .subscribe(([queryParams, departmentId]) => {
        this.searchData.employeeId = queryParams.employeeId;
        if (!this.searchData.departmentIds || departmentId === '') {
          this.searchData.departmentIds = departmentId !== '' ? departmentId as any : null;
          this.loadData();
        }
      });

    this.subscriptions.push(dataSearchSub);
  }

  async loadData() {
    try {
      this.loading = true;
      if (this.widget) {
        this.searchData.isDisplayDataManage = this.widget.data.isDisplayDataManage;
      }
      this.searchData.userIds = null;
      this.data = await this._widgetService.getWidgetLeave(this.searchData);
      this.widgetLoaded?.emit(this.widget);
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
    finally {
      this.loading = false;
    }
  }

  modelChanged(event) {
    this.searchData.departmentIds = event;
    this.loadData();
    if (event !== null) {
      this.websiteEvent.setGroupSourceDataEvent(event);
    }

    if (this.isClientPage) {
      this.searchData = Object.assign({}, this.searchData);
    }
  }

  modelChangedUsers(event) {
    this.searchData.userIds = event;
    this.searchData = Object.assign({}, this.searchData);
  }

  modelChangedSortFieldUsers(event) {
    this.searchData.sortField = event;
    this.searchData = Object.assign({}, this.searchData);
  }

  modelChangedSortByUsers(event) {
    this.searchData.sortFieldType = event;
    this.searchData = Object.assign({}, this.searchData);
  }

  viewDetail() {
    if (this.isClientPage) {
      return;
    }

    this.title = 'Detail';
    this.isDetail = true;
  }
  hideDetail() {
    this.isDetail = false;
  }
  clearFilter() {
    this.websiteEvent.setGroupSourceDataEvent('');
  }

  updateMobileViewData() {
    this.isMobileView = CommonHelper.isMobileView();
  }

  openModalOnMobileScreen(descriptionLeaveType: number) {
    this.isVisibleDescription = true;
    this.descriptionLeaveTypeSelected = descriptionLeaveType;
  }

  cancelModalOnMobileScreen() {
    this.isVisibleDescription = false;
    this.descriptionLeaveTypeSelected = null;
  }
}
