<modal-header [title]="modalHeaderTpl" [activeModal]="activeModal" (close)="closeModal()">
  <ng-template #modalHeaderTpl>
    <div class="modal-title">{{title}}</div>
  </ng-template>
</modal-header>

<modal-body>
  <div class="row m-b-10">
    <div class="col">
      <nz-descriptions [nzColumn]="2" nzTitle="" nzBordered>
        <nz-descriptions-item nzTitle="Date"> {{date | date : 'EEE'}} {{date | date : 'dd/MM/yyyy'}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Status">
          <span [ngClass]="checkInCheckOutStatusDisplay[data.status].color">{{ checkInCheckOutStatusDisplay[data.status].name }}</span>
        </nz-descriptions-item>
        <ng-container *ngIf="data.hasEdit">
          <nz-descriptions-item nzTitle="Check in">
            <nz-time-picker [nzClearText]="false" [(ngModel)]="data.startTime" placeholder="Start Time" nzFormat="HH:mm"></nz-time-picker>

          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Check out">
            <nz-time-picker [nzClearText]="false" [(ngModel)]="data.endTime" placeholder="Start Time" nzFormat="HH:mm"></nz-time-picker>

          </nz-descriptions-item>
        </ng-container>
        <ng-container *ngIf="!data.hasEdit">
          <nz-descriptions-item nzTitle="Check in before claim">{{data.startTimeBeforeClaim | date : 'HH:mm'}}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Check out before claim">{{data.endTimeBeforeClaim | date : 'HH:mm'}}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Check in">{{data.startTime | date : 'HH:mm'}}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Check out">{{data.endTime | date : 'HH:mm'}}</nz-descriptions-item>
        </ng-container>
      </nz-descriptions>
    </div>
  </div>

</modal-body>

<modal-footer>
  <button class="close-btn btn btn-white" (click)="closeModal()" nz-tooltip nzTooltipTitle="Close">
    <i nz-icon nzType="close-circle" nzTheme="outline"></i> Close
  </button>

  <button *ngIf="data.hasEdit" class="submit-btn btn btn-primary" (click)="save()" nz-tooltip nzTooltipTitle="Claim check in/out time" nz-button>
    <i nz-icon nzType="save" nzTheme="outline"></i> Submit
  </button>
</modal-footer>
