<div class="comment-reactions">
    <div class="reactions-component">
      <a class="total-like" (click)="showReactions()" *ngIf="feed?.totalLikes !== 0" >
        <span class='liked like'>
          <ins>{{ feed.totalLikes | thousandSuff }}</ins>
        </span>
      </a>
      <div class="total-comment" *ngIf="feed?.totalComments !== 0">
        {{ feed.totalComments }}
        <span class="label">{{ feed.totalComments > 1 ? 'Comments' : 'Comment' }}</span>
      </div>
    </div>
    <ul class="reactions-component mb-2" *ngIf="feed.allowLike || feed.allowComment">
      <li *ngIf="feed.allowLike">
        <a (click)="onLike(feed)">
          <span [ngClass]="(userLiked ? 'liked' : 'not-like') + ' like'" data-toggle="tooltip" data-original-title="like">
            Like
            <!-- <ins *ngIf="feed.totalLikes">{{ feed.totalLikes | thousandSuff }}</ins> -->
            <!-- <span class="label">{{ feed.totalLikes > 1 ? 'Likes' : 'Like' }}</span> -->
          </span>
        </a>
      </li>
  
      <li *ngIf="feed.allowComment">
        <a>
          <span class="comment" data-toggle="tooltip" data-original-title="Comments">
            Comment
            <!-- <ins *ngIf="feed.totalComments">{{ feed.totalComments | thousandSuff }}</ins> -->
            <!-- <span class="label">{{ feed.totalComments > 1 ? 'Comments' : 'Comment' }}</span> -->
          </span>
        </a>
      </li>
    </ul>
  </div>
  
  <ng-template #likePopoverTemplate>
    <div *ngIf="isLoadingReaction">
      <nz-skeleton [nzActive]="true"></nz-skeleton>
    </div>
    <div *ngIf="!isLoadingReaction && reactions && reactions.length">
      <p *ngFor="let item of reactions" class="reaction-user-item">
        <app-avatar [size]="20" [avatarSource]="item?.avatarUrl" [avatarText]="item?.fullName"></app-avatar>
        <span class="name">{{item?.fullName}}</span>
        <span class="title">{{item?.positionName}}</span>
      </p>
    </div>
  </ng-template>