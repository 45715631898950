import { BenefitCriteriaOperatorEnum } from './enums/benefit-criteria-operator.enum';
import { BenefitCriteriaTypeEnum } from './enums/benefit-criteria-type.enum';
import { BenefitDataTypeEnum } from './enums/benefit-data-type.enum';
import { BenefitRegistrationTypeEnum } from './enums/benefit-registration-type.enum';
import { BenefitRequestStatusEnum } from './enums/benefit-request-status.enum';
import { BenefitStatusEnum } from './enums/benefit-status.enum';
import { Policy } from './policy.model';

export class Benefit {
  id: string;
  title: string;
  description: string;

  allowRegistration: boolean;
  registrationType: BenefitRegistrationTypeEnum;
  isActive: boolean;
  imageUrl: string;
  color: string;
  status: BenefitStatusEnum;
  benefitDates: BenefitDate[];
  benefitBonus: BenefitBonus[];
  isPanelActive: boolean;
  countValue;
}

export class BenefitDate {
  id: string;
  startDate?: Date;
  endDate?: Date;
  description: string;
}

export class BenefitBonus {
  id: string;
  benefitId: string;
  name: string;
  hasCriteria: boolean;
  description: string;
  benefitCriteria: BenefitCriteria[];
  benefitResults: BenefitResult[];
  benefitMembers: BenefitMember[];

  isPanelActive: boolean;
  benefit: Benefit;
}

export class BenefitCriteria {
  id: string;
  benefitBonusId: string;
  name: string;
  type: BenefitCriteriaTypeEnum;
  value: string;
  operator: BenefitCriteriaOperatorEnum;
}

export class BenefitMemberRegistration {
  id: string;
  benefitBonusId: string;
  status: BenefitRequestStatusEnum;
}

export class WidgetBenefit {
  benefitId: string;
  title: string;
  description: string;
  allowRegistration: boolean;
  isActive: boolean;
  imageUrl: string;
  color: string;
  status: BenefitStatusEnum;
  benefitDates: BenefitDate[];
  benefitBonus: BenefitBonus[];
}

export class BenefitCategory {
  id: string;
  name: string;
  parentId: string;
  parent: BenefitCategory;
  deletable: boolean;
  children: BenefitCategory[] = [];

  // nz-tree property
  expanded: boolean;
  isLeaf: boolean;
  key: string;
  title: string;
  selectable: boolean;
}

export class BenefitResult {
  id: string;
  benefitBonusId: string;
  benefitCategoryId: string;
  policyId: string;
  name: string;
  value: string;
  dataType: BenefitDataTypeEnum;
  benefitBonus: BenefitBonus;
  benefitCategory: BenefitCategory;
  policy: Policy;
  description: string;
  benefitMemberRegistrations: BenefitMemberRegistration[];

  registrable: boolean; // DTO only.
}

export class BenefitMember {
  id: string;
  benefitBonusId: string;
  userId: string;
  applyDate: Date;
  registrationDate: Date;
  inactive: boolean;
  user: any;
}

export class BenefitMemberRegistrationSearch {
  userId: string;
  benefitResultId: string;
  keyword: string;
  status: BenefitRequestStatusEnum | null;
  pageNumber: number | null;
  pageSize: number | null;

  constructor(userId: string, benefitResultId: string) {
    this.userId = userId;
    this.benefitResultId = benefitResultId;
  }

  setPaging(pageSize: number, pageNumber: number) {
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
  }
}

export class BenefitGroupedByCategory {
  id: string;
  name: string;
  benefits: Benefit[];
}
export class BenefitClaimDto {
  benefitId: string;
  totalCount: number;
  title: string;
  benefitStatusData: BenefitStatusDto[];
  year: number;
  benefitClaimDetails: BenefitClaimDetailDto[];
}

export class BenefitStatusDto {
  status: BenefitRequestStatusEnum | null;
  count: number;
}

export interface BenefitClaimDetailDto {
  benefitResultId: string;
  title: string;
  count: number;
  benefitClaimMembers: BenefitClaimMemberDto[];
  isExpanded: boolean | true;
}

export class BenefitClaimMemberDto {
  benefitRegistrationId: string;
  fullName: string;
  createdDate: string;
  invoiceValue: number | null;
  actualPay: number;
  diffPay: number;
  benefitValue: number | null;
  status: BenefitRequestStatusEnum;
  statusName: string;
  statusColor: string;
  benefitResultName: string;
  benefitName: string;
  hasRefund: boolean;
  hasPaid: boolean;
  year: number;
  reason: string;
  description: string;
  note: string;
  departments: string[];
}
