<div id="rt-container">
  <app-header-front [currentUser]="currentUser"></app-header-front>
  <main [class]="isExpaned ? 'expaned' : ''">
    <app-sidebar-left (sidebarLeftChanges)="onChangeSliderLeftActive($event)" [id]="sidebarId" [class]="showLeftPanel ? 'd-block' : ''">
    </app-sidebar-left>
    <div id="rt-content-container" class="rt-profile">
      <app-banner-cover [currentUser]="currentUser"></app-banner-cover>
      <app-front-group></app-front-group>
      <app-front-tab-group [groupId]="groupId"></app-front-tab-group>
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </main>
</div>
