<modal-header [title]="headerTemplate" [activeModal]="activeModal">
  <ng-template #headerTemplate>
    <h4 class="modal-title">{{ title || 'People '}}</h4>
  </ng-template>
</modal-header>
<modal-body>
  <div class="mb-5 reaction-user-items">
    <p *ngFor="let item of items" class="reaction-user-item mt-2" (click)="onNavigateUserProfile(item)">
      <app-avatar [size]="20" [avatarSource]="item?.avatarUrl" [avatarText]="item?.fullName"></app-avatar>
      <span class="name">{{item?.fullName}}</span>
      <span class="title">{{item?.positionName}}</span>
    </p>
  </div>
</modal-body>