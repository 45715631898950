import { Position } from '.';
import { UserGroupName } from './enums/user-group-name.enum';
import { GroupUser } from './groupuser.model';
import { UserModel } from './user-model';

export interface DepartmentGroupProfile {
  id: string;
  name: string;
  imageUrl: string;
  type: number;
  createdDate: string | null;
  users: any;
}

export class Department {
  id: string;
  key: string;
  name: string;
  title: string;
  imageUrl: string;
  type: number;
  parentId: string;
  parentName: string;
  nameClient: string;
  company: string;
  address: string;
  phone: string;
  contactEmail: string;
  typeName: string;
  taxCode: string;
  totalCount: number;
  parentLevel: number;
  level: number;
  children: any[];
  isDeleted?: boolean;
  deletedBy?: string;
  color: string;
}

export class EmployeeDepartment {
  positionId: string;
  groupUserId: string;
  employeeId: string;
  departmentId: string;
  departmentName: string;
  userId: string;
  user: UserModel;
  position: Position;
  groupUser: GroupUser;
  isMain: boolean;
}

export enum DEPT_TYPE {
  COMPANY = 1,
  DEPARTMENT = 2,
  FLOOR = 3,
  TEAM = 4,
  OTHER = 5,
  'Company' = DEPT_TYPE.COMPANY,
  'Department' = DEPT_TYPE.DEPARTMENT,
  'Floor' = DEPT_TYPE.FLOOR,
  'Team' = DEPT_TYPE.TEAM,
  'Other' = DEPT_TYPE.OTHER,
}

export class DepartmentUser {
  id: string;
  departmentId: string;
  userId?: string;
  fullName?: string;
  avatarUrl?: string;
  position?: string;
  createdDate?: Date;
  joinedDate: string;
  employeeId?: string;
  hasLeaveToday?: boolean;
  isNewcomer?: boolean;
  totalYearsOfExperience?: number;
}

export interface MyTeam {
  id: string;
  name: string;
  groupName: UserGroupName;
  isMain: boolean;
  parentId: string;
}

export interface MyTeamMember {
  id: string;
  name: string;
  avatarUrl: string;
}
