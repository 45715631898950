<!-- Left panel -->
<div id="rt-left-panel" class="rt-privacy" [ngClass]="{ hide: !showSidebar }">
  <ul class="nav nav-tabs flex-column" *ngIf="menuPolicys" id="privacy-settings" role="tablist">
    <virtual-scroller class="virtual-scroller" #scroll [items]="menuPolicys">
      <li
        *ngFor="let item of scroll.viewPortItems"
        [class]="dataPolicy && item.policyId === dataPolicy.policyId ? 'nav-item active' : 'nav-item'"
      >
        <a
          (click)="policyActive(item)"
          [class]="dataPolicy && item.policyId === dataPolicy.policyId ? 'nav-link active' : 'nav-link'"
          id="privacy-policy-tab"
          data-toggle="tab"
          href="#"
          role="tab"
          aria-controls="privacy-policy"
          aria-selected="true"
        >
          {{ item.title }}
        </a>
        <ul *ngIf="dataPolicy && item.policyId === dataPolicy.policyId" class="nav-sub-list">
          <li *ngFor="let itemDetail of item.policyDetails">
            <a
              [class]="dataPolicyDetail && itemDetail.policyDetailId === dataPolicyDetail.policyDetailId ? 'active' : ''"
              (click)="scrollToId(itemDetail)"
              href="#"
              >{{ itemDetail.title }}</a
            >
          </li>
        </ul>
      </li>
    </virtual-scroller>
  </ul>
</div>

<!-- Content -->
<div id="rt-content-container" class="rt-privacy content-policy">
  <div *ngIf="dataPolicy" class="rt-panel-item detail-policy">
    <div class="tab-content flex-column" id="privacy-settings-content">
      <div class="pt-2 tab-pane fade show active" id="privacy-policy" role="tabpanel" aria-labelledby="privacy-policy-sub-tab">
        <div class="w-100 p-2">
          <div class="content-privacy-policy">
            <h2>{{ dataPolicy.title }}</h2>
            <p class="time">Last Updated: {{ dataPolicy.updatedDate | date : 'yyyy-MM-dd' }}</p>
            <p class="mt-1">{{ dataPolicy.description }}</p>
            <div class="mt-1 mb-1" *ngFor="let itemDetail of dataPolicy.policyDetails; let i = index">
              <h5 [id]="itemDetail.policyDetailId">{{ i + 1 }}. {{ itemDetail.title }}</h5>
              <p class="mt-1">{{ itemDetail.description }}</p>
              <div class="mt-1" [innerHTML]="itemDetail.content | shortcodeToHtml | safeHtml"></div>

              <div class="row mt-1">
                <div class="col-12 attachments" *ngIf="itemDetail.files && itemDetail.files.length > 0">
                  <div class="document-container">
                    <div class="d-flex flex-wrap">
                      <div class="document-item" (click)="click(document)" *ngFor="let document of itemDetail.files">
                        <div class="document-icon" style="width: 180px">
                          <div *ngIf="document.fileType === FileExtensionType.IMAGE">
                            <img src="{{ document.url }}" />
                          </div>
                          <div *ngIf="document.fileType === FileExtensionType.EXCEL">
                            <img src="/assets/images/front/excel_placeholder.PNG" />
                          </div>
                          <div *ngIf="document.fileType === FileExtensionType.POWERPOINT">
                            <img src="/assets/images/front/ppt_placeholder.PNG" />
                          </div>
                          <div
                            *ngIf="
                              document.fileType === FileExtensionType.WORD ||
                              document.fileType === FileExtensionType.PDF ||
                              document.fileType === FileExtensionType.UNKNOWN
                            "
                          >
                            <img src="/assets/images/front/doc_placeholder.PNG" />
                          </div>
                          <div class="download-icon">
                            <i nz-icon nzType="download" nzTheme="outline"></i>
                          </div>
                          <app-file-icon [documentType]="document.fileType"></app-file-icon>
                        </div>
                        <div class="document-name">
                          <span class="ml5">{{ document.fileName }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
