<app-health-check-claim></app-health-check-claim>
<app-claim-daily></app-claim-daily>
<!-- <app-widget-personal-benefit></app-widget-personal-benefit> -->
<app-newsfeed-birthday></app-newsfeed-birthday>
<ng-container *hasPermission="['worktime', 'getworktimetodaybyuser']">
  <app-work-time></app-work-time>
</ng-container>
<app-newsfeed-work-anniversary></app-newsfeed-work-anniversary>
<app-newsfeed-surveys></app-newsfeed-surveys>
<app-newsfeed-announcements></app-newsfeed-announcements>
<app-newsfeed-events-calendar></app-newsfeed-events-calendar>
<!-- <app-newsfeed-recent-activities></app-newsfeed-recent-activities> -->
<app-widget-review [widget]="{}" [isSidebar]="true"></app-widget-review>
