import { BaseModel } from '../base-model';
import { CheckInCheckOutStatusEnum } from '../enums/checkincheckout.enum';

export class CheckInCheckOutRequest extends BaseModel {
  id: string;
  startTime: string | null;
  endTime: string | null;
  startTimeBeforeClaim: string | null;
  endTimeBeforeClaim: string | null;
  reason: string;
  approvedDate: string | null;
  approvedBy: string | null;
  approvedByName: string;
  employeeId: string;
  employeeFullName: string;
  checkInCheckOutStatus: number;
  departments: any;
  hasApprove: boolean;
  hasReject: boolean;
}

export class CheckInCheckOutInfo {
  startTime: string | null;
  endTime: string | null;
  startTimeBeforeClaim: string | null;
  endTimeBeforeClaim: string | null;
  employeeId: string;
  employeeFullName: string;
  status: CheckInCheckOutStatusEnum;
  hasEdit: boolean;
}


export class CreateCheckInCheckOutRequest {
  startTime: string | null;
  endTime: string | null;
}

export class ApproveCheckInCheckOutRequest {
  id: string;
  reason: string;
}
