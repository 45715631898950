import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { ApiEndpoint } from '../../config/api-endpoint.constant';
import { Department } from '../models/department.model';

@Injectable({
  providedIn: 'root',
})
export class AnnualLeaveService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/annual_leave';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getYears(): number[] {
    let currentYear = new Date().getFullYear() + 1;
    const years = [];
    const startYear = currentYear - 15;
    while (currentYear >= startYear) {
      years.push(currentYear--);
    }
    return years;
  }

  async getByCurrentEmp(empId: string, successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void, year?: number) {
    let requestUrl = `get_by_employee`;
    if (empId && !year) {
      requestUrl += `?guidId=${empId}`;
    } else if (empId && year) {
      requestUrl += `?guidId=${empId}&year=${year}`;
    } else if (!empId && year) {
      requestUrl += `?year=${year}`;
    }
    return await this.getByUrl(requestUrl, undefined, successCallback, failedCallback, completeCallback);
  }

  updateSponsorDayNumber(annualLeaveId: string, sponsorDay: number) {
    const updateSponsorDayRequest = {
      annualLeaveId,
      sponsorDay
    };
    const url = ApiEndpoint.AnnualLeave.UpdateSponsorDayNumber(annualLeaveId);

    return this.httpClient.putObservable(url, updateSponsorDayRequest).toPromise();
  }

  getSponsorDays(annualLeaveId: string) {
    const url = ApiEndpoint.AnnualLeave.GetSponsorDays(annualLeaveId);
    return this.httpClient.getObservable(url).toPromise();
  }

  updateSponsorDay(request: any) {
    const url = ApiEndpoint.AnnualLeave.UpdateSponsorDay(request?.annualLeaveId);
    return this.httpClient.putObservable(url, request).toPromise();
  }

  export(searchData: any): Promise<any> {
    const url = ApiEndpoint.AnnualLeave.Export();

    return this.httpClient.getObservable(url, searchData).toPromise();
  }

  getViewableDepartments(): Promise<Department[]> {
    const url = ApiEndpoint.AnnualLeave.Departments();

    return this.httpClient.getObservable(url).toPromise();
  }

  getYearAvailable() {
    const url = ApiEndpoint.AnnualLeave.Years();
    return this.httpClient.getObservable(url).toPromise();
  }
}
