import { DropdownDto } from './bases/dropdown.model';
import { ProjectIterationStateEnum } from './enums/project.enum';

export class ProjectIterationDto extends DropdownDto {
  fromDate: Date;
  toDate: Date;
  iterationState: ProjectIterationStateEnum;
}

export class NewProjectIterationDto {
  iterationId: string;
  name: string;
  fromDate: Date;
  toDate: Date;
  projectId: string;
}
