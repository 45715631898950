import { Component, OnDestroy, OnInit } from '@angular/core';
import { Constants, Toastr } from '@intranet/index';
import { BaseAdminComponent } from '@intranet/lib/components';
import {
  Department,
  DepartmentService,
  Employee,
  EmployeeService,
  Room,
  RoomService,
  SearchEmployee,
  StatusModel,
} from '@intranet/lib/data-access';
import { MeetingBookingRoom } from '@intranet/lib/data-access/models/meeting-booking-room';
import { MeetingRoomService } from '@intranet/lib/data-access/services/meeting-room.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent } from 'angular-calendar';
import { addMinutes, compareAsc, differenceInCalendarDays, setHours } from 'date-fns';
import { DisabledTimeFn } from 'ng-zorro-antd/date-picker';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-create-booking',
  templateUrl: './create-booking.component.html',
  styleUrls: ['./create-booking.component.scss'],
})
export class CreateBookingComponent extends BaseAdminComponent implements OnInit, OnDestroy {
  meetingRoom: MeetingBookingRoom = new MeetingBookingRoom();
  seachEmployeeSelect: SearchEmployee = new SearchEmployee(30);
  rooms: Room[] = [];
  departments: Department[] = [];
  // employess: Employee[] = [];
  timeDefaultValue = setHours(new Date(), 6);
  event: CalendarEvent;
  modal: NgbActiveModal;
  visible: boolean;
  employees: Employee[] = [];
  members: Employee[] = [];
  employee: Employee;
  isLoading: boolean;
  totalCount: number;

  occurIds = Constants.BOOKING_OCCUR_TYPE;
  repeatTypes = Constants.BOOKING_REPEAT_TYPES;

  timeAlters: StatusModel[] = [];

  submit$ = new Subject();

  constructor(
    activeModal: NgbActiveModal,
    private departmentSvc: DepartmentService,
    private meetingRoomSvc: MeetingRoomService,
    private roomSvc: RoomService,
    private employeeSvc: EmployeeService,
  ) {
    super();
    this.modal = activeModal;
    for (let index = 5; index <= 60; index += 5) {
      const element: StatusModel = {
        id: index,
        name: index + ' minus',
      };
      this.timeAlters.push(element);
    }
  }

  ngOnInit() {
    this.submit$.pipe(throttleTime(500)).subscribe(() => this.submit());

    if (this.meetingRoom) {
      this.meetingRoom.occurType = Constants.BOOKING_OCCUR_TYPE.None;
    }

    if (this.event) {
      this.meetingRoom.fromTime = this.event.start;
      this.meetingRoom.toTime = this.event.end;
      if (this.event.title !== 'New event') {
        this.meetingRoom.title = this.event.title;
      }

      if (this.event?.meta) {
        this.meetingRoom.groupId = this.event.meta.groupId;
        this.meetingRoom.roomId = this.event.meta.roomId;
        this.meetingRoom.isSendMail = this.event.meta.isSendMail;
        this.meetingRoom.id = this.event.meta.id;
        this.meetingRoom.createdBy = this.event.meta.createdBy;
        this.meetingRoom.description = this.event.meta.description;
        this.meetingRoom.occurType = this.event.meta.occurType;
        this.meetingRoom.untilDate = this.event.meta.untilDate;
        this.meetingRoom.step = this.event.meta.step;

        this.members = this.event.meta.joiners;
      }
    }
    if (this.meetingRoom.joiners) {
      this.members = this.meetingRoom.joiners;
    }

    this.meetingRoom.step = this.meetingRoom.step || 1;
    this.loadRoom();
    this.loadDepartment();
    this.loadEmployeeScroll();
  }

  ngModelChangeStart(date: Date) {
    const comparer = compareAsc(this.meetingRoom.fromTime, this.meetingRoom.toTime);
    if (comparer === 1) {
      this.meetingRoom.toTime = addMinutes(this.meetingRoom.fromTime, 30);
    }
  }
  disabledStartDate = (current: Date): boolean => {
    // Can not select days before today and today
    const now = new Date();
    return differenceInCalendarDays(current, now) < 0;
    // tslint:disable-next-line: semicolon
  };

  disabledEndDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.meetingRoom.fromTime) !== 0;
    // tslint:disable-next-line: semicolon
  };

  disabledUntilDate = (current: Date): boolean => {
    if (differenceInCalendarDays(current, this.meetingRoom.fromTime) <= 0) {
      return true;
    }
    return differenceInCalendarDays(current, this.meetingRoom.fromTime) > 365;
    // tslint:disable-next-line: semicolon
  };
  disabledDateTime: DisabledTimeFn = () => {
    const hour = this.meetingRoom.fromTime.getHours();
    // const minus = this.meetingRoom.fromTime.getMinutes();
    return {
      nzDisabledHours: () => this.range(0, hour),
      nzDisabledMinutes: () => [],
      nzDisabledSeconds: () => [],
    };
    // tslint:disable-next-line: semicolon
  };

  range(start: number, end: number, step = 1): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i += step) {
      result.push(i);
    }
    return result;
  }

  loadRoom() {
    this.roomSvc.getDropdown({}, (resp: Room[]) => {
      this.rooms = resp;
    });
  }

  loadDepartment() {
    this.departmentSvc.getDropdown({}, (resp: Department[]) => {
      this.departments = resp;
      if (this.departments && this.departmentSession) {
        this.departments = this.departments.filter(x => this.departmentSession.find(p => p.Id === x.id));
      }
    });
  }

  loadMore(): void {
    if (this.employees.length <= this.totalCount) {
      this.loadEmployeeScroll();
    }
  }

  async loadEmployeeScroll() {
    this.isLoading = true;
    this.seachEmployeeSelect.departments = '';
    const data = (await this.employeeSvc.getDropdown(this.seachEmployeeSelect)) as Employee[];
    if (data) {
      // Currently, the api load all employees, so we temporary disable these lines.
      // if (this.members) {
      //   data = data.filter(x => !this.members.some(p => p.id === x.id));
      // }
      this.isLoading = false;
      this.employees = data;
      this.totalCount = data.length ? data[0].totalCount : 0;

      this.updateMembersFromEmployees();
    }
  }

  loadEmployee(hasDept = true) {
    if (hasDept) {
      this.seachEmployeeSelect.departments = this.meetingRoom.groupId;
    }
    if (!this.seachEmployeeSelect.departments) {
      this.members = [];
      return;
    }
    // this.employeeSvc.getDropdown(this.seachEmployeeSelect, (resp: any) => {
    //   // this.employess = resp;
    //   this.members = resp;
    // });
  }

  submit() {
    this.meetingRoom.roomName = this.rooms?.find(x => x.id === this.meetingRoom.roomId)?.name;
    this.meetingRoom.groupName = this.departments?.find(x => x.id === this.meetingRoom.groupId)?.name;
    this.meetingRoom.joiners = this.members;

    if (this.meetingRoom.id) {
      this.update();
    } else {
      this.add();
    }

    this.modal.close(1);
  }

  add() {
    this.meetingRoomSvc.add(this.meetingRoom, () => {
      Toastr.success('Action successfully!');
    });
  }

  update() {
    this.meetingRoomSvc.update(this.meetingRoom, () => {
      Toastr.success('Action successfully!');
    });
  }

  private updateMembersFromEmployees() {
    if (this.members?.length > 0 && this.employees?.length > 0) {
      this.members = this.employees.filter(e => this.members.some(m => m.userId === e.userId));
    }
  }

  ngOnDestroy() {
    this.submit$.unsubscribe();
  }
}
