<div class="survey-answer">
  <modal-header title="Surveys" [activeModal]="activeModal"></modal-header>
  <modal-body>
    <form class="feed-form" (ngkSubmit)="onSubmit()" #frm="ngForm" [ngkForm]="true">
      <div nz-row *ngIf="progress != introStep && progress < survey.questions.length">
        <div nz-col nzSpan="24">
          <nz-progress [nzPercent]="progressPercent" [nzShowInfo]="false"></nz-progress>
        </div>
      </div>
      <div nz-row class="survey-intro" *ngIf="progress == introStep">
        <div nz-col nzSpan="24">
          <div class="survey-title">{{survey.name}}</div>
        </div>
        <div nz-col nzSpan="24">
          <div class="survey-sDescription" [innerHTML]="survey.shortDescription | safeHtml"></div>
        </div>
        <div nz-col nzSpan="24">
          <div class="survey-description" [innerHTML]="survey.description | safeHtml">{{survey.shortDescription}}</div>
        </div>
        <div nz-col nzSpan="24">
          <button nz-button type="button" (click)="onGo(1)" class="btn btn-primary">Start survey</button>
        </div>
        <div nz-col nzSpan="24">
          <div class="intro-image">
            <img src="assets/images/front/survey-bg.png" type="local" />
          </div>
        </div>
      </div>
      <div nz-row class="question-detail" *ngIf="progress > introStep">
        <div nz-col nzSpan="24" class="question-description">
          <div [innerHtml]="question.description"></div>
          <div nz-col nzSpan="24" *ngIf="question.required"><label class="error">(*)</label></div>
        </div>

        <div nz-col nzSpan="24" class="question-body">
          <div [ngSwitch]="question.questionTypeId">
            <div nz-row *ngSwitchCase="questionTypeIds.freetext">
              <div nz-col nzSpan="24">
                <textarea class="form-control" [(ngModel)]="question.result"
                  [ngModelOptions]="{ standalone: true }"></textarea>
              </div>
            </div>
            <div nz-row *ngSwitchCase="questionTypeIds.multiple">
              <div nz-col nzSpan="24" *ngFor="let option of question.options;" class="chk-group">
                <label *ngIf="question.questionTypeId == questionTypeIds.multiple" nz-checkbox
                  [(ngModel)]="option.isSelected" [ngModelOptions]="{ standalone: true }">
                  {{option.description}}</label>
              </div>
            </div>
            <div nz-row *ngSwitchCase="questionTypeIds.single">
              <div nz-col nzSpan="24">
                <nz-radio-group class="rdo-group" [(ngModel)]="question.userChoice"
                  [ngModelOptions]="{ standalone: true }">
                  <div *ngFor="let option of question.options" class="question-option">
                    <label nz-radio nzValue="{{option.id}}">
                      <span class="radio-label">{{option.description}}</span>
                    </label>
                  </div>
                </nz-radio-group>
              </div>
            </div>
            <div nz-row *ngSwitchCase="questionTypeIds.singleWithText">
              <div nz-col nzSpan="24">
                <nz-radio-group class="rdo-group" [(ngModel)]="question.userChoice"
                  [ngModelOptions]="{ standalone: true }">
                  <div *ngFor="let option of question.options" class="question-option">
                    <label nz-radio nzValue="{{option.id}}">
                      <span class="radio-label">{{option.description}}</span>
                    </label>
                  </div>
                </nz-radio-group>
              </div>
              <div nz-col nzSpan="24">
                <textarea class="form-control" placeholder="Why did you choice above answer"
                  [(ngModel)]="question.result" [ngModelOptions]="{ standalone: true }"></textarea>
              </div>
            </div>
            <div nz-row *ngSwitchCase="questionTypeIds.multipleWithText">
              <div nz-col nzSpan="24">
                <div nz-row *ngFor="let option of question.options;" class="chk-group">
                  <div nz-col nzSpan="24">
                    <label nz-checkbox [(ngModel)]="option.isSelected"
                      [ngModelOptions]="{ standalone: true }">{{option.description}}</label>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col nzSpan="24">
                    <textarea class="form-control" [(ngModel)]="question.result" [ngModelOptions]="{ standalone: true }"
                      placeholder="Why did you choice above answer?"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="24" class="survey-submit" *ngIf="progress >= survey.questions.length">
          <div nz-row class="survey-submit-title">
            <div nz-col nzSpan="24" class="thanks-image">
              <img src="assets/images/front/feedback-3.png" type="local" />
            </div>
            <div nz-col nzSpan="24">
              <div class="title">Thanks for your survey</div>
              <p>Thank you for improving the survey by submitting your review.</p>
            </div>
          </div>
          <div nz-row>
            <div class="survey-rating" nz-col nzSpan="24">
              <div nz-col nzSpan="10">
                <label>Your general assessment of the survey:</label>
              </div>
              <div nz-col nzSpan="14">
                <nz-rate [(ngModel)]="rate" [ngModelOptions]="{standalone: true}"></nz-rate>
              </div>
            </div>
            <div nz-col nzSpan="24">
              <label for="comment">Comment</label>
              <textarea id="comment" placeholder="Write your comment" class="form-control" [(ngModel)]="comment"
                [ngModelOptions]="{ standalone: true }"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" *ngIf="progress != introStep">
        <div nz-row>
          <div nz-col nzSpan="12">
            <button type="button" class="btn btn-white" (click)="onGo(-1)" nz-button>Back</button>
          </div>
          <div nz-col nzSpan="12">
            <button type="button" class="btn btn-primary float-right" (click)="onGo(1)"
              *ngIf="progress < survey.questions.length" nz-button>Next</button>
            <button type="submit" class="btn btn-primary float-right" *ngIf="progress >= survey.questions.length"
              nz-button>Submit</button>
          </div>
        </div>
      </div>
    </form>
  </modal-body>
</div>