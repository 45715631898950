import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { CDNService, Employee, EmployeeService, UserService } from '@intranet/lib/data-access';
import { ModalService } from '@intranet/lib/settings/services/modal.service';
import { ResignFormModalComponent } from '@intranet/lib/components';
import { ModalOption, ModalSize } from '@intranet/lib/settings/services/modal.service';
import { CommonHelper } from '@intranet/lib/helpers';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UrlConstant } from '@intranet/lib/config';

export const TARGET_COPY = {
  email: 'email',
  phoneNumber: 'phoneNumber'
};
@Component({
  selector: 'app-front-header-profile',
  templateUrl: './front-header-profile.component.html',
  styleUrls: ['./front-header-profile.component.scss']
})
export class FrontHeaderProfileComponent implements OnInit {
  @Input() currentUser: any;
  empDetails: Employee;
  empPosition: string;
  isProfile = true;
  avatarUrl = '';
  resignModalOption: ModalOption = {
    size: ModalSize.lg
  };
  isMobileView = CommonHelper.isMobileView();
  targetCopy = TARGET_COPY;
  msTeamLink = UrlConstant.MS_TEAMS_WEB_APP;

  constructor(private employeeService: EmployeeService
    , private activatedRoute: ActivatedRoute
    , private userService: UserService
    , private cdnService: CDNService
    , private modalService: ModalService
    , private clipboard: Clipboard
    , private nzMessageService: NzMessageService) {

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const employeeId = params.employeeId;
      this.empDetails = null;
      if (this.currentUser && employeeId && employeeId !== this.currentUser.employeeId) {
        this.isProfile = false;
        this.getPersonal(employeeId);
      } else {
        this.isProfile = true;
        this.getEmployeeDetails();
      }
    });
  }
  private getPersonal(id) {
    this.employeeService.getBasicEmployeeInfo(id, (emp: Employee) => {
      if (emp) {
        this.empDetails = new Employee(emp);
        this.avatarUrl = this.empDetails?.avatarUrl && this.empDetails?.avatarUrl.length > 0 ? this.cdnService.getFileUrl(this.empDetails?.avatarUrl) : null;
      }
    });
  }
  async getEmployeeDetails() {
    if (!this.empDetails) {
      this.getPersonal(this.currentUser.employeeId);
    }
  }

  openResignModal() {
    const modal = this.modalService.open(ResignFormModalComponent, this.resignModalOption);
    modal.componentInstance.currentUser = this.currentUser;
    modal.result.then((resp: any) => { });
  }

  copyToClipBoard(target: string) {
    switch (target) {
      case this.targetCopy.email:
        this.clipboard.copy(this.empDetails.email);
        this.nzMessageService.success('Copied');
        break;
      case this.targetCopy.phoneNumber:
        this.clipboard.copy(this.empDetails.phoneMobile);
        this.nzMessageService.success('Copied');
        break;
      default:
        break;
    }

  }

  @HostListener('window:resize')
  private updateMobileViewData() {
    this.isMobileView = CommonHelper.isMobileView();
  }
}
