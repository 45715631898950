
import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { BaseService } from '../base.service';
import { CreateReviewSummaryDiscuss, ReviewSummaryDiscuss, UpdateReviewSummaryDiscuss } from '../../models/review/review-summary.model';

@Injectable({
  providedIn: 'root',
})
export class ReviewSummaryDiscussService extends BaseService {
  hostUrl = '/api/review-summary-discuss';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  createComment(data: CreateReviewSummaryDiscuss, noLoading = false): Promise<ReviewSummaryDiscuss> {
    const url = this.hostUrl + (noLoading ? '?noLoading=true' : '');
    return this.httpClient.postObservable(url, data).toPromise();
  }

  updateComment(data: UpdateReviewSummaryDiscuss, noLoading = false): Promise<ReviewSummaryDiscuss> {
    const url = this.hostUrl + (noLoading ? '?noLoading=true' : '');
    return this.httpClient.putObservable(url, data).toPromise();
  }
}

