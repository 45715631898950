import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompensationHourUserService } from '@intranet/lib/data-access/services/compensation-hour-user.service';
import { SecurityHelper } from '@intranet/lib/helpers/securityHelper';
import { Toastr } from '@intranet/lib/settings/services/toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NzTimePickerComponent } from 'ng-zorro-antd/time-picker/time-picker.component';


@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss']
})
export class UserRegisterComponent implements OnInit {
  @ViewChild('startTimeComponent', { static: true }) startTimeComponent: NzTimePickerComponent;
  @ViewChild('endTimeComponent', { static: true }) endTimeComponent: NzTimePickerComponent;
  numberNine = 9;
  hoursPerDay = 24;
  minutesPerHour = 60;

  compensationHour = null;

  activeModal: NgbActiveModal;
  dateFormat = 'HH:mm';
  formGroup: FormGroup;

  get startTime() { return this.formGroup.get('startTime'); }
  get endTime() { return this.formGroup.get('endTime'); }

  constructor(modalRef: NgbActiveModal,
    private compensationHourUserService: CompensationHourUserService) {
    this.activeModal = modalRef;
  }

  ngOnInit() {
    this.startTimeComponent.nzDisabledHours = () => this.disableHoursFn();
    this.startTimeComponent.nzDisabledMinutes = (hour) => this.disableMinitesFn(hour);

    this.endTimeComponent.nzDisabledHours = () => this.disableHoursFn();
    this.endTimeComponent.nzDisabledMinutes = (hour) => this.disableMinitesFn(hour);

    this.initForm();
  }

  disableHoursFn(): number[] {
    const hoursPerDayArray = Array.from(Array(this.hoursPerDay).keys()).map(i => i++);
    const hoursDisabled = hoursPerDayArray.filter(m =>
      m < this.getHour(this.compensationHour?.startTime) ||
      m > this.getHour(this.compensationHour?.endTime)
    );
    return hoursDisabled;
  }

  disableMinitesFn(hour: number): number[] {
    const minutesPerHourArray = Array.from(Array(this.minutesPerHour).keys()).map(i => i++);

    const hourStartTime = this.getHour(this.compensationHour?.startTime);
    const minuteStartTime = this.getMinute(this.compensationHour?.startTime);

    const hourEndTime = this.getHour(this.compensationHour?.endTime);
    const minuteEndTime = this.getMinute(this.compensationHour?.endTime);

    if (hour === hourStartTime) {
      return minutesPerHourArray.filter(m => m < minuteStartTime);
    }
    if (hour === hourEndTime) {
      return minutesPerHourArray.filter(m => m > minuteEndTime);
    }
    return [];
  }

  async submit() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    try {
      const authToken = SecurityHelper.getStoreAuthen();
      if (authToken) {
        const request = {
          startTime: this.formatFullDateToHour(this.startTime.value),
          endTime: this.formatFullDateToHour(this.endTime.value),
          compensationHourId: this.compensationHour?.id
        } as any;
        const response = await this.compensationHourUserService.registerCompensationHour(authToken.id, request);
        this.activeModal.close(response);
      }
    }
    catch (res) {
      let message = 'Cannot register, please try again';
      if (res?.error?.message) {
        message = res.error.message;
      }
      Toastr.error(message);
    }
  }

  private initForm() {
    this.formGroup = new FormGroup({
      startTime: new FormControl(null, Validators.required),
      endTime: new FormControl(null, Validators.required)
    });
  }

  private formatFullDateToHour(date: any) {
    const dateFormatted = new Date(date);

    const hour = this.formatNumber(dateFormatted.getHours());
    const minute = this.formatNumber(dateFormatted.getMinutes());
    return `${hour}:${minute}`;
  }

  private formatNumber(value: number) {
    return value > this.numberNine ? value.toString() : `0${value.toString()}`;
  }

  private getHour(value: string) {
    // tslint:disable-next-line: radix
    const hour = value.split(':') ? Number.parseInt(value.split(':')[0]) : 0;
    return hour;
  }

  private getMinute(value: string) {
    // tslint:disable-next-line: radix
    const minute = value.split(':') ? Number.parseInt(value.split(':')[1]) : 0;
    return minute;
  }
}
