<modal-header [title]="'Invoice'" [activeModal]="activeModal"></modal-header>
<modal-body>
  <div class="row gutters" *ngIf="purchaseInvoice">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="card">
        <div class="card-body p-0">
          <div class="invoice-container p-t-xxs p-b-sm p-lr-sm">
            <div class="invoice-header">
              <!-- Row start -->
              <!-- <div class="row gutters">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div class="custom-actions-btns mb-5">
                    <a href="#" class="btn btn-primary">
                      <i class="icon-export"></i> Export
                    </a>
                    <a href="#" class="btn btn-dark">
                      <i class="icon-printer"></i> Print
                    </a>
                  </div>
                </div>
              </div> -->
              <!-- Row end -->

              <!-- Row start -->
              <div class="row gutters">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <a class="invoice-logo">
                    <img src="assets/images/logo.png" type="local" />
                  </a>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <address class="text-right">
                    62 Tran Huy Lieu Street.<br />
                    Phu Nhuan District, Ho Chi Minh City, VietNam.<br />
                    0973-867762
                  </address>
                </div>
              </div>
              <!-- Row end -->

              <!-- Row start -->
              <div class="row gutters">
                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-8 col-12">
                  <div class="invoice-details">
                    <address *ngIf="purchaseInvoice.groupDepartments">
                      Groups:
                      <span>
                        <nz-tag *ngFor="let tag of purchaseInvoice.groupDepartments" [nzColor]="'geekblue'">
                          <span *ngIf="tag" [innerHTML]="tag.name || tag.Name"></span>
                        </nz-tag>
                      </span><br />
                      {{ purchaseInvoice.title }}
                    </address>
                  </div>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-4 col-12">
                  <div class="invoice-details">
                    <div class="invoice-num">
                      <div>
                        <span>Date: </span>
                        <span [innerHTML]="purchaseInvoice.status | status: statusEnum.PRUCHASE_REQUEST_STATUS"></span>
                      </div>
                      <div>{{ purchaseInvoice.createdDate | ngkDate2 }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Row end -->
            </div>

            <div class="invoice-body">
              <!-- Row start -->
              <div class="row gutters">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="table-responsive" *ngIf="productLists">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Supplier</th>
                          <th>Category</th>
                          <th>Quantity</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of productLists">
                          <td>
                            {{ item.name }}
                            <p class="m-0 text-muted">
                              {{ item.reason }}
                            </p>
                          </td>
                          <td>{{ item.supplierName }}</td>
                          <td>{{ item.equipmentCategoryName }}</td>
                          <!-- <td>{{ item.buyQuantity }}</td> -->
                          <td>{{ item.quantity }}</td>
                          <td>
                            <ng-container>
                              <ng-container *ngIf="item.price > 0">{{ item.price | number: '1.0' }}</ng-container>
                            </ng-container>
                            <!-- {{item.price|number:'1.0'}} -->
                          </td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>
                            <p></p>
                            <h5 class="text-danger"><strong>Total</strong></h5>
                          </td>
                          <td>
                            <h5 class="text-danger">
                              <strong> {{ purchaseInvoice.amount | number: '1.0' }}</strong>
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- Row end -->
            </div>

            <div class="invoice-footer">
              Thank you for your Business.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</modal-body>
<modal-footer>
  <div class="flex-fill text-left">
  </div>
  <div class="flex-fill text-right">
    <button class="btn btn-white" (click)="activeModal.close(false)"><i class="far fa-times-circle mr5"></i>
      Close</button>
  </div>
</modal-footer>