import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/invoices';
  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getById(invoiceId) {
    const url = this.hostUrl + `/${invoiceId}/detail`;
    return this.httpClient.getSync(url);
  }

  getItemsById(invoiceId) {
    const url = this.hostUrl + `/${invoiceId}/lines`;
    return this.httpClient.getSync(url);
  }
}
