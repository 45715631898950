
import { HeadcountTypeEnum } from '../data-access/models/enums/headcount-type.enum';

export const EmployeeConstants = {
  HeadcountType: {
    Array: [
      { id: HeadcountTypeEnum.New, name: 'New opening', },
      { id: HeadcountTypeEnum.Replacement, name: 'Replacement' },
    ]
  },
};
