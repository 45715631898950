import { ProjectDocumentTypeEnum } from './enums/project.enum';
import { FileExtensionType } from './enums/document.enum';

export class ProjectItemDocumentDto {
  itemDocumentId: string;
  projectItemId: string;
  documentType: ProjectDocumentTypeEnum;
  documentName: string;
  description: string;
  createdBy: string;
  createdOn: Date;
  extensionType: FileExtensionType;
}

export class ProjectItemDocumentModel {
  projectItemId: string;
  uploadFile: File;
}
