import { Component, OnInit, Input, EventEmitter, OnDestroy, HostListener, AfterViewChecked, AfterViewInit } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { GridsterItem } from 'angular-gridster2';
import { DateMonthSelectModel, SingleChartDto, WidgetAttendanceDetailDto, WidgetAttendanceDto, WidgetSearchDto, WidgetSearchModeEnum, WidgetService, WidgetTrackingTimeSearchDto } from '@intranet/lib/data-access';
import { DateMonthSelectSettingModel } from '../../../data-access/models/bases/date-month-select.model';
import { ActivatedRoute } from '@angular/router';
import { WebsiteEvent } from '@intranet/lib/settings/event-store/website.event';
import { CommonService, PubSubService } from '@intranet/lib/services';
import { CommonHelper } from '@intranet/lib/helpers';
import { StringHelper } from '@intranet/lib/helpers/stringHelper';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import { Constants } from '@intranet/lib/config/constants';
import { PubSubEvent } from '@intranet/lib/config';

@Component({
  selector: 'app-widget-attendance',
  templateUrl: './widget-attendance.component.html',
  styleUrls: ['./widget-attendance.component.scss'],
})
export class WidgetAttendanceComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  dateFormat = 'dd/MM/yyyy';
  @Input() widget;
  @Input() resizeEvent: EventEmitter<GridsterItem>;
  @Input() widgetLoaded: EventEmitter<any>;
  resizeSub: Subscription;
  searchData: WidgetSearchDto = new WidgetSearchDto();
  data: WidgetAttendanceDto = new WidgetAttendanceDto();
  loading = false;
  dataSelect: any;
  dateSelect: DateMonthSelectModel = new DateMonthSelectModel();
  dateSelectSetting: DateMonthSelectSettingModel = new DateMonthSelectSettingModel();
  pieLeftPosition: number;
  legendLeftPosition: number;
  title: string;
  weeks: any;
  dataSearchSubscription: Subscription;
  isMobileView = CommonHelper.isMobileView();

  widgetContentId = 'widget-attendance-content-id';
  widgetContentEl: HTMLElement;
  widgetContentOffsetWidth: number;
  widgetDisplayData: SingleChartDto<WidgetAttendanceDetailDto>;
  widgetSearchMode: string = 'month';
  widgetSearchModeEnum = WidgetSearchModeEnum;

  userOptionFilters: any[] = [];
  userDateFormat: string;
  userDisableDate = (current: Date) => {
    return current.valueOf() > Date.now();
  }

  constructor(private _widgetService: WidgetService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private websiteEvent: WebsiteEvent,
    private pubsub: PubSubService) {

    this.loadDefaultDate();
    this.dateSelectSetting.className = 'widget-attendance-date';
  }

  ngOnDestroy(): void {
    if (this.resizeSub) {
      this.resizeSub.unsubscribe();
    }
    if (this.dataSearchSubscription) {
      this.dataSearchSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.subscribeDataSearch();
    this.userSearchModeChange();

    this.resizeSub =
      this.resizeEvent &&
      this.resizeEvent.subscribe(widget => {
        if (widget === this.widget) {
          setTimeout(() => {
            this.getPosition();
          }, 1000);
        }
      });
    setTimeout(() => {
      this.getPosition();
    }, 1000);

  }

  ngAfterViewInit() {
    CommonHelper.scheduleToExecute(() => this.resizeWindowHandler());
  }

  ngAfterViewChecked() {
    if (!this.widgetContentEl) {
      this.widgetContentEl = document.getElementById(this.widgetContentId);
      CommonHelper.scheduleToExecute(() => this.resizeWindowHandler());
    }
  }

  subscribeDataSearch() {
    this.dataSearchSubscription = combineLatest([
      this.activatedRoute.queryParams,
      this.websiteEvent.groupSourceDataEvent
    ])
      .subscribe(([queryParams, departmentId]) => {
        this.searchData.employeeId = queryParams.employeeId;
        if (!this.searchData.departmentIds || departmentId === '') {
          this.searchData.departmentIds = departmentId !== '' ? departmentId as any : null;
          this.loadData();
        }
      });
  }

  onResizePie(event) {
    this.getPosition();
  }

  getPosition() {
    this.legendLeftPosition = 350;
    this.pieLeftPosition = 80;
  }

  searchUser(value: string) {
    if (value === '') {
      this.userOptionFilters = this.data?.users || [];
      return;
    }
    const fullNameConvert = StringHelper.removeSensitiveWords(value).toUpperCase();
    this.userOptionFilters = this.data?.users.filter(u => u?.enFullName?.includes(fullNameConvert));
  }

  async loadData() {
    if (this.widget) {
      this.searchData.isDisplayDataManage = this.widget.data.isDisplayDataManage;
    }
    this.loading = true;
    try {
      this.data = await this._widgetService.getWidgetAttendance(this.searchData);

      this.data?.users?.map(u => {
        u.enFullName = StringHelper.removeSensitiveWords(u?.fullName ?? '').toUpperCase();
      });

      this.userOptionFilters = [...this.data?.users ?? []];

      this.backupWidgetDisplayData();
      this.updateMobileViewData();

      this.widgetLoaded?.emit(this.widget);
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
    finally {
      this.loading = false;
    }
  }

  modelDepartmentChanged(event) {
    this.searchData.departmentIds = event;
    this.loadData();

    if (event !== null) {
      this.websiteEvent.setGroupSourceDataEvent(event);
    }
    this.searchData.userIds = null;
  }

  modelChanged(event) {
    this.searchData.userIds = event;
    const search = Object.assign({}, this.searchData);
    setTimeout(() => {
      this.searchData = search;
    }, 0);
    this.loadData();
  }

  modelMissingHoursChanged(event) {
    this.searchData.isMissingHours = event;
    const search = Object.assign({}, this.searchData);
    setTimeout(() => {
      this.searchData = search;
    }, 0);
  }

  loadDefaultDate() {
    const date = new Date();
    const fromDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
    this.searchData.fromDate = fromDate.getFullYear() + '-'
      + this.commonService.pad((fromDate.getMonth() + 1), 2) + '-'
      + this.commonService.pad(fromDate.getDate(), 2) + ' 00:00:00';
    this.searchData.toDate = date.getFullYear() + '-'
      + this.commonService.pad((date.getMonth() + 1), 2) + '-'
      + this.commonService.pad((date.getDate() === 1 ? 1 : (date.getDate() - 1)), 2) + ' 23:59:59';
  }

  onChangeFromDate(date) {
    this.searchData.fromDate = date.getFullYear() + '-'
      + this.commonService.pad((date.getMonth() + 1), 2) + '-'
      + this.commonService.pad(date.getDate(), 2) + ' 00:00:00';
    this.loadData();
    const search = Object.assign({}, this.searchData);
    setTimeout(() => {
      this.searchData = search;
    }, 0);
  }

  onChangeToDate(date) {
    this.searchData.toDate = date.getFullYear() + '-' + this.commonService.pad((date.getMonth() + 1), 2) + '-' + this.commonService.pad(date.getDate(), 2) + ' 23:59:59';
    this.loadData();
    const search = Object.assign({}, this.searchData);
    setTimeout(() => {
      this.searchData = search;
    }, 0);
  }

  modelWeekChanged(event) {
    const search = Object.assign({}, this.searchData);
    search.thisWeek = event;
    setTimeout(() => {
      this.searchData = search;
    }, 0);
  }

  weekChange(event) {
    this.weeks = event;
  }

  @HostListener('window:resize')
  resizeWindowHandler() {
    this.isMobileView = CommonHelper.isMobileView();

    CommonHelper.scheduleToExecute(() => {
      this.widgetContentEl = document.getElementById(this.widgetContentId);
      if (this.widgetContentEl) {
        this.widgetContentOffsetWidth = this.widgetContentEl.offsetWidth;
      }

      this.updateMobileViewData();
    });
  }

  updateMobileViewData() {
    if (!this.isMobileView || !this.data) {
      this.restoreWidgetDisplayData();
      return;
    }

    if (!this.widgetContentEl) {
      this.widgetContentEl = document.getElementById(this.widgetContentId);
      CommonHelper.scheduleToExecute(() => this.resizeWindowHandler());
      return;
    }

    if (this.data?.widgetAttendanceDetails) {
      const viewX = this.widgetContentOffsetWidth;
      const viewY = this.widgetDisplayData?.view[1];

      this.data.widgetAttendanceDetails.view = [viewX, viewY];
      this.data.widgetAttendanceDetails.legendPosition = 'bottom';
    }
  }

  backupWidgetDisplayData() {
    if (!this.data?.widgetAttendanceDetails) {
      return;
    }

    this.widgetDisplayData = { ...this.data.widgetAttendanceDetails };
  }

  restoreWidgetDisplayData() {
    if (!this.widgetDisplayData || !this.data?.widgetAttendanceDetails) {
      return;
    }

    this.data.widgetAttendanceDetails = { ...this.widgetDisplayData };
  }

  userSearchModeChange() {
    const searchMode = Constants.WidgetSearchModeDefault;
    const formatData = Constants.WidgetSearchModeFormat[this.searchData.searchMode ?? searchMode];

    if (formatData) {
      this.widgetSearchMode = formatData.mode;
      this.userDateFormat = formatData.format;
    }
  }

  pieChartSelect(selection: any) {
    const name = selection?.name || selection;
    const selectedLabel = this.data?.widgetAttendanceDetails?.chartDatas?.find(d => d.name === name)?.label;

    if (!selectedLabel) {
      return;
    }

    const time = new Date(this.searchData.fromDate);
    const curMonth = time.getMonth() + 1;
    const curYear = time.getFullYear();
    const searchData = {
      searchMode: WidgetSearchModeEnum.Month,
      searchMissingHours: false,
      month: curMonth,
      year: curYear,
    } as WidgetTrackingTimeSearchDto;

    if (StringHelper.compareCaseInsesitive(selectedLabel, Constants.WidgetAttendanceChartLabels.InTime)) {
      this.pubsub.$pub(PubSubEvent.EVENTS.ON_SELECT_MISSING_HOURS, searchData);
    }
    else if (StringHelper.compareCaseInsesitive(selectedLabel, Constants.WidgetAttendanceChartLabels.MissingHours)) {
      searchData.searchMissingHours = true;
      this.pubsub.$pub(PubSubEvent.EVENTS.ON_SELECT_MISSING_HOURS, searchData);
    }
  }
}
