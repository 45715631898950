import { Component, Input, EventEmitter, Output } from '@angular/core';
import { NewsFeed } from '@intranet/lib/data-access';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss'],
  styles: [],
})
export class NewsFeedComponent {
  @Input() feeds: NewsFeed[] = [];
  @Input() disabledLoading: boolean;
  @Input() isLoading: boolean;
  @Input() disableEmpty: boolean;
  @Output() loadMore: EventEmitter<any> = new EventEmitter();

  constructor() { }

  onLoadMore() {
    this.loadMore.emit();
  }
}
