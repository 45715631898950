<p class="title text-center">Welcome</p>
<p class="sub-title text-center">Sign in to continue to Restaff</p>
<form [ngkForm]="true" (ngkSubmit)="frm.form.valid && login()" #frm="ngForm" novalidate>
  
  <div class="form-group">
    <label for="username">Email / Username</label>
    <input type="username" [(ngModel)]="loginModel.UserName" name="Username" class="form-control" id="username" required placeholder="Enter email / Username">
  </div>
  
  <div class="form-group">
    <label for="password">Password</label>
    <input type="password" [(ngModel)]="loginModel.Password" name="Password" class="form-control" id="password" required placeholder="Enter password">
  </div>
  
  <button type="submit" class="btn btn-primary btn-block">Login</button>
  
  <a class="btn btn-block" (click)="forgotPassword()">Forgot password</a>
  
</form>
