import { BenefitCriteriaOperatorConstEnum, BenefitCriteriaOperatorEnum } from '../data-access/models/enums/benefit-criteria-operator.enum';
import { BenefitCriteriaTypeConstEnum, BenefitCriteriaTypeEnum } from '../data-access/models/enums/benefit-criteria-type.enum';
import { BenefitDataTypeConstEnum, BenefitDataTypeEnum } from '../data-access/models/enums/benefit-data-type.enum';
import { BenefitRegistrationConstEnum, BenefitRegistrationTypeEnum } from '../data-access/models/enums/benefit-registration-type.enum';
import { BenefitRequestStatusEnum } from '../data-access/models/enums/benefit-request-status.enum';

export const BenefitConstants = {
  BenefitCriteriaType: {
    Display: {
      [BenefitCriteriaTypeEnum.YearOfSeniority]: BenefitCriteriaTypeConstEnum.YearOfSeniority,
      [BenefitCriteriaTypeEnum.Position]: BenefitCriteriaTypeConstEnum.Position,
      [BenefitCriteriaTypeEnum.Gender]: BenefitCriteriaTypeConstEnum.Gender,
      [BenefitCriteriaTypeEnum.Child]: BenefitCriteriaTypeConstEnum.Child,
    },
    Array: [
      { id: BenefitCriteriaTypeEnum.YearOfSeniority, name: BenefitCriteriaTypeConstEnum.YearOfSeniority },
      { id: BenefitCriteriaTypeEnum.Position, name: BenefitCriteriaTypeConstEnum.Position },
      { id: BenefitCriteriaTypeEnum.Gender, name: BenefitCriteriaTypeConstEnum.Gender },
      { id: BenefitCriteriaTypeEnum.Child, name: BenefitCriteriaTypeConstEnum.Child }
    ]
  },
  BenefitOperatorType: {
    Display: {
      [BenefitCriteriaOperatorEnum.Equal]: BenefitCriteriaOperatorConstEnum.Equal,
      [BenefitCriteriaOperatorEnum.NotEqual]: BenefitCriteriaOperatorConstEnum.NotEqual,
      [BenefitCriteriaOperatorEnum.GreaterThan]: BenefitCriteriaOperatorConstEnum.GreaterThan,
      [BenefitCriteriaOperatorEnum.LessThan]: BenefitCriteriaOperatorConstEnum.LessThan,
      [BenefitCriteriaOperatorEnum.GreaterThanEqual]: BenefitCriteriaOperatorConstEnum.GreaterThanEqual,
      [BenefitCriteriaOperatorEnum.LessThanOrEqual]: BenefitCriteriaOperatorConstEnum.LessThanOrEqual,
    },
    Array: [
      { id: BenefitCriteriaOperatorEnum.Equal, name: BenefitCriteriaOperatorConstEnum.Equal },
      { id: BenefitCriteriaOperatorEnum.NotEqual, name: BenefitCriteriaOperatorConstEnum.NotEqual },
      { id: BenefitCriteriaOperatorEnum.GreaterThan, name: BenefitCriteriaOperatorConstEnum.GreaterThan },
      { id: BenefitCriteriaOperatorEnum.LessThan, name: BenefitCriteriaOperatorConstEnum.LessThan },
      { id: BenefitCriteriaOperatorEnum.GreaterThanEqual, name: BenefitCriteriaOperatorConstEnum.GreaterThanEqual },
      { id: BenefitCriteriaOperatorEnum.LessThanOrEqual, name: BenefitCriteriaOperatorConstEnum.LessThanOrEqual },
    ]
  },
  BenefitDataType: {
    Array: [
      { id: BenefitDataTypeEnum.Number, title: BenefitDataTypeConstEnum.Number },
      { id: BenefitDataTypeEnum.String, title: BenefitDataTypeConstEnum.String },
    ]
  },
  BenefitRegistrationType: {
    Display: {
      [BenefitRegistrationTypeEnum.None]: BenefitRegistrationConstEnum.NotAllow,
      [BenefitRegistrationTypeEnum.Single]: BenefitRegistrationConstEnum.SingleRegister,
      [BenefitRegistrationTypeEnum.Multiple]: BenefitRegistrationConstEnum.MultipleRegister,
    },
    Array: [
      { id: BenefitRegistrationTypeEnum.None, name: BenefitRegistrationConstEnum.NotAllow },
      { id: BenefitRegistrationTypeEnum.Single, name: BenefitRegistrationConstEnum.SingleRegister },
      { id: BenefitRegistrationTypeEnum.Multiple, name: BenefitRegistrationConstEnum.MultipleRegister }
    ]
  },
  DefaultColor: 'gray',
  RegistrationStatus: {
    Display: {
      [BenefitRequestStatusEnum.New]: { name: 'New', colorClass: 'text-info' },
      [BenefitRequestStatusEnum.Approved]: { name: 'Approved', colorClass: 'text-green-success' },
      [BenefitRequestStatusEnum.Rejected]: { name: 'Rejected', colorClass: 'text-danger' },
    },
    Array: [
      { id: BenefitRequestStatusEnum.New, name: 'New' },
      { id: BenefitRequestStatusEnum.Approved, name: 'Approved' },
      { id: BenefitRequestStatusEnum.Rejected, name: 'Rejected' }
    ]
  }
};
