import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { GetReviewFormMember, UserInformationAssociateReviewSummarily } from '@intranet/lib/data-access/models/review/review-form-member.model';
import { SecurityHelper } from '@intranet/lib/helpers';
@Component({
  selector: 'app-review-form-member-result',
  templateUrl: './review-form-member-result.component.html',
  styleUrls: ['./review-form-member-result.component.scss']
})
export class ReviewFormMemberResultComponent implements OnChanges, OnInit {
  @Input() formMember: GetReviewFormMember = null;
  @Input() errorText = null;

  userId = null;

  userInformations: { [userId: string]: any } = {};
  formPersonInformation: { [formPersonId: string]: UserInformationAssociateReviewSummarily } = {};

  constructor() { }

  ngOnInit() {
    const auth = SecurityHelper.getStoreAuthen();
    if (auth) {
      this.userId = auth?.id;
    }
  }

  ngOnChanges() {
    if (this.formMember?.joinedPersons) {
      this.formMember?.joinedPersons.forEach(joinedPerson => {
        this.userInformations[joinedPerson.userId] = {
          ...joinedPerson
        };
      });
      this.formMember?.joinedPersons.forEach(joinedPerson => {
        this.formPersonInformation[joinedPerson?.reviewFormPersonId] = Object.assign({}, joinedPerson);
      });
    }
  }
}
