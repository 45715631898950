import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { RouterHelperService } from '@intranet/lib/helpers';
import { SecurityHelper } from 'projects/intranet/hrm-lib';
import { Constants } from '../config';
@Injectable({
  providedIn: 'root',
})
export class AuthenCheckerAdminService {
  constructor(private router: Router, private activeRouter: ActivatedRoute, private routerHelperService: RouterHelperService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Constants.previousUrl = route;
    const user = route.data;
    if (user) {
      const url: string = this.getStateUrl(route, state.url);
      return this.checkPermission(url, user);
    }
    return this.canActivate(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Constants.previousUrl = route;

    return true; // spike_temporary

    const user = route.data;
    if (user) {
      const url: string = this.getStateUrl(route, state.url);
      return this.checkPermission(url, user);
    }
    return this.canActivate(route, state);
  }
  checkIfValidUUID(str) {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(str);
  }
  getStateUrl(route: ActivatedRouteSnapshot, url: string) {
    if (url) {
      const urls = url.split('?');
      const urlReturn = urls[0];
      const urlData = urlReturn.split('/');
      let result = urlData[0];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 1; i < urlData.length; i++) {
        if (!this.checkIfValidUUID(urlData[i])) {
          result += '/' + urlData[i];
        }
      }
      return result;
    } else {
      return window.location.pathname;
    }
  }

  canLoad(route: Route) {
    const url = `/${route.path}`;

    return this.checkPermission(url);
  }

  async checkPermission(url?: string, data?: any) {
    Constants.previousUrl = url ? url : '/';
    const authToken = SecurityHelper.getStoreAuthen();
    if (authToken && authToken.token) {
      if (!authToken.isAdminAccess) {
        return this.router.navigate(['/not-permission']);
      }
      // check roles
      if (data && data.isPermission) {
        const checker = SecurityHelper.checkPermissionUrl(url);
        if (checker) {
          return true;
        } else {
          return this.router.navigate(['/not-permission']);
        }
      }
      return true;
    } else {
      SecurityHelper.destroyAuthen();
      this.routerHelperService.redirectToLogin();
    }
  }
}
