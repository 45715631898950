import { Component, OnDestroy, OnInit } from '@angular/core';
import { COLUMN_TYPE, UserModel } from '@intranet/lib/data-access';
import { CommonHelper } from '@intranet/lib/helpers';
import { ColumnMode } from '@swimlane/ngx-datatable';
import {
  AppInjector,
  Constants,
  DateHelper,
  SecurityHelper,
  UrlConstant,
  WebHelper,
  WebsiteEvent,
} from 'projects/intranet/hrm-lib';

@Component({
  selector: 'base-admin-component',
  template: ` NO UI TO BE FOUND HERE! `,
})
export class BaseAdminComponent implements OnInit, OnDestroy {
  urlConstant = UrlConstant;
  statusEnum = Constants.STATUS_ENUMS;
  COLUMN_TYPE = COLUMN_TYPE;
  avatarUrl: any;
  companyId: string;
  currentUser: UserModel;
  userId: string;
  currentAuthenCache: any;
  ColumnMode = ColumnMode;
  isLogin: boolean;
  isLoading: boolean;
  currentHeight: number;
  changeHeightSub: any;
  bodyHeight: number;
  fullName: string;
  currentDate = DateHelper.currentDate;
  dateFormat = 'dd/MM/yyyy';
  cutName: string;
  departmentSession: any[];
  coverImageUrl = 'assets/images/resources/timeline-1.jpg';
  private websiteEventBase: WebsiteEvent;
  constructor() {
    this.websiteEventBase = AppInjector.getService<WebsiteEvent>(WebsiteEvent);
    this.getUserInfo();
  }

  detectHeight(minus = 0) {
    if (this.websiteEventBase) {
      this.changeHeightSub = this.websiteEventBase.eventChangeHeight.subscribe(res => {
        this.bodyHeight = res - minus;
      });
    }
    this.bodyHeight = this.websiteEventBase.currentHeight - minus;
  }

  ngOnInit() {}
  getUserInfo() {
    const authToken = SecurityHelper.getStoreAuthen();
    if (authToken) {
      this.currentUser = authToken;
      this.userId = authToken.id;
      this.fullName = this.currentUser.fullName || this.currentUser.userName;
      this.isLogin = true;
      this.avatarUrl = this.currentUser ? this.currentUser.avatarUrl : undefined;
      this.coverImageUrl = this.currentUser ? this.currentUser.coverImageUrl : undefined;
      this.departmentSession = authToken.departments ? JSON.parse(authToken.departments) : undefined;
      this.cutName = CommonHelper.firstCharToUpper(this.fullName);
    } else {
      this.isLogin = false;
    }
  }
  ngOnDestroy(): void {
    WebHelper.unSubscribe(this.changeHeightSub);
    Constants.countRequest = 0;
  }
  updateAvatar(avatarUrl: string, coverImageUrl?: string) {
    const authToken = SecurityHelper.getStoreAuthen();
    if (authToken) {
      if (avatarUrl) {
        authToken.avatarUrl = avatarUrl;
      }
      if (coverImageUrl) {
        authToken.coverImageUrl = coverImageUrl;
      }
      SecurityHelper.createStoreAuthen(authToken);
    }
  }
}
