<div class="poll-detail">
    <modal-header [title]="headerTemplate" [activeModal]="activeModal">
      <ng-template #headerTemplate>
        <button type="button" *ngIf="showRewardConfiguration" (click)="showRewardConfiguration = false" aria-label="Back" class="modal-back ng-star-inserted">
          <span><img src="assets/images/icons/arrow-left.svg" type="local" crossorigin="anonymous"
              style="display: initial;">
          </span>
        </button>
        <h4 class="modal-title">{{ 'Poll result' }}</h4>
        <!-- <h4 *ngIf="questionId && !optionId" class="modal-title">
          <span *ngFor="let question of questions;">{{ question.content }}</span>
        </h4> -->
        <!-- <h4 *ngIf="questionId && optionId" class="modal-title">
          <span>{{questionName}}</span> - <span>{{ optionName }}</span>
        </h4> -->
      </ng-template>
    </modal-header>
    <modal-body>
      <div class="form-group row">
  
        <div class="col-12" *ngIf="!showRewardConfiguration">
          <div *ngFor="let question of questions; index as questionIndex;" class="question-container">
            <div class="mb-2 font-13 font-weight-bold">
              {{question.content}}
            </div>
            <nz-table #basicTable [nzData]="question.users" [nzFrontPagination]="false">
              <thead>
                <tr>
                  <th width="30%">Users</th>
                  <th>Answer</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of basicTable.data">
                  <td>{{data.fullName}}</td>
                  <td>
                    <span *ngFor="let title of data.response.items" class="question-response">
                      <span *ngIf="title" [innerHTML]="title"></span>
                    </span>
                    <div *ngIf="data.response.note">
                      {{data.response.note}}
                    </div>
                  </td>
                  <td>
                    {{data.response.createdDate | date : 'dd/MM/yyyy HH:mm:ss'}}
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
        <div class="col-12" *ngIf="showRewardConfiguration">
          <div *ngFor="let question of questions; index as questionIndex;" class="question-container">
            <div class="mb-2 font-13 font-weight-bold">
              {{question.content}}
            </div>
            <div class="option">
              <div *ngIf="question.isMultipleChoice">
                <div *ngFor="let option of question.options" class="poll-question-option">
                  <div class="poll-question-option-container">
                    <div class="poll-question-option-content">
                      <div nz-row>
                        <div nz-col nzSpan="22">
                          <label nz-checkbox [nzDisabled]="'true'">{{ option.title }}</label>
                        </div>
                        <div nz-col nzSpan="1" *hasPermission="['wallnewsfeed', 'updatepollpoint']">
                          <span *ngIf="option.rewardPoint">+{{option.rewardPoint}}</span>
                        </div>
                        <div nz-col nzSpan="1" *hasPermission="['wallnewsfeed', 'updatepollpoint']">
                          <label nz-checkbox [(ngModel)]="option.hasRewardPoint" (ngModelChange)="checkAllUsers(option)" [nzIndeterminate]="getIndeterminate(option)"
                            [nzDisabled]="!option.users||!option.users.length"></label>
                        </div>
                      </div>
                    </div>
                    <div class="poll-question-option-users">
                      <div nz-row *ngFor="let user of option.users">
                        <div nz-col nzSpan="23">{{user.fullName}}</div>
                        <div nz-col nzSpan="1" *hasPermission="['wallnewsfeed', 'updatepollpoint']">
                          <label nz-checkbox [(ngModel)]="user.hasRewardPoint" [nzDisabled]="user.rewardedPoint" (ngModelChange)="checkUser(option)"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!question.isMultipleChoice">
                <div *ngFor="let option of question.options" class="poll-question-option">
                  <div class="poll-question-option-container">
                    <div class="poll-question-option-content">
                      <div nz-row>
                        <div nz-col nzSpan="22">
                          <label nz-radio [nzDisabled]="'true'" nzValue="{{ option.id }}">
                            <span class="radio-label">{{ option.title }}</span>
                          </label>
                        </div>
                        <div nz-col nzSpan="1" *hasPermission="['wallnewsfeed', 'updatepollpoint']">
                          <span *ngIf="option.rewardPoint">+{{option.rewardPoint}}</span>
                        </div>
                        <div nz-col nzSpan="1" *hasPermission="['wallnewsfeed', 'updatepollpoint']">
                          <label nz-checkbox [(ngModel)]="option.hasRewardPoint" (ngModelChange)="checkAllUsers(option)" [nzIndeterminate]="getIndeterminate(option)"
                            [nzDisabled]="!option.users||!option.users.length"></label>
                        </div>
                      </div>
                    </div>
                    <div class="poll-question-option-users">
                      <div nz-row *ngFor="let user of option.users">
                        <div nz-col nzSpan="23">{{user.fullName}}</div>
                        <div nz-col nzSpan="1" *hasPermission="['wallnewsfeed', 'updatepollpoint']">
                          <label nz-checkbox [(ngModel)]="user.hasRewardPoint" [nzDisabled]="user.rewardedPoint" (ngModelChange)="checkUser(option)"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div nz-row class="float-right" *hasPermission="['wallnewsfeed', 'updatepollpoint']">
            <div nz-col>
              <button class="btn btn-primary" (click)="onSubmitReward()">Issue point</button>
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="!showRewardConfiguration">
          <div *hasPermission="['wallnewsfeed', 'updatepollpoint']">
            <button class="btn btn-primary btn-configuration w-100" (click)="onShowRewardPoint()" *ngIf="poll.enableRewardPoint">Reward point configuration</button>
          </div>
  
        </div>
      </div>
    </modal-body>
  </div>
  