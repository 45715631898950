import { Injectable } from '@angular/core';
import { Toastr } from '../settings/services/toastr';

@Injectable({
  providedIn: 'root',
})

export class ErrorHelper {
  static displayMessage(res: any) {
    const message = res?.error?.message || res?.statusText || res?.message;
    if (message && message.toLowerCase() !== 'ok') {
      Toastr.error(message);
    }
  }

  static async handlePromiseError<T>(promise: Promise<T>): Promise<T | null> {
    try {
      const result = await promise;
      return result;
    } catch (err) {
      this.displayMessage(err);
      return null;
    }
  }
}
