import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReviewViewTokenService } from '@intranet/lib/data-access/services/review/review-view-token.service';

@Component({
  selector: 'app-review-form-member-client',
  templateUrl: './review-form-member-client.component.html',
  styleUrls: ['./review-form-member-client.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReviewFormMemberClientComponent implements OnInit {
  reviewFormMember = null;
  errorText = null;

  constructor(private reviewViewTokenService: ReviewViewTokenService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      const tokenId = param?.id;
      if (tokenId) {
        this.getFormMemberByTokenId(tokenId);
      }
    });
  }

  private getFormMemberByTokenId(tokenId: string) {
    this.reviewViewTokenService.getFormMemberByToken(tokenId).subscribe(reviewFormMember => {
      this.reviewFormMember = Object.assign({}, reviewFormMember);
    }, err => {
      this.errorText = err?.error?.message || err?.statusText || err?.message;
    });
  }
}
