import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/index';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { EncryptHelper } from '@intranet/lib/helpers/encryptHelper';

@Injectable({
  providedIn: 'root',
})
export class ChristmasGameService {
  constructor(private httpClient: HttpClientService) { }

  getOrnaments(options: any = {}) {
    const url = ApiEndpoint.ChristmasGame.getOrnaments();
    return this.httpClient.getObservable(url, options, false).toPromise();
  }

  redeemOrnament(ornamentItem: any) {
    const url = ApiEndpoint.ChristmasGame.redeemOrnament(ornamentItem.id);
    const encryptedKey = this.generateSecretKey(ornamentItem);
    return this.httpClient.postObservable(url, ornamentItem, false, { AdditionalInfo: encryptedKey }).toPromise();
  }

  private generateSecretKey(ornament: any) {
    const key = AppConfig.settings.christmasGameKey;
    const secretKey = ornament?.id + key + ornament?.clickCount;
    const encrypted = EncryptHelper.hashData(secretKey);

    return encrypted;
  }
}
