import { Component, Input, OnInit } from '@angular/core';
import { PubSubEvent } from '@intranet/lib/config';
import { GroupUserWorkTimeDto, WidgetSearchModeEnum, WidgetTrackingTimeSearchDto } from '@intranet/lib/data-access';
import { PubSubService } from '@intranet/lib/services';

@Component({
  selector: 'app-widget-attendance-personal',
  templateUrl: './widget-attendance-personal.component.html',
  styleUrls: ['./widget-attendance-personal.component.scss']
})
export class WidgetAttendancePersonalComponent implements OnInit {
  @Input() dataMember: GroupUserWorkTimeDto;

  constructor(
    private pubsub: PubSubService,
  ) { }

  ngOnInit() { }

  searchMonth(month: number, year: number): void {
    this.publish({
      searchMode: WidgetSearchModeEnum.Month,
      searchMissingHours: true,
      month,
      year,
    } as WidgetTrackingTimeSearchDto);
  }

  searchYear() {
    const year = this.dataMember?.year || new Date().getFullYear();
    this.publish({
      searchMode: WidgetSearchModeEnum.Year,
      searchMissingHours: true,
      year,
    } as WidgetTrackingTimeSearchDto);
  }

  publish(data: WidgetTrackingTimeSearchDto) {
    this.pubsub.$pub(PubSubEvent.EVENTS.ON_SELECT_MISSING_HOURS, data);
  }
}
