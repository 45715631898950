
export enum RedemptionRequestStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
}

export enum RedemptionUpdateModalMode {
  ViewDetail,
  Approve,
  Reject,
}
