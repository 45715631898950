import { ClaimType, UserRewardTypeEnum } from './enums/user-reward-type.enum';
export class Gift {
  id: string;
  name: string;
  description: string;
  points: number;
  link: string;
  value: number;
  images?: string[];
  imageLocations?: string[];
  totalCount?: number;
}

export class RedemptionRequest {
  id: string;
  giftId: string;
  giftName: string;
  point: number;
  createdDate: Date;
  userId: string;
  approvedBy?: string;
  approvedName?: string;
  approvedDate?: Date;
  description?: string;
  fullName: string;
  requestStatus: number;
  totalCount?: number;
  isDeleted?: boolean;
  value?: number;
  note: string;
  userPoint?: number;
}

export class SearchRequest {
  keyword?: string;
  startDate?: Date;
  endDate?: Date;
  isPending?: boolean;
  isApproved?: boolean;
  isRejected?: boolean;
  isDeleted?: boolean;
  pageNumber?: number;
  pageSize?: number;
}

export class SearchDateRangePaging {
  startDate?: Date;
  endDate?: Date;
  pageNumber?: number;
  pageSize?: number;
}

export class SearchGift {
  keyword?: string;
  pointMin?: any;
  pointMax?: any;
  pageNumber?: number;
  pageSize?: number;
}

export class RewardHistory {
  id: string;
  userId: string;
  claimType: ClaimType;
  rewardType: UserRewardTypeEnum;

}
