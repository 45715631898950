import { Injectable } from '@angular/core';
import { HttpClientService, Loading } from '@intranet/index';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { Observable } from 'rxjs';
import { EquipmentCategory } from '../models';
import { PagingResponse } from '../models/api-response.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class EquipmentCategoryService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/equipment_category';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getTree(params?: any, successCallback?: (params?: any) => void) {
    Loading.show();
    const url = 'get-tree';
    return this.getByUrl(url, params, successCallback);
  }

  getPagingList(requestOption: any): Observable<PagingResponse<EquipmentCategory>> {
    return this.httpClient.getObservable(ApiEndpoint.EquipmentCategory.PagingList(), requestOption);
  }
}
