
export enum AnnualLeaveRequestTypeEnum {
  AnnualLeave = 0,
  UnpaidLeave = 1,
}

export enum AnnualLeaveRequestStatusEnum {
  Approved = 1,
  Restored = 2,
}
