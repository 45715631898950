import { NgModule } from '@angular/core';
import { PackageShareModule } from 'projects/intranet/hrm-lib/lib/share';
import { CommonModule } from '@angular/common';
import { EmpCreateGroupUserComponent, EmpEditGroupUserComponent, EditAccountComponent, CreateUpdateUserComponent } from './pages/administrators';
import {
  NewEmployeeModalComponent,
  PurchaseInvoiceComponent,
  NewProjectIterationModalComponent,
  PurchaseDetailComponent,
  NewProjectModalComponent,
  NewProjectItemModalComponent,
  PreviewImageModalComponent,
  ChangePasswordComponent,
  PurchaseDetailForUserComponent,
  ConfirmDetailModalComponent,
  NewcomerTemplateModalComponent,
} from './components';
import { LeaveTypeModalComponent, ApprovalModalComponent, LeaveRequestModalComponent, LeaveDetailComponent } from './pages/leaves';
import { ColorPickerModule } from 'ngx-color-picker';
import { CreateMachineComponent, SelectMachineSyncComponent } from './pages/timekeeper';
import { CreateWarehouseComponent, CreateDeliveryFormComponent } from './pages/warehouse';
import { CreateTimekeeperComponent, CreateContractComponent, EmployeeWorkingTimeComponent } from './pages/employee';
import {
  NewEquipmentCategoryModalComponent,
  NewSupplierEquipmentModalComponent,
  CreateSupplierComponent,
  CreateProductComponent,
  CreateUpdatePackageComponent,
} from './pages/supplier';
import { NewSkillModalComponent, NewPositionModalComponent, NewDeptModalComponent, CreateRoomComponent } from './pages/settings';
import { PurchaseFillpriceComponent, CheckInventoryComponent, BoughtModalComponent, BoughtConfirmComponent, PurchaseOrderDetailComponent } from './pages/purchase';
import { NewCommentModalComponent } from './components/new-comment-modal/new-comment-modal.component';
import { NewDashboardModalComponent } from './components/new-dashboard-modal/new-dashboard-modal.component';
import { NewProjectWidgetComponent } from './components/new-project-widget/new-project-widget.component';
import { PopupDeptUserComponent } from './pages/settings/department/popup-dept-user/popup-dept-user.component';
import { PopupAddUserComponent } from './pages/settings/department/popup-add-user/popup-add-user.component';
import { ReasonModalComponent } from './components/reason-modal/reason-modal.component';
import { TemplateEmailModalComponent } from './pages/template-email/template-email-list/template-email-modal/template-email-modal.component';
import { WidgetSetupModalComponent } from './pages/widget-setup/widget-setup-list/widget-setup-modal/widget-setup-modal.component';
import { DeliveryDetailComponent } from './pages/warehouse/delivery-form/delivery-detail/delivery-detail.component';
import { PolicyDetailModalComponent, PolicyModalComponent } from './pages/policys';

import { TicketTypeModalComponent, TicketModalComponent, } from './pages/ticket';
import { UpdateStatusModalComponent } from './pages/employee/resignation/update-status-modal/update-status-modal.component';
import { InfoWorkTimeModalComponent } from './pages/timekeeper/info-work-time-modal/info-work-time-modal.component';
import { TemplateHtmlModalComponent } from './pages/settings/template-html/template-html-modal/template-html-modal.component';
import { ViewHistoryModalComponent } from './pages/employee/resignation/view-history-modal/view-history-modal.component';
import { UserTierModalComponent } from './pages/settings/user-tier/user-tier-modal/user-tier-modal.component';
import { GiftUpdateModalComponent } from './pages/settings/gift-managing/gift-update-modal/gift-update-modal.component';
import { PurchaseHistoryComponent } from './components/purchase-history/purchase-history.component';
import { RedemptionRequestDetailModalComponent } from './pages/gift/redemption-request-detail-modal/redemption-request-detail-modal.component';
import { CompensationHourDetailModalComponent } from './pages/leaves/compensation-hour-management/compensation-hour-detail-modal/compensation-hour-detail-modal.component';
import { ReviewRatingDetailModalComponent } from './pages/annual-review/review-rating-score/review-rating-detail-modal/review-rating-detail-modal.component';
import { ReviewFormDetailModalComponent } from './pages/annual-review/review-form/review-form-detail-modal/review-form-detail-modal.component';
import { ReviewFormSelectMemberModalComponent } from './pages/annual-review/review-form/review-form-select-member-modal/review-form-select-member-modal.component';
import { AdminShareModule } from './share.module';
import { ReviewFormDetailMemberRejectModalComponent } from './pages/annual-review/review-form/review-form-detail-member/review-form-detail-member-reject-modal/review-form-detail-member-reject-modal.component';
import { UpdateWfhUserModalComponent } from './pages/timekeeper/work-from-home-user/update-wfh-user-modal/update-wfh-user-modal.component';
import { InfoPopupResignComponent } from './pages/employee/resignation/info-popup-resign/info-popup-resign.component';
import { ReviewFormEditLevelModalComponent } from './pages/annual-review/review-form/review-form-edit-level-modal/review-form-edit-level-modal.component';
@NgModule({
  imports: [CommonModule, PackageShareModule, ColorPickerModule, AdminShareModule],
  declarations: [
    CreateUpdateUserComponent,
    NewEmployeeModalComponent,
    EmpCreateGroupUserComponent,
    EmpEditGroupUserComponent,
    EditAccountComponent,
    NewSkillModalComponent,
    NewPositionModalComponent,
    NewDeptModalComponent,
    NewEquipmentCategoryModalComponent,
    LeaveTypeModalComponent,
    ApprovalModalComponent,
    LeaveRequestModalComponent,
    PurchaseFillpriceComponent,
    CreateSupplierComponent,
    CreateContractComponent,
    PurchaseInvoiceComponent,
    PurchaseDetailComponent,
    CreateRoomComponent,
    NewProjectModalComponent,
    NewProjectIterationModalComponent,
    NewProjectItemModalComponent,
    NewSupplierEquipmentModalComponent,
    CreateTimekeeperComponent,
    CreateMachineComponent,
    SelectMachineSyncComponent,
    CreateWarehouseComponent,
    PreviewImageModalComponent,
    NewCommentModalComponent,
    CheckInventoryComponent,
    CreateDeliveryFormComponent,
    NewDashboardModalComponent,
    NewProjectWidgetComponent,
    ChangePasswordComponent,
    CreateProductComponent,
    CreateUpdatePackageComponent,
    PopupDeptUserComponent,
    PopupAddUserComponent,
    ReasonModalComponent,
    BoughtModalComponent,
    ConfirmDetailModalComponent,
    BoughtConfirmComponent,
    PurchaseDetailForUserComponent,
    PurchaseOrderDetailComponent,
    LeaveDetailComponent,
    DeliveryDetailComponent,
    PolicyModalComponent,
    PolicyDetailModalComponent,
    TicketTypeModalComponent,
    TicketModalComponent,
    EmployeeWorkingTimeComponent,
    InfoWorkTimeModalComponent,
    NewcomerTemplateModalComponent,
    UserTierModalComponent,
    GiftUpdateModalComponent,
    PurchaseHistoryComponent,
    CompensationHourDetailModalComponent,
    RedemptionRequestDetailModalComponent,
    UpdateStatusModalComponent,
    ViewHistoryModalComponent,
    ReviewRatingDetailModalComponent,
    ReviewFormDetailModalComponent,
    ReviewFormSelectMemberModalComponent,
    ReviewFormSelectMemberModalComponent,
    ReviewFormDetailMemberRejectModalComponent,
    UpdateWfhUserModalComponent,
    InfoPopupResignComponent,
    ReviewFormEditLevelModalComponent
  ],
  exports: [],
  entryComponents: [
    CreateUpdateUserComponent,
    NewEmployeeModalComponent,
    EmpCreateGroupUserComponent,
    EmpEditGroupUserComponent,
    EditAccountComponent,
    NewSkillModalComponent,
    NewPositionModalComponent,
    NewDeptModalComponent,
    NewEquipmentCategoryModalComponent,
    LeaveTypeModalComponent,
    ApprovalModalComponent,
    LeaveRequestModalComponent,
    PurchaseFillpriceComponent,
    CreateSupplierComponent,
    CreateContractComponent,
    PurchaseInvoiceComponent,
    PurchaseDetailComponent,
    CreateRoomComponent,
    NewProjectIterationModalComponent,
    NewProjectItemModalComponent,
    NewSupplierEquipmentModalComponent,
    CreateTimekeeperComponent,
    CreateMachineComponent,
    SelectMachineSyncComponent,
    CreateWarehouseComponent,
    PreviewImageModalComponent,
    NewCommentModalComponent,
    CheckInventoryComponent,
    CreateDeliveryFormComponent,
    ChangePasswordComponent,
    CreateProductComponent,
    CreateUpdatePackageComponent,
    PopupDeptUserComponent,
    PopupAddUserComponent,
    ReasonModalComponent,
    BoughtModalComponent,
    TemplateEmailModalComponent,
    ConfirmDetailModalComponent,
    BoughtConfirmComponent,
    PurchaseDetailForUserComponent,
    PurchaseOrderDetailComponent,
    LeaveDetailComponent,
    WidgetSetupModalComponent,
    DeliveryDetailComponent,
    PolicyModalComponent,
    PolicyDetailModalComponent,
    TicketTypeModalComponent,
    TicketModalComponent,
    EmployeeWorkingTimeComponent,
    InfoWorkTimeModalComponent,
    TemplateHtmlModalComponent,
    NewcomerTemplateModalComponent,
    UserTierModalComponent,
    GiftUpdateModalComponent,
    PurchaseHistoryComponent,
    CompensationHourDetailModalComponent,
    RedemptionRequestDetailModalComponent,
    ViewHistoryModalComponent,
    UpdateStatusModalComponent,
    ReviewRatingDetailModalComponent,
    ReviewFormDetailModalComponent,
    ReviewFormSelectMemberModalComponent,
    ReviewFormDetailMemberRejectModalComponent,
    UpdateWfhUserModalComponent,
    InfoPopupResignComponent,
    ReviewFormEditLevelModalComponent
  ],
  providers: [],
})
export class EntryAdminModule { }
