import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseAdminComponent } from '@intranet/lib/components';
import { SearchNotification, UserService, NotificationEnum } from '@intranet/lib/data-access';
import { NotificationModel } from '@intranet/lib/data-access/models/notification.model';
import { WebsiteEvent } from '@intranet/lib/settings';
import { IPageInfo } from 'ngx-virtual-scroller';
import { Subscription } from 'rxjs';
import { async } from '@angular/core/testing';
@Component({
  selector: 'app-notification-page',
  templateUrl: './notification-page.component.html',
  styleUrls: ['./notification-page.component.scss']
})
export class NotificationPageComponent extends BaseAdminComponent implements OnInit, OnDestroy {
  search: SearchNotification = new SearchNotification();
  list: NotificationModel[];
  isBuffer = true;
  subscriptions: Subscription[] = [];
  isShowToolbox = false;
  constructor(private userService: UserService, private websiteEvent: WebsiteEvent) {
    super();
    this.search.pageNumber = 0;
    this.search.pageSize = 10;
    this.search.totalItems = 0;
    this.search.notificationType = NotificationEnum.TODO;
  }

  async fetchMore(event: IPageInfo) {
    if (this.list) {
      if (event.endIndex >= this.list.length - 1) {
        this.search.pageNumber = this.search.pageNumber + this.search.pageSize;
        this.search.pageSize = 1;
        await this.loadList();
      }
    }
  }
  async ngOnInit() {
    this.subscriptions.push(this.websiteEvent.notificationHeaderEvent.subscribe(async () => {
      await this.loadResetList();
    }));
    await this.loadList();
  }
  async loadList() {
    if (this.isBuffer) {
      const data = await this.userService.getNotificationList(this.search);
      if (data && data.length > 0) {
        if (this.list) {
          this.list = this.list.concat(data);
        } else {
          this.list = data;
        }

      } else {
        this.isBuffer = false;
      }
    }
  }
  async onLink(item) {
    await this.userService.updateRead(item);
    if (item.link) {
      item.readTime = new Date();
      this.websiteEvent.emitHeaderNotificationEvent();
      window.open(item.link, '_blank');
    }
  }
  async onRead(item) {
    if (item) {
      item.readTime = new Date();
      await this.userService.updateRead(item);
    } else {
      await this.userService.updateReadAll(NotificationEnum.TODO);
    }
    this.websiteEvent.emitHeaderNotificationEvent();

  }
  async onClean(item) {
    if (item) {
      await this.userService.updateClean(item);
    } else {
      await this.userService.updateCleanAll(NotificationEnum.TODO);
    }
    this.websiteEvent.emitHeaderNotificationEvent();
  }
  async loadResetList() {
    this.isBuffer = true;
    this.search.pageNumber = 0;
    this.search.pageSize = 10;
    this.list = [];
    await this.loadList();
  }
  async showToolBox(item) {
    if (item) {
      item.isShowToolbox = !item.isShowToolbox;
    } else {
      this.isShowToolbox = !this.isShowToolbox;
    }
  }
  async hideToolBox(item) {
    if (item) {
      item.isShowToolbox = false;
    } else {
      this.isShowToolbox = false;
    }
  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
