import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '@intranet/index';

@Component({
  selector: 'app-new-comment-modal',
  templateUrl: './new-comment-modal.component.html',
  styleUrls: ['./new-comment-modal.component.scss'],
})
export class NewCommentModalComponent implements OnInit {
  comment: string;
  activeModal: NgbActiveModal;
  editorConfig = Constants.TINY_CONFIG;

  constructor(modalRef: NgbActiveModal) {
    this.activeModal = modalRef;
  }

  ngOnInit(): void {}

  closeModal() {
    this.activeModal.close(null);
  }

  saveComment() {
    this.activeModal.close(this.comment);
  }
}
