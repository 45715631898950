import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import { WidgetEquipmentDto, WidgetSearchDto, WidgetService } from '@intranet/lib/data-access';
import { Subscription } from 'rxjs';
import { GridsterItem } from 'angular-gridster2';
import { WebsiteEvent } from '@intranet/lib/settings/event-store/website.event';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
@Component({
  selector: 'app-widget-equipment',
  templateUrl: './widget-equipment.component.html',
  styleUrls: ['./widget-equipment.component.scss'],
})
export class WidgetEquipmentComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;
  @Input() widgetLoaded: EventEmitter<any>;
  resizeSub: Subscription;
  selectGroupSubscription: Subscription;

  loading = true;
  searchData: WidgetSearchDto = new WidgetSearchDto();
  data: WidgetEquipmentDto = new WidgetEquipmentDto();
  constructor(private _widgetService: WidgetService,
    private websiteEvent: WebsiteEvent) { }

  ngOnDestroy() {
    if (this.selectGroupSubscription) {
      this.selectGroupSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.selectGroupSubscriptionEvent();
  }

  selectGroupSubscriptionEvent() {
    this.selectGroupSubscription = this.websiteEvent.groupSourceDataEvent.subscribe((departmentId: any) => {
      if (!this.searchData.departmentIds || departmentId === '') {
        this.searchData.departmentIds = departmentId !== '' ? departmentId : null;
        this.loadData();
      }
    });
  }

  async loadData() {
    this.loading = true;
    try {
      this.data = await this._widgetService.getWidgetEquipment(this.searchData);
      this.widgetLoaded?.emit(this.widget);
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
    finally {
      this.loading = false;
    }
  }
  modelChanged(event) {
    this.searchData.departmentIds = event;
    this.loadData();
    if (event !== null) {
      this.websiteEvent.setGroupSourceDataEvent(event);
    }
  }
}
