<div class="bg-full"></div>
<div id="rt-login">
  
  <div class="container-fluid">
    <div class="row d-flex flex-row align-items-center justify-content-center rt-login-frame">
      
      <div class="rt-login-form">
        <div class="rt-logo text-center">
          <img width="220" src="assets/images/front/logo-white.png" alt="restaff" />
            </div>
          <div>
            <router-outlet #outlet="outlet"></router-outlet>
          </div>
        </div>
      </div>
      <div class="privacy text-center">
        <p>Copyright © 2022 Restaff. All rights reserved.</p>
      </div>
    </div>
  </div>
  