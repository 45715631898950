export enum ResignStatus {
  PendingApproval,
  LeaderApproved,
  LeaderRejected,
  HRApproved,
  HRRejected,
  'Pending Approval' = PendingApproval,
  'Leader Approved' = LeaderApproved,
  'Leader Rejected' = LeaderRejected,
  'HR Approved' = HRApproved,
  'HR Rejected' = HRRejected,
}

export enum FormAction {
  NeedLeader,
  NeedHR,
  NeedLeaderDelete,
  NeedHRDelete,
  NoNeed,
}
