import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UserModel } from '@intranet/lib/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UrlConstant } from '@intranet/index';
import { Router, ActivatedRoute, Params } from '@angular/router';
@Component({
  selector: 'app-user-list-modal',
  templateUrl: './user-list-modal.component.html',
})
export class UserListModalComponent {
  @Input() items: UserModel[];
  @Input() title: string;

  UrlConstant = UrlConstant;
  activeModal: NgbActiveModal;

  isLoading = false;
  constructor(
    private router: Router,
    modalRef: NgbActiveModal,
  ) {
    this.activeModal = modalRef;
  }

  closeModal() {
    this.activeModal.close(null);
  }

  onNavigateUserProfile(item: UserModel) {
    this.closeModal();
    this.router.navigate([UrlConstant.PROFILE_PAGE], { queryParams: { employeeId: item.employeeId } });
  }
}
