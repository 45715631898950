<nz-upload *ngIf="!isHidden" [nzDisabled]="isDisable"  class="avatar-uploader position-relative" nzName="avatar" nzListType="picture-card" nzAccept="image/x-png,image/gif,image/jpeg"
  [nzShowUploadList]="false" [nzCustomRequest]="customReq" (nzChange)="handleAvatarChange($event)">
  <ng-container *ngIf="!imageUrl">
    <i class="upload-icon" nz-icon [nzType]="'plus'"></i>
    <div class="ant-upload-text">{{ placeholder | translate }}</div>
  </ng-container>
  <ng-container *ngIf="imageUrl">
    <img [src]="imageUrl" default="M_ReferUser-1.png" class="hrm-avatar rounded-circle" type="cdn" />
    <i nz-icon nzType="camera" nzTheme="outline" class="camera-icon" nz-tooltip nzTooltipTitle="Upload avatar image"></i>
  </ng-container>
</nz-upload>

<nz-upload *ngIf="isHidden" nzAccept="image/x-png,image/gif,image/jpeg" [nzShowUploadList]="false" [nzCustomRequest]="customReq" (nzChange)="handleAvatarChange($event)">
</nz-upload>
