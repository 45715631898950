<div class="calendar-component">
  <div class="rt-panel-item-content">
    <div class="calendar-controls">
      <div class="btn btn-none btn-prev" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="onViewDateChange($event)">
        <i class="fas fa-chevron-left"></i>
      </div>
      <div class="calendar-date-wrapper">
        <h6 class="calendar-date">{{ viewDate | date: 'MMMM y' }}</h6>
      </div>
      <div class="btn btn-none btn-next" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="onViewDateChange($event)">
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>

    <ng-template #customCellTemplate let-day="day" let-openDay="openDay" let-locale="locale"
      let-tooltipPlacement="tooltipPlacement" let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay"
      let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate"
      let-tooltipAppendToBody="tooltipAppendToBody" let-trackByEventId="trackByEventId"
      let-currentDate="day.date.toDateString()">
      <div class="cal-cell-top" [ngClass]="_classEvents[day?.date?.toDateString()]" [attr.aria-label]="
          { day: day, locale: locale } | calendarA11y: 'monthCell'
        ">
        <span aria-hidden="true">
          <span class="cal-day-number">{{
            day.date | calendarDate: 'monthViewDayNumber':locale
          }}</span>
        </span>
      </div>
      <div class="cal-events"
        *ngIf="day.events.length > 0 && _filterEventTypes[currentDate] && _filterEventTypes[currentDate].length">
        <ng-container *ngFor="let event of _filterEventTypes[currentDate];">
          <div class="cal-event" 
            [ngStyle]="{ backgroundColor: event.color?.primary }" [ngClass]="event?.cssClass"
            (mouseenter)="highlightDay.emit({ event: event })" (mouseleave)="unhighlightDay.emit({ event: event })"
            [mwlCalendarTooltip]="
              event.title | calendarEventTitle: 'monthTooltip':event
            " [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event" [tooltipTemplate]="tooltipTemplate"
            [tooltipAppendToBody]="tooltipAppendToBody" 
            (mwlClick)="eventClicked.emit({ event: event, sourceEvent: $event })"
            [attr.aria-hidden]="{} | calendarA11y: 'hideMonthCellEvents'"></div>
        </ng-container>
       
      </div>
    </ng-template>

    <div class="newfeed-calendar">
      <mwl-calendar-month-view [viewDate]="viewDate" [activeDayIsOpen]="true" [refresh]="refresh" [events]="_events"
        (dayClicked)="dayClicked($event)" [cellTemplate]="customCellTemplate">
      </mwl-calendar-month-view>
    </div>
  </div>
</div>