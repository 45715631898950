import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CalendarDisplayTypeEnum } from '@intranet/lib/data-access';
import { UrlConstant } from '../../../config/urlConstant';

@Component({
  selector: 'app-widget-calendar',
  templateUrl: './widget-calendar.component.html',
  styleUrls: ['./widget-calendar.component.scss']
})
export class WidgetCalendarComponent implements OnInit {
  @Input() widget: any;
  @Input() resizeEvent: any;
  @Input() widgetLoaded: EventEmitter<any>;
  calendarDisplayTypeEnum = CalendarDisplayTypeEnum;
  urlConstant = UrlConstant;
  constructor() { }

  ngOnInit() {
  }

}
