export enum CalendarView {
  Year = 'year',
  Month = 'month',
  Week = 'week',
  Day = 'day'
}

export enum EventTypeEnum {
  EVENT = 1,
  HOLIDAY = 2,
  PARTY = 3,
  COMPANY_SPONSOR = 4,
}

export enum CalendarEventTypeEnum {
  LEAVE = 1,
  BOOKING = 2,
  EVENT = 3,
}

export enum EventRepeatTypeEnum {
  NONE = 0,
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
  YEARLY = 4
}