import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { Loading } from '@intranet/lib/settings';

@Injectable({
  providedIn: 'root',
})
export class AnnualLeaveRequestService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/annual-leave-request';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }



  getSearchData(scb?: any) {
    const url = 'search-data';
    return this.getByUrl(url, null, scb);
  }

  viewCurrentUser(searchData?: any, scb?: any) {
    const url = 'view-user';
    return this.getByUrl(url, searchData, scb);
  }

  restore(scb?: any) {
    const url = this.hostUrl + '/restore';
    return this.httpClient.putSync(url, scb);
  }
}
