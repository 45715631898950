<ng-container *ngIf="widget && !isClientPage">
  <nz-card [nzBordered]="false" [nzLoading]="loading">
    <div class="widget-header">
      <div class="widget-title">
        <ng-container *ngIf="isDetail">
          <span (click)="hideDetail()" class="vector-right-icon btn-link"><img  src="assets/images/front/shortcut/vector-right.svg" ></span>
          {{title}}
        </ng-container>
        <ng-container *ngIf="!isDetail">
          {{widget.data.title}}
        </ng-container>
      </div>
      <div class="widget-control widget-control-sm-fully">
        <div *ngIf="!isDetail && data && data.companyExperience && data.departmentGroups && data.departmentGroups.length > 1">
          <nz-select (ngModelChange)="modelChanged($event)" nzShowSearch nzAllowClear nzPlaceHolder="Select a groups" [(ngModel)]="searchData.departmentIds">
            <nz-option *ngFor="let option of data.departmentGroups" [nzLabel]="option.name" [nzValue]="option.id">
            </nz-option>
          </nz-select>
        </div>
      </div>
    </div>

    <ng-container [ngTemplateOutlet]="chartTpl" [ngTemplateOutletContext]="{ $implicit: !isDetail }"></ng-container>

    <ng-container *ngIf="isDetail">
      <app-widget-work-experience-detail [(data)]="dataDetail" [(searchData)]="searchData" [mobileView]="isMobileView"></app-widget-work-experience-detail>
    </ng-container>
  </nz-card>
</ng-container>

<ng-container *ngIf="isClientPage">
  <!-- <ng-container [ngTemplateOutlet]="chartTpl" [ngTemplateOutletContext]="{ $implicit: true }"></ng-container>

  <div class="client-page-item" [style.width]="'calc(100% - ' + (data.companyExperience?.view[0] || 0) + 'px)'">
    <p class="pb-1">Details for all members
      <ng-container *ngIf="!isViewAll">with <span class="client-page-select-title">{{title | lowercase}}</span></ng-container>
      <ng-container *ngIf="isViewAll"><span class="client-page-select-title">({{dataDetail?.length || 0}})</span></ng-container>
    </p>
    <app-widget-work-experience-detail [(data)]="dataDetail" [(searchData)]="searchData" [displayMode]="displayMode">
    </app-widget-work-experience-detail>
  </div> -->

  <ng-template #indicatorTpl>
    <i nz-icon nzType="loading" class="loading-indicator"></i>
  </ng-template>

  <nz-spin [nzIndicator]="indicatorTpl" [nzSpinning]="loading">
    <div class="widget-client-container">
      <div class="client-work-experience-chart">
        <ng-container [ngTemplateOutlet]="chartTpl" [ngTemplateOutletContext]="{ $implicit: true }"></ng-container>
        <div class="legend-container">
          <div class="legend-title">Legend</div>
          <div class="information-wrapper">
            <div class="all-members" [ngClass]="{'all-members-active': isViewAll}" (click)="viewAll()">
              <span>All members ({{allMembers?.length || 0}})</span>
            </div>

            <div class="legend-wrapper">
              <span class="legend-bullet" [style.background-color]="legendColor.experience"></span>
              <span class="legend-label">Experience</span>
            </div>

            <div class="legend-wrapper">
              <span class="legend-bullet" [style.background-color]="legendColor.seniority"></span>
              <span class="legend-label">Seniority</span>
            </div>
          </div>
        </div>
      </div>

      <div class="member-table">
        Details for all members
        <ng-container *ngIf="!isViewAll">with <span class="client-page-select-title">{{title | lowercase}}</span></ng-container>
        <ng-container *ngIf="isViewAll"><span class="client-page-select-title">({{dataDetail?.length || 0}})</span></ng-container>
        <app-widget-work-experience-detail [(data)]="dataDetail" [(searchData)]="searchData" [displayMode]="displayMode">
        </app-widget-work-experience-detail>
      </div>
    </div>
  </nz-spin>
</ng-container>

<ng-template #chartTpl let-show>
  <div class="widget-content" [id]="widgetContentId" *ngIf="data && data.companyExperience" [ngClass]="{ 'client-page-item': isClientPage, 'd-none': !show }">
    <ngx-charts-bar-vertical-2d [view]="data.companyExperience.view" [scheme]="data.companyExperience.colorScheme" [results]="data.companyExperience.chartDatas"
      [xAxis]="data.companyExperience.xAxis" [yAxis]="data.companyExperience.yAxis" [legend]="data.companyExperience.showLegend"
      [legendPosition]="data.companyExperience.legendPosition" [showXAxisLabel]="data.companyExperience.showXAxisLabel"
      [showYAxisLabel]="data.companyExperience.showYAxisLabel" (select)="onSelect($event)" [xAxisLabel]="data.companyExperience.xAxisLabel"
      [yAxisLabel]="data.companyExperience.yAxisLabel" [animations]="false">
    </ngx-charts-bar-vertical-2d>
  </div>
</ng-template>
