import { ViewChild, Component, OnDestroy, Input, Output, EventEmitter, OnChanges, OnInit, SimpleChanges, Renderer2 } from '@angular/core';
import { WallNewsFeedService, NewsFeedMedia, UserModel, CDNService } from '@intranet/lib/data-access';
import { Constants, UrlConstant } from '@intranet/index';

@Component({
  selector: 'app-feed-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  styles: [],
})
export class EditorComponent implements OnChanges, OnInit {
  @ViewChild('editor') editorContainer;
  @Input() isLoading: boolean;
  @Input() preview: string;
  @Output() previewChange = new EventEmitter<string>();

  @Input() context: any;
  @Input() editorConfig: any;
  @Input() isInline: boolean;

  @Input() content: string;
  @Output() contentChange = new EventEmitter<string>();

  @Input() hashtags: any[];
  @Output() hashtagsChange = new EventEmitter<any>();

  @Input() mentions: any[];
  @Output() mentionsChange = new EventEmitter<any>();

  @Input() ngEditorStyle: { [klass: string]: any };
  @Input() disablePreview: boolean;
  @Input() disablePlaceHolder: boolean;
  @Input() placeholder: string;

  isShowPreview: boolean;
  previewData: string;
  isActiveEditor = false;
  readyEditor: boolean;
  tinyMceEditorConfig: any;
  editor;

  constructor(private cdnService: CDNService, private wallNewsFeedService: WallNewsFeedService, private renderer: Renderer2) { }

  ngOnInit() {
    this.previewData = '';
    this.isShowPreview = false;
    this.readyEditor = true;
    this.tinyMceEditorConfig = Constants.TINY_CONFIG_NEWSFEED;
    Object.assign(this.tinyMceEditorConfig, this.editorConfig);
    const self = this;

    this.tinyMceEditorConfig.images_upload_handler = async (blobInfo, success, failure, progress) => {
      self.wallNewsFeedService
        .uploadBlob(blobInfo.blob(), blobInfo.filename())
        .then((res: NewsFeedMedia[]) => {
          if (res && res.length > 0) {
            success(self.wallNewsFeedService.getImageUrl(res[0].path));
          }
        })
        .catch(error => {
          failure(error);
        });
    };

    this.tinyMceEditorConfig.mentions = {
      items: 50,
      delimiter: ['@', '#'],
      delay: 100,
      source(query, process, delimiter) {
        const editorSelf = this;
        if (delimiter === '@') {
          const params = this.context || {};
          params.keyword = query;
          self.wallNewsFeedService.getUsers(
            params,
            (res: UserModel[]) => {
              if (res && res.length) {
                editorSelf.queryBy = 'fullName';
                res.map(user => { user.avatarUrl = user.avatarUrl ? self.cdnService.getFileUrl(user.avatarUrl) : null; });
                process(res);
              }
            },
            error => { },
          );
        } else if (delimiter === '#') {
          const params = this.context || {};
          params.query = query;
          self.wallNewsFeedService.getHashtagFeed(
            params,
            (res: UserModel[]) => {
              if (res && res.length) {
                editorSelf.queryBy = 'name';
                res.map(user => { user.avatarUrl = user.avatarUrl ? self.cdnService.getFileUrl(user.avatarUrl) : null; });
                process(res);
              }
            },
            error => { },
          );
        }
      },
      matcher(item) {
        if (item[this.options.queryBy].indexOf(this.options.query)) {
          return true;
        }
        return false;
      },
      render(item) {
        if (this.options.delimiter === '@') {
          return `
            <li class="mention-suggestion">
                <img class="avatar" src='${item.avatarUrl ? item.avatarUrl : '/assets/images/front/user.svg'}'/>
                <span class='fullname'>${item.fullName}</span>
                <span class='position'>${item.positionName}</span>
            </li>`;
        } else {
          return `<li><a href="javascript:;"><span>${item.name}</span></a></li>`;
        }
      },
      insert(item) {
        if (this.options.delimiter === '@') {
          return self.renderMention(item) + '<span></span>';
        } else if (this.options.delimiter === '#') {
          return self.renderHashtag(item) + '<span></span>';
        }
      },
    };
  }

  addMention(user: UserModel) {
    this.isActiveEditor = true;

    if (!this.mentions) {
      this.mentions = [];
    }

    if (this.mentions.indexOf(user.id) === -1) {
      this.mentions.push(user.id);
      if (this.content) {
        this.content += this.renderMention(user);
      } else {
        this.content = this.renderMention(user);
      }
      this.contentChange.emit(this.content);
    }
  }

  onEditorInit() {
    this.readyEditor = true;
  }

  placeholderStyle() {
    const height = this.tinyMceEditorConfig.placeholderHeight || this.tinyMceEditorConfig.height || 200;
    return {
      height: height + 'px',
      'line-height': height + 'px',
    };
  }

  loadingStyle() {
    const height = this.tinyMceEditorConfig.height || 200;
    return {
      height: height + 'px',
      'line-height': height + 'px',
    };
  }

  activeEditor() {
    this.isActiveEditor = true;
  }

  ngOnChanges(changes: SimpleChanges) { }

  reset() {
    if (this.editorContainer && this.editorContainer.nativeElement) {
      this.editorContainer.nativeElement.innerHTML = '';
    }

    this.content = '';
    this.mentions = [];
  }

  onChangeEditor($event) {
    this.contentChange.emit(this.content);

    this.onUpdateEditor(this.content);
  }

  onBlurEditor($event) {
    this.contentChange.emit(this.content);

    this.onUpdateEditor(this.content);
  }

  onPasteEditor(observer) {
    const text = observer.event.clipboardData.getData('text');
    if (!this.previewData) {
      this.parseUrl(observer.event.clipboardData.getData('text'));
    }

    this.contentChange.emit(this.content);

    this.onUpdateEditor(this.content);
  }

  onKeyUpEditor($event) {
    this.contentChange.emit(this.content);

    this.onUpdateEditor(this.content);
  }

  onUpdateEditor(content) {
    const template = this.renderer.createElement('template');
    template.innerHTML = content;

    const words = [];
    const mentions = [];
    const hashtags = [];

    for (const node of template.content.childNodes) {
      let code;
      let text;
      let type;

      if (node.nodeType === Node.TEXT_NODE) {
        text = node.textContent;
      } else {
        code = node.getAttribute('value');
        type = node.getAttribute('type');

        text = node.innerText;
      }

      if (code) {
        words.push(code);
      }
      if (text) {
        words.push(text);
      }
    }

    for (const node of template.content.querySelectorAll('[data-hashtag-id]')) {
      hashtags.push(node.getAttribute('data-hashtag-id'));
    }
    this.hashtags = hashtags;
    this.hashtagsChange.emit(this.hashtags);

    for (const node of template.content.querySelectorAll('[data-mention-id]')) {
      mentions.push(node.getAttribute('data-mention-id'));
    }
    this.mentions = mentions;
    this.mentionsChange.emit(this.mentions);
  }

  toEmoji(code) {
    return String.fromCodePoint(code);
  }
  renderEmoji(emoji: any) {
    return `<span class="emoji emoji-${emoji.code}" value="e[id='${emoji.code.toString()}']" type="emoji">${this.toEmoji(emoji.code)}</span>`;
  }

  renderMention(item: any) {
    return `<a href="${UrlConstant.PROFILE_PAGE + '?employeeId=' + item.employeeid}" class="user-mention" type="mention" data-mention-id="${item.id}">${item.fullname || item.fullName
      }</a><span>&nbsp;</span>`;
  }

  renderHashtag(item: any) {
    return `<a href="${UrlConstant.mapNewsFeed(UrlConstant.NewsFeedUrl.HASHTAG) + '/' + item.id}" class="user-hashtag" type="hashtag" data-mention-id="${item.id}">#${item.id
      }</a><span>&nbsp;</span>`;
  }

  getYoutubeId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  parseUrl(str: string) {
    const regex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
    const matches = str.match(regex);
    if (matches && matches[0] !== undefined) {
      const url = matches[0];
      if (url.indexOf('youtube.com') >= 0) {
        const id = this.getYoutubeId(url);
        if (id) {
          const content = `video[id='${id}' type='youtube']`;
          this.setPreview(content);
        }
      } else {
        this.wallNewsFeedService.getWebsiteMeta(url, resp => {
          if (resp && resp.title && resp.imageUrl && resp.description) {
            const content = `website[url='${url}' title='${resp.title}' short-desc='${resp.description ? resp.description : ''}' placeholder='${resp.imageUrl ? resp.imageUrl : ''
              }']`;
            this.setPreview(content);
          }
        });
      }
    }
  }

  hidePreview() {
    this.setPreview('');
  }

  setPreview(str: string) {
    if (str === null) {
      str = '';
    }
    this.previewData = str;
    this.preview = str;
    this.previewChange.emit(this.preview);
  }
}
