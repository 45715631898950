<ng-container *ngIf="widget">
  <nz-card [nzBordered]="false" [nzLoading]="loading">
    <div class="widget-header">
      <div class="widget-title">
        {{widget.data.title}}
      </div>
      <div class="widget-control widget-control-sm-fully">
        <div *ngIf="data && data.detailDtos">
          <nz-select (ngModelChange)="modelChanged($event)" nzShowSearch nzAllowClear nzPlaceHolder="Select a groups"
            [(ngModel)]="searchData.departmentIds">
            <nz-option *ngFor="let option of data.departmentGroups" [nzLabel]="option.name" [nzValue]="option.id">
            </nz-option>
          </nz-select>
        </div>
      </div>
    </div>
    <div *ngIf="data?.detailDtos?.length > 0 else emptyTemplate">
      <!-- <div class="row p-lr-md p-b-xxs mt-2">
        <div class="col-md-9 col-12">
          <h5 class="fs-14 m-b-none">TOP {{data.detailDtos.length}} Purchased Items</h5>
        </div>
        <div class="col-md-3 col-12 text-right text-left-md"><span
            class="text-muted font-weight-bold fs-14">{{data.totalPrice | number: '1.0'}} VND</span></div>
      </div> -->
      <div class="top-item-container">
        <h5 class="fs-14 m-b-none">TOP {{data.detailDtos.length}} Purchased Items</h5>
        <span class="text-muted font-weight-bold">{{data.totalPrice | number: '1.0'}} VND</span>
      </div>
      <div class="scroll-bar box-equipment">
        <div class="row" *ngFor="let item of data.detailDtos">
          <div class="col-md-9">
            <p>
              <label>Item:</label>
              {{item.productName}}
              <b>x {{item.numberPRRequest | number: '1.0'}} Times</b>
            </p>
            <p><label>Supplier:</label> {{item.supplierName}}</p>
            <p><label>Price:</label> {{item.totalAmount | number: '1.0'}} VND</p>
          </div>
          <!-- <div class="col-md-3">
            <div class="box-time">
              <p class="text-time">Requested</p>
              <div class="box-number">
                <p class="number-time text-success">{{item.numberPRRequest | number: '1.0'}}x</p>
                <span class="text-time">Times</span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </nz-card>
  <!-- <ng-template #titleTemplate>
    <ng-container *ngIf="data && data.detailDtos && data.departmentGroups && data.departmentGroups.length > 1">
      <nz-select class="widget-dashboard-select" (ngModelChange)="modelChanged($event)" nzShowSearch nzAllowClear nzPlaceHolder="Select a groups"
        [(ngModel)]="searchData.departmentIds">
        <nz-option *ngFor="let option of data.departmentGroups" [nzLabel]="option.name" [nzValue]="option.id">
        </nz-option>
      </nz-select>
    </ng-container>
  </ng-template>
  <ng-template #extraTemplate>
    
  </ng-template> -->
</ng-container>

<ng-template #emptyTemplate>
  <nz-empty></nz-empty>
</ng-template>