import { NewsfeedStatusEnum, NewsFeedTypeEnum } from '../data-access/models/enums/news-feed-type.enum';
import { PublicityLevel } from '../data-access/models/enums/publicity-level.enum';

export class NewsFeeds {
  static readonly MAX_QUESTIONS = 3;
  static readonly FEED_TYPES = {
    WALL: NewsFeedTypeEnum.WALL,
    POLL: NewsFeedTypeEnum.POLL,
    BIRTHDAY: NewsFeedTypeEnum.BIRTHDAY,
    WORK_ANNIVERSARY: NewsFeedTypeEnum.WORK_ANNIVERSARY,
    ANNOUNCEMENT: NewsFeedTypeEnum.ANNOUNCEMENT,
    STICKY: NewsFeedTypeEnum.STICKY,
    REPORT_TICKET: NewsFeedTypeEnum.TICKET
  };
  static readonly FEED_STATUSES = [
    { id: NewsfeedStatusEnum.New, name: 'New' },
    { id: NewsfeedStatusEnum.Approved, name: 'Approved' },
    { id: NewsfeedStatusEnum.Rejected, name: 'Rejected' },
    { id: NewsfeedStatusEnum.Closed, name: 'Closed' }
  ];

  static readonly GREETING_MESSAGES = [
    [0, 4, 'Good night'],
    [5, 11, 'Good morning'], // Store messages in an array
    [12, 17, 'Good afternoon'],
    [18, 24, 'Good night'],
  ];

  static readonly PUBLICITY_LEVELS = [
    {
      id: PublicityLevel.PUBLIC,
      name: 'Public',
    },
    {
      id: PublicityLevel.PRIVATE_GROUP,
      name: 'Group/Department',
    },

    {
      id: PublicityLevel.PRIVATE,
      name: 'Only me',
    },
  ];
}
