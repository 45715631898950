<ng-container *ngIf="!isLoading">
    <ng-container *ngIf="data?.length > 0 else emptyData">
        <div class="display-container">
            <ng-content></ng-content>
        </div>
    </ng-container>
    
</ng-container>

<ng-container *ngIf="isLoading">
    <div class="spinner-container">
        <nz-spin nzSimple></nz-spin>
    </div>
</ng-container>

<ng-template #emptyData>
    <div class="empty-container">
        <ng-container *ngIf="errorText else nzEmpty">
            <span class="error-text">{{errorText}}</span>
        </ng-container>
    </div>
</ng-template>

<ng-template #nzEmpty>
    <nz-empty></nz-empty>
</ng-template>