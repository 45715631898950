<div class="survey-answer">
  <form class="feed-form" (ngkSubmit)="onSubmit()" #frm="ngForm" [ngkForm]="true">
    <div nz-row>
      <div nz-col nzSpan="24" class="survey-title">
        <h4>{{survey.name}}</h4>
        <p>{{survey.shortDescription}}</p>
        <p>{{survey.description}}</p>
      </div>
    </div>
    <div nz-row *ngFor="let question of survey.questions; index as questionIndex" class="question-detail">
      <div class="question-index">Question {{questionIndex + 1}}</div>
      <div nz-col nzSpan="24" class="question-body">
        <!-- <app-newsfeed-question-widget [question]="question" [isReadonly]="!enableAnswer">
        </app-newsfeed-question-widget> -->
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <button type="submit" class="btn btn-primary float-right mr10" nz-button>Submit</button>
        <button *hasPermission="['survey', 'approvesurvey']" [nzLoading]="isLoading" [hidden]="!enableApprove"
          type="button" (click)="onApprove(true)" class="btn btn-primary float-right mr10" nz-button>Approve</button>
        <button *hasPermission="['survey', 'approvesurvey']" [hidden]="!enableApprove" type="button"
          (click)="onApprove(false)" class="btn btn-primary float-right mr10" nz-button>Reject</button>
        <button type="button" (click)="onCancel()" class="btn btn-primary float-right mr10" nz-button>Back</button>
      </div>
    </div>
  </form>
</div>