import { Component, OnInit } from '@angular/core';
import {
  PurchaseOrderDetail,
  PurchaseRequestDetailService,
  PurchaseRequestService,
  InternalUploadFileDto,
  PurchaseRequest,
  Department,
  DepartmentService,
} from '@intranet/lib/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '@intranet/index';
import { BaseAdminComponent } from '@intranet/lib/components';
import { Router } from '@angular/router';
import { UrlConstant } from '@intranet/index';

@Component({
  selector: 'app-purchase-detail',
  templateUrl: './purchase-detail-user.component.html',
  styleUrls: ['./purchase-detail-user.component.scss'],
})
export class PurchaseDetailForUserComponent extends BaseAdminComponent implements OnInit {
  modelUpload: InternalUploadFileDto = new InternalUploadFileDto();
  purchseRequestStatus = Constants.PRUCHASE_REQUEST_STATUS_ID;
  purchaseRequest: PurchaseRequest = new PurchaseRequest();
  productLists: PurchaseOrderDetail[];
  purchaseRequestId: string;
  activeModal: NgbActiveModal;
  requestTypeEnum = Constants.REQUEST_TYPE_ENUM;
  groups: Department[];
  purchaseRequestStatus = Constants.PRUCHASE_REQUEST_STATUS_ID;

  constructor(
    modalRef: NgbActiveModal,
    private depSvc: DepartmentService,
    private purchaseRequestDetailSvc: PurchaseRequestDetailService,
    private purchaseRequestSvc: PurchaseRequestService,
    private router: Router,
  ) {
    super();
    this.activeModal = modalRef;
  }

  ngOnInit() {
    this.loadGroups();
    this.loadPurchaseRequest();
    this.loadDetail();
    this.modelUpload.entityId = this.purchaseRequestId;
    this.modelUpload.entityType = Constants.ENTITY_TYPE.PURCHASE_REQUEST;
    this.modelUpload.isReadonly = true;
  }

  loadGroups() {
    // get groups (departments)
    this.depSvc.getDropdown(undefined, (resp: Department[]) => {
      this.groups = resp;
      if (this.groups && this.departmentSession) {
        this.groups = this.groups.filter(x => this.departmentSession.find(p => p.Id === x.id));
      }
    });
  }

  loadPurchaseRequest() {
    this.purchaseRequestSvc.getById(this.purchaseRequestId, (res: PurchaseRequest) => {
      if (res) {
        this.purchaseRequest = res;
      }
    });
  }

  loadDetail() {
    this.purchaseRequestDetailSvc.getByEmployee({ guid: this.purchaseRequestId }, (res: PurchaseOrderDetail[]) => {
      if (res) {
        this.productLists = res.map(item => {
          const newItem: PurchaseOrderDetail = {
            id: item.id,
            equipmentCategoryId: item.equipmentCategoryId,
            equipmentCategoryName: item.equipmentCategory.name,
            name: item.name,
            reason: item.reason,
            quantity: item.quantity,
            status: item.status,
            price: item.price,
            type: item.productId ? this.requestTypeEnum.Package : this.requestTypeEnum.Normal,
            productId: item.productId ? item.productId : null,
            option: item.option ? item.option : null
          };
          return newItem;
        });
      }
    });
  }

  openPageUpdate() {
    this.activeModal.close();
    this.router.navigate([UrlConstant.mapUser(UrlConstant.UserUrl.PURCHASE_UPDATE), this.purchaseRequestId]);
  }
}
