<p class="title text-center">Update password</p>
<p class="sub-title text-center" *ngIf="hasUpdatePassword">Your password has updated, please navigate to login page and sign in at
  <a [routerLink]="'/login'">here</a></p>
<p class="sub-title text-center text-danger" *ngIf="hasErrors">Your link has expired, please try again with another link</p>
<form [formGroup]="formGroup" (submit)="updatePassword()" *ngIf="!hasUpdatePassword && !hasErrors">
  <div class="form-group">
    <label for="password">Password</label>
    <input type="password" class="form-control" formControlName="password" id="password" placeholder="Password">
    <span class="text-danger" *ngIf="password?.errors?.required && password?.touched">Password is required</span>
    <span class="text-danger" *ngIf="password?.errors?.minlength && password?.touched">Password must be at least 6 characters</span>
  </div>
  <div class="form-group">
    <label for="confirmPassword">Confirm password</label>
    <input type="password" class="form-control" formControlName="confirmPassword" id="confirmPassword" placeholder="Confirm password">
    <span class="text-danger" *ngIf="confirmPassword?.errors?.required && confirmPassword?.touched">Confirm password is required</span>
    <span class="text-danger" *ngIf="confirmPassword?.errors?.isNotMatch && confirmPassword?.touched">Confirm password is not match with password</span>
  </div>
  <button type="submit" class="btn btn-primary btn-block">Update password</button>
</form>
