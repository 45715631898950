import { BaseModel } from './base-model';
import { Supplier } from './supplier';
import { SupplierEquipment } from './supplier-equipment.model';
import { EquipmentCategory } from './equipment-category';
import { Product } from './product.model';

export class Warehouse extends BaseModel {
  public productId: string;
  public name: string;
  public lastName: string;
  public price: number;
  public quantity: number;
  public deliverQuantity: number;
  public used: number;
  public remain: number;
  public qrCode: string;
  public product: Product;
  expaned: boolean;
  public details: WarehouseDetail[];
  public detail: WarehouseDetail;

  constructor() {
    super();
    this.detail = new WarehouseDetail();
  }
}
export class WarehouseDetail extends BaseModel {
  public name: string;
  public lastName: string;
  public purchaseOrderNo: string;
  public equipmentName?: string;
  public supplierName?: string;
  public equipmentCategoryName?: string;
  public price: number;
  public quantity: number;
  public deliverQuantity: number;
  public used: number;
  public remain: number;
  public neededQuantity: number;
  public totalQuantity: number;
  public qrCode: string;
  public poId: string;
  public equipmentCategoryId: string;
  public purchaseRequestDetailId: string;
  public supplierId: string;
  public productId: string;
  public warehouseId: string;
  public equipmentId?: string;
  public supplier?: Supplier;
  public supplierEquipment?: SupplierEquipment;
  public squipmentCategory?: EquipmentCategory;
  product: Product;
}
