import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { AsyncPipe, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import vi from '@angular/common/locales/vi';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { OrgchartModule } from '@dabeng/ng-orgchart';
import { ComponentShareModule } from '@intranet/lib/components/component-share.module';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { PushMessagingService } from '@intranet/lib/services/push-messaging.service';
import { UserReducer } from '@intranet/lib/states/reducers/login.reducer';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LightboxModule } from 'ng-gallery/lightbox';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgkParticlejsModule } from 'ngk-particlejs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DndModule } from 'ngx-drag-drop';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { OrgChartPageComponent } from 'projects/intranet/front/pages';
import { EntryAdminModule } from 'projects/intranet/hrm-admin/src/app/entry.module';
import { AppInjector, CommonService, StyleResolver } from 'projects/intranet/hrm-lib';
import { LayoutModule } from 'projects/intranet/hrm-lib/lib/layouts/layout.module';
import { PackageShareModule } from 'projects/intranet/hrm-lib/lib/share';
import { UserEntryModule } from 'projects/intranet/hrm-user/user-entry.module';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoadingAllPageComponent } from './loading-all-page/loading-all-page.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotPermissionComponent } from './not-permission/not-permission.component';
import { ReviewFormMemberClientComponent } from './review-form-member-client/review-form-member-client.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';

registerLocaleData(vi);
const dbConfig: DBConfig = {
  name: 'internetdb',
  version: 1,
  objectStoresMeta: [
    {
      store: 'purchase_order',
      storeConfig: { keyPath: 'localId', autoIncrement: true },
      storeSchema: [
        { name: 'name', keypath: 'name', options: { unique: false } },
        { name: 'assetTypeId', keypath: 'assetTypeId', options: { unique: false } },
        { name: 'quantity', keypath: 'quantity', options: { unique: false } },
        { name: 'price', keypath: 'price', options: { unique: false } },
        { name: 'reason', keypath: 'reason', options: { unique: false } },
        { name: 'assetTypeName', keypath: 'assetTypeName', options: { unique: false } },
        { name: 'isDeleted', keypath: 'isDeleted', options: { unique: false } },
        { name: 'id', keypath: 'id', options: { unique: false } },
      ],
    },
  ],
};

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    NotPermissionComponent,
    OrgChartPageComponent,
    ForgotPasswordComponent,
    UpdatePasswordComponent,
    ReviewFormMemberClientComponent,
    LoadingAllPageComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    StoreModule.forRoot({ users: UserReducer }),
    NgxIndexedDBModule.forRoot(dbConfig),
    HttpClientModule,
    BrowserAnimationsModule,
    EntryAdminModule,
    UserEntryModule,
    NgkParticlejsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PackageShareModule,
    DndModule,
    AppRoutingModule,
    LayoutModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgHttpLoaderModule.forRoot(),
    AngularFireMessagingModule,
    AngularFireAnalyticsModule,
    OrgchartModule,
    ReactiveFormsModule,
    ComponentShareModule,
    LightboxModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgxExtendedPdfViewerModule,
  ],
  providers: [
    AppConfig,
    CommonService,
    PushMessagingService,
    PubSubService,
    DeviceDetectorService,
    AsyncPipe,
    StyleResolver,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injectors: Injector) {
    AppInjector.setInjector(this.injectors);
  }
}
