<div class="widget-attendance-personal">
  <div class="summary">
    <div class="summary-item">
      <div class="summary-item-title">Missing hours {{dataMember.year || 'yearly'}}</div>
      <div class="summary-item-content" [ngClass]="{
        'text-danger' : dataMember.totalMissingHoursYearly < 0,
        'text-success' : dataMember.totalMissingHoursYearly >= 0
      }">
        <ng-container *ngIf="dataMember.totalMissingHoursYearly < 0">
          {{dataMember.totalMissingHoursYearly}}
          <i nz-icon nzType="info-circle" nzTheme="outline" nz-popover [nzPopoverContent]="missingHourDetailsTpl" nzPopoverTrigger="click" class="cursor-pointer"></i>
        </ng-container>

        <ng-container *ngIf="dataMember.totalMissingHoursYearly >= 0">
          <i nz-icon nzType="check-circle" nzTheme="outline" class="cursor-pointer" (click)="searchYear()"
            nz-tooltip [nzTooltipTitle]="'Click to search timesheet in ' + dataMember.year" nzTooltipPlacement="right"></i>
        </ng-container>
      </div>
    </div>
    <div class="summary-item">
      <div class="summary-item-title">Off-hours</div>
      <div class="summary-item-content">{{dataMember.totalLeaveHours}}</div>
    </div>
    <div class="summary-item">
      <div class="summary-item-title">Difference</div>
      <div class="summary-item-content">
        <span class="text-danger" nz-popover nzPopoverTrigger="click" [nzPopoverContent]="explainIsExceptFieldTemplate" *ngIf="dataMember.hoursGap < 0">
        {{dataMember.hoursGap}}
        <i nz-icon nzType="info-circle" nzTheme="outline" class="cursor-pointer"></i>
        <ng-template #explainIsExceptFieldTemplate>
          {{dataMember.totalRealHoursWorking}} - {{dataMember.totalHoursWorkingSetting}} (Accumulated hours - Standard hours)
        </ng-template>
        </span>
        <span *ngIf="dataMember.hoursGap >= 0"><i nz-icon nzType="check-circle" nzTheme="outline" class="text-success"></i></span>
      </div>
    </div>
  </div>

</div>

<ng-template #missingHourDetailsTpl>
  <div class="widget-attendance-personal monthly-missing-hours">
    <div class="row-title">
      <div class="column">Month</div>
      <div class="column">Missing hours</div>
    </div>
    <div class="scroll">
      <div *ngFor="let mmh of dataMember.missingHoursMonthlyData" class="row-content" (click)="searchMonth(mmh.month, mmh.year)">
        <div class="column">{{mmh.year}}/{{mmh.month < 10 ? '0' + mmh.month : mmh.month}} <i nz-icon nzType="double-right" nzTheme="outline" class="search"></i></div>
        <div class="column" [ngClass]="{
          'text-danger' : mmh.remainingHours < 0
        }">{{mmh.remainingHours}}</div>
      </div>
    </div>
    <div class="row-whole-year">
      <div class="column" (click)="searchYear()">Search missing hours in
        {{dataMember.year || 'this year'}}</div>
    </div>
  </div>
</ng-template>
