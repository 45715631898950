import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MemberDto } from '@intranet/lib/data-access';
import { CommonHelper, UrlConstant } from '@intranet/index';

@Component({
  selector: 'app-display-member-template',
  templateUrl: './display-member-template.component.html',
  styleUrls: ['./display-member-template.component.scss']
})
export class DisplayMemberTemplateComponent implements OnInit {
  @Input() memberData: MemberDto[];
  @Input() template?: TemplateRef<void>;
  @Input() additionalTemplateClass?: string;

  @Input() isPagination = false;
  @Input() totalItems = 0;
  @Input() loadMoreButtonName = '';
  @Output() loadMoreEvent = new EventEmitter<any>();

  @Input() maxHeight = '300px';
  @Input() navigateMember = true;
  @Input() isNewsFeedPage = false;

  urlConstant = UrlConstant;
  isPhoneView = false;

  constructor() { }

  ngOnInit() {
    this.isPhoneView = CommonHelper.isPhoneView();
  }

  openInformation(item) {
    if (this.navigateMember) {
      const url = this.urlConstant.PROFILE_PAGE + '?employeeId=' + item.employeeId;
      window.open(
        url,
        '_blank'
      );
    }
  }

  loadMore() {
    this.loadMoreEvent.emit();
  }
}
