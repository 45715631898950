import { Component, OnInit, Input } from '@angular/core';
import { ReactionService, Reaction, ReactionComment } from '@intranet/lib/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UrlConstant } from '@intranet/index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-feed-reaction-modal',
  templateUrl: './news-feed-reaction-modal.component.html',
  styleUrls: ['./news-feed-reaction-modal.component.scss']
})
export class NewsFeedReactionModalComponent implements OnInit {
  @Input() commentId: string;
  @Input() feedId: string;

  UrlConstant = UrlConstant;
  activeModal: NgbActiveModal;
  reactions: Reaction[] = [];
  reactionComments: ReactionComment[] = [];

  isLoading = false;
  constructor(
    private router: Router,
    modalRef: NgbActiveModal,
    private reactionService: ReactionService
  ) {
    this.activeModal = modalRef;
  }

  async ngOnInit() {
    if (this.feedId) {
      this.isLoading = true;
      this.reactionService.getByFeedId(this.feedId).then((res: Reaction[]) => {
        this.isLoading = false;
        if (res) {
          this.reactions = res;
        }
      }).finally(() => {
        this.isLoading = false;
      });
    }
    else if (this.commentId) {
      this.isLoading = true;
      this.reactionService.getByCommentId(this.commentId).then((res: ReactionComment[]) => {
        this.isLoading = false;
        if (res) {
          this.reactionComments = res;
        }
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }


  closeModal() {
    this.activeModal.close(null);
  }

  onNavigateUserProfile(item: ReactionComment) {
    this.closeModal();
    this.router.navigate([UrlConstant.PROFILE_PAGE], { queryParams: { employeeId: item.employeeId } });
  }
}
