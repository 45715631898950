<modal-header [title]="title" [activeModal]="activeModal">
  <ng-template #title>
    <h4 *ngIf="!employee.newcomerNewsFeedId || employee.newcomerNewsFeedId === Constants.EMPTY_GUID">
      Create newcomer post for {{ employee.name }}
    </h4>
    <h4 *ngIf="employee.newcomerNewsFeedId && employee.newcomerNewsFeedId !== Constants.EMPTY_GUID">
      Update newcomer post for {{ employee.name }}
    </h4>
  </ng-template>
</modal-header>
<form (ngkSubmit)="submit()" #frm="ngForm" [ngkForm]="true">
  <modal-body>
    <!-- <div class="form-row">
      <div class="form-group col-xl-12" *ngIf="leaveRequest.employeeName">
        <label>Employee Name</label>
        <input type="text" class="form-control" name="pos-name" [(ngModel)]="leaveRequest.employeeName" disabled>
      </div>
    </div> -->
    <div class="form-row">
      <div class="form-group col-xl-4">
        <label>Avatar</label>
        <p>
          <app-avatar [avatarSource]="employee.avatarUrl"></app-avatar>
        </p>
      </div>
      <div class="form-group col-xl-4">
        <label>Full name</label>
        <p>{{ employee.name }}</p>
      </div>
      <div class="form-group col-xl-4">
        <label>Joined date</label>
        <p [innerHTML]="employee.joinedDate | date : 'dd/MM/yyyy'"></p>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-xl-4">
        <label>Social account</label>
        <p [innerHTML]="employee.socialAccount"></p>
      </div>
      <div class="form-group col-xl-4">
        <label>Email address</label>
        <p [innerHTML]="employee.email"></p>
      </div>
      <div class="form-group col-xl-4">
        <label>Departments</label>
        <div>
          <nz-tag [nzColor]="'geekblue'" class="ml-auto" *ngFor="let department of employee.groupDepartments">
            <span>{{ department.name }}</span>
          </nz-tag>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-xl-4">
        <label>Position</label>
        <p [innerHTML]="employee.positionTitle"></p>
      </div>
      <div class="form-group col-xl-4"></div>
      <div class="form-group col-xl-4"></div>
    </div>
    <div class="form-row">
      <div class="form-group col-xl-12">
        <label>Self introduction</label>
        <textarea class="form-control" name="pos-intro" required [(ngModel)]="employee.newcomerSelfIntroduction"></textarea>
      </div>
    </div>
  </modal-body>
  <modal-footer>
    <button type="button" class="btn btn-white" (click)="activeModal.close()">
      <i class="far fa-times-circle mr5"></i> Close
    </button>
    <button type="button" class="btn btn-primary" (click)="submit()"><i class="fas fa-save mr5"></i> Submit</button>
  </modal-footer>
</form>
