import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { CalendarView, CalendarEvent, CalendarDateFormatter } from 'angular-calendar';
import { ModalService } from '@intranet/index';
import * as moment from 'moment';
import { CalendarService } from '@intranet/lib/data-access';
import { CustomDateFormatter } from '../../custom-date-formatter.provider';
import { UrlConstant } from '@intranet/index';

@Component({
  selector: 'app-shared-calendar',
  templateUrl: './shared-calendar.component.html',
  styleUrls: ['./shared-calendar.component.scss'],
  styles: [],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class SharedCalendarComponent implements OnInit {
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = moment().startOf('day').toDate();
  refresh: Subject<any> = new Subject();
  _events: CalendarEvent[];
  _filterEventTypes: any;
  _classEvents: any;

  @Input() set events(data: CalendarEvent[]) {
    this._events = [];
    data.map(item => {
      this._events.push(Object.create(item));
    });
    this._classEvents = {};
    this._filterEventTypes = {};

    const calendarEventTypes = {};

    this._events.map(item => {
      let loop = new Date(item.start);
      while (loop <= item.end) {
        const currentDate = loop.toDateString();

        if (!this._classEvents[currentDate]) {
          this._classEvents[currentDate] = [];
        }
        this._classEvents[currentDate].push(item.cssClass);

        if (!this._filterEventTypes[currentDate]) {
          this._filterEventTypes[currentDate] = [];
          calendarEventTypes[currentDate] = {};
        }

        if (item.color && !calendarEventTypes[currentDate][item.color.primary]) {
          calendarEventTypes[currentDate][item.color.primary] = 1;
          this._filterEventTypes[currentDate].push(item);
        }
        const newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }
    });

    this._events.map(item => {
      let loop = new Date(item.start);
      while (loop <= item.end) {
        if (!this._classEvents[loop.toDateString()]) {
          this._classEvents[loop.toDateString()] = [loop.toDateString()];
        }
        this._classEvents[loop.toDateString()].push(item.cssClass);
        const newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }
    });
  }

  @Output()
  dayClickedEvent = new EventEmitter<any>();

  @Output()
  viewChangedEvent = new EventEmitter<any>();

  urlConstant = UrlConstant;
  constructor(private modalSvc: ModalService, private calendarService: CalendarService) {
  }

  ngOnInit() {
  }

  getClassFromEvent(day: any) {
    const classes = [];
    if (day.events && day.events.length > 0) {
      day.events.map(event => {
        classes.push(event.cssClass ? event.cssClass : '');
      });
    }
    return classes;
  }

  dayClicked(event): void {
    if (this.viewDate.getMonth() !== event.day.date.getMonth()) {
      this.viewDate = event.day.date;
    } else {
      this.viewDate = event.day.date;
    }
    this.dayClickedEvent.emit(event);
  }

  onViewDateChange(date) {
    this.viewDate = date;
    this.viewChangedEvent.emit(date);
  }
}
