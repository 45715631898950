import { Injectable } from '@angular/core';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseHistoryService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/purchase-request';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getAllByPurchaseRequestId(id: string): Promise<any> {
    const url = this.getUrlByPurchaseRequestId(id);

    return this.httpClient.get(url);
  }

  private getUrlByPurchaseRequestId(id: string) {
    return `${this.hostUrl}/${id}/histories`;
  }
}
