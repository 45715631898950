<div class="rt-panel-item">

  <div class="row align-items-center title-panel">
    <div class="col">
      <p>Search</p>
    </div>
  </div>
  <div class="rt-panel-item-content search-result-content">
    <nz-spin [nzSpinning]="isLoading" class="spinner-wrapper">
      <nz-list *ngIf="feeds && feeds.length > 0" nzBordered nzHeader="Posts">
        <nz-list-item *ngFor="let item of feeds">
          <div class="list-item-wrapper">
            <div><img src="assets/images/icons/post.svg" /></div>
              <div class="result-link" [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.DETAIL), item.id]">
                <p appHighlight [content]="item.title" [searchTerm]="searchData.keyWord" [caseSensitive]="true">
                </p>
              </div>
            </div>
        </nz-list-item>
      </nz-list>
      <nz-list *ngIf="annoucements && annoucements.length > 0" nzBordered nzHeader="Announcements">
        <nz-list-item *ngFor="let item of annoucements">
          <div class="list-item-wrapper">
            <div><img src="assets/images/icons/annoucements.svg" /></div>
              <div class="result-link" [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.DETAIL), item.id]">
                <p appHighlight [content]="item.title" [searchTerm]="searchData.keyWord" [caseSensitive]="true">
                </p>
              </div>
            </div>
        </nz-list-item>
      </nz-list>
      <nz-list *ngIf="events && events.length > 0" nzBordered nzHeader="Events">
        <nz-list-item *ngFor="let item of events">
          <div>
            <img src="assets/images/icons/events.svg" />
            </div>
            <div class="result-link" [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.DETAIL), item.id]">
              <p appHighlight [content]="item.title" [searchTerm]="searchData.keyWord" [caseSensitive]="true">
              </p>
            </div>
        </nz-list-item>
      </nz-list>
      <nz-list *ngIf="bookings && bookings.length > 0" nzBordered nzHeader="Meetings">
        <nz-list-item *ngFor="let item of bookings">
          <div class="list-item-wrapper">
            <div><img src="assets/images/icons/bookings.svg" /></div>
              <div class="result-link" [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.DETAIL), item.id]">
                <p appHighlight [content]="item.title" [searchTerm]="searchData.keyWord" [caseSensitive]="true">
                </p>
              </div>
            </div>
        </nz-list-item>
      </nz-list>
      <nz-list *ngIf="users && users.length > 0" nzBordered nzHeader="Users">
        <nz-list-item *ngFor="let item of users">
          <div class="list-item-wrapper">
            <div class="icon">
              <app-avatar [size]="18" [avatarSource]="item.avatarUrl"></app-avatar>
            </div>
            <div class="over-search-item result-link" [routerLink]="[urlConstant.PROFILE_PAGE]" [queryParams]="{ employeeId : item.employeeId}">
              <p appHighlight [content]="item.fullName" [searchTerm]="searchData.keyWord" [caseSensitive]="true">
              </p>
            </div>
          </div>
        </nz-list-item>
      </nz-list>
      <nz-list *ngIf="departments && departments.length > 0" nzBordered nzHeader="Departments">
        <nz-list-item *ngFor="let item of departments">
          <div class="list-item-wrapper">
            <div class="icon"><img src="assets/images/icons/avatar-default.svg" /></div>
              <div class="over-search-item result-link" [routerLink]="[urlConstant.mapGroup(urlConstant.GroupUrl.NEWSFEED)]" [queryParams]="{ groupId :item.id}">
                <p appHighlight [content]="item.name" [searchTerm]="searchData.keyWord" [caseSensitive]="true">
              </div>
            </div>
        </nz-list-item>
      </nz-list>
      <nz-list *ngIf="policies && policies.length > 0" nzBordered nzHeader="Policy">
        <nz-list-item *ngFor="let item of policies">
          <div class="list-item-wrapper">
            <div class="icon"><img src="assets/images/front/shortcut/policy-black.svg" width="20px" height="20px"/></div>
              <div class="over-search-item result-link" [routerLink]="[urlConstant.POLICY_PAGE]"
                [queryParams]="{policyId: item.policyId, policyDetailId: item.policyDetailId}">
                <p appHighlight [content]="item.title" [searchTerm]="searchData.keyWord" [caseSensitive]="true">
              </div>
            </div>
        </nz-list-item>
      </nz-list>
    </nz-spin>
  </div>
</div>
