import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { Loading } from '../settings/services/loading';
import { environment } from '../environments/environment';
import { AppConfig } from '../environments/config/appConfig';
import { DomSanitizer } from '@angular/platform-browser';
@Directive({
  selector: 'img[url],img[src]',
  // tslint:disable-next-line: use-host-property-decorator
  host: {
    '(error)': 'updateUrl()',
    '[src]': 'sUrl',
  },
})
export class ImgDirective {
  // tslint:disable-next-line: deprecation
  constructor(private el: ElementRef, private renderer2: Renderer2, private sanitizer: DomSanitizer) {
    this.el.nativeElement.style.display = 'none';
    this.renderer2.setAttribute(this.el.nativeElement, 'crossorigin', 'anonymous');
    if (this.el.nativeElement.attributes.loading) {
      Loading.showImg(this.el.nativeElement.parentElement);
    }
  }
  public defaultImg = 'assets/images/no-image2.png';
  private cdnServer = AppConfig.settings.CDN_URL + '/cdn/file/';
  private ztype: string;
  sUrl: any;
  private localUrl: string;

  @Input()
  set type(value: string) {
    this.ztype = value;
    if (this.localUrl) {
      this.checkUrl(this.localUrl);
    }
  }

  @Input()
  set default(value: string) {
    this.defaultImg = 'assets/images/users/' + value;
  }
  @Input()
  set defaultData(value: string) {
    this.defaultImg = value;
  }
  @Input()
  set url(value: string) {
    this.checkUrl(value);
  }

  @Input()
  set src(value: string) {
    this.localUrl = value;
    if (this.ztype) {
      this.localUrl = undefined;
      this.checkUrl(value);
    } else {
      this.sUrl = value;
      this.el.nativeElement.style.display = 'initial';
    }
  }

  updateUrl() {
    this.sUrl = this.defaultImg;
    this.el.nativeElement.style.display = 'initial';
    setTimeout(() => {
      Loading.hide(this.el.nativeElement.parentElement);
    }, 500);
  }

  checkUrl(value: string) {
    if (this.checkIsBase64(value) || this.ztype === 'base64') {
      this.sUrl = this.sanitizer.bypassSecurityTrustResourceUrl(value);
    } else if (value !== undefined && this.ztype === 'local') {
      this.sUrl = value;
    } else if (!this.ztype) {
      this.sUrl = value;
    } else if (value && !this.checkIsBase64(value)) {
      this.sUrl = this.cdnServer + value;
    } else if (value === undefined) {
      this.sUrl = this.defaultImg;
    }
    this.el.nativeElement.style.display = 'initial';
    setTimeout(() => {
      Loading.hide(this.el.nativeElement.parentElement);
    }, 500);
  }

  checkIsBase64(value: string) {
    try {
      if (value) {
        const index = value.indexOf('data:image');
        return index > -1;
      }
      return false;
    } catch (err) {
      return false;
    }
  }
}
