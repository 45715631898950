import { Constants } from '@intranet/lib/config/constants';
import { BaseModel } from './base-model';
import { PurchaseOrderDetail } from './purchase-order-detail';

export class PurchaseOrder extends BaseModel {
  title: string;
  ownerId: string;
  departmentId: string;
  equipmentCategoryId?: string;
  equipmentCategoryName?: string;
  equipmentId?: string;
  equipmentName?: string;
  purchaseRequestId: string;
  status: number;
  totalAmount: number;
  groupName: string;
  groupDepartments: string;
  purchaseOrderNo: string;
  receiptNumber: string;
  receiptAmount: string;
  receiptDate: Date;
  purchaseRequestNo: string;
  details: PurchaseOrderDetail[];
  cancelledBy: string;
  cancelledByName: string;
  approvalBy: string;
  approvalByName: string;
  approvalDate: Date;
  cancelledDate: Date;
  expaned: boolean;
  requestType?: number;
  packageId?: string;
  invoiceId?: string;
  supplierName?: string;
  attachmentFileName?: string;
  reason?: string;
  attachmentFile?: any;
  attachmentIds?: any;
  purchasePoStatus?: number;
  constructor() {
    super();
    this.details = [];
    this.requestType = Constants.REQUEST_TYPE_ENUM.Normal;
  }
}
