import { Injectable } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  monthTooltip(event: CalendarEvent): string {
    return;
  }

  weekTooltip(event: CalendarEvent): string {
    return;
  }

  dayTooltip(event: CalendarEvent): string {
    return;
  }

  // weekTooltip(event: CalendarEvent, title: string) {
  //   if (event.meta && !event.meta.tmpEvent) {
  //     // return super.weekTooltip(event, title);
  //     return;
  //   }
  // }

  // dayTooltip(event: CalendarEvent, title: string) {
  //   return;
  //   if (event.meta && !event.meta.tmpEvent) {
  //     // return super.dayTooltip(event, title);
  //   }
  // }
}
