<ng-container *ngIf="widget">
  <nz-card [nzBordered]="false" [nzLoading]="loading">
    <div class="widget-header">
      <div class="widget-title">{{widget?.data?.title}}</div>
      <div class="widget-control widget-control-sm-fully">
        <div *ngIf="searchData.isDisplayDataManage">
          <nz-date-picker (ngModelChange)="onChangeFromDate($event)" nzPlaceHolder="From date" nzAllowClear='false' [nzFormat]="dateFormat"
            [ngModel]="searchData.fromDate" [nzShowToday]="true">
          </nz-date-picker>
          <nz-date-picker (ngModelChange)="onChangeToDate($event)" nzPlaceHolder="To date" nzAllowClear='false' [nzFormat]="dateFormat" [ngModel]="searchData.toDate"
            [nzShowToday]="true">
          </nz-date-picker>
        </div>

        <div *ngIf="!searchData.isDisplayDataManage" class="search-personal">
          <nz-select [(ngModel)]="searchData.searchMode" (ngModelChange)="userSearchModeChange()" class="search-mode">
            <nz-option [nzValue]="widgetSearchModeEnum.Month" nzLabel="Month"></nz-option>
            <nz-option [nzValue]="widgetSearchModeEnum.Year" nzLabel="Year"></nz-option>
          </nz-select>
          <nz-date-picker (ngModelChange)="onChangeFromDate($event)" nzPlaceHolder="Select a month or a year" [nzAllowClear]="false" [nzFormat]="userDateFormat"
            [ngModel]="searchData.fromDate" [nzShowToday]="true" [nzMode]="widgetSearchMode" [nzDisabledDate]="userDisableDate" class="search-date">
          </nz-date-picker>
        </div>

        <div class="additional-control" *ngIf="searchData.isDisplayDataManage">
          <nz-select *ngIf="data.isDisplayGroups" (ngModelChange)="modelDepartmentChanged($event)" nzShowSearch nzAllowClear nzPlaceHolder="Select a group"
            [(ngModel)]="searchData.departmentIds">
            <nz-option *ngFor="let option of data.departmentGroups" [nzLabel]="option.name" [nzValue]="option.id">
            </nz-option>
          </nz-select>

          <nz-select (ngModelChange)="modelChanged($event)" nzShowSearch nzAllowClear nzPlaceHolder="Select a user" [(ngModel)]="searchData.userIds" nzServerSearch
            (nzOnSearch)="searchUser($event)">
            <nz-option *ngFor="let userOption of userOptionFilters" [nzLabel]="userOption.fullName" [nzValue]="userOption.id"></nz-option>
          </nz-select>

          <!-- <div *ngIf="isDetail">
            <label nz-checkbox (ngModelChange)="modelMissingHoursChanged($event)" [(ngModel)]="searchData.isMissingHours">Missing hours</label>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="data" class="col-12 pie-chart-custom pie-leave">
        <div class="widget-content" *ngIf="data.widgetAttendanceDetails" [id]="widgetContentId">
          <p class="leave-box-head" [ngStyle]="{'left': legendLeftPosition + 'px'}">
            <span class="leave-left">{{data.numberLeaveRequest}}</span>
            <span class="leave-right">
            <span class="leave-title">Leave requests (days)</span>
            <!-- <span class="leave-description d-block">{{searchData.fromDate}}-{{searchData.toDate}} </span> -->
            </span>
          </p>
          <ngx-charts-pie-chart [view]="data.widgetAttendanceDetails.view" [scheme]="data.widgetAttendanceDetails.colorScheme"
            [results]="data.widgetAttendanceDetails.chartDatas" [legend]="data.widgetAttendanceDetails.showLegend"
            [legendPosition]="data.widgetAttendanceDetails.legendPosition" [labels]="data.widgetAttendanceDetails.showLabels"
            [doughnut]="data.widgetAttendanceDetails.isDoughnut" [arcWidth]="data.widgetAttendanceDetails.arcWidth" (window:resize)="onResizePie($event)"
            [animations]="false" (select)="pieChartSelect($event)">
          </ngx-charts-pie-chart>

          <div class="leave-box-footer" [ngStyle]="{'left': legendLeftPosition + 'px'}" [ngClass]="{ 'personal': !searchData.isDisplayDataManage }">
            <ul>
              <li *ngIf="data.avgCheckInTime" class="leave-box-avg">
                <span class="d-block avg-title">Average check-in time</span>
                <span class="d-block avg-time">{{data.avgCheckInTime}}</span>
              </li>
              <li *ngIf="data.avgCheckOutTime" class="leave-box-avg">
                <span class="d-block avg-title">Average check-out time</span>
                <span class="d-block avg-time">{{data.avgCheckOutTime}}</span>
              </li>
            </ul>
            <div *ngIf="!searchData.isDisplayDataManage && data?.dataMember?.[0]" class="personal-data">
              <app-widget-attendance-personal [dataMember]="data.dataMember[0]"></app-widget-attendance-personal>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data && searchData.isDisplayDataManage">
      <app-widget-attendance-member [searchData]="searchData" [data]="data.dataMember"></app-widget-attendance-member>
    </div>
  </nz-card>
</ng-container>
