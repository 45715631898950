import { Injectable } from '@angular/core';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';
import { HttpClientService } from '@intranet/lib/http/http-client';

@Injectable({
    providedIn: 'root'
})
export class ReviewViewTokenService {
    constructor(private httpClient: HttpClientService) { }

    getFormMemberByToken(tokenId: string) {
        const url = ApiEndpoint.Review.ViewToken.GetFormMemberByToken(tokenId);
        return this.httpClient.getObservable(url);
    }
}