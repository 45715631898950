import { Component, OnInit, Input } from '@angular/core';
import { UserModel, CDNService } from '@intranet/lib/data-access';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() size = 40;
  @Input() sizePercent;
  @Input() round = true;
  @Input() user: UserModel;
  @Input() textSizeRatio = 3;
  @Input() bgColor = 'green';
  @Input() bgImageColor = 'transparent';
  @Input() fgColor = '#FFF';
  @Input() borderColor: string | undefined;
  @Input() style: any = {};
  @Input() spanStyle: any = {};
  @Input() cornerRadius = 0;
  @Input() entityType: string | null;
  @Input()
  set avatarUser(user: UserModel | null) {
    if (user) {
      this._avatarSource = user.avatarUrl;
      this.avatarText = user.fullName;
      this.avatarId = user.id;
      this.src = this._avatarSource && this._avatarSource.length > 0 ? this.cdnService.getFileUrl(this._avatarSource) : null;
    }
  }

  @Input() avatarId: string | null;
  @Input() avatarText: string | null;
  private _avatarSource: string | null;

  get avatarSource(): string {
    return this._avatarSource;
  }
  @Input() set avatarSource(value: string) {
    this._avatarSource = value;
    this.src = this._avatarSource && this._avatarSource.length > 0 ? this.cdnService.getFileUrl(this._avatarSource) : null;
  }

  public avatarStyle: any = {};
  public avatarImageStyle: any = {};
  public avatarTextStyle: any = {};
  public containerStyle: any = {};
  public placeholder: string | null = null;
  public src: string | null = null;

  constructor(private cdnService: CDNService) { }
  ngOnInit() {
    this.avatarStyle = this.getInitialsStyle(false);
    this.avatarImageStyle = this.getInitialsStyle(true);

    this.placeholder = this.avatarText
      ? this.avatarText
        .match(/\b(\w)/g)
        .join('')
        .substr(0, 2)
      : 'NA';
    // get avatar by image url or imageId directly
    if (!this.entityType) {
      this.src = this._avatarSource && this._avatarSource.length > 0 ? this.cdnService.getFileUrl(this._avatarSource) : null;
    } else {
      // get by entity infor
      this.src = this.cdnService.getFileUrlByEntity(this._avatarSource, this.entityType);
    }

    this.containerStyle = {
      width: this.size + 'px',
      height: this.size + 'px',
    };
    if (this.sizePercent) {
      this.containerStyle = {
        width: this.sizePercent + '%',
        height: this.sizePercent + '%',
      };
    }
  }

  private getInitialsStyle(isImg: boolean): any {
    if (this.sizePercent) {
      if (this._avatarSource) {
        return {
          textAlign: 'center',
          borderRadius: this.round ? '100%' : this.cornerRadius + 'px',
          border: this.borderColor ? '1px solid ' + this.borderColor : '',
          textTransform: 'uppercase',
          color: this.fgColor,
          backgroundColor: isImg ? this.bgImageColor : this.bgColor,
          fontSize: this.sizePercent * 3 + '%',
          lineHeight: this.size + 'px',
        };
      }
      return {
        textAlign: 'center',
        borderRadius: this.round ? '100%' : this.cornerRadius + 'px',
        border: this.borderColor ? '1px solid ' + this.borderColor : '',
        textTransform: 'uppercase',
        color: this.fgColor,
        backgroundColor: isImg ? this.bgImageColor : this.bgColor,
        fontSize: this.sizePercent * 3 + '%',
        lineHeight: this.size + 'px',
        ...this.style,
      };
    }
    return {
      textAlign: 'center',
      borderRadius: this.round ? '100%' : this.cornerRadius + 'px',
      border: this.borderColor ? '1px solid ' + this.borderColor : '',
      textTransform: 'uppercase',
      color: this.fgColor,
      backgroundColor: isImg ? this.bgImageColor : this.bgColor,
      fontSize: Math.floor(this.size / this.textSizeRatio) + 'px',
      lineHeight: this.size + 'px',
      ...this.style,
    };
  }
}
