import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService, Loading } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class TicketTypeService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/ticket-type';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getById(guidId: string, successCallback: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = 'get-by-id';
    const params = {
      noLoading: true,
      guidId,
    };

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }
}
