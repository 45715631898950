import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { NewProjectModel, Department, DepartmentService, MetaDataPaging, ProjectStatusEnum, ProjectService, UserService, DropdownDto } from '@intranet/lib/data-access';
import { Constants } from '@intranet/index';

@Component({
  selector: 'app-new-project-modal',
  templateUrl: './new-project-modal.component.html',
  styleUrls: ['./new-project-modal.component.scss'],
})
export class NewProjectModalComponent implements OnInit {
  @ViewChild('frm', { static: false }) public formGroup: NgForm;
  @Input() projectId: string;
  activeModal: NgbActiveModal;
  newProject: NewProjectModel = new NewProjectModel();
  isLoading = false;
  departments: Department[];

  users: DropdownDto[] = [];
  projectMembers: DropdownDto[] = [];
  projectStatusList: ProjectStatusEnum[] = [];
  ProjectStatusEnum = ProjectStatusEnum;
  memberId: string;
  visible = false;

  constructor(modalRef: NgbActiveModal, private depSvc: DepartmentService, private projectSvc: ProjectService, private userSvc: UserService) {
    this.activeModal = modalRef;
  }

  async ngOnInit() {
    await this.initData();
  }

  closeModal() {
    this.activeModal.close(null);
  }

  onSubmit() {
    if (!this.projectId && this.projectMembers && this.projectMembers.length) {
      this.newProject.userMemberIds = this.projectMembers.map(p => p.value);
    }

    if (!this.projectId) {
      this.addNewProject();
    } else {
      this.updateExistingProject();
    }
  }

  addNewProjectMember() {
    if (this.projectMembers.every(p => p.value !== this.memberId)) {
      this.projectMembers.push(this.users.find(p => p.value === this.memberId));
    }
    this.memberId = null;
    this.visible = false;
  }

  removeProjectMember(deleteMemberId: string) {
    this.projectMembers = this.projectMembers.filter(p => p.value !== deleteMemberId);
  }

  private addNewProject() {
    if (this.formGroup.invalid) {
      return;
    }
    this.isLoading = true;
    this.projectSvc.add(
      this.newProject,
      () => {
        this.activeModal.close(this.newProject);
      },
      null,
      () => {
        this.isLoading = false;
      },
    );
  }

  private updateExistingProject() {
    if (this.formGroup.invalid) {
      return;
    }

    this.isLoading = true;
    this.projectSvc.update(
      this.newProject,
      () => {
        this.activeModal.close(this.newProject);
      },
      null,
      () => {
        this.isLoading = false;
      },
    );
  }

  private async initData() {
    const paging = new MetaDataPaging({ pageNumber: 1 });
    const fullUserList = await this.userSvc.getList(paging);

    this.depSvc.getAll(paging, (resp: Department[]) => {
      this.departments = resp;
    });

    if (this.projectId) {
      this.projectSvc.getById(this.projectId, (pro: NewProjectModel) => {
        this.newProject = pro;
      });
    }

    this.projectStatusList = [ProjectStatusEnum.NEW, ProjectStatusEnum.APPROVAL, ProjectStatusEnum.COMMITED];

    this.users = fullUserList.map(u => {
      const result = new DropdownDto();
      result.value = u.id;
      result.text = u.fullName;
      return result;
    });
  }
}
