import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { MenuTypeEnum } from '../models/enums/menu-type.enum';
import { SecurityHelper } from '@intranet/lib/helpers';

@Injectable({
  providedIn: 'root',
})
export class AppMenuService extends BaseService {
  static menuController = 'menu';
  hostUrl: string = AppConfig.settings.API_URL + '/api/menu';
  menuTypeData = [
    {
      type: MenuTypeEnum.TopMenu,
      fn: () => this.getTopMenuForCurrentUser(),
      sessionKey: 'topMenu',
    },
    {
      type: MenuTypeEnum.UserSideMenu,
      fn: () => this.getSideMenuForCurrentUser(),
      sessionKey: 'sideMenu',
    }, {
      type: MenuTypeEnum.AdminMainMenu,
      fn: () => this.getAdminMainMenuForCurrentUser(),
      sessionKey: null,
    }, {
      type: MenuTypeEnum.ClientHeadMenu,
      fn: () => this.getClientHeadMenuForCurrentUser(),
      sessionKey: 'clientHeadMenu',
    },
  ];

  currentUser: any;

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getTopMenuForCurrentUser(): Promise<any> {
    const url = 'top-menu';
    return this.getByUrl(url, null, null);
  }

  getSideMenuForCurrentUser(): Promise<any> {
    const url = 'side-menu';
    return this.getByUrl(url, null, null);
  }

  getAdminMainMenuForCurrentUser(): Promise<any> {
    const url = 'admin-main-menu';
    return this.getByUrl(url, null, null);
  }

  getClientSideMenuForCurrentUser(): Promise<any> {
    const url = 'client-side-menu';
    return this.getByUrl(url, null, null);
  }

  getClientHeadMenuForCurrentUser(): Promise<any> {
    const url = 'client-head-menu';
    return this.getByUrl(url, null, null);
  }

  getMenuByType(type: MenuTypeEnum) {
    const api = this.menuTypeData.find(m => m.type === type);
    if (api) {
      return api.fn();
    }
    return null;
  }

  loadMenu(menuType: MenuTypeEnum) {
    const cachedMenuKey = this.getCachedMenuKey(menuType);
    if (cachedMenuKey) {
      // Cached menu is in current session.
      return this.loadCachedMenuFromSession(cachedMenuKey);
    }

    return this.getMenuByType(menuType);
  }

  private getCachedMenuKey(menuType: MenuTypeEnum) {
    const cachedMenuType = this.menuTypeData.find(cmt => cmt.type === menuType);
    return cachedMenuType?.sessionKey;
  }

  private loadCachedMenuFromSession(key: string) {
    this.loadCurrentUser();

    const menu = this.currentUser[key];
    if (!menu) {
      const menuType = this.menuTypeData.find(mtd => mtd.sessionKey === key).type;
      return this.getMenuByType(menuType);
    }
    return menu;
  }

  private loadCurrentUser() {
    const authToken = SecurityHelper.getStoreAuthen();
    if (authToken) {
      this.currentUser = authToken;
    }
  }
}
