import { Component, OnInit } from '@angular/core';
import { PasswordModel, UserService } from '@intranet/lib/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseAdminComponent } from '@intranet/lib/components';
import { Loading, Toastr } from '@intranet/index';
import { ApiResponse } from '@intranet/lib/data-access/models/api-response.model';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styles: [],
})
export class ChangePasswordComponent extends BaseAdminComponent implements OnInit {
  user: PasswordModel = new PasswordModel();
  modal: NgbActiveModal;
  passwordConfirmation = '';
  constructor(activeModal: NgbActiveModal, private userService: UserService) {
    super();
    this.modal = activeModal;
    this.user = this.currentUser;
  }

  ngOnInit() {}

  submit() {
    Loading.show();
    const model = {
      userName: this.currentUser.userName,
      Password: this.user.passWord,
      oldPassword: this.user.oldPassword,
      fullName: this.user.fullName,
    };
    this.userService.updatePasswordPersonal(model).then((result: ApiResponse) => {
      Toastr.message(result.id, result.message);
      Loading.hide();
      this.modal.close(true);
    });
  }
}
