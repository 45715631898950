import { Component, OnInit, Input } from '@angular/core';
import { NotificationModel } from '@intranet/lib/data-access/models/notification.model';
import { NotificationEnum, UserService } from '@intranet/lib/data-access';
import { Router } from '@angular/router';
import { WebsiteEvent } from '@intranet/lib/settings';

@Component({
  selector: 'app-front-header-notification',
  templateUrl: './front-header-notification.component.html',
  styleUrls: ['./front-header-notification.component.scss'],
})
export class FrontHeaderNotificationComponent implements OnInit {
  private _list: NotificationModel[];

  get list(): NotificationModel[] {
    return this._list;
  }
  @Input() set list(value: NotificationModel[]) {
    this._list = value;
    this.getNumberRead();
  }
  numberRead = 0;
  constructor(private router: Router, private userService: UserService,
    private websiteEvent: WebsiteEvent) { }

  ngOnInit() { }

  getNumberRead() {
    if (this.list && this.list.length > 0) {
      const data = this.list[0];
      this.numberRead = data.numberRead;
    }
  }
  async onLink(item) {
    await this.userService.updateRead(item);
    if (item.link) {
      window.location.href = item.link;
    }
  }
  viewAll(link) {
    this.router.navigate([link]);
  }

  async markAllReadNotification() {
    await this.userService.updateReadAll(NotificationEnum.TODO);
    this.websiteEvent.emitHeaderNotificationEvent();
  }
}
