
export class DateMonthSelectSettingModel {
  isDisplayTo = false;
  className = '';
}
export class DateMonthSelectModel {
  fromMonth: number;
  fromYear: number;
  toMonth: number;
  toYear: number;
}
