import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { PackageShareModule } from 'projects/intranet/hrm-lib/lib/share';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TranslateModule } from '@ngx-translate/core';
import {
  EmployeeDetailComponent,
  EmployeeHeadComponent,
  EmployeePersonalComponent,
  EmployeeDocumentComponent,
  EmployeeEducationWorkingComponent,
  ContractEmployeeComponent,
} from './pages';
import { CreatePurchaseComponent } from './pages/purchase/create-purchase/create-purchase.component';
import { EmployeeChildrenComponent } from './pages/employee/employee-detail/employee-children/employee-children.component';

@NgModule({
  imports: [CommonModule, NzDrawerModule, PackageShareModule, EditorModule, TranslateModule],
  declarations: [
    CreatePurchaseComponent,
    EmployeeDetailComponent,
    EmployeeHeadComponent,
    EmployeePersonalComponent,
    EmployeeDocumentComponent,
    EmployeeEducationWorkingComponent,
    EmployeeChildrenComponent,
    ContractEmployeeComponent,
  ],
  exports: [
    PackageShareModule,
    TranslateModule,
    CreatePurchaseComponent,
    EmployeeDetailComponent,
    EmployeeHeadComponent,
    EmployeePersonalComponent,
    EmployeeDocumentComponent,
    EmployeeEducationWorkingComponent,
    EmployeeChildrenComponent,
    ContractEmployeeComponent,
  ],
  entryComponents: [],
  providers: [],
})
export class AdminShareModule { }
