import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { CDNService } from './cdn-server.service';
import { Gift } from '../models/gift.model';

@Injectable({
  providedIn: 'root',
})
export class GiftService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/gift';

  constructor(httpClient: HttpClientService, private cdnService: CDNService) {
    super(httpClient);
  }

  async view(searchData: any, scb?: any, fcb?: any, ccb?: any) {
    return await this.getAll(searchData, scb, fcb, ccb);
  }

  async getGift(id: string, scb?: any, fcb?: any, ccb?: any) {
    const url = `get-by-id?id=${id}`;
    return await this.getByUrl(url, scb, fcb, ccb);
  }

  async userView(searchData: any, scb?: any) {
    const url = `view-gifts`;
    return await this.getByUrl(url, searchData, scb);
  }

  getImageUrl(gift: Gift) {
    if (gift && gift.images) {
      gift.imageLocations = [];
      gift.images.forEach(imageId => {
        gift.imageLocations.push(`${this.cdnService.hostUrl}/download/${imageId}`);
      });
    }

    return gift;
  }
}
