import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { WorkTimeService } from '@intranet/lib/data-access';
import { DateHelper } from '@intranet/lib/helpers/dateHelper';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import { SecurityHelper } from '@intranet/lib/helpers/securityHelper';
import { Toastr } from '@intranet/lib/settings/services/toastr';

@Component({
  selector: 'app-work-time',
  templateUrl: './work-time.component.html',
  styleUrls: ['./work-time.component.scss']
})
export class WorkTimeComponent implements OnInit {
  formGroup: FormGroup;

  workTimeToday: any;
  authToken: any;
  get startTime() { return this.formGroup.get('startTime'); }
  get endTime() { return this.formGroup.get('endTime'); }

  constructor(private workTimeService: WorkTimeService) {
    this.authToken = SecurityHelper.getStoreAuthen();
  }

  ngOnInit() {

    this.initForm();
    this.loadWorkTimeToday();
  }

  initForm() {
    this.formGroup = new FormGroup({
      startTime: new FormControl(),
      endTime: new FormControl()
    });
  }

  loadWorkTimeToday() {
    if (this.authToken?.id && this.authToken?.hasEmployee) {
      this.workTimeService.getWorkTimeTodayByUser(this.authToken?.id).subscribe(res => {
        if (res) {
          this.formGroup.patchValue(res);
        }
      }, (err) => { ErrorHelper.displayMessage(err); });
    }
  }

  updateWorkTime() {
    const authToken = SecurityHelper.getStoreAuthen();
    if (authToken?.id) {
      const startTime = DateHelper.formatFullDateToHour(this.startTime.value);
      const endTime = DateHelper.formatFullDateToHour(this.endTime.value);
      const request = {
        startTime,
        endTime,
        userId: authToken?.id,
      };
      this.workTimeService.updateWorkTimeTodayByUser(request).subscribe(
        () => {
          Toastr.success('Update work time successfully');
          this.loadWorkTimeToday();
        },
        (err) => {
          ErrorHelper.displayMessage(err);
        });
    }
  }
}
