import { Component, OnInit } from '@angular/core';
import { SecurityHelper } from 'projects/intranet/hrm-lib';
import { UserModel } from '@intranet/lib/data-access';
@Component({
  selector: 'app-base-layout',
  template: ''
})

export class BaseLayoutComponent implements OnInit {
  currentUser: UserModel;

  constructor(
  ) {

    this.getUserInfo();
  }

  ngOnInit() {

  }

  getUserInfo() {
    const authToken = SecurityHelper.getStoreAuthen();
    if (authToken) {
      this.currentUser = authToken;
    }
  }
}
