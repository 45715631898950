
export enum ApprovalModalDisplayMode {
  Personal,
  Group,
}

export enum LeaveRequestExceptionStatus {
  New = 0,
  Approve = 1,
  Reject = 2,
}
