import { DataSourceType } from '../enums/dashboard.enum';

export class DropdownDto {
  value: string;
  text: string;
}

export class DropdowFilterDto<T> {
  isSelected: boolean;
  value: T;
  text: string;

  constructor(v: T, s: string) {
    this.isSelected = false;
    this.value = v;
    this.text = s;
  }
}

export class DropdownGenericDto<T> extends DropdowFilterDto<T> {
  dataSource: DataSourceType;

  constructor(v: T, s: string, sr: DataSourceType) {
    super(v, s);
    this.dataSource = sr;
  }
}
