import { Component, OnInit, Input } from '@angular/core';
import { WallNewsFeedService, SearchWallNewsFeed, FileExtensionType, CDNService } from '@intranet/lib/data-access';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { PubSubEvent, UrlConstant } from '@intranet/index';

@Component({
  selector: 'app-newsfeed-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
  styles: [],
})
export class AnnouncementsComponent implements OnInit {
  @Input() view: string;
  queryFeeds: SearchWallNewsFeed = new SearchWallNewsFeed();

  announcements: any;
  hasNextPage: boolean;
  pageSize = 5;

  isLoading: boolean;
  urlConstant = UrlConstant;
  FileExtensionType = FileExtensionType;

  constructor(private wallNewsFeedService: WallNewsFeedService, private pubsub: PubSubService,
    private cdnService: CDNService) { }

  ngOnInit() {
    this.hasNextPage = false;
    this.announcements = [];
    this.isLoading = false;

    this.queryFeeds.noLoading = true;
    this.queryFeeds.pageSize = this.pageSize;
    this.queryFeeds.pageNumber = 1;

    this.getAnnoucements();

    this.pubsub.$sub(PubSubEvent.EVENTS.ON_CREATE_ANNOUNCEMENT).subscribe(feed => {
      this.announcements.unshift(feed);
    });
  }

  getAnnoucements() {
    this.isLoading = true;
    this.wallNewsFeedService.getAnnoucements(
      this.queryFeeds,
      (res: any) => {
        res.forEach(announcement => {
          const existedAnnouncement = this.announcements.filter((item: any) => {
            return item.id === announcement.id;
          });

          if (!existedAnnouncement || existedAnnouncement.length === 0) {
            if (announcement.files && announcement.files.lenth > 0) {
              announcement.files.forEach(element => {
                element.url = this.wallNewsFeedService.getFileUrl(element.fileId);
              });
            }
            this.announcements.push(announcement);
          }
        });

        if (res.length < this.pageSize) {
          this.hasNextPage = false;
        } else {
          this.hasNextPage = true;
        }
      },
      error => { },
      () => {
        this.isLoading = false;
      },
    );
  }
}
