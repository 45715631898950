import { ReviewFormAppraiserStatusEnum, ReviewFormAppraiserStatusFormEnum } from '../enums/review-form.enum';
import { CommonDepartmentDto } from '../widget.model';

export class ReviewFormAppraiser {
  id: string;
  userId: string;
  reviewPerformanceFormMemberId: string;
  statusForm: ReviewFormAppraiserStatusFormEnum;
  status: ReviewFormAppraiserStatusEnum;
  departmentGroupId: string;
  departmentGroup: CommonDepartmentDto;
  user?: any;
  isViewOnly: boolean;
  isMainAppraiser: boolean;

  isChecked: boolean;
  isFreezed: boolean;

  constructor(userId: string, formId: string, departmentId: string, departmentName?: string, user?: any, isViewOnly?: boolean, isMainAppraiser: boolean = true) {
    // "departments" 's type is DepartmentUser in BE.
    this.userId = userId;
    this.reviewPerformanceFormMemberId = formId;
    this.status = ReviewFormAppraiserStatusEnum.New;
    this.statusForm = ReviewFormAppraiserStatusFormEnum.New;
    this.departmentGroupId = departmentId;
    this.user = user;
    this.isViewOnly = !!isViewOnly;
    this.isMainAppraiser = !!isMainAppraiser;

    this.departmentGroup = { id: departmentId, name: departmentName };
  }
}
