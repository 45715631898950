import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NewsflashService } from '@intranet/lib/data-access/services/newsflash.service';
import { UserService } from 'projects/intranet/hrm-lib/lib/data-access';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  user: any = {};
  loginModel: any = { UserName: '', PassWord: '' };
  constructor(private router: Router, private userService: UserService, private readonly newsflashService: NewsflashService) {}

  login() {
    this.userService.login(this.loginModel).then(() => this.newsflashService.shouldGetNewsflashConfirmation$.next());
  }

  forgotPassword() {
    this.router.navigateByUrl('/forgot-password');
  }
}
