import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseLayoutComponent } from '../../base-layout/base-layout.component';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { slideInAnimation } from '@intranet/lib/directives/animation';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { Constants, PubSubEvent, UrlConstant } from '@intranet/index';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-front-profile-layout',
  templateUrl: './front-profile-layout.component.html',
  styleUrls: ['./front-profile-layout.component.scss'],
  animations: [slideInAnimation],
})
export class FrontProfileLayoutComponent extends BaseLayoutComponent implements OnInit, OnDestroy {
  currentUser = this.currentUser;
  year = new Date().getFullYear();
  systemName: string;
  isExpaned = false;
  isRightExpanded = false;
  showLeftPanel = false;
  showRightPanel = false;
  subscriptions: Subscription[] = [];

  get isMobileView() {
    return window.innerWidth <= Constants.SmallViewMaxWidthInPixel;
  }

  get sidebarId() {
    return this.isExpaned || !this.isMobileView ? 'rt-left-panel' : '';
  }

  constructor(private pubsub: PubSubService, private router: Router) {
    super();
    this.systemName = AppConfig.settings.App_Name;
    this.isExpaned = localStorage.getItem('isExpanedFront') ? true : false;
    this.isRightExpanded = localStorage.getItem('isRightExpanded') ? true : false;
  }

  ngOnInit() {
    // this.subscriptions.push(this.pubsub.$sub(PubSubEvent.EVENTS.TOGGLE_LEFT_MENU).subscribe(result => {
    //   this.isExpaned = result;
    // }));

    // this.subscriptions.push(this.pubsub.$sub(PubSubEvent.EVENTS.TOGGLE_RIGHT_MENU).subscribe(result => {
    //   this.isRightExpanded = result;
    // }));

    // this.subscriptions.push(this.pubsub.$sub(PubSubEvent.EVENTS.TOGGLE_LEFT_PANEL).subscribe(result => {
    //   this.toggleLeftPanel(result);
    // }));

    // this.subscriptions.push(this.pubsub.$sub(PubSubEvent.EVENTS.TOGGLE_RIGHT_PANEL).subscribe(result => {
    //   this.toggleRightPanel(result);
    // }));

    // this.subscriptions.push(this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.pubsub.$pub(PubSubEvent.EVENTS.TOGGLE_LEFT_PANEL, false);
    //     this.pubsub.$pub(PubSubEvent.EVENTS.TOGGLE_RIGHT_PANEL, false);
    //   }
    // }));
  }

  onChangeSliderLeftActive(event) {
    this.isExpaned = event;
  }

  toggleLeftPanel(event) {
    this.showLeftPanel = event;
  }

  toggleRightPanel(event) {
    this.showRightPanel = event;
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
