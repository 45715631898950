<div class="comment-item-container">
    <div class="comment-avatar btn-link" [routerLink]="[urlConstant.PROFILE_PAGE]"
      [queryParams]="{ employeeId :comment?.user?.employeeId}">
      <app-avatar *ngIf="comment && comment.user" [size]="comment.parentId ? 24 : 40"
        [avatarSource]="comment.user.avatarUrl" [avatarText]="comment.user.fullName">
      </app-avatar>
    </div>
    <div class="comment">
  
      <div class="comment-head">
        <div class="title">
          <h5>
            <a [routerLink]="[urlConstant.PROFILE_PAGE]"
              [queryParams]="{ employeeId :comment?.user?.employeeId }"
              >
              {{ comment.user ? comment.user.fullName : '' }}
            </a>
          </h5>
        </div>
  
        <div class="actions" *ngIf="currentUser && currentUser.id === comment.createdBy">
  
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <i class="fas fa-ellipsis-h"></i>
          </a>
  
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <!-- <li nz-menu-item *ngIf="canEdit(comment)"><a (click)="onEditComment(comment)">Edit</a></li> -->
              <li nz-menu-item><a (click)="onDeleteComment(comment)">Delete</a>
              </li>
            </ul>
          </nz-dropdown-menu>
  
        </div>
  
      </div>
  
      <div class="comment-description">
        <span [innerHTML]="comment.content | shortcodeToHtml | safeHtml"></span>
        <div *ngIf="comment.totalLikes" fdxz class="reactions-component total-likes" nz-button  (click)="showReactions()">
          <a>
            <span class='liked like'>
              <ins>{{ comment.totalLikes | thousandSuff }}</ins>
            </span>
          </a>
        </div>
      </div>
      
      <div class="reactions-component under-comment d-flex">
        <a (click)="like()">
          <span class="like" [ngClass]="(userLiked ? 'liked' : 'not-like')" data-toggle="tooltip" data-original-title="like">
            <span class="label">Like</span>
          </span>
        </a>
        <a (click)="reply()" class="reply" href="#" title="Reply">
          <span data-toggle="tooltip" data-original-title="reply" class="reply">
            <span class="label reply-text">Reply</span>
          </span>
        </a>
        <span class="comment-datetime ml-1">
          {{ comment.createdDate?.toString() | pastDateByText }}
        </span>
      </div>
  
    </div>
  </div>
  
  
  <ng-template #likePopoverTemplate>
    <div *ngIf="isLoadingReaction">
      <nz-skeleton [nzActive]="true"></nz-skeleton>
    </div>
    <div *ngIf="!isLoadingReaction && commentReactions && commentReactions.length">
      <p class="reaction-user-item" *ngFor="let item of commentReactions">
        <app-avatar [size]="20" [avatarSource]="item?.avatarUrl" [avatarText]="item?.fullName"></app-avatar>
        <span class="name">{{item?.fullName}}</span>
        <span class="title">{{item?.positionName}}</span>
      </p>
    </div>
  </ng-template>