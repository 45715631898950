import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseAdminComponent } from '@intranet/lib/components';
import { WallNewsFeedService, EventCalendar, EventService, NewsFeedTypeEnum, DepartmentService, Department, EventTypeEnum, InternalUploadFileDto, EventRepeatTypeEnum } from '@intranet/lib/data-access';
import { startOfMonth, endOfMonth, differenceInCalendarDays } from 'date-fns';
import * as moment from 'moment';
import * as _ from 'lodash';
import { NewsFeedDetailComponent } from '../detail/detail.component';
import { ModalSize, ModalService, Constants } from '@intranet/index';
import { v4 as uuidv4, v4 as uuid } from 'uuid';

@Component({
  selector: 'app-newsfeed-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
  styles: [],
})
export class EventDetailComponent extends BaseAdminComponent implements OnInit {
  dateFormat = 'dd/MM/yyyy';

  activeModal: NgbActiveModal;
  isWallCreating: boolean;
  isLoading: boolean;
  isFeedLoading: boolean;
  event: EventCalendar;
  announcements: any = [];
  pageNumber: number;
  pageSize: number;
  dateRange: Date[];
  annualDateRange: Date[];
  suggestionRange = { Today: [new Date(), new Date()], 'This Month': [startOfMonth(new Date()), endOfMonth(new Date())] };
  departmentGroups: Department[] = [];
  eventTypes: typeof EventTypeEnum = EventTypeEnum;
  modelUpload: InternalUploadFileDto = new InternalUploadFileDto();
  uuid: string;
  isUpdate: boolean;
  today = new Date();
  isYearly = false;
  constructor(
    modalRef: NgbActiveModal,
    private newsFeedService: WallNewsFeedService,
    private departmentService: DepartmentService,
    private eventService: EventService,
    private modalService: ModalService,
  ) {
    super();
    this.event = new EventCalendar();
    this.activeModal = modalRef;
  }

  ngOnInit() {
    this.pageNumber = 1;
    this.pageSize = 10;
    this.getAnnouncements();
    this.getDepartments();
    if (this.event.id) {
      this.event.fromDate = moment(this.event.fromDate).toDate();
      this.event.toDate = moment(this.event.toDate).toDate();
      this.dateRange = [this.event.fromDate, this.event.toDate];
      this.event.eventType = this.event.eventType.toString();

      if (this.event.isAnnual) {
        this.event.annualFromDate = moment(this.event.annualFromDate).toDate();
        this.event.annualToDate = moment(this.event.annualToDate).toDate();
        this.annualDateRange = [this.event.annualFromDate, this.event.annualToDate];
      }
      this.modelUpload.entityId = this.event.id;
      this.isUpdate = true;
      this.isYearly = this.event.repeatType === EventRepeatTypeEnum.YEARLY;
    }
    this.uuid = uuidv4();
    this.modelUpload.entityId = this.uuid;
    this.modelUpload.entityType = Constants.ENTITY_TYPE.EVENT_BOOKING;
  }

  getDepartments = () => {
    this.departmentService.getAll({}, (resp: Department[]) => {
      this.departmentGroups = resp;
    });
  }

  onSubmit() {
    this.event.departments = JSON.stringify(this.departmentSession);
    if (this.isYearly) {
      this.event.repeatType = EventRepeatTypeEnum.YEARLY;
    }
    if (this.event.id) {
      this.update();
    } else {
      this.add();
    }
  }

  add = () => {
    const data = _.cloneDeep(this.event);
    data.id = this.uuid;
    data.fromDate = moment(data.fromDate).format('MM/DD/YYYY');
    data.toDate = moment(data.toDate).format('MM/DD/YYYY');
    if (data.isAnnual) {
      data.annualFromDate = moment(data.annualFromDate).format('MM/DD/YYYY');
      data.annualToDate = moment(data.annualToDate).format('MM/DD/YYYY');
    }

    this.eventService.add(data, () => {
      this.activeModal.close(1);
    });
  }

  update = () => {
    const data = _.cloneDeep(this.event);
    data.fromDate = moment(data.fromDate).format('MM/DD/YYYY');
    data.toDate = moment(data.toDate).format('MM/DD/YYYY');
    if (data.isAnnual) {
      data.annualFromDate = moment(data.annualFromDate).format('MM/DD/YYYY');
      data.annualToDate = moment(data.annualToDate).format('MM/DD/YYYY');
    }

    this.eventService.update(data, () => {
      this.activeModal.close(1);
    });
  }

  getAnnouncements = () => {
    this.isFeedLoading = true;
    this.newsFeedService.getAnnoucements(
      {
        pageNumber: this.pageNumber,
        pageSize: 100,
      },
      res => {
        this.isFeedLoading = false;
        this.announcements = res;
      },
    );
  }

  onChangeEventFromDate(date) {
    this.event.fromDate = date;
    this.event.toDate = null;
    this.event.annualFromDate = null;
    this.event.annualToDate = null;
  }

  onChangeEventToDate(date) {
    this.event.toDate = date;
    this.event.annualFromDate = null;
    this.event.annualToDate = null;
  }

  onChangeAnnualFromDate(date) {
    this.event.annualFromDate = date;
    this.event.annualToDate = null;
  }

  onChangeAnnualToDate(date) {
    this.event.annualToDate = date;

  }
  onChangeDateRange = () => {
    this.event.fromDate = this.dateRange[0];
    this.event.toDate = this.dateRange[1];
    if (this.event.isAnnual) {
      if (this.event.annualFromDate && (this.event.annualFromDate.getTime() < this.event.fromDate.getTime()
        || this.event.annualFromDate.getTime() > this.event.toDate.getTime())) {
        this.event.annualFromDate = this.event.fromDate;
        this.annualDateRange = [this.event.annualFromDate, this.event.annualToDate];
      }

      if (this.event.annualToDate && (this.event.annualToDate.getTime() < this.event.fromDate.getTime()
        || this.event.annualToDate.getTime() > this.event.toDate.getTime())) {
        this.event.annualToDate = this.event.toDate;
        this.annualDateRange = [this.event.annualFromDate, this.event.annualToDate];
      }
    }
  }

  onChangeAnnualDateRange = () => {
    this.event.annualFromDate = this.annualDateRange[0];
    this.event.annualToDate = this.annualDateRange[1];
  }

  disableEventFromDate = (current: Date) => {
    const now = new Date();
    return differenceInCalendarDays(current, now) < 0;
  }

  disableEventToDate = (current: Date) => {
    const now = new Date();
    return differenceInCalendarDays(current, now) < 0;
  }


  disabledDate = (current: Date) => {
    return differenceInCalendarDays(current, this.today) < 0;
  }

  disableAnnualFromDate = (current: Date) => {
    const now = new Date();

    if (this.event.fromDate && differenceInCalendarDays(current, this.event.fromDate) < 0) {
      return true;
    }

    if (this.event.toDate && differenceInCalendarDays(current, this.event.toDate) > 0) {
      return true;
    }

    return differenceInCalendarDays(current, now) < 0;
  }

  disableAnnualToDate = (current: Date) => {
    const now = new Date();

    if (this.event.toDate && differenceInCalendarDays(current, this.event.toDate) > 0) {
      return true;
    }

    if (this.event.annualFromDate && differenceInCalendarDays(current, this.event.annualFromDate) < 0) {
      return true;
    }

    return differenceInCalendarDays(current, now) < 0;
  }

  disabledAnnualDate = (current: Date) => {
    return differenceInCalendarDays(current, this.event.fromDate || this.today) < 0 || differenceInCalendarDays(current, this.event.toDate) > 0;
  }

  addAnnouncement = () => {
    this.event.newsFeedId = null;
    const modal = this.modalService.open(NewsFeedDetailComponent, {
      size: ModalSize.xl,
    });
    modal.componentInstance.newsFeedTypeId = NewsFeedTypeEnum.ANNOUNCEMENT;
    modal.componentInstance.departments = this.departmentGroups;
    modal.result.then((resp: any) => {
      if (resp) {
        this.event.newsFeedId = resp;
        this.getAnnouncements();
      }
    });
  }

  onAnnouncementChange = () => {
    if (this.event.newsFeedId === '') {
      this.addAnnouncement();
    }
  }

  isCreatingOnWall() {
    return this.isWallCreating;
  }

}
