import { Component, OnInit } from '@angular/core';
import { BaseAdminComponent } from '@intranet/lib/components';
import { ColumnMapping, COLUMN_TYPE } from '@intranet/lib/data-access';
import { CompensationHourUserStatusColorEnum, CompensationHourUserStatusEnum } from '@intranet/lib/data-access/models/enums/compensation-hour-user-status.enum';
import { CompensationHourUserService } from '@intranet/lib/data-access/services/compensation-hour-user.service';
import { SecurityHelper } from '@intranet/lib/helpers/securityHelper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent extends BaseAdminComponent implements OnInit {
  compensationHour = null;

  compensationHourUserStatusEnum = CompensationHourUserStatusEnum;
  compensationHourUserStatusColorEnum = CompensationHourUserStatusColorEnum;
  columnMapping: ColumnMapping[] = [
    new ColumnMapping(COLUMN_TYPE.DATE, 'createdDate', 'Created Date', false, false),
    new ColumnMapping(COLUMN_TYPE.STRING, 'startTime', 'Start Time Registered', false, false),
    new ColumnMapping(COLUMN_TYPE.STRING, 'endTime', 'End Time Registered', false, false),
    new ColumnMapping(COLUMN_TYPE.STRING, 'status', 'Status', false, false),
    new ColumnMapping(COLUMN_TYPE.STRING, 'reason', 'Reason', false, false),
  ];

  activeModal: NgbActiveModal;
  compensationHourRequestHistories: any[] = [];

  constructor(modalRef: NgbActiveModal,
    private compensationHourUserService: CompensationHourUserService) {
    super();
    this.activeModal = modalRef;
  }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    const authToken = SecurityHelper.getStoreAuthen();
    const response = await this.compensationHourUserService
      .getHistoriesCompensationHour(authToken?.id, this.compensationHour);
    this.compensationHourRequestHistories = Object.assign([].concat(response));
  }
}
