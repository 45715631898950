import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class TicketService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/ticket';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getTicket(guidId: string, successCallback?: (resp?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = 'get-by-id';
    const params = {
      noLoading: true,
      guidId,
    };

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  createTicket(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = ``;

    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }

  updateTicket(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = ``;

    return this.updateUrl(url, data, undefined, successCallback, failedCallback, completeCallback);
  }

  getTickets(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `get-tickets`;
    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  deleteTicket(id: string, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = ``;
    const noLoading = true;
    const params = {
      noLoading, id
    };

    return this.deleteByUrl(url, params, successCallback, failedCallback, completeCallback);
  }
}
