<modal-header [title]="headerTemplate" [activeModal]="activeModal">
  <ng-template #headerTemplate>
    <h5 class="modal-title" *ngIf="isNew">Create new resignation form</h5>
    <h5 class="modal-title" *ngIf="!isNew">Update resignation form</h5>
  </ng-template>
</modal-header>
<modal-body>
  <div class="form-row">
    <div class="form-group col-lg-5">
      <label [class]="isUpdate || isNew ? 'required' : ''">Expected date</label>
      <nz-date-picker required name="resign-date" id="p-date" [(ngModel)]="formData.expectedDate" (ngModelChange)="onDateChange($event)"></nz-date-picker>
    </div>
    <div class="form-group col-lg-2"></div>
    <div class="form-group col-lg-5">
      <div *ngIf="!isNew">
        <label>Status</label>
        <p [innerHTML]="resignFormSvc.getStatusColor(formData.status) | shortcodeToHtml | safeHtml"></p>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-lg-12">
      <label [class]="isUpdate || isNew ? 'required' : ''">Reason</label>
      <textarea required class="form-control" id="p-reason" name="resign-reason" [(ngModel)]="formData.reason"></textarea>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-lg-12" *ngIf="modelUpload">
      <app-internal-upload-file [model]="modelUpload"></app-internal-upload-file>
    </div>
  </div>
</modal-body>
<modal-footer>
  <!-- <button *ngIf="!isNew" [disabled]="!isUpdate" (click)="deleteResignForm()" class="btn" nz-button>
    <i class="fas fa-ban mr5"></i>
    Delete
  </button> -->
  <button type="button" class="btn" (click)="eventCancel()" nz-button><i class="far fa-times-circle mr5"></i>Close</button>
  <button type="button" (click)="onSubmit()" class="btn btn-primary waves-effect " [nzLoading]="isLoading" nz-button nzType="primary" [disabled]="!isUpdate && !isNew">
    <i class="fas fa-save mr5"></i>
    <span>Submit</span>
  </button>
</modal-footer>
