import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants, PubSubEvent } from '@intranet/index';
import { CDNService, FileExtensionType, Policy, PolicyDetail, PolicyService } from '@intranet/lib/data-access';
import { Subscription } from 'rxjs';
import { CommonHelper } from '../../../hrm-lib/lib/helpers/commonHelper';
import { PubSubService } from '../../../hrm-lib/lib/services/pubsub.service';

@Component({
  selector: 'app-user-policy',
  templateUrl: './user-policy.component.html',
  styleUrls: ['./user-policy.component.scss'],
})
export class UserPolicyComponent implements OnInit, OnDestroy {
  menuPolicys: Policy[];
  dataPolicy: Policy;
  dataPolicyDetail: PolicyDetail;
  FileExtensionType = FileExtensionType;
  isLoading: false;

  contentContainerId = 'rt-content-container';
  contentContainerElement: HTMLElement;

  isMobileView = window.innerWidth <= Constants.SmallViewMaxWidthInPixel;
  showSidebar = !this.isMobileView;

  subscriptions: Subscription[] = [];

  constructor(
    private policyService: PolicyService,
    private cdnService: CDNService,
    private route: ActivatedRoute,
    private pubSubService: PubSubService,
    private readonly router: Router,
  ) {}

  async ngOnInit() {
    this.init();
    this.updateShowSidebar();

    await this.getDataMenu();
    this.route.queryParams.subscribe(async params => {
      const policyId = params?.policyId;
      const policyDetailId = params?.policyDetailId;
      if (policyId) {
        await this.getDataItem(policyId);
        if (policyDetailId) {
          // set dataPolicy to set current active policy (the title turns blue when you click on it).
          this.dataPolicyDetail = this.dataPolicy.policyDetails.find(pd => pd.policyDetailId === policyDetailId);

          CommonHelper.scheduleToExecute(() => this.scrollToDetailId(policyDetailId), 200);
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s?.unsubscribe());
  }

  scrollToDetailId(policyDetailId: string) {
    const itemToScrollTo = document.getElementById(policyDetailId);
    if (itemToScrollTo) {
      if (this.contentContainerElement) {
        const scrollTopPos = itemToScrollTo.offsetTop - this.contentContainerElement.offsetTop - 5;

        CommonHelper.scrollTo(scrollTopPos, 0, 'smooth');
        return;
      }

      itemToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  async scrollToId(itemDetail: PolicyDetail) {
    this.dataPolicyDetail = itemDetail;
    await this.getDataItem(itemDetail.policyId);
    this.scrollToDetailId(itemDetail.policyDetailId);

    this.setSidebarState(false);

    this.navigateWithPolicyParams();
  }
  async policyActive(data) {
    this.dataPolicyDetail = null;
    await this.getDataItem(data.policyId);
    this.setSidebarState(false);

    CommonHelper.scheduleToExecute(() => CommonHelper.scrollTop());

    this.navigateWithPolicyParams();
  }

  private navigateWithPolicyParams(): void {
    const { policyId, policyDetailId } = this.dataPolicyDetail;

    this.router.navigate([], {
      queryParams: {
        policyId,
        policyDetailId,
      },
    });
  }

  async getDataMenu() {
    this.menuPolicys = await this.policyService.getMenu();
    if (this.menuPolicys && this.menuPolicys.length > 0) {
      await this.getDataItem(this.menuPolicys[0].policyId);
    }
  }
  async getDataItem(id) {
    if (!this.dataPolicy || (this.dataPolicy && this.dataPolicy.policyId !== id)) {
      this.dataPolicy = await this.policyService.getInfomation(id);
      if (!this.dataPolicyDetail) {
        this.dataPolicyDetail = this.dataPolicy.policyDetails[0];
        if (this.dataPolicy.policyDetails && this.dataPolicy.policyDetails.length) {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < this.dataPolicy.policyDetails.length; i++) {
            // build download url
            if (this.dataPolicy.policyDetails[i].files.length) {
              this.dataPolicy.policyDetails[i].files.forEach(item => (item.url = this.policyService.getFileUrl(item.fileId)));
            }
          }
        }
      }
    }
  }

  async click(document) {
    await this.cdnService.downloadFileEntity(document.fileId, document.fileName);
  }

  init() {
    this.contentContainerElement = document.getElementById(this.contentContainerId);

    const expandedFrontSub = this.pubSubService.$sub(PubSubEvent.EVENTS.TOGGLE_LEFT_PANEL).subscribe(showSidebar => {
      CommonHelper.setExpandedFront(showSidebar);

      this.updateShowSidebar();
    });

    this.subscriptions.push(expandedFrontSub);
  }

  @HostListener('window:resize')
  updateShowSidebar() {
    this.isMobileView = window.innerWidth <= Constants.SmallViewMaxWidthInPixel;
    this.showSidebar = this.isMobileView && CommonHelper.isExpandedFront();
  }

  private setSidebarState(expand: boolean) {
    this.pubSubService.$pub(PubSubEvent.EVENTS.TOGGLE_LEFT_PANEL, expand);
  }
}
