import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppConfig } from './model';
import { environment } from '../environment';
import { EncryptHelper } from '@intranet/lib/helpers/encryptHelper';
@Injectable()
export class AppConfig {
  static settings: IAppConfig;
  constructor(private http: HttpClient) { }
  load() {
    const jsonFile = `assets/env/config.${environment.name}.json?t=` + new Date().getTime();
    return new Promise<void>((resolve, reject) => {
      this.http
        .get(jsonFile)
        .toPromise()
        .then((response: any) => {
          if (response.Data) {
            response = EncryptHelper.decrypt(response.Data) as string;
            response = JSON.parse(response);
          }
          // if (environment.name === 'qc') {
          // const hashData = EncryptHelper.encrypt(response);
          // console.log('hashData', hashData);
          // }
          AppConfig.settings = { ...response as IAppConfig };
          resolve();
        })
        .catch((response: any) => {
          reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
        });
    });
  }
}
