import { Injectable } from '@angular/core';
import { HttpClientService, Constants } from '@intranet/index';
import { BaseService } from './base.service';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { MediaTypeEnum } from '@intranet/lib/data-access/models';

@Injectable({
  providedIn: 'root',
})
export class PolicyDetailService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/policy-details';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  uploadBlob(blob: Blob, name: string) {
    const url = AppConfig.settings.API_URL + '/cdn/image/upload_file';
    const formData: FormData = new FormData();

    formData.append('files', blob, name);

    formData.append('mediaType', MediaTypeEnum.IMAGE.toString());

    return this.httpClient.postSync(url, formData);
  }

  getImageUrl(url: string) {
    return `${AppConfig.settings.CDN_URL}/cdn/image/${url}`;
  }
}
