import { CalendarView } from '@intranet/lib/data-access';

export class CalendarOptions {
  view: CalendarView;
  startHour?: number;
  endHour?: number;
  dayStartMinute?: number;
  dayEndMinute?: number;
  locale?: string;
  excludeDays?: number[];
  weekStartsOn?: 0 = 0;
  viewDate?: Date;
  viewYear?: Date;
  constructor() {
    this.startHour = 9;
    this.endHour = 18;
    this.locale = 'en';
    this.view = CalendarView.Week;
    this.excludeDays = [0, 6];
    this.weekStartsOn = 0;
    this.viewDate = new Date();
  }
}
