import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styles: [],
})

export class ModalConfirmComponent implements OnInit {

  @Input() activeModal: NgbActiveModal;

  @Input() lbConfirm = 'Confirm';
  @Input() lbCancel = 'Cancel';

  @Input() title: string;
  @Input() description: string;

  result: boolean;
  @Output() resultChange = new EventEmitter<boolean>();

  constructor(modalRef: NgbActiveModal) {
    this.activeModal = modalRef;
  }
  ngOnInit() { }

  confirm() {
    this.result = true;
    if (this.activeModal) {
      this.activeModal.close(this.result);
    }

    this.resultChange.emit(this.result);
  }

  cancel() {
    if (this.activeModal) {
      this.activeModal.close(this.result);
    }

    this.resultChange.emit(this.result);
  }
}
