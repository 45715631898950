import { Component, Input, OnInit } from '@angular/core';
import { GetReviewSection, GetReviewSectionByMember } from '@intranet/lib/data-access/models/review/review-form-member.model';

@Component({
  selector: 'app-review-overall-result',
  templateUrl: './review-overall-result.component.html',
  styleUrls: ['./review-overall-result.component.scss']
})
export class ReviewOverallResultComponent implements OnInit {
  @Input() section: GetReviewSection = null;

  constructor() { }

  ngOnInit() {

  }
}
