import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFromNow',
})
export class DateFromNowPipe implements PipeTransform {
  constructor() {}
  transform(value: any): any {
    const date = new Date(value);
    const dateMoment = moment(value);
    // const dateNow = new Date();
    // const seconds = Math.floor((dateNow - date) / 1000);

    // let interval = seconds / 31536000;

    // if (interval > 1) {
    //   return Math.floor(interval) + ' years';
    // }
    // interval = seconds / 2592000;
    // if (interval > 1) {
    //   return Math.floor(interval) + ' months';
    // }
    // interval = seconds / 86400;
    // if (interval > 1) {
    //   return Math.floor(interval) + ' days';
    // }
    // interval = seconds / 3600;
    // if (interval > 1) {
    //   return Math.floor(interval) + ' hours';
    // }
    // interval = seconds / 60;
    // if (interval > 1) {
    //   return Math.floor(interval) + ' minutes';
    // }
    // return Math.floor(seconds) + ' seconds';

    return dateMoment.fromNow();
  }
}
