<modal-header [title]="headerTemplate" [activeModal]="activeModal">
  <ng-template #headerTemplate>
    <h4 class="modal-title" *ngIf="!policyId">[Add] {{data.title}}</h4>
    <h4 class="modal-title" *ngIf="policyId">[Edit] {{data.title}}</h4>
  </ng-template>
</modal-header>
<form *ngIf="data" (ngkSubmit)="onSubmit()" #frm="ngForm" [ngkForm]="true">
  <modal-body>
    <div class="form-row">
      <div class="form-group col-xl-12">
        <label class="required">Title</label>
        <input type="text" class="form-control" name="policy-name" [(ngModel)]="data.title">
      </div>
      <div class="form-group col-xl-12">
        <label>Description</label>
        <textarea name="policy-desc" class="form-control" [(ngModel)]="data.description"></textarea>
      </div>
      <div class="form-group col-xl-12">
        <label>Order</label>
        <input type="number" class="form-control" name="policy-order" [(ngModel)]="data.order">
      </div>
    </div>
    <div *ngIf="policyId" class="form-row">
      <div class="form-group col-xl-12">
        <app-policy-detail-list [policyId]="policyId"></app-policy-detail-list>
      </div>
    </div>
  </modal-body>
  <modal-footer>
    <create-update-button [isCancel]="true" [nzLoading]="isLoading" [isUpdate]="policyId" (cancel)="activeModal.close()"></create-update-button>
  </modal-footer>
</form>
