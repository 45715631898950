import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService, Toastr, Loading } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { ApiResponse } from '../models/api-response.model';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/purchase-order';
  httpClientt: HttpClientService;

  constructor(httpClient: HttpClientService) {
    super(httpClient);
    this.httpClientt = httpClient;
  }
  updateStatus(params: any, successCallback?: (params?: any) => void) {
    const url = 'update-status';
    return this.updateUrl(url, params, { params: { idxx: params.status } }, successCallback);
  }
  pushToWarehouse(params: any, successCallback?: (params?: any) => void) {
    const url = 'push-to-warehouse';
    return this.updateUrl(url, params, undefined, successCallback);
  }
  // updateStatus(params: any, successCallback?: (params?: any) => void) {

  //   Loading.show();
  //   const url = this.hostUrl + `/update-status`;
  //   this.httpClientt
  //     .putSync(url, params, { params: { idxx: params.status } })
  //     .then(
  //       (resp: ApiResponse) => {
  //         if (resp && resp.id && resp.id > 0) {
  //           if (successCallback) {
  //             successCallback(resp);
  //           } else {
  //             Toastr.success('Successfully');
  //           }
  //         } else {
  //           Toastr.error(resp.message);
  //         }
  //       },
  //       (error: any) => {
  //         Toastr.error(error);
  //       },
  //     )
  //     .finally(() => {
  //       Loading.hide();
  //     });
  // }

  getForOnlyEmployee(params?: any, successCallback?: (params?: any) => void) {
    const url = 'get_by_employee';
    return this.getByUrl(url, params, successCallback);
  }

  getDetailList(params?: any, successCallback?: (params?: any) => void) {
    const url = 'get_detail';
    return this.getByUrl(url, params, successCallback);
  }

  fillPrice(params?: any, successCallback?: (params?: any) => void) {
    const url = 'fillprice';
    return this.updateUrl(url, params, undefined, successCallback);
  }
}
