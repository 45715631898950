
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { ReviewForm } from '../../models/review/review-form.model';
import { SearchReviewFormMember } from '../../models/search.model';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';

@Injectable({
  providedIn: 'root',
})
export class ReviewFormLevelService extends BaseService {
  hostUrl = '/api/review-form-level';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  view(searchData: any): Promise<any> {
    return this.httpClient.getObservable(this.hostUrl, searchData).toPromise();
  }

  updateLevel(data: any): Promise<any> {
    return this.httpClient.putObservable(this.hostUrl, data, false).toPromise();
  }

  updateComplete(): Promise<any> {
    return this.httpClient.putObservable(this.hostUrl + '/complete', null).toPromise();
  }

  exportGuideline() {
    const url = ApiEndpoint.Review.FormLevel.ExportGuideline();
    return this.httpClient.getObservable(url).toPromise();
  }
}

