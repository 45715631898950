<modal-header [title]="headerTemplate" [activeModal]="activeModal">
  <ng-template #headerTemplate>
    <h4 class="modal-title">{{model.title}}</h4>
  </ng-template>
</modal-header>
<form *ngIf="model" (ngkSubmit)="clickOk()" #frm="ngForm" [ngkForm]="true">

  <modal-body>
    <div class="form-row">
      <p>{{model.description}}</p>
      <div class="form-group col-xl-12">
        <label>Reason</label>
        <textarea class="form-control" name="pos-desc" [required]="model.isRequired" [(ngModel)]="model.reason"></textarea>
      </div>
    </div>
  </modal-body>
  <modal-footer>
    <button type="button" class="btn btn-white" (click)="activeModal.close()"><i class="far fa-times-circle mr5"></i> {{model.textCancel}}</button>
    <button class="submit" nz-button [nzLoading]="model.isLoading" type="submit" [(ngClass)]="model.btnClass">
      {{model.textOk}}
    </button>
  </modal-footer>

</form>
