import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService, Toastr } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class LibraryQuestionService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/library-question';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }
  get(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `get-list`;
    params.noLoading = true;
    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }
  create(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `create`;
    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }
}
