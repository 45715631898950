import { Input, TemplateRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppMenuService, MenuDto, MenuOptions, MenuTypeEnum } from '@intranet/lib/data-access';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() menuType: MenuTypeEnum;
  @Input() template: TemplateRef<void>;
  @Input() options?: MenuOptions;
  @Input() menu?: MenuDto[];
  MenuTypeEnum = MenuTypeEnum;

  constructor(private menuService: AppMenuService, private router: Router) {
  }

  async ngOnInit() {
    // AppMenuService is now handling permission by itself.
    if (!this.menu || this.menu.length === 0) {
      // this.menu = await this.menuService.getMenuByType(this.menuType);
      this.menu = await this.menuService.loadMenu(this.menuType);
    }
    if (this.options?.processMenuFn) {
      this.options.processMenuFn(this.menu, this.router);
    }
    if (this.options?.onNavigatingFn) {
      this.router.events.subscribe(event => {
        this.menu = this.options.onNavigatingFn(event, this.menu);
      });
    }
  }
}
