<modal-header [title]="headerTemplate" [activeModal]="activeModal">
  <ng-template #headerTemplate>
    <h4 class="modal-title" *ngIf="!projectId">Add New Project</h4>
    <h4 class="modal-title" *ngIf="projectId">Update Project</h4>
  </ng-template>
</modal-header>
<form *ngIf="newProject" class="project-info" (ngkSubmit)="onSubmit()" #frm="ngForm" [ngkForm]="true">
  <modal-body>
    <div class="card m-b-20">
      <div class="card-body">
        <h4 class="card-title clearfix">
          Project Information
        </h4>
      </div>
      <div>
        <div class="p-sm">
          <div class="form-row">
            <div class="form-group col-xl-6">
              <label for="p-name" class="required">Name</label>
              <input class="form-control" type="text" name="p-name" [(ngModel)]="newProject.name" required>
            </div>
            <div class="form-group col-xl-6">
              <label for="p-status" class="required">Status</label>
              <nz-select class="form-select" name="p-status" nzPlaceHolder="Group/Department" [(ngModel)]="newProject.status" required>
                <nz-option *ngFor="let item of projectStatusList" [nzLabel]="ProjectStatusEnum[item]" [nzValue]="item">
                </nz-option>
              </nz-select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-xl-6">
              <label for="p-client">Client</label>
              <input class="form-control" type="text" name="p-client" [(ngModel)]="newProject.client" required>
            </div>
            <div class="form-group col-xl-6">
              <label>Project Owner</label>
              <select-search name="p-owner" [(ngModel)]="newProject.ownerId" [data]="users" textField="text" valueField="value" placeholder="Project Owner" required>
              </select-search>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-xl-6">
              <label for="p-department"></label>
              <nz-select class="form-select" name="p-department" nzPlaceHolder="Group/Department" [(ngModel)]="newProject.departmentId" required>
                <nz-option *ngFor="let item of departments" [nzLabel]="item.name" [nzValue]="item.id">
                </nz-option>
              </nz-select>
            </div>
            <div class="form-group col-xl-6">
              <label for="p-dueDate">Due Date</label>
              <div>
                <nz-date-picker name="p-dueDate" [(ngModel)]="newProject.dueDate"></nz-date-picker>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-xl-12">
              <label for="p-description">Description</label>
              <textarea class="form-control" name="p-description" [(ngModel)]="newProject.description"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card m-b-20" *ngIf="!projectId">
      <div class="card-body">
        <h4 class="card-title clearfix">
          Project Members
          <button nz-button nzType="primary" class="mr10" nz-popover [(nzPopoverVisible)]="visible" nzPopoverTitle="New Member" nzPopoverPlacement="right" [nzPopoverContent]="contentTemplate" nzPopoverTrigger="click" (click)="visible = true">
            <i class="fas fa-plus"></i>
          </button>
        </h4>
        <div *ngIf="projectMembers && projectMembers.length;else empty">
          <nz-tag *ngFor="let member of projectMembers" nzMode="closeable" (nzOnClose)="removeProjectMember(member)" [nzColor]="'geekblue'">
            <span [innerHTML]="member.text"></span>
          </nz-tag>
        </div>
        <ng-template #empty>
          <nz-empty [nzNotFoundContent]="'No members'"></nz-empty>
        </ng-template>

        <ng-template #contentTemplate>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <select-search name="memberId" [(ngModel)]="memberId" [data]="users" textField="text" valueField="value" placeholder="User" required>
                </select-search>
              </div>
            </div>
            <div class="col-md-12">
              <button nz-button nzType="primary" class="mr10" type="button" (click)="addNewProjectMember()">
                <i class="fas fa-plus mr10"></i> OK
              </button>
              <button nz-button type="button" (click)="visible = false">
                <i class="far fa-times-circle mr10"></i> Cancel
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </modal-body>
  <modal-footer>
    <button type="button" class="btn btn-white" (click)="closeModal()">Cancel</button>
    <create-update-button [nzLoading]="isLoading" [isUpdate]="projectId"></create-update-button>
  </modal-footer>
</form>
