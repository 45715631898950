import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/index';
import { LeaveSetting } from '../models/leave-setting';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';

@Injectable({
  providedIn: 'root',
})
export class LeaveSettingService {

  constructor(private httpClient: HttpClientService) { }

  create(leaveSetting: LeaveSetting) {
    const url = ApiEndpoint.LeaveSetting.Create();
    return this.httpClient.postObservable(url, leaveSetting).toPromise();
  }

  get(getLeaveSettingDto: { departmentGroupId: string }) {
    const url = ApiEndpoint.LeaveSetting.Get();
    return this.httpClient.getObservable(url, getLeaveSettingDto).toPromise();
  }

  update(leaveSetting: LeaveSetting) {
    const url = ApiEndpoint.LeaveSetting.Update(leaveSetting.id);
    return this.httpClient.putObservable(url, leaveSetting).toPromise();
  }
}
