import { BaseModel } from './base-model';
import { DashboardPositionTypeEnum } from './enums/dashboard-position-type.enum';
export class Dashboard extends BaseModel {
  id: string;
  title: string;
  isDefault: boolean;
  positionType: DashboardPositionTypeEnum;
  isEditAndDelete: boolean;
}

export class DashboardItem extends BaseModel {
  id: string;
  dashboardId: string;
  widgetKey: string;
  title: string;
  width: number;
  height: number;
  positionX: number;
  positionY: number;
  isImportant: boolean;
  isDisplayDataManage: boolean;
  isSettingDisplayDataManage: boolean;
  order: number;
}
