import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  PurchaseOrderDetail,
  PurchaseRequestDetailService,
  PurchaseRequestService,
  InvoiceService,
  Invoice,
  InvoiceLine,
  PurchaseRequest,
  InternalUploadFileDto,
  ColumnMapping,
  COLUMN_TYPE,
} from '@intranet/lib/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants, Loading, Toastr } from '@intranet/index';
import { BaseAdminComponent } from '@intranet/lib/components';
import * as linq from 'linq';

@Component({
  selector: 'app-purchase-detail',
  templateUrl: './purchase-detail.component.html',
  styleUrls: ['./purchase-detail.component.scss'],
})
export class PurchaseDetailComponent extends BaseAdminComponent implements OnInit, AfterViewInit {
  @ViewChild('myTable') table: any;
  modelUpload: InternalUploadFileDto = new InternalUploadFileDto();
  purchseRequestStatus = Constants.PRUCHASE_REQUEST_STATUS_ID;
  purchaseRequest: PurchaseRequest;
  purchaseId: string;
  productLists: PurchaseOrderDetail[];
  activeModal: NgbActiveModal;
  isDetail = false;
  columnMapping = [
    new ColumnMapping(COLUMN_TYPE.STRING, 'productName', 'Name', false, false, 200),
    new ColumnMapping(COLUMN_TYPE.STRING, 'equipmentCategoryName', 'Category', true, false),
    new ColumnMapping(COLUMN_TYPE.STRING, 'supplierName', 'Supplier', true, false),
    new ColumnMapping(COLUMN_TYPE.STRING, 'option', 'Option', true, false),
    new ColumnMapping(COLUMN_TYPE.NUMBER, 'quantity', 'Quantity', false, false, 100),
    new ColumnMapping(COLUMN_TYPE.NUMBER, 'buyQuantity', 'Buy quantity', false, false, 100),
    new ColumnMapping(COLUMN_TYPE.NUMBER, 'quantityInWarehouse', 'Take in warehouse', false, false, 130),
    new ColumnMapping(COLUMN_TYPE.NUMBER, 'price', 'Price', true, false),
    new ColumnMapping(COLUMN_TYPE.NUMBER, 'totalAmount', 'Total Amount', true, false),
  ];
  requestTypeEnum = Constants.REQUEST_TYPE_ENUM;

  constructor(
    modalRef: NgbActiveModal,
    private purchaseRequestDetailSvc: PurchaseRequestDetailService,
    private purchaseRequestSvc: PurchaseRequestService,
    private invoiceService: InvoiceService,
  ) {
    super();
    this.activeModal = modalRef;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.table.rowDetail.expandAllRows();
    }, 1000);
  }
  ngOnInit() {
    this.modelUpload.isReadonly = true;
    this.loadPurchase();
    this.loadOrderDetail();
  }

  loadOrderDetail() {
    Loading.show();
    this.purchaseRequestDetailSvc.getAll({ guid: this.purchaseId }, (res: PurchaseOrderDetail[]) => {
      if (res) {
        let count = 0;
        const brokenList = res.filter(x => x.parentId);
        res
          .filter(x => !x.parentId)
          .forEach(element => {
            const childs = brokenList.filter(x => x.parentId === element.id);
            element.order = count;
            count++;
            if (childs && childs.length) {
              element.hasChildrens = true;
              childs.forEach(child => {
                child.order = count;
                count++;
              });
            }
          });
        this.productLists = linq
          .from(res)
          .orderBy(x => x.order)
          .toArray();
        this.productLists.map(item => (item.price = item.buyQuantity ? item.price : 0));
        setTimeout(() => {
          this.changePrice();
        }, 500);
      }
      Loading.hide();
    });
  }

  loadPurchase() {
    Loading.show();
    this.purchaseRequestSvc.getById(this.purchaseId, (res: PurchaseRequest) => {
      if (res) {
        this.purchaseRequest = res;
        this.modelUpload.entityId = this.purchaseRequest.id;
        this.modelUpload.entityType = Constants.ENTITY_TYPE.PURCHASE_REQUEST;
      }
      Loading.hide();
    });
  }

  updateStatus(status: number) {
    this.purchaseRequestSvc.updateStatus({ id: this.purchaseId, status }, () => {
      this.activeModal.close(1);
    });
  }

  changePrice() {
    this.purchaseRequest.totalAmount = 0;
    this.productLists.forEach(item => {
      this.purchaseRequest.totalAmount += item.buyQuantity * item.price;
    });
  }

  createInvoice() {
    const dataCreate = new Invoice(this.purchaseRequest);
    const dataLines = Object.assign([], this.productLists);
    const dataLinesConvert: InvoiceLine[] = [];
    dataLines.map(item => {
      const line = new InvoiceLine(item);
      dataLinesConvert.push(line);
    });

    dataCreate.lines = dataLinesConvert;
    this.isLoading = true;
    Loading.show();
    this.invoiceService.add(
      dataCreate,
      () => {
        Toastr.success('Created successfully!');
        this.activeModal.close(true);
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }
}
