<div class="rt-panel-item">
  <div class="row align-items-center title-panel">
    <div class="col">
      <p>Surveys</p>
    </div>
    <div *ngIf="list" class="rt-panel-item-content">
      <virtual-scroller class="virtual-scroller" #scroll [items]="list" (vsEnd)="fetchMore($event)">
        <div *ngFor="let item of scroll.viewPortItems">
          <div (click)="onOpenSurvey(item)">
            <p><b>{{ item.name }}</b></p>
            <span class="rt-time">{{ item.endDate  }}</span>
          </div>
        </div>
      </virtual-scroller>
    </div>
  </div>
</div>