import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Policy, PolicyService } from '@intranet/lib/data-access';
import { Loading, Toastr } from '@intranet/lib/settings';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-policy-modal',
  templateUrl: './policy-modal.component.html',
  styleUrls: ['./policy-modal.component.scss']
})
export class PolicyModalComponent implements OnInit {
  @Input() policyId: string;
  @ViewChild('frm', { static: false }) public formGroup: NgForm;

  activeModal: NgbActiveModal;
  data: Policy = new Policy();
  isLoading = false;

  constructor(modalRef: NgbActiveModal, private policySvc: PolicyService) {
    this.activeModal = modalRef;
  }

  ngOnInit() {
    if (this.policyId) {
      this.getPolicyById();
    }
  }

  onSubmit() {
    if (this.policyId) {
      this.updatePolicy();
    } else {
      this.addNewPolicy();
    }
  }

  closeModal() {
    this.activeModal.close(null);
  }

  //#region private methods
  private getPolicyById() {
    this.isLoading = true;
    Loading.show();
    this.policySvc.getById(
      this.policyId,
      (policy: Policy) => {
        if (policy) {
          this.data = policy;
        } else {
          Toastr.error('Cannot find policy data.');
        }
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }

  private addNewPolicy() {
    if (this.formGroup.invalid) {
      return;
    }
    this.isLoading = true;
    Loading.show();
    this.policySvc.add(
      this.data,
      () => {
        this.activeModal.close(this.data);
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }

  private updatePolicy() {
    if (this.formGroup.invalid) {
      return;
    }
    this.isLoading = true;
    Loading.show();
    this.policySvc.update(
      this.data,
      () => {
        this.activeModal.close(this.data);
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }

  //#endregion private methods
}
