import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/index';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { Loading } from '@intranet/lib/settings';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class LeaveRequestService extends BaseService {
  readonly rootUrl = '/api/leave_request';
  readonly hostUrl: string = AppConfig.settings.API_URL + this.rootUrl;

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getLeaveRequest(request: any) {
    return this.httpClient.postObservable(`${this.rootUrl}/get-list`, request).toPromise();
  }

  updateStatus(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void) {
    const url = 'approval-reject';
    return this.updateUrl(url, params, undefined, successCallback, failedCallback);
  }
  updateStatusGroup(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void) {
    const url = 'approval-reject-group';
    return this.updateUrl(url, params, undefined, successCallback, failedCallback);
  }
  restore(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void) {
    const url = 'restore';
    return this.updateUrl(url, params, undefined, successCallback, failedCallback);
  }
  getForOnlyEmployee(params?: any, successCallback?: (params?: any) => void) {
    Loading.show();
    const url = 'get-employee';
    return this.getByUrl(url, params, successCallback);
  }
  countLimit(params?: any, successCallback?: (params?: any) => void) {
    Loading.show();
    const url = 'count-limit';
    return this.getByUrl(url, params, successCallback);
  }

  deleteRequestByUser(params?: any) {
    Loading.show();
    const url = this.hostUrl + `/delete-for-user?id=${params.id}`;
    return this.httpClient.deleteSync(url);
  }

  export(searchData: any): Promise<any> {
    const url = ApiEndpoint.LeaveRequest.Export();

    return this.httpClient.getObservable(url, searchData).toPromise();
  }
}
