import { Employee } from '.';

export class TimekeeperUserModel {
  id: string;
  employeeId: string;
  timeKeeperId: string;
  machineNumber: string;
  enrollNumber: string;
  name: string;
  fingerIndexs: string;
  tmpDatas: string;
  cardTmpData: string;
  faceIndex: string;
  faceTmpData: string;
  privelage: string;
  password: string;
  enabled: string;
  iFlag: string;
  employee: Employee;
  machine: any;
  allowSync: boolean;
  syncCard: boolean;
  syncFingerpint: boolean;
  syncFaceId: boolean;
  hasMachine: boolean;
}
