<modal-body>
  <div class="form-row">
    <div class="form-group col-12">
      <editor [init]="editorConfig" name="comment" [(ngModel)]="comment"></editor>
    </div>
  </div>
</modal-body>
<modal-footer>
  <button type="button" class="btn btn-primary" (click)="saveComment()">Save</button>
  <button type="button" class="btn btn-white" (click)="closeModal()">Close</button>
</modal-footer>
