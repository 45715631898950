export class ResizeFile {
  base64: string;
  fileName: string;
  mimetype: string;
  file: any;
  type: string;
  old: string;
  folder: string;
  constructor() {
    this.fileName = '';
    this.mimetype = '';
    this.base64 = '';
    this.file = null;
  }
}
