import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClientService, Loading } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LeaveTypeService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/leave_type';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getListWithoutApplyAll(params?: any, successCallback?: (params?: any) => void) {
    Loading.show();
    const url = 'without-apply';
    return this.getByUrl(url, params, successCallback);
  }

  getHaftLeaveDropdown(params?: any): Observable<any> {
    const url = '/api/leave_type/get-haft-leave';
    return this.httpClient.getObservable(url, params);
  }
  countSpecialLeave(successCallback?: (params?: any) => void) {
    Loading.show();
    const url = 'count-special';
    return this.getByUrl(url, undefined, successCallback);
  }

  create(params: any): Observable<any> {
    const url = '/api/leave_type';
    return this.httpClient.postObservable(url, params);
  }

  update(params: any): Observable<any> {
    const url = '/api/leave_type';
    return this.httpClient.putObservable(url, params);
  }
}
