import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
    name: 'pastDateByText',
})
export class PastDateByTextPipe implements PipeTransform {
    transform(date: string, ...args: unknown[]): unknown {
        const dayDiff = moment().diff(moment(date), 'minutes');
        const day = Math.round(dayDiff / (24 * 60));
        const hours = Math.round((dayDiff % (24 * 60)) / 60);
        const mins = Math.round((dayDiff % (24 * 60)) % 60);
        let dateString = '';

        if (day < 1) {
            if (hours < 1) {
                dateString = `${(mins > 0 && mins) || '1'} minute${(mins > 1 && 's') || ''}`;
                if (mins < 1) {
                    dateString = 'Just now';
                }
            } else {
                dateString = `${hours} hour${(hours > 1 && 's') || ''}`;
            }
        }
        if (day < 5 && day >= 1) {
            dateString = `${day} day${(day > 1 && 's') || ''}`;
        }
        if (day >= 5) {
            dateString = moment(date).format('D MMM, YYYY');
        }

        return dateString;
    }
}
