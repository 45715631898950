import * as _ from 'lodash';

import { ActivatedRoute, Router } from '@angular/router';
import { BaseUserComponent, ModalConfirmComponent } from '@intranet/lib/components';
import { Bootbox, DateHelper, ModalService, ModalSize, NewsFeeds as NewsFeedConstants, PubSubEvent, UrlConstant, SecurityHelper } from '@intranet/index';
import { Component, OnInit } from '@angular/core';
import { PubSubService } from '@intranet/lib/services';
import { CreatePostModalComponent } from '../../components/index';
import { ViewportScroller } from '@angular/common';

import {
  FeedRealtimeService,
  NewsFeed,
  NewsFeedTypeEnum,
  PollQuestion,
  PollRealtimeService,
  ReactionService,
  SearchWallNewsFeed,
  Survey,
  SurveyService,
  WallNewsFeedService
} from '@intranet/lib/data-access';

import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NewsFeedPollComponent } from '@intrafront/components/news-feed/news-feed-detail/news-feed-poll/news-feed-poll.component';
import { CommonHelper } from '@intranet/lib/helpers/commonHelper';

@Component({
  selector: 'app-newsfeed-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss'],
  styles: [],
})
export class FeedPageComponent extends BaseUserComponent implements OnInit {
  // @ViewChild('surveyModal') surveyModal: ElementRef;
  maxQuestions = NewsFeedConstants.MAX_QUESTIONS;
  year = new Date().getFullYear();

  feed: NewsFeed;
  feedId: string = null;
  title: string;
  latestFeeds: NewsFeed[];

  urlConstant = UrlConstant;
  types = NewsFeedConstants.FEED_TYPES;

  totalVotes = {};
  totalUserVotes = {};

  isLoading: boolean;
  surveys: Survey[];
  canBack: boolean;
  queryFeeds: SearchWallNewsFeed = new SearchWallNewsFeed();
  isPollExpired = false;

  userLiked: boolean;
  originalUserNotes = {};

  activeModal: NgbModalRef;
  navigateToHome: boolean;

  constructor(
    private route: ActivatedRoute,
    private newsFeedService: WallNewsFeedService,
    private router: Router,
    private modalService: ModalService,
    private feedRealtimeService: FeedRealtimeService,
    private pollRealtimeService: PollRealtimeService,
    private pubsub: PubSubService,
    private viewportScroller: ViewportScroller,
    private surveyService: SurveyService,
    private reactionService: ReactionService
  ) {
    super();

    this.route.queryParams.subscribe(params => { });

    this.queryFeeds.noLoading = true;
    this.queryFeeds.pageSize = 10;
    this.queryFeeds.pageNumber = 1;
    this.navigateToHome = false;
  }

  ngOnInit() {
    this.feedRealtimeService.startConnection();
    this.registerFeedRealtime();
    this.registerCommentRealtime();

    this.pollRealtimeService.startConnection();
    this.pollRealtimeService.addTransferResultListener((data: any) => {
      this.pubsub.$pub(PubSubEvent.EVENTS.ON_UPDATE_POLL, data);
    });

    this.route.params.subscribe(params => {
      this.feedId = params.id;
      this.load();
    });

    this.pubsub.$sub(PubSubEvent.EVENTS.ON_UPDATE_POLL).subscribe(res => {
      const items = res.items;
      const userId = res.userId;
      const questionId = res.questionId;
      const userPollOptionId = res.userPollOptionId;
      if (this.feed && this.feed.pollQuestions) {
        const notifiedQuestion = this.feed.pollQuestions.find(q => q.id === questionId);
        const nonNotifiedQuestions = _.filter(this.feed.pollQuestions, q => q.id !== questionId);

        this.totalVotes = {};
        items.map(item => {
          this.totalVotes[notifiedQuestion.id] = this.totalVotes[notifiedQuestion.id] || 0;

          if (item.questionId === notifiedQuestion.id) {
            this.totalVotes[notifiedQuestion.id] += item.totalVoted;
          }
        });

        _.forEach(nonNotifiedQuestions, nonNotifiedQuestion => {
          this.totalVotes[nonNotifiedQuestion.id] = this.totalVotes[nonNotifiedQuestion.id] || 0;

          const { options } = nonNotifiedQuestion;
          const totalVoted = _.sumBy(options, 'totalChoice');

          this.totalVotes[nonNotifiedQuestion.id] = totalVoted;
        });

        if (notifiedQuestion) {
          if (!notifiedQuestion.isMultipleChoice && userId === this.currentUser?.id) {
            notifiedQuestion.userChoice = userPollOptionId;
          }


          notifiedQuestion.options.map(option => {
            // this.totalVotes[notifiedQuestion.id] += option.totalChoice;
            const selectedOption = items.find(r => r.userPollOptionId === option.id);

            if (option.id === userPollOptionId && userId === this.currentUser?.id) {
              option.isSelected = res.isSelect;
            }
            if (!selectedOption) {
              option.totalChoice = 0;
            } else {
              option.totalChoice = selectedOption.totalVoted;
            }
          });
          this._checkAllowAddMoreOption(notifiedQuestion);
        }
      }
    });
  }

  getFeed() {
    this.newsFeedService.getFeed(
      this.feedId,
      (resp: NewsFeed) => {
        if (resp && (resp.newsFeedTypeId === NewsFeedTypeEnum.WALL || resp.newsFeedTypeId === NewsFeedTypeEnum.POLL)) {
          this.feed = resp;
          if (this.feed.beginResultDate) {
            this.feed.beginResultDate = moment.utc(this.feed.beginResultDate).toDate();
          }
          if (this.feed.endResultDate) {
            this.feed.endResultDate = moment.utc(this.feed.endResultDate).toDate();
          }
          this.feed.isExpired = this.feed.endDate && DateHelper.getDistanceWithToday(this.feed.endDate) < 0;
          this.feed.isAllowUpdate = this.canUpdateFeed(this.feed);
          this.feed.isAllowDelete = this.canDeleteFeed(this.feed);
          this._checkUserLike();
          this.checkIsPollExpired();
          this.loadQuestions(this.feed.pollQuestions);
        }
      },
      (error: any) => { },
      () => {
        this.isLoading = false;
      },
    );
  }

  public scrollAuto(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  getLatestFeeds() {
    this.newsFeedService.getWall(
      this.queryFeeds,
      (resp: any) => {
        if (resp && resp.length) {
          this.latestFeeds = resp;
          this.latestFeeds.sort((a, b) => {
            return a.isImportant === b.isImportant ? (a.createdDate > b.createdDate ? -1 : 1) : (a.isImportant > b.isImportant ? -1 : 1);
          });
          this.queryFeeds.totalItems = resp[0].totalCount;
        }
      },
      (error: any) => { },
      () => {
        this.isLoading = false;
      },
    );
  }

  load() {
    this.isLoading = true;
    this.getLatestFeeds();
    this.getFeed();
  }

  onBack() {
    this.router.navigate([UrlConstant.mapNewsFeed()]);
  }

  onShowInstanceOptionResult(questionId: string, optionId: string, question: any) {
    const modal = this.modalService.open(NewsFeedPollComponent, {
      size: ModalSize.lg,
    });

    modal.componentInstance.poll = this.feed;
    modal.componentInstance.optionId = optionId;
    modal.componentInstance.questionId = questionId;
    modal.componentInstance.questionName = question?.content;
    modal.result.then((resp: any) => { });
  }

  onShowInstanceResult() {
    const modal = this.modalService.open(NewsFeedPollComponent, {
      size: ModalSize.lg,
    });
    modal.componentInstance.poll = this.feed;
    modal.result.then((resp: any) => { });
  }

  onPollSelect(question: PollQuestion, userChoice: string) {
    if (this.checkIsPollExpired()) {
      const currentChoice = question.userChoice;
      question.userChoice = userChoice;

      CommonHelper.scheduleToExecute(() => {
        question.userChoice = currentChoice;
      });
      return;
    }

    question.userChoice = userChoice;

    if (question.userChoice) {
      // add choice
      this.newsFeedService.createUserPoll(
        {
          questionId: question.id,
          userPollOptionId: question.userChoice,
          newsFeedId: this.feedId
        },
        () => {
          // Toastr.success('You have voted successfully! Thank you for your supporting.');
        },
      );
    }
  }

  onDeleteFeed(feed: NewsFeed) {
    const modal = this.modalService.open(ModalConfirmComponent, {
      size: ModalSize.lg,
    });
    modal.componentInstance.lbConfirm = 'Delete';
    modal.componentInstance.title = 'Delete post?';
    modal.componentInstance.description = 'Are you sure you want to delete this post?';

    modal.result.then(result => {
      if (result) {
        this.newsFeedService.deletePost(feed.id, (res) => {
          if (res && res.id && res.id > 0) {
            this.navigateToHome = true;
            this.router.navigate([UrlConstant.mapNewsFeed()]);
          }
        }, () => {

        });
      }
    });
  }

  onEditFeed(feed: NewsFeed) {
    const activeModal = this.modalService.open(CreatePostModalComponent, { windowClass: 'create-post-modal', size: ModalSize.xl });
    activeModal.componentInstance.data = feed;
    activeModal.componentInstance.activeModal = activeModal;
    activeModal.result.then(
      result => { },
      reason => { },
    );
  }

  registerCommentRealtime() {
    this.feedRealtimeService.addTransferCommentListener((comment: any) => {
      this.pubsub.$pub(PubSubEvent.EVENTS.ON_CREATE_COMMENT + comment.newsFeedId, comment);
    });

    this.feedRealtimeService.addTransferCommentUpdatedListener((comment: any) => {
      this.pubsub.$pub(PubSubEvent.EVENTS.ON_UPDATE_COMMENT + comment.newsFeedId, comment);
    });

    this.feedRealtimeService.addTransferCommentDeletedListener((comment: any) => {
      this.pubsub.$pub(PubSubEvent.EVENTS.ON_DELETE_COMMENT + comment.newsFeedId, comment);
    });
  }

  registerFeedRealtime() {
    this.feedRealtimeService.addTransferResultUpdatedListener((feed: NewsFeed) => {
      if (feed.id === this.feed.id) {
        this.feed = feed;
      }
    });

    this.feedRealtimeService.addTransferResultDeletedListener((feed: NewsFeed) => {
      if (feed.id === this.feed.id && !this.navigateToHome) {
        Bootbox.confirm('Alert', 'Your looking post has been deleted. You will be redirect into homepage').subscribe(res => {
          this.onBack();
        });
      }
    });
  }

  canSaveUserNote(question: PollQuestion) {
    return question && question.userNote
      && question.userNote.description
      && this.originalUserNotes
      && this.originalUserNotes[question.id] !== question.userNote.description;
  }

  onSubmitUserNote(question: PollQuestion) {
    if (question.userNote) {
      this.originalUserNotes[question.id] = question.userNote?.description;
      this.newsFeedService.saveUserPollNote(question.userNote, () => {
        this._checkAllowAddMoreOption(question);
      });
    }
  }

  onPollMultipleChoiceSelect(option, question) {
    if (this.checkIsPollExpired()) {
      return;
    }

    option.isSelected = !option.isSelected;
    if (option.isSelected) {
      // add choice
      this.newsFeedService.createUserPoll(
        {
          questionId: option.pollQuestionId,
          userPollOptionId: option.id,
          isMultipleChoice: true,
          newsFeedId: this.feedId
        },
        () => {
          this._checkAllowAddMoreOption(question);
        },
      );
    } else {
      // remove choice
      this.newsFeedService.removeUserPoll(
        {
          questionId: option.pollQuestionId,
          userPollOptionId: option.id,
          isMultipleChoice: true,
        },
        () => {
          this._checkAllowAddMoreOption(question);
        },
      );
    }
  }

  checkIsPollExpired() {
    // return DateHelper.getDistanceBetweenDate(this.feed.endResultDate, new Date(), 'minutes') > this.feed.durationChange;
    this.isPollExpired = !this.feed.endResultDate;

    if (this.feed.endResultDate) {
      const delta = this.feed.endResultDate.valueOf() - Date.now();
      const diff = DateHelper.dateDiffFromNumber(delta, {
        disableDays: true,
        disableHours: true,
      });

      this.isPollExpired = delta < 0 || diff.minutes <= (this.feed.durationChange || 0);
    }

    return this.isPollExpired;
  }

  getPollResultText(questionId: string, optionQty: number) {
    const total = this.totalVotes?.[questionId] || 0;
    const qty = optionQty || 0;

    return `${optionQty}/${total}`;
  }

  private _checkAllowAddMoreOption(question) {
    const maxVote = question.maxVote;
    const userSelected = question.options.filter(item => item.isSelected).length;
    if (maxVote) {
      question.options.map(item => {
        item.disabled = userSelected >= maxVote && !item.isSelected;
      });
    }
  }

  private _checkUserLike() {
    if (this.feed) {
      if (
        this.feed.reactions &&
        _.findIndex(this.feed.reactions, reaction => {
          return reaction.reaction === 1;
        }) >= 0
      ) {
        this.userLiked = true;
      }
    }
  }


  loadQuestions(questions: PollQuestion[]) {
    questions.forEach(question => {
      this.originalUserNotes[question.id] = question.userNote?.description;
      this.totalVotes[question.id] = 0;
      this.totalUserVotes[question.id] = 0;
      if (!question.isMultipleChoice) {
        question.userChoice = question.userChoices[0]?.userPollOptionId;
      } else {
        question.options.forEach(option => {
          option.isSelected = !!(question.userChoices || []).find(choice => choice.userPollOptionId === option.id && choice.createdBy === this.currentUser?.id);
          if (option.isSelected) {
            this.totalUserVotes[question.id] += 1;
          }
        });
        this._checkAllowAddMoreOption(question);
      }
      question.options.forEach(option => {
        this.totalVotes[question.id] += option.totalChoice;
      });
    });
  }

  getOptionPercent(questionId: string, optionQty: number) {
    if (!this.totalVotes || !this.totalVotes[questionId]) {
      return 0;
    }

    return (optionQty * 100) / this.totalVotes[questionId];
  }

  onLike(feed: NewsFeed) {
    this.reactionService.toggleFeed(
      {
        noLoading: true,
        newsFeedId: feed.id,
      },
      res => {
        if (res.id) {
          this.userLiked = !this.userLiked;
          if (this.userLiked) {
            this.feed.totalLikes++;
          } else {
            this.feed.totalLikes--;
          }

          this.feed.totalLikes = Math.max(0, this.feed.totalLikes);
        }
      },
    );
  }

  canShowTicketActions(feed: NewsFeed) {
    if (this.canUpdateFeed(feed)) {
      return true;
    }

    if (this.canDeleteFeed(feed)) {
      return true;
    }

    return false;
  }

  private canUpdateFeed(feed: NewsFeed) {
    if (!feed) {
      return false;
    }

    const result = SecurityHelper.checkPermission('wallnewsfeed', 'updatefeed');
    if (result) {
      return true;
    }

    if (this.currentUser?.id === feed.createdBy) {
      return true;
    }

    return false;
  }


  private canDeleteFeed(feed: NewsFeed) {
    if (!feed) {
      return false;
    }

    const result = SecurityHelper.checkPermission('wallnewsfeed', 'deletefeed');
    if (result) {
      return true;
    }

    if (this.currentUser?.id === feed.createdBy && result) {
      return true;
    }

    return false;
  }
}
