import { Component, OnInit, Input, ElementRef, ViewChildren, QueryList, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { BaseAdminComponent } from '@intranet/lib/components';
import { NewsFeed, Comment, SearchComment, WallNewsFeedService } from '@intranet/lib/data-access';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { PubSubEvent } from '@intranet/index';
import * as _ from 'lodash';
import { NewsFeedCommentControlComponent } from './news-feed-comment-control/news-feed-comment-control.component';

@Component({
  selector: 'app-news-feed-comment',
  templateUrl: './news-feed-comment.component.html',
  styleUrls: ['./news-feed-comment.component.scss']
})
export class NewsFeedCommentComponent extends BaseAdminComponent implements OnInit, AfterViewInit {
  @ViewChildren('replyBox') replyBoxex: QueryList<NewsFeedCommentControlComponent>;

  @Input() feed: NewsFeed;
  comments: Comment[] = [];
  searchComent = new SearchComment();
  searchReplies = {};
  displayViewMoreComment = true;
  isReplyLoading = {};
  commentReplyBoxId = null;

  constructor(
    private cd: ChangeDetectorRef,
    private myElement: ElementRef,
    private pubsub: PubSubService,
    private newsFeedService: WallNewsFeedService) {
    super();
  }

  ngOnInit() {
    this.comments = this.feed.comments;
    this.comments.map(comment => {
      this.searchReplies[comment.id] = new SearchComment();
      this.searchReplies[comment.id].parentId = comment.id;
      comment.children = this.sortComments(comment.children);
    });
    this.comments = this.sortComments(this.comments);

    this.searchComent.newsFeedId = this.feed.id;
    this.pubsub.$sub(PubSubEvent.EVENTS.ON_CREATE_COMMENT + this.feed.id).subscribe(comment => {
      if (comment.parent) {
        this.appentReply(comment);
      } else {
        this.appendComment(comment);
      }

      if (!this.feed.totalComments) {
        this.feed.totalComments = 0;
      }

      this.feed.totalComments++;
    });

    this.pubsub.$sub(PubSubEvent.EVENTS.ON_UPDATE_COMMENT + this.feed.id).subscribe(comment => {
      this.updateComment(comment);
    });

    this.pubsub.$sub(PubSubEvent.EVENTS.ON_DELETE_COMMENT + this.feed.id).subscribe(comment => {
      this.deleteComment(comment);
      if (!this.feed.totalComments) {
        this.feed.totalComments = 0;
      }

      this.feed.totalComments--;
      this.feed.totalComments = Math.max(0, this.feed.totalComments);
    });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  deleteComment(comment: Comment) {
    const parentIndex = _.findIndex(this.comments, item => item.id === comment.parentId);
    if (comment.parentId && parentIndex > -1) {
      _.remove(this.comments[parentIndex].children, item => item.id === comment.id);
    }
    else {
      _.remove(this.comments, item => item.id === comment.id);
    }
  }

  updateComment(comment: Comment) {
    if (comment.parentId) {
      const parentIndex = _.findIndex(this.comments, item => item.id === comment.parentId);
      const index = _.findIndex(this.comments[parentIndex], item => item.id === comment.id);
      this.comments[index].children[comment.id] = comment;
    }
    else {
      const index = _.findIndex(this.comments, item => item.id === comment.id);
      this.comments[index] = comment;
    }

    this.comments = this.sortComments(this.comments);
  }

  appendComment(comment: Comment) {
    const existingIndex = _.findIndex(this.comments, item => item.id === comment.id);
    if (existingIndex < 0) {
      this.comments.push(comment);
      if (comment.children && comment.children.length) {
        comment.children.map(reply => {
          this.searchReplies[comment.id] = new SearchComment();
          this.searchReplies[comment.id].parentId = comment.id;
          this.isReplyLoading[comment.id] = false;
        });
      }
      this.comments = this.sortComments(this.comments);
    }
  }

  appentReply(reply: Comment) {
    const parentIndex = _.findIndex(this.comments, item => item.id === reply.parentId);
    if (parentIndex >= 0) {
      const existingIndex = _.findIndex(this.comments[parentIndex].children, child => child.id === reply.id);
      if (existingIndex < 0) {
        this.comments[parentIndex].children = this.comments[parentIndex].children || [];
        this.comments[parentIndex].children.push(reply);
        this.comments[parentIndex].children = this.sortComments(this.comments[parentIndex].children);
      }
    }
  }

  sortComments(comments: Comment[]) {
    return comments.sort((a, b) => (a.createdDate > b.createdDate ? 1 : a.createdDate === b.createdDate ? (a.createdDate > b.createdDate ? 1 : -1) : -1));
  }

  onReply(comment: Comment, replyBox: NewsFeedCommentControlComponent, parent?: Comment) {
    if (!comment || !replyBox || !comment.user) {
      return;
    }
    if (parent !== undefined && parent !== null) {
      this.commentReplyBoxId = parent.id;
    } else {
      this.commentReplyBoxId = comment.id;
    }
    replyBox.reset();
    replyBox.mention(comment.user);
    this.animationReplyInput(this.commentReplyBoxId);
  }

  successReplyBox() {
    this.commentReplyBoxId = null;
  }

  onShowMoreReplies(item: Comment) {
    this.isReplyLoading[item.id] = true;
    const request = this.searchReplies[item.id];
    this.newsFeedService.getComments(
      request,
      (res: Comment[]) => {
        if (res) {
          res.map((reply: Comment) => {
            this.appentReply(reply);
          });
          this.searchReplies[item.id].pageNumber++;
        }
      },
      error => { },
      () => {
        this.isReplyLoading[item.id] = false;
      },
    );
  }

  onShowMoreComments() {
    const request = this.searchComent;
    this.isLoading = true;
    this.newsFeedService.getComments(
      request,
      (res: Comment[]) => {
        if (res) {
          if (res.length === 0) {
            this.displayViewMoreComment = false;
          } else {
            res.map((comment: Comment) => {
              this.appendComment(comment);
            });
          }
          this.searchComent.pageNumber++;
        }
      },
      error => { },
      () => {
        this.isLoading = false;
      },
    );
  }

  private animationReplyInput(id: string) {
    const element = this.myElement.nativeElement.ownerDocument
      .getElementById(`reply-${id}`) as HTMLElement;
    const y = element.getBoundingClientRect().top + window.pageYOffset - 250;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}
