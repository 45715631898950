import { DocumentTypeEnum } from './enums/document.enum';

export class DocumentListDto {
  documentId: string;
  documentName: string;
}

export class DocumentDto {
  documentId: string;
  objectId: string;
  documentType: DocumentTypeEnum;
  documentSubType: number;
  documentName: string;
  documentUrl: string;
  description: string;
}
