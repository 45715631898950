import { Injectable } from '@angular/core';
import { HttpClientService, Toastr } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { MediaTypeEnum, NewsFeed, Ticket } from '../models';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class WallNewsFeedService extends BaseService {
  cdnUrl = AppConfig.settings.CDN_URL + '/cdn/Image';
  hostUrl: string = AppConfig.settings.API_URL + '/api/wall';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getImageUrl(url: string) {
    return `${AppConfig.settings.CDN_URL}/cdn/image/${url}`;
  }

  getFileUrl(id: string) {
    if (!id) return '';

    return `${AppConfig.settings.CDN_URL}/cdn/file/download/${id}`;
  }

  uploadBlob(blob: Blob, name: string) {
    const url = AppConfig.settings.API_URL + '/cdn/image/upload_file';
    const formData: FormData = new FormData();

    formData.append('files', blob, name);

    formData.append('mediaType', MediaTypeEnum.IMAGE.toString());

    return this.httpClient.postSync(url, formData);
  }

  uploadImages(files: any[]) {
    const url = AppConfig.settings.API_URL + '/cdn/Image/upload_file';
    const formData: FormData = new FormData();

    files.map(file => {
      formData.append('files', file, file.name);
    });

    formData.append('mediaType', MediaTypeEnum.IMAGE.toString());

    return this.httpClient.postSync(url, formData);
  }

  getComments(
    params: any,
    successCallback?: (resp?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'get-comments';
    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getFeed(
    guidId: string,
    successCallback?: (resp?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'get-feed-by-id';
    const params = {
      noLoading: true,
      guidId,
    };

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getUsers(
    context: any,
    successCallback?: (resp?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'get-suggestion-users';
    const params = context;
    params.noLoading = true;

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getWebsiteMeta(
    requestUrl: string,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'get-meta-data-by-url';
    const params = {
      noLoading: true,
      url: requestUrl,
    };

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  createPoll(
    data: NewsFeed,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = ``;

    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }

  createPost(
    data: NewsFeed,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = ``;

    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }

  updatePost(
    data: NewsFeed,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = ``;

    return this.updateUrl(url, data, undefined, successCallback, failedCallback, completeCallback);
  }

  updatePoll(
    data: NewsFeed,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = ``;

    return this.updateUrl(url, data, undefined, successCallback, failedCallback, completeCallback);
  }

  deletePost(
    id: string,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = ``;
    const noLoading = true;
    const params = {
      noLoading,
      id,
    };

    return this.deleteByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  deleteTicket(
    id: string,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `delete-ticket`;
    const noLoading = true;
    const params = {
      noLoading,
      id,
    };

    return this.deleteByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getHashtagFeed(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `get-user-wall`;

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  search(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `search`;

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  createComment(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `create-comment`;
    const data = params || {};
    data.noLoading = true;
    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }

  deleteComment(
    id: string,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `delete-comment`;
    const noLoading = true;
    const params = {
      noLoading,
      id,
    };
    return this.deleteByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getAnnoucements(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `get-announcements`;

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getTickets(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `get-tickets`;

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getTicket(
    guidId: string,
    successCallback?: (resp?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'get-ticket';
    const params = {
      noLoading: true,
      id: guidId,
      pageNumber: 1,
      pageSize: 1,
    };

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  // getStickies(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
  //   const url = `get-stickies`;

  //   return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  // }

  getLatestFeeds(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `get-latest-feed`;

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getWall(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `get-user-wall`;

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getStickies(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `get-highlights`;

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  removeUserPoll(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `${this.hostUrl}/remove-user-poll?optionId=${params.userPollOptionId}&questionId=${params.questionId}`;

    return new Promise((resolve, reject) => {
      this.httpClient
        .deleteSync(url, params)
        .then(
          (resp: any) => {
            if (resp.id && resp.id < 0) {
              Toastr.error(resp.message || resp.Message);
            } else {
              // in case service return an array of objects
              if (successCallback) {
                successCallback(resp);
              }
              resolve(resp);
            }
          },
          (error: any) => {
            if (failedCallback) {
              failedCallback(error);
              reject(error);
            } else {
              Toastr.error(error.message);
            }
          },
        )
        .finally(() => {
          if (completeCallback) {
            completeCallback();
          }
        });
    });
  }

  saveUserPollNote(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'save-user-poll-note';
    params.noLoading = true;
    return this.postByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  sendCelebrateBirthday(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'send-celebrate-birthday';
    params.noLoading = true;
    return this.postByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  sendCelebrateWorkAnniversary(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'send-celebrate-work-anniversary';
    params.noLoading = true;
    return this.postByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  createUserPoll(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `create-user-poll`;
    const data = params || {};
    data.noLoading = true;
    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }

  getPollResult(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `get-poll-result`;
    const data = params || {};
    data.noLoading = true;
    return this.getByUrl(url, data, successCallback, failedCallback, completeCallback);
  }

  getGroupWall(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `get-group-wall`;

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getGroupAnnoucements(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `group-get-announcements`;

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  savePollReward(
    params: any,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'update-poll-point';
    params.noLoading = true;
    return this.postByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  updateTicket(
    data: Ticket,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `update-ticket`;

    return this.updateUrl(url, data, undefined, successCallback, failedCallback, completeCallback);
  }

  updateTicketStatus(
    data: Ticket,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `update-ticket-status`;

    return this.updateUrl(url, data, undefined, successCallback, failedCallback, completeCallback);
  }

  createTicket(
    data: Ticket,
    successCallback?: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = `create-ticket`;

    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }
}
