import { BaseModel } from './base-model';
import { NewsFeedTypeEnum } from './enums/news-feed-type.enum';
import { MediaTypeEnum } from './enums/media-type.enum';
import { TicketTypeEnum } from './enums/ticket-type.enum';
import { PollQuestion } from './poll-question.model';
import { UserModel } from './user-model';
import { Comment } from './comment.model';
import { InternalUploadFileResponseModel } from './bases/internal-upload-file.model';

export class NewsFeed extends BaseModel {
  id?: string;
  title?: string;
  user?: UserModel;
  targetUser?: UserModel;
  createdDate?: Date;
  description?: string;
  shortDescription?: string;
  contentHtml?: string;
  newsFeedTypeId?: NewsFeedTypeEnum;
  durationChange?: number;
  beginDate?: Date;
  endDate?: Date;
  beginHighlightDate?: Date;
  endHighlightDate?: Date;
  beginResultDate?: Date;
  endResultDate?: Date;
  allowLike?: boolean;
  allowComment?: boolean;
  publicityLevel?: number;
  totalViews?: number;
  totalLikes?: number;
  totalComments?: number;
  isShow?: boolean;
  isImportant?: boolean;
  order?: number;
  departments?: string;
  departmentGroups: any;
  createdBy?: string;
  targetUserId?: string;
  reactions?: any;
  comments?: Comment[];
  pollQuestions: PollQuestion[];
  media?: NewsFeedMedia[];
  mentions?: [];
  hashtags?: [];
  isExpired?: boolean;
  allowViewInstanceResult: boolean;
  isSendNotification?: boolean;
  isCreate?: boolean;
  files?: InternalUploadFileResponseModel[];
  status?: number;
  enableRewardPoint?: boolean;
  isNewcomerAnnouncement?: boolean;

  expandFeed?: boolean;

  isAllowUpdate: boolean;
  isAllowDelete: boolean;
}

export class NewsFeedMedia extends BaseModel {
  id?: string;
  newsFeedId?: string;
  name?: string;
  path?: string;
  mediaTypeId?: MediaTypeEnum;
}
