<!-- <div class="left side-menu">
  <div class="slimScrollDiv active" style="position: relative; overflow: hidden; width: auto; height: 788px;">
    <div class="slimscroll-menu in" id="remove-scroll" style="overflow: hidden; width: auto; height: 788px;">
      <div id="sidebar-menu" class="active">
        <ul class="metismenu in" id="side-menu">
          <li class="menu-title">Main</li>
          <ng-container *ngFor="let menu of menus">
            <app-menu-item [menu]="menu" remove-host></app-menu-item>
          </ng-container>
        </ul>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="slimScrollBar"
      style="background: rgb(158, 165, 171); width: 7px; position: absolute; top: 0px; opacity: 0.4; display: none; border-radius: 7px; z-index: 99; right: 1px; height: 788px;"></div>
    <div class="slimScrollRail" style="width: 7px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; right: 1px;">
    </div>
  </div>
</div> -->
<nav class="navbar navbar-expand-lg custom-navbar">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#WafiAdminNavbar" aria-controls="WafiAdminNavbar" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon">
      <i></i>
      <i></i>
      <i></i>
    </span>
  </button>
  <!-- <div class="collapse navbar-collapse" id="WafiAdminNavbar" *hasPermission="['menu', 'getadminmainmenu', true]">
    <ul class="navbar-nav">
      <ng-container *ngFor="let menu of menus">
        <app-menu-item [menu]="menu" [paramLink]="paramLink" remove-host></app-menu-item>
      </ng-container>
    </ul>
  </div> -->

  <app-menu [menu]="menu" [menuType]="MenuTypeEnum.AdminMainMenu" [template]="adminMainMenuTpl" [options]="menuOptions">
  </app-menu>
  <ng-template #adminMainMenuTpl let-menuList="menu">
    <div class="collapse navbar-collapse" id="WafiAdminNavbar">
      <ul class="navbar-nav flex-wrap">
        <ng-container *ngFor="let menu of menuList">
          <li class="nav-item dropdown">
            <!-- Has menu.url (and menu.meta) -->
            <a class="nav-link dropdown-toggle menu-item" *ngIf="menu.url && menu.meta" [routerLink]="menu.url"
              [ngClass]="{'not-child': !menu.children || !menu.children.length, 'active-page': menu.meta.isActivated}"
              id="dashboardsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <nz-badge [nzShowZero]="false" [nzCount]="menu.badge || 0" [nzStyle]="{ 'font-size': '11px', 'box-shadow': 'none', 'background-color': menu.badgeColor }" class="nav-icon menu-badge" [nzOverflowCount]="99">
                <i class="{{menu.icon}} nav-icon"></i>
              </nz-badge>
              {{ menu.title }}
            </a>

            <!-- Doesn't have menu.url (but has menu meta) -->
            <a class="nav-link dropdown-toggle menu-item" *ngIf="!menu.url && menu.meta"
              [ngClass]="{'not-child': !menu.children || !menu.children.length, 'active-page': menu.meta.isActivated}"
              routerLinkActive="active-page" id="dashboardsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <nz-badge [nzShowZero]="false" [nzCount]="menu.badge || 0" [nzStyle]="{ 'font-size': '11px', 'box-shadow': 'none', 'background-color': menu.badgeColor }" class="nav-icon menu-badge" [nzOverflowCount]="99">
                <i class="{{menu.icon}} nav-icon"></i>
              </nz-badge>
              {{ menu.title }}
            </a>

            <!-- Has menu.children -->
            <ul *ngIf="menu && menu.children && menu.children.length" class="dropdown-menu" aria-labelledby="dashboardsDropdown">
              <li *ngFor="let item of menu.children">
                <a class="dropdown-item" routerLinkActive="active-page" [routerLink]="item.url">
                {{ item.title }}
                <span class="menu-badge">
                  <nz-badge *ngIf="item.badge && item.badge > 0" nzStandalone [nzCount]="item.badge" class="submenu-badge" [nzStyle]="submenuBadgeStyle"></nz-badge>
                </span>
                </a>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-template>

</nav>
