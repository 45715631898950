import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { CDNService } from './cdn-server.service';
import { Gift } from '../models/gift.model';

@Injectable({
  providedIn: 'root',
})
export class CompensationHourService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/compensation-hour';

  constructor(httpClient: HttpClientService, private cdnService: CDNService) {
    super(httpClient);
  }

  view(searchData: any, scb?: any, fcb?: any, ccb?: any) {
    return this.getAll(searchData, scb, fcb, ccb);
  }

  getRegisteredUsers(searchData: any, scb?: any, fcb?: any, ccb?: any) {
    const url = `registered-users`;
    return this.getByUrl(url, searchData, scb, fcb, ccb);
  }

  create(data: any, scb?: any, fcb?: any, ccb?: any) {
    return this.add(data, scb, fcb, ccb);
  }

  update(data: any, scb?: any, fcb?: any, ccb?: any) {
    return super.update(data, scb, fcb, ccb);
  }

  delete(data: any, scb?: any, fcb?: any, ccb?: any) {
    return super.deleteById(data, scb, fcb, ccb);
  }
}
