export class UrlConstant {
  static readonly ADMIN_FIRSTPAGE = '/dashboard';
  static readonly USER_FIRSTPAGE = '/dashboard';
  static readonly GROUP_FIRSTPAGE = '/groups';
  static readonly NEWFEEDS_FIRSTPAGE = '/dashboard';
  static readonly CLIENT_PAGE = '/client';
  static readonly PROFILE_PAGE = '/dashboard';
  static readonly POLICY_PAGE = '/policy';
  static readonly NOT_PERMISSION_PAGE = '/not-permission';
  static readonly SEARCH_PAGE = '/search';
  static readonly AdminUrl = {
    ROOT: 'admin',
    DASHBOARD: 'dashboard',
    EMPLOYEE: 'employee',
    EMPLOYEE_DETAIL: 'employee-edit/:id',
    EMPLOYEE_TNM: 'employee-tnm',
    POSITION: 'position',
    DEPARTMENT: 'employee-group',
    SKILLS: 'skills',
    SUPPLIER: 'supplier',
    EQUIPMENT: 'equipment-category',
    SUPPLIER_EQUIPMENT: 'supplier-equipment',
    USER: 'user',
    GROUP_USER: 'groupuser',
    LEAVE_TYPE: 'type',
    LEAVE_SETTING: 'leave-setting',
    LEAVE_REQUEST: 'request',
    LEAVE_ANNUAL: 'annual-leave',
    LEAVE_TRANSFER: 'leave-transfers',
    LEAVE_ANNUAL_REQUEST: 'annual-leave-request',
    MONTHLY_MISSING_HOURS: 'monthly-missing-hours',
    PURCHASE_REQUEST: 'purchase-request',
    PURCHASE_CREATE: 'purchase-create',
    PURCHASE_ORDER: 'purchase-order',
    WAREHOUSE: 'warehouse',
    WAREHOUSE_DETAIL: 'warehouse-detail',
    MANAGE_EQUIPMENT: 'manage-equipment',
    DELIVERY_FORM: 'delivery-form',
    TIME_KEEPER: 'time-keeper',
    TIME_KEEPER_PERSONAL: 'time-keeper-personal',
    TIME_KEEPER_USER: 'time-keeper-user',
    TIME_KEEPER_MACHINE: 'time-keeper-machine',
    WORK_TIME_SETTING: 'worktime-setting',
    SCHEDULE_GROUP: 'schedule-group',
    LEAVE: 'leave',
    PROFILE: 'profile',
    ROOM: 'room',
    MEETING_ROOM: 'meeting-room',
    NEWFEEDS: 'news-feed',
    NEWFEED_TYPE: 'news-feed-type',
    NEWFEED_ANNOUNCEMENTS: 'news-feed-annoucement',
    NEWFEED_POLLS: 'news-feed-polls',
    NEWFEED_STICKY: 'news-feed-sticky',
    NEWFEED_SURVEYS: 'surveys',
    NEWFEED_EVENTS: 'events',
    NEWFEED_SURVEY_APPROVE: 'survey-approve',
    COMPENSATION_HOUR: 'compensation-hours',
    TICKET: 'tickets',
    TICKET_TYPE: 'ticket-type',
    PROJECT: 'project',
    PRODUCT: 'product',
    PRODUCT_PACKAGE: 'product-package',
    TEMPLATE_EMAIL: 'template-email',
    TEMPLATE_HTML: 'template-html',
    WIDGET_SETUP: 'widget-setup',
    SYSTEM: 'system',
    INVOICE: 'invoice',
    POLICY: 'policy',
    NEWSFLASH: 'newsflash',
    RESIGNATION: 'resignation',
    USER_TIER: 'tier-user',
    BENEFIT: 'benefits',
    BENEFIT_REGISTRATION: 'registrations',
    BENEFIT_MANAGEMENT: 'managements',
    BENEFIT_CATEGORY: 'categories',
    GIFT_MANAGING: 'gift',
    PDF_TEMPLATES: 'pdf-templates',
    REDEMPTION_REQUESTS: 'redemption-requests',
    COMPENSATION_HOUR_MANAGEMENT: 'compensation-hour-management',
    ANNUAL_REVIEW: 'annual-reviews',
    ANNUAL_REVIEW_SETTINGS: 'settings',
    ANNUAL_REVIEW_CRITERIA: 'criteria-types',
    ANNUAL_REVIEW_RATING: 'ratings',
    ANNUAL_REVIEW_COLUMN_TEMPLATE: 'column-templates',
    ANNUAL_REVIEW_TEMPLATE: 'templates',
    ANNUAL_REVIEW_STATUS_FORM: 'status-forms',
    ANNUAL_REVIEW_FORM: 'forms',
    FRAME: 'frames',
    FRAME_LEVEL: 'frame-levels',
    EMPOYEE_POSITION: 'employee-positions',
    WORK_FROM_HOME_USER: 'work-from-home-user',
    CHECKINOUT_MANAGEMENT: 'checkinout-request',
    LOGOUT: '/',
  };

  static readonly UserUrl = {
    DASHBOARD: 'dashboard',
    ROOT: 'user',
    PROFILE: 'profile',
    LEAVE: 'leave',
    PURCHASE: 'purchase-user',
    PURCHASE_CREATE: 'purchase-user-create',
    PURCHASE_UPDATE: 'purchase-user-update',
    SETTINGS: 'settings',
    POLICY: 'policy',
    MESSAGE: 'message',
    BOOKING: 'calendar',
    NEWSFEED: 'news-feed',
    CALENDAR: 'calendar',
    COMPENSATION_HOUR: 'compensation-hours',
    REVIEW_PERFORMANCE: 'review-performances',
    BENEFIT: 'benefit',
  };

  static readonly GameUrl = {
    ROOT: 'games',
    CHRISTMAS_GAME: 'christmas',
  };
  static readonly DemoUrl = {
    NEWSFEED: 'news-feed',
    DETAIL: 'detail',
    LEAVE: 'leave',
  };
  static readonly ProjectUrl = {
    ROOT: 'project',
    DETAIL: 'detail',
    WIKI: 'wiki',
    DASHBOARD: 'dashboard',
    BACKLOGS_BACKLOG: 'backlogs/backlog',
    BACKLOGS_BOARD: 'backlogs/board',
    SPRINT_BACKLOG: 'sprint/backlog',
    SPRINT_TASKBOARD: 'sprint/taskboard',
    PROJECT_WORK_ITEM: 'workitems',
    PROJECT_CONFIG_STATE: 'projectstate',
  };

  static readonly NewsFeedUrl = {
    ROOT: 'news-feed',
    DETAIL: 'detail',
    ACTIVITIES: 'recent-activities',
    ANNOUNCEMENTS: 'announcements',
    ANNOUNCEMENT_DETAILS: 'announcement-details',
    USER: 'user',
    DEPARTMENT: 'deparment',
    HASHTAG: 'hashtag',
    CALENDAR: 'calendar',
    SURVEY: 'survey',
    SURVEYS: 'surveys',
    MESSAGE: 'messages',
    NOTIFICATION: 'notifications',
    ORGCHART: 'orgchart',
    TICKET_REPORTER: 'ticket-reporter',
    TICKET_DETAILS: 'ticket-details',
    REDEEM_GIFTS: 'redeem-gifts',
    SEARCH: 'search',
  };
  static readonly GroupUrl = {
    ROOT: 'groups',
    NEWSFEED: 'news-feed',
    ANNOUNCEMENT: 'announcement',
    CALENDAR: 'calendar',
    DISCUSSION: 'discussion',
    EVENT: 'event',
  };

  static readonly ClientUrl = {
    REVIEW_FORM: 'review-forms',
  };

  static readonly Utilities = [
    {
      url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.EMPLOYEE_TNM),
      allowed: true,
    },
  ];

  static MS_TEAMS_WEB_APP = 'https://teams.microsoft.com/l/chat/0/0?users=';

  static mapAdmin(url?: string) {
    return '/' + UrlConstant.AdminUrl.ROOT + `${url ? '/' : ''}` + url;
  }

  static mapUser(url?: string) {
    return '/' + UrlConstant.UserUrl.ROOT + `${url ? '/' : ''}` + url;
  }

  static mapProject(url?: string) {
    return '/' + UrlConstant.ProjectUrl.ROOT + `${url ? '/' : ''}` + url;
  }

  static mapNewsFeed(url?: string) {
    return '/' + UrlConstant.NewsFeedUrl.ROOT + `${url ? '/' : ''}` + (url ? url : '');
  }
  static mapGroup(url?: string) {
    return '/' + UrlConstant.GroupUrl.ROOT + `${url ? '/' : ''}` + (url ? url : '');
  }

  static mapGame(url?: string) {
    return '/' + UrlConstant.GameUrl.ROOT + (url ? `/${url}` : '');
  }
}
