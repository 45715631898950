<div class="mt-3">
  <h4>Overall Assessment</h4>

  <div class="mt-2">
    <nz-descriptions [nzColumn]="section?.children?.length" nzTitle="Scores" nzLayout="vertical" nzBordered>
      <nz-descriptions-item *ngFor="let childrenSection of section.children" [nzTitle]="childrenSection?.title">
        <nz-descriptions [nzColumn]="2" nzLayout="vertical" nzBordered>
          <nz-descriptions-item [nzTitle]="'Scores'">
            {{childrenSection?.sectionByMember?.scoreNumber | number:'1.1-1'}}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Proportion">
            {{childrenSection.proportion}} %
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-descriptions-item>

      <nz-descriptions-item nzTitle="Overall Score">
        <nz-descriptions [nzColumn]="1" nzBordered>
          <nz-descriptions-item nzTitle="Total">
            <span> {{section?.sectionByMember?.scoreNumber | number:'1.1-1'}}</span>
          </nz-descriptions-item>
        </nz-descriptions>
      </nz-descriptions-item>
    </nz-descriptions>
  </div>

  <div class="mt-2">
    <nz-descriptions nzTitle="Comments" nzLayout="vertical" [nzColumn]="1" nzBordered>
      <nz-descriptions-item nzTitle="Comments by Appraisee">
        {{section?.sectionByMember?.commentMember}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Comments by Appraiser">
        {{section?.sectionByMember?.commentAppraiser}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Client's feedback (Optional)">
        {{section?.sectionByMember?.commentClient}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Comments by Team Colleagues (Optional)">
        {{section?.sectionByMember?.commentTeam}}
      </nz-descriptions-item>
    </nz-descriptions>
  </div>
</div>
