<app-spinner [data]="formMember?.sections" [errorText]="errorText">
    <div class="review-form-member-result-container">
        <div class="title">
            <h3>Performance Appraisal Form Information</h3>
        </div>
        <div class="review-form-member-result-header">
            <div class="appraisee">
                <div class="info-item">
                    <div><b>MONTH:</b></div>
                    <div>{{formMember?.monthForm}}/{{formMember?.yearForm}}</div>
                </div>
                <div class="info-item">
                    <div><b>APPRAISEE:</b></div>
                    <div>
                        <app-avatar [size]="25" [avatarSource]="formPersonInformation[formMember?.id]?.avatarUrl" 
                              [avatarText]="formPersonInformation[formMember?.id]?.fullName"></app-avatar>
                        {{userInformations[formMember?.userId]?.fullName}}
                    </div>
                </div>
                <div class="info-item">
                    <div><b>JOINED DATE:</b></div>
                    <div>
                        {{userInformations[formMember?.userId]?.joinedDate | date}}
                    </div>
                </div>
                <div class="info-item">
                    <div><b>CURRENT POSITIONS:</b></div>
                    <div>
                        {{userInformations[formMember?.userId]?.positions}}
                    </div>
                </div>
            </div>
            <div class="appraiser">
                <div class="info-item appraiser-form">
                    <div><b>FORM:</b></div>
                    <div>{{formMember?.titleForm}}</div>
                </div>
                <div class="info-item appraiser-persons">
                    <div><b>APPRAISER</b></div>
                    <div>
                        <div *ngFor="let appraiser of formMember?.appraisers">
                            <app-avatar [size]="25" [avatarSource]="formPersonInformation[appraiser?.id]?.avatarUrl" 
                              [avatarText]="formPersonInformation[appraiser?.id]?.fullName">
                            </app-avatar>
                            <span class="mr-2">{{userInformations[appraiser?.userId]?.fullName}}</span>
                            is 
                            <b class="ml-2">{{userInformations[appraiser?.userId]?.positions}}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="review-form-member-result-body">
            <div *ngFor="let section of formMember?.sections">
                <nz-divider [nzText]="'Section: ' + section?.title"></nz-divider>
        
                <ng-container *ngIf="section?.children?.length > 0">
                    <ng-container *ngFor="let childSection of section?.children">
                        <app-review-section-result [section]="childSection" [isChild]="true" [formPersonInformation]="formPersonInformation"></app-review-section-result>
                    </ng-container>
    
                    <ng-container *ngIf="section?.isOverall">
                        <app-review-overall-result [section]="section"></app-review-overall-result>
                    </ng-container>
                </ng-container>
        
                <ng-container *ngIf="section?.children.length === 0" 
                    [ngTemplateOutlet]="rootSectionHasValueTemplate" [ngTemplateOutletContext]="{section: section}">
                </ng-container>
            </div>
        </div>
    </div>
</app-spinner>

<ng-template #rootSectionHasValueTemplate let-section='section'>
    <app-review-section-result [section]="section" [formPersonInformation]="formPersonInformation"></app-review-section-result>
</ng-template>