export class TimekeeperMachine {
  id: string;
  ipAddress: string;
  deviceTime: Date;
  port: number;
  name: string;
  status: boolean;
  zoneAddress: string;
  zonePort: number;
  zoneName: string;
  isMaster: boolean;
  hasFace: boolean;
  hasFingerPrint: boolean;
  hasCard: boolean;
  isActive: boolean;
}
