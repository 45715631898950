import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-all-page',
  templateUrl: './loading-all-page.component.html',
  styleUrls: ['./loading-all-page.component.scss']
})
export class LoadingAllPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
