import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/index';

@Injectable({
  providedIn: 'root',
})
export class WorkFromHomeUserService {
  hostUrl = '/api/work-from-home-user';

  constructor(private httpClient: HttpClientService) {}

  view(searchData: any): Promise<any> {
    return this.httpClient.getObservable(this.hostUrl, searchData).toPromise();
  }

  getUsers(): Promise<any> {
    const url = `${this.hostUrl}/users`;
    return this.httpClient.getObservable(url, null).toPromise();
  }

  updateUsers(updateData: any): Promise<any> {
    return this.httpClient.putObservable(this.hostUrl, updateData).toPromise();
  }
}
