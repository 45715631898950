import { LoadingService, NgkLoadingConfig, ngkLoadingAnimationTypes } from 'ngk-loading';
import { AppInjector } from './app-injector';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
export class Loading {
  static show(name?: string, options?: any) {
    // $('body').addClass('cursor-wait');
    // setTimeout(() => {
    //   const loading = AppInjector.getService<NgxSpinnerService>(NgxSpinnerService);
    //   loading.show(name);
    // }, 100);
    // if (elm) {
    //   let config: NgkLoadingConfig = {
    //     backDrop: true,
    //     fullScreenBackdrop: false,
    //     primaryColour: 'rgb(0, 119, 221)',
    //     secondaryColour: 'rgb(221, 140, 0)',
    //     tertiaryColour: 'rgb(0, 119, 221)',
    //     backCircle: false,
    //     animationType: ngkLoadingAnimationTypes.doubleBounce,
    //   };
    //   if (options) {
    //     config = { ...config, ...options };
    //   }
    //   loading.showContainer(elm, undefined, config);
    // } else {
    //   loading.show();
    // }
  }
  static showOnPage(name?: string, options?: any) {
    // $('body').addClass('cursor-wait');
    // const loading = AppInjector.getService<NgxSpinnerService>(NgxSpinnerService);
    // loading.show(name);
  }
  static showImg(elm = null, options?: NgkLoadingConfig) {
    // const loading = AppInjector.getService<NgxSpinnerService>(NgxSpinnerService);
    // $('body').addClass('cursor-wait');
    // if (elm) {
    //   let config: NgkLoadingConfig = {
    //     backDrop: false,
    //     fullScreenBackdrop: false,
    //     animationType: ngkLoadingAnimationTypes.none,
    //   };
    //   if (options) {
    //     config = { ...config, ...options };
    //   }
    //   // loading.showContainer(elm, '<p>Loading...</p>', config);
    // } else {
    //   loading.show();
    // }
  }
  static hide(name?: string) {
    // const loading = AppInjector.getService<NgxSpinnerService>(NgxSpinnerService);
    // loading.hide(name);
    // setTimeout(() => {
    //   $('body').removeClass('cursor-wait');
    // }, 100);
  }
  static hideAll() {
    // const loading = AppInjector.getService<NgxSpinnerService>(NgxSpinnerService);
    // loading.hide();
    // // const allSpinners = loading.getSpinner();
    // $('body').removeClass('cursor-wait');
  }
}
