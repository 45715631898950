import { Component, OnInit, Input } from '@angular/core';
import { ImageTransform } from 'ngx-image-cropper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CropOptions } from '../../data-access';
import { Loading } from '../../settings';
import { CommonHelper } from 'projects/intranet/hrm-lib';

@Component({
  selector: 'crop-image',
  templateUrl: './crop-image.component.html',
  styles: [],
})
export class CropImageComponent implements OnInit {
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  cropButtonText = null;

  option: CropOptions = {
    resizeToWidth: 0,
    resizeToHeight: 0,
    cropperMinHeight: 0,
    cropperMinWidth: 0,
    cropperStaticHeight: 0,
    cropperStaticWidth: 0,
    aspectRatio: 1 / 1,
    hideResizeSquares: false,
    roundCropper: false,
  };

  @Input()
  set options(value: any) {
    if (value) {
      this.option = { ...this.option, ...value };
    }
  }
  constructor(private modalActive: NgbActiveModal) {
    // Loading.show();
  }
  imgUrl: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  file: any;

  ngOnInit() { }

  fileChangeEvent(event: any): void {
    // Loading.show();
    this.imageChangedEvent = event;
  }
  imageCropped(event: any) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // Loading.hide();
    // show cropper
  }
  cropperReady() {
    // Loading.hide();
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  crop() {
    this.modalActive.close({ id: 1, file: this.croppedImage, base64: this.croppedImage });
  }
  close() {
    this.modalActive.close();
  }
}
