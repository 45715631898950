import { Injectable } from '@angular/core';
import { HttpClientService, Loading } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class WorkTimeService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/worktime';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  claimWorkTime(payload: { date: string }): Observable<void> {
    const url = '/api/worktime/claim';

    return this.httpClient.postObservable(url, payload);
  }

  getClaimWorkTime(): Observable<{
    date: string;
    isEffected: boolean;
  }> {
    const url = '/api/worktime/claim';

    return this.httpClient.getObservable(url);
  }

  updateSetting(params: any) {
    const url = '/api/worktime/setting';
    return this.httpClient.putObservable(url, params);
  }

  getSetting(successCallback?: (params?: any) => void) {
    Loading.show();
    const url = 'setting';
    return this.getByUrl(url, undefined, successCallback);
  }

  getForPersonal(params: any, successCallback?: (params?: any) => void) {
    const url = 'get-for-personal';
    return this.getByUrl(url, params, successCallback);
  }

  SyncWorkTime(params: any, successCallback?: (params?: any) => void) {
    const url = 'sync-work-time';
    return this.postByUrl(url, params, successCallback);
  }

  updateInfoWorkingDetail(data: any) {
    const url = this.hostUrl + `/update-work-time-detail`;
    return this.httpClient.putSync(url, data);
  }
  updateCalculatorScheduleGroup(data) {
    const url = this.hostUrl + `/update-calculator-schedule-worktime`;
    return this.httpClient.putSync(url, data);
  }

  getPersonalWorkTime(request: any): Observable<any> {
    const url = `/api/employee/${request?.employeeId}/work-times`;
    return this.httpClient.getObservable(url, request);
  }

  getWorkTimeTodayByUser(userId: any) {
    const url = `/api/user/${userId}/work-times`;
    return this.httpClient.getObservable(url, {});
  }

  updateWorkTimeTodayByUser(request: any) {
    const url = `/api/user/${request.userId}/work-times`;
    return this.httpClient.putObservable(url, request);
  }
}
