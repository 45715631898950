<ng-container *ngIf="widget">
  <nz-card [nzBordered]="false" [nzLoading]="loading">
    <div class="widget-header">
      <div class="widget-title">{{widget.data.title}}</div>
      <div class="widget-control widget-control-sm-fully">
        <div *ngIf="data && data.departmentGroups && data.departmentGroups.length > 1">
          <nz-select (ngModelChange)="modelChanged($event)" nzShowSearch nzAllowClear nzPlaceHolder="Select a groups"
            [(ngModel)]="searchData.departmentIds">
            <nz-option *ngFor="let option of data.departmentGroups" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>
    <div class="custom-scrollbar" *ngIf="data">
      <div class="scroll-area-lg">
        <div class="row">
          <div class="col-md-12 bg-box">
            <ul class="hr-statistic">
              <li class="hr-statistic-line">
                <span class="hr-statistic-left">
              <span class="hr-statistic-icon"><i class="fas fa-users"></i></span>
                <span class="hr-statistic-info">
                <span class="hr-statistic-title">Total Employees</span>
                <span class="hr-statistic-number d-block">{{data.totalEmployee}}</span>
                </span>
                </span>
                <span class="hr-statistic-right fs-18 text-success"></span>
              </li>
              <li class="hr-statistic-line">
                <span class="hr-statistic-left">
                  <span class="hr-statistic-icon"><i class="fas fa-user-alt"></i></span>
                <span class="hr-statistic-info">
                        <span class="hr-statistic-title">New Employees</span>
                <span class="hr-statistic-number d-block">{{data.numberNewEmployee}}</span>
                </span>
                </span>
                <span *ngIf="data.numberNewEmployee" class="hr-statistic-right fs-18 text-success"><i class="fas fa-caret-up m-r-xxs"></i>{{data.percentNewEmployee}}%</span>
              </li>
              <li class="hr-statistic-line">
                <span class="hr-statistic-left">
              <span class="hr-statistic-icon green"><i class="fab fa-audible"></i></span>
                <span class="hr-statistic-info">
                <span class="hr-statistic-title">Average Age in the Company</span>
                <span class="hr-statistic-number d-block">{{data.averageAgeInTheCompany}}</span>
                </span>
                </span>
                <!-- <span class="hr-statistic-right fs-18 text-danger"><i class="fas fa-caret-down m-r-xxs"></i>%</span> -->
              </li>
              <li class="hr-statistic-line">
                <span class="hr-statistic-left">
              <span class="hr-statistic-icon"><i class="fab fa-audible"></i></span>
                <span class="hr-statistic-info">
                <span class="hr-statistic-title">Average Seniority in the Company</span>
                <span class="hr-statistic-number d-block">{{data.averageSeniorityInTheCompany | number : '1.0-0'}}</span>
                </span>
                </span>
                <!-- <span class="hr-statistic-right fs-18 text-danger"><i class="fas fa-caret-down m-r-xxs"></i>%</span> -->
              </li>
              <li class="hr-statistic-line">
                <span class="hr-statistic-left">
              <span class="hr-statistic-icon"><i class="fas fa-user-friends"></i></span>
                <span class="hr-statistic-info">
                <span class="hr-statistic-title">EE Retention Rate</span>
                </span>
                </span>
                <span class="hr-statistic-right fs-18 text-success">{{data.employeeRetention}}%</span>
              </li>
              <li class="hr-statistic-line">
                <span class="hr-statistic-left">
              <span class="hr-statistic-icon"><i class="fas fa-user-alt-slash"></i></span>
                <span class="hr-statistic-info">
                <span class="hr-statistic-title">EE Turnover Rate</span>
                </span>
                </span>
                <span  class="hr-statistic-right fs-18 text-success">{{data.employeeTurnover}}%</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nz-card>
</ng-container>
