
export enum ReviewTemplateTypeEnum {
  Annual = 1,
  Probation = 2,
  Exceptional = 3,
}

export enum ReviewTemplateStatusEnum {
  New = 1,
  WaitingForApproval = 2,
  Approved = 3,
  Rejected = 4,
}

export enum ReviewTemplateDisplayModeEnum {
  View,
  Create,
  Update,
  SendForApproval,
  Approve,
  Reject,
}
