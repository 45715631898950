import { Component, OnInit } from '@angular/core';
import { BaseAdminComponent } from '../../components';
import { SecurityHelper, WebsiteData, WebsiteEvent } from 'projects/intranet/hrm-lib';
import { Router } from '@angular/router';
import { UserService } from '@intranet/lib/data-access';
import { Loading, ModalService, ModalSize } from '@intranet/lib/settings';
import { ChangePasswordComponent } from 'projects/intranet/hrm-admin/src/app/components';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styles: [],
})
export class ToolbarComponent extends BaseAdminComponent implements OnInit {
  languages = WebsiteData.languages;
  currentFlag: any;
  curentLanguage: string;
  customers: any;
  showQuickLink: boolean;
  constructor(private router: Router, private userService: UserService, private modalSvc: ModalService) {
    super();
  }

  async ngOnInit() {
    window.addEventListener('click', e => {
      if (
        document.getElementById('clickbox') &&
        !document.getElementById('clickbox').contains(e.target as Node) &&
        document.getElementById('clickbox-btn') &&
        !document.getElementById('clickbox-btn').contains(e.target as Node)
      ) {
        // Clicked out box
        this.toggleQuickLink(false);
      }
    });
  }
  logout() {
    Loading.show();
    this.userService.logout().then(res => {
      SecurityHelper.destroyAuthen();
      Loading.hide();
      this.router.navigate(['/login']);
    });
  }

  toggleQuickLink(status) {
    this.showQuickLink = status;
  }

  openChangePassword() {
    this.modalSvc.open(ChangePasswordComponent, { size: ModalSize.lg });
  }
}
