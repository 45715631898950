import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { HttpClientService, Loading } from '@intranet/index';

@Injectable({
  providedIn: 'root',
})
export class TimekeeperMachineService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/timekeepermachine';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  ping(params?: any, successCallback?: (params?: any) => void) {
    const url = 'ping';
    return this.getByUrl(url, params, successCallback);
  }
  reConnect(successCallback?: (params?: any) => void) {
    const url = 're-connect';
    return this.getByUrl(url, undefined, successCallback);
  }
  getNotPing(params?: any, successCallback?: (params?: any) => void) {
    const url = 'get-list';
    return this.getByUrl(url, params, successCallback);
  }
  updateActive(params?: any, successCallback?: (params?: any) => void) {
    const url = 'update-active';
    return this.updateUrl(url, params, undefined, successCallback);
  }
}
