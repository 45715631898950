import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';

import { Constants } from '../config';
import { Injectable } from '@angular/core';
import { RouterHelperService } from '@intranet/lib/helpers';
import { SecurityHelper } from 'projects/intranet/hrm-lib';

@Injectable({
  providedIn: 'root',
})
export class AuthenCheckerNewFeedsService {
  constructor(private router: Router, private routerHelperService: RouterHelperService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Constants.previousUrl = route;
    const user = route.data;
    if (user) {
      const url: string = this.getStateUrl(route, state.url);
      return this.checkPermission(url, user);
    }
    return this.canActivate(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Constants.previousUrl = route;
    const user = route.data;
    if (user) {
      const url: string = this.getStateUrl(route, state.url);
      return this.checkPermission(url, user);
    }
    return this.canActivate(route, state);
  }

  getStateUrl(route: ActivatedRouteSnapshot, url: string) {
    if (url) {
      const urls = url.split('?');
      return urls[0];
    } else {
      return window.location.pathname;
    }
  }

  canLoad(route: Route) {
    const url = `/${route.path}`;

    return this.checkPermission(url);
  }

  async checkPermission(url?: string, data?: any) {
    Constants.previousUrl = url ? url : '/';
    const authToken = SecurityHelper.getStoreAuthen();
    if (authToken && authToken.token) {
      return true;
    } else {
      SecurityHelper.destroyAuthen();
      this.routerHelperService.redirectToLogin();
    }
  }
}
