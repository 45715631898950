import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'angular-calendar';
import { ImageCropperModule } from 'ngx-image-cropper';
import {
  AvatarComponent,
  BaseAdminComponent,
  CalendarComponentComponent,
  CalendarHeaderComponent,
  CreateUpdateButtonComponent,
  CropImageComponent,
  GridButtonComponent,
  HashRoomComponent,
  HashTagComponent,
  InternalUploadFileComponent,
  MembersViewComponent,
  ModalBodyComponent,
  ModalConfirmComponent,
  ModalFooterComponent,
  ModalHeaderComponent,
  NgkPassworkComponent,
  ResizeImageComponent,
  ReviewFormDiscussionModalComponent,
  ReviewPerformanceAddAppraiserModalComponent,
  SearchButtonComponent,
  SearchDateMonthComponent,
  SearchInputComponent,
  SearchInputV2Component,
  SelectMultipleComponent,
  SelectSearchComponent,
  ShareEditorComponent,
  WidgetAssetComponent,
  WidgetAttendanceComponent,
  WidgetAttendanceDetailComponent,
  WidgetAttendanceHourComponent,
  WidgetAttendanceMemberComponent,
  WidgetCompanyComponent,
  WidgetCompanyDetailComponent,
  WidgetEmployeeGenderComponent,
  WidgetEquipmentComponent,
  WidgetHrComponent,
  WidgetLeaveComponent,
  WidgetLeaveDetailComponent,
  WidgetSkillComponent,
  WidgetSkillDetailComponent,
  WidgetTrackingTimeComponent,
  WidgetWorkExperienceComponent,
  WidgetWorkExperienceDetailComponent,
} from '.';

import { SharedCalendarComponent } from '@intrafront/components';
import { ResignFormModalComponent } from '@intranet/lib/components';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NgkValidateFormModule } from 'ngk-validate-form';
import { MomentModule } from 'ngx-moment';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { EventCalendarComponent } from '../../../front/components/events-calendar/events-calendar.component';
import { PolicyDetailListComponent } from '../../../hrm-admin/src/app/pages/policys/policy-detail-list/policy-detail-list.component';
import { ShareDirectiveModule } from '../directives';
import { ColorAccessModulePipe, DateFromNowPipe } from '../pipes';
import { NgZorroShareModule } from '../share/ngzorro.module.share';
import { BenefitUserClaimModalComponent } from './benefit-user/benefit-user-claim-modal/benefit-user-claim-modal.component';
import { BenefitUserComponent } from './benefit-user/benefit-user.component';
import { CheckinCheckoutClaimModalComponent } from './checkin-checkout-claim-modal/checkin-checkout-claim-modal.component';
import { CustomInputComponent } from './custom-input/custom-input.component';
import { DashboardMenuComponent } from './dashboard-menu/dashboard-menu.component';
import { DisplayMemberTemplateComponent } from './display-member-template/display-member-template.component';
import { LazySelectComponent } from './lazy-select/lazy-select.component';
import { MembersComponent } from './members/members.component';
import { MenuComponent } from './menu/menu.component';
import { PosterDisplayModalComponent } from './poster-display-modal/poster-display-modal.component';
import { ReviewFormMemberResultComponent } from './review-form-member-result/review-form-member-result.component';
import { ReviewOverallResultComponent } from './review-form-member-result/review-overall-result/review-overall-result.component';
import { ReviewSectionResultComponent } from './review-form-member-result/review-section-result/review-section-result.component';
import { ReviewFormMembersMobileComponent } from './review-form-members-mobile/review-form-members-mobile.component';
import { ReviewImageAvatarComponent } from './review-image-avatar/review-image-avatar.component';
import { ReviewChooseMemberComponent } from './review-row-clone-step/review-choose-member/review-choose-member.component';
import { ReviewGoalSectionComponent } from './review-row-clone-step/review-goal-section/review-goal-section.component';
import { ReviewPreviewSectionComponent } from './review-row-clone-step/review-preview-section/review-preview-section.component';
import { ReviewRowCloneStepComponent } from './review-row-clone-step/review-row-clone-step.component';
import { ReviewScoreComponent } from './review-score/review-score.component';
import { SelectComponent } from './select/select.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { ToggleCheckBoxComponent } from './toggle-check-box/toggle-check-box.component';
import { TreeSelectComponent } from './tree-select/tree-select.component';
import { TreeComponent } from './tree/tree.component';
import { ViewMoreComponent } from './view-more/view-more.component';
import { WidgetAssetClientComponent } from './widget/widget-asset-client/widget-asset-client.component';
import { WidgetAttendancePersonalComponent } from './widget/widget-attendance/widget-attendance-personal/widget-attendance-personal.component';
import { WidgetBenefitComponent } from './widget/widget-benefit/widget-benefit.component';
import { WidgetPersonalBenefitComponent } from './widget/widget-benefit/widget-personal-benefit/widget-personal-benefit.component';
import { WidgetRegisterBenefitComponent } from './widget/widget-benefit/widget-personal-benefit/widget-register-benefit/widget-register-benefit.component';
import { WidgetCalendarComponent } from './widget/widget-calendar/widget-calendar.component';
import { WidgetDynamicComponent } from './widget/widget-dynamic/widget-dynamic.component';
import { LeaveTooltipComponent } from './widget/widget-leave/leave-tooltip/leave-tooltip.component';
import { WidgetMembersClientComponent } from './widget/widget-members-client/widget-members-client.component';
import { WidgetNewcomerComponent } from './widget/widget-newcomer/widget-newcomer.component';
import { WidgetNewsfeedNoelComponent } from './widget/widget-newsfeed-noel/widget-newsfeed-noel.component';
import { WidgetReviewClientComponent } from './widget/widget-review-client/widget-review-client.component';
import { WidgetReviewGoalComponent } from './widget/widget-review-goal/widget-review-goal.component';
import { WidgetReviewComponent } from './widget/widget-review/widget-review.component';
import { WidgetSkeletonPlaceholderComponent } from './widget/widget-skeleton-placeholder/widget-skeleton-placeholder.component';
import { WidgetTodayLeaveComponent } from './widget/widget-today-leave/widget-today-leave.component';
import { WidgetTodoComponent } from './widget/widget-todo/widget-todo.component';
import { WidgetTrackingMonthlyComponent } from './widget/widget-tracking-time/widget-tracking-monthly/widget-tracking-monthly.component';
import { WidgetTrackingWeeklyComponent } from './widget/widget-tracking-time/widget-tracking-weekly/widget-tracking-weekly.component';
import { ReviewPerformanceAddAppraiserModalV2Component } from './review-performance-add-appraiser-modal-v2/review-performance-add-appraiser-modal-v2.component';

@NgModule({
  imports: [
    EditorModule,
    MomentModule,
    RouterModule,
    CommonModule,
    NgxDatatableModule,
    NzBadgeModule,
    NgZorroShareModule,
    FormsModule,
    TranslateModule.forChild(),
    ImageCropperModule,
    CalendarModule,
    ShareDirectiveModule,
    NgxChartsModule,
    VirtualScrollerModule,
    NzTableModule,
    ReactiveFormsModule,
    NgkValidateFormModule,
    NzSliderModule,
  ],
  declarations: [
    ModalBodyComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalConfirmComponent,
    HashTagComponent,
    SelectSearchComponent,
    SelectMultipleComponent,
    BaseAdminComponent,
    SearchButtonComponent,
    HashRoomComponent,
    NgkPassworkComponent,
    CropImageComponent,
    ResizeImageComponent,
    CalendarHeaderComponent,
    CalendarComponentComponent,
    CreateUpdateButtonComponent,
    GridButtonComponent,
    SearchInputComponent,
    ColorAccessModulePipe,
    DateFromNowPipe,
    InternalUploadFileComponent,
    WidgetTodoComponent,
    WidgetCompanyComponent,
    WidgetCompanyDetailComponent,
    WidgetLeaveComponent,
    WidgetLeaveDetailComponent,
    WidgetSkillComponent,
    WidgetSkillDetailComponent,
    WidgetEquipmentComponent,
    WidgetHrComponent,
    WidgetWorkExperienceComponent,
    WidgetWorkExperienceDetailComponent,
    AvatarComponent,
    ShareEditorComponent,
    WidgetAttendanceComponent,
    WidgetAttendanceMemberComponent,
    WidgetDynamicComponent,
    WidgetAttendanceDetailComponent,
    WidgetAttendanceHourComponent,
    WidgetEmployeeGenderComponent,
    WidgetTrackingTimeComponent,
    WidgetTodayLeaveComponent,
    WidgetAssetComponent,
    SearchDateMonthComponent,
    PolicyDetailListComponent,
    WidgetNewcomerComponent,
    EventCalendarComponent,
    SharedCalendarComponent,
    WidgetCalendarComponent,
    ResignFormModalComponent,
    DisplayMemberTemplateComponent,
    LeaveTooltipComponent,
    WidgetBenefitComponent,
    MenuComponent,
    TreeComponent,
    ReviewScoreComponent,
    ReviewImageAvatarComponent,
    ReviewRowCloneStepComponent,
    ReviewChooseMemberComponent,
    ReviewPreviewSectionComponent,
    ReviewGoalSectionComponent,
    WidgetReviewComponent,
    ViewMoreComponent,
    WidgetReviewGoalComponent,
    SpinnerComponent,
    ReviewFormMemberResultComponent,
    ReviewSectionResultComponent,
    ReviewOverallResultComponent,
    TextAreaComponent,
    WidgetReviewClientComponent,
    MembersComponent,
    WidgetMembersClientComponent,
    WidgetAssetClientComponent,
    MembersViewComponent,
    ReviewFormDiscussionModalComponent,
    ReviewPerformanceAddAppraiserModalComponent,
    WidgetPersonalBenefitComponent,
    WidgetRegisterBenefitComponent,
    ToggleCheckBoxComponent,
    WidgetSkeletonPlaceholderComponent,
    DashboardMenuComponent,
    ReviewFormMembersMobileComponent,
    PosterDisplayModalComponent,
    WidgetTrackingMonthlyComponent,
    WidgetTrackingWeeklyComponent,
    WidgetAttendancePersonalComponent,
    BenefitUserComponent,
    BenefitUserClaimModalComponent,
    CheckinCheckoutClaimModalComponent,
    CustomInputComponent,
    WidgetNewsfeedNoelComponent,
    LazySelectComponent,
    SelectComponent,
    TreeSelectComponent,
    SearchInputV2Component,
    ReviewPerformanceAddAppraiserModalV2Component,
  ],
  exports: [
    VirtualScrollerModule,
    ModalBodyComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalConfirmComponent,
    HashTagComponent,
    SelectSearchComponent,
    SelectMultipleComponent,
    BaseAdminComponent,
    SearchButtonComponent,
    HashRoomComponent,
    NgkPassworkComponent,
    CropImageComponent,
    ResizeImageComponent,
    CalendarComponentComponent,
    CreateUpdateButtonComponent,
    GridButtonComponent,
    SearchInputComponent,
    CalendarHeaderComponent,
    ColorAccessModulePipe,
    DateFromNowPipe,
    CalendarModule,
    InternalUploadFileComponent,
    WidgetTodoComponent,
    WidgetCompanyComponent,
    WidgetCompanyDetailComponent,
    WidgetLeaveComponent,
    WidgetLeaveDetailComponent,
    WidgetSkillComponent,
    WidgetSkillDetailComponent,
    WidgetEquipmentComponent,
    WidgetHrComponent,
    WidgetWorkExperienceComponent,
    WidgetWorkExperienceDetailComponent,
    AvatarComponent,
    ShareEditorComponent,
    WidgetDynamicComponent,
    WidgetAttendanceComponent,
    WidgetAttendanceMemberComponent,
    WidgetAttendanceDetailComponent,
    WidgetAttendanceHourComponent,
    WidgetEmployeeGenderComponent,
    WidgetTrackingTimeComponent,
    WidgetTodayLeaveComponent,
    WidgetAssetComponent,
    SearchDateMonthComponent,
    PolicyDetailListComponent,
    WidgetNewcomerComponent,
    EventCalendarComponent,
    SharedCalendarComponent,
    WidgetCalendarComponent,
    ResignFormModalComponent,
    DisplayMemberTemplateComponent,
    LeaveTooltipComponent,
    WidgetBenefitComponent,
    MenuComponent,
    TreeComponent,
    ReviewScoreComponent,
    ReviewImageAvatarComponent,
    ReviewRowCloneStepComponent,
    WidgetReviewComponent,
    ViewMoreComponent,
    WidgetReviewGoalComponent,
    SpinnerComponent,
    ReviewFormMemberResultComponent,
    TextAreaComponent,
    ReviewPreviewSectionComponent,
    ReviewGoalSectionComponent,
    WidgetReviewClientComponent,
    MembersComponent,
    WidgetMembersClientComponent,
    WidgetAssetClientComponent,
    MembersViewComponent,
    ReviewFormDiscussionModalComponent,
    WidgetPersonalBenefitComponent,
    WidgetSkeletonPlaceholderComponent,
    DashboardMenuComponent,
    ReviewFormMembersMobileComponent,
    PosterDisplayModalComponent,
    WidgetAttendancePersonalComponent,
    BenefitUserComponent,
    BenefitUserClaimModalComponent,
    CheckinCheckoutClaimModalComponent,
    CustomInputComponent,
    WidgetNewsfeedNoelComponent,
    LazySelectComponent,
    SelectComponent,
    TreeSelectComponent,
    ReviewChooseMemberComponent,
    SearchInputV2Component,
  ],
  entryComponents: [CropImageComponent],
})
export class ComponentShareModule {}
