import * as CryptoJS from 'crypto-js';
import { Inject, Injectable } from '@angular/core';
import Cookies from 'universal-cookie';
import * as moment from 'moment';
import { AppConfig } from '../environments/config/appConfig';
const cookies = new Cookies();
@Injectable({
  providedIn: 'root',
})
export class CookieStorage {
  private secrectKey = 'akes!@#616b6573214023@#$#%24023242325324024402340@#$#%2232473646653@#$#%244465';

  constructor() {}

  private encrypt(data: string) {
    if (!data) {
      return '';
    }
    const ciphertext = CryptoJS.AES.encrypt(data, this.secrectKey);
    return ciphertext.toString();
  }

  private decrypt(data: string) {
    if (!data) {
      return null;
    }
    const bytes = CryptoJS.AES.decrypt(data, this.secrectKey);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
  }

  createStore(key: string, data: string) {
    if (data) {
      const hashData = this.encrypt(data);
      cookies.set(key, hashData, { path: '/', domain: AppConfig.settings.domain_login });
    }
  }

  createStoreAccess(key: string, data: any) {
    if (data) {
      const hashData = this.encrypt(data);
      cookies.set(key, hashData, {
        path: '/',
        domain: AppConfig.settings.domain_login,
        expires: new Date(moment().add(10, 'day').format()),
      });
    }
  }

  getStore(key: string) {
    let hashData = cookies.get(key);
    if (hashData) {
      hashData = hashData.trym('"');
      const plainText = this.decrypt(hashData);
      if (plainText) {
        return plainText;
      }
    }
    return undefined;
  }
  removeStore(key: string) {
    cookies.remove(key, { path: '/', domain: AppConfig.settings.domain_login });
  }
}
