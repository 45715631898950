<div class="calendar-component">
  <h4 class="widget-title">Events & Calendar</h4>
  <div>
    <div class="row">
      <div class="col-md-6">
        <h6 class="m-b-none m-t-xxs">{{ viewDate | calendarDate: view + 'ViewTitle':'en' }}</h6>
      </div>
      <div class="col-md-6 text-right">
        <div class="btn-group">
          <div class="btn btn-none m-r-xxs fs20" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="onViewDateChange($event)">
            <i class="fas fa-chevron-circle-left color-grey-light"></i>
          </div>
          <div class="btn btn-none fs20" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="onViewDateChange($event)">
            <i class="fas fa-chevron-circle-right color-grey-light"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="m-t-sm">
      <mwl-calendar-month-view [viewDate]="viewDate" [activeDayIsOpen]="true" [refresh]="refresh" [events]="events" (dayClicked)="dayClicked($event)"
        [openDayEventsTemplate]="openDayEventsTemplate">
      </mwl-calendar-month-view>
    </div>
  </div>
</div>

<ng-template #openDayEventsTemplate let-events="events" let-eventClicked="eventClicked" let-isOpen="isOpen">
  <div *ngIf="isOpen" class="cal-open-day-events">
    <div class="mt5">
      <button type="button" class="btn btn-primary small ant-btn mr5" *ngIf="currentUser.hasEmployee" (click)="createLeave()">
        <i class="fa fa-plus pointer"></i> Leave
      </button>
      <button type="button" class="btn btn-primary small ant-btn" *ngIf="viewDate >= today && currentUser.hasEmployee" (click)="createBooking()">
        <i class="fa fa-plus pointer"></i> Meeting
      </button>
    </div>
    <div class="mt10" *ngIf="currentEvents.length > 0">
      <div *ngFor="let event of currentEvents" class="d-flex align-items-center mb10">
        <span [style.background-color]="event.data.color" class="event-color">&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span class="fs13">{{ event.title }}</span>
      </div>
    </div>
    <nz-tabset *ngIf="currentLeaves.length > 0 || currentMeetings.length > 0">
      <nz-tab nzTitle="Leave" *ngIf="currentLeaves.length > 0">
        <ul class="mt20 mb20">
          <li class="event-item mb20" *ngFor="let event of currentLeaves">
            <div class="d-flex algin-items-center mb10">
              <label class="event-title">{{ event.data.employeeName }} </label>
              <nz-tag [nzColor]="'green'" class="ml10">
                <span>{{ event.data.leaveType.halfDay }}</span>
              </nz-tag>
            </div>
            <div *ngIf="event.data.departments">
              <nz-tag [nzColor]="'geekblue'" class="ml-auto" *ngFor="let department of event.data.departments">
                <span>{{ department.name }}</span>
              </nz-tag>
            </div>
          </li>
        </ul>
      </nz-tab>
      <nz-tab nzTitle="Meeting" *ngIf="currentMeetings.length > 0">
        <ul class="mt20 mb20">
          <li class="event-item" *ngFor="let event of currentMeetings">
            <label class="event-title" *ngIf="!event.sameDay">[{{ event.data?.departmentGroup?.name }}] {{ event.title }}:
              {{ event.start | amDateFormat: 'DD/MM/YYYY HH:mm' }}
              to
              {{ event.end | amDateFormat: 'DD/MM/YYYY HH:mm' }} at {{ event.data.room.name }}</label>
            <label class="event-title" *ngIf="event.sameDay">[{{ event.data?.departmentGroup?.name }}] {{ event.title }}: {{ event.start | amDateFormat: 'HH:mm' }} to
              {{ event.end | amDateFormat: 'HH:mm' }} at
              {{ event.data.room.name }}</label>
          </li>
        </ul>
      </nz-tab>
    </nz-tabset>
  </div>
</ng-template>
