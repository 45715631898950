import { Component, OnInit, Input } from '@angular/core';
import { EmployeeService, UpdateCommon, ResizeFile, CropOptions } from '@intranet/lib/data-access';
import { ApiResponse } from '@intranet/lib/data-access/models/api-response.model';
import { SecurityHelper } from '@intranet/lib/helpers';
import { Toastr } from '@intranet/lib/settings';

@Component({
  selector: 'app-banner-cover',
  templateUrl: './banner-cover.component.html',
  styleUrls: ['./banner-cover.component.scss'],
})
export class BannerCoverComponent implements OnInit {
  @Input() isDisplayEditCoverButton = true;
  @Input() currentUser: any;
  cropOptions = {
    hideResizeSquares: true,
    aspectRatio: 3 / 1,
    cropperStaticHeight: 260,
  };
  images: ResizeFile = new ResizeFile();
  file: ResizeFile = new ResizeFile();
  coverImageUrl = '';
  constructor(private employeeService: EmployeeService) { }

  ngOnInit() {
    this.coverImageUrl = this.currentUser.coverImageUrl || 'assets/images/front/planet-banner.jpg';
  }
  changeCoverImage() {
    if (this.images && this.images.base64) {
      const data: UpdateCommon = {
        coverImageUrl: this.images.base64,
      };
      this.employeeService.updateAvatar(data, (resp: ApiResponse) => {
        this.coverImageUrl = resp.message;
        this.updateAvatar(undefined, this.coverImageUrl);
        Toastr.success('Change cover image successfully');
      });
    }
  }
  updateAvatar(avatarUrl: string, coverImageUrl?: string) {
    const authToken = SecurityHelper.getStoreAuthen();
    if (authToken) {
      if (avatarUrl) {
        authToken.avatarUrl = avatarUrl;
      }
      if (coverImageUrl) {
        authToken.coverImageUrl = coverImageUrl;
      }
      SecurityHelper.createStoreAuthen(authToken);
    }
  }
}
