<div class="event-detail">
  <modal-header [title]="headerTemplate" [activeModal]="activeModal">
    <ng-template #headerTemplate>
      <h4 class="modal-title" *ngIf="!isUpdate">Add</h4>
      <h4 class="modal-title" *ngIf="isUpdate">Update - {{event.title}}</h4>
    </ng-template>
  </modal-header>
  <form class="feed-form" (ngkSubmit)="onSubmit()" #frm="ngForm" [ngkForm]="true">
    <modal-body>
      <div class="form-row">
        <div class="form-group col">
          <label for="title">Name</label>
          <input nz-input placeholder="Event name" [(ngModel)]="event.title" nzSize="large" name="title"
            [ngModelOptions]="{standalone: true}" required />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="title">Event From Date</label>
          <nz-date-picker [nzFormat]="dateFormat" [ngModelOptions]="{standalone: true}" [(ngModel)]="event.fromDate" [nzDisabledDate]="disableEventFromDate" (ngModelChange)="onChangeEventFromDate($event)"  [nzShowToday]="true"></nz-date-picker>
        </div>
        <div class="form-group col-md-6">
          <label for="title">Event To Date</label>
          <nz-date-picker [nzFormat]="dateFormat" [ngModelOptions]="{standalone: true}" [(ngModel)]="event.toDate" [nzDisabledDate]="disableEventToDate" (ngModelChange)="onChangeEventToDate($event)"  [nzShowToday]="true"></nz-date-picker>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="title">Reminder before (days)</label>
          <nz-input-number [(ngModel)]="event.reminder" [nzMin]="1" [nzMax]="100" [nzStep]="1"
            [ngModelOptions]="{standalone: true}"></nz-input-number>
        </div>
      </div>
      <div class="form-row" *ngIf="!isCreatingOnWall()">
        <div class="form-group col-12">
          <label nz-checkbox [(ngModel)]="event.isAnnual" [ngModelOptions]="{standalone: true}">Is Annual
            Leave</label>
        </div>
        <div class="form-group col-md-6" *ngIf="event.isAnnual">
          <label for="title">Annual From Date</label>
          <nz-date-picker [nzFormat]="dateFormat" [ngModelOptions]="{standalone: true}" [(ngModel)]="event.annualFromDate" (ngModelChange)="onChangeAnnualFromDate($event)" [nzDisabledDate]="disableAnnualFromDate"  [nzShowToday]="true"></nz-date-picker>
        </div>
        <div class="form-group col-md-6" *ngIf="event.isAnnual">
          <label for="title">Annual To Date</label>
          <nz-date-picker [nzFormat]="dateFormat" [ngModelOptions]="{standalone: true}" [(ngModel)]="event.annualToDate" (ngModelChange)="onChangeAnnualToDate($event)" [nzDisabledDate]="disableAnnualToDate"  [nzShowToday]="true"></nz-date-picker>
        </div>
      </div>
      <div class="form-row" *ngIf="!isCreatingOnWall()">
        <div class="form-group col-md-6">
          <label for="title">Link to announcement</label>
          <nz-select nzShowSearch [(ngModel)]="event.newsFeedId" nzPlaceHolder="Select announcement" nzAllowClear
            [ngModelOptions]="{standalone: true}" (ngModelChange)="onAnnouncementChange()">
            <nz-option nzCustomContent nzLabel="Create announcement" nzValue="">
              <i nz-icon nzType="plus-circle"></i> Create announcement
            </nz-option>
            <nz-option *ngFor="let announcement of announcements" nzLabel="{{announcement.title}}"
              nzValue="{{announcement.id}}"></nz-option>
          </nz-select>
        </div>
        <div class="form-group col-12 col-md-6">
          <label for="title">Type</label>
          <nz-select [(ngModel)]="event.eventType" nzPlaceHolder="Select type" required
            [ngModelOptions]="{standalone: true}">
            <nz-option nzLabel="Holiday" nzValue="{{eventTypes.HOLIDAY}}"></nz-option>
            <nz-option nzLabel="Party" nzValue="{{eventTypes.PARTY}}"></nz-option>
            <nz-option nzLabel="Company Sponsor" nzValue="{{eventTypes.COMPANY_SPONSOR}}"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="form-row" *ngIf="event.eventType === eventTypes.HOLIDAY.toString()">
        <div class="form-group col-12">
          <label nz-checkbox [(ngModel)]="isYearly" [ngModelOptions]="{standalone: true}">Is Yearly Repeat</label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12">
          <app-internal-upload-file [model]="modelUpload" [multiple]="false" [imageOnly]="true">
          </app-internal-upload-file>
        </div>
      </div>
    </modal-body>
    <modal-footer>
      <create-update-button [isCancel]="true" [isUpdate]="isUpdate" (cancel)="activeModal.close()">
      </create-update-button>
    </modal-footer>
  </form>
</div>