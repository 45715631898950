import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/index';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { Observable } from 'rxjs';
import { PurchaseRequest, PurchaseRequestSearch } from '../models';
import { PagingResponse } from '../models/api-response.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class PurchaseRequestService extends BaseService {
  purchaseRequestURL = '/api/purchase-request';
  hostUrl: string = AppConfig.settings.API_URL + this.purchaseRequestURL;
  httpClientt: HttpClientService;

  constructor(httpClient: HttpClientService) {
    super(httpClient);
    this.httpClientt = httpClient;
  }

  listForAdminV2(params: PurchaseRequestSearch): Observable<PagingResponse<PurchaseRequest>> {
    return this.httpClient.getObservable(this.purchaseRequestURL + '/v2', params);
  }

  updateStatus(params: any, successCallback?: (params?: any) => void) {
    const url = 'update-status';
    return this.updateUrl(url, params, { params: { idxx: params.status } }, successCallback);
  }
  undoStatus(params: any, successCallback?: (params?: any) => void) {
    const url = 'undo-status';
    return this.updateUrl(url, params, { params: { idxx: params.status } }, successCallback);
  }

  getForOnlyEmployee(params?: any, successCallback?: (params?: any) => void) {
    const url = 'get_by_employee';
    return this.getByUrl(url, params, successCallback);
  }

  fillPrice(params?: any, successCallback?: (params?: any) => void) {
    const url = 'fillprice';
    return this.updateUrl(url, params, undefined, successCallback);
  }

  reCreate(params?: PurchaseRequest, successCallback?: (params?: any) => void) {
    const url = 're-create';
    return this.postByUrl(url, params, successCallback);
  }

  updatePRByEmployee(purchaseRequestId: string, body, successCallback?: (params?: any) => void) {
    const url = 'update-by-employee/' + purchaseRequestId;
    return this.updateUrl(url, body, undefined, successCallback);
  }

  updateItemPRByEmployee(purchaseRequestId: string, body, successCallback?: (params?: any) => void) {
    const url = 'update-by-employee/' + purchaseRequestId + '/items';
    return this.updateUrl(url, body, undefined, successCallback);
  }

  deleteItemPRByEmployee(purchaseRequestId: string, purchaseRequestDetailId: string, successCallback?: (params?: any) => void) {
    const url = this.hostUrl + '/delete-by-employee/' + purchaseRequestId + '/item/' + purchaseRequestDetailId;
    return this.deleteByUrl(url, null, successCallback);
  }

  addItemPRByEmployee(purchaseRequestId: string, body, successCallback?: (params?: any) => void) {
    const url = 'add-by-employee/' + purchaseRequestId + '/items';
    return this.postByUrl(url, body, successCallback);
  }

  getStatusDropdown(): Promise<any> {
    const url = ApiEndpoint.PurchaseRequest.StatusDropdown();
    return this.httpClient.getObservable(url).toPromise();
  }

  export(params: PurchaseRequestSearch): Observable<{ id: number; message: any; data: Blob[] }> {
    const url = ApiEndpoint.PurchaseRequest.Export();
    return this.httpClient.getObservable(url, params);
  }
}
