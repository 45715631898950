import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@intranet/index';
export function getBaseUrl() {
  // return document.getElementsByTagName('base')[0].href;
  return 'http://localhost:58509';
}
const providers = [{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }];
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  // tslint:disable-next-line: no-console
  .catch(err => console.error(err));
