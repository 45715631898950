<div class="page-header">
  <ng-template *ngIf="titleTemplate" [ngTemplateOutlet]="titleTemplate"></ng-template>
  <ol *ngIf="!titleTemplate" class="breadcrumb">
    <!-- <li class="breadcrumb-item" *ngIf="routerInfo.previousPageTitle">
      <a [routerLink]="[routerInfo.previousUrl]">
      {{routerInfo.previousPageTitle}}
      </a>
    </li> -->
    <li class="breadcrumb-item active">
      <ng-container *ngIf="url;else noneurl">
        <a [routerLink]="url">
        <i class="fas fa-arrow-left mr10"></i> {{ atitle }}
      </a>
      </ng-container>
      <ng-template #noneurl>
        {{ atitle }}
      </ng-template>
    </li>
  </ol>

  <ul class="app-actions">
    <li>
      <a  id="reportrange">
        <span class="range-text">
          {{timeLeft}}
        </span>
      </a>
    </li>
    <ng-template *ngIf="btnTemplate" [ngTemplateOutlet]="btnTemplate"></ng-template>
  </ul>
</div>
