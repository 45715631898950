import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { Toastr, AppInjector, LocalStorage, WebsiteEvent } from '@intranet/index';
import { FirebaseMessageModel, FirebaseMessageNotificationModel } from '../data-access/models/bases/firebase-message.model';
import { Constants } from '../config';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable()
export class PushMessagingService {
  public currentMessage = new BehaviorSubject(null);
  public token = '';
  constructor(private angularFireMessaging: AngularFireMessaging, private message: NzMessageService, private websiteEvent: WebsiteEvent) { }

  requestPermission() {

    this.angularFireMessaging.requestToken.subscribe(token => {
      this.token = token;
    },
      (err) => {
        this.message.warning('Website needs your permission to enable desktop notifications');
      });

  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((msg: FirebaseMessageModel) => {
      Toastr.showMessage(msg.notification.title, msg.notification.body, msg.notification.click_action);
      this.currentMessage.next(msg);
      this.afterwardReceiveMessageWithEntityType(msg.notification);
    });
  }

  afterwardReceiveMessageWithEntityType(notification: FirebaseMessageNotificationModel) {
    if (!notification || !notification.data || !notification.data.entityType) {
      return;
    }
    switch (notification.data?.entityType) {
      case Constants.ENTITY_TYPE_NOTIFICATION.SyncDatatTimeKeeper:
        this.removeAlertSynTimeKeeper();
        break;
      default:
        break;
    }
    this.websiteEvent.emitHeaderMessageEvent();
    this.websiteEvent.emitHeaderNotificationEvent();
  }

  removeAlertSynTimeKeeper() {
    const storage = AppInjector.getService<LocalStorage>(LocalStorage);
    storage.removeStore(Constants.SYNC_TIME_KEEPER_ALERT);
  }
}
