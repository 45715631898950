<modal-header [title]="purchaseRequest && purchaseRequest.title" [activeModal]="activeModal"></modal-header>
<modal-body>
  <!-- <div class="form-row">
    <div class="ol-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="card">
        <div class="card-body">
          <div class="row" *ngIf="purchaseRequest">
            <div class="col" *ngIf="departmentSession && departmentSession.length > 1">
              <div class="form-group mt0">
                <label for="groups" class="required">Groups</label>
                <select-search name="p-group" [data]="groups" textField="name" valueField="id" placeholder="Select Group" [(ngModel)]="purchaseRequest.departmentId"
                  required disabled>
                </select-search>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="form-group mt0">
                <label>Description</label>
                <textarea class="form-control" name="description_pr" placeholder="Description ..." [(ngModel)]="purchaseRequest.description" type="text"
                  disabled></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <app-internal-upload-file [model]="modelUpload"></app-internal-upload-file>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="central-meta item none-boxshadown border" *ngIf="productLists && productLists.length; else empty">
                <ngx-datatable #myTable class="material grid-aluha" [rows]="productLists" [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50"
                  [virtualization]="false" [scrollbarH]="false" rowHeight="auto">
                  <ngx-datatable-column *ngFor="let col of columnMapping" prop="{{ col.ColumnName }}" name="{{ col.DisplayName }}" [canAutoResize]="col.AutoResize"
                    [sortable]="col.Sortable" [width]="col.Witdh">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <ng-container [ngSwitch]="col.ColumnName">
                        <p *ngSwitchCase="'status'">
                          <span [innerHTML]="value | status: 2"></span>
                        </p>
                        <p *ngSwitchCase="'name'">
                          <i *ngIf="row.type === requestTypeEnum.Package" class="fas fa-cube m-r-xxs fs-12" nz-tooltip nzTooltipTitle="Item Package"></i><span>{{ value }}</span>
                        </p>
                        <p *ngSwitchCase="'option'">
                          {{value | purchaseDetailOption}}
                        </p>
                        <p *ngSwitchDefault>
                          <span>{{ value }}</span>
                        </p>
                      </ng-container>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
              <ng-template #empty>
                <nz-empty></nz-empty>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="purchase-detail-container">
    <div *ngIf="departmentSession?.length > 1">
      <label for="groups" class="required">Groups</label>
      <select-search name="p-group" [data]="groups" textField="name" valueField="id" placeholder="Select Group" 
        [(ngModel)]="purchaseRequest.departmentId" required disabled>
      </select-search>
    </div>
    <div>
      <label>Description</label>
      <textarea class="form-control" name="description_pr" placeholder="Description ..." [(ngModel)]="purchaseRequest.description" type="text" disabled>
      </textarea>
    </div>
    <div>
      <app-internal-upload-file [model]="modelUpload"></app-internal-upload-file>
    </div>
    <div class="products" *ngIf="productLists?.length else empty">
      <nz-table [nzData]="productLists" [nzScroll]="{x: '600px', y: '500px'}" [nzNoResult]="empty" [nzShowPagination]="false">
        <thead>
          <tr>
            <th nzWidth="150px">Name</th>
            <th nzWidth="150px">Category</th>
            <th nzWidth="150px">Option</th>
            <th nzWidth="150px">Quantity</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of productLists">
            <td>
              <i *ngIf="product.type === requestTypeEnum.Package" class="fas fa-cube m-r-xxs fs-12" nz-tooltip nzTooltipTitle="Item Package"></i>
              {{ product?.name }}
            </td>
            <td>{{product?.equipmentCategoryName}}</td>
            <td>{{product?.option | purchaseDetailOption}}</td>
            <td>{{product?.quantity}}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</modal-body>

<modal-footer>
  <div class="footer-container">
    <div class="status">
      Status: 
      <span [innerHTML]="purchaseRequest.purchasePoStatus | status: statusEnum.PRUCHASE_REQUEST_STATUS"></span>
    </div>
    <button *ngIf="purchaseRequest.purchasePoStatus === purchaseRequestStatus.New" (click)="openPageUpdate()" class="btn btn-success">
      <i nz-icon nzType="edit" theme="outline"></i>
      Update
    </button>
  </div>
</modal-footer>

<ng-template #empty>
  <nz-empty></nz-empty>
</ng-template>
