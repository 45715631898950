import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../config';

@Pipe({
  name: 'status',
})
export class StatusNamePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let exist;
    switch (args) {
      case Constants.STATUS_ENUMS.PRUCHASE_REQUEST_STATUS:
        exist = Constants.PRUCHASE_REQUEST_STATUS.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.name}</span>`;
        }
        return '';
      case Constants.STATUS_ENUMS.PRUCHASE_REQUEST_DETAIL_STATUS:
        exist = Constants.PRUCHASE_REQUEST_DETAIL_STATUS.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.name}</span>`;
        }
        return '';
      case Constants.STATUS_ENUMS.LEAVE_STATUS:
        exist = Constants.LEAVE_STATUS.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.name}</span>`;
        }
        return '';
      case Constants.STATUS_ENUMS.LEAVE_ACTIONS:
        exist = Constants.LEAVE_ACTIONS.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.subTitle}</span>`;
        }
        return '';
      case Constants.STATUS_ENUMS.PURCHASE_ORER_STATUS:
        exist = Constants.PURCHASE_ORER_STATUS.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.name}</span>`;
        }
        return '';
      case Constants.STATUS_ENUMS.USER_TYPES:
        exist = Constants.USER_TYPES.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.name}</span>`;
        }
        return '';
      case Constants.STATUS_ENUMS.EQUIPMENT_STATUS:
        exist = Constants.EQUIPMENT_STATUS.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.name}</span>`;
        }
        return '';
      case Constants.STATUS_ENUMS.DELIVERY_FROM_STATUS:
        exist = Constants.DELIVERY_FROM_STATUS.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.name}</span>`;
        }
        return '';
      case Constants.STATUS_ENUMS.PRUCHASE_REQUEST_DELIVERY_STATUS:
        exist = Constants.PRUCHASE_REQUEST_DELIVERY_STATUS.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.name}</span>`;
        }
        return '';
      case Constants.STATUS_ENUMS.REDEMPTION_REQUEST_STATUS:
        exist = Constants.REDEMPTION_STATUS.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.name}</span>`;
        }
        return `${value.toString()}`;
      case Constants.STATUS_ENUMS.ANNUAL_LEAVE_REQUEST_STATUS:
        exist = Constants.ANNUAL_LEAVE_REQUEST_STATUS.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.name}</span>`;
        }
        return '';
      case Constants.STATUS_ENUMS.ANNUAL_LEAVE_REQUEST_TYPE:
        exist = Constants.ANNUAL_LEAVE_REQUEST_TYPE.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.name}</span>`;
        }
        return '';
      case Constants.STATUS_ENUMS.MONTHLY_MISSING_HOURS_STATUS:
        exist = Constants.MONTHLY_MISSING_HOURS_STATUS.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.name}</span>`;
        }
        return '';
      case Constants.STATUS_ENUMS.DELIVERY_FORM_PR_STATUS:
        exist = Constants.DELIVERY_FORM_PR_STATUS.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.name}</span>`;
        }
        return '';
      case Constants.STATUS_ENUMS.LEAVE_TRANSFER_EXCEPTION:
        exist = Constants.LEAVE_TRANSFER_EXCEPTION.find(x => x.id === value);
        if (exist) {
          return `<span class="text-${exist.color}">${exist.name}</span>`;
        }
        return '';
      default:
        if (Array.isArray(args)) {
          const exist = args.find(x => x.id === value);
          if (exist) {
            return exist.name;
          }
          return '';
        }
    }
  }
}
