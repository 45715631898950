import { BaseModel } from './base-model';
import { UserModel } from './user-model';
export class Comment extends BaseModel {
  id?: string;
  content?: string;
  contentHtml?: string;
  parentId?: string;
  parent?: Comment;
  user?: UserModel;
  createdBy?: string;
  newsFeedId?: string;
  children?: Comment[];
  totalLikes?: number;
  reactions?: any;
  mentions?: [];
  hashtags?: [];
  totalCount?: number;
}
