import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { Router, RouterOutlet } from '@angular/router';
import { AppMenuService, MenuDto } from '@intranet/lib/data-access';
import { UrlConstant } from 'projects/intranet/hrm-lib/lib/config';
import { slideInAnimation } from 'projects/intranet/hrm-lib/lib/directives/animation';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  animations: [
    slideInAnimation,
    // animation triggers go here,
  ],
})
export class AdminLayoutComponent extends BaseLayoutComponent implements OnInit {
  menu: MenuDto[];
  utilities = UrlConstant.Utilities;
  adminMenu: any;
  roles: any;
  constructor(private menuService: AppMenuService, private router: Router, private changeDetector: ChangeDetectorRef) {
    super();
  }

  async ngOnInit() {
    super.ngOnInit();
    // Load admin menu, redirection to /not-permission page when API returns empty or failed response.
    // Load before ngOnInit to prevent error: ExpressionChangedAfterItHasBeenCheckedError caused by @routeAnimations.
    this.menuService
      .getAdminMainMenuForCurrentUser()
      .then(menu => {
        this.menu = menu;

        if (!this.menu || this.menu.length === 0) {
          this.router.navigate(['/not-permission']);
        }
        this.triggerChangeDetector();
      })
      .catch(() => {
        this.router.navigate(['/not-permission']);
      });
  }

  prepareRoute(outlet: RouterOutlet) {
    // tslint:disable-next-line: no-string-literal
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  triggerChangeDetector() {
    this.changeDetector.detectChanges();
    this.changeDetector.markForCheck();
  }
}
