<div class="poll-detail">
  <nz-collapse>
    <nz-collapse-panel [nzExtra]="extraTpl" *ngFor="let question of feed.pollQuestions; index as questionIndex;"
      [nzHeader]="'Question #' + (questionIndex + 1)" nzActive="true">
      <div class="poll-question">
        <div class="form-group row">
          <div class="col-sm-12">
            <div class="">
              <textarea required rows="4" nz-input [(ngModel)]="question.content"
                [ngModelOptions]="{ standalone: true }"></textarea>
            </div>
            <div class="attachments mt-2">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label nz-checkbox [(ngModel)]="question.allowNote" name="isImportant"
                    [ngModelOptions]="{standalone: true}">Allow Note</label>
                </div>
                <div class="form-group col-md-4">
                  <label nz-checkbox [(ngModel)]="question.isMultipleChoice" name="isImportant"
                    [ngModelOptions]="{standalone: true}">Multiple Choices</label>
                </div>
                <div class="form-group col-md-4" *ngIf="question.isMultipleChoice">
                  <label for="description">Maximum of votes</label>
                  <nz-input-number [nzPlaceHolder]="'Maximum of votes'" [(ngModel)]="question.maxVote"
                    [nzMax]="question.options.length" [ngModelOptions]="{ standalone: true }" [nzMin]="1" [nzStep]="1">
                  </nz-input-number>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12">
            <div class="options">
              <label>Options</label>
              <div *ngFor="let item of question.options; index as optionIndex;" class="option row">
                <div class="col-12 d-inline-flex mb-2">
                  <div class="type">
                    <label *ngIf="question.isMultipleChoice" nz-checkbox nzDisabled [ngModel]="true"></label>
                    <label *ngIf="!question.isMultipleChoice" nz-radio nzDisabled [ngModel]="true"></label>
                  </div>
                  <div class="content">
                    <div class="row">
                      <div class="col-12">
                        <input required maxlength="2000" [(ngModel)]="item.title"
                          [placeholder]="'Please enter your option #' + (optionIndex + 1)"
                          [ngModelOptions]="{ standalone: true }" class="form-control"
                          (keyup)="onOptionKeyupEvent($event, question, item)" />
                      </div>
                    </div>
                    <div class="row point" *ngIf="feed.enableRewardPoint">
                      <div class="col-sm-12 col-md-3 offset-md-9">
                        <nz-input-number [(ngModel)]="item.rewardPoint" [nzPlaceHolder]="'Reward point'" [nzMin]="0"
                          [nzStep]="1"></nz-input-number>
                      </div>
                    </div>
                  </div>
                  <div class="icon" (click)="onRemoveOption(question, item)">
                    <button class="btn btn-danger" type="button"><i class="far fa-times-circle"></i></button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="option-add col">
                  <button type="button" class="btn btn-primary float-right" (click)="onAddOption(question)"><i
                      class="fa fa-plus-circle"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="question.allowNote">
            <input type="text" nz-input placeholder="Additional Answer" disabled />
          </div>
        </div>
      </div>
      <ng-template #extraTpl>
        <i nz-icon nzType="delete" (click)="onRemoveQuestion(question)"></i>
      </ng-template>
    </nz-collapse-panel>
  </nz-collapse>

  <div class="form-group row mt-2">
    <div class="col-12">
      <div class="option-add">
        <button type="button" class="btn btn-primary float-right" (click)="onAddQuestion()"><i
            class="fa fa-plus-circle"></i> Question</button>
      </div>
    </div>
  </div>
</div>