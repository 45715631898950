import { Component, EventEmitter, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PubSubEvent } from '@intranet/lib/config';
import { DateMonthSelectModel, DateMonthSelectSettingModel, WidgetTrackingTimeDto, WidgetService, WidgetTrackingTimeSearchDto, WidgetSearchModeEnum } from '@intranet/lib/data-access';
import { DateHelper } from '@intranet/lib/helpers/dateHelper';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import { CommonService, PubSubService } from '@intranet/lib/services';
import { GridsterItem } from 'angular-gridster2';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-widget-tracking-time',
  templateUrl: './widget-tracking-time.component.html',
  styleUrls: ['./widget-tracking-time.component.scss']
})
export class WidgetTrackingTimeComponent implements OnInit, OnDestroy {
  dateFormat = 'dd/MM/yyyy';
  loading = false;
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;
  @Input() widgetLoaded: EventEmitter<any>;
  searchData: any = {};
  data: WidgetTrackingTimeDto = new WidgetTrackingTimeDto();
  dataSelect: any;
  dateSelect: DateMonthSelectModel = new DateMonthSelectModel();
  dateSelectSetting: DateMonthSelectSettingModel = new DateMonthSelectSettingModel();
  isShowPagination = false;

  isVisibleExplanationModal = false;
  dataSelected = null;

  subscriptions: Subscription[] = [];

  constructor(
    private widgetService: WidgetService,
    private activatedRoute: ActivatedRoute,
    private pubsub: PubSubService) {
  }

  ngOnInit() {
    this.loadDefaultDate();
    this.activatedRoute.queryParams.subscribe(params => {
      this.searchData.employeeId = params.employeeId;
      this.loadData();
    });

    this.subscriptions.push(this.pubsub.$sub(PubSubEvent.EVENTS.ON_SELECT_MISSING_HOURS, (searchData: WidgetTrackingTimeSearchDto) => {
      this.fillSearchData(searchData);
      this.loadData();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async loadData() {
    if (this.widget) {
      this.searchData.isDisplayDataManage = this.widget.data.isDisplayDataManage;
    }
    this.loading = true;
    const request = {
      ...this.searchData,
      fromDate: DateHelper.formatServer(this.searchData.fromDate),
      toDate: DateHelper.formatServer(this.searchData.toDate),
    };

    try {
      this.data = await this.widgetService.getWidgetTrackingTime(request);
      if (this.data.listDetails.length > 15) {
        this.isShowPagination = true;
      }
      if (!this.searchData.thisWeek) {
        this.searchData.thisWeek = this.data.thisWeek;
      }
      this.widgetLoaded?.emit(this.widget);
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
    finally {
      this.loading = false;
    }
  }
  modelWeekChanged(event) {
    this.searchData.thisWeek = event;
    this.loadData();
  }
  modelChanged(event) {
    this.searchData.userIds = event;
    this.loadData();
  }
  modelMissingHoursChanged(event) {
    this.searchData.isMissingHours = event;
    this.loadData();
  }

  loadDefaultDate() {
    const currentDate = new Date();
    this.searchData.fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    this.searchData.toDate = currentDate;
  }

  onChangeFromDate(date) {
    this.searchData.fromDate = date;
    this.loadData();
  }

  onChangeToDate(date) {
    this.searchData.toDate = date;
    this.loadData();
  }

  openExplanationModal(data: any) {
    this.isVisibleExplanationModal = true;
    this.dataSelected = data;
  }

  closeExplanationModal() {
    this.dataSelected = null;
    this.isVisibleExplanationModal = false;
  }

  fillSearchData(data: WidgetTrackingTimeSearchDto) {
    if (!data) {
      return;
    }

    this.searchData.isMissingHours = data.searchMissingHours;

    const now = new Date();
    const curMonth = now.getMonth() + 1;
    const curYear = now.getFullYear();
    const curDay = now.getDate();

    this.searchData.toDate = new Date(curYear, curMonth, curDay);

    const [dataMonth, dataYear] = [data.month - 1, data.year];

    if (data.searchMode === WidgetSearchModeEnum.Month) {
      this.searchData.fromDate = DateHelper.getFirstDateOfMonth(dataMonth, dataYear);

      if (data.month === curMonth && data.year === curYear) {
        return;
      }

      this.searchData.toDate = DateHelper.getLastDateOfMonth(dataMonth, dataYear);
      return;
    }

    this.searchData.fromDate = DateHelper.getFirstDateOfYear(dataYear);

    if (dataYear === curYear) {
      return;
    }

    this.searchData.toDate = DateHelper.getLastDateOfYear(dataYear);
  }
}
