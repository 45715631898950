export enum BenefitRequestStatusEnum {
  New = 1,
  Approved = 2,
  Rejected = 3
}

export enum BenefitRequestStatusDisplayEnum {
  New = 'New',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export enum BenefitRequestStatusColorEnum {
  New = 'text-info',
  Approved = 'text-success',
  Rejected = 'text-danger'
}
