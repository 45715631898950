import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { HttpClientService } from '@intranet/index';

@Injectable({
  providedIn: 'root',
})
export class ProjectRelationService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/projectitemrelation';
  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }
}
