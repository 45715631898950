import { Component, Input, OnInit } from '@angular/core';
import { PubSubEvent } from '@intranet/lib/config';
import { Constants } from '@intranet/lib/config/constants';
import { UserService, Emote, UserTierService, UserTier } from '@intranet/lib/data-access';
import { SecurityHelper } from '@intranet/lib/helpers';
import { PubSubService } from '@intranet/lib/services';
import { ModalService, ModalSize } from '@intranet/lib/settings';
import * as moment from 'moment';
import { CreateLeaveComponent } from 'projects/intranet/hrm-user/components';
import { CreateBookingComponent } from 'projects/intranet/hrm-user/pages/user-booking/create-booking/create-booking.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-claim-daily',
  templateUrl: './claim-daily.component.html',
  styleUrls: ['./claim-daily.component.scss']
})
export class ClaimDailyComponent implements OnInit {
  tierInfoContent = '';
  loading = false;
  userReward: any;
  emotes = [];
  imgSrc: string[] = [];
  currentEmoteAddr: string;
  subscriptions: Subscription[] = [];

  constructor(
    private userService: UserService,
    private userTierService: UserTierService,
    private modalService: ModalService,
    private pubsub: PubSubService) { }
  async ngOnInit() {
    const reloadSub = this.pubsub.$sub(PubSubEvent.EVENTS.ON_CLAIM_DAILY_POINTS, () => this.loadClaimDaily());

    this.subscriptions.push(reloadSub);

    await this.loadClaimDaily();
  }
  async loadClaimDaily() {
    this.userReward = await this.userService.checkClaimDaily();

    const location = this.userReward.allEmotes.find(ae => ae.value === this.userReward.emote.value)?.location ?? this.userReward.emote.location;

    this.setEmote(location);
    this.emotes = this.userReward.allEmotes;
    this.imgSrc = this.emotes.map(e => e.disabledIcon);

    if (this.userReward && this.userReward.tier) {
      this.userReward.tier = this.userTierService.getImageUrl(this.userReward.tier);
      this.userReward.userTiers.sort((a, b) => (a.minimumPoints > b.minimumPoints) ? 1 : -1); // sort tiers array by minimumPoints from low to high.

      this.tierInfoContent = `Your tier is ${this.userReward.tier.name}`;
      const nextTierIndex = this.userReward.userTiers.findIndex(ut => ut.id === this.userReward.tier.id) + 1; // next tier ID is position of current ID + 1
      const currentTier = this.userReward.tier;
      if (nextTierIndex < this.userReward.userTiers.length) {
        const nextTier = this.userReward.userTiers[nextTierIndex];
        const diff = nextTier.minimumPoints - currentTier.minimumPoints;
        if (diff <= 100) {
          // this message only shows when points difference is smaller than or eq 100.
          this.tierInfoContent += ` and you need ${diff} points to reach ${nextTier.name}`;
        }
      }
    }
  }
  async claimDaily(emote: Emote) {
    await this.userService.claimDaily(emote.value);
    this.pubsub.$pub(PubSubEvent.EVENTS.ON_CLAIM_DAILY_POINTS);
  }
  setEmote(addr: string) {
    this.currentEmoteAddr = addr;
  }

  createLeave() {
    const defaultDate = moment().startOf('day').toDate();
    const modalRef = this.modalService.open(CreateLeaveComponent, { size: ModalSize.lg, windowClass: 'CreateLeaveComponent' });
    const d = moment(defaultDate);
    const dateString = d.format('YYYY-MM-DD').toString();
    const date = new Date(dateString);
    modalRef.componentInstance.data = {
      fromDate: date,
      toDate: date,
    };
  }

  createBooking() {
    const defaultDate = moment().startOf('day').toDate();
    const modalRef = this.modalService.open(CreateBookingComponent, { size: ModalSize.lg, windowClass: 'CreateBookingComponent' });
    modalRef.componentInstance.event = {
      start: moment(defaultDate).add(moment.duration(Constants.START_WORKING_TIME)).toDate(),
      end: moment(defaultDate).add(moment.duration(Constants.END_WORKING_TIME)).toDate(),
      meta: {
        createdBy: SecurityHelper.getStoreAuthen()?.id,
      },
    };
  }
}
