<div class="feed-images-component">
    <div *ngFor="let item of filesToDisplay; let i = index" (click)="onClickImage(item.url, $event)" class="d-inline-block mr-1 mb-1 btn-link ">
      <div *ngIf="item.fileType === FileExtensionType.IMAGE" class="image-container" [style.background-image]="'url(' + item.url + ')'">
        <div class="more-layer" *ngIf="i === (filesToDisplay.length - 1) && images.length > maxImageCount">+{{images.length - maxImageCount }}</div>
      </div>
  
      <div *ngIf="item.fileType === FileExtensionType.VIDEO" class="image-container">
        <div class="more-layer" *ngIf="i === (filesToDisplay.length - 1) && images.length > maxImageCount">+{{images.length - maxImageCount }}</div>
        <img class="playable-video" [src]="Constants.PlayImagePath" width="150" height="150"/>
        <video width="150" height="150">
          <source [src]="item.url" [type]="item.mimeType" />
        </video>
      </div>
  
    </div>
  </div>
  