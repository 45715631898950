import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Router } from '@angular/router';
import { Constants, ModalService, ModalSize, UrlConstant } from '@intranet/index';
import { BaseAdminComponent } from '@intranet/lib/components';
import { EVENT_CATEGORY, EVENT_NAME } from '@intranet/lib/config/google-analytics.constants';
import { CalendarEventTypeEnum, CalendarService, EventTypeEnum, MemberDto, SearchEvent } from '@intranet/lib/data-access';
import { CalendarDateFormatter, CalendarEvent, CalendarView } from 'angular-calendar';
import * as moment from 'moment';
import { CreateLeaveComponent } from 'projects/intranet/hrm-user/components';
import { CreateBookingComponent } from 'projects/intranet/hrm-user/pages/user-booking/create-booking/create-booking.component';
import { Subject } from 'rxjs';
import { CalendarDisplayTypeEnum } from '../../../hrm-lib/lib/data-access/models/enums/calendar-display-type.enum';
import { CustomDateFormatter } from '../../custom-date-formatter.provider';
const colors: any = {
  event: {
    primary: '#40a8c4',
    secondary: '#40a8c4 ',
  },
  booking: {
    primary: '#703ed3',
    secondary: '#703ed3',
  },
  leave: {
    primary: '#e94b86',
    secondary: '#e94b86',
  },
  default: {
    primary: '#fff',
    secondary: '#fff',
  }
};

const classNames: any = {
  holiday: 'cal-holiday-day',
  party: 'cal-party-day',
  companySponsor: 'cal-company-sponsor-day'
};

@Component({
  selector: 'app-newsfeed-events-calendar',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.scss'],
  styles: [],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class EventCalendarComponent extends BaseAdminComponent implements OnInit {
  @Input() displayType?: CalendarDisplayTypeEnum = CalendarDisplayTypeEnum.DASHBOARD;
  @Output() loaded?: EventEmitter<void> = new EventEmitter();
  displayTypeEnum = CalendarDisplayTypeEnum;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  selectedDate: any;
  today: Date = moment().startOf('day').toDate();
  viewDate: Date = moment().startOf('day').toDate();
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[];
  currentEvents: any;
  currentMeetings: any;
  currentLeaves: any;
  leaveMembers: MemberDto[] = [];
  searchEvent: SearchEvent = new SearchEvent();
  memberData: MemberDto[] = [];
  urlConstant = UrlConstant;

  readonly LeaveTabIndex = 0;
  readonly MeetingTabIndex = 1;

  currentSelectedTab = this.LeaveTabIndex;

  constructor(private router: Router, private modalSvc: ModalService, private calendarService: CalendarService, private analytics: AngularFireAnalytics) {
    super();
  }

  ngOnInit() {
    this.getEvents();
  }

  clickOnViewAll() {
    this.analytics.logEvent(EVENT_NAME.ViewCalendar, { event_category: EVENT_CATEGORY.Dashboard });
    this.router.navigate([this.urlConstant.mapUser(this.urlConstant.UserUrl.CALENDAR)]);
  }

  getColor(eventType: CalendarEventTypeEnum) {
    switch (eventType) {
      case CalendarEventTypeEnum.LEAVE:
        return colors.leave;
      case CalendarEventTypeEnum.BOOKING:
        return colors.booking;
      case CalendarEventTypeEnum.EVENT:
        return colors.event;
      default:
        return colors.default;
    }
  }

  getClassName(event: any) {
    const eventType = +event.eventType;
    if (eventType === CalendarEventTypeEnum.EVENT) {
      const eventObjectType = event?.data?.eventType ?? 0;
      switch (eventObjectType) {
        case EventTypeEnum.PARTY:
          return classNames.party;
        case EventTypeEnum.HOLIDAY:
          return classNames.holiday;
        case EventTypeEnum.COMPANY_SPONSOR:
          return classNames.companySponsor;
        default:
          return '';
      }
    }
    return '';
  }

  dayClicked(event): void {
    this.analytics.logEvent(EVENT_NAME.MiniCalendar_PickDate, { event_category: EVENT_CATEGORY.Dashboard });
    this.viewDate = event.day.date;
    this.getEvents();
  }

  onViewDateChange(date) {
    this.analytics.logEvent(EVENT_NAME.MiniCalendar_SwitchMonth, { event_category: EVENT_CATEGORY.Dashboard });
    this.viewDate = date;
    this.getEvents();
  }

  getEvents() {
    this.events = [];
    this.currentEvents = [];
    this.currentMeetings = [];
    this.currentLeaves = [];
    this.memberData = [];

    this.isLoading = true;

    this.searchEvent.fromDate = moment(this.viewDate).startOf('month').format('YYYY-MM-DD');
    this.searchEvent.toDate = moment(this.viewDate).endOf('month').format('YYYY-MM-DD');

    const startOfMonth = moment(this.viewDate).startOf('month');
    const endOfMonth = moment(this.viewDate).endOf('month');

    this.calendarService.getEventsByDate(
      this.searchEvent,
      (res: any) => {
        const events = [];
        for (const event of res) {
          // parse eventType enum to int
          const eventType = +event.eventType;
          if (isNaN(eventType)) {
            continue;
          }

          const eventStartTime = moment(event.fromDate);
          const eventEndTime = moment(event.toDate);

          const meta = {
            leaveType: event.data?.leaveType,
            departmentGroup: event.data?.departmentGroup,
            room: event.data?.room
          };
          event.meta = meta;

          const eventData = {
            start: eventStartTime.toDate(),
            end: eventEndTime.toDate(),
            title: event.name,
            data: event.data,
            eventType,
            allDay: this.isAllDayEvent(event),
            color: this.getColor(eventType),
            cssClass: this.getClassName(event),
            sameDay: moment.utc(event.fromDate).format('YYYY-MM-DD') === moment.utc(event.toDate).format('YYYY-MM-DD'),
            meta
          };
          if ((eventStartTime.isSameOrAfter(startOfMonth, 'day') &&
            eventStartTime.isSameOrBefore(endOfMonth, 'day')) ||
            (eventEndTime.isSameOrBefore(endOfMonth, 'day') &&
              eventEndTime.isSameOrAfter(startOfMonth, 'day'))) {
            const isToday = (eventStartTime.startOf('day').toDate() <= this.viewDate && eventEndTime.endOf('day').toDate() >= this.viewDate);
            if (isToday) {
              if (this.viewDate.getDay() !== 0 && this.viewDate.getDay() !== 6) {
                if (eventType === CalendarEventTypeEnum.LEAVE) {
                  this.currentLeaves.push(eventData);
                  this.memberData.push(this.currentLeaveToMemberDto(eventData));
                } else if (eventType === CalendarEventTypeEnum.BOOKING) {
                  this.currentMeetings.push(eventData);
                } else {
                  this.currentEvents.push(eventData);
                }
              }
            }
          }
          const eventDataList = this.splitLeaveEvent(eventData);
          events.push.apply(events, eventDataList);
        }
        this.events = this.filterLeavesInHoliday(events);
        this.priorityTabIndexToView();
      },
      () => { },
      () => {
        this.isLoading = false;
        this.loaded?.emit();
      },
    );
  }

  createLeave() {
    this.analytics.logEvent(EVENT_NAME.AddLeave, { event_category: EVENT_CATEGORY.Dashboard });
    const modalRef = this.modalSvc.open(CreateLeaveComponent, { size: ModalSize.lg, windowClass: 'CreateLeaveComponent' });
    const d = moment(this.viewDate);
    const dateString = d.format('YYYY-MM-DD').toString();
    const date = new Date(dateString);
    modalRef.componentInstance.data = {
      fromDate: date,
      toDate: date,
    };
    modalRef.result.then(res => {
      if (res) {
        this.getEvents();
      }
    });
  }

  createBooking() {
    this.analytics.logEvent(EVENT_NAME.BookMeeting, { event_category: EVENT_CATEGORY.Dashboard });
    const modalRef = this.modalSvc.open(CreateBookingComponent, { size: ModalSize.lg, windowClass: 'CreateBookingComponent' });
    modalRef.componentInstance.event = {
      start: moment(this.viewDate).add(moment.duration(Constants.START_WORKING_TIME)).toDate(),
      end: moment(this.viewDate).add(moment.duration(Constants.END_WORKING_TIME)).toDate(),
      meta: {
        createdBy: this.userId,
      },
    };
    modalRef.result.then(res => {
      this.getEvents();
    });
  }

  tabIndexChanges(tabIndex: number) {
    if (tabIndex === 0) {
      this.analytics.logEvent(EVENT_NAME.Calendar_Leave, { event_category: EVENT_CATEGORY.Dashboard });
    } else {
      this.analytics.logEvent(EVENT_NAME.Calendar_Meeting, { event_category: EVENT_CATEGORY.Dashboard });
    }
    this.currentSelectedTab = tabIndex;
  }

  private priorityTabIndexToView() {
    if (this.currentLeaves.length !== 0) {
      this.currentSelectedTab = this.LeaveTabIndex;
      return;
    }

    if (this.currentMeetings !== 0) {
      this.currentSelectedTab = this.MeetingTabIndex;
    }
  }

  private isAllDayEvent(event: any) {
    const isAllDayEvent = event.eventType === CalendarEventTypeEnum.EVENT ||
      (event.meta && event.meta.leaveType && event.meta.leaveType.halfDay === 'All day');
    return isAllDayEvent;
  }

  private currentLeaveToMemberDto(leaveInfo: any) {
    const leaveRequest = leaveInfo.data;
    const memberDto = new MemberDto();
    memberDto.avatarUrl = leaveRequest.avatarUrl;
    memberDto.departments = leaveRequest.departments;
    memberDto.departmentsJson = leaveRequest.departmentsJson;
    memberDto.positionName = leaveRequest.positionName;
    memberDto.employeeId = leaveRequest.employeeId;
    memberDto.fullName = leaveRequest.fullName ?? leaveRequest.employeeName;
    memberDto.meta = leaveInfo;
    return memberDto;
  }

  private splitLeaveEvent(leaveEvent: any): any[] {
    if (leaveEvent.eventType !== CalendarEventTypeEnum.LEAVE
      || leaveEvent.start.toDateString() === leaveEvent.end.toDateString()
      || leaveEvent.sameDay) {
      return [leaveEvent];
    }

    const startDate = new Date(leaveEvent.start);
    const endDate = new Date(leaveEvent.end);
    const result = [];

    for (const date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      // Ignore saturday and sunday.
      if (date.getDay() === 0 || date.getDay() === 6) {
        continue;
      }

      const event = {
        allDay: leaveEvent.allDay,
        color: leaveEvent.color,
        cssClass: leaveEvent.cssClass,
        data: leaveEvent.data,
        eventType: leaveEvent.eventType,
        meta: leaveEvent.meta,
        start: new Date(date),
        end: new Date(date),
        sameDate: true,
        title: leaveEvent.title,
      };

      result.push(event);
    }

    return result;
  }

  private filterLeavesInHoliday(events: any[]): any[] {
    // Expect: leave events in events
    const holidayEvents = events.filter(e => e.eventType === CalendarEventTypeEnum.EVENT);
    const result = events.filter(event => event.eventType !== CalendarEventTypeEnum.LEAVE
      || holidayEvents.every(holiday => !this.isEventInHoliday(event, holiday)));
    return result;
  }

  private isEventInHoliday(event: any, holiday: any): boolean {
    const eventStart = new Date(event.start).setHours(0, 0, 0, 0);
    let eventEnd = new Date(event.end).setHours(0, 0, 0, 0);
    const holidayStart = new Date(holiday.start).setHours(0, 0, 0, 0);
    let holidayEnd = new Date(holiday.end).setHours(0, 0, 0, 0);
    if (event.sameDay) {
      eventEnd = eventStart;
    }

    if (holiday.sameDay) {
      holidayEnd = holidayStart;
    }

    return holidayStart <= eventStart && eventEnd <= holidayEnd;
  }
}
