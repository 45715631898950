import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WidgetMonthlyTrackingTimeDto } from '@intranet/lib/data-access/models';

@Component({
  selector: 'app-widget-tracking-monthly',
  templateUrl: './widget-tracking-monthly.component.html',
  styleUrls: ['./widget-tracking-monthly.component.scss']
})
export class WidgetTrackingMonthlyComponent implements OnInit {

  @Input()
  listMonthly: WidgetMonthlyTrackingTimeDto[];
  constructor() { }

  ngOnInit() {
  }

}
