<div *ngIf="feed">
  <modal-header [title]="headerTemplate" [activeModal]="activeModal">
    <ng-template #headerTemplate>
      <h4 class="modal-title" *ngIf="!feedId">[Add] {{feed.title}}</h4>
      <h4 class="modal-title" *ngIf="feedId">[Edit] {{feed.title}}</h4>
    </ng-template>
  </modal-header>
  <form class="feed-form" (ngkSubmit)="onSubmit()" #frm="ngForm" [ngkForm]="true">
    <modal-body>
      <div class="row">
        <div class="form-group col">
          <label for="title">Title</label>
          <input nz-input placeholder="What's on your mind" [(ngModel)]="feed.title" nzSize="large" name="title" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>
      <div class="row">
        <div class="form-group col">
          <label for="shortDescription">Short Description</label>
          <textarea nz-input placeholder="Short description" [(ngModel)]="feed.shortDescription" nzSize="large" name="shortDescription"
            [ngModelOptions]="{standalone: true}"></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <label for="description">Description</label>
          <app-share-editor #editor [(preview)]="feed.contentHtml" [(content)]="feed.description" [editorHeight]="'250'"
            [isLoading]="isLoading" [(hashtags)]="feed.hashtags" [(mentions)]="feed.mentions">
          </app-share-editor>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="publicityLevel">Privacy Level</label>
          <select-search name="publicity-level" required [(ngModel)]="feed.publicityLevel" [data]="publicityLevels" required textField="name" valueField="id"
            placeholder="Publicity level ...">
          </select-search>
        </div>
        <div class="form-group col-md-8" *ngIf="isPrivateGroup()">
          <label for="isImportant">Group/Department</label>
          <nz-select *ngIf="isPrivateGroup()" class="form-select" name="department-groups" nzMode="multiple" nzPlaceHolder="Group/Department"
            [(ngModel)]="feed.departments">
            <nz-option *ngFor="let item of departments" [nzLabel]="item.name" [nzValue]="item.id">
            </nz-option>
          </nz-select>
        </div>
      </div>
      <div class="form-row" *hasPermission="['newsfeed', 'updateticket']">
        <div class="form-group col-md-4" *ngIf="feed.newsFeedTypeId === newsFeedTypes.TICKET && feedId">
          <label for="status">Status</label>
          <select-search name="status" required [(ngModel)]="feed.status" [data]="statuses" required textField="name" valueField="id" placeholder="Status">
          </select-search>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12 single-choice-option">
          <label nz-checkbox [(ngModel)]="feed.allowLike" name="allowLike" [ngModelOptions]="{standalone: true}">Allow Like</label>
          <label nz-checkbox [(ngModel)]="feed.allowComment" name="allowComment" [ngModelOptions]="{standalone: true}">Allow Comment</label>
          <label nz-checkbox [(ngModel)]="feed.isShow" name="visible" [ngModelOptions]="{standalone: true}">Visible</label>
          <label *ngIf="!feedId" nz-checkbox [(ngModel)]="feed.isSendNotification" name="isSendNotification" [ngModelOptions]="{standalone: true}">Send Notification Email</label>
          <label nz-checkbox [(ngModel)]="feed.isImportant" name="isImportant" [ngModelOptions]="{standalone: true}">Highlight</label>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6" *ngIf="feed.isImportant">
          <label for="period">Highlight Begin Date</label>
          <nz-date-picker [nzFormat]="dateFormat" [(ngModel)]="feed.beginHighlightDate" (ngModelChange)="onChangeHighlightBeginDate($event)" [nzShowToday]="true"
            [ngModelOptions]="{ standalone: true }"></nz-date-picker>
        </div>
        <div class="form-group col-md-6" *ngIf="feed.isImportant">
          <label for="period">Highlight End Date</label>
          <nz-date-picker [nzFormat]="dateFormat" [(ngModel)]="feed.endHighlightDate" (ngModelChange)="onChangeHighlightEndDate($event)"
            [nzDisabledDate]="disableEndHighlighDate" [nzShowToday]="true" [ngModelOptions]="{ standalone: true }"></nz-date-picker>
        </div>
      </div>

      <div class="extra" [ngSwitch]="feed.newsFeedTypeId">
        <div *ngSwitchCase="newsFeedTypes.WALL">

        </div>
        <div *ngSwitchCase="newsFeedTypes.POLL">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="period">Vote Start Date</label>
              <nz-date-picker nzFormat="dd-MM-yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep:10,nzUse12Hours:false  }" [(ngModel)]="feed.beginResultDate"
                (ngModelChange)="onChangeResultBeginDate($event)" [nzShowToday]="true" [ngModelOptions]="{ standalone: true }"></nz-date-picker>
            </div>
            <div class="form-group col-md-6">
              <label for="period">Vote End Date</label>
              <nz-date-picker nzFormat="dd-MM-yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' , nzMinuteStep:10,nzUse12Hours:false }" [(ngModel)]="feed.endResultDate"
                (ngModelChange)="onChangeResultEndDate($event)" [nzDisabledDate]="disableEndResultDate" [nzShowToday]="true" [ngModelOptions]="{ standalone: true }">
              </nz-date-picker>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group  col-md-4">
              <label nz-checkbox [(ngModel)]="feed.allowViewInstanceResult" name="allowViewInstanceResult" [ngModelOptions]="{standalone: true}">Allow View
                Results</label>
            </div>
            <div class="form-group  col-md-4">
              <label for="durationChange">Duration of Change</label>
              <nz-input-number [nzPlaceHolder]="'Change result duration (Min)'" [(ngModel)]="feed.durationChange" [ngModelOptions]="{ standalone: true }" [nzMin]="1"
                [nzStep]="1"></nz-input-number>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label nz-checkbox [(ngModel)]="feed.enableRewardPoint" name="enableRewardPoint" [ngModelOptions]="{standalone: true}">Enable Reward Point</label>
            </div>
          </div>
          <div class="mt-2">
            <app-newsfeed-detail-poll [feed]="feed"></app-newsfeed-detail-poll>
          </div>
        </div>
        <div *ngSwitchCase="newsFeedTypes.BIRTHDAY">

        </div>
        <div *ngSwitchCase="newsFeedTypes.ANNOUNCEMENT">

        </div>
        <div *ngSwitchCase="newsFeedTypes.STICKY">

        </div>
        <div *ngSwitchDefault></div>
      </div>
      <div class="form-row" *ngIf="!feed.isNewcomerAnnouncement">
        <div class="form-group col-12">
          <app-internal-upload-file [model]="modelUpload" [multiple]="true" [imageOnly]="false">
          </app-internal-upload-file>
        </div>
      </div>
    </modal-body>
    <modal-footer>
      <button type="button" class="btn btn-white" (click)="closeModal()"><i
          class="far fa-times-circle mr5"></i>Close</button>
      <create-update-button [nzLoading]="isLoading" [isUpdate]="feedId">Save
      </create-update-button>
    </modal-footer>
  </form>
</div>
