import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { Subject } from 'rxjs';
import { SecurityHelper } from '@intranet/index';

@Injectable({
  providedIn: 'root',
})
export class FeedRealtimeService {
  private hubConnection: signalR.HubConnection;

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${AppConfig.settings.API_URL}/feed`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => SecurityHelper.getStoreAuthen().token,
      })
      .withAutomaticReconnect()
      .build();

    this.hubConnection.start();

  }

  /* Feed hub */
  public addTransferResultListener = (callback: (params?: any) => void) => {
    this.hubConnection.on('FeedCreated', data => {
      callback(data);
    });
  }

  public addTransferResultUpdatedListener = (callback: (params?: any) => void) => {
    this.hubConnection.on('FeedUpdated', data => {
      callback(data);
    });
  }

  public addTransferResultDeletedListener = (callback: (params?: any) => void) => {
    this.hubConnection.on('FeedDeleted', data => {
      callback(data);
    });
  }

  /* Comment hub */
  public addTransferCommentListener = (callback: (params?: any) => void) => {
    this.hubConnection.on('CommentCreated', data => {
      callback(data);
    });
  }

  public addTransferCommentUpdatedListener = (callback: (params?: any) => void) => {
    this.hubConnection.on('CommentUpdated', data => {
      callback(data);
    });
  }

  public addTransferCommentDeletedListener = (callback: (params?: any) => void) => {
    this.hubConnection.on('CommentDeleted', data => {
      callback(data);
    });
  }

}
