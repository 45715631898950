/* tslint:disable */
import { Injectable } from '@angular/core';
import { SortDirection } from '@swimlane/ngx-datatable';
import { NzTreeNodeOptions } from 'ng-zorro-antd/core/tree';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Constants, UrlConstant } from '../config';
import { ITreeNode, PagingSortenum, TreeNodeInterface, Treeable } from '../data-access';
import { WebsiteEvent } from '../settings';
@Injectable({
  providedIn: 'root',
})
export class CommonHelper {
  static isRefreshBrowser = false;
  static getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    // tslint:disable-next-line:no-non-null-assertion
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  static getUserType(type: string) {
    switch (type) {
      case Constants.TYPE_USER.admin:
        return 'candidate';
      default:
        return type;
    }
  }

  public static GetUniqueId(): string {
    var result: string;
    var i: string;
    var j: number;

    result = '';

    for (j = 0; j < 32; j++) {
      if (j == 8 || j == 12 || j == 16 || j == 20) result = result;
      i = Math.floor(Math.random() * 16)
        .toString(16)
        .toLowerCase();
      result = result + i;
    }

    return result;
  }

  static firstCharToUpper(input: string) {
    if (input != null && input != '') {
      var result = input.split(' ').map(x => {
        const firstLetter = x[0];
        if (firstLetter) {
          return firstLetter.toUpperCase();
        }
      });
      var result2 = result.join('');
      return result2;
    }
    return '';
  }

  static convertTreeToList(root: ITreeNode): any[] {
    const stack: ITreeNode[] = [];
    const array: ITreeNode[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: false });

    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level! + 1, expand: false, parent: node });
        }
      }
    }

    return array;
  }

  static convertTreeToListNew(root: any): any[] {
    const stack: any[] = [];
    const array: any[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: true });

    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode2(node, hashMap, array);
      if (node.warehouseDetails) {
        node.warehouseDetails = node.warehouseDetails.filter(x => x.remain > 0);
        for (let i = node.warehouseDetails.length - 1; i >= 0; i--) {
          stack.push({ ...node.warehouseDetails[i], level: node.level! + 1, expand: true, parent: node });
        }
      }
    }

    return array;
  }

  static visitNode2(node: TreeNodeInterface, hashMap: { [key: string]: boolean }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.id]) {
      hashMap[node.id] = true;
      array.push(node);
    }
  }

  static visitNode(node: ITreeNode, hashMap: { [key: string]: boolean }, array: ITreeNode[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }

  static range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  static sortAlphabet(data: any) {
    data.sort(function (a, b) {
      let nameA = a.title.toUpperCase();
      let nameB = b.title.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // name trùng nhau
      return 0;
    });
    return data;
  }

  static swap<T>(array: T[], index1: number, index2: number) {
    if (!array || array.length === 0 || index1 >= array.length || index2 >= array.length || index1 == index2) {
      return;
    }

    [array[index1], array[index2]] = [array[index2], array[index1]];
  }

  // Sort optional key selectors, allow sorting by multiple keys.
  static sort<T>(array: T[], ...getPropertyFns: ((_: T) => any)[]): T[] {
    let sortFn = (left: T, right: T) => (left < right ? -1 : 1);

    if (getPropertyFns) {
      sortFn = (left: T, right: T) => {
        for (const getPropFn of getPropertyFns) {
          if (getPropFn(left) < getPropFn(right)) {
            return -1;
          } else if (getPropFn(left) > getPropFn(right)) {
            return 1;
          }
        }
      };
    }

    return array.sort(sortFn);
  }

  static scrollUnderElementById(elId: string, topEl: HTMLElement, options?: any) {
    const el = document.getElementById(elId);

    if (!el || !topEl) {
      if (options?.scrollTopIfFailed) {
        CommonHelper.scrollTop();
      }
      return;
    }

    const bodyCoord = document.body.getBoundingClientRect();
    const elCoord = el.getBoundingClientRect();
    const topElCoord = topEl.getBoundingClientRect();

    if (!bodyCoord || !elCoord || !topElCoord) {
      if (options?.scrollTopIfFailed) {
        CommonHelper.scrollTop();
      }
      return;
    }

    let target: HTMLElement | Window = window;
    if (options?.targetId) {
      const customTarget = document.getElementById(options.targetId);
      target = customTarget || target;
    }

    let topPosition = bodyCoord.top;
    if (options?.topPositionElId) {
      const topPosElCoord = document.getElementById(options.topPositionElId)?.getBoundingClientRect();
      if (topPosElCoord) {
        topPosition = topPosElCoord.top - topElCoord.bottom;
      }
    }

    const scrollBehavior = 'smooth';
    CommonHelper.scrollTo(Math.abs(elCoord.top - topPosition - topElCoord.bottom), elCoord.left, scrollBehavior, target);

    // First scroll may not be in right place,
    // Sleep 100ms to make browser update coordination DOM elements and scroll again.
    setTimeout(() => {
      const bodyCoord = document.body.getBoundingClientRect();
      const elCoord = el.getBoundingClientRect();
      const topElCoord = topEl.getBoundingClientRect();

      let topPosition = bodyCoord.top;
      if (options?.topPositionElId) {
        const topPosElCoord = document.getElementById(options.topPositionElId)?.getBoundingClientRect();
        if (topPosElCoord) {
          topPosition = topPosElCoord.top - topElCoord.bottom;
        }
      }

      CommonHelper.scrollTo(Math.abs(elCoord.top - topPosition - topElCoord.bottom), elCoord.left, scrollBehavior, target);
    }, options?.sleepDuration || 1000);
  }

  static scrollTop(target: HTMLElement | Window = window) {
    CommonHelper.scrollTo(0, 0, 'smooth', target);
  }

  static scrollTo(top: number, left: number = 0, behavior: ScrollBehavior = 'smooth', target: HTMLElement | Window = window) {
    top = Math.round(top);
    left = Math.round(left);
    target.scrollTo({
      top,
      left,
      behavior,
    });
  }

  static isElementUnderOtherElement(elId: string, topEl: HTMLElement, options?: any): boolean {
    const elCoord = document.getElementById(elId)?.getBoundingClientRect();
    const topElCoord = topEl?.getBoundingClientRect();

    if (!elCoord || !topElCoord) {
      return false;
    }

    const translationDelta = options?.translationDelta || 10;
    return topElCoord.bottom <= elCoord.bottom - translationDelta && topElCoord.bottom >= elCoord.top - translationDelta;
  }

  static isElementAboveContainerHeight(elId: string, target: HTMLElement = null): boolean {
    const elCoord = document.getElementById(elId)?.getBoundingClientRect();

    let containerHeight = window.innerHeight;
    const containerCoord = target?.getBoundingClientRect();
    if (containerCoord) {
      containerHeight = containerCoord.bottom;
    }

    return elCoord?.bottom <= containerHeight;
  }

  static flatten<T>(array: T[][]): T[] {
    return [].concat.apply([], array);
  }

  static isExpandedFront(): boolean {
    return localStorage.getItem('isExpanedFront') ? true : false;
  }

  static setExpandedFront(value: boolean = true) {
    if (!value) {
      this.unsetExpandedFront();
      return;
    }

    localStorage.setItem('isExpanedFront', 'true');
  }

  static unsetExpandedFront() {
    localStorage.removeItem('isExpanedFront');
  }

  static isMobileView() {
    return window.innerWidth <= Constants.SmallViewMaxWidthInPixel;
  }

  static isPhoneView() {
    return window.innerWidth <= Constants.MobileMaxWidthInPixel;
  }

  static isTabletView() {
    return this.isMobileView() && window.innerWidth > Constants.MobileMaxWidthInPixel;
  }

  static subscribeResizeEvent(websiteEvent: WebsiteEvent, fn: () => void): Subscription {
    return websiteEvent.eventChangeHeight.pipe(startWith(window.innerHeight)).subscribe(fn);
  }

  static scheduleToExecute(fn: () => void, delay: number = 0) {
    // This call to setTimeout with 0ms will put `fn` in waiting queue to run whenever no code is currently executing.
    // https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful
    setTimeout(fn, delay);
  }

  static openPolicyLink(policyId: string, policyDetailId: string = null) {
    const policyDetailParam = policyDetailId ? `&policyDetailId=${policyDetailId}` : '';

    const url = `${UrlConstant.POLICY_PAGE}?policyId=${policyId}${policyDetailParam}`;

    window.open(url, '_blank');
  }

  // asc/desc -> 0/1
  static mapTableSortDirection(sortDirection: SortDirection): PagingSortenum {
    if (sortDirection === SortDirection.asc) return PagingSortenum.ASC;

    return PagingSortenum.DESC;
  }

  // 0/1 -> asc/desc
  static mapToTableSortDirection(sortDirection: PagingSortenum): SortDirection {
    if (sortDirection === PagingSortenum.ASC) return SortDirection.asc;

    return SortDirection.desc;
  }

  static removeEmptyProperty(obj: {}): void {
    Object.entries(obj).forEach(([key, value]) => !value?.toString() && delete obj[key]);
  }

  static convertToTree<T extends Treeable>(rawTreeItems: T[], parentId?: string): NzTreeNodeOptions[] {
    return rawTreeItems
      .filter(i => i.parentId === parentId || (!parentId && (!i.parentId || rawTreeItems.every(j => j.id !== i.parentId))))
      .sort((i, j) => i.title.localeCompare(j.title))
      .map(i => {
        const children = CommonHelper.convertToTree(rawTreeItems, i.id);

        return { ...i, key: i.id, isLeaf: !children.length, children };
      });
  }
}
