<div class="widget-attendance-container">
  <nz-table #basicMemberTable [nzPageSize]="15" [nzShowPagination]="isShowPagination" [nzData]="data">
    <thead>
      <tr>
        <th *ngFor="let column of listOfColumns" [nzWidth]="column.width" [class]="column.class" [nzSortOrder]="column.sortOrder" [nzSortFn]="column.sortFn"
          [nzSortDirections]="column.sortDirections">
          {{ column.name }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicMemberTable.data">
        <td>
          <div class="row">
            <div class="col-3">
              <app-avatar [size]="70" [(avatarSource)]="data.avatarUrl" [(avatarText)]="data.fullName"> </app-avatar>
            </div>
            <div class="col-9">
              <div class="row mt-2">
                <div (click)="openInformation(data)" class="col-9 font-12 employee-information">
                  <p class="font-weight-bold">{{data.fullName}}</p>
                  <p class="font-weight-bold">{{data.positionName}}</p>
                  <div *ngIf="data.departments">
                    <nz-tag *ngFor="let department of data.departments" [nzColor]="'geekblue'">
                      <span *ngIf="department" [innerHTML]="department.name"></span>
                    </nz-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-right">{{data.totalMissingHoursYearly}}</td>
        <td class="text-right">{{data.totalLeaveHours}}</td>
        <td class="text-right">
          <span [class]="data.classNameHoursGap"  nz-popover nzPopoverTrigger="click" [nzPopoverContent]="explainIsExceptFieldTemplate" *ngIf="data.hoursGap < 0">
          {{data.hoursGap}}
          <i nz-icon nzType="info-circle" nzTheme="outline"></i>
          <ng-template #explainIsExceptFieldTemplate>
            {{data.totalRealHoursWorking}} - {{data.totalHoursWorkingSetting}} (Accumulated hours - Standard hours)
          </ng-template>
          </span>
          <span class="text-success" *ngIf="data.hoursGap >= 0"><i nz-icon nzType="check-circle" nzTheme="outline"></i></span>
        </td>
      </tr>
    </tbody>
  </nz-table>
  
  <div class="widget-attendance-detail-wrapper">
    <div class="title">
      <ng-container *ngIf="data?.length > 0">
        <div class="title-missing-hours">Missing hours</div>
        <div class="title-off-hours">Off-hours</div>
        <div class="title-difference">Differrence</div>
      </ng-container>
    </div>
  
    <div class="member-data">
      <div class="no-member" *ngIf="!data?.length">
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="noMemberTpl"></nz-empty>
        <ng-template #noMemberTpl>
          <span class="no-member-text">No data</span>
        </ng-template>
      </div>
  
      <virtual-scroller *ngIf="data?.length > 0" #scroll [items]="data" class="scroller" [style.height.px]="data.length * 80" [bufferAmount]="7">
        <div class="member-row-data" *ngFor="let member of scroll.viewPortItems; let idx = index">
          <!-- <div class="member-avatar">
            <app-avatar [size]="40" [(avatarSource)]="member.avatarUrl" [(avatarText)]="member.fullName"> </app-avatar>
          </div> -->
  
          <div (click)="openInformation(member)" class="font-12 member-information">
            <p>{{member.fullName}}</p>
            <p>{{member.positionName}}</p>
            <div *ngIf="member.departments">
              <nz-tag *ngFor="let department of member.departments" [nzColor]="'geekblue'">
                <span [innerHTML]="department.name"></span>
              </nz-tag>
            </div>
          </div>
  
          <div class="member-hoursgap">
            <div *ngIf="member.totalMissingHoursYearly < 0" class="missing-hours-yearly text-danger" nz-popover nzPopoverTrigger="click"
              [nzPopoverContent]="explainMissingHoursYearlyTpl">
              {{member.totalMissingHoursYearly}}
              <i nz-icon nzType="info-circle" nzTheme="outline"></i>
              <ng-template #explainMissingHoursYearlyTpl>
                Total missing hours in {{fromYear}}
              </ng-template>
            </div>
            <div *ngIf="member.totalMissingHoursYearly >= 0" class="missing-hours-yearly text-success">
              <i nz-icon nzType="check-circle" nzTheme="outline" class="text-success"></i></div>
            <div class="off-hours">{{member.totalLeaveHours}}</div>
            <div class="difference">
              <span [class]="member.classNameHoursGap" nz-popover nzPopoverTrigger="click" [nzPopoverContent]="explainIsExceptFieldTemplate" *ngIf="member.hoursGap < 0">
              {{member.hoursGap}}
              <i nz-icon nzType="info-circle" nzTheme="outline"></i>
              <ng-template #explainIsExceptFieldTemplate>
                {{member.totalRealHoursWorking}} - {{member.totalHoursWorkingSetting}} (Accumulated hours - Standard hours)
              </ng-template>
              </span>
              <span class="text-success" *ngIf="member.hoursGap >= 0"><i nz-icon nzType="check-circle" nzTheme="outline"></i></span>
            </div>
          </div>
        </div>
      </virtual-scroller>
    </div>
  </div>
</div>