import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BaseLayoutComponent } from '../../base-layout/base-layout.component';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { slideInAnimation } from '@intranet/lib/directives/animation';
import { NavigationEnd, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterEvent, RoutesRecognized } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { Constants, PubSubEvent } from '@intranet/lib/config';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { CommonHelper } from '@intranet/lib/helpers';

@Component({
  selector: 'app-front-policy-layout',
  templateUrl: './front-policy-layout.component.html',
  styleUrls: ['./front-policy-layout.component.scss'],
  animations: [slideInAnimation],
})
export class FrontPolicyLayoutComponent extends BaseLayoutComponent implements OnInit, OnDestroy {
  currentUser = this.currentUser;
  year = new Date().getFullYear();
  systemName: string;
  isExpanded = false;
  showLeftPanel = false;
  showRightPanel = false;

  showSidebar$: Observable<string>;
  subscriptions: Subscription[] = [];
  constants = Constants;
  layout: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private pubsub: PubSubService) {
    super();
    this.systemName = AppConfig.settings.App_Name;
    this.layout = this.constants.LAYOUTS.ONE_COLUMN;
    this.isExpanded = CommonHelper.isExpandedFront();

    const sub = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute.snapshot),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.layout = route.data.layout;
      });

    this.subscriptions.push(sub);
  }

  ngOnInit() {
    this.subscriptions.push(this.pubsub.$sub(PubSubEvent.EVENTS.TOGGLE_LEFT_PANEL).subscribe(isExpanded => {
      CommonHelper.setExpandedFront(isExpanded);

      this.isExpanded = isExpanded;
    }));

    // this.subscriptions.push(this.pubsub.$sub(PubSubEvent.EVENTS.TOGGLE_RIGHT_PANEL).subscribe(result => {
    //   this.toggleRightPanel(result);
    // }));

    // this.subscriptions.push(this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.pubsub.$pub(PubSubEvent.EVENTS.TOGGLE_LEFT_PANEL, false);
    //     this.pubsub.$pub(PubSubEvent.EVENTS.TOGGLE_RIGHT_PANEL, false);
    //   }
    // }));
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
