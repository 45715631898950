import { BaseModel } from './base-model';

export class Room extends BaseModel {
  id?: string;
  name?: string;
  level?: number;
  colorInCalendar?: string;
  constructor() {
    super();
    this.colorInCalendar = '#2889e9';
  }
}
