import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { HttpClientService } from '@intranet/index';

@Injectable({
  providedIn: 'root',
})
export class ProjectDocumentService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/projectdocument';
  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getList(projectId) {
    const url = this.hostUrl + '/get_list_by_id/' + projectId;
    return this.httpClient.getSync(url);
  }

  getDetail(documentId) {
    const url = this.hostUrl + '/get_detail/' + documentId;
    return this.httpClient.getSync(url);
  }
}
