import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NewsFeedTypeEnum, Department, PublicityLevel, NewsFeed, UserPoll, UserPollOption } from '@intranet/lib/data-access';
import { Guid } from 'guid-typescript';
import { WallNewsFeedService } from '@intranet/lib/data-access/services/wall-news-feed.service';
import { BaseAdminComponent } from '@intranet/lib/components';
import { Toastr } from '@intranet/lib/settings/services/toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-news-feed-poll',
  templateUrl: './news-feed-poll.component.html',
  styleUrls: ['./news-feed-poll.component.scss']
})
export class NewsFeedPollComponent extends BaseAdminComponent implements OnInit {
  poll: NewsFeed;
  activeModal: NgbActiveModal;
  questions = [];
  questionId: string;
  optionId: string;

  questionName: string;
  optionName: string;
  showRewardConfiguration: boolean;

  constructor(modalRef: NgbActiveModal, private newsFeedService: WallNewsFeedService) {
    super();
    this.isLoading = false;
    this.activeModal = modalRef;
  }

  ngOnInit() {
    if (!this.poll || !this.poll.id) {
      this.activeModal.close();
    }

    if (this.currentUser) {
      const userDepartments = JSON.parse(this.currentUser.departments);
    }
    this.getResult();
  }

  getResult() {
    try {
      this.isLoading = true;
      const self = this;
      return this.newsFeedService.getPollResult({ id: this.poll.id }, (results: UserPoll[]) => {
        this.isLoading = false;
        this.poll.pollQuestions.forEach(item => {
          const question = {
            id: item.id,
            content: item.content,
            options: item.options,
            users: []
          };
          let questionResults;

          if (self.questionId) {
            questionResults = results.filter(r => r.questionId === self.questionId);
            self.questionName = question.content;
          }
          else {
            questionResults = results.filter(r => r.questionId === item.id);
          }

          const users = questionResults.reduce((users: any, result: UserPoll) => {
            if (!self.optionId) {
              (result?.notes || []).forEach(note => {
                const fullName = note?.user?.fullName;
                if (fullName) {
                  users[fullName] = users[fullName] || [];
                  users[fullName].note = note.description;
                }
              });
            }
            result.items.map((choice) => {
              if (!self.optionId || (self.optionId && self.optionId === choice.userPollOptionId)) {
                const fullName = choice?.user?.fullName;
                if (fullName) {
                  users[fullName] = users[fullName] || [];
                  users[fullName].items = users[fullName].items || [];
                  users[fullName].items.push(choice.title);
                  self.optionName = choice.title;
                }
                (result?.notes || []).forEach(note => {
                  if (note?.user?.fullName === fullName) {
                    users[fullName] = users[fullName] || [];
                    users[fullName].note = note.description;
                    users[fullName].createdDate = note.createdDate ? moment.utc(note.createdDate).toDate() : new Date();
                  }
                });
              }
            });
            return users;
          }, Object.create(null));

          Object.keys(users).map((key) => {
            const value = users[key];
            question.users.push({
              fullName: key,
              createdDate: value.createdDate,
              response: value
            });

          });

          question.options.forEach(option => {
            if (questionResults && questionResults.length) {
              option.users = questionResults[0].items.filter(r => r.userPollOptionId === option.id)
                .map(m => {
                  let r: any = {};
                  r = { ...r, ...m.user };
                  // for user poll choice id
                  r.choiceId = m.id;
                  // r.id for userId
                  r.hasRewardPoint = m.hasRewardPoint;
                  r.rewardedPoint = r.hasRewardPoint;
                  r.rewardPoint = option.rewardPoint;
                  return r;
                });
            }
          });
          if (self.questionId && question.id === self.questionId) {
            self.questions.push(question);
          }
          else if (!self.questionId) {
            self.questions.push(question);
          }
          if (question.users) {
            question.users = question.users.sort((a, b) => {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              const datea = new Date(a.createdDate);
              const dateb = new Date(b.createdDate);
              return datea.getTime() - dateb.getTime();
            });
          }
        });
      }, () => {
        this.isLoading = false;
      });
    } catch (e) {
      this.isLoading = false;
      Toastr.error(e.toString());
    }
  }

  onShowRewardPoint() {
    this.showRewardConfiguration = !this.showRewardConfiguration;
  }

  getIndeterminate(option) {
    if (!option || !option.users || !option.users.length || option.hasRewardPoint) {
      return false;
    }
    if (option.users.length === option.users.filter(item => item.hasRewardPoint).length) {
      option.hasRewardPoint = true;
      return false;
    }
    return option.users.some(item => item.hasRewardPoint);
  }

  checkAllUsers(option) {
    if (!option || !option.users) {
      return;
    }
    option.users.forEach(element => {
      if (!element.rewardedPoint) {
        element.hasRewardPoint = option.hasRewardPoint;
      }
    });
  }

  checkUser(option) {
    if (option.users.every(item => !item.hasRewardPoint)) {
      option.hasRewardPoint = false;
    } else if (option.users.every(item => item.hasRewardPoint)) {
      option.hasRewardPoint = true;
    }
  }

  onSubmitReward() {
    const request = [];
    this.questions.forEach(element => {
      const options = element.options.filter(item => item.users && item.users.length);
      options.forEach(option => {
        option.users.forEach(user => {
          request.push(user);
        });
      });
    });
    this.newsFeedService.savePollReward(request, res => {
      Toastr.success('Update user reward point successfully!');
      this.showRewardConfiguration = false;
    });
  }
}
