import { Component, OnInit, Input, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { WidgetService, WidgetSkillTeamDto, WidgetSearchDto, MemberDisplayMode, MemberDto } from '@intranet/lib/data-access';
import { Subscription } from 'rxjs';
import { GridsterItem } from 'angular-gridster2';
import { WebsiteEvent } from '@intranet/lib/settings/event-store/website.event';
import * as moment from 'moment';
import { CommonHelper } from '@intranet/lib/helpers/commonHelper';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
@Component({
  selector: 'app-widget-skill',
  templateUrl: './widget-skill.component.html',
  styleUrls: ['./widget-skill.component.scss'],
})
export class WidgetSkillComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;
  @Input() displayMode = MemberDisplayMode.UserWidget;
  @Input() widgetLoaded: EventEmitter<any>;
  resizeSub: Subscription;
  selectGroupSubscription: Subscription;

  isLoading = true;
  searchData: WidgetSearchDto = new WidgetSearchDto();
  data: WidgetSkillTeamDto = new WidgetSkillTeamDto();
  isDetail = false;
  title: string;

  filterSkillName: string;
  memberDisplayData: MemberDto[] = [];

  get isClientPage() {
    return this.displayMode === MemberDisplayMode.ClientPage;
  }

  constructor(private _widgetService: WidgetService,
    private websiteEvent: WebsiteEvent) { }

  ngOnDestroy() {
    if (this.selectGroupSubscription) {
      this.selectGroupSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.searchData.clientMode = this.isClientPage;

    this.selectGroupSubscriptionEvent();
  }

  selectGroupSubscriptionEvent() {
    this.selectGroupSubscription = this.websiteEvent.groupSourceDataEvent.subscribe((departmentId: any) => {
      if (!this.searchData.departmentIds || departmentId === '') {
        this.searchData.departmentIds = departmentId !== '' ? departmentId : null;
        this.loadData();
      }
    });
  }

  async loadData() {
    this.isLoading = true;

    this.searchData.noLoading = this.isClientPage;
    try {
      this.data = await this._widgetService.getWidgetSkillTeam(this.searchData);
      this.formatData();
      this.selectPie(this.filterSkillName);
      this.widgetLoaded?.emit(this.widget);
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  modelChanged(event) {
    this.searchData.departmentIds = event;
    this.loadData();
    if (event !== null) {
      this.websiteEvent.setGroupSourceDataEvent(event);
    }
  }

  viewDetail(item) {
    this.searchData.skillId = item.skillId;
    this.title = item.skillName;
    this.isDetail = true;
  }
  hideDetail() {
    this.isDetail = false;
  }

  resetFilterSkillName() {
    this.filterSkillName = null;
  }

  selectPie(event: any = null) {
    this.filterSkillName = event?.name;

    if (this.filterSkillName) {
      this.memberDisplayData = this.data.teamSkills.find(ts => ts.name === this.filterSkillName)?.members;
      return;
    }

    this.memberDisplayData = this.data.allMembers;
  }

  private formatData() {
    this.updateViewChart();
  }

  @HostListener('window:resize')
  private updateViewChart() {
    const isMobileView = CommonHelper.isMobileView();
    if (isMobileView && this.data?.chartData?.view) {
      this.data.chartData.view = [(window.innerWidth / 2), 400];
    }
  }
}
