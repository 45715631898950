import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncryptHelper {
  static secrectKey = 'YWtlcyFAIzYxNmI2NTczMjE0MDIzQCMkIyUyNDAyMzI0MjMyNTMyNDAyNDQwMjM0MEAjJCMlMjIzMjQ3MzY0NjY1M0AjJCMlMjQ0NDY1';

  static hashData(data: string) {
    const ciphertext = CryptoJS.MD5(data).toString();
    return ciphertext;
  }

  static encrypt(data: any, isOnlyValue = false, secrectKey?: string) {
    if (!data) {
      return '';
    }
    if (typeof data === 'object' && data.constructor === Object) {
      if (isOnlyValue) {
        const keys = Object.keys(data);
        keys.forEach(key => {
          const ciphertext = CryptoJS.AES.encrypt(data[key], 'kenadf3f#$@#4adsa$%45gsdg#$%#$').toString();
          data[key] = ciphertext;
        });
        return data;
      } else {
        data = JSON.stringify(data);
        const ciphertext = CryptoJS.AES.encrypt(data, secrectKey || this.secrectKey).toString();
        return ciphertext;
      }
    } else {
      const ciphertext = CryptoJS.AES.encrypt(data, secrectKey || this.secrectKey);
      return ciphertext.toString();
    }
  }

  static encryptUrl(data: any, isOnlyValue = false) {
    if (!data) {
      return '';
    }
    if (typeof data === 'object' && data.constructor === Object) {
      if (isOnlyValue) {
        const keys = Object.keys(data);
        keys.forEach(key => {
          const ciphertext = this.enUrl(data[key], 'kenadf3f#$@#4adsa$%45gsdg#$%#$');
          data[key] = ciphertext;
        });
        return data;
      } else {
        data = JSON.stringify(data);
        const eHex = this.enUrl(data);
        return eHex;
      }
    } else {
      const ciphertext = this.enUrl(data);
      return ciphertext.toString();
    }
  }

  static enUrl(data: string, key?: string) {
    const iv = CryptoJS.enc.Utf8.parse(data);
    const ciphertext = CryptoJS.AES.encrypt(data, key || this.secrectKey).toString();
    const e64 = CryptoJS.enc.Base64.parse(ciphertext);
    const eHex = e64.toString(CryptoJS.enc.Hex);
    return eHex;
  }

  static decrypt(data: string, toObject = false, secrectKey?: string) {
    if (!data) {
      return null;
    }
    const bytes = CryptoJS.AES.decrypt(data, secrectKey || this.secrectKey);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    if (toObject && plaintext) {
      return JSON.parse(plaintext);
    }
    return plaintext;
  }

  static decryptUrl(data: string, toObject = false) {
    if (!data) {
      return null;
    }
    const reb64 = CryptoJS.enc.Hex.parse(data);
    const bytes = reb64.toString(CryptoJS.enc.Base64);
    const decrypt = CryptoJS.AES.decrypt(bytes, this.secrectKey);
    const plaintext = decrypt.toString(CryptoJS.enc.Utf8);
    if (toObject && plaintext) {
      return JSON.parse(plaintext);
    }
    return plaintext;
  }
}
