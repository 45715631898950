import { Component, Input, OnInit } from '@angular/core';
import { Constants } from '@intranet/index';
import { InternalUploadFileDto, ResignForm, ResignStatus } from '@intranet/lib/data-access';
import { ResignFormService } from '@intranet/lib/data-access/services';
import { Loading, Toastr } from '@intranet/lib/settings';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-resign-form-modal',
  templateUrl: './resign-form-modal.component.html',
  styleUrls: ['./resign-form-modal.component.scss'],
})
export class ResignFormModalComponent implements OnInit {
  _currentUser: any;
  formData = new ResignForm();
  isLoading = false;
  isNew = true;
  isUpdate = false;
  resignStatus = ResignStatus;
  activeModal: NgbActiveModal;
  modelUpload: InternalUploadFileDto;

  @Input() set currentUser(value: any) {
    this._currentUser = value;
    this.formData = { ...this.currentUser };
    this.modelUpload = new InternalUploadFileDto();
    this.modelUpload.entityId = this.currentUser.id;
    this.modelUpload.entityType = Constants.ENTITY_TYPE.RESIGN_FORM;
  }

  get currentUser() {
    return this._currentUser;
  }

  constructor(modalRef: NgbActiveModal, public resignFormSvc: ResignFormService) {
    this.activeModal = modalRef;
  }

  ngOnInit() {
    this.getResignationFormByUserId();
  }

  async getResignationFormByUserId() {
    this.isUpdate = false;
    this.isNew = true;
    const form = await this.resignFormSvc.getForm();
    if (form) {
      this.formData = form;
      this.isNew = false;
      this.isUpdate = this.checkUpdatable();
    }
  }

  checkRequiredClass() {
    if (this.formData !== null && this.formData.status !== ResignStatus.PendingApproval) {
      return true;
    }
    return false;
  }

  onSubmit() {
    if (!this.validateForm()) {
      return;
    }

    this.isLoading = true;
    Loading.show();
    this.formData.userId = this.currentUser.id;
    if (this.isUpdate) {
      this.updateResignForm();
      return;
    }

    this.addResignForm();
    Loading.hide();
  }

  eventCancel() {
    this.activeModal.close();
  }

  async addResignForm() {
    try {
      await this.resignFormSvc.createResignationForm(this.formData);
      Toastr.success('Resign successfully');
      this.activeModal.close(this.formData);
    } catch (res) {
      const message = 'Cannot resign, please try again';
      if (res?.error?.message) {
        Toastr.error(res?.error?.message);
        return;
      }
      Toastr.error(message);
    }
  }

  async updateResignForm() {
    try {
      await this.resignFormSvc.updateResignationForm(this.formData);
      Toastr.success('Update resignation form successfully');
      this.activeModal.close(this.formData);
    } catch (res) {
      const message = 'Cannot update resignation form';
      if (res?.error?.message) {
        Toastr.error(res?.error?.message);
        return;
      }
      Toastr.error(message);
    }
  }

  async deleteResignForm() {
    if (!this.validateForm()) {
      return;
    }
    if (!this.formData?.resignFormId) {
      Toastr.error('Resignation form not exist');
      return;
    }
    try {
      await this.resignFormSvc.deleteResignationForm(this.formData?.resignFormId);
      Toastr.success('Your resignation form has deleted');
      this.activeModal.close(this.formData);
    } catch (res) {
      const message = res?.error?.message;
      if (message) {
        Toastr.error(message);
      }
    }
  }

  checkUpdatable() {
    if (this.formData && this.formData.status === ResignStatus.PendingApproval) {
      return true;
    }

    return false;
  }

  validateForm() {
    if (!this.formData.expectedDate) {
      Toastr.error('Missing expected date');
      return false;
    }
    if (!this.formData.reason) {
      Toastr.error('Missing reason');
      return false;
    }
    return true;
  }

  onDateChange(event: Date) {
    const d = moment(event);
    const dateString = d.format('YYYY-MM-DD').toString();
    const date = new Date(dateString);
    this.formData.expectedDate = date;
  }
}
