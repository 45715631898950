import { Component, Input, OnInit } from '@angular/core';
import { Department, ReviewFormService } from '@intranet/lib/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import { Toastr } from '@intranet/lib/settings';
import { ReviewConstants } from '@intranet/lib/config/review.constants';
import { CommonHelper } from '@intranet/lib/helpers';
import { ReviewFormAppraiserStatusEnum, ReviewFormAppraiserStatusFormEnum } from '../../data-access/models/enums/review-form.enum';
import { ReviewFormAppraiser } from '@intranet/lib/data-access/models/review/review-form-appraiser.model';
import { ReviewFormMember } from '@intranet/lib/data-access/models/review/review-form-member.model';

@Component({
  selector: 'app-review-performance-add-appraiser-modal',
  templateUrl: './review-performance-add-appraiser-modal.component.html',
  styleUrls: ['./review-performance-add-appraiser-modal.component.scss']
})
export class ReviewPerformanceAddAppraiserModalComponent implements OnInit {
  @Input() member: ReviewFormMember;
  @Input() appraiserUsers: any[];
  @Input() restrictedMode: boolean; // "restrictedMode" mean this component is in user page (true) or in admin page (false)

  activeModal: NgbActiveModal;
  appraisers: ReviewFormAppraiser[] = [];
  filteredAppraisers: ReviewFormAppraiser[] = [];
  selectedDepartment: string;
  keyword: string;
  isVerifiedAppraiserRoleChanged = false;

  displayTable = {
    pageSize: 14,
    pageNumber: 1,
    pageSizeOptions: new Array(3).fill(10).map((value, index) => value + index * 2),
    parent: this,
    tooltip: ReviewConstants.Tooltip.AddAppraiserModal,
    reviewFormAppraiserStatusEnum: ReviewFormAppraiserStatusEnum,
    reviewFormAppraiserStatusFormEnum: ReviewFormAppraiserStatusFormEnum,
    departmentList: [],

    get unfreezedAppraisers() {
      return this.parent.filteredAppraisers.filter(ap => !ap.isFreezed);
    },

    get checked() {
      return this.unfreezedAppraisers.length > 0
        && this.unfreezedAppraisers.every(ap => ap.isChecked);
    },

    get indeterminate() {
      return this.unfreezedAppraisers.some(ap => ap.isChecked)
        && this.unfreezedAppraisers.some(ap => !ap.isChecked);
    },

    get totalSelectedAppraisers() {
      // Ignore already selected appraisers in restricted mode.
      return this.parent.appraisers?.filter(ap => ap.isChecked && (!this.parent.restrictedMode || !ap.isFreezed)).length || 0;
    },

    get totalSeletableAppraisers() {
      return this.parent.appraisers?.filter(ap => !this.parent.restrictedMode || !ap.isFreezed).length || 0;
    }
  };

  constructor(modalRef: NgbActiveModal,
    private reviewFormService: ReviewFormService) {
    this.activeModal = modalRef;
  }

  async ngOnInit() {
    try {
      if (!this.member) {
        this.close();
        return;
      }

      if (this.member.reviewPerformanceFormAppraisers?.length > 0 && this.restrictedMode) {
        this.member.reviewPerformanceFormAppraisers.forEach(ap => ap.isFreezed = true);
      }

      if ((this.appraiserUsers?.length || 0) === 0) {
        if (this.restrictedMode) {
          this.appraiserUsers = await this.reviewFormService.getAppraisers(this.member.id);
        }
        else if (!this.restrictedMode) {
          this.appraiserUsers = await this.reviewFormService.getAppraisers();
        }
      }

      this.removeCurrentUserInAppraiserList();
      this.updateAppraisersFromMemberInfo();
      this.updateDepartmentListFromAppraisers();
      this.search();
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
      this.close();
    }
  }

  async updateAppraisers() {
    if (!this.validateChoosenAppraisers()) {
      Toastr.error('A form member needs at least one main appraiser and one rating appraiser');
      return;
    }

    const newMember = this.createMemberFromSelectedAppraisers();

    if (!newMember) {
      return;
    }

    this.close(newMember);
  }

  close(result: any = null) {
    this.activeModal.close(result);
  }

  selectAppraiser(appraiser: ReviewFormAppraiser, isChecked: boolean) {
    appraiser.isChecked = isChecked;

    if (this.restrictedMode) {
      appraiser.isViewOnly = true;
    }
  }

  selectAllAppraiser(isChecked: boolean) {
    this.filteredAppraisers.forEach(ap => {
      if (this.restrictedMode && ap.isFreezed) {
        return;
      }

      this.selectAppraiser(ap, isChecked);
    });
  }

  setHasRatingPermission(appraiser: ReviewFormAppraiser, hasRatingPermission: boolean) {
    appraiser.isViewOnly = !hasRatingPermission;

    if (!this.isVerifiedAppraiserRoleChanged) {
      this.isVerifiedAppraiserRoleChanged = appraiser.statusForm === ReviewFormAppraiserStatusFormEnum.Verified;
    }
  }

  setMainAppraiser(appraiser: ReviewFormAppraiser) {
    this.appraisers.forEach(ap => ap.isMainAppraiser = false);

    appraiser.isMainAppraiser = true;
  }

  search() {
    const stringFmt = (s: string) => s?.trim().toLowerCase().removeVietnamese();
    const keyword = stringFmt(this.keyword);
    this.filteredAppraisers = this.appraisers
      .filter(ap => !this.selectedDepartment
        || ap.user?.userDepartments?.some(ud => ud.department.id === this.selectedDepartment))
      .filter(ap => !this.keyword || (
        stringFmt(ap.user?.fullName).includes(keyword)
        || stringFmt(ap.user?.userName).includes(keyword)
      ));

    this.displayTable.pageNumber = 1;
  }

  private updateAppraisersFromMemberInfo() {
    if (!(this.member && this.appraiserUsers?.length > 0)) {
      return;
    }

    if (!this.member.reviewPerformanceFormAppraisers) {
      this.member.reviewPerformanceFormAppraisers = [];
    }

    this.appraisers = this.appraiserUsers.map(apu => this.createAppraiser(apu, false, false));

    for (const appraiser of this.appraisers) {
      const currentap = this.member.reviewPerformanceFormAppraisers.find(ap => ap.userId === appraiser.userId);
      if (!currentap) {
        appraiser.status = this.displayTable.reviewFormAppraiserStatusEnum.New;
        appraiser.statusForm = this.displayTable.reviewFormAppraiserStatusFormEnum.New;
        appraiser.isChecked = false;
        appraiser.isMainAppraiser = false;
        appraiser.isViewOnly = false;
        continue;
      }

      appraiser.isChecked = true;
      appraiser.isMainAppraiser = currentap.isMainAppraiser;
      appraiser.isViewOnly = currentap.isViewOnly;
      appraiser.isFreezed = currentap.isFreezed;
      appraiser.status = currentap.status;
      appraiser.statusForm = currentap.statusForm;
    }

    this.appraisers = CommonHelper.sort(this.appraisers, ap => ap.user.fullName.toLowerCase().removeVietnamese());
    this.filteredAppraisers = this.appraisers;
  }

  private createMemberFromSelectedAppraisers(): ReviewFormMember {
    if (!(this.member && this.appraisers?.length > 0)) {
      return null;
    }

    if (!this.member.reviewPerformanceFormAppraisers) {
      this.member.reviewPerformanceFormAppraisers = [];
    }

    if (this.restrictedMode && this.member.reviewPerformanceFormAppraisers.length > 0) {
      const currentSelectedAppraisers = this.appraisers.filter(ap => ap.isChecked);
      const deletedAppraiser = this.member.reviewPerformanceFormAppraisers.find(map => currentSelectedAppraisers.every(ap => ap.userId !== map.userId));
      if (deletedAppraiser) {
        Toastr.error(`Deleting appraiser is not allowed.`);
        return null;
      }
    }

    const newMember = {
      ...this.member,
      reviewPerformanceFormAppraisers: this.appraisers.filter(ap => ap.isChecked)
    };

    return newMember;
  }

  private validateChoosenAppraisers(): boolean {
    const isAtLeastOneMainAp = this.appraisers.some(ap => ap.isChecked && ap.isMainAppraiser);
    const isAtLeastOneRatingAp = this.appraisers.some(ap => ap.isChecked && !ap.isViewOnly);

    if (isAtLeastOneMainAp && isAtLeastOneRatingAp) {
      return true;
    }

    return false;
  }

  private createAppraiser(appraiserUser: any, isMainAppraiser: boolean, isViewOnly: boolean = false): ReviewFormAppraiser {
    if (!this.member || !(appraiserUser?.userDepartments?.length > 0)) {
      return null;
    }

    const idTitleList = this.member.user?.userDepartments?.map(ud => ({
      id: ud.department.id,
      name: ud.department.name,
    }));

    // Find the first common department between member and appraiser.
    // If none, take the first department from appraiser.
    let department = appraiserUser.userDepartments.find(ud => idTitleList.some(it => it.id === ud.department.id))?.department;
    if (!department) {
      department = appraiserUser.userDepartments;
    }

    if (!department?.id || !department?.name) {
      department = appraiserUser.userDepartments[0].department;
    }

    return new ReviewFormAppraiser(appraiserUser.id,
      this.member.reviewPerformanceFormId,
      department.id, department.name,
      appraiserUser, isViewOnly,
      isMainAppraiser);
  }

  private updateDepartmentListFromAppraisers() {
    if (!this.appraisers) {
      return;
    }

    const departmentList: Department[] = [];
    const userDepartmentsIter = [].concat.apply([],
      this.appraisers.map(ap => ap.user.userDepartments.map(aud => aud.department)));

    for (const dept of userDepartmentsIter) {
      const deptExists = departmentList.some(u => u.id === dept.id);

      if (!deptExists) {
        departmentList.push({ id: dept.id, name: dept.name } as Department);
      }
    }

    CommonHelper.sort(departmentList, dept => dept.name.toLowerCase().removeVietnamese());

    this.displayTable.departmentList = [...departmentList];

    const memberDept = this.member.user?.userDepartments?.map(ud => ud.department);
    if (this.restrictedMode) {
      this.selectedDepartment =
        this.displayTable.departmentList.find(dept => memberDept.some(mdept => mdept.id === dept.id))?.id;
    }
  }

  private removeCurrentUserInAppraiserList() {
    this.appraiserUsers = this.appraiserUsers.filter(ap => ap.id !== this.member?.userId);
  }
}
