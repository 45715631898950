import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Constants } from '@intranet/lib/config';
import { InternalUploadFileDto, PolicyDetail, PolicyMedia } from '@intranet/lib/data-access';
import { PolicyDetailService } from '@intranet/lib/data-access/services/policy-detail.service';
import { Loading, Toastr } from '@intranet/lib/settings';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
@Component({
  selector: 'app-policy-detail-modal',
  templateUrl: './policy-detail-modal.component.html',
  styleUrls: ['./policy-detail-modal.component.scss']
})
export class PolicyDetailModalComponent implements OnInit {
  @Input() policyId: string;
  @ViewChild('frm', { static: false }) public formGroup: NgForm;

  activeModal: NgbActiveModal;
  data: PolicyDetail = new PolicyDetail();
  isLoading = false;
  editorConfig: any;
  modelUpload: InternalUploadFileDto;
  _policyDetailId: string;
  uuid: string;

  get policyDetailId(): string {
    return this._policyDetailId;
  }

  @Input() set policyDetailId(value: string) {
    this._policyDetailId = value;
    this.modelUpload = new InternalUploadFileDto();
    this.modelUpload.entityType = Constants.ENTITY_TYPE.POLICY_DETAIL;
    if (this._policyDetailId) {
      this.modelUpload.entityId = value;
      this.getPolicyDetailById();
    } else {
      this.uuid = uuidv4();
      this.modelUpload.entityId = this.uuid;
    }
  }


  constructor(modalRef: NgbActiveModal, private policyDetailSvc: PolicyDetailService) {
    this.activeModal = modalRef;
  }

  ngOnInit() {
    this.editorConfig = Constants.TINY_CONFIG;
    this.editorConfig.height = 'calc(100vh - 250px)';
    this.editorConfig.images_upload_handler = async (blobInfo, success, failure, progress) => {
      this.policyDetailSvc.uploadBlob(blobInfo.blob(), blobInfo.filename())
        .then((res: PolicyMedia[]) => {
          if (res && res.length > 0) {
            success(this.policyDetailSvc.getImageUrl(res[0].path));
          }
        }).catch(error => {
          failure(error);
        });
    };
  }

  onSubmit() {
    if (this.policyDetailId) {
      this.updatePolicyDetail();
    } else {
      this.addNewPolicyDetail();
    }
  }

  //#region private methods
  private getPolicyDetailById() {
    this.isLoading = true;
    Loading.show();
    this.policyDetailSvc.getById(
      this.policyDetailId,
      (policyDetail: PolicyDetail) => {
        if (policyDetail) {
          this.data = policyDetail;
        } else {
          Toastr.error('Cannot find policyDetail data.');
        }
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }

  private addNewPolicyDetail() {
    if (this.formGroup.invalid) {
      return;
    }
    this.isLoading = true;
    Loading.show();
    this.data.policyId = this.policyId;
    this.data.policyDetailId = this.uuid;
    this.policyDetailSvc.add(
      this.data,
      () => {
        this.activeModal.close(this.data);
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }

  private updatePolicyDetail() {
    if (this.formGroup.invalid) {
      return;
    }
    this.isLoading = true;
    this.data.policyId = this.policyId;
    Loading.show();
    this.policyDetailSvc.update(
      this.data,
      () => {
        this.activeModal.close(this.data);
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }

  //#endregion private methods
}
