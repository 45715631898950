import { Injectable } from '@angular/core';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})

export class CompensationHourUserService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getHistoriesCompensationHour(userId: string, compensationHourUser: any) {
    const url = `${this.getUrlByUserId(userId)}/${compensationHourUser?.id}/histories`;
    return this.httpClient.get(url);
  }

  getCompensationHourByUserId(userId: string) {
    const url = this.getUrlByUserId(userId);
    return this.httpClient.get(url);
  }

  registerCompensationHour(userId: string, compensationHourUser: any) {
    const url = `${this.hostUrl}/user/${userId}/compensation-hour-users`;
    return this.httpClient.post(url, compensationHourUser);
  }

  getCompensationHourRequestByUserId(userId: string) {
    const url = this.getUrlAdminPageByUserId(userId);
    return this.httpClient.get(url);
  }

  updateStatusCompensationHourRequest(userId: string, request: any) {
    const url = `${this.getUrlAdminPageByUserId(userId)}/${request.compensationHourUserId}`;
    return this.httpClient.put(url, request);
  }

  private getUrlByUserId(userId: string) {
    return `${this.hostUrl}/user/${userId}/compensation-hours`;
  }

  private getUrlAdminPageByUserId(userId: string) {
    return `${this.hostUrl}/user/${userId}/compensation-hour-users`;
  }
}
