<div class="announcement-detail-component">
  <div class="rt-panel-item placeholder" *ngIf="isLoading">
    <div class="row">
      <nz-skeleton [nzActive]="true"></nz-skeleton>
    </div>
  </div>
  <div class="rt-panel-item" *ngIf="!isLoading && feed">
    <div class="rt-panel-item-content">
      <div class="row">
        <div class="col-12 col-lg-9 announcement-detail">
          <div class="announcement-title">
            <span
              class="vector-right-icon btn-link"
              [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.ANNOUNCEMENTS)]"
              ><img src="assets/images/front/shortcut/vector-right.svg"
            /></span>
            <h3 class="announcement-title-text">{{ feed.title }}</h3>
          </div>

          <div class="time-post separated-dot">
            <div>
              <img src="assets/images/front/shortcut/post-icon.svg" /><span
                >Posted: {{ feed.createdDate | amFromUtc | amDateFormat : 'DD MMM YYYY' }}</span
              >
            </div>
            <div *ngIf="feed.endDate">
              <img src="assets/images/front/shortcut/post-icon.svg" /><span
                >Due day: {{ feed.endDate | amDateFormat : 'DD MMM YYYY' }}</span
              >
            </div>
          </div>
          <div class="description-newsfeed-detail">
            <p [innerHTML]="feed.description | shortcodeToHtml | safeHtml"></p>
          </div>
          <div class="row">
            <div class="col-12 attachments" *ngIf="feed.files && feed.files.length > 0">
              <div class="document-container">
                <h5>Attachment ({{ feed.files.length }})</h5>
                <div class="d-flex flex-wrap">
                  <div class="document-item" (click)="click(document)" *ngFor="let document of feed.files">
                    <div class="document-icon">
                      <div *ngIf="document.fileType === FileExtensionType.IMAGE">
                        <img src="{{ document.url }}" />
                      </div>
                      <div *ngIf="document.fileType === FileExtensionType.EXCEL">
                        <img src="/assets/images/front/excel_placeholder.PNG" />
                      </div>
                      <div *ngIf="document.fileType === FileExtensionType.POWERPOINT">
                        <img src="/assets/images/front/ppt_placeholder.PNG" />
                      </div>
                      <div
                        *ngIf="
                          document.fileType === FileExtensionType.WORD ||
                          document.fileType === FileExtensionType.PDF ||
                          document.fileType === FileExtensionType.UNKNOWN
                        "
                      >
                        <img src="/assets/images/front/doc_placeholder.PNG" />
                      </div>
                      <div class="download-icon">
                        <i nz-icon nzType="download" nzTheme="outline"></i>
                      </div>
                    </div>
                    <div class="document-name text-left">
                      <app-file-icon [documentType]="document.fileType"></app-file-icon>
                      <span class="ml5">{{ document.fileName }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 news-feed-lastest">
          <div class="news-feed-lastest-title">Latest News Feed</div>
          <app-newsfeed-announcements view="details"></app-newsfeed-announcements>
        </div>
      </div>
    </div>
  </div>
</div>
