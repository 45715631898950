<div class="review-member-mobile">
  <fieldset *ngFor="let formMember of reviewForms" class="member-item">
    <legend class="member-item-legend">
      <div class="member-form-name">{{formMember?.reviewPerformanceFormName ?? formMember?.formTitle}}</div>
      <div class="filler-line"></div>
      <div class="member-form-actions">
        <ng-container [ngTemplateOutlet]="actionButtonsTpl" [ngTemplateOutletContext]="{ $implicit: formMember }"></ng-container>
      </div>
    </legend>

    <div class="member-info-column">
      <div class="member-info">
        <app-avatar [avatarSource]="formMember?.avatarUrl ?? formMember?.appraiseeAvatarUrl" [size]="avatarSize"
          [avatarText]="formMember?.fullName ?? formMember?.appraiseeName"></app-avatar>
        <div class="personal-info">
          <p class="fullname">{{formMember?.fullName ?? formMember?.appraiseeName}}</p>
          <p *ngIf="formMember?.position" class="position-title">{{formMember?.position}}</p>
          <div class="department">
            <nz-tag *ngIf="formMember?.department" nzColor="geekblue">{{formMember?.department}}</nz-tag>
          </div>
        </div>
        <div class="member-status">
          <div [ngClass]="formMemberStatusFormDisplay[formMember?.statusForm]?.color" class="status-dot"></div>
          <div class="status-text">{{formMemberStatusFormDisplay[formMember?.statusForm]?.name}}</div>
        </div>
      </div>
    </div>

    <div class="member-info-column">
      <div class="appraiser-title">
        <p>Appraiser{{formMember?.appraisers?.length > 1 ? 's' : ''}}</p>
      </div>

      <div *ngFor="let appraiser of formMember?.appraisers" class="member-info appraiser-info">
        <app-avatar [avatarSource]="appraiser?.avatarUrl" [size]="avatarSize" [avatarText]="appraiser?.name"></app-avatar>
        <div class="personal-info">
          <p class="fullname">{{appraiser?.fullName ?? appraiser?.name}}</p>
          <p *ngIf="appraiser?.position" class="position-title">{{appraiser?.position}}</p>
          <div class="department">
            <nz-tag *ngIf="appraiser?.department" nzColor="geekblue">{{appraiser?.department}}</nz-tag>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</div>
