export class InternalUploadFileDto {
  entityId: string;
  entityType = '';
  isReadonly = false;
  title = 'Files';
}
export class InternalUploadFileRequestModel {
  id?: string;
  entityId = '';
  entityType = '';
  file: any;
}
export class InternalUploadFileResponseModel {
  fileId: string;
  fileName: string;
  fileLocation: string;
  fileExt: string;
  fileType: number;
  entityId: string;
  entityType: string;
  isPerrmissonDelete: boolean;
  url?: string;

  thumbUrl?: string;
  mimeType?: string;
}
