import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Constants, MenuHelper } from '@intranet/lib/config';
import { Router } from '@angular/router';
import { MenuTypeEnum, MenuEventAction, MenuDto, MenuEventTypeEnum, MenuOptions } from '@intranet/lib/data-access';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import * as _ from 'lodash';
import { ModalService, ModalSize, WebsiteEvent } from '@intranet/lib/settings';
import { CreateBookingComponent } from 'projects/intranet/hrm-user/pages/user-booking/create-booking/create-booking.component';
import { CreateLeaveComponent } from 'projects/intranet/hrm-user/components';
import * as moment from 'moment';
import { CommonHelper, PubSubEvent } from '@intranet/index';
import { Subscription } from 'rxjs';
import { ApplicationService } from '@intranet/lib/data-access/services/application.service';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import { BaseUserComponent } from '@intranet/lib/components';
import { EVENT_CATEGORY, EVENT_NAME } from '@intranet/lib/config/google-analytics.constants';
import { AngularFireAnalytics } from '@angular/fire/analytics';
@Component({
  selector: 'app-sidebar-left',
  templateUrl: './sidebar-left.component.html',
  styleUrls: ['./sidebar-left.component.scss'],
})
export class SidebarLeftComponent extends BaseUserComponent implements OnInit, OnDestroy {
  @Input() menuType = MenuTypeEnum.UserSideMenu;
  @Output() sidebarLeftChanges = new EventEmitter();

  isExpanedFront = localStorage.getItem('isExpanedFront');
  linkActive = '';
  get viewDate(): Date {
    return new Date(new Date().toDateString());
  }

  menuEventTable: MenuEventAction[] = [
    new MenuEventAction('addLeave', () => this.addLeave()),
    new MenuEventAction('addMeeting', () => this.addMeeting()),
  ];
  menuOptions: MenuOptions = {
    processMenuFn: this.initListMenuMeta.bind(this)
  };

  MenuTypeEnum = MenuTypeEnum;
  subscriptionList: Subscription[] = [];
  backendVersion = null;

  get isCollapsed() {
    return this.isExpanedFront !== 'true';
  }
  get isMobileView() {
    return window.innerWidth <= Constants.SmallViewMaxWidthInPixel;
  }

  constructor(private router: Router,
    private pubsub: PubSubService,
    private websiteEvent: WebsiteEvent,
    private modalSvc: ModalService,
    private applicationService: ApplicationService,
    private analytics: AngularFireAnalytics) {
    super();
  }

  initListMenuMeta(menu: MenuDto[], _router: Router): MenuDto[] {
    // Init menu meta for compatibility with Angular router and linkActive.
    return menu.map(this.initMenuMeta.bind(this));
  }

  initMenuMeta(menu: MenuDto): MenuDto {
    // initMenuMeta functionality:
    // Add a key name 'url' in 'meta' which hold an URL which is built from menu.url and menu.meta.params
    // By comparing menu.meta.url === linkActive we can highlight selected item in the menu.

    // Handle cases:
    //    1. Menu has URL and meta.params.
    //    2. Menu has only URL (meta is/is not null).
    //    3. Menu has no URL (meta is/is not null).
    if (menu) {
      if (menu.url) {
        if (menu.meta) {
          if (menu.meta.params) {
            // menu.meta.params hold parameters for URL like: { 'tab': 2, ... }
            // 'for ... in' to get a list of params like ['tab=2', ...]
            const params = [];
            // tslint:disable-next-line: forin
            for (const key in menu.meta.params) {
              params.push(`${key}=${menu.meta.params[key]}`);
            }

            // join them to get final URL.
            // Like: /user/calendar?tab=2
            menu.meta.url = `${menu.url}?${params.join('&')}`;
          }
          else if (!menu.meta.params) {
            // Menu has URL but no meta.param
            menu.meta.url = menu.url;
          }
        }
        else if (!menu.meta) {
          // Menu has URL but no meta
          menu.meta = { url: menu.url };
        }
      }
      else if (!menu.url) {
        // Menu has no URL and has/doesn't have meta.
        if (menu.meta) {
          menu.meta.url = null;
        }
        else if (!menu.meta) {
          menu.meta = { url: null };
        }
      }

      // If menu has children
      if (menu.children && menu.children.length > 0) {
        menu.children = menu.children.map(this.initMenuMeta.bind(this));
      }
    }
    return menu;
  }

  ngOnInit() {
    // Limitation:
    //  window.location.search many contain a lot of query params in the future like: /user/calendar?tab=2&function=abc
    //  so menu has URL like: '/user/calendar' and query params { 'tab': 2 } => URL: '/user/calendar?tab=2' != '/user/calendar?tab=2&function=abc'
    this.linkActive = window.location.pathname + window.location.search;
    this.smallViewInit();
    this.getBackendVersion();
  }

  private async getBackendVersion() {
    try {
      const res = await this.applicationService.getInformation();
      this.backendVersion = res?.version;
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
  }

  ngOnDestroy() {
    if (this.subscriptionList) {
      this.subscriptionList.forEach(s => s.unsubscribe());
    }
  }

  onClickEvent(menu: MenuDto) {
    switch (menu?.title) {
      case MenuHelper.MENU_TITLE_LIST.viewCalendar:
        this.analytics.logEvent(EVENT_NAME.ViewCalendar, { event_category: EVENT_CATEGORY.Menu });
        break;
      case MenuHelper.MENU_TITLE_LIST.viewLeaves:
        this.analytics.logEvent(EVENT_NAME.ViewLeave, { event_category: EVENT_CATEGORY.Menu });
        break;
      case MenuHelper.MENU_TITLE_LIST.addLeave:
        this.analytics.logEvent(EVENT_NAME.AddLeave, { event_category: EVENT_CATEGORY.Menu });
        break;
      case MenuHelper.MENU_TITLE_LIST.addMeeting:
        this.analytics.logEvent(EVENT_NAME.BookMeeting, { event_category: EVENT_CATEGORY.Menu });
        break;
      default:
        break;
    }
    if (CommonHelper.isMobileView()) {
      this.pubsub.$pub(PubSubEvent.EVENTS.TOGGLE_LEFT_PANEL, false);
    }

    if (menu && menu.url) {
      // Priority URL over click event.
      this.linkActive = menu.meta.url;
      if (menu.meta?.params) {
        this.router.navigate([menu.url], { queryParams: menu.meta.params });
        return;
      }

      this.router.navigate([menu.url]);
    }
    else if (menu && menu.event && menu.event.onClick) {
      // Call all functions stored in menuEventTable which have the same eventType and action.
      this.menuEventTable
        .filter(met => met.action === menu.event.onClick && met.eventType === MenuEventTypeEnum.Click)
        .forEach(met => met.fn());
    }
  }

  addLeave() {
    const d = moment(this.viewDate);
    const dateString = d.format('YYYY-MM-DD').toString();
    const date = new Date(dateString);
    const modalRef = this.modalSvc.open(CreateLeaveComponent, { size: ModalSize.lg });
    modalRef.componentInstance.data = { fromDate: date, toDate: date };
  }

  addMeeting() {
    const modalRef = this.modalSvc.open(CreateBookingComponent, { size: ModalSize.lg });
    modalRef.componentInstance.event = {
      start: moment(this.viewDate).add(moment.duration(Constants.START_WORKING_TIME)).toDate(),
      end: moment(this.viewDate).add(moment.duration(Constants.END_WORKING_TIME)).toDate(),
      meta: {
        createdBy: this.currentUser?.id,
      }
    };
  }

  onChangeActive() {
    if (localStorage.getItem('isExpanedFront')) {
      this.unsetExpandedFront();
    } else {
      this.setExpandedFront();
    }
    this.websiteEvent.emitMenuLeftEvent();
  }

  private smallViewInit() {
    // const resizeEvent = fromEvent(window, 'resize').subscribe(() => this.mobileMenuDisplayHandler());
    const sub = this.pubsub.$sub(PubSubEvent.EVENTS.TOGGLE_LEFT_PANEL).subscribe(showSideMenu => {
      if (showSideMenu && this.isCollapsed && this.isMobileView) {
        this.setExpandedFront();
      }
      else if (!showSideMenu && !this.isCollapsed && this.isMobileView) {
        this.unsetExpandedFront();
      }
    });

    this.subscriptionList.push(sub);
  }

  private setExpandedFront() {
    localStorage.setItem('isExpanedFront', 'true');
    this.isExpanedFront = 'true';
    this.sidebarLeftChanges.emit(true);
    // this.pubsub.$pub(PubSubEvent.EVENTS.TOGGLE_LEFT_MENU, true);
  }

  private unsetExpandedFront() {
    localStorage.removeItem('isExpanedFront');
    this.isExpanedFront = null;
    this.sidebarLeftChanges.emit(null);
    // this.pubsub.$pub(PubSubEvent.EVENTS.TOGGLE_LEFT_MENU, false);
  }
}
