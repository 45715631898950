import tinymce from 'tinymce';
import { StatusModel } from '../data-access';
import { AnnualLeaveRequestStatusEnum, AnnualLeaveRequestTypeEnum } from '../data-access/models/enums/annual-leave-request.enum';
import { DayOfWeek } from '../data-access/models/enums/day-of-week.enum';
import { GenderConstEnum, GenderEnum } from '../data-access/models/enums/gender.enum';
import { GroupMemberSortEnum, GroupMemberSortOrderingEnum } from '../data-access/models/enums/group-member-sort.enum';
import { LeaveRequestExceptionStatus } from '../data-access/models/enums/leave-request.enum';
import { MonthlyMissingHoursStatusEnum } from '../data-access/models/enums/monthly-missing-hours.enum';
import { PurchaseDetailOption } from '../data-access/models/enums/purchase-option.enum';
import { RedemptionRequestStatus } from '../data-access/models/enums/redemption-request-status.enum';
import { ReviewFormSelectMemberDisplayStateEnum } from '../data-access/models/enums/review-form.enum';
import { ReviewTemplateTypeEnum } from '../data-access/models/enums/review-template.enum';
import { ScheduleGroupEntityType } from '../data-access/models/enums/schedule-group-entity-type.enum';
import { WidgetSearchModeEnum } from '../data-access/models/enums/widget.enum';
import { AppConfig } from '../environments/config/appConfig';
import { UrlConstant } from './urlConstant';

export class Constants {
  static readonly SYNC_TIME_KEEPER_ALERT = 'intranet.pro.sync.timekeeper';
  static readonly DATE_FMT = 'dd/MM/yyyy hh:mm:ss';
  static readonly DATE__SERVIER_FMT = 'yyyy/MM/dd hh:mm:ss';
  static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} hh:mm:ss`;
  static previousUrl: any;
  static countRequest = 0;
  static readonly EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
  static readonly ONLINE_ROOM_GUID = '00000000-0000-0000-0000-000000000001';
  static readonly DashboardDefault = {
    User: '00000000-0000-0000-0000-000000000001',
    Group: '00000000-0000-0000-0000-000000000002',
    Team: '00000000-0000-0000-0000-000000000003',
    Company: '00000000-0000-0000-0000-000000000004',
  };
  static readonly TYPE_USER = {
    admin: 'admin',
  };
  static readonly OneHundredPercentNumber = 100;
  static readonly ZeroPercentNumber = 0;
  static readonly SmallViewMaxWidthInPixel = 991;
  static readonly MobileMaxWidthInPixel = 767;
  static readonly MillisecondsPerSecond = 1000;
  static readonly MillisecondsPerMinute = 60 * Constants.MillisecondsPerSecond;
  static readonly MillisecondsPerHour = 60 * Constants.MillisecondsPerMinute;
  static readonly MillisecondsPerDay = 24 * Constants.MillisecondsPerHour;

  static readonly DayOfWeeks: StatusModel[] = [
    {
      id: DayOfWeek.Sunday,
      name: 'Sunday',
    },
    {
      id: DayOfWeek.Monday,
      name: 'Monday',
    },
    {
      id: DayOfWeek.TuesDay,
      name: 'TuesDay',
    },
    {
      id: DayOfWeek.Wednesday,
      name: 'Webnesday',
    },
    {
      id: DayOfWeek.Thursday,
      name: 'Thursday',
    },
    {
      id: DayOfWeek.Friday,
      name: 'Friday',
    },
    {
      id: DayOfWeek.Saturday,
      name: 'Saturday',
    },
  ];

  static readonly BOOKING_OCCUR_TYPE = {
    None: 0,
    Daily: 1,
    Weekly: 2,
    Monthly: 3,
  };

  static readonly PRUCHASE_REQUEST_STATUS_ID = {
    New: 1,
    WaitingVerify: 2,
    Verify: 3,
    WaitingApproval: 4,
    Approve: 5,
    Reject: 6,
    PartialPurchased: 7,
    Purchased: 8,
  };
  static readonly PRUCHASE_REQUEST_DELIVERY_STATUS_ID = {
    Waiting_Deliver: 1,
    PartialDelivered: 2,
    Delivered: 3,
  };
  static readonly PRUCHASE_REQUEST_DETAIL_STATUS_ID = {
    New: 1,
    InProcess: 2,
    FilledPrice: 3,
    Rejected: 4,
    Approval: 5,
    WaitingDeliver: 6,
    PartialPurchased: 7,
    Delivered: 8,
    PartialDelivered: 9,
    CancelDelivered: 10,
    MissPurchase: 11,
    PurchaseCancelled: 12,
    ReCreated: 13,
  };

  static readonly PURCHASE_DETAIL_DATASOURCE_OPTION: any[] = [
    { id: PurchaseDetailOption.New, title: 'New' },
    { id: PurchaseDetailOption.Replace, title: 'Replace' },
  ];

  static readonly SCHEDULE_GROUP_ENTITY_TYPE: any[] = [
    { id: ScheduleGroupEntityType.Meeting, title: 'Meeting', entityTitle: 'Room' },
  ];

  static readonly BOOKING_REPEAT_TYPES: StatusModel[] = [
    {
      id: Constants.BOOKING_OCCUR_TYPE.None,
      name: 'None',
    },
    {
      id: Constants.BOOKING_OCCUR_TYPE.Daily,
      name: 'Daily',
    },
    {
      id: Constants.BOOKING_OCCUR_TYPE.Weekly,
      name: 'Weekly',
    },
    {
      id: Constants.BOOKING_OCCUR_TYPE.Monthly,
      name: 'Monthly',
    },
  ];

  static readonly PRUCHASE_REQUEST_STATUS: StatusModel[] = [
    {
      id: Constants.PRUCHASE_REQUEST_STATUS_ID.New,
      name: 'New',
      color: 'info',
    },
    {
      id: Constants.PRUCHASE_REQUEST_STATUS_ID.WaitingVerify,
      name: 'Waiting for verification',
      color: 'warning',
    },
    {
      id: Constants.PRUCHASE_REQUEST_STATUS_ID.Verify,
      name: 'Verified',
      subTitle: 'Verified',
      color: 'info',
    },
    {
      id: Constants.PRUCHASE_REQUEST_STATUS_ID.WaitingApproval,
      name: 'Waiting for approval',
      color: 'warning',
    },
    {
      id: Constants.PRUCHASE_REQUEST_STATUS_ID.Approve,
      name: 'Approved',
      color: 'success',
    },
    {
      id: Constants.PRUCHASE_REQUEST_STATUS_ID.Reject,
      name: 'Rejected',
      color: 'danger',
    },
    {
      id: Constants.PRUCHASE_REQUEST_STATUS_ID.PartialPurchased,
      name: 'Partial purchased',
      color: 'success',
    },
    {
      id: Constants.PRUCHASE_REQUEST_STATUS_ID.Purchased,
      name: 'Purchased',
      color: 'success',
    },
  ];
  static readonly PRUCHASE_REQUEST_DELIVERY_STATUS: StatusModel[] = [
    {
      id: Constants.PRUCHASE_REQUEST_DELIVERY_STATUS_ID.Waiting_Deliver,
      color: 'warning',
      name: 'Waiting for deliver',
    },
    {
      id: Constants.PRUCHASE_REQUEST_DELIVERY_STATUS_ID.Delivered,
      color: 'success',
      name: 'Delivered',
    },
    {
      id: Constants.PRUCHASE_REQUEST_DELIVERY_STATUS_ID.PartialDelivered,
      color: 'info',
      name: 'Partial delivered',
    },
  ];

  static readonly PRUCHASE_REQUEST_DETAIL_STATUS: StatusModel[] = [
    {
      id: Constants.PRUCHASE_REQUEST_DETAIL_STATUS_ID.New,
      name: 'New',
      color: 'info',
    },
    {
      id: Constants.PRUCHASE_REQUEST_DETAIL_STATUS_ID.InProcess,
      name: 'InProcess',
      color: 'warning',
    },
    {
      id: Constants.PRUCHASE_REQUEST_DETAIL_STATUS_ID.FilledPrice,
      name: 'Filled Price',
      color: 'success',
    },
    {
      id: Constants.PRUCHASE_REQUEST_DETAIL_STATUS_ID.Rejected,
      name: 'Rejected',
      color: 'info',
    },
    {
      id: Constants.PRUCHASE_REQUEST_DETAIL_STATUS_ID.Approval,
      name: 'Approved',
      color: 'success',
    },
    {
      id: Constants.PRUCHASE_REQUEST_DETAIL_STATUS_ID.WaitingDeliver,
      name: 'Waiting for deliver',
      color: 'warning',
    },
    {
      id: Constants.PRUCHASE_REQUEST_DETAIL_STATUS_ID.PartialPurchased,
      name: 'Partial purchased',
      color: 'success',
    },
    {
      id: Constants.PRUCHASE_REQUEST_DETAIL_STATUS_ID.Delivered,
      color: 'success',
      name: 'Delivered',
    },
    {
      id: Constants.PRUCHASE_REQUEST_DETAIL_STATUS_ID.PartialDelivered,
      color: 'info',
      name: 'Partial delivered',
    },
    {
      id: Constants.PRUCHASE_REQUEST_DETAIL_STATUS_ID.CancelDelivered,
      color: 'danger',
      name: 'Deliver cancelled',
    },
    {
      id: Constants.PRUCHASE_REQUEST_DETAIL_STATUS_ID.MissPurchase,
      color: 'danger',
      name: 'Missing purchase',
    },
    {
      id: Constants.PRUCHASE_REQUEST_DETAIL_STATUS_ID.PurchaseCancelled,
      color: 'danger',
      name: 'Purchase cancelled',
    },
    {
      id: Constants.PRUCHASE_REQUEST_DETAIL_STATUS_ID.ReCreated,
      color: 'info',
      name: 'Re-Created',
    },
  ];

  static readonly HALF_PART_LEAVE: StatusModel[] = [
    {
      id: 0,
      name: 'All day',
      time: 8,
    },
    // {
    //   id: 1,
    //   name: 'First half',
    //   time: 4,
    // },
    // {
    //   id: 2,
    //   name: 'Second half',
    //   time: 4,
    // },
  ];
  static readonly LEAVE_ACTIONS: StatusModel[] = [
    {
      name: 'Company Sponsors',
      id: 1,
      subTitle: 'Company Sponsors',
      color: 'warning',
    },
    {
      name: 'Taken Annual Leave',
      id: 2,
      subTitle: 'Annual Leave',
      color: 'info',
    },
    {
      name: 'Unpaid Leave',
      id: 3,
      subTitle: 'Unpaid Leave',
      color: 'danger',
    },
    {
      name: 'Paid Leave',
      id: 4,
      subTitle: 'Paid Leave',
      color: 'success',
    },
    {
      name: 'Sick Leave',
      id: 6,
      subTitle: 'Sick Leave',
      color: 'warning',
    },
  ];
  static readonly REDEMPTION_STATUS: StatusModel[] = [
    {
      id: RedemptionRequestStatus.Pending,
      color: 'info',
      name: 'Pending',
    },
    {
      id: RedemptionRequestStatus.Approved,
      color: 'success',
      name: 'Approved',
    },
    {
      id: RedemptionRequestStatus.Rejected,
      color: 'danger',
      name: 'Rejected',
    },
  ];
  static readonly LEAVE_STATUS_IDS = {
    Waiting: 1,
    Approved: 2,
    Rejected: 3,
    Restored: 4,
  };
  static readonly SUPPLIER_STATUS_IDS = {
    Waiting: 1,
    Approved: 2,
    Rejected: 3,
    Restored: 4,
  };

  static readonly STATUS_ENUMS = {
    PRUCHASE_REQUEST_STATUS: 1,
    PRUCHASE_REQUEST_DETAIL_STATUS: 2,
    LEAVE_STATUS: 3,
    LEAVE_ACTIONS: 4,
    PURCHASE_ORER_STATUS: 5,
    USER_TYPES: 6,
    EQUIPMENT_STATUS: 7,
    DELIVERY_FROM_STATUS: 8,
    PRUCHASE_REQUEST_DELIVERY_STATUS: 9,
    PUBLICITY_LEVEL: 10,
    ANNUAL_LEAVE_REQUEST_STATUS: 11,
    ANNUAL_LEAVE_REQUEST_TYPE: 12,
    MONTHLY_MISSING_HOURS_STATUS: 13,
    REDEMPTION_REQUEST_STATUS: 14,
    DELIVERY_FORM_PR_STATUS: 15,
    LEAVE_TRANSFER_EXCEPTION: 16,
  };

  static readonly LEAVE_STATUS: StatusModel[] = [
    {
      id: Constants.LEAVE_STATUS_IDS.Waiting,
      name: 'Pending',
      color: 'leave-pending',
    },
    {
      id: Constants.LEAVE_STATUS_IDS.Approved,
      name: 'Approved',
      color: 'leave-approved',
    },
    {
      id: Constants.LEAVE_STATUS_IDS.Rejected,
      name: 'Rejected',
      color: 'leave-rejected',
    },
    {
      id: Constants.LEAVE_STATUS_IDS.Restored,
      name: 'Restored',
      color: 'leave-restored',
    },
  ];
  static readonly ANNUAL_LEAVE_REQUEST_STATUS: StatusModel[] = [
    {
      id: AnnualLeaveRequestStatusEnum.Approved,
      name: 'Approved',
      color: 'success',
    },
    {
      id: AnnualLeaveRequestStatusEnum.Restored,
      name: 'Restored',
      color: 'info',
    },
  ];

  static readonly ANNUAL_LEAVE_REQUEST_TYPE: StatusModel[] = [
    {
      id: AnnualLeaveRequestTypeEnum.AnnualLeave,
      name: 'Annual leave',
      color: 'success',
    },
    {
      id: AnnualLeaveRequestTypeEnum.UnpaidLeave,
      name: 'Unpaid leave',
      color: 'danger',
    },
  ];

  static readonly MONTHLY_MISSING_HOURS_STATUS: StatusModel[] = [
    {
      id: MonthlyMissingHoursStatusEnum.InProcess,
      name: 'In-process',
      color: 'info',
    },
    {
      id: MonthlyMissingHoursStatusEnum.KeptMissingHours,
      name: 'Kept missing hours',
      color: 'success',
    },
    {
      id: MonthlyMissingHoursStatusEnum.DecreasedAnnualLeaves,
      name: 'Decreased annual leaves',
      color: 'danger',
    },
  ];

  static readonly ANNUAL_REVIEW_TEMPLATE_TYPE: StatusModel[] = [
    {
      id: ReviewTemplateTypeEnum.Annual,
      name: 'Annual',
      color: 'success',
    },
    {
      id: ReviewTemplateTypeEnum.Probation,
      name: 'Probation',
      color: 'info',
    },
    {
      id: ReviewTemplateTypeEnum.Exceptional,
      name: 'Exceptional',
      color: 'danger',
    },
  ];

  static readonly ANNUAL_REVIEW_FORM_SELECT_MEMBER_DISPLAY_STATE: StatusModel[] = [
    {
      id: ReviewFormSelectMemberDisplayStateEnum.All,
      name: 'All members',
    },
    {
      id: ReviewFormSelectMemberDisplayStateEnum.CheckedOnly,
      name: 'Checked members',
    },
    {
      id: ReviewFormSelectMemberDisplayStateEnum.UncheckedOnly,
      name: 'Unchecked members',
    },
    {
      id: ReviewFormSelectMemberDisplayStateEnum.UnfilledAppraiser,
      name: 'Unfilled appraiser',
    },
  ];

  static readonly LEAVE_ACTION_IDS = {
    None: 1,
    Reduced_Date: 2,
    Reduced_Salary: 3,
    Legal_Entitled: 4,
    System_Leave: 5,
    Sick_Leave: 6,
  };

  static readonly ENTITY_TYPE = {
    PURCHASE_REQUEST: 'PR',
    PURCHASE_ORDER: 'PO',
    LEAVE_REQUEST: 'LR',
    DELIVERY_FORM: 'DF',
    EVENT_BOOKING: 'EB',
    NewsFeed: 'NF',
    POLICY_DETAIL: 'PD',
    TIER_IMAGE: 'TI',
    GIFT_IMAGE: 'GI',
    RESIGN_FORM: 'RF',
    BENEFIT_REGISTRATION: 'BR',
  };
  static readonly FILE_EXT = {
    PDF: '.pdf',
    IMAGE: '.png,.jpg,.git',
    EXCEL: '.xlsx,.xls',
    CSV: '.csv,.cvs',
    WORK: '.docx,.doc',
    VIDEO: '.webm,.mp4',
  };
  static readonly KEY_WIDGET_OBJECT = {
    ATTENDANCE: 'Attendance',
    ATTENDANCEHOUR: 'AttendanceHour',
    TRACKINGTIMEWEEK: 'TrackingTimeWeek',
    EQUIPMENTPERSONAL: 'EquipmentPersonal',
    TODAYLEAVE: 'TodayLeave',
    COMPANY: 'Company',
    EQUIPMENT: 'Equipment',
    HRINFORMATION: 'HRInformation',
    WORKEXPERIENCE: 'WorkExperience',
    EMPLOYEEGENDER: 'EmployeeGender',
    SURVEY: 'Survey',
    TEAM: 'Team',
    TODO: 'Todo',
    LEAVE: 'Leave',
    NEWCOMER: 'Newcomer',
    CALENDAR: 'Calendar',
    BENEFIT: 'Benefit',
    REVIEW: 'Review',
    GOAL: 'Goal',
  };
  static readonly USER_TYPE_IDS = {
    Internal: 1,
    Client: 2,
  };
  static readonly USER_TYPES: StatusModel[] = [
    {
      id: Constants.USER_TYPE_IDS.Internal,
      name: 'Internal',
      color: 'warning',
    },
    {
      id: Constants.USER_TYPE_IDS.Client,
      name: 'External',
      color: 'success',
    },
  ];

  static readonly MODULES: StatusModel[] = [
    {
      id: 1,
      name: 'Admin Dashboard',
    },
    {
      id: 2,
      name: 'Personal Dashboard',
    },
    {
      id: 3,
      name: 'NewFeeds',
    },
  ];

  static readonly MODULE_IDS = {
    Admin_Dashboard: 1,
    Personal_Dashboard: 2,
    NewFeeds: 3,
  };

  static readonly PURCHASE_ORER_STATUS_IDS = {
    WaitingToBuy: 1,
    Warehoused: 2,
    Cancel: 3,
    Bought: 4,
    PartialPurchased: 5,
  };
  static readonly PURCHASE_ORER_STATUS: StatusModel[] = [
    {
      id: Constants.PURCHASE_ORER_STATUS_IDS.WaitingToBuy,
      name: 'Waiting to Buy',
      color: 'warning',
    },
    {
      id: Constants.PURCHASE_ORER_STATUS_IDS.Warehoused,
      name: 'Warehoused',
      color: 'info',
    },
    {
      id: Constants.PURCHASE_ORER_STATUS_IDS.Cancel,
      name: 'Cancel',
      color: 'danger',
    },
    // {
    //   id: Constants.PURCHASE_ORER_STATUS_IDS.Bought,
    //   name: 'Bought',
    //   color: 'success',
    // },
    {
      id: Constants.PURCHASE_ORER_STATUS_IDS.PartialPurchased,
      name: 'Partial purchased',
      color: 'success',
    },
  ];

  static readonly MARITAL_STATUS: StatusModel[] = [
    {
      id: 1,
      name: 'Married',
    },
    {
      id: 2,
      name: 'Single',
    },
    {
      id: 3,
      name: 'In Love',
    },
    {
      id: 4,
      name: 'Others',
    },
  ];
  static readonly DELIVERY_FROM_STATUS_IDS = {
    New: 1,
    Delivered: 2,
    Cancel: 3,
  };

  static readonly DELIVERY_FROM_STATUS: StatusModel[] = [
    {
      id: Constants.DELIVERY_FROM_STATUS_IDS.New,
      name: 'Waiting for deliver',
      color: 'info',
    },
    {
      id: Constants.DELIVERY_FROM_STATUS_IDS.Delivered,
      color: 'success',
      name: 'Delivered',
    },
    {
      id: Constants.DELIVERY_FROM_STATUS_IDS.Cancel,
      color: 'danger',
      name: 'Cancel',
    },
  ];

  static readonly DELIVERY_FROM_PR_STATUS_IDS = {
    None: 0,
    WaitingDelivery: 1,
    PartiallyDelivered: 2,
    Delivered: 3,
  };

  static readonly DELIVERY_FORM_PR_STATUS: StatusModel[] = [
    {
      id: Constants.DELIVERY_FROM_PR_STATUS_IDS.None,
      name: 'None',
      color: 'danger',
    },
    {
      id: Constants.DELIVERY_FROM_PR_STATUS_IDS.WaitingDelivery,
      name: 'Waiting for delivery',
      color: 'warning',
    },
    {
      id: Constants.DELIVERY_FROM_PR_STATUS_IDS.PartiallyDelivered,
      name: 'Partially delivered',
      color: 'info',
    },
    {
      id: Constants.DELIVERY_FROM_PR_STATUS_IDS.Delivered,
      name: 'Delivered',
      color: 'success',
    },
  ];

  static readonly EQUIPMENT_STATUS: StatusModel[] = [
    {
      id: 1,
      name: 'Pending',
      color: 'warning',
    },
    {
      id: 2,
      color: 'success',
      name: 'Received',
    },
    // {
    //   id: 3,
    //   color: 'info',
    //   name: 'Returned',
    // },
    {
      id: 3,
      color: 'danger',
      name: 'Cancel',
    },
  ];

  static readonly PRIVELAGES: StatusModel[] = [
    {
      id: 0,
      name: 'User’s Privilege',
    },
    {
      id: 1,
      name: 'Commone User',
    },
    {
      id: 2,
      name: 'Enroller ',
    },
    {
      id: 3,
      name: 'Administrator',
    },
  ];

  static readonly LEAVE_TRANSFER_EXCEPTION: StatusModel[] = [
    {
      id: LeaveRequestExceptionStatus.New,
      name: 'New',
      color: 'info',
    },
    {
      id: LeaveRequestExceptionStatus.Approve,
      name: 'Approved',
      color: 'success',
    },
    {
      id: LeaveRequestExceptionStatus.Reject,
      name: 'Rejected',
      color: 'danger',
    },
  ];

  static readonly REQUEST_TYPE_ENUM = {
    Normal: 1,
    Package: 2,
  };

  static readonly REQUEST_TYPE = [
    { id: Constants.REQUEST_TYPE_ENUM.Normal, name: 'Normal' },
    { id: Constants.REQUEST_TYPE_ENUM.Package, name: 'Package' },
  ];

  static readonly MAX_PAGE_SIZE = 50;
  static readonly CLIENT_PAGE_SIZE = 15;
  static readonly MAX_ITEM_PAGE_SIZE = 2147483647;
  static readonly FEED_SEARCH = 'FEED_SEARCH';
  static readonly PAGE_SIZE = {
    FEED_SEARCH: 5,
  };
  static TINY_CONFIG = {
    base_url: '/tinymce',
    suffix: '.min',
    plugins:
      // tslint:disable-next-line: max-line-length
      'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help',
    toolbar:
      // tslint:disable-next-line: max-line-length
      'formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment',
    image_advtab: true,
    content_css: ['//fonts.googleapis.com/css?family=Lato:300,300i,400,400i', '//www.tiny.cloud/css/codepen.min.css'],
    importcss_append: true,
    height: '300px',
    templates: [
      { title: 'Some title 1', description: 'Some desc 1', content: 'My content' },
      {
        title: 'Some title 2',
        description: 'Some desc 2',
        content: '<div class="mceTmpl"><span class="cdate">cdate</span><span class="mdate">mdate</span>My content2</div>',
      },
    ],
    template_cdate_format: '[CDATE: %m/%d/%Y : %H:%M:%S]',
    template_mdate_format: '[MDATE: %m/%d/%Y : %H:%M:%S]',
    image_caption: true,
    spellchecker_dialog: true,
    spellchecker_whitelist: ['Ephox', 'Moxiecode'],
    tinycomments_mode: 'embedded',
    content_style: '.mce-annotation { background: #fff0b7; } .tc-active-annotation {background: #ffe168; color: black; }',
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,
    document_base_url: AppConfig.settings?.CDN_URL,
  };
  static TINY_CONFIG_EMAIL = {
    base_url: '/tinymce',
    suffix: '.min',
    plugins:
      // tslint:disable-next-line: max-line-length
      'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help',
    toolbar:
      // tslint:disable-next-line: max-line-length
      'templateButton | formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment',
    image_advtab: true,
    content_css: ['//fonts.googleapis.com/css?family=Lato:300,300i,400,400i', '//www.tiny.cloud/css/codepen.min.css'],
    importcss_append: true,
    height: '300px',
    templates: [
      { title: 'Some title 1', description: 'Some desc 1', content: 'My content' },
      {
        title: 'Some title 2',
        description: 'Some desc 2',
        content: '<div class="mceTmpl"><span class="cdate">cdate</span><span class="mdate">mdate</span>My content2</div>',
      },
    ],
    template_cdate_format: '[CDATE: %m/%d/%Y : %H:%M:%S]',
    template_mdate_format: '[MDATE: %m/%d/%Y : %H:%M:%S]',
    image_caption: true,
    spellchecker_dialog: true,
    spellchecker_whitelist: ['Ephox', 'Moxiecode'],
    tinycomments_mode: 'embedded',
    content_style: '.mce-annotation { background: #fff0b7; } .tc-active-annotation {background: #ffe168; color: black; }',
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,
    document_base_url: AppConfig.settings?.CDN_URL,
  };

  static TINY_CONFIG_PDF_TEMPLATE = {
    selector: 'textarea',
    base_url: '/tinymce',
    suffix: '.min',
    height: '300px',
    image_caption: true,
    spellchecker_dialog: true,
    spellchecker_whitelist: ['Ephox', 'Moxiecode'],
    relative_urls: false,
    remove_script_host: true,
    convert_urls: false,
    automatic_uploads: true,
    file_picker_types: 'image',
    plugins:
      'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen link template codesample image code',
    toolbar:
      'templateButton | formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat',
    file_picker_callback: (cb, value, meta) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      input.onchange = () => {
        const file = input.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          const id = 'blobid' + new Date().getTime();
          const blobCache = tinymce.activeEditor.editorUpload.blobCache;
          const base64 = (reader.result as any).split(',')[1];
          const blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          /* call the callback and populate the Title field with the file name */
          cb(blobInfo.blobUri(), { title: file.name });
        };
        reader.readAsDataURL(file);
      };

      input.click();
    },
  };

  static TINY_CONFIG_STATUS_FORM = {
    selector: 'textarea',
    base_url: '/tinymce',
    suffix: '.min',
    height: '300px',
    image_caption: true,
    spellchecker_dialog: true,
    spellchecker_whitelist: ['Ephox', 'Moxiecode'],
    relative_urls: false,
    remove_script_host: true,
    convert_urls: false,
    automatic_uploads: true,
    file_picker_types: 'image',
    plugins: 'preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen link',
    toolbar:
      'templateButton | formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat',
  };

  static TINY_CONFIG_NEWSFEED = {
    base_url: '/tinymce',
    suffix: '.min',
    plugins:
      // tslint:disable-next-line: max-line-length
      'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help mention',
    toolbar:
      // tslint:disable-next-line: max-line-length
      'templateButton | formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment',
    image_advtab: true,
    menubar: '',
    content_css: [],
    importcss_append: true,
    height: '40px',
    image_caption: true,
    spellchecker_dialog: true,
    spellchecker_ignore_list: ['Ephox', 'Moxiecode'],
    tinycomments_mode: 'embedded',
    content_style: '.mce-annotation { background: #fff0b7; } .tc-active-annotation {background: #ffe168; color: black; }',
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,
    document_base_url: AppConfig.settings?.CDN_URL,
    branding: false,
    statusbar: false,
  };

  static readonly LAYOUTS = {
    ONE_COLUMN: 'one-column',
    TWO_COLUMNS_RIGHT: 'two-columns-right',
    TWO_COLUMNS_MAIN: 'two-columns-main',
  };

  static readonly ENTITY_TYPE_NOTIFICATION = {
    SyncDatatTimeKeeper: 'SYNCDATATK',
  };

  static readonly START_WORKING_TIME = '09:00:00';

  static readonly END_WORKING_TIME = '18:00:00';

  static readonly NEWSFEED_STATUS_ID = {
    New: 1,
    Approved: 2,
    Rejected: 3,
    Closed: 4,
  };
  static readonly NEWSFEED_STATUSES = [
    { id: Constants.NEWSFEED_STATUS_ID.New, name: 'New' },
    { id: Constants.NEWSFEED_STATUS_ID.Approved, name: 'Approved' },
    { id: Constants.NEWSFEED_STATUS_ID.Rejected, name: 'Rejected' },
    { id: Constants.NEWSFEED_STATUS_ID.Closed, name: 'Closed' },
  ];

  static readonly GENDER = [
    { id: GenderEnum.Male, title: GenderConstEnum.Male },
    { id: GenderEnum.Female, title: GenderConstEnum.Female },
    { id: GenderEnum.Others, title: GenderConstEnum.Others },
  ];

  static readonly LEAVE_COMPONENT_DISPLAY_TAB = {
    LeaveRequest: 0,
    AnnualLeaveRequest: 1,
  };

  static readonly ACTION_EVENT = {
    CREATE: 0,
    EDIT: 1,
    DELETE: 2,
  };

  static readonly TextColor = {
    Default: 'text-default',
    Success: 'text-success',
    Warning: 'text-warning',
    Info: 'text-info',
    Danger: 'text-danger',
    Muted: 'text-muted',
    Primary: 'text-primary',
    GreenSuccess: 'text-green-success',
  };

  static readonly ColorTextDiff = {
    Normal: '#AAAAAA',
    New: '#007C00',
    Edit: '#2572D6',
  };

  static readonly ClientPermission = {
    Client: {
      ControllerName: 'client',
      Actions: {
        Manage: 'clientmanage',
        Member: 'clientmember',
      },
    },
    Widget: {
      ControllerName: 'clientwidget',
      Actions: {
        GetReviewForms: 'getreviewforms',
        GetAssets: 'getassets',
      },
    },
  };

  static readonly HeadcountMemberSortType = [
    {
      title: 'Joined date',
      value: GroupMemberSortEnum.JoinedDate,
    },
    {
      title: 'Name',
      value: GroupMemberSortEnum.Name,
    },
    {
      title: 'Total experience',
      value: GroupMemberSortEnum.TotalExperience,
    },
    {
      title: 'Tech stack',
      value: GroupMemberSortEnum.TechStack,
    },
    {
      title: 'Position',
      value: GroupMemberSortEnum.Position,
    },
  ];

  static readonly HeadcountMemberSortOrdering = [
    {
      title: 'Ascending',
      value: GroupMemberSortOrderingEnum.Ascending,
    },
    {
      title: 'Descending',
      value: GroupMemberSortOrderingEnum.Descending,
    },
  ];

  static readonly HeadcountMemberDefaultSortColumn = GroupMemberSortEnum.JoinedDate;
  static readonly HeadcountMemberDefaultSortOrdering = GroupMemberSortOrderingEnum.Descending;

  static readonly ModalSize = {
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
    percentFullScreen: 'aluha-modal modal-fullscreen',
    percent90: 'aluha-modal modal-90',
    percent80: 'aluha-modal modal-80',
    percent60: 'aluha-modal modal-60',
    percent70: 'aluha-modal modal-70',
    percent80AutoHeight: 'aluha-modal modal-80 auto-height',
  };

  static readonly LeaveTransferModalSize = Constants.ModalSize.lg;

  static readonly HoursPerWorkDay = 8;

  static readonly AnnualLeaveExportFileName = 'AnnualLeave';
  static readonly LeaveRequestExportFileName = 'LeaveRequest';
  static readonly PurchaseRequestExportFileName = 'PurchaseRequest';
  static readonly CriteriaExportFileName = 'Criteria';
  static readonly LeaveTransferExportFileName = 'LeaveTransfer';
  static readonly EmployeeExportFileName = 'Employee';
  static readonly BenefitRegistrationsExportFileName = 'Benefit-registrations';
  static readonly LevelGuidelineExportFileName = 'Level-guideline';
  static readonly PlayImagePath = 'assets/images/play.png';
  static readonly ChristmasGamePosterImagePath = 'assets/images/games/christmas-poster.png';

  static readonly MimeTypes = {
    '.mp4': 'video/mp4',
    '.webm': 'video/webm',
    '.mpeg': 'video/mpeg',
  };

  static readonly HideLoadingIncludeUrls = [UrlConstant.mapNewsFeed(), UrlConstant.USER_FIRSTPAGE, UrlConstant.mapGame()];

  static readonly HideLoadingExcludeUrls = [
    UrlConstant.mapNewsFeed(UrlConstant.NewsFeedUrl.ANNOUNCEMENTS),
    UrlConstant.mapNewsFeed(UrlConstant.NewsFeedUrl.TICKET_REPORTER),
  ];

  static readonly HidePopoverOnScrollingClass = 'hide-popover-on-scroll';

  static readonly PurchaseRequestPermission = {
    Controller: 'purchaserequest',
    Actions: {
      OnlyGroup: 'only_group',
      OnlyStatus: 'only_status',
    },
  };

  static readonly WidgetSearchModeDefault = WidgetSearchModeEnum.Month;
  static readonly WidgetSearchModeFormat = {
    [WidgetSearchModeEnum.Month]: {
      mode: 'month',
      format: 'MM/yyyy',
    },
    [WidgetSearchModeEnum.Year]: {
      mode: 'year',
      format: 'yyyy',
    },
  };

  static readonly WidgetAttendanceChartLabels = {
    InTime: 'In-time',
    ApprovedLeave: 'Approved Leave',
    MissingHours: 'Missing Hours',
  };
}
