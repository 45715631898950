<nz-select (ngModelChange)="changeDate($event,1)" [ngModel]="model.fromMonth">
  <nz-option *ngFor="let item of months" [nzValue]="item" [nzLabel]="item"></nz-option>
</nz-select>
<nz-select (ngModelChange)="changeDate($event,2)" class="ml-2" [ngModel]="model.fromYear">
  <nz-option *ngFor="let item of years" [nzValue]="item" [nzLabel]="item"></nz-option>
</nz-select>
<p class="stylep ml-2">-</p>
<nz-select (ngModelChange)="changeDate($event,3)" class="ml-2" [ngModel]="model.toMonth">
  <nz-option *ngFor="let item of months" [nzValue]="item" [nzLabel]="item"></nz-option>
</nz-select>
<nz-select (ngModelChange)="changeDate($event,4)" class="ml-2" [ngModel]="model.toYear">
  <nz-option *ngFor="let item of years" [nzValue]="item" [nzLabel]="item"></nz-option>
</nz-select>
