import { Injectable } from '@angular/core';
import { HttpClientService } from '../../http/http-client';
import { Constants } from '../../config';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class GroupUserService {
  hostUrl = AppConfig.settings.API_URL + '/api/groupuser/';
  hostUrl2 = AppConfig.settings.API_URL + '/api/user/';
  constructor(private httpClient: HttpClientService) {}

  getAll() {
    const url = this.hostUrl;
    return this.httpClient.getSync(url);
  }
  getRole() {
    const url = this.hostUrl + 'roles';
    return this.httpClient.getSync(url);
  }

  getRoleAll() {
    const url = this.hostUrl2 + 'get-role-all';
    return this.httpClient.getSync(url);
  }

  getById(id) {
    const url = this.hostUrl + 'get-by-id/' + id;
    return this.httpClient.getSync(url);
  }

  getDropdown() {
    const url = this.hostUrl + 'dropdown';
    return this.httpClient.getSync(url);
  }

  create(data) {
    const url = this.hostUrl;
    return this.httpClient.postSync(url, data);
  }

  update(data) {
    const url = this.hostUrl;
    return this.httpClient.putSync(url, data);
  }

  delete(id) {
    const url = this.hostUrl + id;
    return this.httpClient.deleteSync(url);
  }

  convertToTree(data) {
    const datas = data.replaceMany([
      { oldvalue: 'controllers', newvalue: 'children' },
      { oldvalue: 'actions', newvalue: 'children' },
    ]);
    datas.forEach(item => {
      item.title = item.name;
      if (item.children) {
        item.children.forEach((ctrler, index) => {
          ctrler.title = ctrler.name;
          if (ctrler.children) {
            ctrler.children.forEach((ac, index) => {
              if (ac.isShow !== undefined && ac.isShow === false) {
                delete ctrler.children[index];
              } else {
                ac.module = item.module;
                ac.controller = ctrler.controller;
                ac.title = ac.name;
                ac.isLeaf = true;
              }
            });
          } else {
            if (ctrler.isShow !== undefined && ctrler.isShow === false) {
              delete item.children[index];
            } else {
              ctrler.module = item.module;
              ctrler.controller = item.controller;
              ctrler.isLeaf = true;
            }
          }
        });
      }
    });
    return datas;
  }
}
