import { Component, OnInit } from '@angular/core';
import { BaseAdminComponent } from '@intranet/lib/components';
import { ColumnMapping, COLUMN_TYPE, MetaDataPaging, Policy, PolicyService, SearchPolicy } from '@intranet/lib/data-access';
import { Loading, ModalOption, ModalService, ModalSize } from '@intranet/lib/settings';
import { PolicyModalComponent } from './policy-modal/policy-modal.component';

@Component({
  selector: 'app-policy-list',
  templateUrl: './policy-list.component.html',
  styleUrls: ['./policy-list.component.scss']
})
export class PolicyListComponent extends BaseAdminComponent implements OnInit {
  keyWord = '';
  columnMapping = [
    new ColumnMapping(COLUMN_TYPE.STRING, 'title', 'Title', true, true),
    new ColumnMapping(COLUMN_TYPE.STRING, 'description', 'Description', true, true),
  ];
  modalOption: ModalOption = {
    size: ModalSize.lg,
  };
  isAdvanceFilter: boolean;
  searchData: SearchPolicy = new SearchPolicy();

  data: Policy[] = [];
  rootPolicys: Policy[] = [];

  constructor(private policySvc: PolicyService, private modalSvc: ModalService) {
    super();
  }

  ngOnInit() {
    this.getPolicyList();
  }

  openNewModal() {
    const modal = this.modalSvc.open(PolicyModalComponent, this.modalOption);
    modal.result.then((resp: any) => {
      if (resp) {
        this.getPolicyList();
      }
    });
  }

  editPolicy(id) {
    const modal = this.modalSvc.open(PolicyModalComponent, this.modalOption);
    modal.componentInstance.policyId = id;
    modal.result.then((resp: Policy) => {
      if (resp) {
        this.getPolicyList();
      }
    });
  }

  deletePolicy(id) {
    Loading.show();
    this.policySvc.deleteById(id, () => {
      this.getPolicyList();
    });
  }

  search() {
    this.searchData.pageNumber = 1;
    this.getPolicyList();
  }

  // datatable events
  pageChange(pageInfo) {
    this.searchData.pageNumber = pageInfo.offset + 1;
    this.getPolicyList();
  }

  //#region private methods

  private getPolicyList() {
    this.isLoading = true;
    this.policySvc.getAll(this.searchData, (resp: Policy[]) => {
      this.data = resp;
      if (resp && resp.length) {
        this.searchData.totalItems = this.data[0].totalCount;
      } else {
        this.searchData.totalItems = 0;
      }
      this.isLoading = false;
    });
  }

  //#endregion private methods
}
