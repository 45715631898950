import { Injectable } from '@angular/core';
import { HttpClientService, Loading, Toastr } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { Observable } from 'rxjs';
import { ApiEndpoint } from '../../config/api-endpoint.constant';
import { SearchModel } from '../models';
import { ApiResponse, PagingResponse } from '../models/api-response.model';
import { Employee, EmployeeContractType } from '../models/employee.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/employee';
  httpClient2: HttpClientService;
  constructor(httpClient: HttpClientService) {
    super(httpClient);
    this.httpClient2 = httpClient;
  }

  getPositionByUserId(
    uidId: string,
    successCallback?: (resp?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'get-position-by-user-id';
    const params = {
      noLoading: true,
      id: uidId,
    };

    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  updateAvatar(
    data: any,
    successCallback: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = this.hostUrl + '/update-avatar';
    if (!data.noLoading) {
      Loading.show();
    }
    this.httpClient2
      .putSync(url, data)
      .then(
        (resp: ApiResponse) => {
          if (resp && resp.id && resp.id > 0) {
            successCallback(resp);
          } else {
            Toastr.error(resp.message);
          }
        },
        (error: any) => {
          if (failedCallback) {
            failedCallback(error);
          } else {
            Toastr.error(error.message);
          }
        },
      )
      .finally(() => {
        if (completeCallback) {
          completeCallback();
        }
        if (!data.noLoading) {
          Loading.hide();
        }
      });
  }
  updatePersonal(
    data: any,
    successCallback: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'update-personal';
    return this.updateUrl(url, data, undefined, successCallback, failedCallback, completeCallback);
  }
  getForOnlyEmployee(id, successCallback?: (params?: any) => void) {
    Loading.show();
    const url = 'get_by_personal';
    return this.getByUrl(url, { id }, successCallback);
  }

  // this function is to get basic information of employee
  getBasicEmployeeInfo(id, successCallback?: (params?: any) => void) {
    Loading.show();
    const url = `${id}/information`;
    return this.getByUrl(url, {}, successCallback);
  }

  getForDept(data: any, successCallback?: (params?: any) => void) {
    Loading.show();
    const url = 'get-by-dept';
    return this.getByUrl(url, data, successCallback);
  }
  getForSelectWithoutJoinUser(params: any, successCallback?: (params?: any) => void) {
    Loading.show();
    const url = 'get-select';
    return this.getByUrl(url, params, successCallback);
  }

  getBirthday(
    params: any,
    successCallback: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'get-birthday';
    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getWorkAnniversary(
    params: any,
    successCallback: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'get-work-anniversary';
    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getBirthdayWishes(
    params: any,
    successCallback: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'get-birthday-wishes';
    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getWorkAnniversaryWihes(
    params: any,
    successCallback: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const url = 'get-work-anniversary-wishes';
    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getEmployeeById(employeeId: string) {
    const url = `/api/employee/get_by_id`;
    const request = {
      guidId: employeeId,
    } as any;
    return this.httpClient.getObservable(url, request);
  }

  export(searchData: any): Promise<any> {
    const url = ApiEndpoint.Employee.Export();

    return this.httpClient.getObservable(url, searchData).toPromise();
  }

  getContractTypes(): Promise<EmployeeContractType[]> {
    const url = ApiEndpoint.Employee.ContractTypes();

    return this.httpClient.getObservable(url).toPromise();
  }

  getEmployeeByName(requestOption: SearchModel): Observable<PagingResponse<Employee>> {
    return this.httpClient.getObservable('/api/employee/get-dropdown-pagin', requestOption, false);
  }

  getEmployeeList(requestOption: any): Observable<PagingResponse<Employee>> {
    return this.httpClient.postObservable('/api/employee/get-employee-list', requestOption);
  }
}
