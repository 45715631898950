import { MenuItemComponent } from './sidebar/menu-item/menu-item.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import {
  MasterLayoutComponent,
  SidebarComponent,
  ToolbarComponent,
  UserLayoutComponent,
  UserToolbarComponent,
  AdminHeaderComponent,
  NewFeedLayoutComponent,
  BannerCoverComponent,
  SidebarRightComponent,
  SidebarLeftComponent,
  HeaderFrontComponent,
  FrontAvatarComponent,
  FrontTabProfileComponent,
  FrontProfileLayoutComponent,
  FrontNewsFeedLayoutComponent,
  FrontDetailLayoutComponent,
  FrontHeaderMessageComponent,
  FrontHeaderNotificationComponent,
  FrontGroupComponent,
  FrontTabGroupComponent,
  FrontPolicyLayoutComponent,
  FrontHeaderProfileComponent
} from '.';
import { MomentModule } from 'ngx-moment';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { ShareDirectiveModule } from '../directives';
import { TranslateModule } from '@ngx-translate/core';
import { BlankLayoutComponent } from './layout/blank-layout/blank-layout.component';
import { UserSidebarComponent } from './user-sidebar/user-sidebar.component';
import { ComponentShareModule } from '../components/component-share.module';
import { NgZorroShareModule } from '../share/ngzorro.module.share';
import { ProjectLayoutComponent } from './layout/project-layout/project-layout.component';
import { ProjectToolbarComponent } from './project-toolbar/project-toolbar.component';
import { FrontGroupLayoutComponent } from './layout/front-layout/front-group-layout/front-group-layout.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { ClientLayoutComponent } from './layout/client-layout/client-layout.component';
@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, TranslateModule, ShareDirectiveModule, ComponentShareModule, NgZorroShareModule, MomentModule],
  declarations: [
    MasterLayoutComponent,
    AdminLayoutComponent,
    BlankLayoutComponent,
    SidebarComponent,
    ToolbarComponent,
    MenuItemComponent,
    UserSidebarComponent,
    UserLayoutComponent,
    UserToolbarComponent,
    AdminHeaderComponent,
    ProjectLayoutComponent,
    ProjectToolbarComponent,
    NewFeedLayoutComponent,
    BannerCoverComponent,
    HeaderFrontComponent,
    SidebarLeftComponent,
    SidebarRightComponent,
    FrontHeaderMessageComponent,
    FrontHeaderNotificationComponent,
    FrontAvatarComponent,
    FrontGroupComponent,
    FrontTabProfileComponent,
    FrontTabGroupComponent,
    FrontProfileLayoutComponent,
    FrontGroupLayoutComponent,
    FrontDetailLayoutComponent,
    FrontNewsFeedLayoutComponent,
    FrontPolicyLayoutComponent,
    FrontHeaderProfileComponent,
    LoginLayoutComponent,
    ClientLayoutComponent
  ],
  exports: [
    FrontGroupComponent,
    BannerCoverComponent
  ]
})
export class LayoutModule { }
