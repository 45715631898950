<nz-card [nzBordered]="true" [nzTitle]="'Histories'" *ngIf="purchaseHistories.length > 0">
  <div>
    <div *ngFor="let purchaseHistory of purchaseHistories" class="col-12 pb-2">
      <div class="row pb-0 mb-0">
        <div class="col-4 font-weight-bold">
          {{purchaseHistory?.createdDate | date: 'EEEE, MMMM d, y HH:mm:ss'}}
        </div>
        <div class="col-8">
          <div class="row status-container"
            *ngIf="purchaseHistory && (purchaseHistory.originStatus !== purchaseHistory.destinationStatus || purchaseHistory.deliveryStatus === null || purchaseHistory.deliveryStatus === undefined)">
            {{purchaseHistory?.fullName}} has changed status from
            <div [innerHTML]="purchaseHistory?.originStatus | status: statusEnum.PRUCHASE_REQUEST_STATUS" class="mr-2 ml-2"></div> to
            <div [innerHTML]="purchaseHistory?.destinationStatus | status: statusEnum.PRUCHASE_REQUEST_STATUS" class="ml-2"></div>
          </div>
          <div class="row status-container"
            *ngIf="purchaseHistory && (purchaseHistory.originStatus === purchaseHistory.destinationStatus && purchaseHistory.deliveryStatus !== null && purchaseHistory.deliveryStatus !== undefined)">
            {{purchaseHistory?.fullName}} has changed delivery form status to
            <div [innerHTML]="purchaseHistory?.deliveryStatus | status: statusEnum.DELIVERY_FORM_PR_STATUS" class="ml-2"></div>
          </div>
        </div>
      </div>
      <div class="row pt-0 mt-0" *ngIf="purchaseHistory?.description">
        <div class="col-12">
          <span class="font-italic font-weight-bold">Note: </span>
          {{purchaseHistory?.description}}
        </div>
      </div>
    </div>
  </div>
</nz-card>
