import { Constants } from '@intranet/lib/config';
import { Skill } from './skill.model';
import { Department } from './department.model';
import { Children } from './children.model';
import { EmployeeContractTypeEnum } from './enums/employee-contract-type.enum';
import { HeadcountTypeEnum } from './enums/headcount-type.enum';

export class Account {
  id: string;
  userName: string;
  password: string;
  fullName: string;
  groupUserId: string;
  employeeId: string;
  isActive: boolean;
  isSendMail: boolean;
  constructor() {
    this.isActive = true;
    this.isSendMail = true;
  }
}

export interface IEmployee {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  employeeNo: string;
  gender: string;
  dateOfBirth: Date;
  address: string;
  zipCode: string;
  country: string;
  email: string;
  phoneMobile: string;
  positionId: string;
  joinedDate: Date;
  terminatedDate: Date;
  nationlity: string;
  passportNo: string;
  issuedDate: Date;
  hometown: string;
  maritalStatus: string;
  noOfKids: string;
  noOfDependents: string;
  socialSecurityNo: string;
  socialIssueDate: Date;
  taxNo: string;
  emergencyFullName: string;
  emergencyPhone: string;
  emergencyAddress: string;
  residentialAddress: string;
  deparments: Department[];
  groupDepartments: Department[];
  skills: any; // for create and update
  userId: string;
  cityId: number;
  bankAccountNo: string;
  totalCount: number;
  listSkills: any[]; // for emp list
  account: Account;
  leaveTypes: string;
  socialAccount: string;
  position: string;
}

export class Employee implements IEmployee {
  id: string;
  isMaritalStatus: boolean;
  enrollNumber: string;
  firstName: string;
  lastName: string;
  joinName: string;
  fullName: string;
  name: string;
  userName: string;
  avatarUrl: string;
  employeeNo: string;
  gender: string;
  dateOfBirth: Date;
  address: string;
  zipCode: string;
  country: string;
  email: string;
  phoneMobile: string;
  privateEmail: string;
  numberWorkExperience: number;
  positionId: string;
  joinedDate: Date = new Date();
  terminatedDate: Date;
  nationlity: string;
  passportNo: string;
  issuedDate: Date;
  hometown: string;
  maritalStatus: string;
  noOfKids: string;
  noOfDependents: string;
  socialSecurityNo: string;
  socialIssueDate: Date;
  taxNo: string;
  emergencyFullName: string;
  emergencyPhone: string;
  emergencyAddress: string;
  residentialAddress: string;
  deparments: Department[];
  groupDepartments: Department[];
  skills: any; // for create and update
  userId: string;
  cityId: number;
  timeKeeperId: number;
  bankAccountNo: string;
  totalCount: number;
  listSkills: any[]; // for emp list
  account: Account;
  leaveTypes: string;
  isGuessUser?: string;
  socialAccount: string;
  msTeamsAccount?: string;
  position: string;
  positionTitle: string;
  newcomerNewsFeedId?: string;
  newcomerSelfIntroduction?: string;
  isShowToolbox?: boolean;
  childrens: Children[];
  contractType: EmployeeContractTypeEnum;
  layerId: string;
  employeePositionId: string;
  layerDetailId: string;
  headcountType: HeadcountTypeEnum = HeadcountTypeEnum.New;
  replacementEmployeeId: string;
  constructor(emp?: IEmployee) {
    if (emp) {
      Object.assign(this, emp);
    }
    this.account = new Account();
  }
  getSkillList(): Skill[] {
    if (this.skills) {
      return JSON.parse(this.skills);
    }
    return [];
  }
  stringifySkillList(skills: Skill[]): void {
    this.skills = JSON.stringify(skills);
  }
}

export class UserTimeWorkingUpdateDto {
  id: string;
  workingStartTime: string;
  workingEndTime: string;
  earliestStartTime: string;
  latestEndTime: string;
}

export class EmployeeBirthday {
  id?: string;
  userId?: string;
  fullName: string;
  avatarUrl: string;
  reactionCount?: number;
  userCount?: number;
}

export class EmployeeWorkAnniversary {
  id?: string;
  userId?: string;
  fullName: string;
  avatarUrl: string;
  reactionCount?: number;
  userCount?: number;
}

export class EmployeeContractType {
  id: number;
  name: string;
}
