import { Component, HostListener, OnInit } from '@angular/core';
import { UrlConstant } from '@intranet/lib/config';
import { OrgChartDto, OrgChartItemDto, UserService } from '@intranet/lib/data-access';
@Component({
  selector: 'app-org-chart-page',
  templateUrl: './org-chart-page.component.html',
  styleUrls: ['./org-chart-page.component.scss']
})
export class OrgChartPageComponent implements OnInit {
  list: OrgChartDto<OrgChartItemDto>[];
  item: OrgChartDto<OrgChartItemDto>;
  urlConstant = UrlConstant;
  groupScale = 3;
  countGroupScale = 3;

  constructor(private userService: UserService) { }

  async ngOnInit() {
    await this.loadOrgChart();
  }
  selectNode(nodeData: any) {
    if (nodeData.type === 1) {
      this.loadFilterData(nodeData.data.departmentId);
    }
  }
  async loadOrgChart() {
    const data = await this.userService.getDataOrgChart();
    this.list = data;
    this.item = data[0];
    this.calCountGroupScale();
  }
  loadFilterData(id) {
    if (this.item.id === id) {
      if (this.item.data.parentId) {
        this.item = this.list.find((item) => {
          return item.id === this.item.data.parentId;
        });
      }
    } else {
      this.item = this.list.find((item) => {
        return item.id === id;
      });
    }
    this.calCountGroupScale();
  }
  calGroupScale() {
    if (this.item.children) {
      let count = Math.round(this.item.children.length / this.countGroupScale);
      if (this.item.children.length % this.countGroupScale !== 0 && this.countGroupScale > 2) {
        count++;
      }
      this.groupScale = count;
    }
  }
  calCountGroupScale() {
    const width = window.innerWidth;
    this.countGroupScale = this.item.children.length > 10 ? 4 : 3;
    if (width <= 768) {
      this.countGroupScale = 2;
    }
    if (width <= 500) {
      this.countGroupScale = 1;
    }

    this.calGroupScale();
  }
  @HostListener('window:resize')
  resizeWindowHandler() {
    this.calCountGroupScale();
  }
}
