import { Component, ElementRef, OnInit } from '@angular/core';
import { Toastr } from '@intranet/index';
import { WorkTimeService } from '@intranet/lib/data-access';
import { endOfWeek, isBefore, isFuture, isSameWeek, isSaturday, isSunday } from 'date-fns';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-health-check-claim',
  templateUrl: './health-check-claim.component.html',
  styleUrls: ['./health-check-claim.component.scss'],
})
export class HealthCheckClaimComponent implements OnInit {
  dateFormat = 'dd/MM/yyyy';

  claimDate = new Date();

  claimedDate: Date;

  isEditMode = false;

  isEffected = false;

  loading = true;

  readonly startEventDate = new Date('2023-07-24T00:00:00');

  readonly endEventDate = endOfWeek(this.startEventDate, { weekStartsOn: 1 });

  readonly isInEventTime = isSameWeek(new Date(), this.startEventDate, { weekStartsOn: 1 });

  constructor(private readonly workTimeService: WorkTimeService, private readonly hostElement: ElementRef) {}

  ngOnInit(): void {
    // Check display: none before execute api
    if (this.hostElement?.nativeElement.offsetParent) this.getClaimHealthCheck();
  }

  claimHealthCheck(): void {
    this.loading = true;
    this.workTimeService
      .claimWorkTime({ date: moment(this.claimDate).format('YYYY-MM-DD') })
      .pipe(
        catchError(err => {
          this.loading = false;
          Toastr.error(err.message);

          return throwError(err);
        }),
      )
      .subscribe(() => {
        this.isEditMode = false;
        this.getClaimHealthCheck();
      });
  }

  cancel(): void {
    this.isEditMode = false;
    this.claimDate = this.claimedDate;
  }

  // tslint:disable
  disabledDateFn = (date: Date): boolean =>
    isBefore(date, this.startEventDate) || isFuture(date) || isSaturday(date) || isSunday(date);
  // tslint:enable

  private getClaimHealthCheck(): void {
    this.workTimeService
      .getClaimWorkTime()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(({ date, isEffected }) => {
        if (date) {
          this.claimDate = new Date(date);
          this.claimedDate = new Date(date);
        }

        this.isEffected = isEffected;
      });
  }
}
