import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { unitOfTime } from 'moment';
import { Constants } from '../config/constants';

@Injectable({
  providedIn: 'root',
})
export class DateHelper {
  static YYYYMMDDFormater = 'YYYY/MM/DD';
  static currentDate = new Date();

  static getRemainSeconds(value: string) {
    if (value) {
      value = this.removeISO(value);
      const endDate = moment(value);
      const today = moment();
      const duration = moment.duration(today.diff(endDate));
      const hours = duration.asSeconds();
      return hours;
    }
  }

  static formatLocalTime(value: string): string {
    return moment(value).format();
  }

  static formatDateFromServer(value: string) {
    if (value) {
      value = this.removeISO(value);
      const date = new Date(value);
      return date;
    }
    return new Date();
  }

  static format(value: any, format = 'DD-MM-YYYY HH:mm:ss') {
    if (value) {
      value = this.removeISO(value);
      const date = moment(value).format(format);
      return date;
    }
  }
  static formatServer(value: any, format = 'YYYY/MM/DD HH:mm:ss') {
    if (value) {
      const date = moment(value).format(format);
      return date;
    }
    return value;
  }
  static formatServerRemoveISO(value: any, format = 'YYYY/MM/DD HH:mm:ss') {
    if (value) {
      const date = moment(this.removeISO(value)).format(format);
      return date;
    }
    return value;
  }

  static formatServerNotTime(value: any, format = 'YYYY-MM-DD') {
    if (value) {
      const date = moment(value).format(format);
      return date;
    }
    return value;
  }

  static getTimeBetweenDate(date: any) {
    if (!date) {
      return date;
    }
    date = this.removeISO(date);
    const currentTime = new Date().getTime();
    const expiredTime = new Date(date).getTime();
    return currentTime - expiredTime;
  }

  static getDistanceWithToday(startDate: any) {
    if (!startDate) {
      return startDate;
    }
    startDate = this.removeISO(startDate);
    startDate = new Date(startDate);
    const currentTime = moment().format('YYYY-MM-DD');
    const expiredTime = moment(startDate, 'YYYY-MM-DD');
    const result = expiredTime.diff(currentTime, 'days');
    return result;
  }

  static getTimeDistanceWithToday(startDate: any) {
    if (!startDate) {
      return startDate;
    }
    startDate = this.removeISO(startDate);
    startDate = new Date(startDate);
    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
    const expiredTime = moment(startDate, 'YYYY-MM-DD HH:mm:ss');
    const result = expiredTime.diff(currentTime, 'minutes');
    return result;
  }

  static getDistanceBetweenDate(startDate: any, endDate: any, unit: unitOfTime.Diff = 'days') {
    if (!startDate || !endDate) {
      return 0;
    }
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const currentTime = moment(startDate).format('YYYY-MM-DD');
    const expiredTime = moment(endDate, 'YYYY-MM-DD');
    const result = expiredTime.diff(currentTime, unit);
    return result;
  }

  static getDistanceBetweenDateAndCheckToDay(date: any) {
    if (!date) {
      return date;
    }
    date = this.removeISO(date);
    date = new Date(date);
    const currentTime = moment().format('YYYY-MM-DD');
    const expiredTime = moment(date, 'YYYY-MM-DD');
    let result = expiredTime.diff(currentTime, 'days');
    if (result === 0) {
      const a = new Date(currentTime).getDate();
      const b = expiredTime.toDate().getDate();
      result = b - a;
    }
    return result;
  }

  static getDistanceTwoDate(date1: any, date2: any) {
    if (!date1 || !date2) {
      return -1;
    }
    date1 = this.removeISO(date1);
    date2 = this.removeISO(date2);
    date1 = new Date(date1);
    date2 = new Date(date2);
    const startTime = moment(date1, 'YYYY-MM-DD');
    const expiredTime = moment(date2, 'YYYY-MM-DD');
    const result = expiredTime.diff(startTime, 'days');
    return result;
  }

  static formatDate(date: any) {
    const d = new Date(date);
    const dateFormat =
      d.getFullYear() +
      '/' +
      ('00' + (d.getMonth() + 1)).slice(-2) +
      '/' +
      ('00' + d.getDate()).slice(-2) +
      ' ' +
      ('00' + d.getHours()).slice(-2) +
      ':' +
      ('00' + d.getMinutes()).slice(-2) +
      ':' +
      ('00' + d.getSeconds()).slice(-2);
    return dateFormat;
  }

  static removeISO(date: any) {
    if (date && typeof date === 'string') {
      // return date.replace(/([A-Za-z])/gi, ' ');
      return date;
    }
    return date;
  }

  static convertDateStringtoIso(value: any) {
    if (!value) {
      return value;
    }
    const str = value.split(/[\s,-/]+/);
    if (str && str.length) {
      let year = 0;
      let month = 0;
      let day = 0;
      str.forEach(val => {
        // tslint:disable-next-line: radix
        if (val.length >= 4 && parseInt(val) > 1950) {
          year = val;
          // tslint:disable-next-line: radix
        } else if (val.length <= 2 && parseInt(val) <= 12) {
          month = val;
        } else {
          day = val;
        }
      });
      const date = new Date(year, month, day);
      return date;
    }
  }

  static getFirstTimeOfDateLocaltion(value) {
    return new Date(this.formatServerNotTime(value)).setHours(0, 0, 0, 0);
  }

  static getLastTimeOfDateLocaltion(value) {
    return new Date(this.formatServerNotTime(value)).setHours(23, 59, 59, 59);
  }

  static getDayFromDate(value) {
    const now = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const day = days[now.getDay()];
    return day;
  }

  static formatFullDateToHour(date: any) {
    const dateFormatted = new Date(date);
    return `${dateFormatted.getHours()}:${dateFormatted.getMinutes()}`;
  }

  static getMonth(date: Date) {
    if (date) {
      return moment(date).month() + 1;
    }
    return null;
  }

  static getMonthFromNumber(month: number) {
    // Month number begin from 0 to 11 numbers
    if (month >= 1) {
      return month - 1;
    }
    return null;
  }

  static getYear(date: Date) {
    if (date) {
      return moment(date).year();
    }
    return null;
  }

  static dateDiffFromNumber(delta: number, options?: any) {
    const diffResult = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (delta <= 0 || !delta) {
      return diffResult;
    }

    if (!options?.disableDays) {
      diffResult.days = Math.floor(delta / Constants.MillisecondsPerDay);
      delta -= diffResult.days * Constants.MillisecondsPerDay;
    }

    if (!options?.disableHours) {
      diffResult.hours = Math.floor(delta / Constants.MillisecondsPerHour);
      delta -= diffResult.hours * Constants.MillisecondsPerHour;
    }

    if (!options?.disableMinutes) {
      diffResult.minutes = Math.floor(delta / Constants.MillisecondsPerMinute);
      delta -= diffResult.minutes * Constants.MillisecondsPerMinute;
    }

    diffResult.seconds = Math.floor(delta / Constants.MillisecondsPerSecond);

    return diffResult;
  }

  static getFirstDateOfMonth(month: number, year: number): Date {
    if (month < 0 || month > 11 || !year) {
      return;
    }

    return new Date(year, month, 1);
  }

  static getLastDateOfMonth(month: number, year: number): Date {
    const result = this.getFirstDateOfMonth(month, year);

    if (!result) {
      return;
    }

    result.setMonth(result.getMonth() + 1);
    result.setDate(result.getDate() - 1);

    return result;
  }

  static getFirstDateOfYear(yearOrDate: number | Date): Date {
    const year = yearOrDate instanceof Date ? yearOrDate.getFullYear() : yearOrDate;

    return new Date(year, 0, 1);
  }

  static getLastDateOfYear(yearOrDate: number | Date): Date {
    const year = yearOrDate instanceof Date ? yearOrDate.getFullYear() : yearOrDate;

    return new Date(year, 11, 31);
  }

  static convertDateRangeToString(dateRange: Date[]): string {
    if (!dateRange || dateRange.length !== 2) return;

    const [startDate, endDate] = dateRange;
    return `${moment(startDate).format('YYYY/MM/DD')}-${moment(endDate).format('YYYY/MM/DD')}`;
  }

  static convertStringToDateRange(dateRangeStr: string): Date[] {
    if (!dateRangeStr) return [];

    return dateRangeStr.split('-').map(d => new Date(d));
  }
}
