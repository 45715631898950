import { BaseModel } from './base-model';
import { EquipmentCategory } from './equipment-category';

export class Product extends BaseModel {
  id: string;
  code: string;
  name: string;
  note: string;
  model: string;
  equipmentCategoryId: string;
  equipmentCategory: EquipmentCategory;
  categoryId: string;
  categoryName: string;
  description: string;
  linkWebsite: string;

  constructor(data?) {
    super();
    this.equipmentCategory = new EquipmentCategory();
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.model = data.model;
      this.equipmentCategoryId = data.equipmentCategoryId;
      this.description = data.description;
      this.linkWebsite = data.linkWebsite;
    }
  }
}

export class ProductPackageItem extends BaseModel {
  id: string;
  equipmentCategoryId?: string;
  categoryName: string;
  product: Product;
  productId?: string;
  quantity: number;
  description: string;
  constructor(data?) {
    super();
    this.product = new Product();
    this.quantity = 0;
    if (data) {
      this.id = data.id;
      this.equipmentCategoryId = data.product.equipmentCategoryId;
      this.product = data.product;
      this.productId = data.productId;
      this.quantity = data.quantity;
      this.description = data.description;
    }
  }
}

export class ProductPackage extends BaseModel {
  id: string;
  name: string;
  description: string;
  lines: ProductPackageItem[];

  constructor(data?) {
    super();
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.description = data.description;
      this.lines = data.lines;
    }
  }
}
