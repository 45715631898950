import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Loading, Toastr } from '../../settings';

import { UrlConstant } from '@intranet/index';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { PushMessagingService } from '@intranet/lib/services/push-messaging.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';
import { Constants } from '../../config/constants';
import { SecurityHelper } from '../../helpers/securityHelper';
import { HttpClientService } from '../../http/http-client';
import { CreateCheckInCheckOutRequest, DepartmentGroupProfile } from '../models';
import { Benefit } from '../models/benefit.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  rootUrl = '/api/user';
  hostUrl = '/api/user/';

  constructor(
    private httpClient: HttpClientService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private pushMessageService: PushMessagingService,
  ) {
    this.hostUrl = AppConfig.settings.API_URL + '/api/user';
  }
  departmentGroupProfile = new BehaviorSubject<DepartmentGroupProfile[]>([]);

  getDepartmentGroup;

  async login(params: any) {
    Loading.showOnPage();
    const url = this.hostUrl + '/login';
    params.pushToken = this.pushMessageService.token;
    params.platform = 'Web';
    const deviceInfo = this.deviceService.getDeviceInfo();
    params.deviceId = deviceInfo.os + '-' + deviceInfo.browser + '-' + deviceInfo.browser_version;
    const data = await this.httpClient.postSync(url, params);
    if (data && data.code > 0) {
      SecurityHelper.createStoreAuthen(data.data);
      const previousUrl = Constants.previousUrl;
      let returnUrl;

      const allParams = this.activatedRoute.snapshot.queryParams;
      if (allParams && allParams.returnUrl) {
        returnUrl = decodeURIComponent(allParams.returnUrl);
      }

      if (returnUrl) {
        this.router.navigateByUrl(returnUrl);
      } else {
        const checkFirstPage = SecurityHelper.detectFirstPageAllow(previousUrl);
        if (!Constants.previousUrl) {
          if (data.data.isAdminAccess) {
            Constants.previousUrl = checkFirstPage;
          } else if (data.data.isPersonalAccess) {
            Constants.previousUrl = UrlConstant.NEWFEEDS_FIRSTPAGE;
          }
        }
        if (data.data?.isExternalAccount) {
          this.router.navigateByUrl('/client');
          return;
        }
        this.router.navigateByUrl(checkFirstPage);
      }
    } else {
      Toastr.error(data.message);
    }
    setTimeout(() => {
      Loading.hide();
    }, 200);
    return data;
  }

  logout() {
    const url = this.hostUrl + '/logout';
    return this.httpClient.postSync(url);
  }
  updateToken() {
    const url = this.hostUrl + '/update-token';
    // tslint:disable-next-line: prefer-const
    const deviceInfo = this.deviceService.getDeviceInfo();
    const data = {
      pushToken: this.pushMessageService.token,
      platform: 'Web',
      deviceId: deviceInfo.os + '-' + deviceInfo.browser + '-' + deviceInfo.browser_version,
    };
    return this.httpClient.postSync(url, data);
  }
  create(data: any) {
    const url = this.hostUrl;
    return this.httpClient.postSync(url, data);
  }
  update(data: any) {
    const url = this.hostUrl;
    return this.httpClient.putSync(url, data);
  }
  updatePassword(data: any) {
    const url = this.hostUrl + '/change-password';
    return this.httpClient.putSync(url, data);
  }
  updateReadAll(type) {
    const url = this.hostUrl + '/update-read-all-by-user';
    return this.httpClient.postSync(url, { type });
  }
  updateCleanAll(type) {
    const url = this.hostUrl + '/update-clean-all-user';
    return this.httpClient.postSync(url, { type });
  }
  updateClean(data: any) {
    const url = this.hostUrl + '/update-clean-by-user';
    return this.httpClient.postSync(url, data);
  }
  updateRead(data: any) {
    const url = this.hostUrl + '/update-read-by-user';
    return this.httpClient.postSync(url, data);
  }
  claimDaily(data: any) {
    // New! param
    const url = this.hostUrl + '/claim-daily';
    return this.httpClient.postSync(url, data);
  }
  checkClaimDaily() {
    const url = this.hostUrl + '/check-claim-daily';
    return this.httpClient.getSync(url);
  }
  updatePasswordPersonal(data: any) {
    const url = this.hostUrl + '/update-password';
    return this.httpClient.putSync(url, data);
  }
  getById(id: string) {
    const url = this.hostUrl + `/get-by-id/${id}`;
    return this.httpClient.getSync(url);
  }
  getByEmployee(id: string) {
    const url = this.hostUrl + `/get-by-employee/${id}`;
    return this.httpClient.getSync(url);
  }
  getWorkTime() {
    const url = this.hostUrl + `/get-worktime`;
    return this.httpClient.getSync(url);
  }
  getNotificationList(params: any) {
    const url = this.hostUrl + `/get-notification-by-user`;
    return this.httpClient.postSync(url, params);
  }
  getDataOrgChart() {
    const url = this.hostUrl + `/get-data-org-chart`;
    return this.httpClient.getSync(url);
  }
  getList(params: any) {
    const url = this.hostUrl;
    return this.httpClient.getSync(url, { params });
  }

  // this new API to get all active users excluding external users and allow to exclude more specified users
  getListV2(params: any) {
    const url = '/api/user/v2';
    return this.httpClient.getObservable(url, params);
  }

  delete(userId: string) {
    const url = this.hostUrl + `/${userId}`;
    return this.httpClient.deleteSync(url);
  }

  activate(data: any) {
    const url = this.hostUrl + `/change-status`;
    return this.httpClient.putSync(url, data);
  }
  hasTracking(data: any) {
    const url = this.hostUrl + `/change-tracking-status`;
    return this.httpClient.putSync(url, data);
  }
  updateReviewableStatus(data: any) {
    const url = this.hostUrl + `/change-reviewable-status`;
    return this.httpClient.putSync(url, data);
  }
  updateHasAnnualLeave(data: any) {
    const url = ApiEndpoint.User.HasAnnualLeave();
    return this.httpClient.putObservable(url, data).toPromise();
  }
  updateInfoWorkingSetting(data: any) {
    const url = this.hostUrl + `/update-time-working`;
    return this.httpClient.putSync(url, data);
  }

  getInfoWorkingSetting(data: any) {
    const url = this.hostUrl + `/get-time-working`;
    return this.httpClient.getSync(url, data);
  }
  checkPermissionDashboard(id) {
    const url = this.hostUrl + `/check-permission-view-dashboard?employeeId=${id}`;
    return this.httpClient.getSync(url);
  }

  checkExpirationFotgotPassword(forgotPasswordId: string) {
    const url = this.hostUrl + `/forgot-passwords/${forgotPasswordId}/expires`;
    return this.httpClient.getSync(url);
  }

  sendVerificationEmailForgotPassword(data: any) {
    const url = this.hostUrl + `/forgot-passwords`;
    return this.httpClient.postSync(url, data);
  }

  updatePasswordWhenUserForgot(data: any) {
    const url = this.hostUrl + `/forgot-passwords/${data.forgotPasswordId}`;
    return this.httpClient.putSync(url, data);
  }

  getBenefit(userId: string): Promise<Benefit[]> {
    const url = `${this.rootUrl}/${userId}/benefits`;
    return this.httpClient.getObservable(url).toPromise();
  }

  getRewardHistory(params: any) {
    const url = this.hostUrl + `/get-reward-history`;
    return new Promise((resolve, reject) =>
      this.httpClient.getSync(url, { params }).then((resp: any) => {
        if (resp.id && resp.id < 0) {
          Toastr.error(resp.message || resp.Message);
        } else {
          resolve(resp);
        }
      }),
    );
  }

  getChildDepartmentByUser(userId: string) {
    const url = this.hostUrl + `/get-child-department?userId=${userId}`;
    return this.httpClient.getSync(url);
  }

  registerBenefit(userId: string, requestData: any) {
    const url = `${this.rootUrl}/${userId}/benefit/register`;
    return this.httpClient.postObservable(url, requestData).toPromise();
  }

  getDepartments(userId: string) {
    const url = `${this.hostUrl}/${userId}/departments`;
    return this.httpClient.get(url);
  }

  setDepartmentGroupsBehavior(departmentGroups: DepartmentGroupProfile[]) {
    this.departmentGroupProfile.next(departmentGroups);
  }

  getDepartmentGroupBehavior() {
    return this.departmentGroupProfile.asObservable();
  }

  getReviewPerformanceForm(userId: string, request: any) {
    const url = `${this.rootUrl}/${userId}/review-forms`;
    return this.httpClient.getObservable(url, request).toPromise();
  }

  getMemberToCloneRow(request: any) {
    const url = `${this.rootUrl}/member-to-clone-rows`;
    return this.httpClient.getObservable(url, request);
  }

  getDepartmentAssociateUser(userId) {
    const url = `${this.rootUrl}/${userId}/department-associate-users`;
    return this.httpClient.getObservable(url);
  }

  updateGoalValueAnnualMonth(userId: string, data: any) {
    const url = ApiEndpoint.User.Review.UpdateGoalValueAnnualMonth(userId, data);
    return this.httpClient.putObservable(url, data, false);
  }

  updateProgressPercentGoalRowAnnualMonth(userId: string, data: any) {
    const url = ApiEndpoint.User.Review.UpdateProgressPercentGoalRowAnnualMonth(userId, data);
    return this.httpClient.putObservable(url, data, false);
  }

  updateGoalSectionAnnualMonth(userId: string, data: any) {
    const url = ApiEndpoint.User.Review.UpdateGoalSectionAnnualMonth(userId, data.id);
    return this.httpClient.putObservable(url, data);
  }

  getInfoCheckInOutRequest(date: string): Promise<Benefit[]> {
    const url = `${this.rootUrl}/checkinout-request/get-info`;
    return this.httpClient.postObservable(url, { date }).toPromise();
  }

  registerCheckInOutRequest(model: CreateCheckInCheckOutRequest): Promise<Benefit[]> {
    const url = `${this.rootUrl}/checkinout-request/register`;
    return this.httpClient.postObservable(url, model).toPromise();
  }
}
