import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService, Loading } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class SupplierEquipmentService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/supplier-equipment';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getBySupplier(params?: any, successCallback?: (params?: any) => void) {
    Loading.show();
    const url = 'get_by_supplier';
    return this.getByUrl(url, params, successCallback);
  }

  importEquipmentQuotation(data: any, successCallback: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const formData = new FormData();
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) {
        continue;
      }
      formData.append(prop, data[prop]);
    }
    this.postByUrl(`create-by-import?supplierId=${data.supplierId}`, formData, successCallback, failedCallback, completeCallback);
  }
}
