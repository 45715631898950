import { Department, UserModel } from '..';
import {
  ReviewFormAppraiserStatusEnum,
  ReviewFormAppraiserStatusFormEnum,
  ReviewFormMemberStatusEnum,
  ReviewFormMemberStatusFormEnum,
  ReviewFormStatusEnum,
} from '../enums/review-form.enum';
import { ReviewFormMember } from './review-form-member.model';
import { ReviewSection } from './review-section.model';
import { ReviewTemplate } from './review-template.model';

export class ReviewForm {
  id: string;
  title: string;
  month: number;
  year: number;
  formStatus: ReviewFormStatusEnum;
  totalMember: number;
  totalCompleted: number;
  totalVerifying: number;
  totalVerified: number;
  reviewPerformanceFormMembers: ReviewFormMember[]; // Only for DTO
  reviewPerformanceTemplateId: string; // {Create,Update}ReviewForm
  reviewPerformanceTemplate?: ReviewTemplate; // {Create,Update}ReviewForm
  departmentList: { id: string; name: string; type: number }[];
  templateTitle: string; // ViewReviewForm
  templateId: string; // ViewReviewForm
  isEditable: boolean; // ViewReviewForm
  layerGuidelineDropdowns: LayerGuidelineDropdown[];
}

export class ReviewFormMembersGroupedByDepartment {
  id: string;
  name: string;
  members: ReviewFormMember[];
  isExpanded: boolean;
  isSelected: boolean;
  isSelectable: boolean;
  isIndeterminated: boolean;
  isDisplayPdfHeader: boolean;
  isSelectedLevel: boolean;

  constructor(departmentId: string, name: string, members?: ReviewFormMember[], isExpanded: boolean = false) {
    this.id = departmentId;
    this.name = name;
    this.members = members;
    this.isExpanded = isExpanded;
    this.isSelected = false;
    this.isSelectedLevel = false;
    this.isSelectable = members?.some(m => m.statusForm === ReviewFormMemberStatusFormEnum.New);
    this.isDisplayPdfHeader = members?.some(m => m.statusForm === ReviewFormMemberStatusFormEnum.Completed);
  }
}

export class ReviewNotifyAppraiser {
  formId: string;
  memberId: string;
  appraiserId: string;

  constructor(formId: string, memberId: string, appraiserId: string) {
    this.formId = formId;
    this.memberId = memberId;
    this.appraiserId = appraiserId;
  }
}

export class ReviewPerformanceFormDetailUser {
  reviewPerformanceSections: ReviewSection[];
  reviewPerformanceFormMember: ReviewPerformanceFormMemberForUser;
  reviewPerformanceFormMemberIds: string[];
  reviewPerformanceFormAppraiserIds: string[];
  listMonth: ReviewPerformanceFormDetailUserMonth[];
  reviewPerformanceFormMemberId: string;
  reviewPerformanceFormAppraiserId: string;
  reviewPerformanceFormMemberOldId: string;
  reviewPerformanceFormPeerId: string;
  reviewPerformanceFormPeerIds: string[];
  hasRating: boolean;
  hasVerify: boolean;
  hasFinalizing: boolean;
  hasCompleted: boolean;
  hasDisplayAvatar: boolean;
  hasChangeGoalCompleted: boolean;
  isAppraiser: boolean;
  isMemberVerified: boolean;
  isMainAppraiser: boolean;
  isPassProbationResult: boolean;
  hasTemplteProbation: boolean;
  hasTemplateCommentOld: boolean;
  displaAdditionalComment: boolean;
  hasChangeCommentMember: boolean;
  hasChangeCommentAppraiser: boolean;
  hasChangeCommentAdditional: boolean;
  hasChangeResultProbation: boolean;
  summary: string;
  workAchievement: string;
  selfAssessmentOfLastYearGoalAccomplishment: string;
  selfAssessmentOfGoalComingYear: string;
  summaryAppraiser: string;
  workAchievementAppraiser: string;
  directionForTheComingYearAppraiser: string;
  summary2: string;
  summary3: string;
  workAchievement2: string;
  selfAssessmentOfLastYearGoalAccomplishment2: string;
  selfAssessmentOfGoalComingYear2: string;
  summaryAppraiser2: string;
  workAchievementAppraiser2: string;
  directionForTheComingYearAppraiser2: string;
  expectationForTheComingYear: string;
  additionalCommentAppraiser: string;
  salaryIncreaseSuggestionAppraiser: number;
  displayHasNoRating: boolean;
  displayGoalHistory: boolean;
  activeCommentAppraisee: boolean;
  activeCommentAppraiseer: boolean;
  goalHistory: any;
  activeCommentPeer: boolean;
}
export class ReviewPerformanceFormDetailUserMonth {
  month: number;
  year: number;
  reviewPerformanceFormMemberId: string;
}
export class ReviewPerformanceFormMemberForUser {
  id: string;
  userId: string;
  reviewPerformanceTemplateId: string;
  reviewPerformanceFormId: string | null;
  statusForm: ReviewFormMemberStatusFormEnum;
  status: ReviewFormMemberStatusEnum;
  user: UserModel;
  reviewPerformanceFormAppraisers: ReviewPerformanceFormAppraiserForUser[];
}
export class ReviewPerformanceFormAppraiserForUser {
  id: string;
  userId: string;
  reviewPerformanceFormMemberId: string;
  statusForm: ReviewFormAppraiserStatusFormEnum;
  status: ReviewFormAppraiserStatusEnum;
  user: UserModel;
}

export class ReviewFormProgress {
  progress: ReviewProgress;
  children: ReviewSectionProgress[];
}

export class ReviewSectionProgress {
  id: string;
  title: string;
  progress: ReviewProgress;
  children: ReviewSectionProgress[];
  totalChildrenProgress: ReviewProgress;

  constructor(section: ReviewSection, progress: ReviewProgress) {
    this.id = section?.id;
    this.title = section?.title;
    this.progress = progress;
  }
}

export class ReviewProgress {
  totalRequiredRows: number;
  totalFilledRequiredRows: number;
  percentage: number;

  constructor(filled: number, total: number) {
    this.totalFilledRequiredRows = filled;
    this.totalRequiredRows = total;
    this.percentage = total > 0 ? Math.floor((filled * 100) / total) : 0;
  }
}
export class ReviewProgressFormTotal {
  progressColor: any;
  currentStep: ReviewProgressFormDetail;
  steps: ReviewProgressFormDetail[];
  progressFormParts: ReviewProgressFormPart[];
  percent: number;
  stepCompleted: number;
  stepTotal: number;
}

export class ReviewProgressFormDetail {
  step: number;
  title: string;
  description: string;
  color: string;
  status: number;
  actionTime: string;
  statusName: string;
  fullName: string;
  avatar: string;
  hasAction: boolean;
}
export class ReviewProgressFormPart {
  color: string;
  percent: number;
  stepCompleted: number;
  stepTotal: number;
  status: number;
  statusName: string;
}
export class SearchLayoutGuideline {
  employeePositionId: string;
}

export class LayerGuideline {
  title: string;
  listPositionLevel: EmployeePositionLevelDropdownlist[];
  listDetail: LayerGuidelineDetail[];
}

export class LayerGuidelineDetail {
  colSpan: number;
  rowSpan: number;
  name: string;
  knowledgeAndSkills: string;
  jobComplexityAndContribution: string;
  professionalCharacterCultureFit: string;
  englishProficiency: string;
  levels: LayerGuidelineSummary[];
}

export class LayerGuidelineSummary {
  level: number;
  minYoe: number | null;
  maxYoe: number | null;
  manageSizeMin: number | null;
  manageSizeMax: number | null;
}

export class EmployeePositionLevelDropdownlist {
  employeePositioId: string | null;
  layerDetailId: string | null;
  layerLevelId: string | null;
  layerId: string | null;
  displayName: string;
  employeePositionName: string;
  layerName: string;
  layerPositionName: string;
  layerLevel: number;
  knowledgeAndSkills: string;
  jobComplexityAndContribution: string;
  professionalCharacterCultureFit: string;
  englishProficiency: string;
}

export class SearchReviewFormLevel {
  reviewFormId: string | null;
  keyword: string;
  year: number | null;
  departmentId: string | null;
  date: Date | null;
}

export class ReviewPerformanceLevel {
  data: ReviewPerformanceLevelDetail[];
  layerGuidelineDropdowns: LayerGuidelineDropdown[];
  departments: Department[];
  totalAppraisees: number;
  totalAppraiseesFilled: number;
  hasEdit: boolean;
}

export class ReviewPerformanceLevelDetail {
  employeeId: string | null;
  userId: string | null;
  reviewFormMemberId: string | null;
  joinedDate: string;
  seniorityRestaff: number;
  fullName: string;
  avatarUrl: string;
  departmentName: string;
  currentPosition: string;
  currentLevelName: string;
  resultPosition: string;
  resultLevelName: string;
  currentPositionId: string | null;
  currentLevelDetailId: string | null;
  currentPositionOrder: number | null;
  resultLayerId: string | null;
  resultPositionId: string | null;
  resultLevelDetailId: string | null;
  resultPositionOrder: number | null;
  createDate: string;
  hasEdit: boolean;
  totalScore: number;
  listSection: ReviewSectionLevel[];
  hasChangePosition: boolean;
  increaseLevel: number;
}

export class ReviewSectionLevel {
  name: string;
  score: number;
  proportion: number;
}

export class LayerGuidelineDropdown {
  title: string;
  layerId: string;
  employeePositions: EmployeePositionDropdown[];
}

export class EmployeePositionDropdown {
  layerId: string | null;
  employeePositionName: string;
  employeePositioId: string | null;
  layerDetails: LayerDetailDropdownlist[];
  order: number;
}

export class LayerDetailDropdownlist {
  employeePositioId: string | null;
  layerDetailId: string | null;
  layerId: string | null;
  layerName: string | null;
  displayName: string;
  layerPositionName: string;
  layerLevel: number;
  knowledgeAndSkills: string;
  jobComplexityAndContribution: string;
  professionalCharacterCultureFit: string;
  englishProficiency: string;
  minYoe: number | null;
  maxYoe: number | null;
  manageSizeMin: number | null;
  manageSizeMax: number | null;
  order: number;
}
