import { NgModule } from '@angular/core';
import { PackageShareModule } from 'projects/intranet/hrm-lib/lib/share';
import { CommonModule } from '@angular/common';
import { CreateLeaveComponent } from './components';
import { CreateBookingComponent } from './pages/user-booking/create-booking/create-booking.component';

@NgModule({
  imports: [CommonModule, PackageShareModule],
  declarations: [CreateLeaveComponent, CreateBookingComponent],
  exports: [],
  entryComponents: [CreateLeaveComponent, CreateBookingComponent],
  providers: [],
})
export class UserEntryModule {}
