export class KanbanBoardModel<TTask> {
  name: string;
  columns: KanbanColumn<TTask>[];

  constructor(bName: string, bColumns: KanbanColumn<TTask>[] = []) {
    this.name = bName;
    this.columns = bColumns;
  }
}

export class KanbanColumn<TTask> {
  column: string | number;
  isGroupColumn: boolean;
  tasks: TTask[];

  constructor(kColumn: string | number, kIsGroup: boolean) {
    this.column = kColumn;
    this.isGroupColumn = kIsGroup;
    this.tasks = [];
  }
}

export class KanbanTask<TTask> {
  task: TTask;

  constructor(kTask: TTask) {
    this.task = kTask;
  }
}
