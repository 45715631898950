import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckinCheckoutClaimModalComponent } from '@intranet/lib/components/checkin-checkout-claim-modal/checkin-checkout-claim-modal.component';
import { CheckInCheckOutConstants } from '@intranet/lib/config/checkincheckout.constant';
import { UserService, WidgetTrackingTimeDetailDto, WidgetWeeklyTrackingTimeDto } from '@intranet/lib/data-access';
import { ModalOption, ModalService, ModalSize } from '@intranet/lib/settings';

@Component({
  selector: 'app-widget-tracking-weekly',
  templateUrl: './widget-tracking-weekly.component.html',
  styleUrls: ['./widget-tracking-weekly.component.scss']
})
export class WidgetTrackingWeeklyComponent implements OnInit {

  checkInCheckOutConstants = CheckInCheckOutConstants;
  checkInCheckOutStatusEnum = this.checkInCheckOutConstants.Status;
  checkInCheckOutStatusDisplay = this.checkInCheckOutConstants.CheckInCheckOutStatus.DisplayClient;

  @Input()
  listWeekly: WidgetWeeklyTrackingTimeDto[];
  modalOption: ModalOption = {
    size: ModalSize.lg,
  };
  isVisibleExplanationModal = false;
  dataSelected = null;


  constructor(private userService: UserService, private modalService: ModalService) { }

  ngOnInit() {
  }

  openExplanationModal(data: any) {
    this.isVisibleExplanationModal = true;
    this.dataSelected = data;
  }

  async openPopupRegistration(item: WidgetTrackingTimeDetailDto) {
    const resultData = await this.userService.getInfoCheckInOutRequest(item.startTime);
    if (resultData) {
      const modal = this.modalService.open(CheckinCheckoutClaimModalComponent, this.modalOption);
      modal.componentInstance.title = 'Check in/out time request';
      modal.componentInstance.date = item.startTime;
      modal.componentInstance.data = resultData;
      modal.result.then(res => {
        if (res) {
          if (res.data) {
            item.checkInCheckOutStatus = this.checkInCheckOutConstants.Status.New;
            item.hasCheckInCheckOutRequest = true;
          }
        }
      });
    }
  }
}
