import { Component, OnInit } from '@angular/core';
import { Employee, EmployeeBirthday, EmployeeService, NewsFeed, NewsFeedTypeEnum, PublicityLevel, UserModel, WallNewsFeedService } from '@intranet/lib/data-access';
import { UrlConstant } from '@intranet/index';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { ModalService, ModalSize } from '@intranet/index';
import { UserListModalComponent } from '../user-list-modal/user-list-modal.component';
@Component({
  selector: 'app-newsfeed-birthday',
  templateUrl: './birthday.component.html',
  styles: [],
})
export class BirthdayComponent implements OnInit {
  public birthdays: EmployeeBirthday[];
  isLoading: boolean;
  sent: boolean;
  urlConstant = UrlConstant;

  constructor(
    private modalService: ModalService,
    private employeeService: EmployeeService,
    private newsFeedService: WallNewsFeedService,
    private pubsub: PubSubService
  ) { }

  ngOnInit() {
    this.getEmployeeBirthday();
  }

  getEmployeeBirthday() {
    const params = {
      noLoading: true,
    };
    this.isLoading = true;
    this.employeeService.getBirthday(
      params,
      (res: EmployeeBirthday[]) => {
        this.birthdays = res;
      },
      error => { },
      () => {
        this.isLoading = false;
      },
    );
  }

  sendCelebrate(item: EmployeeBirthday) {
    if (item.userId) {
      const feed = new NewsFeed();
      feed.title = 'Happy birthday';
      feed.description = item.fullName;
      feed.targetUserId = item.userId;
      feed.newsFeedTypeId = NewsFeedTypeEnum.BIRTHDAY;
      feed.publicityLevel = PublicityLevel.PUBLIC;
      feed.isShow = false;
      feed.beginDate = new Date();
      feed.allowComment = true;
      feed.allowLike = true;

      this.newsFeedService.sendCelebrateBirthday(feed, res => {
        if (res && res.id) {
          item.reactionCount++;
          if(!item.userCount){
            item.userCount = 0;
          }
          item.userCount++;
        }
      });
    }
  }

  onShowWishedItems(item: EmployeeBirthday) {
    if (item.userId) {
      this.employeeService.getBirthdayWishes({ userId: item.userId }, (items: UserModel[]) => {
        const modal = this.modalService.open(UserListModalComponent, {
          size: ModalSize.md,
        });
        modal.componentInstance.title = 'Birthday wishes';
        modal.componentInstance.items = items;
      });

    }
  }
}
