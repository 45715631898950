import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/index';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  constructor(private httpClient: HttpClientService) {
  }

  getWidgetAttendance(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetAttendance();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetAsset(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetAsset();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetTodayLeave(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetTodayLeave();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetAttendanceHour(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetAttendanceHour();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetTrackingTime(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetTrackingTime();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetLeave(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetLeave();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetCompany(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetCompany();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetWorkExperience(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetWorkExperience();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetEquipment(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetEquipment();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getHRInformation(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetHRInformation();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetEmployeeGender(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetEmployeeGender();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetSkillTeam(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetSkillTeam();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetMemberByTeam(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetMemberByTeam();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetMemberForLeave(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetMemberForLeave();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetTodo(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetTodo();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetSurvey(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetSurvey();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetNewcomer(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetNewcomer();
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetBenefits(request: any) {
    const url = ApiEndpoint.Widget.GetWidgetBenefits();
    return this.httpClient.getObservable(url, request).toPromise();
  }

  getWidgetBenefitMember(params: any, isLoading = false) {
    const url = ApiEndpoint.Widget.GetWidgetBenefitMember(params.benefitId);
    return this.httpClient.getObservable(url, params, isLoading).toPromise();
  }

  getWidgetReviewForms(request: any) {
    const url = ApiEndpoint.Widget.GetWidgetReviewForms();
    return this.httpClient.postObservable(url, request, false).toPromise();
  }

  getWidgetReviewGoal() {
    const url = ApiEndpoint.Widget.GetWidgetReviewGoal();
    return this.httpClient.getObservable(url, {}, false);
  }
}
