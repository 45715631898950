<nz-card [nzTitle]="isChild ? ('Section ' + section?.title) : null" [nzExtra]="scoreTemplate">
  <nz-table [nzTemplateMode]="true">
    <thead>
      <tr>
        <th *ngFor="let columnTemplateDetail of section?.columnTemplateDetails"
          [ngClass]="{'criteria-header-column': columnTemplateDetail.type === columnTemplateTypeEnum.Criteria}">
          {{columnTemplateDetail?.title}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of section?.rows">
        <td *ngFor="let column of row?.columns" [ngClass]="{'criteria-header-column': column?.type === columnTemplateTypeEnum.Criteria}">
          <ng-container *ngFor="let value of column?.values">
            <p class="value-container">

              <ng-container
                *ngIf="value?.type !== columnTemplateTypeEnum.Criteria && value?.type !== columnTemplateTypeEnum.Proportion && section.type !== sectionTypeEnum.Goal">
                <app-avatar [size]="25" [avatarSource]="formPersonInformation[value?.reviewPerformanceFormMemberId] ?
                                    formPersonInformation[value?.reviewPerformanceFormMemberId]?.avatarUrl :
                                    formPersonInformation[value?.reviewPerformanceFormAppraiserId]?.avatarUrl" [avatarText]="formPersonInformation[value?.reviewPerformanceFormMemberId] ?
                                    formPersonInformation[value?.reviewPerformanceFormMemberId]?.fullName :
                                    formPersonInformation[value?.reviewPerformanceFormAppraiserId]?.fullName">
                </app-avatar>
              </ng-container>
              {{value.value}}
              <span *ngIf="value?.type === columnTemplateTypeEnum.Proportion"> %</span>

            </p>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-card>

<ng-template #scoreTemplate>
  <ng-container *ngIf="section.type === sectionTypeEnum.Normal">
    <span class="mr-3">SECTION SCORE</span>
    {{section?.sectionByMember?.scoreNumber | number:'1.1-1'}}
  </ng-container>

</ng-template>
