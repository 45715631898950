<div id="rt-content-container-wall">
  <div id="rt-container">
    <app-news-feed [feeds]="feeds" (loadMore)="onLoadMore()" [isLoading]="feedIsLoading" [disabledLoading]="disabledLoading">
      <app-health-check-claim claimHealthCheck></app-health-check-claim>
      <app-claim-daily claimDaily></app-claim-daily>
      <app-newsfeed-create-post *hasPermission="['wallnewsfeed', 'createpublicpost']" createPost></app-newsfeed-create-post>
    </app-news-feed>
  </div>
</div>

<app-poster-display-modal
  *ngIf="posterDisplayInfo"
  [posterInfo]="posterDisplayInfo"
  (close)="closePoster()"
  (clickLink)="closePoster()"
></app-poster-display-modal>
