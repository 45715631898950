<nz-card [nzBordered]="false">
  <div class="widget-header" [ngClass]="{ 'pt15': isSidebar }">
    <div class="widget-title">{{widget?.data?.title || 'Review Forms'}}</div>
    <div class="widget-control widget-controll-sm-fully">
      <ng-container *ngIf="isSidebar">
        <div class="text-right">
          <a href="" class="view-all" (click)="viewList()">View all</a>
        </div>
      </ng-container>
      <ng-container *ngIf="!isSidebar">
        <form [formGroup]="formGroup" class="form-group-controls">
          <ng-container *ngIf="!isSidebar && widget?.data?.isDisplayDataManage">
            <nz-date-picker nzFormat="yyyy" formControlName="year" name="b-date" nzMode="year" class="date-input" nzPlaceHolder="Select year"></nz-date-picker>
            <nz-select class="search-department" nzShowSearch nzAllowClear formControlName="departmentId" (ngModelChange)="modelDepartmentChanged($event)"
              nzPlaceHolder="Select a group">
              <nz-option *ngFor="let departmentAssociateUser of departmentAssociateUsers" [nzLabel]="departmentAssociateUser.name" [nzValue]="departmentAssociateUser.id">
              </nz-option>
            </nz-select>
            <nz-select class="search-name" nzServerSearch nzShowSearch nzAllowClear formControlName="userId" nzPlaceHolder="Select a user"
              (nzOnSearch)="searchUserOptions($event)">
              <nz-option *ngFor="let userOption of userOptions" [nzLabel]="userOption.fullName" [nzValue]="userOption.id">
              </nz-option>
            </nz-select>
          </ng-container>
        </form>
      </ng-container>
    </div>
  </div>
  <div class="review-member scroll-bar">
    <ng-container *ngIf="!isLoading else loadingTemplate">
      <ng-container *ngIf="reviewForms.length > 0 else emptyReviewForms">
        <ng-container *ngIf="!isPhoneView">
          <nz-list [nzHeader]="''">
            <nz-list-item *ngFor="let formMember of reviewForms">
              <div class="review-member-item">
                <div class="review-member-item-column">
                  <p>Appraisee</p>
                  <div class="pl-1">
                    <app-avatar [avatarSource]="formMember?.avatarUrl" [size]="avatarSize"></app-avatar>
                    <div *ngIf="!isSidebar">
                      <p class="fullname">{{formMember?.fullName}}</p>
                      <p class="position-title">{{formMember?.position}}</p>
                      <nz-tag nzColor="geekblue">{{formMember?.department}}</nz-tag>
                    </div>

                  </div>
                </div>

                <div class="review-member-item-column">
                  <p>Appraiser</p>
                  <div *ngFor="let appraiser of formMember?.appraisers">
                    <app-avatar [avatarSource]="appraiser?.avatarUrl" [size]="avatarSize"></app-avatar>
                    <div *ngIf="!isSidebar" class="pl-1">
                      <p class="fullname">{{appraiser?.fullName}}</p>
                      <p class="position-title">{{appraiser?.position}}</p>
                      <nz-tag nzColor="geekblue">{{appraiser?.department}}</nz-tag>
                    </div>
                  </div>
                </div>

                <div class="review-member-item-column">
                  <p>Status</p>
                  <span [ngClass]="formMemberStatusFormDisplay[formMember?.statusForm]?.color">
                  {{formMemberStatusFormDisplay[formMember?.statusForm]?.name}}
                  </span>
                </div>
                <div class="review-member-item-column">
                  <p>Form</p>
                  <span >
                  {{formMember.reviewPerformanceFormName}}
                  </span>
                </div>
                <div class="review-member-item-action">
                  <ng-container [ngTemplateOutlet]="formMemberActionButtonsTpl" [ngTemplateOutletContext]="{ $implicit: formMember }"></ng-container>
                </div>
              </div>
            </nz-list-item>
          </nz-list>
        </ng-container>
        <app-review-form-members-mobile *ngIf="isPhoneView && !isSidebar" [actionButtonsTpl]="formMemberActionButtonsTpl" [avatarSize]="avatarSize"
          [reviewForms]="reviewForms"></app-review-form-members-mobile>
      </ng-container>
    </ng-container>
  </div>
</nz-card>

<ng-template #loadingTemplate>
  <nz-spin [nzIndicator]="indicatorTemplate"></nz-spin>
</ng-template>

<ng-template #emptyReviewForms>
  <div class="empty-list" *ngIf="!isLoading">
    <nz-empty></nz-empty>
  </div>
</ng-template>
<ng-template #indicatorTemplate>
  <i nz-icon nzType="loading"></i>
</ng-template>

<ng-template #formMemberActionButtonsTpl let-formMember>
  <ng-container *ngIf="formMember?.statusForm === formMemberStatusFormEnum.Completed && !isSidebar">
    <div class="fs16 no-button pointer text-warning not-mobile-props" nz-tooltip nzTooltipTitle="Pdf File" (click)="exportPdfFile(formMember)"
      *hasPermission="['reviewperformanceform','exportpdfonuserpage']">
      <i nz-icon nzType="file-pdf" noconfirm nzTheme="outline" class="action-icon" ></i>
    </div>
  </ng-container>

  <div *ngIf="!isPhoneView" class="fs16 no-button pointer text-info not-mobile-props" nz-tooltip nzTooltipTitle="View detail" (click)="viewDetail(formMember)">
    <i nz-icon nzType="eye" noconfirm nzTheme="outline" class="action-icon"></i>
  </div>
  <!-- <ng-container
    *ngIf="formMember?.statusForm === formMemberStatusFormEnum.Completed || formMember?.statusForm === formMemberStatusFormEnum.Verified || formMember?.statusForm === formMemberStatusFormEnum.Finalizing">
    <div class="fs16 no-button pointer text-success" *ngIf="!isPhoneView && (formMember?.groupDepartments | checkRole: 'reviewperformancesummary':'view')" nz-tooltip
      nzTooltipTitle="View summary" (click)="openDiscussion(formMember)">
      <i nz-icon nzType="solution" noconfirm nzTheme="outline"></i>
    </div>
  </ng-container> -->
</ng-template>
