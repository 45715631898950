import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root',
})
export class Sweetalert2Service {
  constructor() { }

  show(data, option?, callback?) {
    Swal.fire({
      title: data.title,
      text: data.text,
      icon: data.icon,
      ...option,
    }).then(result => {
      if (callback) {
        callback(result);
      }
    });
  }
  showSlow(data, option?, callback?) {
    const timer = {
      timer: 3000,
      timerProgressBar: true,
    };
    option = { ...option, ...timer };
    setTimeout(() => {
      Swal.fire({
        title: data.title,
        text: data.text,
        icon: data.icon,
        ...option,
      }).then(result => {
        if (callback) {
          callback(result);
        }
      });
    }, 1200);
  }
}
