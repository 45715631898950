import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modal-footer',
  templateUrl: './modal-footer.component.html',
  styles: [],
})
export class ModalFooterComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
