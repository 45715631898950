import { Injectable } from '@angular/core';
import { CookieStorage } from './cookieStorage';
import { AppInjector } from '../settings/services/app-injector';

@Injectable({
  providedIn: 'root',
})
export class CookieHelper {
  static authenKey = 'aluha.intranet.pro';

  static createStore(key: string, data: any) {
    if (data !== null && data !== undefined) {
      const cookie = AppInjector.getService<CookieStorage>(CookieStorage);
      cookie.createStore(key, data.token);
    }
  }
  static removeStore(key: string) {
    if (key) {
      const cookie = AppInjector.getService<CookieStorage>(CookieStorage);
      cookie.removeStore(key);
    }
  }
  static createCookieAccess(data: any) {
    if (data) {
      const cookie = AppInjector.getService<CookieStorage>(CookieStorage);
      cookie.createStoreAccess('AccessPage', data);
    }
  }

  static getCookieAccess() {
    const cookie = AppInjector.getService<CookieStorage>(CookieStorage);
    return cookie.getStore('AccessPage');
  }

  static getCookieToken(key = this.authenKey) {
    const cookie = AppInjector.getService<CookieStorage>(CookieStorage);
    return cookie.getStore(key);
  }
}
