import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-leave-tooltip',
  templateUrl: './leave-tooltip.component.html',
  styleUrls: ['./leave-tooltip.component.scss']
})
export class LeaveTooltipComponent implements OnChanges {
  @Input() data: any;
  @Input() isTotalLeave = false;
  @Input() isUpaidLeave = false;
  @Input() isSickLeave = false;

  dataSource: any[] = [];

  constructor() { }

  ngOnChanges() {
    if (this.isUpaidLeave) {
      this.buildingUpaidDataSource();
      return;
    }
    if (this.isTotalLeave) {
      this.buildingTotalLeaveDataSource();
      return;
    }
    if (this.isSickLeave) {
      this.buildingSickDataSource();
      return;
    }
    this.buildingDayUsedDataSource();
  }

  private buildingTotalLeaveDataSource() {
    this.dataSource = [
      { title: 'Current total annual leaves', value: this.data.totalDay },
      { title: 'Last year\'s transferred days', value: this.data.prevYearTranferDay },
      { title: 'Sponsored days', value: this.data.sponsorDay }
    ];
  }
  private buildingUpaidDataSource() {
    this.dataSource = [
      { title: 'Unpaid leaves', value: this.data.unpaidDay },
      { title: 'Statutory leaves', value: this.data.unpaidDaySocialInsurance },
    ];
  }

  private buildingSickDataSource() {
    this.dataSource = [
      { title: 'Sick leaves', value: this.data.sickLeave },
      { title: 'Sick leaves used', value: this.data.sickLeaveUsed },
    ];
  }
  private buildingDayUsedDataSource() {
    let usedDay = this.data.usedDay;
    let sponsorDayUsed = this.data.sponsorDayUsed;

    if (usedDay < 0) {
      sponsorDayUsed += usedDay;
      usedDay = 0;
    }

    this.dataSource = [
      { title: 'Current used annual leaves', value: usedDay },
      { title: 'Transferred days', value: this.data.tranferDay },
      { title: 'Sold days', value: this.data.sellDay },
      { title: 'Sponsored days used', value: sponsorDayUsed },
    ];
  }
}
