import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BenefitConstants } from '@intranet/lib/config/benefit.constants';
import { UserService } from '@intranet/lib/data-access';
import { Benefit, BenefitBonus, BenefitResult } from '@intranet/lib/data-access/models/benefit.model';
import { BenefitUserClaimModalDisplayModeEnum } from '@intranet/lib/data-access/models/enums/benefit.enum';
import { CommonHelper, SecurityHelper } from '@intranet/lib/helpers';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import { ModalOption, ModalService, ModalSize, Toastr } from '@intranet/lib/settings';
import { BenefitUserClaimModalComponent } from './benefit-user-claim-modal/benefit-user-claim-modal.component';

@Component({
  selector: 'app-benefit-user',
  templateUrl: './benefit-user.component.html',
  styleUrls: ['./benefit-user.component.scss']
})
export class BenefitUserComponent implements OnInit {
  @Output() loaded = new EventEmitter();

  @Input() set benefits(value: Benefit[]) {
    this.autoLoadData = false;
    this.benefitData = value;
    this.benefitBonuses = this.transformBenefitListToBenefitBonusList(this.benefitData);
  }

  get benefits() {
    return this.benefitData;
  }

  benefitData: Benefit[];
  autoLoadData: boolean = true;

  benefitBonuses: BenefitBonus[] = [];
  currentUser: any = null;

  benefitConstants = BenefitConstants;

  readonly claimModalSize: ModalOption = {
    size: ModalSize.lg,
  };

  constructor(
    private userService: UserService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.currentUser = SecurityHelper.getStoreAuthen();

    if (this.autoLoadData) {
      this.loadData().then(() => this.loaded.emit());
      return;
    }

    this.loaded.emit();
  }

  async loadData() {
    try {
      const benefits = await this.userService.getBenefit(this.currentUser.id);

      this.benefitBonuses = this.transformBenefitListToBenefitBonusList(benefits);
      this.benefits = benefits;
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
  }

  async viewBenefitResult(benefitBonus: BenefitBonus, benefitResult: BenefitResult) {
    try {
      if (!benefitBonus?.benefit || !benefitResult) {
        return;
      }

      const modalRef = this.modalService.open(BenefitUserClaimModalComponent, this.claimModalSize);
      modalRef.componentInstance.benefit = benefitBonus.benefit;
      modalRef.componentInstance.benefitBonus = benefitBonus;
      modalRef.componentInstance.benefitResult = benefitResult;
      modalRef.componentInstance.displayMode = BenefitUserClaimModalDisplayModeEnum.View;

      modalRef.result.then((res: boolean) => {
        if (res) {
          this.loadData();
        }
      });
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
  }

  async claimBenefitResult(benefitBonus: BenefitBonus, benefitResult: BenefitResult) {
    try {
      if (!benefitResult.registrable) {
        Toastr.error(`This benefit is not claimable.`);
        return;
      }

      const modalRef = this.modalService.open(BenefitUserClaimModalComponent, this.claimModalSize);
      modalRef.componentInstance.benefit = benefitBonus?.benefit;
      modalRef.componentInstance.benefitBonus = benefitBonus;
      modalRef.componentInstance.benefitResult = benefitResult;
      modalRef.componentInstance.displayMode = BenefitUserClaimModalDisplayModeEnum.Claim;

      modalRef.result.then((res: boolean) => {
        if (res) {
          this.loadData();
        }
      });
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
  }

  openPolicyLink(benefitResult: BenefitResult) {
    const policy = benefitResult?.policy;

    if (!policy?.policyId) {
      return;
    }

    CommonHelper.openPolicyLink(policy.policyId);
  }

  private transformBenefitListToBenefitBonusList(benefits: Benefit[]): BenefitBonus[] {
    if (!benefits?.length) {
      return [];
    }

    const iter = benefits.map(b => {
      b.countValue = b.benefitBonus.reduce((sum, current) => sum + current.benefitResults.length, 0);
      b.benefitBonus.forEach(bb => bb.benefit = b);
      return b.benefitBonus;
    });

    const bonuses = CommonHelper.flatten(iter);

    if (bonuses?.length) {
      bonuses[0].isPanelActive = true;
    }
    // if (benefits.length > 0) {
    //   benefits[0].isPanelActive = true;
    // }
    return bonuses;
  }
}
