import { BaseModel } from './base-model';
import { Product } from './product.model';
import { Supplier } from './supplier';
import { WarehouseDetail } from './warehouse';
import { EmployeeEquipment } from './employee-equipment';
import { EquipmentCategory } from './equipment-category';
import { PurchaseDetailOption } from './enums/purchase-option.enum';

export class PurchaseOrderDetail extends BaseModel {
  id?: string;
  parentId?: string;
  name: string;
  cancelledBy?: string;
  cancelledByName?: string;
  equipmentCategoryId?: string;
  equipmentCategoryName?: string;
  equipmentId?: string;
  productName?: string;
  reason?: string;
  quantity?: number;
  buyQuantity?: number;
  missingQuantity?: number;
  boughtQuantity?: number;
  quantityInWarehouse?: number;
  productId?: string;
  price?: number;
  totalAmount?: number;
  totalQuantityAvailable?: number;
  vAT?: number;
  status: number;
  supplierId?: string;
  warehouseItemId?: string;
  supplierName?: string;
  purchaseOrderId?: string;
  purchaseRequestId?: string;
  localId?: number;
  isSelected?: boolean;
  isBroken?: boolean;
  type?: number;
  quantityAvailable?: number;
  afterApproval?: boolean;
  order?: number;
  product?: Product;
  supplier?: Supplier;
  warehouseDetail?: WarehouseDetail;
  hasChildrens?: boolean;
  warehouseDetails?: WarehouseDetail[];
  expaned?: boolean;
  equipmentCategory?: EquipmentCategory;
  option: number;

  constructor() {
    super();
    this.quantity = 1;
    this.option = PurchaseDetailOption.Replace;
  }
}

export class DeliveryFormEdit {
  purchaseRequestDetails?: PurchaseOrderDetail[];
  deliveryFormDetails?: EmployeeEquipment[];
  userId?: string;
  totalRequiredQuantity?: number;
  totalHasQuantity?: number;
}
