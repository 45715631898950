import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReviewConstants } from '@intranet/lib/config/review.constants';
import { UrlConstant } from '@intranet/lib/config/urlConstant';
import { ReviewFormMemberStatusFormEnum } from '@intranet/lib/data-access';
import { CDNService } from '@intranet/lib/data-access/services/cdn-server.service';
import { ReviewFormMemberService } from '@intranet/lib/data-access/services/review/review-form-member.service';
import { WidgetService } from '@intranet/lib/data-access/services/widget.service';
import { DateHelper, CommonHelper } from '@intranet/lib/helpers';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import { StringHelper } from '@intranet/lib/helpers/stringHelper';
import { ModalService, ModalSize, WebsiteEvent } from '@intranet/lib/settings';
import { Subscription } from 'rxjs';
import { debounceTime, finalize, first, startWith } from 'rxjs/operators';
import { ReviewFormDiscussionModalComponent } from '../../review-form-discussion-modal/review-form-discussion-modal.component';

@Component({
  selector: 'app-widget-review',
  templateUrl: './widget-review.component.html',
  styleUrls: ['./widget-review.component.scss'],
})
export class WidgetReviewComponent implements OnChanges, OnInit, OnDestroy {
  @Input() widget = null;
  @Input() resizeEvent = null;
  @Input() isSidebar = false;
  @Input() departmentAssociateUsers: any[] = [];
  @Input() widgetLoaded: EventEmitter<any>;
  formMemberStatusFormDisplay = ReviewConstants.FormMember.StatusForm.Display;
  formMemberStatusFormEnum = ReviewFormMemberStatusFormEnum;
  isLoading = false;
  formId: string;
  formMemberId: string;
  reviewForms: any[] = [];
  userOptions: any[] = [];
  isPhoneView = CommonHelper.isPhoneView();

  formGroup: FormGroup;
  get departmentId() { return this.formGroup.get('departmentId'); }
  get userId() { return this.formGroup.get('userId'); }
  get year() { return this.formGroup.get('year'); }

  get avatarSize() {
    return this.isSidebar ? 40 : 50;
  }

  subscriptions: Subscription[] = [];

  constructor(private router: Router,
    private widgetService: WidgetService,
    private websiteEvent: WebsiteEvent,
    private reviewFormMemberService: ReviewFormMemberService,
    private cdnService: CDNService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.departmentAssociateUsers?.previousValue !== changes.departmentAssociateUsers?.currentValue && this.formGroup) {
      this.departmentAssociateUsers.forEach(dpu => {
        (dpu?.users as any[]).forEach(user => {
          user.enFullName = StringHelper.removeSensitiveWords(user?.fullName ?? '').toUpperCase();
        });
      });

      this.userId.setValue(null);
      if (this.departmentId.value) {
        const department = this.departmentAssociateUsers.find(m => m.id === this.departmentId.value);
        this.userOptions = !department ? [] : [...department.users];
        return;
      }
      this.updateUserOptions();
    }
  }

  ngOnInit() {
    const resizeEvent = this.websiteEvent.eventChangeHeight.pipe(startWith(window.innerHeight)).subscribe(() => this.updateMobileViewData());
    this.subscriptions.push(resizeEvent);

    this.initForm();
    this.activatedRoute.queryParams.subscribe(async params => {
      this.formId = params.formId;
      this.formMemberId = params.formMemberId;
      if (this.formId && this.formMemberId) {
        this.openDiscussion({ id: this.formMemberId });
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscriptionValueChange => {
      subscriptionValueChange.unsubscribe();
    });
  }

  viewDetail(formMember: any) {
    const url = UrlConstant.UserUrl.REVIEW_PERFORMANCE + '/detail?id=' + formMember?.id;
    window.open(
      url,
      '_blank'
    );
  }

  openDiscussion(formMember: any) {
    if (!formMember?.id) {
      return;
    }

    const modalRef = this.modalService.open(ReviewFormDiscussionModalComponent, { size: ModalSize.percent90 });
    modalRef.componentInstance.formMemberId = formMember.id;
  }

  async exportPdfFile(reviewForm: any) {
    const reviewFormMemberId = reviewForm?.id;
    if (!reviewFormMemberId) {
      return;
    }
    try {
      const fileId = await this.reviewFormMemberService.generatePdfFileId(reviewFormMemberId).toPromise();
      if (fileId) {
        await this.cdnService.getPrivateFileById(fileId, '');
      }
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
  }

  viewList() {
    this.router.navigate([`/${UrlConstant.UserUrl.REVIEW_PERFORMANCE}`]);
  }

  modelDepartmentChanged(departmentId) {
    if (departmentId) {
      this.websiteEvent.setGroupSourceDataEvent(departmentId);
    }
  }

  searchUserOptions(fullName: string) {
    this.updateUserOptions();
    if (fullName === '') {
      return;
    }


    const fullNameConvert = StringHelper.removeSensitiveWords(fullName).toUpperCase();

    const userOptionFilters = this.userOptions
      .filter(user => user?.enFullName?.includes(fullNameConvert));

    this.userOptions = [];

    userOptionFilters.forEach(userOption => {
      if (this.userOptions.some(m => m.id === userOption.id)) {
        return;
      }
      this.userOptions = [...this.userOptions, ...[userOption]];
    });
  }

  private async loadReviewForms() {
    this.isLoading = true;
    const userIds = this.userId.value ? [this.userId.value] : this.userOptions.map(user => user?.id);
    const request = {
      isDisplayDataManage: this.widget?.data?.isDisplayDataManage || false,
      year: this.year.value ? DateHelper.getYear(this.year.value) : null,
      userIds
    };
    try {
      this.reviewForms = await this.widgetService.getWidgetReviewForms(request);
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
    finally {
      this.isLoading = false;
      this.widgetLoaded?.emit(this.widget);
    }
  }

  private initForm() {
    const dateTimeNow = Date.now();

    this.formGroup = new FormGroup({
      year: new FormControl(dateTimeNow),
      departmentId: new FormControl(null),
      userId: new FormControl(null)
    });

    const groupSourceDataChanges = this.websiteEvent.groupSourceDataEvent.subscribe(departmentId => {
      if (!this.departmentId.value || departmentId === '') {
        const departmentIdValue = departmentId !== '' ? departmentId as any : null;
        this.departmentId.setValue(departmentIdValue);
      }
    });

    const formGroupValueChanges = this.formGroup.valueChanges.pipe(startWith(this.formGroup.getRawValue()), debounceTime(300))
      .subscribe(() => {
        this.loadReviewForms();
      });

    const departmentValueChanges = this.departmentId.valueChanges.subscribe(departmentId => {
      this.userId.setValue(null);
      this.updateUserOptions();
    });

    this.subscriptions = [...this.subscriptions,
      departmentValueChanges,
      formGroupValueChanges,
      groupSourceDataChanges
    ];
  }

  private updateUserOptions() {
    if (this.departmentId.value) {
      const department = this.departmentAssociateUsers.find(m => m.id === this.departmentId.value);
      this.userOptions = !department ? [] : [...department.users];
      return;
    }

    this.userOptions = [];
    this.departmentAssociateUsers.forEach(departmentAssociateUser => {
      this.userOptions = [...this.userOptions, ...departmentAssociateUser?.users];
    });
  }

  private updateMobileViewData() {
    this.isPhoneView = CommonHelper.isPhoneView();
  }
}
