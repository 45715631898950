<div class="rt-panel-item" *ngIf="activities.length > 0">
  <div class="row align-items-center title-panel">
    <div class="col-8">
      <p>Recent activities</p>
    </div>
    <div class="col-4 text-right">
      <a [routerLink]="['/news-feed/recent-activities']" class="view-all">View all</a>
    </div>
  </div>
  <div class="rt-panel-item-content">
    <div class="row align-items-center" *ngFor="let activity of activities">
      <div class="col">
        <div class="recent-activities">
          <div class="img-notify btn-link" [routerLink]="[urlConstant.PROFILE_PAGE]">
            <app-avatar [size]="32" [avatarSource]="activity?.user?.avatarUrl" [avatarText]="activity?.user?.fullName">
            </app-avatar>
          </div>

          <p *ngIf="activity.activityType == activityTypes.POST">
            <ng-container *ngTemplateOutlet="userTemplate"></ng-container> just posted status.
          </p>
          <p *ngIf="activity.activityType == activityTypes.POLL">
            <ng-container *ngTemplateOutlet="userTemplate"></ng-container> just posted poll.
          </p>
          <p *ngIf="activity.activityType == activityTypes.LIKE">
            <ng-container *ngTemplateOutlet="userTemplate"></ng-container> just liked.
          </p>
          <p *ngIf="activity.activityType == activityTypes.UNLIKE">
            <ng-container *ngTemplateOutlet="userTemplate"></ng-container> just unliked.
          </p>
          <p *ngIf="activity.activityType == activityTypes.COMMENT">
            <ng-container *ngTemplateOutlet="userTemplate"></ng-container> just commented
            comment.
          </p>
          <p *ngIf="activity.activityType == activityTypes.SURVEY">
            <ng-container *ngTemplateOutlet="userTemplate"></ng-container> just created survey.
          </p>
          <p *ngIf="activity.activityType == activityTypes.BIRTHDAY">
            <ng-container *ngTemplateOutlet="userTemplate"></ng-container> just wished birthday.
          </p>
          <p *ngIf="activity.activityType == activityTypes.WORK_ANNIVERSARY">
            <ng-container *ngTemplateOutlet="userTemplate"></ng-container> just wished work anniversary.
          </p>
          <p *ngIf="activity.activityType == activityTypes.SURVEY_ANSWER">
            <ng-container *ngTemplateOutlet="userTemplate"></ng-container> just completed survey.
          </p>
          <p>{{ activity.createdDate | amFromUtc | amTimeAgo }}</p>
          <ng-template #userTemplate>
            <a [routerLink]="[urlConstant.PROFILE_PAGE]"><b>{{activity?.user?.fullName}}</b></a>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="row align-items-center" *ngIf="isLoading">
      <nz-skeleton [nzActive]="true"></nz-skeleton>
    </div>
  </div>
</div>
