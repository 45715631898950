import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { BaseLayoutComponent } from '@intranet/lib/layouts';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { PubSubEvent, Constants } from '@intranet/index';
@Component({
  selector: 'app-front-group-layout',
  templateUrl: './front-group-layout.component.html',
  styleUrls: ['./front-group-layout.component.scss']
})
export class FrontGroupLayoutComponent extends BaseLayoutComponent implements OnInit {
  currentUser = this.currentUser;
  year = new Date().getFullYear();
  systemName: string;
  isExpaned = false;
  showLeftPanel = false;
  showRightPanel = false;
  groupId: string;

  get isMobileView() {
    return window.innerWidth <= Constants.SmallViewMaxWidthInPixel;
  }

  get sidebarId() {
    return this.isExpaned || !this.isMobileView ? 'rt-left-panel' : '';
  }

  constructor(private route: ActivatedRoute, private pubsub: PubSubService, private router: Router) {
    super();
    this.systemName = AppConfig.settings.App_Name;
    this.isExpaned = localStorage.getItem('isExpanedFront') ? true : false;
    this.route.queryParams.subscribe(res => {
      this.groupId = res.groupId;
    });
  }

  ngOnInit() {
    this.pubsub.$sub(PubSubEvent.EVENTS.TOGGLE_LEFT_PANEL).subscribe(result => {
      this.toggleLeftPanel(result);
    });

    this.pubsub.$sub(PubSubEvent.EVENTS.TOGGLE_RIGHT_PANEL).subscribe(result => {
      this.toggleRightPanel(result);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.pubsub.$pub(PubSubEvent.EVENTS.TOGGLE_LEFT_PANEL, false);
        this.pubsub.$pub(PubSubEvent.EVENTS.TOGGLE_RIGHT_PANEL, false);
      }
    });
  }

  onChangeSliderLeftActive(event) {
    this.isExpaned = event;
  }

  toggleLeftPanel(event) {
    this.showLeftPanel = event;
  }

  toggleRightPanel(event) {
    this.showRightPanel = event;
  }
}
