<page-main>
  <page-header title="Policys" [btnTemplate]="btnTempate">
    <ng-template #btnTempate>
      <!-- add new skill -->
      <li nz-tooltip nzTooltipTitle="Add new">
        <a (click)="openNewModal()">
          <i class="fas fa-plus"></i>
        </a>
      </li>
    </ng-template>
  </page-header>
  <page-content>
    <!-- filter -->
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-xl-3 col-lglg-3 col-md-3 col-sm-3 col-12">
            <search-input name="keyword" [(ngModel)]="keyWord" (clear)="search()" (input)="search()"></search-input>
          </div>
        </div>
      </div>
      <!-- datatable -->
      <div class="card-body">
        <ngx-datatable #myTable class="material grid-aluha" [rows]="data" [loadingIndicator]="isLoading" [columnMode]="ColumnMode.force" [headerHeight]="50"
          [footerHeight]="50" [virtualization]="false" [externalSorting]="false" [externalPaging]="true" [scrollbarH]="true" [count]="searchData.totalItems"
          [offset]="searchData.pageNumber - 1" [limit]="searchData.pageSize" (page)="pageChange($event)" rowHeight="40">
          <ngx-datatable-column *ngFor="let col of columnMapping" prop="{{ col.ColumnName }}" name="{{ col.DisplayName }}" [canAutoResize]="col.AutoResize"
            [sortable]="col.Sortable">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span>{{ value }}</span>
            </ng-template>
          </ngx-datatable-column>
          <!-- Actions -->
          <ngx-datatable-column name="Actions" prop="policyId" fixed [width]="160" [frozenRight]="true">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <grid-button (okEvent)="editPolicy(value)" tooltip="Edit" className="text-warning">
                <i nz-icon noconfirm nzType="edit" theme="outline"></i>
              </grid-button>
              <grid-button confirmTitle="Are you sure delete this item?" (okEvent)="deletePolicy(value)" tooltip="Delete" className="text-danger">
                <i nz-icon confirm nzType="delete" theme="outline"></i>
              </grid-button>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </page-content>
</page-main>
