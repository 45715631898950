import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
declare var $: any;

@Directive({
  selector: '[RemoveEmptyMenu]',
})
export class RemoveEmptyMenuDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer2: Renderer2) {}
  ngAfterViewInit(): void {
    this.checkEmpty();
  }
  checkEmpty() {
    const element = this.el.nativeElement;
    if (element) {
      const ul = element.querySelector('ul');
      if (ul) {
        const lis = ul.querySelectorAll('li');
        if (!lis || !lis.length) {
          $(element).remove();
        }
      }
    }
  }
}
