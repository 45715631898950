import { Injectable } from '@angular/core';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { ReviewPerformanceSummary } from '../../models/review/review-form-member.model';

@Injectable({
  providedIn: 'root',
})
export class ReviewFormMemberService {
  constructor(private httpClient: HttpClientService) {}

  getMembersToCloneRow(id: string) {
    const url = ApiEndpoint.Review.FormMember.GetMembersToCloneRow(id);
    return this.httpClient.getObservable(url);
  }

  get(reviewFormMemberId: string) {
    const url = ApiEndpoint.Review.FormMember.Get(reviewFormMemberId);
    return this.httpClient.getObservable(url);
  }

  generateTokenToView(reviewFormMemberId: string) {
    const url = ApiEndpoint.Review.FormMember.GenerateTokenToView(reviewFormMemberId);
    return this.httpClient.postObservable(url);
  }

  generatePdfFileId(reviewFormMemberId: string) {
    const url = ApiEndpoint.Review.FormMember.GeneratePdfFileId(reviewFormMemberId);
    return this.httpClient.postObservable(url, {});
  }

  generatePdfFilesZip(request: any) {
    const url = ApiEndpoint.Review.FormMember.GeneratePdfFileZip();
    return this.httpClient.postObservable(url, request);
  }

  updateAffectSalary(reviewFormMemberId: string, data: any): Promise<void> {
    const url = ApiEndpoint.Review.FormMember.UpdateAffectSalary(reviewFormMemberId);
    return this.httpClient.putObservable(url, data).toPromise();
  }

  updateIsSelfVerifying(reviewFormMemberId: string, data: any): Promise<void> {
    const url = ApiEndpoint.Review.FormMember.UpdateIsSelfVerifying(reviewFormMemberId);
    return this.httpClient.putObservable(url, data).toPromise();
  }

  saveSummary(reviewFormMemberId: string, data: any): Promise<void> {
    const url = ApiEndpoint.Review.FormMember.SaveSummary(reviewFormMemberId);
    return this.httpClient.putObservable(url, data).toPromise();
  }
  getSummary(reviewFormMemberId: string): Promise<ReviewPerformanceSummary> {
    const url = ApiEndpoint.Review.FormMember.GetSummary(reviewFormMemberId);
    return this.httpClient.getObservable(url).toPromise();
  }

  deleteFormMember(reviewFormMemberId: string): Promise<any> {
    const url = ApiEndpoint.Review.FormMember.Delete(reviewFormMemberId);
    return this.httpClient.deleteObservable(url).toPromise();
  }
}
