import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseAdminComponent } from '@intranet/lib/components';
import { SurveyService, Survey, QuestionType, SurveyQuestion } from '@intranet/lib/data-access';

import { Router, ActivatedRoute } from '@angular/router';
import { PubSubEvent, PubSubService, Toastr } from '@intranet/index';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-newsfeed-survey-modal',
  templateUrl: './survey-modal.component.html',
  styleUrls: ['./survey-modal.component.scss'],
  styles: [],
})
export class SurveyModalComponent extends BaseAdminComponent implements OnInit {
  @Input() survey: Survey;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  activeModal: NgbActiveModal;
  enableAnswer: boolean;
  enableApprove: boolean;
  questionTypeIds = {
    single: QuestionType.SingleChoice,
    multiple: QuestionType.MultipleChoice,
    freetext: QuestionType.Freetext,
    singleWithText: QuestionType.SingleChoiceWithText,
    multipleWithText: QuestionType.MultipleChoiceWithText,
  };
  isLoading: boolean;
  guessId: string;
  progress: number;
  progressPercent: number;
  total: number;
  question: SurveyQuestion;
  introStep = -1;
  rate: number;
  comment: string;

  constructor(modalRef: NgbActiveModal, private surveyService: SurveyService, private router: Router,
    private route: ActivatedRoute, private pubsub: PubSubService) {
    super();
    this.activeModal = modalRef;
    this.survey = { name: '' };
    this.progress = this.introStep;
    this.progressPercent = 0;
    this.rate = 0;
  }

  ngOnInit() {
    this.survey = this.survey = this.survey || { name: '', questions: [] };
    if (this.survey.id) {
      this.getDetail(this.survey.id);
    }
  }

  getDetail(surveyId: string) {
    this.isLoading = true;
    this.surveyService.getSurvey(
      surveyId,
      res => {
        this.survey = res;
        this.total = (this.survey.questions).length;
        this._checkActionPermission();
      },
      error => { },
      () => {
        this.isLoading = false;
      },
    );
  }

  onSubmit() {
    const request = {
      id: Guid.create().toString(),
      userId: this.userId || this.guessId,
      surveyId: this.survey.id,
      answers: [],
      rate: this.rate,
      comment: this.comment
    };
    let userAnswers = [];
    this.survey.questions.forEach(question => {
      switch (question.questionTypeId) {
        case QuestionType.Freetext:
          userAnswers.push(this._initFreetextAnswer(question));
          break;
        case QuestionType.SingleChoice:
          userAnswers.push(this._initSingleChoiceAnswer(question));
          break;
        case QuestionType.MultipleChoice:
          userAnswers = userAnswers.concat(this._initMultipleChoiceAnswer(question));
          break;
        case QuestionType.SingleChoiceWithText:
          userAnswers.push(this._initSingleChoiceAnswer(question));
          userAnswers.push(this._initFreetextAnswer(question));
          break;
        case QuestionType.MultipleChoiceWithText:
          userAnswers = userAnswers.concat(this._initMultipleChoiceAnswer(question));
          userAnswers.push(this._initFreetextAnswer(question));
          break;
        default:
          break;
      }
    });
    request.answers = userAnswers;
    this.isLoading = true;
    this.surveyService.createUserSurveyAnswer(
      request,
      (res) => {
        if (res && res.guidId) {
          Toastr.success('We received your answers! Thank you for your supporting.');
          this.pubsub.$pub(PubSubEvent.EVENTS.ON_REFRESH_SURVEY);

        } else {
          Toastr.error('You already submitted answers! Thank you for your supporting');
        }
        this.activeModal.close();
        // this.onClose.emit();
      });
  }


  onGo(step) {
    const isValid = this._validateGoNext();
    if (!isValid) {
      Toastr.error('Please answer the required question!');
      return;
    }
    this.progress += step;
    // tslint:disable-next-line: triple-equals
    this.question = this.survey.questions.find((item, index) => index == this.progress) || {};
    this.progressPercent = Math.round(((this.progress) / this.survey.questions.length) * 100);
  }

  onCancel() {
  }

  private _validateGoNext(): boolean {
    let result = true;
    if (this.question && this.question.required) {
      const question = this.survey.questions.find(item => item.id === this.question.id);
      if (question) {
        switch (question.questionTypeId) {
          case this.questionTypeIds.freetext:
            result = question.result !== undefined && question.result !== null;
            break;
          case this.questionTypeIds.single:
          case this.questionTypeIds.singleWithText:
            result = question.userChoice !== undefined && question.result !== null;
            break;
          case this.questionTypeIds.multiple:
          case this.questionTypeIds.multipleWithText:
            result = question.options.find(item => item.isSelected) !== null;
            break;
        }
      }
    }
    return result;
  }

  private _checkActionPermission() {
    this.enableAnswer = (!this.survey.rejected && this.survey.participants && this._isUserPariticipant());
    this.enableApprove = (!this.survey.rejected && !this.survey.isApproved);
  }

  private _initFreetextAnswer(question) {
    return {
      id: Guid.create().toString(),
      questionId: question.id,
      userId: this.userId,
      result: question.result,
      isFreetext: true,
    };
  }

  private _isUserPariticipant() {
    const participants = JSON.parse(this.survey.participants);
    const participant = participants.find(item => item.userId === this.userId || item.userId === this.guessId);
    return (participant !== undefined && participant !== null);
  }

  private _initSingleChoiceAnswer(question) {
    return {
      id: Guid.create().toString(),
      questionId: question.id,
      userId: this.userId,
      result: question.userChoice,
      isFreetext: false,
    };
  }

  private _initMultipleChoiceAnswer(question) {
    const userAnswers = [];
    question.options.forEach(option => {
      if (option.isSelected) {
        userAnswers.push({
          id: Guid.create().toString(),
          questionId: question.id,
          userId: this.userId,
          result: option.id,
          isFreetext: false,
        });
      }
    });
    return userAnswers;
  }
}
