import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-header',
  templateUrl: './modal-header.component.html',
  styles: [],
})
export class ModalHeaderComponent implements OnInit {
  constructor() {
  }
  headerTemplate: TemplateRef<void>;
  @Input() closeTemplate: TemplateRef<void>;

  strTitle: string;
  @Input()
  set title(value: string | TemplateRef<void>) {
    if (value instanceof TemplateRef) {
      this.headerTemplate = value;
    } else {
      this.strTitle = value;
    }
  }
  @Input() activeModal: NgbActiveModal;
  @Input() closeData: any;
  @Input() canBack: boolean;
  @Output() canBackChange = new EventEmitter<boolean>();
  @Output() close = new EventEmitter();

  ngOnInit() { }

  back() {
    this.canBack = false;
    this.canBackChange.emit(this.canBack);
  }
  closeEvent() {
    if (this.activeModal) {
      this.activeModal.close(this.closeData);
    } else {
      this.close.emit(this.closeData);
    }
  }
}
