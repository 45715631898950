import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UrlConstant } from '@intranet/lib/config';
import { MemberDisplayMode, MemberDto, WidgetSearchDto, WidgetService } from '@intranet/lib/data-access';
import { CommonHelper } from '@intranet/lib/helpers';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import * as moment from 'moment';
@Component({
  selector: 'app-widget-skill-detail',
  templateUrl: './widget-skill-detail.component.html',
  styleUrls: ['./widget-skill-detail.component.scss'],
})
export class WidgetSkillDetailComponent implements OnInit, OnChanges {
  @Input() searchData: WidgetSearchDto = new WidgetSearchDto();
  @Input() displayMode = MemberDisplayMode.UserWidget;
  @Input() data: MemberDto[] = [];
  @Input() isLoading = false;
  isDetail = false;
  urlConstant = UrlConstant;
  isMobileView = CommonHelper.isMobileView();

  extraInfoColumnBootstrapWidth = 2;
  extraInfoColumnName = 'Tech stack';
  headers = [
    { value: 'Name', classes: 'col pr-1' },
    { value: 'Joined date', classes: 'col-2 pr-1' },
    { value: 'Seniority at Restaff', classes: 'col-3 pr-1' },
    { value: 'Total years of experience', classes: 'col-3 pr-1' },
  ];
  format = [
    { key: 'positionName', classes: 'col info-text pr-1 position-text' },
    { key: 'joinedDate', classes: 'col-2 info-text pr-1' },
    { key: 'seniority', classes: 'col-3 info-text pr-1' },
    { key: 'totalYearsOfExperience', classes: 'col-3 info-text pr-1' },
  ];

  get isClientPage() {
    return this.displayMode === MemberDisplayMode.ClientPage;
  }

  constructor(private _widgetService: WidgetService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.data = this.formatData(this.data);
    }
  }

  ngOnInit() {
    if (this.isClientPage) {
      return;
    }

    this.loadData();
  }

  async loadData() {
    this.isLoading = true;

    if (this.isClientPage && this.searchData) {
      this.searchData.noLoading = true;
    }
    try {
      const res = await this._widgetService.getWidgetMemberByTeam(this.searchData);
      this.data = this.formatData(res);
    } catch (err) {
      ErrorHelper.displayMessage(err);
    } finally {
      this.isLoading = false;
    }
  }

  openInformation(item) {
    if (this.isClientPage) {
      return;
    }

    const url = this.urlConstant.PROFILE_PAGE + '?employeeId=' + item.employeeId;
    window.open(url, '_blank');
  }

  private formatData(data: MemberDto[]): MemberDto[] {
    return data.map(m => {
      m.joinedDate = moment(m.joinedDate).format('DD/MM/YYYY');
      (m.totalYearsOfExperience as any) = m.totalYearsOfExperience + ' year' + (m.totalYearsOfExperience > 1 ? 's' : '');
      return m;
    });
  }

  @HostListener('window:resize')
  updateMobileViewData() {
    this.isMobileView = CommonHelper.isMobileView();
  }
}
