import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EditorComponent } from '@intrafront/components/editor/editor.component';
import { UrlConstant } from '@intranet/lib/config/urlConstant';
import { NewsFeed, Comment, WallNewsFeedService, UserModel } from '@intranet/lib/data-access';
import { SecurityHelper } from '@intranet/lib/helpers/securityHelper';
import { Toastr } from '@intranet/lib/settings/services/toastr';
@Component({
  selector: 'app-news-feed-comment-control',
  templateUrl: './news-feed-comment-control.component.html',
  styleUrls: ['./news-feed-comment-control.component.scss']
})
export class NewsFeedCommentControlComponent implements OnInit {
  @ViewChild(EditorComponent) editorComponent: EditorComponent;

  @Input() feed: NewsFeed;
  @Input() parent: Comment;

  @Output() success = new EventEmitter();

  currentUser = null;
  urlConstant = UrlConstant;

  comment: Comment;
  isLoading = false;

  editorConfig = {
    toolbar: 'bold italic strikethrough forecolor backcolor | emoticons',
    plugins: 'image emoticons mention',
    content_style: ``,
    placeholder: 'Write a comment...'
  };

  formGroup: FormGroup;

  constructor(private newsFeedService: WallNewsFeedService) { }

  ngOnInit() {
    const authToken = SecurityHelper.getStoreAuthen();
    if (authToken) {
      this.currentUser = authToken;
    }

    this.reset();
  }

  getEditorStyle() {
    return {
      'min-height': '100px',
      'background-color': '#dedede',
    };
  }

  reset() {
    this.isLoading = false;

    this.comment = new Comment();
    this.comment.newsFeedId = this.feed.id;
    this.comment.user = this.currentUser;
    this.comment.children = Array<Comment>();

    if (this.parent && this.parent.id) {
      this.comment.parent = this.parent;
      this.comment.parentId = this.parent.id;
    }

    if (this.editorComponent) {
      this.editorComponent.reset();
    }
  }

  mention(user: UserModel) {
    this.editorComponent.addMention(user);
  }

  async onSubmit() {
    if (this.isLoading || !this.comment?.content || this.comment.content.length === 0) {
      return;
    }

    this.isLoading = true;

    try {
      const params = JSON.parse(JSON.stringify(this.comment));
      params.noLoading = true;
      this.newsFeedService.createComment(
        params,
        res => { },
        () => { },
        () => {
          this.reset();
        },
      );

      this.success.emit();
    } catch (e) {
      this.isLoading = false;
      Toastr.error(e.toString());
    }
  }
}
