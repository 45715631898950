import { BaseModel } from './base-model';
import { Department } from './department.model';
import { Room } from './room';

export class MeetingBookingRoom extends BaseModel {
  title?: string;
  roomId?: string;
  roomName?: string;
  occurType?: number;
  fromTime?: Date;
  toTime?: Date;
  step?: number;
  untilDate?: Date;
  alertDuration?: number;
  isAlertDuration?: string;
  groupId?: string;
  groupName?: string;
  isSendMail?: boolean;
  onlySendNewJoiner?: boolean;
  departmentGroup?: Department;
  room?: Room;
  joiners?: any[];
  allDay?: boolean;
  description: string;
  timeForMin: number;
  constructor() {
    super();
    this.occurType = 0;
  }
}
