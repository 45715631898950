import { Component, OnInit, Input, TemplateRef, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MenuHelper } from '@intranet/lib/config';
import { WebsiteEvent } from '@intranet/lib/settings';
import { Subscription } from 'rxjs';
import { WebHelper, RouterHelperService } from '@intranet/lib/helpers';
import { RouterInfoModel } from '@intranet/lib/data-access';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styles: [],
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  @Input() atitle: string;
  @Input() url: string;
  @Input() titleTemplate: TemplateRef<void>;
  @Input() btnTemplate: TemplateRef<void>;
  name: string;
  menu: Array<any> = [];
  deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30;
  breadcrumbList: Array<any> = [];
  websiteEvent: Subscription;
  routerEvent: Subscription;
  routerInfo: RouterInfoModel = new RouterInfoModel();
  constructor(private route: ActivatedRoute, websiteEvent: WebsiteEvent, private routerHelperService: RouterHelperService) {
    this.websiteEvent = websiteEvent.eventChangeTitle.subscribe(title => {
      this.atitle = title;
      if (this.atitle === this.routerInfo.previousPageTitle) {
        this.routerInfo.previousPageTitle = undefined;
      }
    });
    this.routerEvent = this.routerHelperService.eventRouter.subscribe((res: RouterInfoModel) => {
      this.routerInfo = res;
    });
    this.startTimer();
  }

  timeLeft: string;
  interval;

  startTimer() {
    this.interval = setInterval(() => {
      const currentDate = new Date();
      this.timeLeft = currentDate.toLocaleTimeString();
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  ngOnDestroy(): void {
    this.pauseTimer();
    WebHelper.unSubscribe(this.websiteEvent, this.routerEvent);
  }

  ngOnInit() {
    this.menu = MenuHelper.menusListAdmin;
  }
}
