import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'page-content',
    templateUrl: './page-content.component.html',
})
export class PageContentComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}
