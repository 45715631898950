import { BaseModel } from './base-model';

export class Worktime extends BaseModel {
  fullName: string;
  enrollNumber: string;
  workTimeId: string;
  employeeId: string;
  isInValid: number;
  attState: number;
  verifyMethod: number;
  year: number;
  totalHourMonth: number;
  timeMinuteLate?: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
  workCode: string;
  startTime: Date;
  endTime: Date;
  totalHour: number;
}
export class UpdateWorkTimeDetailDto {
  id: string;
  enrollNumber: string;
  fullName: string;
  startTime: string;
  endTime: string;
  isManage: boolean;
  year: number;
  month: number;
  day: number;
}
