<!-- <div *ngIf="memberData" class="scroll-bar" [style.max-height]="maxHeight ? maxHeight : '100%'">
  <div *ngFor="let member of memberData; let i = index" [class]="i % 2 === 0 ? 'row mt-1' : 'row mt-1 odd'">
    <div class="col-3 vertical-center">
      <app-avatar [size]="40" [(avatarSource)]="member.avatarUrl" [(avatarText)]="member.fullName"> </app-avatar>
    </div>
    <div class="col-9">
      <div class="row mt-2">
        <div (click)="openInformation(member)" class="col-9 font-12" [ngClass]="{'employee-information': navigateMember}">
          <p class="font-weight-bold">{{member.fullName}}</p>
          <p class="font-weight-bold">{{member.positionName}}</p>
          <div *ngIf="member.departments">
            <nz-tag *ngFor="let department of member.departments" [nzColor]="'geekblue'">
              <span *ngIf="department" [innerHTML]="department.name"></span>
            </nz-tag>
          </div>
        </div>
        <div [class]="additionalTemplateClass ? additionalTemplateClass + ' col-3 font-12' : 'col-3 font-12'">
          <ng-container *ngIf="template" [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{member: member}"></ng-container>
        </div>
      </div>

    </div>
  </div>
  <nz-empty *ngIf="memberData && memberData.length == 0"></nz-empty>
</div> -->

<ng-container *ngIf="memberData?.length > 0 else emptyTemplate">
  <div class="member-container scroll-bar" [style.max-height]="maxHeight ?? '100%'">
    <div class="member-item" *ngFor="let member of memberData; let i = index" [ngClass]="{'odd': i % 2 !== 0}">
      <div class="member-avatar">
        <app-avatar [size]="isPhoneView ? 40 : 70" [(avatarSource)]="member.avatarUrl" [(avatarText)]="member.fullName"> </app-avatar>
      </div>
      <div class="member-information font-12">
        <div class="left-information">
          <span class="font-weight-bold full-name" (click)="openInformation(member)">{{member.fullName}}</span>
          <span class="font-weight-bold">{{member.positionName}}</span>
          <div class="member-departments" [ngClass]="isNewsFeedPage ? 'news-feed-page': ''">
            <nz-tag *ngFor="let department of member.departments" [nzColor]="'geekblue'">
              <span *ngIf="department" [innerHTML]="department.name"></span>
            </nz-tag>
          </div>
        </div>
        <div class="right-information" *ngIf="template" [ngClass]="additionalTemplateClass ?? ''">
          <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{member: member}">
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #emptyTemplate>
  <nz-empty></nz-empty>
</ng-template>
