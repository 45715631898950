import { Component, OnInit, Input } from '@angular/core';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Component({
  selector: 'master-layout',
  templateUrl: './master-layout.component.html',
  styleUrls: [],
})
export class MasterLayoutComponent implements OnInit {
  @Input() menu: any = [];
  currentYear: number;
  systemName: string;
  constructor() {
    this.currentYear = new Date().getFullYear();
    this.systemName = AppConfig.settings.App_Name;
  }

  ngOnInit() { }
}
