<ng-container *ngIf="!isMobileView || !isCollapsed">
  <div (click)="onChangeActive()" class="rt-left-panel-item">
    <span class="icon">
      <img src="assets/images/front/shortcut/combined-shape.svg" />
    </span>
    <span class="label sidemenu-label">Shortcuts</span>
    <span class="clearfix"></span>
  </div>
  <app-menu [menuType]="menuType" [template]="newSideMenu" [options]="menuOptions" [ngClass]="{ 'small-view': isMobileView && !isCollapsed }">
  </app-menu>

  <div class="app-info" *ngIf="!isCollapsed">
    <p>
      Version: {{backendVersion}}
    </p>
    <p>Copyright © 2022 Restaff. All rights reserved.</p>
  </div>
</ng-container>

<ng-template #newSideMenu let-menu="menu">
  <!-- Collapsed menu -->
  <ng-container *ngIf="isCollapsed">
    <div *ngFor="let menuLvl1 of menu">
      <div>
        <span class="sidemenu-title-collapsed">
        {{ menuLvl1.title }}
        </span>
      </div>
      <ng-container *ngFor="let menulvl2 of menuLvl1.children">
        <div *ngIf="!menulvl2.children" class="rt-left-panel-item menu-left" nzTooltipPlacement="right" nz-tooltip [nzTooltipTitle]="menulvl2.title"
          (click)="onClickEvent(menulvl2)">
          <span [class]="linkActive === menulvl2.meta.url? menulvl2.icon + ' active' : menulvl2.icon ">
            <img [src]="menulvl2.imagePath" class="sidemenu-icon-size"/>
          </span>

          <!-- <ng-template #submenuIcon>
            <span [class]="'sidemenu-icon'">
              <img [src]="menulvl2.imagePath"/>
            </span>
          </ng-template> -->
        </div>
        <ng-container *ngIf="menulvl2.children">
          <ul nz-menu nzMode="inline" nzTheme="light" [nzInlineCollapsed]="true" class="sidemenu" (click)="onClickEvent(menulvl2)">
            <li nz-submenu [nzTitle]="submenuIcon" class="sidemenu-collapsed">
              <ul *ngFor="let menulvl3 of menulvl2.children">
                <li nz-menu-item (click)="onClickEvent(menulvl3)" [nzSelected]="linkActive === menulvl3.meta.url">
                  <span [class]="linkActive === menulvl3.meta.url? 'sidemenu-icon sidemenu-icon-active' : 'sidemenu-icon'">
                    <img [src]="menulvl3.imagePath" class="sidemenu-icon-size"/>
                  </span>
                  <span class="sidemenu-title">{{ menulvl3.title }}</span>
                </li>
              </ul>
            </li>
            <ng-template #submenuIcon>
              <span [class]="linkActive === menulvl2.meta.url? 'sidemenu-icon sidemenu-icon-active' : 'sidemenu-icon'">
                <img [src]="menulvl2.imagePath" class="sidemenu-icon-size"/>
              </span>
            </ng-template>
          </ul>

        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <!-- Expanded menu -->
  <ng-container *ngIf="!isCollapsed">
    <ul nz-menu nzMode="inline" nzTheme="light" [nzInlineCollapsed]="isCollapsed" class="sidemenu" *ngFor="let menulvl1 of menu">
      <li *ngIf="menulvl1.children" nz-submenu [nzTitle]="menuTitleExpanded" [nzOpen]="menulvl1 && menulvl1.meta && menulvl1.meta.nzOpen">
        <ul *ngFor="let menulvl2 of menulvl1.children">
          <li *ngIf="!menulvl2.children" nz-menu-item (click)="onClickEvent(menulvl2)" [nzSelected]="linkActive === menulvl2.meta.url">
            <span [class]="linkActive === menulvl2.meta.url ? 'sidemenu-icon sidemenu-icon-active' : 'sidemenu-icon'">
              <img [src]="menulvl2.imagePath" class="sidemenu-icon-size"/>
            </span>
            <span class="sidemenu-title">{{ menulvl2.title }}</span>
          </li>
          <li *ngIf="menulvl2.children" nz-submenu [nzTitle]="submenuTitleExpanded" [nzOpen]="menulvl2 && menulvl2.meta && menulvl2.meta.nzOpen">
            <ul *ngFor="let menulvl3 of menulvl2.children">
              <li nz-menu-item (click)="onClickEvent(menulvl3)" [nzSelected]="linkActive === menulvl3.meta.url">
                <span [class]="linkActive === menulvl3.meta.url ? 'sidemenu-icon sidemenu-icon-active' : 'sidemenu-icon'">

                  <img [src]="menulvl3.imagePath" class="sidemenu-icon-size"/>
                </span>
                <span class="sidemenu-title">{{ menulvl3.title }}</span>
              </li>
            </ul>
            <ng-template #submenuTitleExpanded>
              <span [class]="linkActive === menulvl2.meta.url ? 'sidemenu-icon sidemenu-icon-active' : 'sidemenu-icon'">
                <img [src]="menulvl2.imagePath" class="sidemenu-icon-size"/>
              </span>
              <span class="sidemenu-title"> {{ menulvl2.title }} </span>
            </ng-template>
          </li>
        </ul>
      </li>
      <ng-template #menuTitleExpanded>
        <span [class]="'sidemenu-icon'">
          <img [src]="menulvl1.imagePath" class="sidemenu-icon-size"/>
        </span>
        <span class="sidemenu-title"> {{ menulvl1.title }} </span>
      </ng-template>
    </ul>
  </ng-container>
</ng-template>
