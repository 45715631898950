import { WidgetType, ConditionalOperator, ComparisonOperator, GroupOperator, SortDashboardField, DataSourceType } from './enums/dashboard.enum';
import { PagingSortenum } from './enums/sortType.enum';
import { FieldTypeEnum } from './enums/field-setup.enum';
import { DropdownGenericDto } from './bases/dropdown.model';
export class ProjectDashboardDto {
  dashboardId: string;
  name: string;
  description: string;
  projectId: string;
}

export class ProjectWidgetDto {
  widgetId: string;
  name: string;
  dashboardId: string;
  widgetOrder: number;
  widgetConfig: ProjectWidgetConfigDto;
}

export class ProjectWidgetConfigDto {
  chart: WidgetType;
  where: ExpressionFilter[];
  group: string;
  aggregate: GroupOperator;
  aggregateField: string;
  sortField: SortDashboardField;
  sortType: PagingSortenum;
}

export class ProjectWidgetChartDto {
  widgetId: string;
  widgetName: string;
  widgetType: WidgetType;
  widgetData: ProjectWidgetChartDataDto[];
}

export class ProjectWidgetChartDataDto {
  name: string;
  value: number;
}

export class ExpressionFilter {
  tmpUniqueId: string;
  propertyName: string;
  propertyType: FieldTypeEnum;
  propertyDataSourceType: DataSourceType;
  value: string;
  comparison: ComparisonOperator;
  conditional: ConditionalOperator;
  availableComparison: ComparisonOperator[];
  valueDataSource: DropdownGenericDto<any>[];
  valueData: any;
}

export class FieldSetupDataDto {
  fieldId: string;
  fieldDesc: string;
  fieldType: FieldTypeEnum;
  dataSource: DataSourceType;
}
