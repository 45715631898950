import { Injectable } from '@angular/core';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { HttpClientService } from '@intranet/index';
import { BaseService } from './base.service';
@Injectable({
  providedIn: 'root',
})
export class ProjectItemDocumentService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/projectitemdocument';
  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getDiscussion(projectItemId: string) {
    const url = this.hostUrl + `/get_discussion/${projectItemId}`;
    return this.httpClient.getSync(url);
  }

  getDocument(projectItemId: string) {
    const url = this.hostUrl + `/get_document/${projectItemId}`;
    return this.httpClient.getSync(url);
  }

  createDocument(data: any, successCallback: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    this.postByUrl('create_document', data, successCallback, failedCallback, completeCallback);
  }

  uploadDocument(data: any, successCallback: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const formData = new FormData();
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) {
        continue;
      }
      formData.append(prop, data[prop]);
    }
    this.postByUrl('upload_document', formData, successCallback, failedCallback, completeCallback);
  }
}
