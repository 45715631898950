import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseOrderDetail, InvoiceService } from '@intranet/lib/data-access';
import { Loading, Toastr } from '@intranet/index';
import { BaseAdminComponent } from '@intranet/lib/components';

@Component({
  selector: 'app-purchase-invoice',
  templateUrl: './purchase-invoice.component.html',
  styles: [],
})
export class PurchaseInvoiceComponent extends BaseAdminComponent implements OnInit {
  purchaseInvoice: any;
  purchaseId: string;
  productLists: PurchaseOrderDetail[];
  activeModal: NgbActiveModal;
  constructor(
    modalRef: NgbActiveModal,
    private invoiceService: InvoiceService,
  ) {
    super();
    this.activeModal = modalRef;
  }
  ngOnInit() {
    this.loadOrderDetail();
  }

  loadOrderDetail() {
    Loading.show();
    this.invoiceService.getItemsById(this.purchaseId).then((res: any) => {
      if (res) {
        this.productLists = this.convertItems(res);
      } else {
        Toastr.error('Cannot find invoice detail.');
      }
      Loading.hide();
    });
  }

  convertItems(data) {
    const items = [];
    // let total = 0;
    data.forEach(item => {
      // total += item.buyQuantity * item.perPrice;
      const dataConvert = {
        name: item.equipmentName,
        supplierName: item.equipment.supplier.name,
        equipmentCategoryName: item.categoryName,
        quantity: item.quantity,
        price: item.price,
      };
      items.push(dataConvert);
    });
    // this.purchaseInvoice.totalAmount = total;
    return items;
  }
}
