<div class="editor-component fluid-container">
  <div class="row">
    <div class="col">
      <editor *ngIf="!isLoading && (isActiveEditor || content)" [init]="editorConfig" #editor name="content"
        (onChange)="onChangeEditor($event)" (onBlur)="onBlurEditor($event)" (onKeyUp)="onKeyUpEditor($event)"
        (onPaste)="onPasteEditor($event)" [(ngModel)]="content"></editor>
      <div *ngIf="placeholder && !content && !isActiveEditor" class="editor-placeholder" (click)="activeEditor()"
        [ngStyle]="placeholderStyle()">
        <span *ngIf="!content">{{ placeholder? placeholder : 'Share us your information ...' }}</span>
      </div>
      <div *ngIf="isLoading" class="editor-loading" [ngStyle]="loadingStyle()">
        {{ isLoading ? 'Loading ...' : '' }}</div>
    </div>
  </div>
  <div *ngIf="previewData && !disablePreview" class="row preview" #preview>
    <div class="col-12 p-0">
      <a (click)="hidePreview()" class="hide-preview float-right"><i class="ti-close"></i></a>
    </div>
    <div class="col-12 p-0" [innerHtml]="previewData | shortcodeToHtml | safeHtml"></div>
  </div>
</div>