import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat',
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!this.isNumber(value.toString())) {
      return value;
    }
    const locale = args || 'en-US';
    if (value) {
      return new Intl.NumberFormat(locale).format(value);
    }
    return null;
  }

  isNumber(s: string) {
    // only 0-9 and '.' or ',' are allowed.
    const result = s.match(/^[0-9,\.]+$/);
    return !!result && result.length > 0;
  }
}
