import { Component, OnInit, Input } from '@angular/core';
import { NewsFeed } from '@intranet/lib/data-access';
import { Guid } from 'guid-typescript';
import { BaseAdminComponent } from '@intranet/lib/components';

import { Bootbox } from '@intranet/index';
@Component({
    selector: 'app-newsfeed-detail-poll',
    templateUrl: './poll.component.html',
    styleUrls: ['./poll.component.scss'],
    styles: [],
})
export class NewsFeedDetailPollComponent extends BaseAdminComponent implements OnInit {
    @Input() feed: NewsFeed;

    onAddQuestion() {
        const questionId = Guid.create().toString();
        this.feed.pollQuestions = this.feed.pollQuestions || [];
        this.feed.pollQuestions.push({
            id: questionId,
            content: '',
            htmlContent: '',
            newsFeedId: this.feed.id,
            isMultipleChoice: false,
            options: [
                {
                    id: Guid.create().toString(),
                    pollQuestionId: questionId,
                    title: '',
                },
            ],
        });
    }

    onOptionKeyupEvent(event, question, item) {
        if (event.keyCode === 13 && event.shiftKey) {
            this.onAddOption(question);
        }
    }

    onRemoveQuestion(question) {
        Bootbox.confirm().subscribe(res => {
            if (res) {
                this.feed.pollQuestions.splice(this.feed.pollQuestions.indexOf(question), 1);
            }
        });
    }

    onRemoveOption(question, option) {
        Bootbox.confirm().subscribe(res => {
            if (res) {
                question.options.splice(question.options.indexOf(option), 1);
            }
        });
    }

    onAddOption(question) {
        question.options.push({
            id: Guid.create().toString(),
            pollQuestionId: question.id,
            title: '',
        });
    }
}
