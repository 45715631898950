
import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class StyleResolver implements Resolve<any> {
    constructor(private router: Router, @Inject(DOCUMENT) protected document: Document) { }

    loadStyle(styleName: string) {

        const head = this.document.getElementsByTagName('head')[0];

        const themeLink = this.document.getElementById(
            'client-theme'
        ) as HTMLLinkElement;
        if (themeLink) {
            themeLink.href = styleName;
        } else {
            const style = this.document.createElement('link');
            style.id = 'client-theme';
            style.rel = 'stylesheet';
            style.href = `${styleName}`;

            head.appendChild(style);
        }
    }

    getStyle(route) {
        return 'user-theme.css';
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const style = this.getStyle(route);
        return this.loadStyle(style);
    }
}