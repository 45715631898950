import { Component, Input, OnInit } from '@angular/core';
import { Route, RouterOutlet } from '@angular/router';
import { MenuHelper } from '@intranet/lib/config';

@Component({
  selector: 'app-front-tab-group',
  templateUrl: './front-tab-group.component.html',
  styleUrls: ['./front-tab-group.component.scss']
})
export class FrontTabGroupComponent implements OnInit {
  @Input() groupId: string;
  menusList = MenuHelper.menuListGroup;
  year = new Date().getFullYear();
  systemName: string;
  constructor() {
  }

  ngOnInit() {
  }


  prepareRoute(outlet: RouterOutlet) {
    // tslint:disable-next-line: no-string-literal
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
