<modal-header [title]="modalTitle" [activeModal]="activeModal">
</modal-header>

<modal-body>
  <div class="row row-discussion-info pb-2">
    <div class="col-5 info-column">
      <ng-container *ngIf="summaryInfo">
        <div class="display-break-spaces summary-info-comment">
          <ng-container *ngIf="summaryInfo.reviewPerformanceFormMember.resultLevelName">
            <div class="header-summary">
              <div class="row">
                <div class="col-4">
                  <p class="title">Current postition & level</p>
                  <p class="text">{{summaryInfo.reviewPerformanceFormMember.currentPosition}}</p>
                  <p class="text">{{summaryInfo.reviewPerformanceFormMember.currentLevelName}}</p>
                </div>
                <div class="col-4">
                  <p class="title">New postition & level</p>
                  <p class="text">{{summaryInfo.reviewPerformanceFormMember.resultPosition}}</p>
                  <p class="text">{{summaryInfo.reviewPerformanceFormMember.resultLevelName}}</p>
                </div>
                <div class="col-4">
                  <p class="title">Increased level</p>
                  <p class="text">
                    <ng-container *ngIf="summaryInfo.reviewPerformanceFormMember.hasChangePosition && summaryInfo.reviewPerformanceFormMember.currentLevelName">
                      <p class="text-position-changed">Position Changed</p>
                    </ng-container>
                    <ng-container *ngIf="!summaryInfo.reviewPerformanceFormMember.hasChangePosition">
                      <p class="text-increase" *ngIf="summaryInfo.reviewPerformanceFormMember.resultLevelName">
                        <i *ngIf="summaryInfo.reviewPerformanceFormMember.increaseLevel === 0" class="text-primary" nz-icon nzType="dash" nzTheme="outline"></i>
                        <i *ngIf="summaryInfo.reviewPerformanceFormMember.increaseLevel > 0" class="text-green-success" nz-icon nzType="rise" nzTheme="outline"></i>
                        <span>{{summaryInfo.reviewPerformanceFormMember.increaseLevel}}</span>
                      </p>
                    </ng-container>
                  </p>
                </div>
              </div>
            </div>
            <hr />
          </ng-container>
          <ng-container *ngIf="summaryInfo.summaryAppraiser || summaryInfo.summaryAppraiser2">
            <nz-descriptions [nzColumn]="1" nzLayout="vertical" nzTitle="1. Summary">
              <nz-descriptions-item *ngIf="summaryInfo.summaryAppraiser" nzTitle="What are the highlights of appraisee's job knowledge & competencies?">
                {{summaryInfo.summaryAppraiser}}
              </nz-descriptions-item>
              <nz-descriptions-item *ngIf="summaryInfo.summaryAppraiser2" nzTitle="What can be performed better by the appraisee to help achieve larger team goals?">
                {{summaryInfo.summaryAppraiser2}}
              </nz-descriptions-item>
            </nz-descriptions>
          </ng-container>
          <ng-container *ngIf="summaryInfo.workAchievementAppraiser || summaryInfo.workAchievementAppraiser2">
            <nz-descriptions [nzColumn]="1" nzLayout="vertical" nzTitle="2. Work achievement">
              <nz-descriptions-item *ngIf="summaryInfo.workAchievementAppraiser"
                nzTitle="What are the contributions the appraisee has done remarkably in the previous year/past time?">
                {{summaryInfo.workAchievementAppraiser}}
              </nz-descriptions-item>
              <nz-descriptions-item *ngIf="summaryInfo.workAchievementAppraiser2"
                nzTitle="What are the improvements the appraisee has done successfully in the previous year/past time?">
                {{summaryInfo.workAchievementAppraiser2}}
              </nz-descriptions-item>
            </nz-descriptions>
          </ng-container>
          <ng-container *ngIf="summaryInfo.directionForTheComingYearAppraiser || summaryInfo.directionForTheComingYearAppraiser2">
            <nz-descriptions [nzColumn]="1" nzLayout="vertical" nzTitle="3. Direction for the coming year">
              <nz-descriptions-item *ngIf="summaryInfo.directionForTheComingYearAppraiser"
                nzTitle="What is the direction/position that might unlock the appraisee's potential that you're planning to conduct in the next 12 months?">
                {{summaryInfo.directionForTheComingYearAppraiser}}
              </nz-descriptions-item>
              <nz-descriptions-item *ngIf="summaryInfo.directionForTheComingYearAppraiser2"
                nzTitle="What is expected to be performed in the next 12 months by the appraisee to match such direction/position?">
                {{summaryInfo.directionForTheComingYearAppraiser2}}
              </nz-descriptions-item>
            </nz-descriptions>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <!-- COMMENTS -->
    <div class="col-7 h-100">
      <nz-spin nzSize="large" [nzSpinning]="isLoading" class="h-100">
        <div class="info-key bold">
          Comments
          <span class="text-feedback">If you have any feedback or question please leave comment below</span>
        </div>

        <div class="comment-section">
          <div class="comment-list mb-1" [id]="listCommentElementId">
            <div class="comment-item p-2" *ngFor="let comment of comments" [ngClass]="{ 'hoverable': !comment?.isEditing }">
              <div class="comment-avatar">
                <app-avatar [size]="40" [avatarSource]="comment.user?.avatarUrl" [avatarText]="comment.user?.fullName">
                </app-avatar>
              </div>

              <div class="comment-content">
                <div class="comment-header pb-2">
                  <span class="comment-name">{{ comment.user ? comment.user.fullName : '' }}</span>
                  <span class="comment-datetime mr-2">{{ comment.lastEditTime | amDateFormat: 'HH:mm DD/MM/YYYY' }}</span>
                </div>

                <div class="comment-body" *ngIf="!comment.isEditing">
                  <ng-container *ngIf="!comment.isEditing">
                    <p class="comment-data display-break-spaces">{{comment.comment}}</p>
                    <div class="comment-actions pl-2" *ngIf="comment.isEditable">
                      <i *hasPermission="['ReviewPerformanceSummaryDiscuss', 'UpdateComment']" nz-icon nzType="edit" nzTheme="outline" (click)="editComment(comment)" class="text-warning pointer mr-2 action-button"></i>
                    </div>
                  </ng-container>
                </div>

                <ng-container *hasPermission="['ReviewPerformanceSummaryDiscuss', 'UpdateComment']">
                  <div class="comment-user-area pb-1" *ngIf="comment.isEditable && comment.isEditing">
                    <div class="comment-user-input mr-2">
                      <textarea class="comment-text-input" name="content" [(ngModel)]="comment.tempComment" placeholder="Write a comment"></textarea>
                    </div>
                    <div class="comment-user-actions update-actions">
                      <i nz-icon nzType="send" nzTheme="outline" class="mr-1 text-success pointer action-button mb-2 mr-2" nz-tooltip
                        nzTooltipTitle="Save comment" nz-popconfirm nzPopconfirmTitle="Are you sure to cancel updating this comment?" (nzOnConfirm)="updateComment(comment)"></i>

                      <i nz-icon nzType="close-circle" nzTheme="outline" class="text-danger pointer action-button mr-2"
                        nz-tooltip nzTooltipTitle="Cancel updating comment"
                        nz-popconfirm nzPopconfirmTitle="Are you sure to cancel updating this comment?"
                        (nzOnConfirm)="cancelUpdate(comment)"></i>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="no-comment-item p-2" *ngIf="!(comments?.length > 0)">
              <nz-empty [nzNotFoundContent]="noCommentTpl"></nz-empty>
              <ng-template #noCommentTpl>
                This performance review doesn't have any comments.
              </ng-template>
            </div>
          </div>

          <div class="comment-user-area pb-1" *hasPermission="['ReviewPerformanceSummaryDiscuss', 'CreateComment']">
            <div class="comment-user-avatar mr-2">
              <app-avatar [size]="40" [avatarSource]="currentUser?.avatarUrl" [avatarText]="currentUser?.fullName">
              </app-avatar>
            </div>
            <div class="comment-user-input mr-2">
              <textarea class="comment-text-input" name="content" [(ngModel)]="currentComment" placeholder="Write a comment"></textarea>
            </div>
            <div class="comment-user-actions">
              <button class="btn btn-primary" (click)="createComment()" [disabled]="isLoading">
                <i nz-icon nzType="send" nzTheme="outline" class="mr-1"></i> Send
              </button>
            </div>
          </div>
        </div>
      </nz-spin>
    </div>
  </div>
</modal-body>
