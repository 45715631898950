<modal-header [title]="'Register Compensation Hour'" [activeModal]="activeModal">
</modal-header>
<modal-body>
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-6 form-group">
        <label class="required">Start Time</label>
        <nz-time-picker #startTimeComponent name="startTime" placeholder="Start time" nzFormat="HH:mm" formControlName="startTime" [nzHideDisabledOptions]="true">
        </nz-time-picker>
        <span class="text-danger" *ngIf="startTime?.touched && startTime?.errors?.required">
          Start time is required
        </span>
      </div>
      <div class="col-6 form-group">
        <label class="required">End Time</label>
        <nz-time-picker #endTimeComponent name="endTime" placeholder="End time" [nzFormat]="dateFormat" formControlName="endTime" [nzHideDisabledOptions]="true">
        </nz-time-picker>
        <span class="text-danger" *ngIf="endTime?.touched && endTime?.errors?.required">
          End time is required
        </span>
      </div>
    </div>
  </form>
</modal-body>
<modal-footer>
  <div class="w-100">
    <div class="d-flex flex-row justify-content-between">
      <div class="form-group ml-auto">
        <div class="float-right">
          <button class="btn btn-cancel" (click)="activeModal.close()"><i class="far fa-times-circle mr5"></i>Cancel</button>
          <button class="ml-2 btn btn-primary" type="submit" (click)="submit()">
            Register
          </button>
        </div>
      </div>
    </div>
  </div>
</modal-footer>
