
export enum CheckInCheckOutStatusEnum {
  None = 0,
  New = 1,
  Approved = 2,
  Rejected = 3,
}



