<!-- <div class="ml-0 mr-0 pr-2 member-table header-format row">
  <div [class]="'col-' + (12 - extraInfoColumnBootstrapWidth)">
    <div class="header-wrapper">
      <div class="row">
        <div *ngFor="let header of headers" [class]="header.classes">{{header.value}}</div>
      </div>
    </div>
  </div>
  <div [class]="'col-' + extraInfoColumnBootstrapWidth" class="pr-0">
    {{extraInfoColumnName}}
  </div>
</div>

<div [style.overflow-y]="dataOverflowYBehavior">
  <div class="ml-0 mr-2 pr-0 member-table row member-row" *ngFor="let member of memberData">
    <div [class]="'col-' + (12 - extraInfoColumnBootstrapWidth)">
      <div class="member-table-cell">
        <div class="avatar-position">
          <app-avatar [size]="40" [avatarSource]="member.avatarUrl" [avatarText]="member.fullName"></app-avatar>
        </div>
        <p class="font-12 position-text">{{member.fullName}}
          <ng-container *ngIf="extraInfoForMemberNameTemplate" [ngTemplateOutlet]="extraInfoForMemberNameTemplate" [ngTemplateOutletContext]="{ $implicit: member }">
          </ng-container>
        </p>
        <div class="row">
          <div *ngFor="let fmt of format" [class]="fmt.classes">{{member[fmt.key]}}</div>
        </div>
      </div>
    </div>

    <div *ngIf="extraInfoColumnBootstrapWidth > 0" [class]="'col-' + extraInfoColumnBootstrapWidth" class="pr-0">
      <ng-container *ngIf="extraInfoColumnTemplate" [ngTemplateOutlet]="extraInfoColumnTemplate" [ngTemplateOutletContext]="{ $implicit: member }">
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="memberData?.length === 0">
    <div class="ml-0 mr-2 pr-0 member-table row">
      <div class="col">
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="noDataText"></nz-empty>
      </div>
    </div>
  </ng-container>
</div> -->


<nz-spin [nzIndicator]="indicatorTpl" [nzSpinning]="isLoading" class="table-member-view scroll-bar" #tableMemberView (scroll)="handleScrollTable($event)">
  <ng-container *ngIf="memberData?.length > 0 || isLoading else emptyTemplate">
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Joined Date</th>
          <th>Seniority At Restaff</th>
          <th>Total Year Of Experience</th>
          <th *ngIf="extraInfoColumnName">{{extraInfoColumnName}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let member of memberData">
          <td>
            <div class="name">
              <app-avatar [size]="40" [avatarSource]="member.avatarUrl" [avatarText]="member.fullName"></app-avatar>
              <div class="short-information">
                <div>
                  {{member?.fullName}}
                  <ng-container *ngIf="extraInfoForMemberNameTemplate" [ngTemplateOutlet]="extraInfoForMemberNameTemplate"
                    [ngTemplateOutletContext]="{$implicit: member}">
                  </ng-container>
                </div>
                <div class="position">{{member?.position}}</div>
              </div>
            </div>
          </td>
          <td>{{member?.joinedDate}}</td>
          <td>{{member?.seniority}}</td>
          <td>{{member?.totalYearsOfExperience}}</td>
          <td *ngIf="extraInfoColumnTemplate">
            <ng-container [ngTemplateOutlet]="extraInfoColumnTemplate" [ngTemplateOutletContext]="{ $implicit: member }"></ng-container>
          </td>
          <div [ngClass]="isShowBoxShadow ? 'shadow-wrapper': 'shadow-wrapper-hidden'"></div>
        </tr>
      </tbody>
    </table>
  </ng-container>
</nz-spin>

<ng-template #indicatorTpl>
  <i nz-icon nzType="loading" class="loading-indicator"></i>
</ng-template>

<ng-template #emptyTemplate>
  <nz-empty></nz-empty>
</ng-template>
