import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  NewsFeed, WallNewsFeedService, SurveyService,
  FeedRealtimeService, PollRealtimeService, FileExtensionType, CDNService
} from '@intranet/lib/data-access';
import { ActivatedRoute, Router } from '@angular/router';
import { PubSubService } from '@intranet/lib/services';
import { ModalService } from '@intranet/index';
import { BaseUserComponent } from '@intranet/lib/components';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-newsfeed-announcement-details',
  templateUrl: './announcement-details.component.html',
  styleUrls: ['./announcement-details.component.scss'],
  styles: [],
})
export class AnnouncementDetailsPageComponent extends BaseUserComponent implements OnInit {
  activeModal: NgbModalRef;
  feed: NewsFeed;
  feedId: string = null;
  isLoading: boolean;
  FileExtensionType = FileExtensionType;

  constructor(
    private route: ActivatedRoute,
    private newsFeedService: WallNewsFeedService,
    private router: Router,
    private modalService: ModalService,
    private feedRealtimeService: FeedRealtimeService,
    private pollRealtimeService: PollRealtimeService,
    private pubsub: PubSubService,
    private surveyService: SurveyService,
    private cdnService: CDNService
  ) {
    super();

    this.route.queryParams.subscribe(params => { });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.feedId = params.id;
      this.getFeed();
    });
  }

  getFeed() {
    this.newsFeedService.getFeed(
      this.feedId,
      (resp: NewsFeed) => {
        if (resp) {
          this.feed = resp;
          if (this.feed.files && this.feed.files.length) {
            // build download url
            this.feed.files.forEach(item => item.url = this.newsFeedService.getFileUrl(item.fileId));
          }
        }
      },
      (error: any) => { },
      () => {
        this.isLoading = false;
      },
    );
  }
  async click(document) {
    await this.cdnService.downloadFileEntity(document.fileId, document.fileName);
  }

}
