import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class CalendarService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/calendar';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getEventsByDate(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `get-events-by-date`;
    const data = params || {};
    data.noLoading = true;
    return this.getByUrl(url, data, successCallback, failedCallback, completeCallback);
  }
}
