<form (ngkSubmit)="onSubmit()" [ngkForm]="true" #frm="ngForm">
  <modal-header [title]="feed.isCreate ? 'Edit Announcement' : 'Create Announcement'" [(canBack)]="canBack"
    [activeModal]="activeModal">
  </modal-header>
  <modal-body>
    <ng-template *ngIf="!canBack then generalConfiguration"></ng-template>
    <ng-template *ngIf="canBack then postConfiguration"></ng-template>
  </modal-body>
  <modal-footer>
    <div class="w-100">
      <div class="d-flex flex-row justify-content-between">
        <div class="form-group ml-auto">
          <div class="float-right">
            <button class="btn btn-cancel" (click)="activeModal.close()"><i class="far fa-times-circle mr5"></i>Close</button>
            <button class="ml-2 btn btn-primary" type="submit"><i class="fas fa-save mr5"></i>{{feed.id ? 'Save' : 'Publish'}}</button>
          </div>
        </div>
      </div>
    </div>
  </modal-footer>
</form>

<ng-template #generalConfiguration>
  <div class="new-postbox">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label><strong>Title</strong></label>
          <input required nz-input placeholder="Create new title" [(ngModel)]="feed.title" nzSize="large" name="title"
            [ngModelOptions]="{ standalone: true }" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <label><strong>Content</strong></label>
        <app-feed-editor #editor [(preview)]="feed.contentHtml" [(content)]="feed.description"
          [editorConfig]="editorConfig" placeholder="Describe something more" [isLoading]="isLoading"
          [(hashtags)]="feed.hashtags" [(mentions)]="feed.mentions">
        </app-feed-editor>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-6">
        <div class="form-group">
          <label><strong>Due date</strong></label>
          <nz-date-picker [nzShowTime]="false" [nzShowToday]="true" [nzDisabledDate]="disabledEndDate" nzShowTime
            nzFormat="dd/MM/yyyy" [(ngModel)]="feed.endDate" nzPlaceHolder="DD/MM/YYYY"
            [ngModelOptions]="{ standalone: true }">
          </nz-date-picker>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col mt-2">
        <button type="button" class="btn btn-primary btn-configuration w-100" (click)="showPostConfiguration()">
          <img src="assets/images/front/shortcut/setting.svg" type="local" />
          Post Configuration</button>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col mt-2">
        <app-internal-upload-file [model]="modelUpload"></app-internal-upload-file>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #postConfiguration>
  <div class="form-row">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.allowLike" name="allowLike" [ngModelOptions]="{ standalone: true }">Allow
        like</label>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.allowComment" name="allowComment"
        [ngModelOptions]="{ standalone: true }">Allow comment</label>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.isShow" name="isShow" [ngModelOptions]="{ standalone: true }">Visible</label>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.isSendNotification" name="isSendNotification"
        [ngModelOptions]="{ standalone: true }">Send Email Notification</label>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.isImportant" name="isImportant"
        [ngModelOptions]="{ standalone: true }">Highlight</label>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col" *ngIf="feed.isImportant">
      <label for="period">Highlight From Date</label>
      <nz-date-picker [nzFormat]="dateFormat" [(ngModel)]="feed.beginHighlightDate" (ngModelChange)="onChangeHighlightBeginDate($event)" [nzShowToday]="true"></nz-date-picker>
    </div>
    <div class="form-group col" *ngIf="feed.isImportant">
      <label for="period">Highlight To Date</label>
      <nz-date-picker [nzFormat]="dateFormat" [(ngModel)]="feed.endHighlightDate" (ngModelChange)="onChangeHighlightEndDate($event)" [nzDisabledDate]="disableEndHighlighDate" [nzShowToday]="true"></nz-date-picker>
    </div>
  </div>
</ng-template>