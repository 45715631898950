import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CreateFeedBaseComponent } from '../create-feed-base/create-feed-base.component';
import { EventTypeEnum, NewsFeed, WallNewsFeedService } from '@intranet/lib/data-access';
import { FormBuilder } from '@angular/forms';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { Constants } from '@intranet/index';

@Component({
  selector: 'app-newsfeed-create-standard-post',
  templateUrl: './create-standard-post.component.html',
  styleUrls: ['../create-feed-base/create-feed-base.component.scss'],
  styles: [],
})
export class CreateStandardPostComponent extends CreateFeedBaseComponent implements OnInit, AfterViewInit {
  constructor(protected newsFeedService: WallNewsFeedService, protected fb: FormBuilder, protected pubsub: PubSubService) {
    super(newsFeedService, fb, pubsub);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    this.setTitle('Create Post');
  }

  public getServiceMethod(): (
    params: any,
    successCallback?: (params: any) => void,
    failCallback?: (params: any) => void,
    completeCallback?: (params: any) => void,
  ) => void {
    return this.newsFeedService.createPost.bind(this.newsFeedService);
  }


  public getServiceUpdateMethod(): (
    params: any,
    successCallback?: (params: any) => void,
    failCallback?: (params: any) => void,
    completeCallback?: (params: any) => void,
  ) => void {
    return this.newsFeedService.updatePost.bind(this.newsFeedService);
  }
}
