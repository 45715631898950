import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NewsFeed, NewsFeedTypeEnum, SearchNewsFeed, WallNewsFeedService } from '@intranet/lib/data-access';

@Component({
  selector: 'app-newsfeed-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
})
export class ActivityPageComponent implements OnInit {
  year = new Date().getFullYear();

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {}
}
