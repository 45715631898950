import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CreateFeedBaseComponent } from '../create-feed-base/create-feed-base.component';
import { WallNewsFeedService, NewsFeedTypeEnum, InternalUploadFileDto } from '@intranet/lib/data-access';
import { FormBuilder } from '@angular/forms';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { NewsFeed } from '../../../../hrm-lib/lib/data-access/models/news-feed.model';
import { NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { differenceInCalendarDays } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { Constants } from '@intranet/index';

@Component({
  selector: 'app-newsfeed-create-announcement',
  templateUrl: './create-announcement.component.html',
  styleUrls: ['../create-feed-base/create-feed-base.component.scss'],
  styles: [],
})
export class CreateAnnouncementComponent implements OnInit {
  dateFormat = 'dd/MM/yyyy';

  feed: NewsFeed = new NewsFeed();
  activeModal: NgbActiveModal;
  canBack = false;
  dateRanges: Date[];
  modelUpload: InternalUploadFileDto = new InternalUploadFileDto();

  isLoading = false;
  editorConfig = {
    placeholderHeight: 150,
    height: 250,
    toolbar: 'templateButton | formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat',
    plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help',
  };

  constructor(protected newsFeedService: WallNewsFeedService, protected fb: FormBuilder, protected pubsub: PubSubService, modalRef: NgbActiveModal) {
    this.activeModal = modalRef;
  }

  onChangeHighlightBeginDate(date) {
    this.feed.beginHighlightDate = date;
    this.feed.endHighlightDate = null;
  }

  onChangeHighlightEndDate(date) {
    this.feed.endHighlightDate = date;
  }

  ngOnInit() {
    if (this.feed.id) {
      if (this.feed.beginDate) {
        this.feed.beginDate = new Date(this.feed.beginDate);
      }

      if (this.feed.endDate) {
        this.feed.endDate = new Date(this.feed.endDate);
      }

      if (this.feed.beginHighlightDate && this.feed.endHighlightDate) {
        this.dateRanges = [new Date(this.feed.beginHighlightDate), new Date(this.feed.endHighlightDate)];
      }
    } else {
      this.feed.id = uuidv4();
      this.feed.isCreate = true;
      this.feed.beginDate = new Date();
      this.feed.allowComment = true;
      this.feed.allowLike = true;
      this.feed.isShow = true;
      this.feed.newsFeedTypeId = NewsFeedTypeEnum.ANNOUNCEMENT;
    }
    this.modelUpload.entityId = this.feed.id;
    this.modelUpload.entityType = Constants.ENTITY_TYPE.NewsFeed;
  }

  onSubmit() {
    if (this.canBack) {
      this.canBack = false;
      return false;
    }

    if (this.feed.id && !this.feed.isCreate) {
      this.newsFeedService.updateUrl('update-announcement', this.feed, null, res => {
        this.activeModal.close(1);
      });
    } else {
      this.newsFeedService.postByUrl('create-announcement', this.feed, res => {
        this.activeModal.close(1);
      });
    }
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.feed.endDate) {
      return false;
    }
    return startValue.getTime() > this.feed.endDate.getTime();
  }

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.feed.beginDate) {
      return false;
    }
    return endValue.getTime() <= this.feed.beginDate.getTime();
  }

  showPostConfiguration = () => {
    this.canBack = true;
  }

  disableEndHighlighDate = (current: Date): boolean => {
    if (!this.feed || !this.feed.beginHighlightDate) {
      return true;
    }

    return differenceInCalendarDays(this.feed.beginHighlightDate, current) > 0;
  }

  onChangeRange(event) {
    this.feed.beginHighlightDate = this.dateRanges[0];
    if (this.dateRanges[1]) {
      this.feed.endHighlightDate = this.dateRanges[1];
    }
  }
}
