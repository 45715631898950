<ng-container *ngIf="widget && !isClientPage">
  <nz-card [nzBordered]="false" [nzLoading]="isLoading">
    <div class="widget-header">
      <div class="widget-title">
        <ng-container *ngIf="isDetail">
          <span (click)="hideDetail()" class="vector-right-icon btn-link"><img  src="assets/images/front/shortcut/vector-right.svg" ></span>
          {{title}}
        </ng-container>
        <ng-container *ngIf="!isDetail">
          {{widget.data.title}}
        </ng-container>
      </div>
      <div class="widget-control widget-control-sm-fully">
        <div class="widget-control-wrapper">
          <ng-container *ngIf="data && data.teamSkills && data.departmentGroups && data.departmentGroups.length > 1 && !isDetail">
            <nz-select class="" (ngModelChange)="modelChanged($event)" nzShowSearch nzAllowClear nzPlaceHolder="Select a groups" [(ngModel)]="searchData.departmentIds">
              <nz-option *ngFor="let option of data.departmentGroups" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
            </nz-select>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="!isDetail && data" class="scroll-area-lg">
      <div *ngFor="let item of data.teamSkills" class="widget-content">
        <div class="widget-content-outer">
          <div class="widget-content-wrapper">
            <div (click)="viewDetail(item)" class="widget-content-left">
              <div class="widget-numbers fs-14">{{item.skillName}} ({{item.count}})</div>
            </div>
            <div class="widget-content-right">
              <div class="percent-team">{{item.percent}}%</div>
            </div>
          </div>
          <div class="widget-progress-wrapper mt-1">
            <div class="progress-bar-sm progress-bar-animated-alt progress">
              <div role="progressbar" aria-valuenow="item.percent" aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                [style]="{width : item.percent +'%',backgroundColor : item.color }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isDetail">
      <app-widget-skill-detail [(searchData)]="searchData"></app-widget-skill-detail>
    </ng-container>
  </nz-card>
</ng-container>

<ng-container *ngIf="isClientPage">
  <!-- <div class="pie-leave" [ngClass]="{ 'client-page-item': isClientPage }">
    <ngx-charts-pie-chart [view]="data.chartData.view" [scheme]="data.chartData.colorScheme" [results]="data.teamSkills" [legendTitle]="data.chartData.legendTitle"
      [legend]="data.chartData.showLegend" [legendPosition]="data.chartData.legendPosition" [labels]="data.chartData.showLabels" [doughnut]="data.chartData.isDoughnut"
      [arcWidth]="data.chartData.arcWidth" (select)="selectPie($event)">
    </ngx-charts-pie-chart>
    <div class="legend-container float-left pl-5 pt-3" [style.width]="data.chartData?.view[0] + 'px'">
      <div class="legend-title-text pb-2">Legend</div>
      <div>
        <div class="pointer all-skills" [ngClass]="{'legend-active': !filterSkillName}" (click)="selectPie(null)">
          <span class="legend-label-text pr-2 pl-2">All skills ({{data?.allMembers?.length || 0}})</span>
        </div>
      </div>

      <div *ngFor="let skill of data.teamSkills">
        <div class="pointer legend-wrapper" [ngClass]="{'legend-active': skill.name === filterSkillName}" (click)="selectPie(skill)">
          <span class="legend-bullet mr-1" [style.background-color]="skill.color"></span>
          <span class="legend-label-text">{{skill.name}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="client-page-item" [style.width]="'calc(100% - ' + (data.chartData?.view[0] || 0) + 'px)'">
    <p class="pb-1">Details for all members
      <ng-container *ngIf="filterSkillName">with skill <span class="client-page-select-title">{{filterSkillName}}</span></ng-container>
      <ng-container *ngIf="!filterSkillName"><span class="client-page-select-title">({{memberDisplayData?.length || 0}})</span></ng-container>
    </p>
    <app-widget-skill-detail [data]="memberDisplayData" [displayMode]="displayMode"></app-widget-skill-detail>
  </div> -->

  <ng-template #indicatorTpl>
    <i nz-icon nzType="loading" class="loading-indicator"></i>
  </ng-template>

  <nz-spin [nzIndicator]="indicatorTpl" [nzSpinning]="isLoading">
    <div class="widget-client-container">
      <div class="client-skill-chart" *ngIf="data?.chartData">
        <ngx-charts-pie-chart [view]="data.chartData.view" [scheme]="data.chartData.colorScheme" [results]="data.teamSkills" [legendTitle]="data.chartData.legendTitle"
          [legend]="data.chartData.showLegend" [legendPosition]="data.chartData.legendPosition" [labels]="data.chartData.showLabels"
          [doughnut]="data.chartData.isDoughnut" [arcWidth]="data.chartData.arcWidth" (select)="selectPie($event)">
        </ngx-charts-pie-chart>
        <div class="legend-container">
          <div class="legend-title">Legend</div>
          <div class="information-wrapper">
            <div class="all-members" [ngClass]="{'all-members-active': !filterSkillName}" (click)="selectPie(null)">
              <span>All skills ({{data?.allMembers?.length || 0}})</span>
            </div>
            <div *ngFor="let skill of data.teamSkills" class="legend-wrapper" [ngClass]="{'legend-wrapper-active': skill.name === filterSkillName}"
              (click)="selectPie(skill)">
              <span class="legend-bullet" [style.background-color]="skill.color"></span>
              <span class="legend-label">{{skill.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="member-table">
        Details for all members
        <ng-container *ngIf="filterSkillName">with skill <span class="client-page-select-title">{{filterSkillName}}</span></ng-container>
        <ng-container *ngIf="!filterSkillName"><span class="client-page-select-title">({{memberDisplayData?.length || 0}})</span></ng-container>
        <app-widget-skill-detail [data]="memberDisplayData" [displayMode]="displayMode" [isLoading]="false"></app-widget-skill-detail>
      </div>
    </div>
  </nz-spin>
</ng-container>
