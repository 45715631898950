import * as moment from 'moment';
import { Constants } from '../config/constants';
import { UrlConstant } from '../config/urlConstant';

export class GameHelper {
  static getPosterDisplayInfo(displayInfo: any) {
    let posterDisplayInfo = null;

    if (!displayInfo || !displayInfo.startDate || !displayInfo.endDate) {
      return posterDisplayInfo;
    }

    const setMinutesFn = (dateInString: string, min: number) => {
      const date = moment(dateInString).toDate();
      return date.setMinutes(date.getMinutes() + min);
    };

    const startTime = setMinutesFn(displayInfo.startDate, -10);
    const endTime = setMinutesFn(displayInfo.endDate, 10);
    const now = Date.now();

    const isRunOutOfPrizes = displayInfo.totalPrizesReceived >= displayInfo.totalPrizes;

    if (startTime <= now && now <= endTime && !isRunOutOfPrizes) {
      posterDisplayInfo = {
        imageUrl: Constants.ChristmasGamePosterImagePath,
        link: UrlConstant.GameUrl.ROOT,
      };
    }

    return posterDisplayInfo;
  }
}
