
import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { BaseService } from '../base.service';
import { ReviewPerformanceSummary } from '../../models/review/review-summary.model';

@Injectable({
  providedIn: 'root',
})
export class ReviewSummaryService extends BaseService {
  hostUrl = '/api/review-summary';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  view(formMemberId: string, noLoading: boolean = false): Promise<ReviewPerformanceSummary> {
    return this.httpClient.getObservable(this.hostUrl, { formMemberId, noLoading }).toPromise();
  }
}
