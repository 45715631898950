export enum BenefitDataTypeEnum {
  Number = 1,
  String = 2,
  Boolean = 3,
  Date = 4,
  Enum = 5
}

export enum BenefitDataTypeConstEnum {
  Number = 'Number',
  String = 'String',
  Boolean = 'Boolean',
  Date = 'Date',
  Enum = 'Enum'
}
