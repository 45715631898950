import { Component, Input, OnInit } from '@angular/core';
import { Constants } from '@intranet/lib/config/constants';
import { PurchaseHistoryService } from '@intranet/lib/data-access/services/purchase-history.service';
import { Toastr } from '@intranet/lib/settings/services/toastr';

@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.scss']
})
export class PurchaseHistoryComponent implements OnInit {
  @Input() purchaseRequestId: string;

  statusEnum = Constants.STATUS_ENUMS;
  purchaseHistories: any[] = [];

  constructor(private purchaseHistory: PurchaseHistoryService) { }

  ngOnInit() {
    if (this.purchaseRequestId) {
      this.loadData();
    }
  }

  private async loadData() {
    try {
      this.purchaseHistories = await this.purchaseHistory
        .getAllByPurchaseRequestId(this.purchaseRequestId);
    }
    catch (err) {
      let message = 'Cannot get histories of purchase request, please try again';
      if (err.message) {
        message = err.message;
      }
      Toastr.error(message);
    }
  }
}
