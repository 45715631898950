<nz-collapse [nzAccordion]="false">
  <nz-collapse-panel *ngFor="let dataWeekly of listWeekly" [nzHeader]="headerPanel" [nzActive]="dataWeekly.active">
    <ng-template #headerPanel>
      <ng-container *ngIf="dataWeekly">
        <nz-descriptions [nzTitle]="'Week ' + (dataWeekly.weekOfMonth + 1) + ' ' + (dataWeekly.startDate | date : 'MM/yyyy')" [nzColumn]="4" nzBordered="false"
          nzLayout="vertical" class="lg-screen">
          <nz-descriptions-item nzTitle="Date">
            {{dataWeekly.startDate | date : 'dd/MM/yyyy'}} - {{dataWeekly.endDate | date : 'dd/MM/yyyy'}}
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="Accumulated hours">{{dataWeekly.accumulatedHours}}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Standard hours">{{dataWeekly.totalAllHoursWorking}}</nz-descriptions-item>
          <nz-descriptions-item nzTitle="Difference (+/-)">
            <span class="text-danger" *ngIf="dataWeekly.totalRemainHoursWorking < 0">{{dataWeekly.totalRemainHoursWorkingData}}</span>
            <span class="text-success" *ngIf="dataWeekly.totalRemainHoursWorking >= 0"><i nz-icon nzType="check-circle" nzTheme="outline"></i></span>
            <span class="ml-1" *ngIf="dataWeekly.totalMissingHoursWorking > 0" nz-popover nzPopoverTrigger="hover" [nzPopoverContent]="explainMissingHoursFieldTemplate" >
          <i nz-icon nzType="info-circle" nzTheme="outline"></i>
          <ng-template #explainMissingHoursFieldTemplate>
             {{dataWeekly.totalRemainHoursWorking}} ({{dataWeekly.totalRemainHoursWorkingData}} + {{dataWeekly.totalMissingHoursWorking}})
          </ng-template>
          </span>
          </nz-descriptions-item>
        </nz-descriptions>

        <div class="sm-screen sm-header-information">
          <div class="week-title">
            {{'Week ' + (dataWeekly.weekOfMonth + 1) + ' ' + (dataWeekly.startDate | date : 'MM/yyyy')}}
          </div>
          <div>
            <span>Date:</span>
            <span>{{dataWeekly.startDate | date : 'dd'}} - {{dataWeekly.endDate | date : 'dd/MM'}}</span>
          </div>
          <div>
            <span>Accumulated:</span>
            <span>{{dataWeekly.accumulatedHours}}</span>
          </div>
          <div>
            <span>Standard:</span>
            <span>{{dataWeekly.totalAllHoursWorking}}</span>
          </div>
          <div>
            <span>Difference (+/-):</span>
            <span>
            <span class="text-danger" *ngIf="dataWeekly.totalRemainHoursWorking < 0">{{dataWeekly.totalRemainHoursWorkingData}}</span>
            <span class="text-success" *ngIf="dataWeekly.totalRemainHoursWorking >= 0"><i nz-icon nzType="check-circle" nzTheme="outline"></i></span>
            <span class="ml-1" *ngIf="dataWeekly.totalMissingHoursWorking > 0" nz-popover nzPopoverTrigger="hover" [nzPopoverContent]="explainMissingHoursFieldTemplate" >
            <i nz-icon nzType="info-circle" nzTheme="outline"></i>
            <ng-template #explainMissingHoursFieldTemplate>
              {{dataWeekly.totalRemainHoursWorking}} ({{dataWeekly.totalRemainHoursWorkingData}} + {{dataWeekly.totalMissingHoursWorking}})
            </ng-template>
            </span>
            </span>
          </div>
        </div>
      </ng-container>

    </ng-template>
    <nz-table #basicTable [nzPageSize]="15" nzShowPagination="false" [nzData]="dataWeekly.listDetails" nzTableLayout="fixed">
      <thead>
        <tr>
          <th>Date</th>
          <th>
            <span class="lg-screen">Check in</span>
            <span class="sm-screen">In</span>
          </th>
          <th>
            <span class="lg-screen">Check out</span>
            <span class="sm-screen">Out</span>
          </th>
          <th class="difference-column">
            <span class="lg-screen">Difference (+/-)</span>
            <span class="sm-screen">(+/-)</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td class="date">
            <span class="day" [ngClass]="data.classUnderline" class="lg-screen">
            {{data.startTime | date : 'EEE'}}
            </span>

            <span [ngClass]="data.classUnderline">
            <span class="lg-screen">{{data.startTime | date : 'dd/MM/yyyy'}}</span>
            <span class="sm-screen">{{data.startTime | date : 'dd/MM'}}</span>
            </span>
          </td>
          <td>
            <p [class]="data.classUnderline">
              <ng-container *ngIf="data.startTime != data.startTimeAccumulated">
                <span nzTooltipTitle="Execution time : {{data.startTime | date:'HH:mm'}}" nzTooltipPlacement="topRight" nz-tooltip>
                {{data.startTimeAccumulated | date:'HH:mm'}}
                </span>
              </ng-container>
              <ng-container *ngIf="data.startTime == data.startTimeAccumulated">
                {{data.startTime | date:'HH:mm'}}
              </ng-container>
            </p>
          </td>
          <td>
            <p [class]="data.classUnderline">
              <ng-container *ngIf="data.endTime != data.endTimeAccumulated">
                <span nzTooltipTitle="Execution time : {{data.endTime | date:'HH:mm'}}" nzTooltipPlacement="topRight" nz-tooltip>
                {{data.endTimeAccumulated | date:'HH:mm'}}
                </span>
              </ng-container>
              <ng-container *ngIf="data.endTime == data.endTimeAccumulated">
                {{data.endTime | date:'HH:mm'}}
              </ng-container>
            </p>
          </td>
          <td class="difference-column">
            <span [class]="data.classNameHoursGap">
            {{data.hoursGap}}
            <i class="lg-screen-icon cursor-pointer" nz-icon nzType="info-circle" nzTheme="outline" nz-popover nzPopoverTrigger="click" [nzPopoverContent]="explainIsExceptFieldTemplate"></i>
            <i class="sm-screen-icon cursor-pointer" nz-icon nzType="info-circle" nzTheme="outline" nz-popover (click)="openExplanationModal(data)"></i>
            <i *ngIf="data.hasClaimTime" [ngClass]="checkInCheckOutStatusDisplay[data.checkInCheckOutStatus].color" class="edit-screen-icon cursor-pointer" nz-icon nzType="clock-circle" nzTheme="outline" (click)="openPopupRegistration(data)"></i>
            </span>
            <!-- <span class="text-success" *ngIf="data.hoursGap >= 0"><i nz-icon nzType="check-circle" nzTheme="outline"></i></span> -->
          </td>

          <ng-template #explainIsExceptFieldTemplate>
            {{data.totalHours}} - {{data.totalWorkingSetting}} (Accumulated hours - Standard hours)
          </ng-template>

        </tr>
      </tbody>
    </nz-table>
  </nz-collapse-panel>
</nz-collapse>
