import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { UserService } from '../data-access';
import { SecurityHelper } from '../helpers';
import { CookieHelper } from '../helpers/cookieHelper';

@Injectable({
  providedIn: 'root',
})
export class LoginCheckerService implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url: string = state.url;
    // console.log('=========>canActivate', new Date().toISOString());
    // ! get token from end-user page
    try {
      const authToken = SecurityHelper.getStoreAuthen();
      const cookieToken = CookieHelper.getCookieToken();
      if (!authToken && !cookieToken) {
        return true;
      }
      if (authToken && authToken.token) {
        return this.router.navigate(['/admin']);
      }
      return true;
    } catch (error) {
      return true;
    }

    // return this.checkPermission2(state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = state.url;
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    return false;
  }
}
