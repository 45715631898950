<div class="editor-component">
  <!-- <div class="row">
    <div class="col-12">
      
    </div>
  </div> -->

  <editor *ngIf="isActiveEditor || disablePlaceHolder || content" [init]="tinyMceEditorConfig" [inline]="isInline"
        (onInit)="onEditorInit()" #editor name="content" (onChange)="onChangeEditor($event)"
        (onBlur)="onBlurEditor($event)" (onKeyUp)="onKeyUpEditor($event)" (onPaste)="onPasteEditor($event)"
        [(ngModel)]="content">
      </editor>

      <nz-spin [nzSpinning]="!readyEditor || isLoading" [nzSize]="'large'"></nz-spin>
      <div *ngIf="!disablePlaceHolder && !content && !isActiveEditor" class="editor-placeholder"
        (click)="activeEditor()" [ngStyle]="placeholderStyle()">
        <span *ngIf="!content">{{ placeholder ? placeholder : '' }}</span>
      </div>

  <div *ngIf="previewData && !disablePreview" class="row preview">
    <div class="col-12 p-0">
      <a (click)="hidePreview()" class="hide-preview float-right"><i class="ti-close"></i></a>
    </div>
    <div class="col-12 p-0" [innerHtml]="previewData | shortcodeToHtml | safeHtml"></div>
  </div>
</div>