<div class="rt-panel-item">
  <div class="title-panel">
    <p>How's your day?</p>

    <div class="extra-button">
      <button nz-button class="btn btn-info btn-add-booking mr-2" (click)="createBooking()">
        <div class="btn-content">
          <i nz-icon nzType="plus" nzTheme="outline"></i> <span class="extra-btn-text">Meeting</span>
        </div>
      </button>
      <button nz-button class="btn btn-primary btn-add-leave" (click)="createLeave()">
        <div class="btn-content">
          <i nz-icon nzType="plus" nzTheme="outline"></i> <span class="extra-btn-text">Leave</span>
        </div>
      </button>
    </div>
  </div>

  <div class="rt-panel-item-content">
    <nz-skeleton *ngIf="!userReward || loading === true"></nz-skeleton>
    <div *ngIf="userReward && loading === false" class="coin-rewards">
      <p>Your Points</p>
      <p class="coin">{{userReward.point}}</p>
      <p>Log in everyday to collect points!</p>
      <div class="row progress-collect text-center">
        <div *ngFor='let item of userReward.listDisplayDay ;let i = index' [class]="item.class">
          <span class="dot"></span>
          <span *ngIf="!item.emoteLocation" class="progress-text">Day {{(item.day)}}</span>
          <span *ngIf="item.emoteLocation">
            <img [src]="item.emoteLocation" width="20px" height="20px">
          </span>
          <b>{{item.point}}</b>
        </div>
      </div>
      <div class="gold row d-flex flex-row align-items-center">
        <img *ngIf="userReward.tier" width="50px" height="50px" [(src)]="userReward.tier.imageLocation" nz-popover [nzPopoverContent]="tierInfo"/>
        <img *ngIf="!userReward.tier" width="50px" height="50px" [(src)]="userReward.emote.location" />
        <ng-template #tierInfo>
          {{ tierInfoContent }}
        </ng-template>
      </div>
      <!-- Uncomment below line to hide those emotes -->
      <div *ngIf="userReward.isClaimDaily" class="ribbon-container">
        <nz-divider class="ribbon" nzText="Click on an emote to claim {{userReward.settingPointDaily}} points!"></nz-divider>
      </div>
      <div *ngIf="userReward.isClaimDaily" class="emotes-container">
        <div *ngFor="let emote of emotes; let i = index" class="emote">
          <img [(src)]="imgSrc[i]" [title]="emote.name" (click)="claimDaily(emote)" (mouseover)="imgSrc[i] = emote.location" (mouseout)="imgSrc[i] = emote.disabledIcon"
              width="50px" height="50px" class="claim-emote" />
          </div>
        </div>

        <!-- <div class="next-earn text-center">Next earn points in: <b>18:23:02</b></div> -->
        <!-- <button *ngIf="userReward.isClaimDaily" (click)="claimDaily()" type="button" class="btn btn-primary btn-block">
            Claim {{userReward.settingPointDaily}} points
          </button> -->
        <button *ngIf="!userReward.isClaimDaily" disabled type="button" class="btn btn-primary btn-block pl-1 pr-1">
          Come back tomorrow to get more points
          <!-- Come back tomorrow to get {{userReward.settingPointDaily}} points -->
        </button>
      </div>
    </div>


  </div>
