// export interface IUserModel {
//   id?: string;
//   fullName: string;
//   userName: string;
//   passWord?: string;
//   isActive?: boolean;
//   isSendMail?: boolean;
//   groupUserId?: string;
//   employeeId: string;
// }

import { Position } from '.';

export class UserModel {
  id?: string;
  fullName?: string;
  groupName?: string;
  userName?: string;
  passWord?: string;
  groupUserId?: string;
  departments?: any;
  isActive: boolean;
  isSendMail?: boolean;
  employeeId?: string;
  userType?: number;
  avatarUrl?: string;
  coverImageUrl?: string;
  hasEmployee?: boolean;
  isAdminAccess?: boolean;
  isPersonalAccess?: boolean;
  isNewFeedsAccess?: boolean;
  isExternalAccount: boolean;
  departmentObject: any[];
  JoinedDate?: Date;
  positions?: Position[];
  positionIds?: string;
  groupId?: string;
  userDepartments?: any[];
  enFullName: string;

  constructor() {
    this.isActive = true;
    this.userType = 1;
  }
}

export class UserReferrerModel {
  id: string;
  email: string;
  fullName: string;
  isActive: boolean;
  createdDate: Date;
  avatar: string;
  avatarGoogle: string;

  constructor(data: any) {
    if (!data) {
      return;
    }

    this.id = data._id;
    this.email = data.email;
    this.fullName = data.fullName;
    this.isActive = data.isActive;
    this.createdDate = data.createdDate;
    this.avatar = data.avatar;
    this.avatarGoogle = data.avatarGoogle;
  }

  static newUsers(data: any): UserReferrerModel[] {
    return data && data.length >= 0 && data.map(item => new UserReferrerModel(item));
  }
}

export class PasswordModel {
  id?: string;
  oldPassword?: string;
  passWord?: string;
  userName?: string;
  fullName?: string;
}

export class UserTier {
  id: string;
  name: string;
  description: string;
  minimumPoints: number;
  imageId: string;
  imageLocation: string;
}
