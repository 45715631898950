export class WebsiteData {
  static languages = [
    { name: 'English', icon: 'flag-icon-gb', value: 'en' },
    { name: 'Norwegian', icon: 'flag-icon-no', value: 'nb-NO' },
    { name: 'Vietnamese', icon: 'flag-icon-vn', value: 'vi' },
  ];
  static myStyle = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    'background-color': '#262b31',
    'background-repeat': ' no-repeat',
    'background-size': 'cover',
    'background-position': '50% 50%',
  };

  static myParams = {
    particles: {
      number: {
        value: 200,
        density: {
          enable: true,
          value_area: 2051.7838682439087,
        },
      },
      color: {
        value: '#cc2626',
      },
      shape: {
        type: 'circle',
        stroke: {
          width: 1,
          color: '#cc2626',
        },
        polygon: {
          nb_sides: 5,
        },
        image: {
          src: 'img/github.svg',
          width: 100,
          height: 100,
        },
      },
      opacity: {
        value: 0.5017060304327615,
        random: false,
        anim: {
          enable: false,
          speed: 1.6241544246026902,
          opacity_min: 0.5821448583331299,
          sync: false,
        },
      },
      size: {
        value: 4.008530152163807,
        random: true,
        anim: {
          enable: false,
          speed: 40,
          size_min: 0.1,
          sync: false,
        },
      },
      line_linked: {
        enable: true,
        distance: 150,
        color: '#cc2626',
        opacity: 0.6,
        width: 1,
      },
      move: {
        enable: true,
        speed: 6,
        direction: 'none',
        random: false,
        straight: false,
        out_mode: 'out',
        bounce: false,
        attract: {
          enable: false,
          rotateX: 4890.406785639845,
          rotateY: 5451.6010069427775,
        },
      },
    },
    interactivity: {
      detect_on: 'canvas',
      events: {
        onhover: {
          enable: true,
          mode: 'grab',
        },
        onclick: {
          enable: false,
          mode: 'push',
        },
        resize: true,
      },
      modes: {
        grab: {
          distance: 400,
          line_linked: {
            opacity: 1,
          },
        },
        bubble: {
          distance: 400,
          size: 40,
          duration: 2,
          opacity: 6,
          speed: 3,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
        push: {
          particles_nb: 4,
        },
        remove: {
          particles_nb: 2,
        },
      },
    },
    retina_detect: true,
  };
}
