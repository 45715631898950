import { BaseModel } from './base-model';
import { EquipmentCategory } from './equipment-category';

export class InvoiceLine extends BaseModel {
  productId: string;
  equipmentId: string;
  equipmentName: string;
  categoryName: string;
  quantity: number;
  perPrice: number;

  constructor(data?) {
    super();
    if (data) {
      this.productId = data.productId;
      this.equipmentId = data.equipmentId;
      this.equipmentName = data.equipmentName;
      this.categoryName = data.equipmentCategoryName;
      this.quantity = data.quantity;
      this.perPrice = data.price;
    }
  }
}

export class Invoice extends BaseModel {
  title: string;
  purchaseRequestId: string;
  purchaseRequestNo: string;
  departments: any;
  groupDepartments: any;
  departmentName: string;
  lines: InvoiceLine[];

  constructor(data?) {
    super();
    if (data) {
      this.title = data.title;
      this.purchaseRequestId = data.id;
      this.purchaseRequestNo = data.purchaseRequestNo;
      this.departments = data.groupDepartmentJson;
    }
  }
}
