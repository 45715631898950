<div class="rt-panel-item new-post">
  <div class="row align-items-center title-panel">
    <div class="col-md-6 col-12">
      <p>{{getGreeting()}}</p>
    </div>
    <div *ngIf="workTime" class="col-6 text-right">
      <p *ngIf="workTime.startTime">
        <span>Checked time : </span><span
          *ngIf="workTime.startTime">{{workTime.startTime| date:'EEE, dd/MM/yyy HH:mm'}}</span><span
          *ngIf="workTime.endTime">-{{workTime.endTime | date:'HH:mm'}} Total Hours :
          {{workTime.totalHour | number}}</span>
      </p>
    </div>
  </div>
  <div class="rt-panel-item-content">
    <div class="rt-new-post-area" (click)="openComposeEditor(constants.FEED_TYPES.WALL)">
      <div class="input-group">
        <div class="input-group-prepend">
          <app-avatar [size]="40" [avatarSource]="currentUser?.avatarUrl" [avatarText]="currentUser?.fullName">
          </app-avatar>
        </div>
        <textarea class="form-control" placeholder="Share your idea"></textarea>
      </div>
    </div>

    <div class="rt-option-group">
      <div class="left">
        <button type="button" class="btn btn-link btn-sm btn-block"
          (click)="openComposeEditor(constants.FEED_TYPES.WALL)">
          <img class="user-photo" src="assets/images/icons/post-icon.svg" alt="create post" />
          Post
        </button>
      </div>
      <div class="right text">
        <button type="button" class="btn btn-link btn-sm btn-block"
          (click)="openComposeEditor(constants.FEED_TYPES.POLL)">
          <img class="user-photo" src="assets/images/icons/poll-icon.svg" alt="create poll" />
          Poll
        </button>
      </div>
    </div>
  </div>
</div>