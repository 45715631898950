import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/index';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { DepartmentUser } from '../models';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/department_group';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getTree(params?: any, successCallback?: (params?: any) => void) {
    const url = 'get-tree';
    return this.getByUrl(url, params, successCallback);
  }

  getProfile(data: any) {
    const url = this.hostUrl + `/get-profile`;
    return this.httpClient.postSync(url, data);
  }

  getMembers(data: any, successCallback?: (params?: any) => void): Promise<{ results: DepartmentUser[] }> {
    const url = `get-members`;
    return this.getByUrl(url, data, successCallback) as Promise<{ results: DepartmentUser[] }>;
  }

  getTeams() {
    const url = `${AppConfig.settings.API_URL}/api/teams`;
    return this.httpClient.get(url);
  }

  getAllDepartments() {
    const url = `${this.hostUrl}/get-dropdown`;
    return this.httpClient.getSync(url);
  }

  viewMembers(data: any): Promise<any> {
    const url = ApiEndpoint.DepartmentGroup.Members();

    return this.httpClient.getObservable(url, data, !!data?.noLoading).toPromise();
  }
}
