import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';

import { Constants } from '../config';
import { Injectable } from '@angular/core';
import { RouterHelperService } from '@intranet/lib/helpers';
import { SecurityHelper } from 'projects/intranet/hrm-lib';

@Injectable({
  providedIn: 'root',
})
export class AuthenCheckerUserService {
  constructor(private router: Router, private routerHelperService: RouterHelperService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Constants.previousUrl = route;
    const user = route.data;
    if (user) {
      const url: string = this.getStateUrl(route, state.url);
      return this.checkPermission(url, user);
    }
    return this.canActivate(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Constants.previousUrl = route;
    const user = route.data;
    if (user) {
      const url: string = this.getStateUrl(route, state.url);
      return this.checkPermission(url, user);
    }
    return this.canActivate(route, state);
  }

  getStateUrl(route: ActivatedRouteSnapshot, url: string) {
    const configPath = route.routeConfig.path;
    if (!configPath) {
      return configPath;
    }
    const pathConfig = route.routeConfig.path.split('/:');
    if (pathConfig.length) {
      const path = pathConfig[0];
      let index = url.indexOf(path);
      index += path.length;
      const finalUrl = url.substr(0, index);
      return finalUrl;
    }
  }

  canLoad(route: Route) {
    const url = `/${route.path}`;

    return this.checkPermission(url);
  }

  async checkPermission(url?: string, data?: any) {
    Constants.previousUrl = url ? url : '/';
    const authToken = SecurityHelper.getStoreAuthen();
    if (authToken && authToken.token) {
      // if (!authToken.isPersonalAccess) {
      //   return this.router.navigate(['/not-permission']);
      // }
      // check roles
      if (data && data.isPermission) {
        const checker = SecurityHelper.checkPermissionUrl(url);
        if (checker) {
          return true;
        } else {
          return this.router.navigate(['/not-permission']);
        }
      }
      return true;
    } else {
      SecurityHelper.destroyAuthen();
      this.routerHelperService.redirectToLogin();
    }
  }
}
