import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NewsFeedTypeEnum, Department, PublicityLevel, NewsFeed, NewsFeedService, InternalUploadFileDto } from '@intranet/lib/data-access';
import { Guid } from 'guid-typescript';
import { BaseAdminComponent, ShareEditorComponent } from '@intranet/lib/components';
import { Toastr } from '@intranet/lib/settings/services/toastr';
import { Constants } from '@intranet/index';
import * as moment from 'moment';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
@Component({
  selector: 'app-newsfeed-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  styles: [],
})
export class NewsFeedDetailComponent extends BaseAdminComponent implements OnInit, AfterViewInit {
  @ViewChild(ShareEditorComponent) shareEditorComponent: ShareEditorComponent;
  dateFormat = 'dd/MM/yyyy';

  @Input() feedId: string;
  @Input() departments: Department[];
  @Input() newsFeedTypeId: NewsFeedTypeEnum;
  modelUpload: InternalUploadFileDto = new InternalUploadFileDto();
  statuses = Constants.NEWSFEED_STATUSES;
  hashtags: any[];
  mentions: any[];

  newsFeedTypes = {
    WALL: NewsFeedTypeEnum.WALL,
    POLL: NewsFeedTypeEnum.POLL,
    BIRTHDAY: NewsFeedTypeEnum.BIRTHDAY,
    ANNOUNCEMENT: NewsFeedTypeEnum.ANNOUNCEMENT,
    STICKY: NewsFeedTypeEnum.STICKY,
    TICKET: NewsFeedTypeEnum.TICKET,
    WORK_ANNIVERSARY: NewsFeedTypeEnum.WORK_ANNIVERSARY,
  };

  activeModal: NgbActiveModal;
  isLoading: boolean;

  editorContent: string;
  editorPreview: string;
  editorRawContent: string;
  dateRanges: Date[];
  dateResultRanges: Date[];
  departmentGroups: Department[];
  publicityLevels = [
    {
      id: PublicityLevel.PUBLIC,
      name: 'Public',
    },
    {
      id: PublicityLevel.PRIVATE_GROUP,
      name: 'Department',
    },

    {
      id: PublicityLevel.PRIVATE,
      name: 'Only me',
    },
  ];

  feed: NewsFeed;
  editorConfig = Constants.TINY_CONFIG;

  constructor(modalRef: NgbActiveModal, private newsFeedService: NewsFeedService,
    private cdr: ChangeDetectorRef) {
    super();

    this.initHighlightDateRanges();
    this.initPollResultDateRanges();
    this.isLoading = false;
    this.activeModal = modalRef;
  }

  ngAfterViewInit() {
    if (this.shareEditorComponent) {
      this.shareEditorComponent.activeEditor();
      this.cdr.detectChanges();
    }
  }

  initPollResultDateRanges() {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 15);

    this.dateResultRanges = [startDate, endDate];
  }

  initHighlightDateRanges() {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 15);

    this.dateRanges = [startDate, endDate];
  }

  disableEndHighlighDate = (current: Date): boolean => {
    if (!this.feed || !this.feed.beginHighlightDate) {
      return false;
    }

    return differenceInCalendarDays(this.feed.beginHighlightDate, current) >= 0;
  }

  disableEndResultDate = (current: Date): boolean => {
    if (!this.feed || !this.feed.beginResultDate) {
      return false;
    }

    return differenceInCalendarDays(this.feed.beginResultDate, current) >= 0;
  }

  ngOnInit() {
    this.load();
  }

  load() {
    if (this.feedId) {
      this.newsFeedService.getFeed(this.feedId, (res: NewsFeed) => {
        if (res) {
          this.feed = res;
          if (this.feed.departments) {
            this.feed.departments = JSON.parse(this.feed.departments);
            this.feed.isSendNotification = !!this.feed.isSendNotification;
          }

          if (res.beginHighlightDate) {
            this.feed.beginHighlightDate = new Date(res.beginHighlightDate);
          }

          if (res.endHighlightDate) {
            this.feed.endHighlightDate = new Date(res.endHighlightDate);
          }
          this.dateRanges = [this.feed.beginHighlightDate, this.feed.endHighlightDate];

          if (res.beginResultDate) {
            this.feed.beginResultDate = moment.utc(res.beginResultDate).toDate();
          }

          if (res.endResultDate) {
            this.feed.endResultDate = moment.utc(res.endResultDate).toDate();
          }

          this.dateResultRanges = [this.feed.beginResultDate, this.feed.endResultDate];
          this.modelUpload.entityId = this.feed.id;
          this.modelUpload.entityType = Constants.ENTITY_TYPE.NewsFeed;
        }
      }, (error) => {
        Toastr.error(error);
        this.closeModal();
      });
    }
    else {
      this.initData();
    }
  }

  initData() {
    this.feed = new NewsFeed();
    this.feed.id = Guid.create().toString();
    this.feed.publicityLevel = PublicityLevel.PUBLIC;
    this.feed.newsFeedTypeId = this.newsFeedTypeId;
    this.feed.beginDate = new Date();
    this.feed.allowComment = true;
    this.feed.allowLike = true;
    this.feed.isShow = true;
    this.feed.isSendNotification = false;
    this.feed.title = '';
    this.feed.description = '';
    this.modelUpload.entityId = this.feed.id;
    this.modelUpload.entityType = Constants.ENTITY_TYPE.NewsFeed;
    this.feed.status = Constants.NEWSFEED_STATUS_ID.New;

    if (this.newsFeedTypeId === NewsFeedTypeEnum.POLL) {
      this.initPollData();
    }
  }

  initPollData() {
    this.feed.pollQuestions = [];

    const questionId = Guid.create().toString();
    this.feed.pollQuestions.push({
      id: questionId,
      content: '',
      htmlContent: '',
      newsFeedId: this.feed.id,
      isMultipleChoice: false,
      options: [
        {
          id: Guid.create().toString(),
          pollQuestionId: questionId,
          title: '',
        },
      ],
    });
  }

  validate(): boolean {
    if (!this.feed.description || this.feed.description === '') {
      throw new Error('Please share your thinking');
    }

    if (!this.feed.title || this.feed.title === '') {
      throw new Error('Please share your mind');
    }

    if (this.feed.endDate && this.feed.endDate && this.feed.endDate < this.feed.beginDate) {
      throw new Error('End date happens before begin date');
    }

    return true;
  }

  onChangeRange(event) {
    this.feed.beginHighlightDate = this.dateRanges[0];
    if (this.dateRanges[1]) {
      this.feed.endHighlightDate = this.dateRanges[1];
    }
  }

  onChangeResultRange(event) {
    this.feed.beginResultDate = this.dateResultRanges[0];
    if (this.dateResultRanges[1]) {
      this.feed.endResultDate = this.dateResultRanges[1];
    }
  }

  onSubmit() {
    if (this.isLoading) {
      return false;
    }

    const request = this.feed;
    if (request.departments) {
      request.departments = JSON.stringify(request.departments);
      request.departmentGroups = null;
    }
    try {
      this.validate();
      this.isLoading = true;
      if (!request.publicityLevel) {
        request.publicityLevel = PublicityLevel.PRIVATE_GROUP;
      }

      if (this.feedId) {
        this.newsFeedService.updatePost(request, (res) => {
          if (res && res.id) {
            this.activeModal.close(res.id);
          }
          else {
            this.activeModal.close(res.data.id);
          }
        }, (error) => {
          this.activeModal.close();
        }, () => {
          this.reset();
        });
      }
      else {
        this.newsFeedService.createPost(request, (res) => {
          if (res && res.id) {
            this.activeModal.close(res.id);
            // this.newsFeedService.getFeed(res.guidId, (res) => {
            //     Toastr.success('You just have posted status successfully!');
            // });
          }
          else {
            this.activeModal.close(res.data.id);
          }
        }, (error) => {
          this.activeModal.close();
        }, () => {
          this.reset();
        });
      }

    } catch (e) {
      this.isLoading = false;
      Toastr.error(e.toString());
    }
  }

  reset() {
    this.isLoading = false;
  }

  closeModal() {
    this.activeModal.close(null);
  }

  isPrivateGroup() {
    return (
      (this.feed.publicityLevel === PublicityLevel.PRIVATE_GROUP) &&
      this.departments &&
      this.departments.length > 0
    );
  }

  onChangeResultBeginDate(date) {
    this.feed.beginResultDate = date;
    this.feed.endResultDate = null;
  }

  onChangeResultEndDate(date) {
    this.feed.endResultDate = date;
  }

  onChangeHighlightBeginDate(date) {
    this.feed.beginHighlightDate = date;
    this.feed.endHighlightDate = null;
  }

  onChangeHighlightEndDate(date) {
    this.feed.endHighlightDate = date;
  }

  onChangeDateResultRange($event) {

  }
}
