<form class="edit-phto edit-cover">
  <div class="banner">
    <img  [defaultData]="'assets/images/front/planet-banner.jpg'" [src]="coverImageUrl"  type="cdn" />
    <form class="edit-phto edit-cover">
      <label class="fileContainer">
        <div class="button btn btn-default" *ngIf="isDisplayEditCoverButton"> <img src="assets/images/front/shortcut/camera.png"/> Edit cover photo
        </div>
          <resize-image [cropOptions]="cropOptions" [isHidden]="true" [cropFile]="images" placeholder="page.edit-profile.upload-image"
            (eventCompelete)="changeCoverImage()">
          </resize-image>
      </label>
    </form>
  </div>
