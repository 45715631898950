import { v4 as uuid } from 'uuid';
import { UrlConstant } from './urlConstant';
import { Menu } from '@intranet/lib/data-access/models/Menu';
import { CalendarEventTypeEnum, } from '@intranet/lib/data-access/models/enums/event.enum';
import { MenuAction } from '../data-access/models/enums/menu-action.enum';
export class MenuHelper {
  static menusListAdmin: Menu[] = [
    {
      id: uuid(),
      icon: 'mdi mdi-account-multiple',
      title: 'Employees',
      children: [
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Employees',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.EMPLOYEE),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-email',
          title: 'Resignation',
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.RESIGNATION),
        },
      ]

    },
    {
      id: uuid(),
      icon: 'mdi mdi-xmpp',
      title: 'Leaves',
      children: [
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Leave Approval',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.LEAVE_REQUEST),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Annual Leaves',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.LEAVE_ANNUAL),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Annual Leave Request',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.LEAVE_ANNUAL_REQUEST),
        },
        // {
        //   id: uuid(),
        //   icon: 'mdi mdi-account-multiple',
        //   title: 'Leave Transfer',
        //   checkPermission: true,
        //   url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.LEAVE_TRANSFER),
        // },
        {
          id: uuid(),
          icon: 'mdi mdi-home',
          title: 'Leave type',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.LEAVE_TYPE),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-home',
          title: 'Leave settings',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.LEAVE_SETTING),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-home',
          title: 'Monthly Missing Hours',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.MONTHLY_MISSING_HOURS),
        },
      ],
    },
    {
      id: uuid(),
      icon: 'mdi mdi-cash-100',
      title: 'Purchasing',
      children: [
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Purchase Requests',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.PURCHASE_REQUEST),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Purchase Order',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.PURCHASE_ORDER),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Warehouse',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.WAREHOUSE),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Delivery Form',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.DELIVERY_FORM),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Purchasing Management',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.MANAGE_EQUIPMENT),
        },
        // {
        //   id: uuid(),
        //   icon: 'mdi mdi-account-multiple',
        //   title: 'Invoices',
        //   // checkPermission: true,
        //   url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.INVOICE),
        // },
      ],
    },
    {
      id: uuid(),
      icon: 'mdi mdi-av-timer',
      title: 'TimeKeeper',
      children: [
        {
          title: 'Work Time',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.TIME_KEEPER),
        },
        {
          title: 'Accounts',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.TIME_KEEPER_USER),
        },
        {
          title: 'Machine',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.TIME_KEEPER_MACHINE),
        },
        {
          title: 'Settings',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.WORK_TIME_SETTING),
        },
        {
          title: 'Schedule Group',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.SCHEDULE_GROUP),
        },
        {
          title: 'Comp time management',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.COMPENSATION_HOUR_MANAGEMENT),
        },
        {
          title: 'Comp time requests',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.COMPENSATION_HOUR),
        },
      ],
    },
    {
      id: uuid(),
      icon: 'mdi mdi-code-equal',
      title: 'News Feed',
      children: [
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'News Feed',
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.NEWFEEDS),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Announcements',
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.NEWFEED_ANNOUNCEMENTS),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Polls',
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.NEWFEED_POLLS),
        },
      ],
    },
    {
      id: uuid(),
      icon: 'mdi mdi-code-equal',
      title: 'Tickets',
      children: [
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Tickets',
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.TICKET),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Ticket Types',
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.TICKET_TYPE),
        },
      ],
    },
    {
      id: uuid(),
      icon: 'mdi mdi-book-multiple',
      title: 'Surveys',
      url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.NEWFEED_SURVEYS),
    },
    {
      id: uuid(),
      icon: 'mdi mdi-calendar-multiple',
      title: 'Events',
      url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.NEWFEED_EVENTS),
    },
    {
      id: uuid(),
      icon: 'mdi mdi-gift',
      title: 'Gifts',
      children: [
        {
          id: uuid(),
          title: 'Manage Gifts',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.GIFT_MANAGING),
        },
        {
          id: uuid(),
          title: 'Redemption Requests',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.REDEMPTION_REQUESTS),
        },
      ],
    },
    {
      id: uuid(),
      icon: 'mdi mdi-chemical-weapon',
      title: 'Supplier',
      children: [
        {
          id: uuid(),
          title: 'Supplier',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.SUPPLIER),
        },
        {
          id: uuid(),
          title: 'Category',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.EQUIPMENT),
        },
        {
          id: uuid(),
          title: 'Product',
          // checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.PRODUCT),
        },
        {
          id: uuid(),
          title: 'Package',
          // checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.PRODUCT_PACKAGE),
        },
        {
          id: uuid(),
          title: 'Quotation',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.SUPPLIER_EQUIPMENT),
        },
      ],
    },
    {
      id: uuid(),
      icon: 'mdi mdi-bookmark',
      title: 'Policy',
      children: [
        {
          id: uuid(),
          title: 'Policy',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.POLICY),
        }
      ],
    },
    // {
    //   id: uuid(),
    //   icon: 'mdi mdi-account-settings-variant',
    //   title: 'Personal',
    //   url: UrlConstant.mapUser(UrlConstant.UserUrl.PROFILE),
    // },
    // {
    //   id: uuid(),
    //   icon: 'mdi mdi-share-variant',
    //   title: 'Project',
    //   url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.PROJECT),
    // },
    , {
      id: uuid(),
      icon: 'mdi mdi-security-account',
      title: 'Authority',
      children: [
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Users',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.USER),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Group Permissions',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.GROUP_USER),
        },
      ],
    }
    , {
      id: uuid(),
      icon: 'mdi mdi mdi-settings',
      title: 'Settings',
      children: [
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Positions',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.POSITION),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Groups/Departments',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.DEPARTMENT),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Technical Skills',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.SKILLS),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Meeting Rooms',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.ROOM),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-email',
          title: 'Email Templates',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.TEMPLATE_EMAIL),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-email',
          title: 'HTML Templates',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.TEMPLATE_HTML),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-email',
          title: 'User Tiers',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.USER_TIER),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-setting',
          title: 'Widget Setup',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.WIDGET_SETUP),
        },
        {
          id: uuid(),
          icon: 'mdi mdi-setting',
          title: 'System',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.SYSTEM),
        },
      ],
    },
    {
      id: uuid(),
      icon: 'mdi mdi-calendar-multiple',
      title: 'Benefits',
      children: [
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Benefits',
          checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.BENEFIT)
        },
        {
          id: uuid(),
          icon: 'mdi mdi-account-multiple',
          title: 'Benefit Member Requests',
          // checkPermission: true,
          url: UrlConstant.mapAdmin(UrlConstant.AdminUrl.BENEFIT_REGISTRATION)
        }
      ]
    },
    // {
    //   id: uuid(),
    //   icon: 'mdi mdi-chair-school',
    //   title: 'EXTRAS',
    //   isMenuTitle: true,
    // },
  ];
  static menuListUser: Menu[] = [
    {
      id: uuid(),
      icon: 'mdi mdi-briefcase',
      title: 'Dashboard',
      url: '/dashboard',
    },
    {
      id: uuid(),
      icon: 'mdi mdi-briefcase',
      title: 'Leave',
      checkPermission: true,
      onlyEmployee: true,
      url: UrlConstant.mapUser(UrlConstant.UserUrl.LEAVE),
    },
    {
      id: uuid(),
      icon: 'mdi mdi-cart-outline',
      title: 'Purchase',
      checkPermission: true,
      onlyEmployee: true,
      url: UrlConstant.mapUser(UrlConstant.UserUrl.PURCHASE),
    },
    // {
    //   id: uuid(),
    //   icon: 'mdi mdi-calendar-clock',
    //   title: 'Booking',
    //   url: UrlConstant.mapUser(UrlConstant.UserUrl.BOOKING),
    // },
    // {
    //   id: uuid(),
    //   icon: 'mdi mdi-account-card-details',
    //   title: 'Policy',
    //   url: UrlConstant.mapUser(UrlConstant.UserUrl.POLICY),
    //   onlyEmployee: true,
    // },
    // {
    //   id: uuid(),
    //   icon: 'mdi mdi-message-text',
    //   title: 'Message',
    //   isActive: true,
    //   url: UrlConstant.mapUser(UrlConstant.UserUrl.MESSAGE),
    // },
    {
      id: uuid(),
      icon: 'mdi mdi-calendar',
      title: 'Calendar',
      url: UrlConstant.mapUser(UrlConstant.UserUrl.CALENDAR),
    },
    {
      id: uuid(),
      icon: 'mdi mdi-account-circle',
      title: 'Profile Settings',
      url: UrlConstant.mapUser(UrlConstant.UserUrl.PROFILE),
    },
    // {
    //   id: uuid(),
    //   icon: 'mdi mdi-account-settings-variant',
    //   title: 'CV',
    //   url: UrlConstant.mapUser(UrlConstant.UserUrl.YOUR_CV),
    // },
    // {
    //   id: uuid(),
    //   icon: 'mdi mdi-account-settings-variant',
    //   title: 'Settings',
    //   url: UrlConstant.mapUser(UrlConstant.UserUrl.SETTINGS),
    // },
  ];

  static menuListShortCut: Menu[] = [
    {
      id: uuid(),
      icon: 'icon icon-default',
      imageShortcut: 'assets/images/icons//dashboard.svg',
      title: 'Dashboard',
      groupName: 'Personal',
      order: 1,
      // checkPermission: true,
      // onlyEmployee: true,
      url: '/dashboard',
    },
    {
      id: uuid(),
      icon: 'icon icon-default',
      imageShortcut: 'assets/images/icons/news-feed-icon.svg',
      title: 'NewsFeed',
      groupName: 'Personal',
      order: 1,
      // checkPermission: true,
      // onlyEmployee: true,
      url: '/' + UrlConstant.NewsFeedUrl.ROOT,
    },
    {
      id: uuid(),
      icon: 'icon icon-default',
      imageShortcut: 'assets/images/icons/leave.svg',
      onlyEmployee: true,
      groupName: 'Personal',
      checkPermission: true,
      order: 2,
      title: 'Leave',
      url: UrlConstant.mapUser(UrlConstant.UserUrl.LEAVE),
    },
    {
      id: uuid(),
      icon: 'icon icon-default',
      imageShortcut: 'assets/images/icons/purchase.svg',
      onlyEmployee: true,
      groupName: 'Personal',
      checkPermission: true,
      order: 3,
      title: 'Purchase',
      url: UrlConstant.mapUser(UrlConstant.UserUrl.PURCHASE),
    },
    {
      id: uuid(),
      icon: 'icon icon-default',
      imageShortcut: 'assets/images/icons/annoucements-active.svg',
      title: 'Announcements',
      groupName: 'Company',
      order: 4,
      // checkPermission: true,
      // onlyEmployee: true,
      url: UrlConstant.mapNewsFeed(UrlConstant.NewsFeedUrl.ANNOUNCEMENTS),
    },
    {
      id: uuid(),
      icon: 'icon icon-default',
      imageShortcut: 'assets/images/icons/events.svg',
      title: 'Events',
      groupName: 'Company',
      // checkPermission: true,
      // onlyEmployee: true,
      order: 5,
      url: UrlConstant.mapUser(UrlConstant.UserUrl.CALENDAR), // UrlConstant.mapUser(UrlConstant.UserUrl.PURCHASE),
      params: {
        tab: CalendarEventTypeEnum.EVENT
      }
    },
    {
      id: uuid(),
      icon: 'icon icon-default',
      imageShortcut: 'assets/images/icons/booking.svg',
      title: 'Meeting',
      order: 6,
      groupName: 'Company',
      url: UrlConstant.mapUser(UrlConstant.UserUrl.BOOKING),
      params: {
        tab: CalendarEventTypeEnum.BOOKING
      }
    },
    {
      id: uuid(),
      icon: 'icon icon-default',
      imageShortcut: 'assets/images/front/shortcut/org-chart.svg',
      title: 'Org Chart',
      groupName: 'Company',
      order: 7,
      url: '/' + UrlConstant.NewsFeedUrl.ORGCHART,
    },
    {
      id: uuid(),
      icon: 'icon icon-default',
      imageShortcut: 'assets/images/icons/admin-groups.svg',
      title: 'Admin Center',
      groupName: 'Company',
      order: 8,
      onlyAdmin: true,
      url: UrlConstant.AdminUrl.ROOT,
    },
    {
      id: uuid(),
      icon: 'icon icon-default',
      imageShortcut: 'assets/images/front/shortcut/ticket.svg',
      title: 'Ticket',
      groupName: 'Personal',
      order: 4,
      url: UrlConstant.mapNewsFeed(UrlConstant.NewsFeedUrl.TICKET_REPORTER),
    },
    {
      id: uuid(),
      icon: 'icon icon-default',
      imageShortcut: 'assets/images/front/shortcut/policy.svg',
      title: 'Policy',
      groupName: 'Company',
      order: 7,
      url: UrlConstant.UserUrl.POLICY,
    }
  ];

  static menuListGroup: Menu[] = [
    {
      id: uuid(),
      icon: 'icon icon-discussions',
      imageShortcut: 'assets/images/icons/annoucements-active.svg',
      title: 'Discussion',
      // checkPermission: true,
      // onlyEmployee: true,
      url: UrlConstant.mapGroup(UrlConstant.GroupUrl.DISCUSSION),
    },
    // {
    //   id: uuid(),
    //   icon: 'icon icon-newsfeed',
    //   imageShortcut: 'assets/images/icons/news-feed-icon.svg',
    //   title: 'News Feed',
    //   // checkPermission: true,
    //   // onlyEmployee: true,
    //   url: UrlConstant.mapGroup(UrlConstant.GroupUrl.NEWSFEED),
    // },
    {
      id: uuid(),
      icon: 'icon icon-announcements',
      imageShortcut: 'assets/images/icons/annoucements-active.svg',
      title: 'Announcements',
      // checkPermission: true,
      // onlyEmployee: true,
      url: UrlConstant.mapGroup(UrlConstant.GroupUrl.ANNOUNCEMENT),
    },
    {
      id: uuid(),
      icon: 'icon icon-events',
      imageShortcut: 'assets/images/icons/annoucements-active.svg',
      title: 'Events',
      // checkPermission: true,
      // onlyEmployee: true,
      url: UrlConstant.mapGroup(UrlConstant.GroupUrl.EVENT),
    },
  ];

  static menuListSideBar: Menu[] = [
    {
      id: uuid(),
      icon: 'icon icon-default',
      imageShortcut: 'assets/images/icons/personal.svg',
      title: 'Personal',
      groupName: 'Personal',
      level: 1,
      children: [
        {
          id: uuid(),
          icon: 'icon icon-default',
          imageShortcut: 'assets/images/icons/dashboard.svg',
          title: 'Dashboard',
          groupName: 'Personal',
          level: 2,
          // checkPermission: true,
          // onlyEmployee: true,
          url: '/dashboard',
        },
        {
          id: uuid(),
          icon: 'icon icon-default',
          imageShortcut: 'assets/images/icons/news-feed-icon.svg',
          title: 'News Feed',
          groupName: 'Personal',
          level: 2,
          // checkPermission: true,
          // onlyEmployee: true,
          url: '/' + UrlConstant.NewsFeedUrl.ROOT,
        },
        // {
        //   id: uuid(),
        //   icon: 'icon icon-default',
        //   imageShortcut: 'assets/images/icons/leave.svg',
        //   onlyEmployee: true,
        //   groupName: 'Personal',
        //   checkPermission: true,
        //   level: 2,
        //   title: 'Leave',
        //   url: UrlConstant.mapUser(UrlConstant.UserUrl.LEAVE),
        // },
        {
          id: uuid(),
          icon: 'icon icon-default',
          imageShortcut: 'assets/images/icons/sidemenu-calendar.svg',
          onlyEmployee: true,
          groupName: 'Personal',
          checkPermission: true,
          level: 2,
          title: 'Calendar',
          url: UrlConstant.mapUser(UrlConstant.UserUrl.BOOKING),
          nzOpen: false,
          params: {
            tab: CalendarEventTypeEnum.BOOKING
          },
          children: [
            // {
            //   id: uuid(),
            //   icon: 'icon icon-default',
            //   imageShortcut: 'assets/images/icons/events.svg',
            //   title: 'Events',
            //   groupName: 'Personal',
            //   // checkPermission: true,
            //   // onlyEmployee: true,
            //   level: 2,
            //   url: UrlConstant.mapUser(UrlConstant.UserUrl.CALENDAR), // UrlConstant.mapUser(UrlConstant.UserUrl.PURCHASE),
            //   params: {
            //     tab: CalendarEventTypeEnum.EVENT
            //   }
            // },
            {
              id: uuid(),
              icon: 'icon icon-default',
              imageShortcut: 'assets/images/icons/booking.svg',
              title: 'View Calendar',
              level: 2,
              groupName: 'Personal',
              url: UrlConstant.mapUser(UrlConstant.UserUrl.BOOKING),
              params: {
                tab: CalendarEventTypeEnum.BOOKING
              }
            },
            // {
            //   id: uuid(),
            //   icon: 'icon icon-default',
            //   imageShortcut: 'assets/images/icons/leave.svg',
            //   onlyEmployee: true,
            //   groupName: 'Personal',
            //   checkPermission: true,
            //   level: 2,
            //   title: 'Leave',
            //   url: UrlConstant.mapUser(UrlConstant.UserUrl.BOOKING),
            //   params: {
            //     tab: CalendarEventTypeEnum.LEAVE
            //   }
            // },
            {
              id: uuid(),
              icon: 'icon icon-default',
              imageShortcut: 'assets/images/front/shortcut/add.svg',
              onlyEmployee: true,
              groupName: 'Personal',
              checkPermission: true,
              level: 2,
              title: 'Add Leave',
              action: MenuAction[MenuAction.ADD_LEAVE],
            },
            {
              id: uuid(),
              icon: 'icon icon-default',
              imageShortcut: 'assets/images/front/shortcut/add.svg',
              onlyEmployee: true,
              groupName: 'Personal',
              checkPermission: true,
              level: 2,
              title: 'Add Meeting',
              action: MenuAction[MenuAction.ADD_BOOKING],
            },
          ],
        },
        {
          id: uuid(),
          icon: 'icon icon-default',
          imageShortcut: 'assets/images/icons/purchase.svg',
          onlyEmployee: true,
          groupName: 'Personal',
          checkPermission: true,
          level: 2,
          title: 'Purchase',
          url: UrlConstant.mapUser(UrlConstant.UserUrl.PURCHASE),
        },
        {
          id: uuid(),
          icon: 'icon icon-default',
          imageShortcut: 'assets/images/front/shortcut/ticket.svg',
          title: 'Ticket',
          groupName: 'Personal',
          level: 2,
          url: UrlConstant.mapNewsFeed(UrlConstant.NewsFeedUrl.TICKET_REPORTER),
        },
      ]
    },
    {
      id: uuid(),
      icon: 'icon icon-default',
      imageShortcut: 'assets/images/icons/company.svg',
      title: 'Company',
      groupName: 'Company',
      level: 1,
      nzOpen: false,
      children: [
        {
          id: uuid(),
          icon: 'icon icon-default',
          imageShortcut: 'assets/images/icons/annoucements-active.svg',
          title: 'Announcements',
          groupName: 'Company',
          level: 2,
          // checkPermission: true,
          // onlyEmployee: true,
          url: UrlConstant.mapNewsFeed(UrlConstant.NewsFeedUrl.ANNOUNCEMENTS),
        },
        {
          id: uuid(),
          icon: 'icon icon-default',
          imageShortcut: 'assets/images/front/shortcut/org-chart.svg',
          title: 'Org Chart',
          groupName: 'Company',
          level: 2,
          url: '/' + UrlConstant.NewsFeedUrl.ORGCHART,
        },
        {
          id: uuid(),
          icon: 'icon icon-default',
          imageShortcut: 'assets/images/icons/admin-groups.svg',
          title: 'Admin Center',
          groupName: 'Company',
          level: 2,
          onlyAdmin: true,
          url: UrlConstant.AdminUrl.ROOT,
        },
        {
          id: uuid(),
          icon: 'icon icon-default',
          imageShortcut: 'assets/images/front/shortcut/policy.svg',
          title: 'Policy',
          groupName: 'Company',
          level: 2,
          url: UrlConstant.UserUrl.POLICY,
        },
      ],
    },
  ];

  static MENU_TITLE_LIST = {
    viewCalendar: 'View Calendar',
    viewLeaves: 'View Leaves',
    addLeave: 'Add Leave',
    addMeeting: 'Add Meeting',
  };
}
