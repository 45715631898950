import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../config/constants';
import { PurchaseDetailOption } from '../data-access/models/enums/purchase-option.enum';

@Pipe({
  name: 'purchaseDetailOption'
})
export class PurchaseDetailOptionPipe implements PipeTransform {
  purchaseDetailOption = Constants.PURCHASE_DETAIL_DATASOURCE_OPTION;

  transform(value: number): string {
    const data = this.purchaseDetailOption.find(m => m.id === value);
    if (!data) {
      const defaultOption = this.purchaseDetailOption.find(m => m.id === PurchaseDetailOption.Replace);
      return defaultOption.title;
    }
    return data.title;
  }

}
