import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import * as FileSaver from 'file-saver';
import { HttpClientService } from 'projects/intranet/hrm-lib';
import * as XLSX from 'xlsx';
import { BaseService } from './base.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class CDNService extends BaseService {
  hostUrl = AppConfig.settings.API_URL + '/cdn/file';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  uploadAvatar(data: any, mainFolder: 'company' | 'referral' | 'TNM') {
    const url = this.hostUrl + `upload`;
    if (data) {
      data.append('type', mainFolder);
      data.append('prefixFolder', 'avartar');
    }
    return this.httpClient.postSync(url, data);
  }

  uploadFiles(
    data: any,
    successCallback: (params?: any) => void,
    failedCallback?: (error?: any) => void,
    completeCallback?: (params?: any) => void,
  ) {
    const formData = new FormData();
    for (const prop in data) {
      if (!data.hasOwnProperty(prop)) {
        continue;
      }
      formData.append(prop, data[prop]);
    }
    this.postByUrl('upload-files', formData, successCallback, failedCallback, completeCallback);
  }

  async getFileById(fileId: string, fileName = null) {
    if (!fileId) return;

    const url = ApiEndpoint.File.GetFileById(fileId);
    const res = await this.httpClient.getFileObservable(url, {}).toPromise();
    const downloadedFile = new Blob([res], { type: res.type });
    this.downloadFileResponse(downloadedFile, fileName);
  }

  async downloadFileEntity(fileId: string, fileName) {
    if (!fileId) return;

    const downloadUrl = this.getFileUrl('download/' + fileId);
    const result = await this.httpClient.getSync(downloadUrl, { options: { responseType: 'blob' } });
    const downloadedFile = new Blob([result], { type: result.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = fileName;
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  async getPrivateFileById(fileId: string, fileNameDefault = null) {
    if (!fileId) return;

    const url = ApiEndpoint.File.GetPrivateFileById(fileId);
    const res = (await this.httpClient.getFileObservable(url, {}).toPromise()) as HttpResponse<any>;
    const files = res.body;
    const fileName = (res as HttpResponse<any>).headers?.get('File-Name') ?? fileNameDefault;
    const downloadedFile = new Blob([files], { type: files.type });
    this.downloadFileResponse(downloadedFile, fileName);
  }

  async deleteFileEntity(fileId: string) {
    const url = this.hostUrl + `/delete-file/` + fileId;
    return this.httpClient.deleteSync(url);
  }

  async getFileEntity(data: any) {
    const url = this.hostUrl + `/get-files`;
    return this.httpClient.postSync(url, data);
  }

  getFileUrl(fileName: string): string {
    return this.hostUrl + '/' + fileName;
  }

  getFileUrlByEntity(entityId: string, entityType: string) {
    return `${this.hostUrl}/downloadByEntity?entityId=${entityId}&entityType=${entityType}`;
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  saveAsExcelFromBase64(data: any, fileName: string) {
    const fileDownload: Blob = new Blob([this.s2ab(atob(data))], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(fileDownload, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  convertExcelFromBase64ToLink(data: any, fileName: string) {
    const fileDownload: Blob = new Blob([this.s2ab(atob(data))], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const a: any = document.querySelector('#import_result') as any;
    a.download = fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION;
    a.href = URL.createObjectURL(fileDownload);
  }

  getBlobFileById(id: string): Promise<Blob> {
    if (!id) return Promise.reject('Error!');

    const url = this.getFileUrl('download/' + id);

    return this.httpClient.getSync(url, { options: { responseType: 'blob' } });
  }

  private downloadFileResponse(downloadedFile, fileName) {
    const element = document.createElement('a');
    element.setAttribute('style', 'display:none;');
    document.body.appendChild(element);
    element.download = fileName;
    element.href = URL.createObjectURL(downloadedFile);
    element.target = '_blank';
    element.click();
    document.body.removeChild(element);
  }

  private s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      // tslint:disable-next-line: no-bitwise
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
