import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SecurityHelper } from '../helpers/securityHelper';

class Role {
  controller: string;
  action: string;
  expect: boolean;
  department: string;
}
@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective {
  private controller: string;
  private action: string;
  private department: string;
  private expect: boolean;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  @Input()
  set hasPermission(val) {
    if (typeof val === 'object' && val && val.length > 1) {
      this.controller = val[0];
      this.action = val[1];
      this.expect = val[2];
      if (this.expect !== true && this.expect !== false) {
        // this.department = val[2];
        this.expect = undefined;
      }
      this.updateView();
    } else {
    }
  }

  @Input()
  set hasPermissionCtrl(permop) {
    this.controller = permop;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    if (this.controller && this.action) {
      const result = SecurityHelper.checkPermission(this.controller, this.action, this.department);
      if (this.expect) {
        if (result === this.expect) {
          return false;
        }
        return true;
      } else {
        return result;
      }
    }
    return true;
  }
}
