import { ProjectStatusEnum } from './enums/project.enum';

export class NewProjectModel {
  projectId: string;
  departmentId: string;
  name: string;
  description: string;
  ownerId: string;
  status: ProjectStatusEnum;
  client: string;
  dueDate?: Date;
  userMemberIds: string[];

  /**
   *
   */
  constructor() {
    this.status = ProjectStatusEnum.NEW;
  }
}

export class ProjectListModel extends NewProjectModel {
  projectCode: string;
  statusText: string;
  projectOwnerName: string;
  deparmentName: string;
}
