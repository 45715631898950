<div
  class="infinite-scroll"
  infiniteScroll
  [infiniteScrollDistance]="2"
  (scrolled)="onLoadMore()"
  [infiniteScrollDisabled]="disabledLoading"
>
  <ng-content select="[claimHealthCheck]"></ng-content>
  <ng-content select="[claimDaily]"></ng-content>
  <ng-content select="[createPost]"></ng-content>
  <app-news-feed-detail *ngFor="let item of feeds" [data]="item"></app-news-feed-detail>
</div>

<ng-container *ngIf="!isLoading && !disableEmpty && (!feeds || feeds.length <= 0)" class="rt-panel-item">
  <nz-empty [nzNotFoundContent]="contentTpl"></nz-empty>
</ng-container>

<div class="rt-panel-item p-3" *ngIf="isLoading">
  <nz-skeleton> </nz-skeleton>
</div>
<ng-template #contentTpl>
  <span>There are no news at this time</span>
</ng-template>
