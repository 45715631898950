import { Router } from '@angular/router';
import { MenuEventTypeEnum } from './enums/menu-type.enum';
export class Menu {
  id?: string;
  key?: string;
  controller?: string;
  action?: string;
  largeTitle?: string;
  title?: string;
  url?: string;
  isActive?: boolean;
  icon?: string;
  imageShortcut?: string;
  parent?: string;
  children?: Menu[];
  className?: string;
  isMenuTitle?: boolean;
  onlyEmployee?: boolean;
  onlyAdmin?: boolean;
  checkPermission?: boolean;
  utility?: boolean;
  params?: any;
  groupName?: string;
  order?: number;
  level?: number;
  nzOpen?: boolean;
}

export class MenuDto {
  id: string;
  title: string;
  url: string;
  icon: string;
  imagePath: string;
  toolTip: string;
  class: string;
  badge?: number;
  badgeColor?: string;
  permissions: MenuPermission[];
  event: MenuEvent;
  isAdminAccess: boolean;
  onlyEmployee: boolean;
  children?: MenuDto[];
  meta: any;
}

export class MenuPermission {
  controller: string;
  action: string;
}

export class MenuEvent {
  onClick: string;
}

export class MenuEventAction {
  action: string;
  fn: () => void;
  eventType: MenuEventTypeEnum;
  constructor(action: string, fn: () => void, eventType: MenuEventTypeEnum = MenuEventTypeEnum.Click) {
    this.action = action;
    this.fn = fn;
    this.eventType = eventType;
  }
}

export class MenuOptions {
  processMenuFn?: (menu: MenuDto[], router: Router) => MenuDto[];
  onNavigatingFn?: (event: any, menu: MenuDto[]) => MenuDto[];
}
