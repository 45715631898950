<div class="rt-panel-item" *ngIf="feed">
  <div class="rt-news-feed">
    <div class="favorites" *ngIf="feed.isImportant">
      <img src="assets/images/front/shortcut/favorite.svg" alt="favorite" />
      </div>
      <div class="rt-news-feed-title">
        <div class="rt-news-feed-photo btn-link" [routerLink]="[urlConstant.PROFILE_PAGE]" [queryParams]="{ employeeId :feed?.user?.employeeId}">
          <app-avatar *ngIf="feed.user" [avatarSource]="feed?.user?.avatarUrl" [avatarText]="feed?.user?.fullName">
          </app-avatar>
        </div>
        <p><span class="btn-link" [routerLink]="[urlConstant.PROFILE_PAGE]"
            [queryParams]="{ employeeId :feed?.user?.employeeId}">{{ feed?.user?.fullName }}</span>
          <ng-container *ngIf="feed.departmentGroups && feed.departmentGroups.length > 0">
            <img class="share-to-group-arrow" type="local" src="assets/images/icons/share-to-icon.svg" alt="" />
            <span *ngFor="let department of feed.departmentGroups; let first = first; let last = last">
              <span class="btn-link" [routerLink]="[urlConstant.mapGroup(urlConstant.GroupUrl.NEWSFEED)]"
                [queryParams]="{ groupId :department.id}">{{ department.name }}</span>
            <span *ngIf="feed.departmentGroups && feed.departmentGroups.length > 1 && !last"> , </span>
            </span>
          </ng-container>
        </p>
        <p class="time">{{ feed.createdDate?.toString() | pastDateByText }}</p>
        <div class="dropdown show rt-news-feed-option" *ngIf="currentUser.id === feed.createdBy">
          <div class="dropdown show rt-notification-option">
            <a class="rt-account" href="#" role="button" id="rt-account-notification-{{feed.id}}" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <span></span>
              </a>
            <div class="dropdown-menu dropdown-menu-right" [attr.aria-labelledby]="'rt-account-notification-' + feed.id">
              <a class="dropdown-item" (click)="onEditFeed(feed)">
                  <img src="assets/images/front/shortcut/mark.svg" />
                  Edit message
                </a>

              <a class="dropdown-item" (click)="onDeleteFeed(feed)" >
                  <img src="assets/images/front/shortcut/delete.svg" />
                  Delete this message
                </a>
            </div>
          </div>
        </div>
      </div>

      <a class="newfeed-title" (click)="navigateNewFeedDetailPage(feed)">{{ feed.title }}</a>

      <div class="rt-news-feed-post">
        <div class="preview" *ngIf="feed.contentHtml && !feed.isNewcomerAnnouncement" [innerHTML]="feed.contentHtml | shortcodeToHtml | safeHtml"></div>
        <div class="rt-news-feed-post-desc" [ngClass]="{ 'collapsed': !feed.expandFeed }" *ngIf="feed.description">
          <p [innerHTML]="feed.description | shortcodeToHtml | safeHtml"></p>
        </div>
        <a class="float-right more-link" (click)="toggleViewMore(feed)">{{feed.expandFeed ? 'See less' : 'View more'}}</a>
        <app-news-feed-image-avatar *ngIf="feed.isNewcomerAnnouncement" [src]="feed.contentHtml"></app-news-feed-image-avatar>
        <div class="rt-news-feed-post-photo" *ngIf="!feed.isNewcomerAnnouncement && feed.files && feed.files.length > 0">
          <app-news-feed-images [images]="feed.files"></app-news-feed-images>
        </div>
        <ng-container *ngIf="feed.newsFeedTypeId === types.POLL" [ngTemplateOutlet]="pollInformation"></ng-container>
        <app-news-feed-reaction [feed]="feed"></app-news-feed-reaction>
        <div class="comment-area">
          <app-news-feed-comment [feed]="feed"></app-news-feed-comment>
        </div>
      </div>
    </div>
  </div>

  <ng-template #voteAmount let-question let-option="option">
    <div class="poll-question-option-vote">
      <ng-container *ngIf="option.totalChoice">
        <a class="view-result-options view-result" (click)="onShowInstanceOptionResult(question.id, option.id, question)">{{
        getPollResultText(question.id, option.totalChoice)
      }}</a>
        <span>{{ option.totalChoice > 1 ? 'Votes' : 'Vote' }}</span>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #pollInformation>
    <div *ngFor="let question of feed.pollQuestions; let i = index">
      <div *ngIf="i < maxQuestions" class="poll-question">
        <div class="poll-question-content">
          <span [innerHTML]="question.content | shortcodeToHtml | safeHtml"></span>
        </div>
        <div class="poll-question-options">
          <div *ngIf="question.isMultipleChoice">
            <div *ngFor="let option of question.options" class="poll-question-option">
              <div class="poll-question-option-container">
                <div class="poll-question-option-content">
                  <div>
                    <label *ngIf="!isPollExpired" nz-checkbox [nzDisabled]="option.disabled || question.isExpired"
                      (ngModelChange)="onPollMultipleChoiceSelect(option, question)" [(ngModel)]="option.isSelected">{{ option.title }}</label>
                    <label *ngIf="isPollExpired" nz-checkbox [nzDisabled]="true" [ngModel]="option.isSelected">{{ option.title }}</label>
                  </div>
                  <nz-progress *ngIf="isPollExpired || feed.allowViewInstanceResult" [nzPercent]="getOptionPercent(question.id, option.totalChoice)" [nzShowInfo]="false">
                  </nz-progress>
                </div>
                <ng-container *ngIf="(isPollExpired || feed.allowViewInstanceResult)">
                  <ng-container *ngTemplateOutlet="voteAmount; context: {$implicit: question, option: option }">
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
          <div *ngIf="!question.isMultipleChoice">
            <nz-radio-group [ngModel]="question.userChoice" (ngModelChange)="onPollSelect(question, $event)">
              <div *ngFor="let option of question.options" class="poll-question-option">
                <div class="poll-question-option-container">
                  <div class="poll-question-option-content">
                    <div>
                      <label nz-radio [nzDisabled]="isPollExpired" nzValue="{{ option.id }}">
                        <span class="radio-label">{{ option.title }}</span>
                      </label>
                    </div>
                    <nz-progress *ngIf="isPollExpired || feed.allowViewInstanceResult" [nzPercent]="getOptionPercent(question.id, option.totalChoice)"
                      [nzShowInfo]="false">
                    </nz-progress>
                  </div>
                  <ng-container *ngIf="(isPollExpired || feed.allowViewInstanceResult)">
                    <ng-container *ngTemplateOutlet="voteAmount; context: {$implicit: question, option: option }">
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </nz-radio-group>
          </div>
          <div class="poll-question-note-container">
            <div class="poll-question-note" *ngIf="question.allowNote">
              <textarea nz-input placeholder="Short description" [disabled]="isPollExpired" [(ngModel)]="question.userNote.description" nzSize="large" name="userNote"
                class="poll-usernote-input" [ngModelOptions]="{ standalone: true }"></textarea>
              <button *ngIf="!isPollExpired" [disabled]="!canSaveUserNote(question)" class="user-note-submit-button" (click)="onSubmitUserNote(question)" type="button"
                nz-button nzType="primary" nzShape="circle">
                <i nz-icon nzType="check" nzTheme="outline"></i>
              </button>
            </div>
            <div class="poll-question-option-vote" *ngIf="isPollExpired || feed.allowViewInstanceResult"></div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isPollExpired || feed.allowViewInstanceResult" class="text-center mb-2">
      <a class="more-link view-result text-center" (click)="onShowInstanceResult()">View All Results </a>
    </div>
    <div *ngIf="maxQuestions < feed.pollQuestions.length" class="text-center">
      <button class="more-button" (click)="maxQuestions = feed.pollQuestions.length">
        View more ({{ feed.pollQuestions.length - maxQuestions }}) questions <i class="fas fa-chevron-down"></i>
      </button>
    </div>
  </ng-template>
