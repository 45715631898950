import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class MonthlyMissingHoursService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/monthly-missing-hours';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getMissingHoursByYear(year: number, scb?: any) {
    const url = 'get-current-user-by-year';
    return this.getByUrl(url, { year }, scb);
  }

  keepMissingHours(scb?: any) {
    const url = this.hostUrl + '/keep-missing-hours';
    return this.httpClient.putSync(url, scb);
  }

  decreaseAnnualLeaves(scb?: any) {
    const url = this.hostUrl + '/decrease-annual-leaves';
    return this.httpClient.putSync(url, scb);
  }

  syncData(scb?: any) {
    const url = this.hostUrl + '/sync-data';
    return this.httpClient.putSync(url, scb);
  }
}
