import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  ProjectItemTypeEnum,
  ProjectItemDto,
  ProjectItemService,
  ProjectMember,
  ProjectMemberService,
  ProjectIterationDto,
  ProjectIterationService,
  ProjectItemPriorityEnum,
  DropdownDto,
  ProjectRelationService,
  ProjectItemRelationListDto,
  ProjectItemRelationEnum,
  ProjectItemRelationDto,
  ProjectItemDocumentModel,
  ProjectItemDocumentService,
  ProjectItemDetailDto,
  ProjectItemDocumentDto,
  ProjectDocumentTypeEnum,
  FileExtensionType,
  CDNService,
  ProjectItemHistoryStateChangeDto,
  ProjectItemHistoryChangeDto,
  HistoryLogActionEnum,
  ProjectItemStateDto,
} from '@intranet/lib/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toastr, Constants, Loading, ModalService, ModalSize } from '@intranet/index';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Subscription } from 'rxjs';
import { PreviewImageModalComponent } from '../preview-image-modal/preview-image-modal.component';
import { NewCommentModalComponent } from '../new-comment-modal/new-comment-modal.component';
import { ProjectItemStateService } from '@intranet/lib/data-access/services/project-item-state.service';

@Component({
  selector: 'app-new-project-item-modal',
  templateUrl: './new-project-item-modal.component.html',
  styleUrls: ['./new-project-item-modal.component.scss'],
})
export class NewProjectItemModalComponent implements OnInit {
  @ViewChild('frm', { static: false }) public formGroup: NgForm;
  @Input() projectItemId: string;
  @Input() projectId: string;
  @Input() sprintId: string;
  @Input() projectItemType: ProjectItemTypeEnum;

  ProjectItemTypeEnum = ProjectItemTypeEnum;
  ProjectItemPriorityEnum = ProjectItemPriorityEnum;
  ProjectItemRelationEnum = ProjectItemRelationEnum;
  HistoryLogActionEnum = HistoryLogActionEnum;
  FileExtensionType = FileExtensionType;
  activeModal: NgbActiveModal;
  isLoading: boolean;
  projectItemDto: ProjectItemDto = new ProjectItemDto();
  discussionItemList: ProjectItemDocumentDto[] = [];
  documentItemList: ProjectItemDocumentDto[] = [];
  projectMemberList: ProjectMember[] = [];
  fullItemStateList: ProjectItemStateDto[] = [];
  projectItemStateList: ProjectItemStateDto[] = [];
  projectIterationList: ProjectIterationDto[] = [];
  priorityItemList: ProjectItemPriorityEnum[] = [
    ProjectItemPriorityEnum.URGENT,
    ProjectItemPriorityEnum.IMPORTANT,
    ProjectItemPriorityEnum.MEDIUM,
    ProjectItemPriorityEnum.LOW,
  ];
  isEditing: boolean;
  editorConfig = Constants.TINY_CONFIG;
  projectItemList: DropdownDto[] = [];
  projectRelationList: ProjectItemRelationListDto[] = [];
  mapOfProjectRelation: { [key: number]: ProjectItemRelationListDto[] } = {};
  isShowEditRelationComment = false;
  isShowCreateRelationModal = false;
  updateProjectRelationId: string;
  projectRelationComment: string;
  newProjectRelation: ProjectItemRelationDto = new ProjectItemRelationDto();
  relationTypeList: ProjectItemRelationEnum[] = [
    ProjectItemRelationEnum.PARENT,
    ProjectItemRelationEnum.CHILD,
    ProjectItemRelationEnum.RELATED,
    ProjectItemRelationEnum.DUPLICATED,
    ProjectItemRelationEnum.AFFECT,
  ];

  historyStateList: ProjectItemHistoryStateChangeDto[] = [];
  historyChangeList: ProjectItemHistoryChangeDto[] = [];
  selectedChangeHistory: ProjectItemHistoryChangeDto;
  // customReq = () => {
  //   return Subscription.EMPTY;
  //   // tslint:disable-next-line: semicolon
  // };

  constructor(
    modalRef: NgbActiveModal,
    private modelService: ModalService,
    private projectItemSvc: ProjectItemService,
    private projectMemberSvc: ProjectMemberService,
    private projectItemStateSvc: ProjectItemStateService,
    private projectIterationSvc: ProjectIterationService,
    private projectRelationSvc: ProjectRelationService,
    private projectItemDocumentSvc: ProjectItemDocumentService,
    private cdnService: CDNService,
  ) {
    this.activeModal = modalRef;
  }

  async ngOnInit() {
    await this.getListData();
    this.initData();
  }

  onSubmit(): void {
    // if (!this.projectItemDto.projectIterationId) {
    //   this.projectItemDto.projectIterationId = null;
    // }
    if (!this.projectItemId) {
      this.addNewProjectItem();
    } else {
      this.updateExistingProjectItem();
    }
  }

  deleteProjectItem(): void {
    this.isLoading = true;
    Loading.show();
    this.projectItemSvc.deleteById(
      this.projectItemId,
      () => {
        this.activeModal.close(this.projectItemId);
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }

  closeModal() {
    this.activeModal.close(null);
  }

  openItemModal(relateItemId: string) {
    const modal = this.modelService.open(NewProjectItemModalComponent, {
      size: ModalSize.percent80,
    });
    modal.componentInstance.projectId = this.projectId;
    modal.componentInstance.projectItemId = relateItemId;
  }

  refreshProjectData() {
    this.initData();
  }

  private initData() {
    this.projectItemDto.projectId = this.projectId;
    this.projectItemDto.projectItemType = this.projectItemType;
    this.discussionItemList = [];
    this.documentItemList = [];
    if (this.sprintId) {
      this.projectItemDto.projectIterationId = this.sprintId;
    }

    const defaultInsertState = this.fullItemStateList.find(s => s.isDefaultInsert);
    this.projectItemDto.stateId = defaultInsertState ? defaultInsertState.itemStateId : defaultInsertState[0];
    this.projectItemDto.priority = ProjectItemPriorityEnum.MEDIUM;
    if (!this.projectItemId) {
      this.isEditing = true;
    }
    if (this.projectItemId) {
      this.projectItemSvc.getById(this.projectItemId, (pro: ProjectItemDetailDto) => {
        if (pro) {
          this.projectItemDto = pro.info;
          if (pro.documents && pro.documents.length) {
            this.discussionItemList = pro.documents.filter(d => d.documentType === ProjectDocumentTypeEnum.COMMENT);
            this.documentItemList = pro.documents.filter(d => d.documentType === ProjectDocumentTypeEnum.DOCUMENT);
          }
          if (pro.history) {
            if (pro.history.historyState && pro.history.historyState.length) {
              this.historyStateList = pro.history.historyState;
              this.historyStateList.forEach(h => {
                h.stateLabel = this.fullItemStateList.find(s => s.itemStateId === h.stateId)?.name;
              });
            }
            if (pro.history.historyChange && pro.history.historyChange.length) {
              this.historyChangeList = pro.history.historyChange;
            }
          }
          this.projectRelationList = pro.relations;
          this.projectRelationList.forEach(r => {
            r.mainItemStateLabel = this.fullItemStateList.find(s => s.itemStateId === r.mainItemState)?.name;
            r.relatedItemStateLabel = this.fullItemStateList.find(s => s.itemStateId === r.relatedItemState)?.name;
          });
          this.mapProjectRelationListToKeyValue();
        } else {
          this.projectItemId = null;
          Toastr.error('Cannot find employee data.');
        }
      });
    }
    this.getFixedListByInput();
  }

  //#region ITEM RELATION
  showCreateRelationModal() {
    this.newProjectRelation = new ProjectItemRelationDto();
    this.newProjectRelation.mainItemId = this.projectItemId;
    this.isShowCreateRelationModal = true;
  }

  showEditRelationCommentModal(updateRelationId: string) {
    const editRelation = this.projectRelationList.find(r => r.projectItemRelationId === updateRelationId);
    this.updateProjectRelationId = updateRelationId;
    this.projectRelationComment = editRelation.comment;
    this.isShowEditRelationComment = true;
  }

  deleteProjectRelation(deleteRelationId: string) {
    this.projectRelationSvc.deleteById(deleteRelationId, resp => {
      this.projectRelationList = this.projectRelationList.filter(r => r.projectItemRelationId !== deleteRelationId);
      this.mapProjectRelationListToKeyValue();
    });
  }

  handleOkEditRelationComment() {
    const editRelation = this.projectRelationList.find(r => r.projectItemRelationId === this.updateProjectRelationId);
    editRelation.comment = this.projectRelationComment;
    this.projectRelationSvc.update(
      editRelation,
      (resp: any) => {
        const updateItemIndex = this.projectRelationList.indexOf(editRelation);
        this.projectRelationList[updateItemIndex] = resp.data as ProjectItemRelationListDto;
        this.mapProjectRelationListToKeyValue();
        this.isShowEditRelationComment = false;
      },
      null,
      () => {
        this.updateProjectRelationId = null;
        this.projectRelationComment = '';
      },
    );
  }

  handleOkCreateNewRelation() {
    this.projectRelationSvc.add(
      this.newProjectRelation,
      (resp: any) => {
        const newData = resp.data as ProjectItemRelationListDto;
        newData.mainItemStateLabel = this.fullItemStateList.find(s => s.itemStateId === newData.mainItemState)?.name;
        newData.relatedItemStateLabel = this.fullItemStateList.find(s => s.itemStateId === newData.relatedItemState)?.name;
        this.projectRelationList.push(newData);
        this.mapProjectRelationListToKeyValue();
        this.isShowCreateRelationModal = false;
      },
      null,
      null,
    );
  }

  private mapProjectRelationListToKeyValue() {
    this.mapOfProjectRelation = {};
    if (this.projectRelationList && this.projectRelationList.length) {
      this.projectRelationList.forEach(item => {
        if (!this.mapOfProjectRelation[item.relationType]) {
          this.mapOfProjectRelation[item.relationType] = [];
        }
        this.mapOfProjectRelation[item.relationType].push(item);
      });
    }
  }
  //#endregion END ITEM RELATION

  //#region ITEM DOCUMENT
  openCommentModal() {
    const modal = this.modelService.open(NewCommentModalComponent, {
      size: ModalSize.xl,
    });
    modal.result.then((resp: string) => {
      if (resp) {
        const newDiscussion = new ProjectItemDocumentDto();
        newDiscussion.projectItemId = this.projectItemId;
        newDiscussion.documentType = ProjectDocumentTypeEnum.COMMENT;
        newDiscussion.description = resp;
        this.projectItemDocumentSvc.createDocument(
          newDiscussion,
          resp => {
            this.discussionItemList.push(resp.data);
          },
          null,
          null,
        );
      }
    });
  }

  handleUploadDocument = (uploadFile: NzUploadFile) => {
    const uploadModel = new ProjectItemDocumentModel();
    uploadModel.projectItemId = this.projectItemId;
    uploadModel.uploadFile = uploadFile.file;
    this.projectItemDocumentSvc.uploadDocument(
      uploadModel,
      resp => {
        this.documentItemList.push(resp.data);
      },
      null,
      null,
    );
    return Subscription.EMPTY;
    // tslint:disable-next-line: semicolon
  };

  previewProjectDocument(previewDocumentId: string) {
    const findDocument = this.documentItemList.find(d => d.itemDocumentId === previewDocumentId);
    if (findDocument) {
      const modal = this.modelService.open(PreviewImageModalComponent, {
        size: ModalSize.xl,
      });
      modal.componentInstance.imageId = findDocument.description;
    }
  }

  async downloadProjectDocument(downloadDocumentId: string) {
    // const findDocument = this.documentItemList.find(d => d.itemDocumentId === downloadDocumentId);
    // await this.cdnService.downloadFile(findDocument.description, findDocument.documentName);
  }

  deleteProjectDocument(deleteDocumentId: string) {
    this.projectItemDocumentSvc.deleteById(deleteDocumentId, resp => {
      this.documentItemList = this.documentItemList.filter(r => r.itemDocumentId !== deleteDocumentId);
    });
  }
  //#endregion END ITEM DOCUMENT

  //#region  ITEM HISTORY
  onSelectedChangeItem(selectedHistoryId: string) {
    this.selectedChangeHistory = this.historyChangeList.find(h => h.historyLogId === selectedHistoryId);
    if (this.selectedChangeHistory && !this.selectedChangeHistory.isFormatDetail) {
      this.selectedChangeHistory.historyDetails.forEach(item => {
        item.fieldDataDisplay = item.fieldData;
        if (item.fieldDescription === 'ProjectIterationId') {
          item.fieldDescription = 'Iteration';
          item.fieldDataDisplay = this.projectIterationList.find(i => i.value === item.fieldData)?.text;
        } else if (item.fieldDescription === 'ParentProjectItemId') {
          item.fieldDescription = 'Parent';
          item.fieldDataDisplay = this.projectItemList.find(i => i.value === item.fieldData)?.text;
        } else if (item.fieldDescription === 'ProjectMemberId') {
          item.fieldDescription = 'Assign Member';
          item.fieldDataDisplay = this.projectMemberList.find(i => i.projectMemberId === item.fieldData)?.fullName;
        } else if (item.fieldDescription === 'StateId') {
          item.fieldDescription = 'State';
          item.fieldDataDisplay = this.fullItemStateList.find(i => i.itemStateId === Number(item.fieldData))?.name;
        }
      });

      this.selectedChangeHistory.isFormatDetail = true;
    }
  }
  //#endregion END ITEM HISTORY

  private getFixedListByInput() {
    if (!this.projectItemId) {
      const defaultInsertState = this.fullItemStateList.find(s => s.isDefaultInsert);
      this.projectItemStateList = [defaultInsertState];
    } else {
      this.projectItemStateList = this.fullItemStateList;
    }
  }

  private addNewProjectItem() {
    if (this.formGroup.invalid) {
      return;
    }
    this.isLoading = true;
    Loading.show();
    this.projectItemSvc.add(
      this.projectItemDto,
      (resp: any) => {
        this.projectItemDto = resp.data;
        this.isEditing = false;
        this.projectItemId = this.projectItemDto.projectItemId;
        this.projectId = this.projectItemDto.projectId;
        this.sprintId = this.projectItemDto.projectIterationId;
        this.projectItemType = this.projectItemDto.projectItemType;
        this.getFixedListByInput();
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }

  private updateExistingProjectItem() {
    if (this.formGroup.invalid) {
      return;
    }
    this.isLoading = true;
    Loading.show();
    this.projectItemSvc.update(
      this.projectItemDto,
      (resp: any) => {
        this.projectItemDto = resp.data;
        this.isEditing = false;
        this.projectItemId = this.projectItemDto.projectItemId;
        this.projectId = this.projectItemDto.projectId;
        this.sprintId = this.projectItemDto.projectIterationId;
        this.projectItemType = this.projectItemDto.projectItemType;
        this.getFixedListByInput();
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }

  async getListData() {
    this.fullItemStateList = (await this.projectItemStateSvc.getStateList(this.projectId)) as ProjectItemStateDto[];
    this.projectMemberList = (await this.projectMemberSvc.getByProjectId(this.projectId)) as ProjectMember[];
    this.projectIterationList = (await this.projectIterationSvc.getListByProjectId(this.projectId)) as ProjectIterationDto[];
    this.projectItemList = ((await this.projectItemSvc.getDropDown(this.projectId)) as DropdownDto[]).filter(item => item.value !== this.projectItemId);
  }
}
