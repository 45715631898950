import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class DashboardSetupService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/dashboard-setup';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }
}
