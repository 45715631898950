import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@intranet/lib/data-access';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
  formGroup: FormGroup;
  forgotPasswordId: string;
  hasUpdatePassword = false;
  hasErrors = false;

  get password() { return this.formGroup.get('password'); }
  get confirmPassword() { return this.formGroup.get('confirmPassword'); }

  constructor(private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.initForm();
    this.validateLink();
    this.confirmPassword.valueChanges.subscribe(res => {
      if (this.password.value !== res && res !== '' && this.confirmPassword.dirty) {
        this.confirmPassword.setErrors({ isNotMatch: true });
      }
    });
  }

  async updatePassword() {
    if (!this.formGroup.valid || this.password.value !== this.confirmPassword.value) {
      this.formGroup.markAllAsTouched();
      return;
    }
    if (this.forgotPasswordId === null) {
      return;
    }

    const requestUpdatePassword = {
      forgotPasswordId: this.forgotPasswordId,
      password: this.password.value
    } as any;

    const response = await this.userService.updatePasswordWhenUserForgot(requestUpdatePassword);
    if (response?.id === -1) {
      this.hasErrors = true;
      return;
    }

    this.hasUpdatePassword = true;
  }

  private initForm() {
    this.formGroup = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', Validators.required)
    });
  }

  private async validateLink() {
    this.forgotPasswordId = this.activatedRoute.snapshot.params.id;
    if (!this.forgotPasswordId) {
      this.hasErrors = true;
      return;
    }
    const validateForgotPasswordLink = await this.userService.checkExpirationFotgotPassword(this.forgotPasswordId);
    if (validateForgotPasswordLink.id !== -1) {
      return;
    }
    this.hasErrors = true;
  }
}
