import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NewProjectIterationDto, ProjectIterationService } from '@intranet/lib/data-access';
import { Toastr, Loading } from '@intranet/lib/settings';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-new-project-iteration-modal',
  templateUrl: './new-project-iteration-modal.component.html',
  styleUrls: ['./new-project-iteration-modal.component.scss'],
})
export class NewProjectIterationModalComponent implements OnInit {
  @ViewChild('frm', { static: false }) public formGroup: NgForm;
  @Input() projectIterationId: string;
  @Input() projectId: string;
  activeModal: NgbActiveModal;
  newIteration: NewProjectIterationDto = new NewProjectIterationDto();
  isLoading: boolean;

  constructor(modalRef: NgbActiveModal, private iterationService: ProjectIterationService) {
    this.activeModal = modalRef;
  }

  ngOnInit() {
    this.initData();
  }

  onSubmit() {
    this.newIteration.projectId = this.projectId;
    if (!this.projectIterationId) {
      this.addNewProjectIteration();
    } else {
      this.updateExistingProjectIteration();
    }
  }

  closeModal() {
    this.activeModal.close(null);
  }

  private addNewProjectIteration() {
    if (this.formGroup.invalid) {
      return;
    }
    this.isLoading = true;
    Loading.show();
    this.iterationService.add(
      this.newIteration,
      () => {
        this.activeModal.close(this.newIteration);
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }

  private updateExistingProjectIteration() {
    if (this.formGroup.invalid) {
      return;
    }

    this.isLoading = true;
    Loading.show();
    this.iterationService.update(
      this.newIteration,
      () => {
        this.activeModal.close(this.newIteration);
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }

  deleteSprint() {
    this.isLoading = true;
    Loading.show();
    this.iterationService.deleteById(
      this.projectIterationId,
      () => {
        this.activeModal.close(this.newIteration);
      },
      null,
      () => {
        this.isLoading = false;
        Loading.hide();
      },
    );
  }

  private initData() {
    if (this.projectIterationId) {
      this.iterationService.getById(this.projectIterationId, (pro: NewProjectIterationDto) => {
        if (pro) {
          this.newIteration = pro;
        } else {
          Toastr.error('Cannot find employee data.');
        }
      });
    }
  }
}
