<ng-container *ngIf="widget">
  <nz-card [nzBordered]="false">
    <div class="widget-header">
      <div class="widget-title">{{widget?.data?.title}}</div>
      <div class="widget-control">
        <div *ngIf="widget?.data?.isDisplayDataManage">
          <nz-select [(ngModel)]="searchBenefitWidget.departmentId" (ngModelChange)="modalChanged($event)" nzShowSearch
            nzAllowClear nzPlaceHolder="Select a groups">
            <nz-option *ngFor="let departmentGroup of departmentGroups" [nzLabel]="departmentGroup.name"
              [nzValue]="departmentGroup.id"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>
    <div class="benefit-container">
      <ng-container *ngIf="widget?.data?.isDisplayDataManage else personalWidget">
      </ng-container>

      <ng-template #personalWidget>
        <app-benefit-user (loaded)="widgetLoadedCb()"></app-benefit-user>
      </ng-template>

    </div>
  </nz-card>
</ng-container>

<ng-template #emptyBenefitTemplate>
  <nz-empty></nz-empty>
</ng-template>