<div class="rt-panel-item surveys-panel" *ngIf="surveys.length > 0">
  <div class="row align-items-center title-panel">
    <div class="col-8">
      <p>Surveys</p>
    </div>
  </div>
  <div class="rt-panel-item-content">
    <div class="row align-items-center surveys btn-link" *ngFor="let survey of surveys"
      (click)="openSurvey(survey)">
      <div class="col">
        <div class="survey">
          <div class="label">
            <span>{{ survey.name.charAt(0) }}</span>
          </div>
          <div class="info">
            <b class="title col">{{survey.name}}</b>
            <span class="col" *ngIf="remainingDate(survey) > 3">Expiration date: {{ survey.endDate | date}}</span>
            <span class="col alert" *ngIf="remainingDate(survey) <= 3"><img
                src="assets/images/front/shortcut/time.svg" /> Expires after {{ remainingDate(survey) }}
              days</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="surveys && surveys.length < search.totalItems" class="text-center">
      <button class="more-button" (click)="loadMore()">
        View more ({{ search.totalItems - surveys.length  }}) surveys <i class=" fas fa-chevron-down"></i>
      </button>
    </div>

    <div class="row align-items-center" *ngIf="isLoading">
      <nz-skeleton [nzActive]="true"></nz-skeleton>
    </div>
  </div>
</div>