export enum CompensationHourUserStatusEnum {
  New = 1,
  Approved = 2,
  Rejected = 3
}

export enum CompensationHourUserStatusColorEnum {
  New = 'text-info',
  Approved = 'text-success',
  Rejected = 'text-danger'
}
