import { Constants } from '@intranet/index';
import { CalendarEventTypeEnum } from '@intranet/lib/data-access';
import { DateHelper } from '@intranet/lib/helpers';
import { subMonths } from 'date-fns';
import { CheckInCheckOutStatusEnum, ReviewFormStatusEnum } from '.';
import { AnnualLeaveRequestStatusEnum, AnnualLeaveRequestTypeEnum } from './enums/annual-leave-request.enum';
import { BenefitRequestStatusEnum } from './enums/benefit-request-status.enum';
import { GroupMemberSortEnum, GroupMemberSortOrderingEnum } from './enums/group-member-sort.enum';
import { MonthlyMissingHoursStatusEnum } from './enums/monthly-missing-hours.enum';
import { NotificationEnum } from './enums/notification.enum';
import { ReviewTemplateStatusEnum, ReviewTemplateTypeEnum } from './enums/review-template.enum';
import { PagingSortenum } from './enums/sortType.enum';
import { NewsflashStatus, UserNewsflashStatus } from './newsflash.models';

export class SearchPagination {
  keyWord?: string;
  pageNumber: number;
  pageSize: number;
  sortField: string;
  sortFieldType: PagingSortenum;

  constructor(searchText?: string, pageNumber?: number, pageSize?: number, sortField?: string, sortType?: PagingSortenum) {
    this.keyWord = searchText;
    this.pageNumber = pageNumber ?? 1;
    this.pageSize = pageSize ?? Constants.CLIENT_PAGE_SIZE;
    this.sortField = sortField;
    this.sortFieldType = sortType ?? PagingSortenum.ASC;
  }
}

export class SearchModel {
  keyWord?: string;
  startDate?: Date;
  endDate?: Date;
  pageNumber?: number;
  pageSize?: number;
  totalPages?: number;
  isGenerate?: boolean;
  // pagination?: Page;
  totalItems?: number;
  ignores? = [];
  noLoading?: boolean;

  formatDate(hasTime = false) {
    if (hasTime) {
      this.startDate = DateHelper.formatServer(this.startDate);
      this.endDate = DateHelper.formatServer(this.endDate);
    } else {
      this.startDate = DateHelper.formatServerNotTime(this.startDate);
      this.endDate = DateHelper.formatServerNotTime(this.endDate);
    }
  }

  setTotalItem(lists: any[]) {
    if (lists && lists.length) {
      this.totalItems = lists[0].totalCount;
    } else {
      this.totalItems = 0;
    }
  }
  calTotalPage() {
    this.totalPages = Math.ceil(this.totalItems / this.pageSize);
  }

  validatePageNumber(pageNumber: number): boolean {
    return this.totalPages >= pageNumber;
  }

  constructor() {
    this.keyWord = '';
    // this.pagination = new Page();
    this.ignores = ['pagination', 'totalItems', 'eDate', 'sDate'];
    this.pageNumber = 0;
  }
}
export class SearchEmployee extends SearchModel {
  departments?: any;
  notDepartmentId?: any;
  departmentId?: string;
  statusId?: number;
  status?: number;
  hasUser?: boolean;
  showDeleted?: boolean;
  year?: number;
  ids: string;
  departmentIds: string;
  startDateRange: string;
  sortField: string;
  sortFieldType: PagingSortenum;
  constructor(pageSize = 20, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
    this.departmentId = '';
    this.departmentIds = '';
    this.startDateRange = '';
  }
}

export class PurchaseRequestSearch extends SearchModel {
  purchaseRequestNo?: string;
  departmentIds?: string;
  statusId?: number;
  status?: number;
  createdDateRange: string;
  employeeIds?: string;
  constructor(pageSize = 20, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
    this.departmentIds = '';
  }
}

export class SearchAnnualLeave extends SearchModel {
  year?: number;
  departmentId?: string;
  constructor(pageSize = 20, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.year = DateHelper.currentDate.getFullYear();
    this.pageNumber = 1;
  }
}
export class SearchSimple extends SearchModel {
  constructor(pageSize = 20, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchDataModelProject extends SearchPagination {
  departmentId: string;
}
export class SearchWarehouse extends SearchModel {
  purchaseRequestId: string;
  purchaseRequestDetailId?: string;
  departments: string;
  supplierId: string;
  categoryId: string;
  deliveryFormId?: string;
  hasQuantity: boolean;
  id?: string;
  constructor(pageSize = 20, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchWorkTime extends SearchModel {
  departments?: any;
  year?: number;
  month?: number;
  type?: number;
  employeeId?: string;
  employeeName?: string;
  constructor(pageSize = 20, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
    this.type = 1;
    this.employeeId = '';
    this.endDate = undefined;
    this.employeeName = undefined;
  }
}

export class SearchWorkTimePersonal extends SearchModel {
  employeeId: string;
  constructor(pageSize = 20, pageNumber = 1) {
    super();
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
  }
}

export class EmployeeQuery extends SearchModel {
  constructor(pageSize = 20, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
    this.endDate = new Date();
    this.startDate = subMonths(this.endDate, 3);
  }
}

export class SearchNewsFeedType extends SearchModel {
  constructor(pageSize = 20, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}
export class SearchTemplateEmail extends SearchModel {
  constructor(pageSize = 1000, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchTemplateHtml extends SearchModel {
  constructor(pageSize = 1000, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchWidget extends SearchModel {
  constructor(pageSize = 1000, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchNewsFeed extends SearchModel {
  departments: string;
  newsFeedTypeId: string;
  type: number;
  constructor(pageSize = 20, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchComment extends SearchModel {
  noLoading: boolean;
  newsFeedId: string;
  parentId: string;

  constructor(pageSize = 20, isGenerate = false) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;

    this.noLoading = true;
  }
}

export class SearchWallNewsFeed extends SearchModel {
  departments: string;
  newsFeedTypeId: string;
  noLoading: boolean;
  type: number;
  includeReaction: boolean;
  includeComment: boolean;
  includeUser: boolean;
  groupId?: string;

  constructor(pageSize = 20, isGenerate = false) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;

    this.noLoading = true;
    this.includeComment = true;
    this.includeReaction = true;
    this.includeUser = true;
  }
}

export class SearchTicket extends SearchModel {
  noLoading: boolean;
  type: number;
  ticketTypeId: string;
  includeReaction: boolean;
  includeComment: boolean;
  includeUser: boolean;

  constructor(pageSize = 20, isGenerate = false) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;

    this.noLoading = true;
    this.includeComment = true;
    this.includeReaction = true;
    this.includeUser = true;
  }
}

export class SearchProduct extends SearchModel {
  keyWord: string;
  categoryId: string;
  constructor(pageSize = 10, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchPackage extends SearchModel {
  keyWord: string;
  constructor(pageSize = 10, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchSupplier extends SearchModel {
  keyWord: string;
  constructor(pageSize = 10, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchNormal extends SearchModel {
  keyWord: string;
  constructor(pageSize = 10, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchLeaveRequest extends SearchModel {
  departments?: any;
  departmentId?: string;
  notDepartmentId?: any;
  statusId?: number;
  hasUser?: boolean;
  leaveRule = null;
  constructor(pageSize = 20, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
    this.departmentId = '';
  }
}
export class SearchPolicy extends SearchModel {
  constructor(pageSize = 20, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchPolicyDetail extends SearchModel {
  policyId: string;
  constructor(pageSize = 20, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}
export class SearchNotification extends SearchModel {
  notificationType: NotificationEnum;
  constructor(pageSize = 20, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
    this.notificationType = NotificationEnum.TODO;
  }
}

export class SearchEvent extends SearchModel {
  userId: string;
  fromDate: string;
  toDate: string;
  groupId: string;
  calendarTypes: CalendarEventTypeEnum[];

  constructor(pageSize = 20, isGenerate = false) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchGroupMember extends SearchModel {
  groupId: string;
  noLoading: true;
  clientMode: boolean;
  sortColumn: GroupMemberSortEnum;
  sortOrder: GroupMemberSortOrderingEnum;
  userId: string;
  constructor(pageSize = 20, pageNumber = 1) {
    super();
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
    this.clientMode = false;
    this.sortColumn = Constants.HeadcountMemberDefaultSortColumn;
    this.sortOrder = Constants.HeadcountMemberDefaultSortOrdering;
    this.userId = null;
  }
}

export class SearchScheduleGroup extends SearchModel {
  constructor(pageSize = 20, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchBenefit extends SearchModel {
  constructor(pageSize = 20, pageNumber = 1) {
    super();
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
  }
}
export class SearchBenefitMemberRegistration extends SearchModel {
  keyword: string;
  status: BenefitRequestStatusEnum | null;
  departments: string;
  year: number | null;
  startCreatedDate: Date | null;
  endCreatedDate: Date | null;
  benefitId: string | null;
  constructor(pageSize = 20, pageNumber = 1) {
    super();
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
  }
}

export class SearchAnnualLeaveRequest extends SearchPagination {
  type: AnnualLeaveRequestTypeEnum;
  status: AnnualLeaveRequestStatusEnum;
  month: number;
  year: number;
  departments: string[];
  constructor(pageSize = 15) {
    super();
    this.pageSize = pageSize;
    this.pageNumber = 1;
  }
}

export class SearchMonthlyMissingHours extends SearchPagination {
  status: MonthlyMissingHoursStatusEnum;
  month: number;
  year: number;
  departments: string[];
  constructor(pageSize = 15) {
    super();
    this.pageSize = pageSize;
    this.pageNumber = 1;
  }
}

export class SearchBenefitWidget extends SearchPagination {
  departmentId: string;
  constructor(pageSize = 20) {
    super();
    this.pageSize = pageSize;
    this.pageNumber = 1;
  }
}

export class SearchBenefitWidgetMembers extends SearchPagination {
  benefitId: string;
  departmentId: string;
  constructor(pageSize = 15) {
    super();
    this.pageSize = pageSize;
    this.pageNumber = 1;
  }
}
export class SearchAnnualReviewCriteriaType extends SearchModel {
  constructor(pageSize = 15, pageNumber = 1) {
    super();
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
  }
}

export class SearchReviewRating extends SearchPagination {
  startScore: number;
  endScore: number;
  mapScore: string;

  constructor() {
    super();
  }
}

export class SearchReviewTemplate extends SearchPagination {
  title: string;
  type: ReviewTemplateTypeEnum;
  status: ReviewTemplateStatusEnum;

  constructor(pageSize = 15, pageNumber = 1) {
    super();
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
  }
}

export class SearchReviewColTemplate extends SearchModel {
  title: string;

  constructor(pageSize = 15, pageNumber = 1) {
    super();
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
  }
}

export class SearchCheckInCheckOut extends SearchModel {
  employeeId: string | null;
  status: CheckInCheckOutStatusEnum | null;
  departmentId: string;

  constructor(pageSize = 15, pageNumber = 1) {
    super();
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
  }
}

export class SearchReviewForm extends SearchPagination {
  id: string;
  title: string;
  formStatus?: ReviewFormStatusEnum;
  month?: number;
  year?: number;
  templateType: number;

  constructor() {
    super();
  }
}

export class SearchReviewFormMember {
  id: string;
  keyword: string;
  departmentId: string;
  formMemberId: string;
  reviewFormDisplayType: number;
  noLoading = true;

  constructor() {}
}

export class BaseRequestOption {
  keyword: string;
  pageNumber: number;
  pageSize: number;
  sortField: string;
  sortFieldType: PagingSortenum;

  constructor(pageSize = 20) {
    this.pageNumber = 1;
    this.pageSize = pageSize;
  }
}

export class WarehouseReqOption extends BaseRequestOption {
  productKeyWord: string;
  categoryKeyWord: string;
  productIds: string;
  categoryIds: string;
}

export class SearchUser extends SearchModel {
  departmentId: string;
  excludedUerIds?: string[];
  constructor(pageSize = 10, isGenerate = true) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
    this.departmentId = '';
  }
}

export class SearchNewsflash {
  keyWord = '';
  status: NewsflashStatus = null;
  pageNumber = 1;
  pageSize = 20;
}

export class SearchNewsflashDetail {
  keyWord = '';
  employeeIds = '';
  departmentIds = '';
  status: UserNewsflashStatus = null;
  pageNumber = 1;
  pageSize = 20;
}
