import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { ReviewTemplate } from '../../models/review/review-template.model';
@Injectable({
  providedIn: 'root',
})
export class ReviewTemplateService {
  private rootUrl = '/api/review-template';

  constructor(private httpClient: HttpClientService) { }

  getById(id: string): Promise<ReviewTemplate> {
    const url = `${this.rootUrl}/get-by-id`;
    return this.httpClient.getObservable(url, { id }).toPromise();
  }

  getListApprovedTemplates(): Promise<ReviewTemplate[]> {
    const url = `${this.rootUrl}/get-list-approved`;
    return this.httpClient.getObservable(url).toPromise();
  }

  view(searchData: any): Promise<any> {
    return this.httpClient.getObservable(this.rootUrl, searchData).toPromise();
  }

  create(data: ReviewTemplate): Promise<ReviewTemplate> {
    return this.httpClient.postObservable(this.rootUrl, data).toPromise();
  }

  update(data: ReviewTemplate): Promise<ReviewTemplate> {
    return this.httpClient.putObservable(this.rootUrl, data).toPromise();
  }

  sendForApproval(data: ReviewTemplate): Promise<ReviewTemplate> {
    const url = `${this.rootUrl}/send-for-approval`;
    return this.httpClient.putObservable(url, data).toPromise();
  }

  approve(data: ReviewTemplate): Promise<ReviewTemplate> {
    const url = `${this.rootUrl}/approve`;
    return this.httpClient.putObservable(url, data).toPromise();
  }

  reject(data: ReviewTemplate): Promise<ReviewTemplate> {
    const url = `${this.rootUrl}/reject`;
    return this.httpClient.putObservable(url, data).toPromise();
  }

  delete(id: string): Promise<void> {
    const url = `${this.rootUrl}?id=${id}`;
    return this.httpClient.deleteObservable(url).toPromise();
  }

  clone(id: string): Promise<void> {
    const url = `${this.rootUrl}/${id}/clones`;
    return this.httpClient.postObservable(url).toPromise();
  }

  getRatingDetailScore(templateId: string) {
    const url = `${this.rootUrl}/${templateId}/rating-detail-scores`;
    return this.httpClient.getObservable(url).toPromise();
  }

  getSectionsById(id: string): Promise<ReviewTemplate> {
    const url = `${this.rootUrl}/details-by-id`;
    return this.httpClient.getObservable(url, { id }).toPromise();
  }

  getRatingDetails(templateId: string) {
    const url = `${this.rootUrl}/${templateId}/rating-details`;
    return this.httpClient.getObservable(url).toPromise();
  }

  getSectionAssociateRatingDetails(templateId: string) {
    const url = `${this.rootUrl}/${templateId}/section-rating-details`;
    return this.httpClient.getObservable(url).toPromise();
  }

  getSectionAssociateColumnDetails(templateId: string) {
    const url = `${this.rootUrl}/${templateId}/section-column-details`;
    return this.httpClient.getObservable(url);
  }

  getSummarySectionAssociateColumnDetails(templateId: string) {
    const url = `${this.rootUrl}/${templateId}/summary-section-column-details`;
    return this.httpClient.getObservable(url);
  }
}
