<div class="rt-panel-item">
  <div class="row align-items-center title-panel">
    <div class="col">
      <p>Compensation Hour</p>
    </div>
  </div>
  <div class="rt-panel-item-content">
    <ngx-datatable #myTable class="material grid-aluha" [rows]="compensationHours" [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50"
      [virtualization]="false" [scrollbarH]="true" rowHeight="auto">
      <ngx-datatable-column *ngFor="let col of columnMapping" prop="{{ col.ColumnName }}" name="{{ col.DisplayName }}" [canAutoResize]="col.AutoResize"
        [sortable]="col.Sortable">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <ng-container [ngSwitch]="col.ColumnName">
            <div *ngSwitchCase="'statusMapping'" [ngClass]="compensationHourUserStatusColorEnum[compensationHourUserStatusEnum[value]]">
              <span>{{compensationHourUserStatusEnum[value]}}</span>
            </div>
            <span *ngSwitchCase="'date'">
            {{ value | date: 'fullDate' }}
            </span>
            <span *ngSwitchDefault>
            {{value}}
            </span>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Actions" prop="id" fixed [width]="200" [frozenRight]="true" RemoveEmptyMenu>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <grid-button tooltip="History" (okEvent)="openPopupHistory(row)">
            <i nz-icon nzType="history" noconfirm nzTheme="outline"></i>
          </grid-button>
          <grid-button *ngIf="!row?.hasRegistered" className="text-warning" tooltip="Register" (okEvent)="openPopupRegisterUser(row)">
            <i nz-icon nzType="diff" noconfirm nzTheme="outline"></i>
          </grid-button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
