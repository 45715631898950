import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { UrlConstant } from '../config';
import { AppConfig } from '../environments/config/appConfig';
import { SecurityHelper } from '../helpers';
import { CookieHelper } from '../helpers/cookieHelper';
@Injectable({
  providedIn: 'root',
})
export class AccessSiteService implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router) { }

  canActivate() {
    const user = SecurityHelper.User;
    if (user?.isExternalAccount) {
      return this.router.navigate([UrlConstant.CLIENT_PAGE]);
    }
    if (!AppConfig.settings.isDev) {
      return true;
    }
    // const checkerLogin = CookieHelper.getCookieAccess();
    // if (checkerLogin === 'restaff@2019@123') {
    //   return true;
    // } else {
    if (AppConfig.settings.isMaintenance) {
      return this.router.navigate(['/maintenance']);
    }
    return this.router.navigate(['/login-access-page']);
    // }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate();
  }

  canLoad(route: Route) {
    return this.canActivate();
  }
}
