<div class="modal-header">
  <button *ngIf="canBack" type="button" class="modal-back" aria-label="Back" (click)="back()">
    <span><img src="assets/images/icons/arrow-left.svg" type="local"></span>
  </button>
  <ng-template *ngIf="headerTemplate" [ngTemplateOutlet]="headerTemplate"></ng-template>
  <h4 *ngIf="strTitle" class="modal-title">{{ strTitle }}</h4>
  <!-- <a (click)="activeModal.close()">
    <span class="fs24 pointer close mr10">&times;</span>
  </a> -->
  <ng-content *ngIf="!strTitle || !headerTemplate"></ng-content>

  <button *ngIf="!canBack"  type="button" class="modal-close" aria-label="Close" data-dismiss="modal" (click)="closeEvent()">
    <span><img src="assets/images/icons/icon-close.svg" type="local"></span>
  </button>
  <span *ngIf="canBack" ></span>
</div>