import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UrlConstant } from '../config';
import { SecurityHelper } from '../helpers';

@Injectable({
    providedIn: 'root',
})
export class ClientGuardService implements CanActivate, CanActivateChild {
    constructor(private router: Router) { }

    canActivate() {
        const user = SecurityHelper.User;
        if (user?.isExternalAccount) {
            return true;
        }
        this.router.navigate([UrlConstant.USER_FIRSTPAGE]);
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate();
    }
}
