import { Component, OnInit } from '@angular/core';
import { BaseAdminComponent } from '@intranet/lib/components/base-admin-component/base-admin-component.component';
import { ColumnMapping, COLUMN_TYPE } from '@intranet/lib/data-access/models/datatable.model';
import { CompensationHourUserStatusColorEnum, CompensationHourUserStatusEnum } from '@intranet/lib/data-access/models/enums/compensation-hour-user-status.enum';
import { CompensationHourUserService } from '@intranet/lib/data-access/services/compensation-hour-user.service';
import { SecurityHelper } from '@intranet/lib/helpers/securityHelper';
import { ModalOption, ModalService, ModalSize } from '@intranet/lib/settings/services/modal.service';
import { Toastr } from '@intranet/lib/settings/services/toastr';
import { HistoryComponent } from './history/history.component';
import { UserRegisterComponent } from './user-register/user-register.component';

@Component({
  selector: 'app-compensation',
  templateUrl: './compensation.component.html',
  styleUrls: ['./compensation.component.scss']
})

export class CompensationComponent extends BaseAdminComponent implements OnInit {
  userId = null;
  columnMapping: ColumnMapping[] = [
    new ColumnMapping(COLUMN_TYPE.DATE, 'date', 'Date', true, true),
    new ColumnMapping(COLUMN_TYPE.STRING, 'startTime', 'Start Time', true, true),
    new ColumnMapping(COLUMN_TYPE.STRING, 'endTime', 'End Time', true, true),
    new ColumnMapping(COLUMN_TYPE.STRING, 'minimumWorkTime', 'Minimum Working Hour', true, true),
    new ColumnMapping(COLUMN_TYPE.STRING, 'statusMapping', 'Status', true, true)
  ];

  modalOption: ModalOption = {
    size: ModalSize.md,
  };
  compensationHourUserStatusEnum = CompensationHourUserStatusEnum;
  compensationHourUserStatusColorEnum = CompensationHourUserStatusColorEnum;

  compensationHours: any[] = [];

  constructor(private modalSvc: ModalService,
    private compensationHourUserService: CompensationHourUserService) {
    super();
  }

  ngOnInit() {
    const authToken = SecurityHelper.getStoreAuthen();
    this.userId = authToken?.id;

    this.loadData();
  }

  openPopupRegisterUser(compensationHour: any) {
    const modalRef = this.modalSvc.open(UserRegisterComponent, this.modalOption);
    modalRef.componentInstance.compensationHour = compensationHour;
    modalRef.result.then((res: any) => {
      if (res) {
        Toastr.success('Register successfully');
        this.loadData();
      }
    });
  }

  openPopupHistory(compensationHour: any) {
    const modalRef = this.modalSvc.open(HistoryComponent, { size: ModalSize.lg } as ModalOption);
    modalRef.componentInstance.compensationHour = compensationHour;
  }

  async loadData() {
    if (!this.userId) {
      return;
    }
    try {
      const compensationHourResponse = await this.compensationHourUserService.getCompensationHourByUserId(this.userId) as any[];

      const compensationHourMapping = compensationHourResponse.map(item => {
        const hasRegistered = item?.compensationHourUsers?.some(m => m.status !== CompensationHourUserStatusEnum.Rejected);
        const statusMapping = item?.compensationHourUsers?.length > 0 ? item?.compensationHourUsers[0]?.status : CompensationHourUserStatusEnum.New;
        return { ...item, hasRegistered, statusMapping } as any;
      });
      this.compensationHours = Object.assign([].concat(compensationHourMapping));
    }
    catch (res) {
      if (res?.statusText) {
        Toastr.error(res?.statusText);
        return;
      }
      let message = 'Cannot get compensation hour request, please try again';
      if (res?.error?.message) {
        message = res.error.message;
      }
      Toastr.error(message);
    }
  }
}
