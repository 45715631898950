import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import { WidgetCompanyDto, WidgetService, WidgetSearchDto, MemberDto } from '@intranet/lib/data-access';
import { Subscription } from 'rxjs';
import { GridsterItem } from 'angular-gridster2';
import { WebsiteEvent } from '@intranet/lib/settings/event-store/website.event';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
@Component({
  selector: 'app-widget-company',
  templateUrl: './widget-company.component.html',
  styleUrls: ['./widget-company.component.scss'],
})
export class WidgetCompanyComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;
  @Input() widgetLoaded: EventEmitter<any>;
  resizeSub: Subscription;

  loading = true;
  dataSelect: any;
  pieLeftPosition: number;
  searchData: WidgetSearchDto = new WidgetSearchDto();
  data: WidgetCompanyDto = new WidgetCompanyDto();
  dataDetail: MemberDto[];
  isDetail = false;
  title: string;

  selectGroupSubscription: Subscription;

  constructor(private _widgetService: WidgetService,
    private websiteEvent: WebsiteEvent) { }

  ngOnDestroy() {
    if (this.selectGroupSubscription) {
      this.selectGroupSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.selectGroupSubscriptionEvent();
  }

  selectGroupSubscriptionEvent() {
    this.selectGroupSubscription = this.websiteEvent.groupSourceDataEvent.subscribe((departmentId: any) => {
      if (!this.searchData.departmentIds || departmentId === '') {
        this.searchData.departmentIds = departmentId !== '' ? departmentId : null;
        this.loadData();
      }
    });
  }

  async loadData() {
    try {
      this.loading = true;
      this.data = await this._widgetService.getWidgetCompany(this.searchData);

      if (innerWidth < 640) {
        this.data.companyMembers.view = [innerWidth / 1.2, 300];
        this.data.companyMembers.legendPosition = 'below';
      }
      this.dataSelect = null;
      this.widgetLoaded?.emit(this.widget);
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
    finally {
      this.loading = false;
    }
  }

  modelChanged(event) {
    this.searchData.departmentIds = event;
    this.dataSelect = null;
    this.loadData();
    if (event !== null) {
      this.websiteEvent.setGroupSourceDataEvent(event);
    }
  }
  onSelect(item) {
    this.viewDetail(item);
  }
  viewDetail(item) {
    const name = item.name ? item.name : item;
    const itemData = this.data.companyMembers.chartDatas.find(i => i.name === name);
    if (itemData && itemData.data) {
      this.dataDetail = JSON.parse(itemData.data);
      this.title = name;
      this.isDetail = true;
    }
  }
  hideDetail() {
    this.isDetail = false;
  }
}
