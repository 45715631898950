import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AppInjector } from '../settings/services/app-injector';
export class WebHelper {
  static get isBrowser() {
    const platformId = AppInjector.getService<any>(PLATFORM_ID);
    if (isPlatformBrowser(platformId)) {
      return true;
    }
    return false;
  }

  removePortalSty;
  static filterByRegEx(columnValue, value) {
    value = value.removeVietnamese().toLowerCase();
    const regEx = new RegExp(value, 'i');
    const colValue = columnValue.removeVietnamese().toLowerCase();
    const result = regEx.test(colValue);
    return result;
  }

  static getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    // tslint:disable-next-line: no-non-null-assertion
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }
  static unSubscribe(...params: any) {
    for (const iterator of params) {
      if (iterator) {
        iterator.unsubscribe();
      }
    }
  }

  static getErrorMessage(error: any) {
    if (error) {
      if (error.error) {
        return error.error.Message;
      } else {
        return error.message || error;
      }
    }
    return '';
  }

  static hexToRgb(hex: string) {
    const reu = hex
      .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1)
      .match(/.{2}/g)
      .map(x => parseInt(x, 16));
    return reu;
  }

  static detectTextColor(color: string, outDarkColor = 'text-black-50', outLightColor = 'text-white') {
    if (!color) {
      return 'outLightColor';
    }
    const checkRgba = color.indexOf('rgb');
    let rgb;
    if (checkRgba === -1) {
      rgb = WebHelper.hexToRgb(color);
    } else {
      rgb = color
        .replace(/[a-z ()]/g, '')
        .split(',')
        .map(x => parseInt(x, 16));
    }
    const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
    const textColour = brightness > 125 ? outDarkColor : outLightColor;
    return textColour;
  }
}
