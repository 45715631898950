export class WorktimeSetting {
  public id: string;
  public startDay: number;
  public endDay: number;
  public startTime: any;
  public endTime: any;
  public earliestStartTime: any;
  public latestEndTime: any;
  public payrollCycleDate: number;
  public backgroundJobGetLeaveDate: number;
  public hoursGetLeaveWidthDay: any;
  public nonWorkingStartTime: any;
  public nonWorkingEndTime: any;
  public totalChangeCheckinAndCheckout: number;
  public percentMissingHoursNotification: number;
  public dayOfWeek: number;
  public hourOfDay: number;
  public relaxHour: number;
  public hourLate: number;
  public numberOfLate: number;
  periodTimeForFemaleByHour: number;
  sponsorAfterMaternityByHour: number;
  sponsorAfterMaternityByMonth: number;
  numberOfProbationByMonth: number;
  constructor() {
    this.startTime = 0;
    this.endTime = 0;
    this.earliestStartTime = 0;
    this.latestEndTime = 0;
    this.earliestStartTime = 0;
    this.latestEndTime = 0;
  }
}
