import { ReviewFormMember } from './review-form-member.model';

export class CreateReviewSummaryDiscuss {
  reviewPerformanceSummaryId: string;
  comment: string;

  constructor(summaryId: string, comment: string) {
    this.reviewPerformanceSummaryId = summaryId;
    this.comment = comment?.trim();
  }
}

export class UpdateReviewSummaryDiscuss {
  id: string;
  comment: string;

  constructor(commentId: string, comment: string) {
    this.id = commentId;
    this.comment = comment?.trim();
  }
}

export class ReviewSummaryDiscuss {
  id: string;
  reviewPerformanceSummaryId: string;
  comment: string;
  userId: string;
  user: any;
  createdDate: Date;
  updatedDate: Date;
  lastEditTime: Date;

  isEditable: boolean;
  isEditing: boolean;
  tempComment: string;
}

export class ReviewPerformanceSummary {
  id: string;
  comment: string;
  summary: string;
  summaryAppraiser: string;
  summaryAppraiser2: string;
  workAchievementAppraiser: string;
  workAchievementAppraiser2: string;
  directionForTheComingYearAppraiser: string;
  directionForTheComingYearAppraiser2: string;
  additionalCommentAppraiser: string;
  reviewPerformanceFormMemberId: string;
  reviewPerformanceFormMember: ReviewFormMember;
  reviewPerformanceSummaryDiscusses: ReviewSummaryDiscuss[];
  users: any[];
}
