import { Component, OnInit, Input } from '@angular/core';
import { BaseAdminComponent } from '@intranet/lib/components';
import { NewsFeed, ReactionService, Reaction } from '@intranet/lib/data-access';
import { ModalService, ModalSize } from '@intranet/index';
import { NewsFeedReactionModalComponent } from './news-feed-reaction-modal/news-feed-reaction-modal.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-news-feed-reaction',
  templateUrl: './news-feed-reaction.component.html',
  styleUrls: ['./news-feed-reaction.component.scss']
})
export class NewsFeedReactionComponent extends BaseAdminComponent implements OnInit {
  @Input() feed: NewsFeed;
  public userLiked: boolean;
  isSubmiting: boolean;
  isLoadingReaction: boolean;
  reactions: Reaction[];

  constructor(
    private reactionService: ReactionService,
    private modalService: ModalService
  ) {
    super();
    this.userLiked = false;
  }

  ngOnInit() {
    if (
      this.feed.reactions &&
      _.findIndex(this.feed.reactions, reaction => {
        return reaction.reaction === 1;
      }) >= 0
    ) {
      this.userLiked = true;
    }
  }

  onLike(feed: NewsFeed) {
    if (this.isSubmiting) {
      return;
    }
    this.isSubmiting = true;
    this.reactionService.toggleFeed(
      {
        noLoading: true,
        newsFeedId: feed.id,
      },
      res => {
        if (res && res.id > 0) {
          if (res.status > 0) {
            this.userLiked = true;
            this.feed.totalLikes++;
          } else {
            this.userLiked = false;
            this.feed.totalLikes--;
          }

          this.feed.totalLikes = Math.max(0, this.feed.totalLikes);
        }
      },
      (error) => { },
      () => {
        this.isSubmiting = false;
      }
    );
  }

  showReactions() {
    if (this.feed && this.feed.id) {
      const modal = this.modalService.open(NewsFeedReactionModalComponent, {
        size: ModalSize.md,
      });
      modal.componentInstance.feedId = this.feed.id;
    }
  }
}