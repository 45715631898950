import { Injectable } from '@angular/core';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { HttpClientService } from '@intranet/index';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/project';
  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }
}
