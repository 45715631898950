<app-display-member-template *ngIf="!isClientPage && !isMobileView" [memberData]="data"></app-display-member-template>

<app-members-view *ngIf="isClientPage" [memberData]="data" [headers]="headers" [format]="format" [extraInfoColumnTemplate]="memberSkillsTpl"
  [extraInfoColumnName]="extraInfoColumnName" [extraInfoColumnBootstrapWidth]="extraInfoColumnBootstrapWidth" [isLoading]="isLoading"></app-members-view>
<ng-template #memberSkillsTpl let-member>
  <nz-tag *ngFor="let skill of member.skills" nzColor="green">{{skill}}</nz-tag>
</ng-template>

<div *ngIf="!isClientPage && isMobileView" class="widget-skill-detail-wrapper">
  <div class="member-data">
    <div class="no-member" *ngIf="!data?.length">
      <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="noMemberTpl"></nz-empty>
      <ng-template #noMemberTpl>
        <span class="no-member-text">No data</span>
      </ng-template>
    </div>

    <virtual-scroller *ngIf="data?.length > 0" #scroll [items]="data" class="scroller" [style.height.px]="data.length * 80" [bufferAmount]="7">
      <div class="member-row-data" *ngFor="let member of scroll.viewPortItems; let idx = index">
        <div class="member-avatar">
          <app-avatar [size]="40" [(avatarSource)]="member.avatarUrl" [(avatarText)]="member.fullName"> </app-avatar>
        </div>

        <div (click)="openInformation(member)" class="font-12 member-information">
          <p class="font-weight-bold">{{member.fullName}}</p>
          <p class="font-weight-bold">{{member.positionName}}</p>
          <div *ngIf="member.departments">
            <nz-tag *ngFor="let department of member.departments" [nzColor]="'geekblue'">
              <span [innerHTML]="department.name"></span>
            </nz-tag>
          </div>
        </div>
      </div>
    </virtual-scroller>
  </div>
</div>
