import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService, Toastr } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SurveyService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/survey';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getSurvey(id: string, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `get-survey?id=${id}`;
    const params = {
      id,
      noLoading: true
    };
    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  createSurvey(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `create-survey`;
    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }

  updateSurvey(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `update-survey`;
    data.noLoading = true;
    return this.updateUrl(url, data, undefined, successCallback, failedCallback, completeCallback);
  }

  getSurveys(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `get-surveys`;
    params.noLoading = true;
    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  deleteSurvey(id: string, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `delete-survey`;
    const noLoading = true;
    const params = {
      noLoading, id
    };

    return this.deleteByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  getUserSurveys(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `get-user-survey`;
    params.noLoading = true;
    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  createUserSurveyAnswer(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `create-survey-answer`;
    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }

  getSurveyResult(params: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `get-survey-result`;
    return this.getByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  approveSurvey(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = `approve-survey`;
    return this.updateUrl(url, data, undefined, successCallback, failedCallback, completeCallback);
  }

  exportSurveyAnswer(surveyId: string): Observable<any> {
    const url = `/api/survey/${surveyId}/export`;
    return this.httpClient.getObservable(url, {});
  }
}
