import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-blank-layout',
    templateUrl: './blank-layout.component.html',
    styles: [],
})
export class BlankLayoutComponent implements OnInit {
    constructor() { }

    ngOnInit() {

    }
}
