export class UpdateOrderModel {
  ObjectId: string;
  Order: number;

  constructor(id: string, order: number) {
    this.ObjectId = id;
    this.Order = order;
  }
}

export class UpdateOrderNumberDto<T> {
  mainObjectId: string;
  items: UpdateOrderItemDto<T>[];
}

export class UpdateOrderItemDto<T> {
  objectId: T;
  order: number;
}
