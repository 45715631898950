<nz-card class="redeem-gifts-main">
  <nz-card-tab>
    <nz-tabset nzSize="large" [(nzSelectedIndex)]="tabIndex" [nzTabBarExtraContent]="userPoints">
      <nz-tab nzTitle="Redeem gifts" (nzClick)="tabChange(tabRedeem)"></nz-tab>
      <nz-tab nzTitle="View redeem history" (nzClick)="tabChange(tabRedeemHistory)"></nz-tab>
    </nz-tabset>
  </nz-card-tab>
  <ng-template #userPoints>
    <!-- <div class="row">
      <div class="col">
        <div class="user-points row">
          <div class="col-9">
            Your usable points:
          </div>
          <div class="col-3 nowrap-text">
            <ng-container *ngIf="userReward">
              <span class="points" [innerHTML]="userReward.point"></span>
              <ng-container *ngIf="userReward.rawPoint && userReward.rawPoint > 0"> / {{userReward.rawPoint}}</ng-container>
            </ng-container>
          </div>
        </div>
        <div class="user-points row">
          <div class="col-9">
            Your redeeming points:
          </div>
          <div class="col-3 nowrap-text">
            <span *ngIf="userReward" class="points">{{userReward.redeemingPoint}}</span>
          </div>
        </div>
      </div>
    </div> -->
    <div *ngIf="userReward" class="user-points">
      Your usable points: <span class="points" [innerHTML]="userReward.point"></span>
      <ng-container *ngIf="userReward.rawPoint && userReward.rawPoint > 0"> / {{userReward.rawPoint}}</ng-container>
      (Redeeming <span *ngIf="userReward" class="points">{{userReward.redeemingPoint}}</span> point<ng-container *ngIf="userReward.redeemingPoint > 1">s</ng-container>)
    </div>
  </ng-template>
  <ng-container *ngIf="tabIndex === tabRedeem">
    <div class="rt-panel-item">
      <div class="row align-items-center title-panel">
        <div class="col">
          <p>Redeem gifts</p>
        </div>
      </div>
      <div class="rt-panel-item-content">
        <ngx-datatable #myTable class="material grid-aluha" [rows]="giftData" [columnMode]="ColumnMode.force" [loadingIndicator]="isLoading" [headerHeight]="50"
          [footerHeight]="50" [virtualization]="false" [externalSorting]="false" [scrollbarH]="true" rowHeight="auto" [externalPaging]="true" [count]="giftTotalRows"
          [offset]="giftPaging.pageNumber - 1" [limit]="giftPaging.pageSize" (page)="pageChangeGift($event)">
          <ngx-datatable-column *ngFor="let col of columnMapping" prop="{{ col.ColumnName }}" name="{{ col.DisplayName }}" [canAutoResize]="col.AutoResize"
            [sortable]="col.Sortable">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span *ngIf="col.ColumnType == COLUMN_TYPE.STRING" class="vertical-center">{{ value }}</span>
              <span *ngIf="col.ColumnType == COLUMN_TYPE.SPECIAL" class="vertical-center">
                <a href="" (click)="openLink(value)">{{ value }}</a>
              </span>
              <span *ngIf="col.ColumnType == COLUMN_TYPE.NUMBER" class="vertical-center">{{ value | currencyFormat }}</span>
              <span *ngIf="col.ColumnType == COLUMN_TYPE.IMAGE" >
                  <img *ngFor="let img of value" [src]="img" width="50" height="50">
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Actions" prop="key" fixed [width]="160" [frozenRight]="true">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <grid-button className="text-success" tooltip="Redeem" (okEvent)="redeemGift(row)" [confirmTitle]="'Do want to redeem ' + row.points + ' points?'">
                <i nz-icon nzType="gift" confirm theme="outline"></i>
              </grid-button>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </ng-container>

  <!-- View redeem history -->
  <ng-container *ngIf="tabIndex === tabRedeemHistory">
    <div class="rt-panel-item">
      <div class="row align-items-center title-panel">
        <div class="col">
          <p>Redeem history</p>
        </div>
        <div class="col">
          <form class="search-form">
            <div class="search-el">
              <nz-range-picker name="range" [nzFormat]="dateFormat" [nzRanges]="dateRangeModel" (ngModelChange)="onChangeDateRangeRedeemHistory()"
                [(ngModel)]="dateRangeRedeemHistory"></nz-range-picker>
            </div>
            <div class="search-el">
              <button type="button" (click)="searchRedeemHistory()" class="btn btn-primary search-btn"><i class="ion-ios7-search-strong"></i></button>
            </div>
          </form>
        </div>
      </div>
      <div class="rt-panel-item-content">
        <ngx-datatable #myTable class="material grid-aluha" [rows]="history" [columnMode]="ColumnMode.force" [loadingIndicator]="isLoading" [headerHeight]="50"
          [footerHeight]="50" [virtualization]="false" [externalSorting]="false" [scrollbarH]="true" rowHeight="auto" [externalPaging]="true"
          [count]="redeemHistoryTotalRows" [offset]="redeemHistorySearchData.pageNumber - 1" [limit]="redeemHistorySearchData.pageSize"
          (page)="pageChangeRedeemHistory($event)">
          <ngx-datatable-column *ngFor="let col of columnMappingHistory" prop="{{ col.ColumnName }}" name="{{ col.DisplayName }}" [canAutoResize]="col.AutoResize"
            [sortable]="col.Sortable" [width]="col.Witdh">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span *ngIf="col.ColumnType == COLUMN_TYPE.STRING" >{{ value }}</span>
              <span *ngIf="col.ColumnType == COLUMN_TYPE.NUMBER" >{{ value }}</span>
              <span *ngIf="col.ColumnType == COLUMN_TYPE.ENUM" [innerHTML]="value | status: 11 | shortcodeToHtml | safeHtml" ></span>
              <span *ngIf="col.ColumnType == COLUMN_TYPE.DATE" >{{ value | date: 'dd/MM/yyyy' }}</span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </ng-container>

  <!-- View all history -->
  <ng-container *ngIf="false">
    <div class="rt-panel-item">
      <div class="row align-items-center title-panel">
        <div class="col">
          <p>All history</p>
        </div>
        <div class="col">
          <form class="search-form">
            <div class="search-el">
              <nz-range-picker name="range" [nzFormat]="dateFormat" [nzRanges]="dateRangeModel" (ngModelChange)="onChangeDateRangeAllHistory()"
                [(ngModel)]="dateRangeAllHistory"></nz-range-picker>
            </div>
            <div class="search-el">
              <button type="button" (click)="searchAllHistory()" class="btn btn-primary search-btn"><i class="ion-ios7-search-strong"></i></button>
            </div>
          </form>
        </div>
      </div>
      <div class="rt-panel-item-content">
        <ngx-datatable #myTable class="material grid-aluha" [rows]="history" [columnMode]="ColumnMode.force" [loadingIndicator]="isLoading" [headerHeight]="0"
          [footerHeight]="50" [virtualization]="false" [externalSorting]="false" [scrollbarH]="true" rowHeight="auto" [externalPaging]="true"
          [count]="allHistoryTotalRows" [offset]="allHistorySearchData.pageNumber - 1" [limit]="allHistorySearchData.pageSize" (page)="pageChangeAllHistory($event)">
          <ngx-datatable-column>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span> Formatted Value ~</span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </ng-container>
</nz-card>
