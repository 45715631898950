import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { ReviewFormProgress, ReviewPerformanceFormDetailUser } from '../../models/review/review-form.model';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class ReviewFormUserService extends BaseService {
  hostUrl = '/api/review-form-user';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  get(id: string): Promise<ReviewPerformanceFormDetailUser> {
    const url = `${this.hostUrl}?reviewPerformanceFormMemberId=${id}`;
    return this.httpClient.getObservable(url).toPromise();
  }
  getCompare(data: any): Promise<ReviewPerformanceFormDetailUser> {
    const url = `${this.hostUrl}/compare`;
    return this.httpClient.postObservable(url, data).toPromise();
  }
  getDiff(data: any): Promise<ReviewPerformanceFormDetailUser> {
    const url = `${this.hostUrl}/diff`;
    return this.httpClient.postObservable(url, data).toPromise();
  }
  updateInformationSection(data: any) {
    const url = `${this.hostUrl}/comment-section?noLoading=true`;
    return this.httpClient.putObservable(url, data).toPromise();
  }
  updateFormCommentAppraisers(data: any) {
    const url = `${this.hostUrl}/form-comment-apprairer?noLoading=true`;
    return this.httpClient.putObservable(url, data).toPromise();
  }
  updateFormCommentAppraisee(data: any) {
    const url = `${this.hostUrl}/form-comment-appraisee?noLoading=true`;
    return this.httpClient.putObservable(url, data).toPromise();
  }
  updateFormCommentAdditionals(data: any) {
    const url = `${this.hostUrl}/form-comment-additional?noLoading=true`;
    return this.httpClient.putObservable(url, data).toPromise();
  }
  updateRowGoalHistory(data: any) {
    const url = `${this.hostUrl}/form-row-goal-history?noLoading=true`;
    return this.httpClient.putObservable(url, data).toPromise();
  }
  updateFormProbation(data: any) {
    const url = `${this.hostUrl}/form-probation?noLoading=true`;
    return this.httpClient.putObservable(url, data).toPromise();
  }
  updateLevelMemeber(data: any) {
    const url = `${this.hostUrl}/form-level-member?noLoading=true`;
    return this.httpClient.putObservable(url, data).toPromise();
  }
  updateLevelAppraiser(data: any) {
    const url = `${this.hostUrl}/form-level-appraisser?noLoading=true`;
    return this.httpClient.putObservable(url, data).toPromise();
  }
  getMemberFormInfo(memberId: string) {
    const url = `${this.hostUrl}/info-by-id?memberId=${memberId}`;
    return this.httpClient.getObservable(url).toPromise();
  }

  updateAppraisers(member: any): Promise<void> {
    const url = `${this.hostUrl}/form-appraisers`;
    return this.httpClient.putObservable(url, member).toPromise();
  }

  getFormUserContext(): Promise<any> {
    const url = `${this.hostUrl}/context`;
    return this.httpClient.getObservable(url).toPromise();
  }

  getProgressColor(): Promise<any> {
    const url = `${this.hostUrl}/progress-color`;
    return this.httpClient.getObservable(url).toPromise();
  }
  getProgresForm(searchData: any): Promise<any> {
    const url = `${this.hostUrl}/data-progress-form`;
    return this.httpClient.getObservable(url, searchData).toPromise();
  }
  getCommentHistory(searchData: any): Promise<any> {
    const url = `${this.hostUrl}/comment-history`;
    return this.httpClient.getObservable(url, searchData).toPromise();
  }

  getProgress(searchData: any): Promise<ReviewFormProgress> {
    const url = `${this.hostUrl}/progress`;

    if (!searchData?.noLoading) {
      searchData.noLoading = true;
    }

    return this.httpClient.getObservable(url, searchData).toPromise();
  }
}
