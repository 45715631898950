import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Employee, EmployeeService, NewsFeedService } from '@intranet/lib/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { Constants, Toastr } from '@intranet/index';

@Component({
  selector: 'app-newcomer-template-modal',
  templateUrl: './newcomer-template-modal.component.html',
  styleUrls: ['./newcomer-template-modal.component.scss']
})
export class NewcomerTemplateModalComponent implements OnInit {
  @ViewChild('frm', { static: false }) public formGroup: NgForm;
  @Input() employee: Employee;
  activeModal: NgbActiveModal;
  isLoading = false;
  Constants = Constants;

  constructor(modalRef: NgbActiveModal, private employeeSvc: EmployeeService, private newsFeedSvc: NewsFeedService) {
    this.activeModal = modalRef;
  }

  ngOnInit() {
  }

  async submit() {
    if (!this.employee.deparments) {
      this.employee.deparments = this.employee.groupDepartments;
    }

    await this.newsFeedSvc.createNewcomerPost(this.employee, (resp: any) => {
      if (resp.id < 0) {
        Toastr.error(`Error: ${resp.message}`);
        return;
      }

      this.activeModal.close(resp);
    });
  }
}
