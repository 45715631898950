import { Pipe, PipeTransform } from '@angular/core';
import { SecurityHelper } from '../helpers';

@Pipe({
  name: 'checkRole',
  pure: true,
})
export class CheckRolePipe implements PipeTransform {
  transform(department: any, controller?: any, action?: any): any {
    if (controller && action && department) {
      const resule = this.checkPermission(controller, action, department);
      return resule;
    }
    return false;
  }
  private checkPermission(controller: string, action: string, department: any) {
    if (controller && action) {
      const result = SecurityHelper.checkPermission(controller, action, department);
      return result;
    }
    return true;
  }
}
