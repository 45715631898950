import { QuestionType } from '../data-access/models/survey-question.model';

export class Surveys {
  static readonly DEFAULT_REWARD_POINT = 50;
  static readonly QUESTIONTYPES = [
    {
      id: QuestionType.SingleChoice,
      name: 'Single choice',
    },
    {
      id: QuestionType.MultipleChoice,
      name: 'Multiple choice',
    },
    {
      id: QuestionType.Freetext,
      name: 'Free text',
    },
    {
      id: QuestionType.SingleChoiceWithText,
      name: 'Single choice with freetext',
    },
    {
      id: QuestionType.MultipleChoiceWithText,
      name: 'Multiple choice with freetext',
    },
  ];
}