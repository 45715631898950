import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { ReviewReminderSettings } from '../../models/review/review-reminder-settings.model';

@Injectable({
  providedIn: 'root',
})
export class ReviewPerformanceReminderSettingsService extends BaseService {
  hostUrl = '/api/review-reminder-settings';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  view(): Promise<ReviewReminderSettings> {
    return this.httpClient.getObservable(this.hostUrl).toPromise();
  }

  updateSettings(settings: ReviewReminderSettings): Promise<ReviewReminderSettings> {
    return this.httpClient.putObservable(this.hostUrl, settings).toPromise();
  }
}
