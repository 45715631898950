import { Component, Input, OnInit } from '@angular/core';
import { UrlConstant } from '@intranet/lib/config';
import { GroupUserWorkTimeDto, TableColumnItem, WidgetSearchDto } from '@intranet/lib/data-access';
import * as moment from 'moment';

@Component({
  selector: 'app-widget-attendance-member',
  templateUrl: './widget-attendance-member.component.html',
  styleUrls: ['./widget-attendance-member.component.scss']
})
export class WidgetAttendanceMemberComponent implements OnInit {
  private _searchData: WidgetSearchDto | null;
  private _data: GroupUserWorkTimeDto[] | null;
  public fromYear: number = new Date().getFullYear();
  public isShowPagination = false;
  public listOfColumns: TableColumnItem[] = [
    {
      name: 'Name',
      class: '',
      width: null,
      sortOrder: null,
      sortFn: (a: GroupUserWorkTimeDto, b: GroupUserWorkTimeDto) => a.fullName.localeCompare(b.fullName),
      sortDirections: ['ascend', 'descend'],
      listOfFilter: [],
      filterFn: null,
      filterMultiple: false
    },
    {
      name: 'Missing hours yearly',
      width: '200px',
      class: 'text-right',
      sortOrder: null,
      sortFn: (a: GroupUserWorkTimeDto, b: GroupUserWorkTimeDto) => a.totalMissingHoursYearly - b.totalMissingHoursYearly,
      sortDirections: ['ascend', 'descend'],
      listOfFilter: [],
      filterFn: null,
      filterMultiple: true
    },
    {
      name: 'Off-hours',
      width: '130px',
      class: 'text-right',
      sortOrder: null,
      sortFn: (a: GroupUserWorkTimeDto, b: GroupUserWorkTimeDto) => a.totalLeaveHours - b.totalLeaveHours,
      sortDirections: ['ascend', 'descend'],
      listOfFilter: [],
      filterFn: null,
      filterMultiple: true
    },
    {
      name: 'Difference (+/-)',
      width: '160px',
      class: 'text-right',
      sortOrder: 'ascend',
      sortDirections: ['ascend', 'descend'],
      sortFn: (a: GroupUserWorkTimeDto, b: GroupUserWorkTimeDto) => (a.hoursGap) - (b.hoursGap),
      listOfFilter: [],
      filterFn: null,
      filterMultiple: false
    }
  ];
  get searchData(): WidgetSearchDto {
    return this._searchData;
  }
  @Input() set searchData(value: WidgetSearchDto) {
    this._searchData = value;

    this.fromYear = new Date().getFullYear();
    if (value?.fromDate) {
      this.fromYear = moment(value.fromDate).year();
    }
  }

  get data(): GroupUserWorkTimeDto[] {
    return this._data;
  }
  @Input() set data(value: GroupUserWorkTimeDto[]) {
    this._data = value;
    if (value && value.length > 15) {
      this.isShowPagination = true;
    }
  }
  urlConstant = UrlConstant;


  loading = true;
  isDetail = false;
  constructor() { }

  ngOnInit() {
  }

  openInformation(item) {
    const url = this.urlConstant.PROFILE_PAGE + '?employeeId=' + item.employeeId;
    window.open(
      url,
      '_blank'
    );
  }
}
