import { Component, OnInit, Input } from '@angular/core';
import { BaseAdminComponent } from '@intranet/lib/components';
import { Loading, Bootbox } from '@intranet/index';
import { ActivityService } from '@intranet/lib/data-access';
import { Activity, ActivityTypeEnum } from '@intranet/lib/data-access';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { PubSubEvent, UrlConstant } from '@intranet/index';
import * as _ from 'lodash';

@Component({
  selector: 'app-newsfeed-recent-activities',
  templateUrl: './recent-activities.component.html',
  styleUrls: ['./recent-activities.component.scss'],
  styles: [],
})
export class RecentActivitiesComponent extends BaseAdminComponent implements OnInit {
  @Input() view: string;
  @Input() title = 'Recent Activities';
  @Input() pageSize = 5;
  @Input() canRemove: boolean;

  avatarSize = 32;
  isLoading: boolean;
  currentItems: number;
  hasNextPage: boolean;
  activities: Activity[];
  activityTypes: typeof ActivityTypeEnum = ActivityTypeEnum;

  constructor(private activityService: ActivityService, private router: Router, private activeRouter: ActivatedRoute, private pubsub: PubSubService) {
    super();
  }

  ngOnInit() {
    this.currentItems = 1;
    this.hasNextPage = false;
    this.activities = [];
    this.isLoading = false;

    this.pubsub.$sub(PubSubEvent.EVENTS.ON_CREATE_FEED).subscribe(feed => {
      this.getInitData();
    });

    this.getInitData();
  }

  getInitData() {
    this.isLoading = true;
    this.activityService.getAll(
      {
        noLoading: true,
        pageSize: this.pageSize,
        currentItems: this.currentItems,
      },
      (res: Activity[]) => {
        res.forEach(activity => {
          const existedActivities = this.activities.filter((item: Activity) => {
            return item.id === activity.id;
          });

          if (!existedActivities || existedActivities.length === 0) {
            this.activities.push(activity);
          }
        });

        this.currentItems = this.activities.length;

        this.hasNextPage = res && res.length > 0;
      },
      error => { },
      () => {
        this.isLoading = false;
      },
    );
  }

  navigateTo(activity: Activity): void {
    switch (activity.activityType) {
      case ActivityTypeEnum.POST:
      case ActivityTypeEnum.LIKE:
      case ActivityTypeEnum.COMMENT:
      case ActivityTypeEnum.POLL:
        this.router.navigate([UrlConstant.mapNewsFeed(UrlConstant.NewsFeedUrl.DETAIL), activity.extData.postId]);
        break;
      default:
        return null;
    }
  }

  deleteActivity(item: Activity): void {
    Bootbox.confirm().subscribe(res => {
      if (res) {
        this.isLoading = true;
        this.activityService.deleteById(
          item.id,
          res => {
            if (this.view === 'list') {
              this.currentItems--;
            } else {
              this.getInitData();
            }
            Loading.hide();
          },
          error => { },
          () => {
            this.isLoading = false;
          },
        );
      }
    });
  }

  loadMore() {
    this.getInitData();
  }
}
