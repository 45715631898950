import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/index';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { MyTeam, MyTeamMember } from '../models';
import { PagingResponse } from '../models/api-response.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class UserDeprtmentService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/userdepartment';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getEdit(params?: any, successCallback?: (params?: any) => void) {
    const url = 'get_by_id';
    return this.getByUrl(url, params, successCallback);
  }

  getMyTeams(): Observable<MyTeam[]> {
    return this.httpClient.getObservable(ApiEndpoint.UserDepartment.MyTeams()).pipe(pluck('data'));
  }

  getPagingMembers(requestOption: any): Observable<PagingResponse<MyTeamMember>> {
    return this.httpClient.getObservable(ApiEndpoint.UserDepartment.PagingMembers(), requestOption);
  }
}
