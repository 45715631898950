import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { LeaveRequest, LeaveRequestService, APPROVAL_STATUS, ModalDto } from '@intranet/lib/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Loading, Toastr, Constants } from '@intranet/index';
import { NgForm } from '@angular/forms';

class StatusApproval {
  id: string;
  status: number;
  approvalComment: string;
}

@Component({
  selector: 'app-reason-modal',
  templateUrl: './reason-modal.component.html',
})
export class ReasonModalComponent implements OnInit {
  model: ModalDto;
  activeModal: NgbActiveModal;
  action: any;
  @ViewChild('frm', { static: false }) public formGroup: NgForm;

  constructor(modalRef: NgbActiveModal) {
    this.activeModal = modalRef;
  }

  ngOnInit() {
    this.model.isLoading = false;
  }
  clickOk() {
    this.model.actionOk(this.model);
  }
  //#endregion private methods
}
