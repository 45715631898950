import { MediaTypeEnum } from '.';
import { BaseModel } from './base-model';

export class Policy extends BaseModel {
  policyId: string;
  title: string;
  content: string;
  order: number;
  policyDetails: PolicyDetail[];
}

export class PolicyDetail extends BaseModel {
  policyDetailId: string;
  policyId: string;
  benefitId: string | null;
  title: string;
  order: number;
  content: string;
  files: any;
}

export class PolicyMedia extends BaseModel {
  id?: string;
  newsFeedId?: string;
  name?: string;
  path?: string;
  mediaTypeId?: MediaTypeEnum;
}
