<ng-template *ngIf="layout == constants.LAYOUTS.ONE_COLUMN; then oneColumn"></ng-template>
<ng-template *ngIf="layout == constants.LAYOUTS.TWO_COLUMNS_RIGHT; then twoColumnsRight"></ng-template>
<ng-template *ngIf="layout == constants.LAYOUTS.TWO_COLUMNS_MAIN; then twoColumnsMain"></ng-template>

<ng-template #oneColumn>
  <div id="rt-container">
    <app-header-front [currentUser]="currentUser"></app-header-front>
    <main [class]="isExpaned ? 'expaned' : ''">
      <app-sidebar-left *ngIf="showSidebar" (sidebarLeftChanges)="onChangeSliderLeftActive($event)" [id]="sidebarId"
        [class]="isExpaned ? 'd-block' : ''">
      </app-sidebar-left>
      <div id="rt-content-container" class="rt-profile">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </main>
  </div>
</ng-template>

<ng-template #twoColumnsRight>
  <div id="rt-container">
    <app-header-front [currentUser]="currentUser"></app-header-front>
    <main [class]="isExpaned ? 'expaned' : ''">
      <app-sidebar-left *ngIf="showSidebar" (sidebarLeftChanges)="onChangeSliderLeftActive($event)" [id]="sidebarId"
        [class]="isExpaned ? 'd-block' : ''">
      </app-sidebar-left>
      <div id="rt-content-container-home">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
      <div id="rt-right-panel" [class]="showRightPanel ? 'd-block' : ''">
        <router-outlet name="right"></router-outlet>
      </div>
    </main>
  </div>
</ng-template>

<ng-template #twoColumnsMain>
  <div id="rt-container">
    <app-header-front [currentUser]="currentUser"></app-header-front>
    <main [class]="isExpaned ? 'expaned' : ''">
      <app-sidebar-left *ngIf="showSidebar" (sidebarLeftChanges)="onChangeSliderLeftActive($event)" [id]="sidebarId"
        [class]="isExpaned ? 'd-block' : ''">
      </app-sidebar-left>
      <div id="rt-content-container" class="rt-notification" [ngClass]="{ 'no-sidebar-style': !showSidebar }">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </main>
  </div>
</ng-template>
