<modal-header [title]="headerTemplate" [activeModal]="activeModal">
  <ng-template #headerTemplate>
    <h4 class="modal-title" *ngIf="!projectIterationId">Add New Sprint</h4>
    <h4 class="modal-title" *ngIf="projectIterationId">Update Sprint</h4>
  </ng-template>
</modal-header>
<form *ngIf="newIteration" class="project-iteration-info" (ngkSubmit)="onSubmit()" #frm="ngForm" [ngkForm]="true">
  <modal-body>
    <div class="p-sm">
      <div class="form-row">
        <div class="form-group col-xl-12">
          <label for="p-name" class="required">Name</label>
          <input class="form-control" type="text" name="p-name" [(ngModel)]="newIteration.name" required>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-xl-12">
          <label for="p-dueDate">From Date</label>
          <div>
            <nz-date-picker name="p-dueDate" [(ngModel)]="newIteration.fromDate"></nz-date-picker>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-xl-12">
          <label for="p-dueDate">To Date</label>
          <div>
            <nz-date-picker name="p-dueDate" [(ngModel)]="newIteration.toDate"></nz-date-picker>
          </div>
        </div>
      </div>
    </div>
  </modal-body>
  <modal-footer>
    <button *ngIf="projectIterationId" nz-popconfirm nzPopconfirmTitle="Are you sure delete this iteration?" nzPopconfirmPlacement="top" (nzOnConfirm)="deleteSprint()"
      nz-tooltip nzTooltipTitle="Delete" class="btn btn-danger text-danger mr5 fs14">
      <i nz-icon nzType="delete" theme="outline"></i> Delete
    </button>
    <button type="button" class="btn btn-white" (click)="closeModal()">Cancel</button>
    <create-update-button [nzLoading]="isLoading" [isUpdate]="projectIterationId"></create-update-button>
  </modal-footer>
</form>
