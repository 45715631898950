import { Component, OnInit } from '@angular/core';
import { Employee, EmployeeWorkAnniversary, EmployeeService, NewsFeed, NewsFeedTypeEnum, PublicityLevel, WallNewsFeedService, UserModel } from '@intranet/lib/data-access';
import { ModalService, UrlConstant, ModalSize } from '@intranet/index';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { UserListModalComponent } from '../user-list-modal/user-list-modal.component';

@Component({
  selector: 'app-newsfeed-work-anniversary',
  templateUrl: './work-anniversary.component.html',
  styles: [],
})
export class WorkAnniversaryComponent implements OnInit {
  public anniversaries: EmployeeWorkAnniversary[];
  isLoading: boolean;
  sent: boolean;
  urlConstant = UrlConstant;

  constructor(
    private modalService: ModalService,
    private employeeService: EmployeeService,
    private newsFeedService: WallNewsFeedService,
    private pubsub: PubSubService) { }

  ngOnInit() {
    this.getEmployeeWorkAnniversary();
  }

  getEmployeeWorkAnniversary() {
    const params = {
      noLoading: true,
    };
    this.isLoading = true;
    this.employeeService.getWorkAnniversary(
      params,
      (res: EmployeeWorkAnniversary[]) => {
        this.anniversaries = res;
      },
      error => { },
      () => {
        this.isLoading = false;
      },
    );
  }

  sendCelebrate(item: EmployeeWorkAnniversary) {
    if (item.userId) {
      const feed = new NewsFeed();
      feed.title = 'Happy Work Anniversary';
      feed.description = item.fullName;
      feed.targetUserId = item.userId;
      feed.newsFeedTypeId = NewsFeedTypeEnum.WORK_ANNIVERSARY;
      feed.publicityLevel = PublicityLevel.PUBLIC;
      feed.isShow = false;
      feed.beginDate = new Date();
      feed.allowComment = true;
      feed.allowLike = true;

      this.newsFeedService.sendCelebrateWorkAnniversary(feed, res => {
        if (res && res.id) {
          item.reactionCount++;
          if(!item.userCount){
            item.userCount = 0;
          }
          item.userCount++;
        }
      });
    }
  }

  onShowWishedItems(item: EmployeeWorkAnniversary) {
    if (item.userId) {
      this.employeeService.getWorkAnniversaryWihes({ userId: item.userId }, (items: UserModel[]) => {
        const modal = this.modalService.open(UserListModalComponent, {
          size: ModalSize.md,
        });
        modal.componentInstance.title = 'Today\'s Newcomer';
        modal.componentInstance.items = items;
      });

    }
  }
}
