import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { DateMonthSelectModel, DateMonthSelectSettingModel, WidgetAttendanceHourDto, WidgetSearchDto, WidgetService } from '@intranet/lib/data-access';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import { GridsterItem } from 'angular-gridster2';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-widget-attendance-hour',
  templateUrl: './widget-attendance-hour.component.html',
  styleUrls: ['./widget-attendance-hour.component.scss']
})
export class WidgetAttendanceHourComponent implements OnInit, OnDestroy {

  loading = false;
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;
  @Input() widgetLoaded: EventEmitter<any>;
  resizeSub: Subscription;
  searchData: WidgetSearchDto = new WidgetSearchDto();
  data: WidgetAttendanceHourDto = new WidgetAttendanceHourDto();
  dataSelect: any;
  dateSelect: DateMonthSelectModel = new DateMonthSelectModel();
  dateSelectSetting: DateMonthSelectSettingModel = new DateMonthSelectSettingModel();
  view = [400, 250];

  constructor(private _widgetService: WidgetService) {
    this.view = innerWidth < 640 ? [innerWidth / 1.1, 250] : [600, 250];
    this.loadDefaultDate();
    this.dateSelectSetting.className = 'widget-attendance-date';
  }

  onSelect(event) {
    const series = event.series;
    if (series && series.includes('-')) {
      const dataSplit = series.split('-');
      // tslint:disable-next-line: radix
      this.dateSelect.fromMonth = this.dateSelect.toMonth = parseInt(dataSplit[0]);
      // tslint:disable-next-line: radix
      this.dateSelect.fromYear = this.dateSelect.toYear = parseInt(dataSplit[1]);
      const fromDate = new Date(this.dateSelect.fromYear, this.dateSelect.fromMonth - 1, 1, 0, 0, 0, 0);
      this.searchData.fromDate = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate() + ' 00:00:00';
      this.searchData.toDate = this.searchData.fromDate;
      this.loadData();
    }
  }

  ngOnInit() {
    this.resizeSub =
      this.resizeEvent &&
      this.resizeEvent.subscribe(widget => {
        if (widget === this.widget) {
          setTimeout(() => {
            this.getPosition();
          }, 1000);
        }
      });
    setTimeout(() => {
      this.getPosition();
    }, 1000);
    this.loadData();
  }

  getPosition() {
  }

  async loadData() {
    if (this.widget) {
      this.searchData.isDisplayDataManage = this.widget.data.isDisplayDataManage;
    }
    this.loading = true;
    try {
      this.data = await this._widgetService.getWidgetAttendanceHour(this.searchData);
      if (innerWidth < 640) {
        this.data.widgetAttendanceDetails.legendPosition = 'below';
      }
      this.widgetLoaded?.emit(this.widget);
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
    finally {
      this.loading = false;
    }
  }
  modelChanged(event) {
    this.searchData.userIds = event;
    this.dataSelect = null;
    this.loadData();
  }
  changeDate(model) {
    const fromDate = new Date(model.fromYear, model.fromMonth - 1, 1, 0, 0, 0, 0);
    const lastDayOfMonth = new Date(model.toYear, model.toMonth, 0);
    const toDate = new Date(model.toYear, model.toMonth - 1, lastDayOfMonth.getDate(), 0, 0, 0, 0);
    this.searchData.fromDate = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate() + ' 00:00:00';
    this.searchData.toDate = toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate() + ' 00:00:00';
    this.loadData();
  }
  loadDefaultDate() {
    const date = new Date();
    this.dateSelect.fromMonth = date.getMonth() + 1;
    this.dateSelect.fromYear = date.getFullYear();
    this.dateSelect.toMonth = date.getMonth() + 1;
    this.dateSelect.toYear = date.getFullYear();
  }
  ngOnDestroy(): void {
    if (this.resizeSub) {
      this.resizeSub.unsubscribe();
    }
  }
}
