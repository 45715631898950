<!-- <div *ngIf="data" class="custom-scrollbar">
  <div (click)="openInformation(item)" *ngFor="let item of data" class="row mt-1 employee-information">
    <div class="col-2">
      <app-avatar [size]="70" [(avatarSource)]="item.avatarUrl" [(avatarText)]="item.fullName"> </app-avatar>
    </div>
    <div class="col-10">
      <div class="row mt-2">
        <div class="col-9 font-12">
          <span  class="font-weight-bold">{{item.fullName}}</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-9 font-weight-bold">
          {{item.positionName}}
        </div>
      </div>
    </div>
  </div>
  <nz-empty *ngIf="data && data.length == 0"></nz-empty>
</div> -->
<app-display-member-template [memberData]="data"></app-display-member-template>
