<ng-container *ngIf="widget">
  <nz-card [nzBordered]="false" [nzTitle]="widget.data.title" [nzLoading]="loading" [nzExtra]="this.searchData.isDisplayDataManage ?  extraTemplate : null">
    <div class="row" *ngIf="data.widgetAttendanceDetails">
      <ngx-charts-bar-vertical-stacked [scheme]="data.widgetAttendanceDetails.colorScheme" [results]="data.widgetAttendanceDetails.chartDatas"
        [gradient]="data.widgetAttendanceDetails.gradient" [xAxis]="data.widgetAttendanceDetails.xAxis" [yAxis]="data.widgetAttendanceDetails.yAxis"
        [legend]="data.widgetAttendanceDetails.showLegend" [legendPosition]="data.widgetAttendanceDetails.legendPosition"
        [showXAxisLabel]="data.widgetAttendanceDetails.showXAxisLabel" [showYAxisLabel]="data.widgetAttendanceDetails.showYAxisLabel"
        [xAxisLabel]="data.widgetAttendanceDetails.xAxisLabel" [yAxisLabel]="data.widgetAttendanceDetails.yAxisLabel"
        [animations]="data.widgetAttendanceDetails.animations" (select)="onSelect($event)" [view]="view">
      </ngx-charts-bar-vertical-stacked>
    </div>
  </nz-card>
</ng-container>
<ng-template #extraTemplate>
  <ng-container *ngIf="data && data.widgetAttendanceDetails">
    <div class="search-widget-right">
      <app-search-date-month class="widget-attendance-date" (change)="changeDate($event)" [setting]="dateSelectSetting" [model]="dateSelect">
      </app-search-date-month>

      <nz-select class="widget-dashboard-select ml-2" (ngModelChange)="modelChanged($event)" nzShowSearch nzAllowClear nzPlaceHolder="Select a users"
        [(ngModel)]="searchData.userIds">
        <nz-option *ngFor="let option of data.users" [nzLabel]="option.fullName" [nzValue]="option.id"></nz-option>
      </nz-select>

    </div>
  </ng-container>
</ng-template>
