<div id="rt-container">
  <app-header-front [currentUser]="currentUser" [isDisplaySearchControl]="false"></app-header-front>
  <main>
    <!-- <app-sidebar-left (sidebarLeftChanges)="onChangeSliderLeftActive($event)" id="rt-left-panel" [class]="isExpaned ? 'd-block' : ''">
    </app-sidebar-left> -->
    <div id="rt-content-container" class="rt-profile" style="width: 100%; margin-left: 0px;">
      <!-- <app-banner-cover [currentUser]="currentUser"></app-banner-cover>
      <app-front-avatar [currentUser]="currentUser"></app-front-avatar>
      <app-front-tab-profile [currentUser]="currentUser"></app-front-tab-profile> -->
      <app-front-header-profile [currentUser]="currentUser"></app-front-header-profile>
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </main>
</div>
