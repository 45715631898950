export enum NewsFeedTypeEnum {
  WALL = 1,
  BIRTHDAY = 2,
  POLL = 3,
  POLL_MULTIPLE_QUESTION = 4,
  ANNOUNCEMENT = 5,
  STICKY = 6,
  TICKET = 7,
  WORK_ANNIVERSARY = 8
}


export enum NewsfeedStatusEnum {
  New = 1,
  Approved = 2,
  Rejected = 3,
  Closed = 4
}