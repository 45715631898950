import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WidgetAssetDto, WidgetSearchDto, WidgetService } from '@intranet/lib/data-access';
import { GridsterItem } from 'angular-gridster2';
import { Subscription } from 'rxjs';
import { UrlConstant } from '@intranet/index';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';

@Component({
  selector: 'app-widget-asset',
  templateUrl: './widget-asset.component.html',
  styleUrls: ['./widget-asset.component.scss']
})
export class WidgetAssetComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;
  @Input() widgetLoaded: EventEmitter<any>;
  resizeSub: Subscription;
  searchData: WidgetSearchDto = new WidgetSearchDto();
  data: WidgetAssetDto = new WidgetAssetDto();
  loading = false;
  urlConstant = UrlConstant;

  constructor(private _widgetService: WidgetService
    , private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.searchData.employeeId = params.employeeId;
      this.loadData();
    });
    this.resizeSub =
      this.resizeEvent &&
      this.resizeEvent.subscribe(widget => {
        if (widget === this.widget) {
          setTimeout(() => {
            this.getPosition();
          }, 1000);
        }
      });
    setTimeout(() => {
      this.getPosition();
    }, 1000);

  }
  onResizePie(event) {
    this.getPosition();
  }

  getPosition() {
  }

  async loadData() {
    if (this.widget) {
      this.searchData.isDisplayDataManage = this.widget.data.isDisplayDataManage;
    }
    this.loading = true;
    try {
      this.data = await this._widgetService.getWidgetAsset(this.searchData);
      this.widgetLoaded?.emit(this.widget);
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
    finally {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    if (this.resizeSub) {
      this.resizeSub.unsubscribe();
    }
  }

  modelDepartmentChanged(event) {
    this.searchData.departmentIds = event;
    this.loadData();
  }
  modelChanged(event) {
    this.searchData.userIds = event;
    this.loadData();
  }
}
