import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modal-body',
  templateUrl: './modal-body.component.html',
  styles: [],
})
export class ModalBodyComponent implements OnInit {
  @Input() className = '';
  constructor() { }

  ngOnInit() { }
}
