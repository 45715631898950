import {
  ColumnTemplateTypeEnum,
  ReviewFormAppraiserStatusEnum,
  ReviewFormAppraiserStatusFormEnum,
  ReviewFormMemberStatusEnum,
  ReviewFormMemberStatusFormEnum,
  ReviewFormStatusEnum,
  ReviewTemplateStatusEnum,
  ReviewTemplateTypeEnum,
} from '../data-access';
import { AnnualReviewDisplayType } from '../data-access/models/enums/annual-review-display-type.enum';
import { ReviewSectionType } from '../data-access/models/enums/review-section-type.enum';
import { Constants } from './constants';

const TextColor = Constants.TextColor;
const ColorTextDiff = Constants.ColorTextDiff;
export const ReviewConstants = {
  ReviewInfoDetailAutoCloseWhenOverHeightInPercent: 50,
  GrabbingCursorStyle: 'grabbing',
  ColumnTemplate: {
    DefaultColumnWidth: 80,
    DefaultColumnWidthStyle: true,
  },

  HideColumnInEditModeType: [ColumnTemplateTypeEnum.Note, ColumnTemplateTypeEnum.Rating],

  ReviewColumnItemDisplayTable: {
    scroll: { x: '700px' },
    grabbingCol: {
      size: '15px',
      stick: true,
    },
    actionCol: {
      size: '20px',
      stick: true,
    },
    showColSize: false,
  },

  DefaultMemberContextColor: TextColor.Muted,

  Tooltip: {
    ColumnTemplate: {
      IsRequired: 'Enable to require appraisers and appraisees inputs before submit the form',
      FillData:
        'Enable to display only in reviewing session (for rating and comment fields), disable to display input when setting up data (for criteria and proportion fields)',
    },
    Section: {
      General: 'Enable to require HR inputs to fill criteria, disable to require appraiser inputs',
      Overall: 'Generate an overall section and calculate scores of its children sections',
      TypeNormal: 'Normal template type is for HR and appraiser to fill criteria',
      TypeGoal:
        'Allow appraisee adding and filling rows, this typically goes along with column template "Goal", enabled "General" and disabled "Overall"',
    },
    Setting: {
      RemindAnnual: 'Notify HR a number of days before annual review period and auto create annual review forms',
      RemindProbation:
        'If there are probation employees who are about to end probation period, notify a number of days before their end of probation day and auto create probation review forms if there are at one approved probation template',
      RemindGoal: 'Remind employees about their goals once in a period.',
      ReviewableWorkingDay:
        'Only employees who have enough working days can have performance appraisal (probation period included).',
      TokenExpirationTime: 'Expiration time for generated tokens and PDF files',
      AddRemoveReviewMonth:
        'Set up review performance months. Add button appears when all month fields are all filled. Remove button appears when there are more than one review month.',
      AddReviewMonth: 'Add new review month.',
      RemoveReviewMonth: 'Remove review month.',
    },
    AddAppraiserModal: {
      MainAppraiser: 'Selected main appraisers can add other appraisers',
      RatingPermission:
        'Check to allow selected appraisers to rate scores. Uncheck to restrict selected appraisers to only view, choose criteria and complete form',
    },
    UserOverall: {
      /* tslint:disable-next-line quotemark */
      ViewHistoryComment: "View comments in current user's previous review performance form.",
    },
  },

  SectionTypeColor: {
    [ReviewSectionType.Goal]: TextColor.Warning,
  },

  TemplateType: {
    Display: {
      [ReviewTemplateTypeEnum.Annual]: { name: 'Annual', color: TextColor.Success },
      [ReviewTemplateTypeEnum.Probation]: { name: 'Probation', color: TextColor.Info },
      [ReviewTemplateTypeEnum.Exceptional]: { name: 'Exceptional', color: TextColor.Danger },
    },
    Array: [
      { id: ReviewTemplateTypeEnum.Annual, name: 'Annual' },
      { id: ReviewTemplateTypeEnum.Probation, name: 'Probation' },
      { id: ReviewTemplateTypeEnum.Exceptional, name: 'Exceptional' },
    ],
  },

  TemplateStatus: {
    Display: {
      [ReviewTemplateStatusEnum.New]: {
        name: 'New',
        color: TextColor.Info,
      },
      [ReviewTemplateStatusEnum.WaitingForApproval]: {
        name: 'Waiting for approval',
        color: TextColor.Warning,
      },
      [ReviewTemplateStatusEnum.Approved]: {
        name: 'Approved',
        color: TextColor.Success,
      },
      [ReviewTemplateStatusEnum.Rejected]: {
        name: 'Rejected',
        color: TextColor.Danger,
      },
    },
    Array: [
      { id: ReviewTemplateStatusEnum.New, name: 'New' },
      { id: ReviewTemplateStatusEnum.WaitingForApproval, name: 'Waiting for approval' },
      { id: ReviewTemplateStatusEnum.Approved, name: 'Approved' },
      { id: ReviewTemplateStatusEnum.Rejected, name: 'Rejected' },
    ],
  },

  SectionType: {
    Display: {
      [ReviewSectionType.Normal]: 'Normal',
      [ReviewSectionType.Goal]: 'Goal',
    },
    Array: [
      { id: ReviewSectionType.Normal, name: 'Normal' },
      { id: ReviewSectionType.Goal, name: 'Goal' },
    ],
  },

  FormStatus: {
    Display: {
      [ReviewFormStatusEnum.New]: {
        name: 'New',
        color: TextColor.Info,
      },
      [ReviewFormStatusEnum.Reviewing]: {
        name: 'Reviewing',
        color: TextColor.Success,
      },
      [ReviewFormStatusEnum.Completed]: {
        name: 'Completed',
        color: TextColor.Danger,
      },
    },
    Array: [
      { id: ReviewFormStatusEnum.New, name: 'New' },
      { id: ReviewFormStatusEnum.Reviewing, name: 'Reviewing' },
      { id: ReviewFormStatusEnum.Completed, name: 'Completed' },
    ],
  },

  FormAppraiser: {
    Status: {
      Display: {
        [ReviewFormAppraiserStatusEnum.New]: {
          name: 'New',
          color: TextColor.Info,
        },
        [ReviewFormAppraiserStatusEnum.Submitted]: {
          name: 'Submitted',
          color: TextColor.Warning,
        },
        [ReviewFormAppraiserStatusEnum.Completed]: {
          name: 'Completed',
          color: TextColor.Success,
        },
        [ReviewFormAppraiserStatusEnum.Rejected]: {
          name: 'Rejected',
          color: TextColor.Danger,
        },
      },
    },
    StatusForm: {
      Display: {
        [ReviewFormAppraiserStatusFormEnum.New]: {
          name: 'New',
          color: TextColor.Info,
        },
        [ReviewFormAppraiserStatusFormEnum.Verifying]: {
          name: 'Verifying',
          color: TextColor.Warning,
        },
        [ReviewFormAppraiserStatusFormEnum.Verified]: {
          name: 'Verified',
          color: TextColor.Success,
        },
      },
    },
  },

  FormMember: {
    Status: {
      Display: {
        [ReviewFormMemberStatusEnum.New]: {
          name: 'New',
          color: TextColor.Info,
        },
        [ReviewFormMemberStatusEnum.Submitted]: {
          name: 'Submitted',
          color: TextColor.Warning,
        },
        [ReviewFormMemberStatusEnum.Completed]: {
          name: 'Completed',
          color: TextColor.Success,
        },
        [ReviewFormMemberStatusEnum.Rejected]: {
          name: 'Rejected',
          color: TextColor.Danger,
        },
      },
    },
    StatusForm: {
      Display: {
        [ReviewFormMemberStatusFormEnum.New]: {
          name: 'New',
          color: TextColor.Muted,
        },
        [ReviewFormMemberStatusFormEnum.Verifying]: {
          name: 'Verifying',
          color: TextColor.Info,
        },
        [ReviewFormMemberStatusFormEnum.Verified]: {
          name: 'Verified',
          color: TextColor.Success,
        },
        [ReviewFormMemberStatusFormEnum.Finalizing]: {
          name: 'Reviewing',
          color: TextColor.Warning,
        },
        [ReviewFormMemberStatusFormEnum.Completed]: {
          name: 'Reviewed',
          color: TextColor.GreenSuccess,
        },
      },
      Array: [
        { id: ReviewFormMemberStatusFormEnum.New, name: 'New', color: TextColor.Muted },
        { id: ReviewFormMemberStatusFormEnum.Verifying, name: 'Verifying', color: TextColor.Info },
        { id: ReviewFormMemberStatusFormEnum.Verified, name: 'Verified', color: TextColor.Success },
        { id: ReviewFormMemberStatusFormEnum.Finalizing, name: 'Reviewing', color: TextColor.Warning },
        { id: ReviewFormMemberStatusFormEnum.Completed, name: 'Reviewed', color: TextColor.GreenSuccess },
      ],
    },
  },

  DisplayType: {
    Display: {
      [AnnualReviewDisplayType.Vertical]: 'Vertical',
      [AnnualReviewDisplayType.Horizontal]: 'Horizontal',
    },
    Array: [
      { id: AnnualReviewDisplayType.Vertical, name: 'Vertical' },
      { id: AnnualReviewDisplayType.Horizontal, name: 'Horizontal' },
    ],
  },

  DiffColor: {
    Array: [
      { text: 'Normal', color: ColorTextDiff.Normal },
      { text: 'New', color: ColorTextDiff.New },
      { text: 'Edit', color: ColorTextDiff.Edit },
    ],
  },

  SkipButtonColor: {
    Verify: TextColor.Info,
    Submit: TextColor.Warning,
    Complete: TextColor.GreenSuccess,
  },

  ReviewDetailPageUrl: '/review-performances/detail',
  ReviewDetailLevelPageUrl: '/review-performances/level',
  ReviewDetailPageClientUrl: '/client/review-forms',
  ReviewFormAdminPageUrl: '/admin/annual-reviews/forms',
};
