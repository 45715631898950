<ng-container *ngIf="widget">
  <nz-card [nzBordered]="false">
    <div class="widget-header">
      <div class="widget-title">
        {{widget.data.title}}
      </div>
      <div class="widget-control">
        <a class="view-all" [routerLink]="[urlConstant.mapUser(urlConstant.UserUrl.CALENDAR)]">View all</a>
      </div>
      
    </div>
    <app-newsfeed-events-calendar [displayType]="calendarDisplayTypeEnum.WIDGET" (loaded)="widgetLoaded?.emit(widget)"></app-newsfeed-events-calendar>
  </nz-card>
</ng-container>