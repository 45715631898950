import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { slideInAnimation } from '@intranet/lib/directives/animation';
import { Subscription } from 'rxjs';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';
import { MenuTypeEnum } from '../../../data-access/models/enums/menu-type.enum';

@Component({
  selector: 'app-client-layout',
  templateUrl: './client-layout.component.html',
  animations: [slideInAnimation],
  styleUrls: ['./client-layout.component.scss']
})
export class ClientLayoutComponent extends BaseLayoutComponent implements OnInit, OnDestroy {
  currentUser = this.currentUser;
  year = new Date().getFullYear();
  systemName: string;
  isExpaned = false;
  isRightExpanded = false;
  showLeftPanel = false;
  showRightPanel = false;
  subscriptions: Subscription[] = [];

  MenuTypeEnum = MenuTypeEnum;

  constructor() {
    super();
    this.systemName = AppConfig.settings.App_Name;
    this.isExpaned = localStorage.getItem('isExpanedFront') ? true : false;
    this.isRightExpanded = localStorage.getItem('isRightExpanded') ? true : false;
  }

  ngOnInit() {

  }

  onChangeSliderLeftActive(event) {
    this.isExpaned = event;
  }

  toggleLeftPanel(event) {
    this.showLeftPanel = event;
  }

  toggleRightPanel(event) {
    this.showRightPanel = event;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
