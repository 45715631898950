import { Component, OnInit, Input } from '@angular/core';
import { Constants, ModalService } from '@intranet/index';
import { FileExtensionType, InternalUploadFileResponseModel, WallNewsFeedService } from '@intranet/lib/data-access';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize, VideoItem } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-news-feed-images',
  templateUrl: './news-feed-images.component.html',
  styleUrls: ['./news-feed-images.component.scss']
})
export class NewsFeedImagesComponent implements OnInit {
  @Input() images: InternalUploadFileResponseModel[];
  galleryItems: GalleryItem[];
  galleryId: string;
  // masonryItems: IMasonryGalleryImage[];
  FileExtensionType = FileExtensionType;
  Constants = Constants;
  filesToDisplay: InternalUploadFileResponseModel[];

  maxImageCount = 4;

  constructor(private wallNewsfeedService: WallNewsFeedService, private modalService: ModalService,
    public gallery: Gallery, public lightbox: Lightbox) { }

  ngOnInit() {
    this.images.forEach(item => {
      item.url = this.wallNewsfeedService.getFileUrl(item.fileId);

      if (item.fileType === FileExtensionType.VIDEO) {
        item.mimeType = Constants.MimeTypes[item.fileExt];
        item.thumbUrl = Constants.PlayImagePath;
      }
    });

    this.filesToDisplay = this.images.slice(0, this.maxImageCount);

    this.galleryItems = this.images.map(item => {
      if (item.fileType === FileExtensionType.VIDEO) {
        return new VideoItem({
          src: [
            { url: item.url, type: item.mimeType }
          ] as any,
          thumb: item.thumbUrl,
        });
      }

      return new ImageItem({ src: item.url, thumb: item.url });
    });

    // Get a lightbox gallery ref
    const guid = uuidv4();
    this.galleryId = 'box' + guid;
    const lightboxRef = this.gallery.ref(this.galleryId);

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Top
    });

    // Load items into the lightbox gallery ref
    lightboxRef.load(this.galleryItems);
  }

  // onClickImage($event) {
  //   const index = this.images.findIndex(item => item.url === $event.imageUrl) || 0;
  //   this.lightbox.open(index, this.galleryId);
  // }

  onClickImage(imageUrl, event: Event = null) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const index = this.images.findIndex(item => item.url === imageUrl) || 0;
    this.lightbox.open(index, this.galleryId);
  }
}
