<div class="comment-list-container">
  <ul class="comment-list-body">
    <li>
      <app-news-feed-comment-control [feed]="feed"></app-news-feed-comment-control>
    </li>

    <li class="comment-item view-more-comments">
      <ng-container *ngIf="displayViewMoreComment && comments?.length > 0 && comments[0].totalCount > comments.length">
        <span *ngIf="!isLoading else loadingTemplate" (click)="onShowMoreComments()">
          View previous comments...
        </span>
      </ng-container>
    </li>

    <li class="comment-item" *ngFor="let item of comments; let i = index">

      <app-news-feed-comment-item [comment]="item" (replyComment)="onReply(item, replyBox)">
      </app-news-feed-comment-item>

      <ul>
        <li class="view-more-comments reply-comments" *ngIf="item && item?.children?.length > 0 && item.children[0].totalCount > item.children.length">
          <span *ngIf="!isReplyLoading[item.id] else loadingTemplate" (click)="onShowMoreReplies(item)">
            View {{ item.children[0].totalCount - item.children.length }} previous 
            {{ item.children[0].totalCount - item.children.length > 1 ? 'replies' : 'reply' }}...
          </span>
        </li>

        <li *ngFor="let child of item.children; let ic = index">
          <app-news-feed-comment-item [comment]="child" (replyComment)="onReply(child, replyBox, item)">
          </app-news-feed-comment-item>
        </li>

        <li [ngClass]="commentReplyBoxId !== item.id ? 'd-none' : ''">
          <app-news-feed-comment-control [feed]="feed" [parent]="item" #replyBox (success)="successReplyBox()">
          </app-news-feed-comment-control>
        </li>

        <div [id]="'reply-' + item.id"></div>
      </ul>

    </li>
  </ul>
</div>
  

<ng-template #loadingTemplate>
  <div class="loading">
    <nz-spin nzSimple></nz-spin>
  </div>
</ng-template>