import { Constants } from '@intranet/lib/config';
import { BaseModel } from './base-model';
import { StatusModel } from './common';
import { LeaveRequestExceptionStatus } from './enums/leave-request.enum';
import { LeaveTransfer } from './leave-transfer';

export class LeaveType {
  id?: string;
  name?: string;
  leaveRule: number;
  limitDay?: number;
  description?: string;
  halfDay?: string;
  applyAll?: boolean;
  useForOneTime?: boolean;
  hasSocialInsurance?: boolean;
  lastDateForUse?: Date;
  tagOptions?: string;
  tagOptionData?: string[];
  permissionApproveId?: string;
  emailsCc?: string[];
  isAllowProbationTime: boolean;
  isAllowMaternityTime: boolean;
  constructor() {
    this.limitDay = 0;
    this.emailsCc = [];
    this.isAllowProbationTime = false;
    this.isAllowMaternityTime = false;
  }
}

export enum LEAVE_ACTION {
  NONE = 1,
  REDUCED_LEAVE_DATE = 2,
  REDUCED_SALARY = 3,
  LEGAL_ENTITLED_LEAVE = 4,
  SICK_LEAVE = 6,
  'Company Sponsors' = LEAVE_ACTION.NONE,
  'Taken Annual Leave' = LEAVE_ACTION.REDUCED_LEAVE_DATE,
  'Unpaid Leave' = LEAVE_ACTION.REDUCED_SALARY,
  'Legal Entitled Leave' = LEAVE_ACTION.LEGAL_ENTITLED_LEAVE,
}

export class AnnualLeave {
  id: string;
  employeeId: string;
  employeeName: string;
  firstName: string;
  lastName: string;
  avaImageUrl: string;
  year: number;
  totalDay: number;
  usedDay: number;
  remainingDay: number;
  unpaidDay: number;
  unpaidDaySocialInsurance: number;
  legalEntitledDay: number;
  prevYearTranferDay: number;
  isRemovedUnusedDay: boolean;
  tranferDay: number;
  sponsorDay: number;
  sellDay: number;
  descrition?: string;
  createdDate: Date;
  updatedDate?: Date;
  deletedDate?: Date;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  totalCount: number;
  sponsorDayUsed: number;

  totalAnnualLeaves: number;
  sponsoredDayDisplay: number;
  usedAnnualLeaves: number;
  totalUnpaid: number;
  sickLeave: number;
  sickLeaveUsed: number;
  sickLeaveRemainer: number;
}

export class LeaveRequest extends BaseModel {
  id: string;
  leaveTypeId: string;
  leaveType: LeaveType;
  employeeId: string;
  employeeName: string;
  fromDate: Date;
  toDate: Date;
  halfDay: StatusModel;
  reason: string;
  status: number;
  approvedBy: string;
  approvedDate: Date;
  comment: string;
  approvalComment: string;
  leaveDate: number;
  totalCount: number;
  totalDate: number;
  totalTime: number;
  statusName: string;
  isShowDelete?: boolean;
  isPermissionApporveAdmin?: boolean;
  leaveRequestExceptionStatus: LeaveRequestExceptionStatus;
  leaveTransferId?: string;
  leaveTransfer: LeaveTransfer;
  constructor() {
    super();
    this.fromDate = new Date();
    this.toDate = new Date();
    this.halfDay = Constants.HALF_PART_LEAVE[0];
    // this.toDate.setDate(this.fromDate.getDate() + 1);
  }
}

export class LeaveApprovalGroup {
  ids: string[];
  status: number;
  approvalComment: string;
}

export enum APPROVAL_STATUS {
  PENDING = 0,
  REJECTED = 1,
  APPROVED = 2,
  RESTORE = 4,
  'Pending' = APPROVAL_STATUS.PENDING,
  'Rejected' = APPROVAL_STATUS.REJECTED,
  'Approved' = APPROVAL_STATUS.APPROVED,
}
