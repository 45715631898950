import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  BlankLayoutComponent,
  AdminLayoutComponent,
  FrontProfileLayoutComponent,
  FrontNewsFeedLayoutComponent
} from 'projects/intranet/hrm-lib/lib/layouts';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotPermissionComponent } from './not-permission/not-permission.component';
import { AccessSiteService } from '@intranet/lib/guards/access-site.service';
import {
  LoginCheckerService,
  AuthenCheckerAdminService,
  AuthenCheckerNewFeedsService,
  AuthenCheckerUserService
} from '@intranet/lib/guards';
import { MessagePageComponent, NotificationPageComponent, OrgChartPageComponent, RedeemGiftsComponent, SearchPageComponent } from 'projects/intranet/front/pages';
import { Constants, UrlConstant } from '@intranet/lib/config';
import { FrontGroupLayoutComponent } from '../../projects/intranet/hrm-lib/lib/layouts/layout/front-layout/front-group-layout/front-group-layout.component';
import { FrontPolicyLayoutComponent } from '../../projects/intranet/hrm-lib/lib/layouts/layout/front-layout/front-policy-layout/front-policy-layout.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { ReviewFormMemberClientComponent } from './review-form-member-client/review-form-member-client.component';
import { LoginLayoutComponent } from '@intranet/lib/layouts/layout/login-layout/login-layout.component';
import { ClientLayoutComponent } from '@intranet/lib/layouts/layout/client-layout/client-layout.component';
import { ClientGuardService } from '@intranet/lib/guards/client-guard.service';
import { CompensationComponent } from '@intrafront/pages/compensation/compensation.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    data: { css: 'frontTheme' },
    component: FrontNewsFeedLayoutComponent,
    canActivateChild: [AccessSiteService],
    children: [
      {
        path: UrlConstant.NewsFeedUrl.SEARCH,
        component: SearchPageComponent,
        data: { title: 'Search', layout: Constants.LAYOUTS.TWO_COLUMNS_MAIN },
        canActivate: [AuthenCheckerUserService]
      },
      {
        path: UrlConstant.NewsFeedUrl.MESSAGE,
        component: MessagePageComponent,
        data: { title: 'Messages', layout: Constants.LAYOUTS.TWO_COLUMNS_MAIN },
        canActivate: [AuthenCheckerUserService]
      },
      {
        path: UrlConstant.NewsFeedUrl.NOTIFICATION,
        component: NotificationPageComponent,
        data: { title: 'Notifications', layout: Constants.LAYOUTS.TWO_COLUMNS_MAIN },
        canActivate: [AuthenCheckerUserService]
      },
      {
        path: UrlConstant.UserUrl.COMPENSATION_HOUR,
        component: CompensationComponent,
        data: {
          title: 'Register Compensation',
          layout: Constants.LAYOUTS.TWO_COLUMNS_MAIN,
          isPermission: true
        },
        canActivate: [AuthenCheckerUserService]
      },
      {
        path: UrlConstant.NewsFeedUrl.REDEEM_GIFTS,
        component: RedeemGiftsComponent,
        data: {
          title: 'Redeem Points',
          layout: Constants.LAYOUTS.TWO_COLUMNS_MAIN,
          isPermission: true
        },
        canActivate: [AuthenCheckerUserService]
      },
      {
        path: 'orgchart',
        data: { title: 'Org chart', layout: Constants.LAYOUTS.TWO_COLUMNS_MAIN },
        component: OrgChartPageComponent,
        canActivate: [AuthenCheckerNewFeedsService],
      },
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    canActivate: [LoginCheckerService],
    data: { animation: 'LoginComponent', css: 'frontTheme' },
    children: [
      {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Login' }
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: { title: 'Forgot Password' }
      },
      {
        path: 'update-password/:id',
        component: UpdatePasswordComponent,
        data: { title: 'Update Password' },
      }
    ]
  },
  {
    path: 'dashboard',
    data: { css: 'frontTheme' },
    component: FrontProfileLayoutComponent,
    loadChildren: () => import('../../projects/intranet/hrm-user/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AccessSiteService, AuthenCheckerNewFeedsService],
  },
  {
    path: 'user',
    data: { css: 'frontTheme' },
    component: FrontProfileLayoutComponent,
    loadChildren: () => import('../../projects/intranet/hrm-user/user.module').then(m => m.UserModule),
    canActivate: [AccessSiteService, AuthenCheckerNewFeedsService],
  },
  {
    path: 'client',
    data: { title: 'Client', css: 'frontTheme' },
    component: ClientLayoutComponent,
    canActivateChild: [ClientGuardService],
    loadChildren: () => import('../../projects/intranet/hrm-client/client.module').then(m => m.ClientModule)
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivateChild: [AccessSiteService, AuthenCheckerAdminService],
    data: { isPermission: true },
    loadChildren: () => import('../../projects/intranet/hrm-admin/src/app/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'policy',
    data: { css: 'frontTheme' },
    component: FrontPolicyLayoutComponent,
    canActivateChild: [AccessSiteService, AuthenCheckerNewFeedsService],
    loadChildren: () => import('../../projects/intranet/front/pages/user-policy/user-policy.module').then(m => m.UserPolicyModule)
  },
  {
    path: UrlConstant.UserUrl.NEWSFEED,
    data: { css: 'frontTheme', title: 'News Feed' },
    component: FrontNewsFeedLayoutComponent,
    canActivateChild: [AccessSiteService, AuthenCheckerNewFeedsService],
    loadChildren: () => import('../../projects/intranet/front/front-news-feed.routing.module').then(m => m.FrontNewsFeedRoutingModule)
  },
  {
    path: 'groups',
    data: { css: 'frontTheme' },
    component: FrontGroupLayoutComponent,
    canActivate: [AccessSiteService, AuthenCheckerNewFeedsService],
    loadChildren: () => import('../../projects/intranet/hrm-group/group.module').then(m => m.FrontGroupModule)
  },
  {
    path: UrlConstant.GameUrl.ROOT,
    data: { css: 'frontTheme' },
    component: BlankLayoutComponent,
    canActivate: [AuthenCheckerNewFeedsService],
    loadChildren: () => import('../../projects/intranet/game/game.module').then(m => m.GameModule)
  },

  // Share link to allow clients access to view review form
  {
    path: 'form-members/:id',
    component: ReviewFormMemberClientComponent,
    data: { title: 'Review Form Member', css: 'frontTheme' }
  },
  {
    path: UrlConstant.UserUrl.REVIEW_PERFORMANCE,
    data: { css: 'frontTheme', isPermission: true },
    component: FrontNewsFeedLayoutComponent,
    loadChildren: () => import('../../projects/intranet/front/pages/review-performance/review-performance.routing.module').then(m => m.ReviewPerformanceRoutingModule),
    canActivate: [AuthenCheckerUserService],
  },
  {
    path: UrlConstant.UserUrl.BENEFIT,
    data: { css: 'frontTheme', isPermission: true },
    component: FrontNewsFeedLayoutComponent,
    loadChildren: () => import('../../projects/intranet/front/pages/benefit-user-page/benefit-user-page.module').then(m => m.BenefitUserPageModule),
    canActivate: [AuthenCheckerUserService],
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'not-permission',
        component: NotPermissionComponent,
        data: { title: 'Permission denied!' },
      },
      {
        path: '**',
        component: NotFoundComponent,
        data: { title: 'err.err404' },
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
