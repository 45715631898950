import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class StringHelper {
  static cleanWhiteSpace(value: any) {
    if (typeof value !== 'string') {
      return value;
    }

    const valueCleaned = (value as string).trim();

    return valueCleaned;
  }

  static renderCompareIcon(valueOld: string, valueNew: string, type = 1) {
    // tslint:disable-next-line: radix
    const numberOld = parseFloat(valueOld);
    // tslint:disable-next-line: radix
    const numberNew = parseFloat(valueNew);
    if (numberNew < numberOld) {
      return type === 1 ? 'fall' : 'rise';
    }
    else if (numberNew > numberOld) {
      return type === 1 ? 'rise' : 'fall';
    }
    else if (numberNew === numberOld) {
      return 'stock';
    }
  }

  static renderCompareColor(valueOld: string, valueNew: string, type = 1) {
    // tslint:disable-next-line: radix
    const numberOld = parseFloat(valueOld);
    // tslint:disable-next-line: radix
    const numberNew = parseFloat(valueNew);
    if (numberNew < numberOld) {
      return type === 1 ? 'red' : 'green';
    }
    else if (numberNew > numberOld) {
      return type === 1 ? 'green' : 'red';
    }
  }

  static removeSensitiveWords(value: string) {
    if (value === undefined || value === null || value === '') {
      return '';
    }
    value = value.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    value = value.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    value = value.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    value = value.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    value = value.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    value = value.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    value = value.replace(/đ/g, 'd');
    // Some system encode vietnamese combining accent as individual utf-8 characters
    value = value.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
    value = value.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư

    return value;
  }

  static compareCaseInsesitive(str1: string, str2: string): boolean {
    return (str1 || str1 === '')
      && (str2 || str2 === '')
      && str1.toLowerCase() === str2.toLowerCase();
  }
}
