<div class="rt-panel-item">
  <div class="row align-items-center title-panel">
    <div class="col">
      <p>Messages</p>
      <div (clickOutside)="hideToolBox(null)" class="dropdown show rt-news-feed-option">
        <a class="rt-account" (click)="showToolBox(null)" href="#" role="button" id="rt-account-notification">
          <span></span>
        </a>
        <div [class]="isShowToolbox ? 'dropdown-menu dropdown-menu-right show' : 'dropdown-menu dropdown-menu-right'">
          <a class="dropdown-item" (click)="onRead(null)" href="#"><img src="assets/images/front/shortcut/mark.svg" /> Mark as all
            read</a>
          <a class="dropdown-item" nz-popconfirm nzPopconfirmTitle="Are you sure clean all?" nzPopconfirmPlacement="top"
            (nzOnConfirm)="onClean(null)" href="#"><img src="assets/images/front/shortcut/delete.svg" /> Remove all
            message</a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="list" class="rt-panel-item-content">
    <virtual-scroller class="virtual-scroller" #scroll [items]="list" (vsEnd)="fetchMore($event)">

      <div [class]="item.readTime ? 'recent-notification message read' : 'recent-notification message'" *ngFor="let item of scroll.viewPortItems">
        <div class="img-notify" (click)="onLink(item)">
          <app-avatar class="user-photo" [style]="{width: '100%'}" [size]="55" [avatarSource]="item?.avatarUrl" [avatarText]="item?.createdByName"> </app-avatar>

        </div>
        <div (click)="onLink(item)" class="rt-message-content">
          <p><b>{{item.title}}</b></p>
          <p>{{item.description}}</p>
        </div>
        <span class="rt-time">{{item.createdDate | dateFromNow}}</span>
        <div (clickOutside)="hideToolBox(item)" class="dropdown show rt-notification-option">
          <a class="rt-account" (click)="showToolBox(item)" href="#" role="button">
            <span></span>
          </a>
          <div [class]="item.isShowToolbox ? 'dropdown-menu dropdown-menu-right show' : 'dropdown-menu dropdown-menu-right'">
            <a *ngIf="!item.readTime" (click)="onRead(item)" class="dropdown-item" href="#"><img
                src="assets/images/front/shortcut/mark.svg" /> Mark as read</a>
            <a nz-popconfirm nzPopconfirmTitle="Are you sure clean this item?" (nzOnConfirm)="onClean(item)"
              class="dropdown-item" href="#"><img src="assets/images/front/shortcut/delete.svg" /> Remove this
              message</a>
          </div>
        </div>
      </div>
    </virtual-scroller>
  </div>

</div>
