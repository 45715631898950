<header>
  <div class="container-fluid">
    <div class="row d-flex flex-row align-items-center container-header">
      <div class="col-header rt-col-left">
        <div class="small-view-only" *ngIf="!currentUser?.isExternalAccount">
          <button class="navbar-toggler" type="button" (click)="handleToggleLeftPanel()" [ngClass]="{
            'navbar-toggler-active': isExpandedFront
          }">
            <span class="icon collapse-expaned">
              <img src="assets/images/front/shortcut/combined-shape.svg" />
            </span>
          </button>
        </div>
        <div class="rt-logo">
          <a [routerLink]="['/' + urlConstant.NewsFeedUrl.ROOT]"> <img src="assets/images/front/logo.png"
              alt="restaff" /></a>
        </div>
        <div class="small-view-only">
          <!-- <button class="navbar-toggler" type="button" (click)="handleToggleRightPanel()" [class]="showRight() ? 'visible' : 'invisible'">
            <i class="fas fa-bars"></i>
          </button> -->
          <ng-container [ngTemplateOutlet]="headMenuTpl"></ng-container>
        </div>
      </div>
      <div class="col-header rt-col-center">
        <div class="input-group rt-search" *ngIf="isDisplaySearchControl">
          <div class="input-group-prepend">
            <span class="input-group-text" id="rt-search"><img src="assets/images/front/shortcut/search.svg"
                alt="restaff" /></span>
          </div>
          <input nz-input (keydown.enter)="onSearchEnter($event)" [(ngModel)]="searchData.keyWord" [ngModelOptions]="{ standalone: true }" [nzAutocomplete]="auto"
            type="text" (ngModelChange)="onSearch()" class="form-control" placeholder="Search" aria-label="Default" aria-describedby="rt-search" />
          <ng-template #indicatorTemplate><i  class="loadingSearch" nz-icon nzType="loading"></i>
          </ng-template>
          <nz-spin *ngIf="isLoadingSearch" nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
          <nz-autocomplete #auto>
            <div *ngIf="!isOnPageSearch" class="search-pannel">
              <!-- TODO: link to view all result -->
              <!-- <div nz-row>
                <div nz-col nzSpan="24" class="search-header">
                  <span><i nz-icon nzType="search" nzTheme="outline"></i></span>
                  <span>
                    <a href=""
                      [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.DETAIL), annoucement.id]">View all
                      results with "{{searchData.keyWord}}"</a>
                  </span>
                </div>
              </div> -->
              <!-- <div class="break-line"></div> -->
              <div nz-row>
                <div nz-col nzSpan="24" class="search-result">
                  <div class="result-group" nz-row *ngIf="feeds && feeds.length > 0">
                    <div nz-col nzSpan="24">
                      <div class="group-title">Posts</div>
                    </div>
                    <div nz-col nzSpan="24">
                      <nz-auto-option *ngFor="let feed of feeds" class="result-item" [nzValue]="searchData.keyWord">
                        <div>
                          <img src="assets/images/icons/post.svg" />
                        </div>
                          <div class="over-search-item" [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.DETAIL), feed.id]">
                            <p appHighlight [content]="feed.title" [searchTerm]="searchData.keyWord" [caseSensitive]="true">
                            </p>
                          </div>
                      </nz-auto-option>
                    </div>
                  </div>
                  <div class="break-line" *ngIf="feeds && feeds.length > 0"></div>
                  <div class="result-group" nz-row *ngIf="annoucements && annoucements.length > 0">
                    <div nz-col nzSpan="24">
                      <div class="group-title">Announcements</div>
                    </div>
                    <div nz-col nzSpan="24">
                      <nz-auto-option *ngFor="let annoucement of annoucements" class="result-item" [nzValue]="searchData.keyWord">
                        <div class="icon">
                          <img src="assets/images/icons/annoucements.svg" />
                        </div>
                          <div class="over-search-item" [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.DETAIL), annoucement.id]">
                            <p appHighlight [content]="annoucement.title" [searchTerm]="searchData.keyWord" [caseSensitive]="true">
                            </p>
                          </div>
                      </nz-auto-option>
                    </div>
                  </div>
                  <div class="break-line" *ngIf="annoucements && annoucements.length > 0"></div>
                  <div class="result-group" nz-row *ngIf="events && events.length > 0">
                    <div nz-col nzSpan="24">
                      <div class="group-title">Events</div>
                    </div>
                    <div nz-col nzSpan="24">
                      <nz-auto-option *ngFor="let event of events" class="result-item" [nzValue]="searchData.keyWord">
                        <div class="icon">
                          <img src="assets/images/icons/events.svg" />
                        </div>
                          <div class="over-search-item" [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.DETAIL), event.id]">
                            <p appHighlight [content]="event.title" [searchTerm]="searchData.keyWord" [caseSensitive]="true">
                            </p>
                          </div>
                      </nz-auto-option>
                    </div>
                  </div>
                  <div class="break-line" *ngIf="events && events.length > 0"></div>
                  <div class="result-group" nz-row *ngIf="bookings && bookings.length > 0">
                    <div nz-col nzSpan="24">
                      <div class="group-title">Meetings</div>
                    </div>
                    <div nz-col nzSpan="24">
                      <nz-auto-option *ngFor="let booking of bookings" class="result-item" [nzValue]="searchData.keyWord">
                        <div class="icon">
                          <img src="assets/images/icons/bookings.svg" />
                        </div>
                          <div class="over-search-item" [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.DETAIL), booking.id]">
                            <p appHighlight [content]="booking.title" [searchTerm]="searchData.keyWord" [caseSensitive]="true">
                            </p>
                          </div>
                      </nz-auto-option>
                    </div>
                  </div>
                  <div class="break-line" *ngIf="bookings && bookings.length > 0"></div>
                  <div class="result-group" nz-row *ngIf="users && users.length > 0">
                    <div nz-col nzSpan="24">
                      <div class="group-title">Users</div>
                    </div>
                    <div nz-col nzSpan="24">
                      <nz-auto-option *ngFor="let user of users" class="result-item" [nzValue]="searchData.keyWord">
                        <div class="icon">
                          <app-avatar [size]="18" [avatarSource]="user.avatarUrl"></app-avatar>
                        </div>
                        <div class="over-search-item" (click)="openEmployeePage(user.employeeId)">
                          <p appHighlight [content]="user.fullName" [searchTerm]="searchData.keyWord" [caseSensitive]="true">
                          </p>
                        </div>
                      </nz-auto-option>
                    </div>
                  </div>
                  <div class="break-line" *ngIf="users && users.length > 0"></div>
                  <div class="result-group" nz-row *ngIf="departments && departments.length > 0">
                    <div nz-col nzSpan="24">
                      <div class="group-title">Departments</div>
                    </div>
                    <div nz-col nzSpan="24">
                      <nz-auto-option *ngFor="let department of departments" class="result-item" [nzValue]="searchData.keyWord">
                        <div class="icon"><img src="assets/images/icons/avatar-default.svg" /></div>
                          <div class="over-search-item" [routerLink]="[urlConstant.mapGroup(urlConstant.GroupUrl.NEWSFEED)]" [queryParams]="{ groupId :department.id}">
                            <p appHighlight [content]="department.name" [searchTerm]="searchData.keyWord" [caseSensitive]="true">
                          </div>
                      </nz-auto-option>
                    </div>
                  </div>
                  <!-- Policy region -->
                  <div class="break-line" *ngIf="departments && departments.length > 0"></div>
                  <div class="result-group" nz-row *ngIf="policies && policies.length > 0">
                    <div nz-col nzSpan="24">
                      <div class="group-title">Policy</div>
                    </div>
                    <div nz-col nzSpan="24">
                      <nz-auto-option *ngFor="let policy of policies" class="result-item" [nzValue]="searchData.keyWord">
                        <div class="icon"><img src="assets/images/front/shortcut/policy-black.svg" width="20px" height="20px"/></div>
                          <div class="over-search-item" [routerLink]="[urlConstant.POLICY_PAGE]"
                            [queryParams]="{policyId: policy.policyId, policyDetailId: policy.policyDetailId}">
                            <p appHighlight [content]="policy.title" [searchTerm]="searchData.keyWord" [caseSensitive]="true">
                          </div>
                      </nz-auto-option>
                    </div>
                  </div>
                  <!-- End policy region -->
                  <div class="result-group" nz-row>
                    <div nz-col nsSpan="24">
                      <div nz-row>
                        <div nz-col nsSpan="6">
                          <button nz-button class="btn btn-white btn-search-action" type="button" (click)="onAddFeed()">
                            <i nz-icon nzType="plus" nzTheme="outline"></i> Feed
                          </button>
                        </div>
                      </div>
                      <div nz-row *hasPermission="['wallnewsfeed','createannouncement']">
                        <div nz-col nsSpan="6">
                          <button nz-button class="btn btn-white btn-search-action" type="button" (click)="onAddAnnoucement()">
                            <i nz-icon nzType="plus" nzTheme="outline"></i> Annoucement
                          </button>
                        </div>
                      </div>
                      <div nz-row *hasPermission="['event','create']">
                        <div nz-col nsSpan="6">
                          <button nz-button class="btn btn-white btn-search-action" type="button" (click)="onAddEvent()">
                            <i nz-icon nzType="plus" nzTheme="outline"></i> Event
                          </button>
                        </div>
                      </div>
                      <div nz-row *ngIf="currentUser && currentUser.hasEmployee">
                        <div nz-col nsSpan="6">
                          <button nz-button class="btn btn-white btn-search-action" type="button" (click)="onAddBooking()">
                            <i nz-icon nzType="plus" nzTheme="outline"></i> Meeting
                          </button>
                        </div>
                      </div>
                      <div nz-row *ngIf="currentUser && currentUser.hasEmployee">
                        <div nz-col nsSpan="6">
                          <button nz-button class="btn btn-white btn-search-action" type="button" (click)="onAddLeave()">
                            <i nz-icon nzType="plus" nzTheme="outline"></i> Leave
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nz-autocomplete>
        </div>
      </div>
      <div class="col-header rt-col-right text-right user-header-info" *ngIf="!isMobileView">
        <app-front-header-message [list]="listMessage"></app-front-header-message>
        <app-front-header-notification [list]="listNotification"></app-front-header-notification>
        <ng-container [ngTemplateOutlet]="headMenuTpl"></ng-container>
      </div>
    </div>
  </div>
</header>

<ng-template #headMenuTpl>
  <div class="dropdown show rt-header-option avatar-menu">
    <a class="btn btn-link dropdown-toggle rt-account" href="#" role="button" id="rt-account-info"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <app-avatar [size]="40" [avatarSource]="currentUser?.avatarUrl" [avatarText]="currentUser?.fullName">
      </app-avatar>
      <span class="username"> {{ currentUser?.fullName }}</span>
    </a>

    <app-menu [menuType]="MenuTypeEnum.TopMenu" [template]="topMenu"></app-menu>
    <ng-template #topMenu let-menu="menu">
      <div *ngIf="menu" class="dropdown-menu dropdown-menu-right" aria-labelledby="rt-account-info">
        <ng-container *ngFor="let item of menu">
          <a *ngIf="item.url" [class]="item.class" [routerLink]="item.url" (click)="onClickEvent(item.event)">
            <i [class]="item.icon"></i> {{item.title}}
          </a>

          <a *ngIf="!item.url" [class]="item.class" (click)="onClickEvent(item.event)">
            <i [class]="item.icon"></i> {{item.title}}
          </a>
        </ng-container>
      </div>
    </ng-template>
  </div>
</ng-template>
