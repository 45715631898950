import { createReducer, on } from '@ngrx/store';
import { ActionEx, UserActionTypes } from '../actions/login.action';

export const initialState = [];
export function UserReducer(state = initialState, action: ActionEx) {
  switch (action.type) {
    case UserActionTypes.Login:
      return action.payload;
    case UserActionTypes.Info:
      return [...state];
    default:
      return state;
  }
}
