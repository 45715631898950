import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { EVENT_CATEGORY, EVENT_NAME } from '@intranet/lib/config/google-analytics.constants';
import { CalendarView, Department, Room } from '@intranet/lib/data-access';
@Component({
  selector: 'calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
})
export class CalendarHeaderComponent implements OnInit {
  @Input() view: CalendarView | 'year' | 'month' | 'week' | 'day';

  @Input() durationFilters = [
    { value: 'month', label: 'Month' },
    { value: 'week', label: 'Week' },
    { value: 'day', label: 'Day' },
  ];

  @Input() viewDate: Date;

  @Input() locale = 'en';

  @Input() dataHeader: any;

  @Input() showFilter = true;

  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
  @Output() viewYearChange: EventEmitter<Date> = new EventEmitter();
  @Output() eventFilter = new EventEmitter();

  calendarViews = CalendarView;
  year: number;

  selectedRooms: Room[] = [];
  selectedDepartments: Department[] = [];
  keyword?: string;
  options: any[] = [];

  constructor(private analytics: AngularFireAnalytics) {}

  ngOnInit() {
    this.year = this.viewDate.getFullYear();
    setTimeout(() => {
      if (this.dataHeader && this.dataHeader.rooms) {
        this.dataHeader?.rooms?.map(item => (item.isChecked = true));
      }

      if (this.dataHeader && this.dataHeader.departments) {
        this.dataHeader?.departments?.map(item => (item.isChecked = true));
      }

      this.changeRoom();
      this.changeDepartment();

      this.filter();
    }, 1000);
  }

  filter() {
    this.eventFilter.emit({ selectedRooms: this.selectedRooms, selectedDepartments: this.selectedDepartments });
  }

  onSearch(e: Event): void {
    const value = (e.target as HTMLInputElement).value;
    if (!value) {
      this.options = [];
    }
  }

  changeRoom() {
    const roomIds = [];
    if (this.dataHeader && this.dataHeader.rooms) {
      this.selectedRooms = this.dataHeader.rooms.filter(item => item.isChecked);
      this.selectedRooms.forEach(item => {
        roomIds.push(item.id);
      });
      this.selectedRooms = roomIds;
    }

    this.filter();
  }

  changeDepartment() {
    const departmentIds = [];
    if (this.dataHeader && this.dataHeader.departments) {
      this.selectedDepartments = this.dataHeader.departments?.filter(item => item.isChecked);
      this.selectedDepartments?.forEach(item => {
        departmentIds.push(item.id);
      });
      this.selectedDepartments = departmentIds;
    }

    this.filter();
  }

  nextYear() {
    this.viewDate.setFullYear(this.viewDate.getFullYear() + 1);
    this.viewDateChange.emit(this.viewDate);
  }

  previousYear() {
    this.viewDate.setFullYear(this.viewDate.getFullYear() - 1);
    this.viewDateChange.emit(this.viewDate);
  }
  onClickToday() {
    this.analytics.logEvent(EVENT_NAME.PickToday, { event_category: EVENT_CATEGORY.Calendar });
  }

  switchTimeline() {
    this.analytics.logEvent(EVENT_NAME.SwitchTimeline, { event_category: EVENT_CATEGORY.Calendar });
  }

  changeCalendarType(value: any) {
    switch (value) {
      case 'month':
        this.analytics.logEvent(EVENT_NAME.View_ByMonth, { event_category: EVENT_CATEGORY.Calendar });
        break;
      case 'week':
        this.analytics.logEvent(EVENT_NAME.View_ByWeek, { event_category: EVENT_CATEGORY.Calendar });
        break;
      case 'day':
        this.analytics.logEvent(EVENT_NAME.View_ByDay, { event_category: EVENT_CATEGORY.Calendar });
        break;

      default:
        break;
    }
    this.viewChange.emit(value);
  }

  trackSelectFilter(filterName: string) {
    switch (filterName) {
      case 'room':
        this.analytics.logEvent(EVENT_NAME.Filter_MeetingRoom_ClickOption, { event_category: EVENT_CATEGORY.Calendar });
        break;
      case 'department':
        this.analytics.logEvent(EVENT_NAME.Filter_Group_ClickOption, { event_category: EVENT_CATEGORY.Calendar });
        break;
      default:
        break;
    }
  }

  nzVisibleChangeCalendar($event) {
    if ($event) {
      this.analytics.logEvent(EVENT_NAME.Filter_MeetingRoom_Open, { event_category: EVENT_CATEGORY.Calendar });
    }
  }

  nzVisibleChangeGroup($event) {
    if ($event) {
      this.analytics.logEvent(EVENT_NAME.Filter_Group_Open, { event_category: EVENT_CATEGORY.Calendar });
    }
  }
}
