import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { HttpClientService, Loading } from '@intranet/index';

@Injectable({
  providedIn: 'root',
})
export class TimekeeperUserService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/timekeeper-user';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  createTimeKeeper(data: any, successCallback: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = 'create-timekeeper';
    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }
  createUserInfo(data: any, successCallback: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = 'create-user-info';
    return this.postByUrl(url, data, successCallback, failedCallback, completeCallback);
  }

  getByEmployee(params?: any, successCallback?: (params?: any) => void) {
    const url = 'get-employee';
    return this.getByUrl(url, params, successCallback);
  }

  getUserInfo(params?: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = 'get-user-info';
    return this.postByUrl(url, params, successCallback, failedCallback, completeCallback);
  }

  pushToMachine(params?: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = 'create-user-all';
    return this.postByUrl(url, params, successCallback, failedCallback, completeCallback);
  }
  SyncToDatabae(params?: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = 'start-all-user-info';
    return this.postByUrl(url, params, successCallback, failedCallback, completeCallback);
  }
}
