import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseAdminComponent } from '@intranet/lib/components';
import { UserService, GiftService, Gift, ColumnMapping, COLUMN_TYPE, GiftApproveService, RedemptionRequest, SearchDateRangePaging } from '@intranet/lib/data-access';
import { DateHelper, SecurityHelper, Toastr } from '@intranet/index';
import { ActivatedRoute, Router } from '@angular/router';
import { startOfMonth, endOfMonth } from 'date-fns';
import * as moment from 'moment';

@Component({
  selector: 'app-redeem-gifts',
  templateUrl: './redeem-gifts.component.html',
  styleUrls: ['./redeem-gifts.component.scss']
})
export class RedeemGiftsComponent extends BaseAdminComponent implements OnInit, OnDestroy {
  redeemController = 'giftapproval';
  redeemAction = 'redeemgift';

  giftData: Gift[] = [];
  userReward: any;
  tabRedeem = 0;
  tabRedeemHistory = 1;
  tabAllHistory = 2;
  tabIndex = 0;
  giftPaging = new SearchDateRangePaging();
  giftTotalRows = 0;
  dateRangeModel = { Today: [new Date(), new Date()], 'This Month': [startOfMonth(new Date()), endOfMonth(new Date())] };

  history: RedemptionRequest[] = [];
  dateRangeRedeemHistory = [];
  redeemHistorySearchData = new SearchDateRangePaging();
  redeemHistoryTotalRows = 0;

  dateRangeAllHistory = [];
  allHistorySearchData = new SearchDateRangePaging();
  allHistoryTotalRows = 0;

  columnMapping = [
    new ColumnMapping(COLUMN_TYPE.STRING, 'name', 'Name', true, true),
    new ColumnMapping(COLUMN_TYPE.NUMBER, 'points', 'Points', true, true),
    new ColumnMapping(COLUMN_TYPE.NUMBER, 'value', 'Value', true, true),
    new ColumnMapping(COLUMN_TYPE.SPECIAL, 'link', 'Link', true, true),
    new ColumnMapping(COLUMN_TYPE.STRING, 'description', 'Description', true, false),
    new ColumnMapping(COLUMN_TYPE.IMAGE, 'imageLocations', 'Images', true, false),
  ];
  columnMappingHistory = [
    new ColumnMapping(COLUMN_TYPE.STRING, 'giftName', 'Gift', false, true, 250),
    new ColumnMapping(COLUMN_TYPE.NUMBER, 'point', 'Points', false, true, 100),
    new ColumnMapping(COLUMN_TYPE.ENUM, 'requestStatus', 'Status', false, true, 150),
    new ColumnMapping(COLUMN_TYPE.DATE, 'createdDate', 'Created on', false, true, 150),
    new ColumnMapping(COLUMN_TYPE.STRING, 'approvedName', 'Approved / Rejected by', false, true, 250),
    new ColumnMapping(COLUMN_TYPE.DATE, 'approvedDate', 'Approved / Rejected on', false, true, 200),
    new ColumnMapping(COLUMN_TYPE.STRING, 'note', 'Note', true, true, 500),
  ];
  // tslint:disable-next-line: max-line-length
  constructor(private userService: UserService
    , private giftService: GiftService
    , private giftApproveService: GiftApproveService
    , private route: ActivatedRoute
    , private router: Router) {
    super();
  }

  async ngOnInit() {
    this.loadUserReward();
    this.route.queryParams.subscribe(async (params) => {
      if (params.tab) {
        // tslint:disable-next-line: radix
        const index = parseInt(params.tab);
        this.tabIndex = index;
        await this.tabChange(index);
        return;
      }

      await this.tabChange(this.tabRedeem);
    });

    this.redeemHistorySearchData.pageNumber = 1;
    this.redeemHistorySearchData.pageSize = 15;
    this.allHistorySearchData.pageNumber = 1;
    this.allHistorySearchData.pageSize = 15;
    this.giftPaging.pageNumber = 1;
    this.giftPaging.pageSize = 15;
  }

  async loadGifts() {
    const data: any = await this.giftService.userView(this.giftPaging);
    this.giftData = data.map(g => this.giftService.getImageUrl(g));
    if (this.giftData && this.giftData[0]) {
      this.giftTotalRows = this.giftData[0].totalCount;
    }
  }

  async loadUserReward() {
    this.userReward = await this.userService.checkClaimDaily();
  }

  async loadHistory() {
    await this.giftApproveService.viewHistory(this.redeemHistorySearchData, (resp: RedemptionRequest[]) => {
      this.history = resp;
      if (this.history && this.history[0]) {
        this.redeemHistoryTotalRows = this.history[0].totalCount;
      }
    });
  }

  async loadAllHistory() {
    return await this.userService.getRewardHistory(this.allHistorySearchData);
  }

  async redeemGift(gift: Gift) {
    if (!this.checkRedeemPermission()) {
      Toastr.error(`You don't have permission to redeem gifts.`);
      return;
    }

    const result = await this.giftApproveService.redeemGift(gift);
    if (result && result.id >= 0) {
      Toastr.success(result.message);
      await this.loadUserReward();
      await this.loadGifts();
      return;
    }

    if (result && result.message) {
      Toastr.error(result.message);
      return;
    }

    Toastr.error(`Can't redeem gift due to internal error`);
    return;

  }

  async tabChange(index: number) {
    await this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { tab: index },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    await this.loadUserReward();
    if (index === 0 || index > 2) {
      await this.loadGifts();
    }
    else if (index === 1) {
      await this.loadHistory();
    } else if (index === 2) {
      await this.loadAllHistory();
    }
  }

  pageChangeGift(pageInfo: any) {
    this.giftPaging.pageNumber = pageInfo.offset + 1;
    this.loadGifts();
  }

  async searchRedeemHistory() {
    this.redeemHistorySearchData.pageNumber = 1;
    await this.onChangeDateRangeRedeemHistory();
    await this.loadHistory();
  }

  async onChangeDateRangeRedeemHistory() {
    this.dateRangeRedeemHistory = this.dateRangeRedeemHistory.map(dr => this.formatDate(dr));
    this.redeemHistorySearchData.startDate = DateHelper.formatServerNotTime(this.dateRangeRedeemHistory[0]);
    this.redeemHistorySearchData.endDate = DateHelper.formatServerNotTime(this.dateRangeRedeemHistory[1]);
  }

  pageChangeRedeemHistory(pageInfo: any) {
    this.redeemHistorySearchData.pageNumber = pageInfo.offset + 1;
    this.loadHistory();
  }

  async searchAllHistory() {
    this.allHistorySearchData.pageNumber = 1;
    await this.onChangeDateRangeAllHistory();
    await this.loadAllHistory();
  }
  async onChangeDateRangeAllHistory() {
    this.dateRangeAllHistory = this.dateRangeAllHistory.map(dr => this.formatDate(dr));
    this.allHistorySearchData.startDate = DateHelper.formatServerNotTime(this.dateRangeAllHistory[0]);
    this.allHistorySearchData.endDate = DateHelper.formatServerNotTime(this.dateRangeAllHistory[1]);
  }
  pageChangeAllHistory(pageInfo: any) {
    this.allHistorySearchData.pageNumber = pageInfo.offset + 1;
    this.loadAllHistory();
  }

  formatDate(date: any) {
    const d = moment(date);
    const dateString = d.format('YYYY-MM-DD').toString();
    const result = new Date(dateString);
    return result;
  }

  openLink(link: string) {
    window.open(link, '_blank');
  }

  historyRecordToString(his: any) {

  }

  checkRedeemPermission() {
    if (SecurityHelper.checkPermission(this.redeemController, this.redeemAction)) {
      return true;
    }

    return false;
  }
}
