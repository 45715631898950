import { BaseModel } from './base-model';
import { ResignStatus } from './enums/resign-status.enum';

export class ResignForm extends BaseModel {
  resignFormId: string;
  userId: string;
  fullName: string;
  departments: any;
  displayForLeader: boolean;
  expectedDate: Date;
  reason: string;
  status: ResignStatus;
  statusContent: string;
  joinDate: string | null;
  totalDay: number;
  sponsorDay: number;
  sponsorDayUsed: number;
  usedDay: number;
  remainingUsedDay: number;
  prevYearTranferDay: number;
  totalMissingHoursYears: number;
  dayForMissingHoursYear: number;
  totalAnnualLeaveWhenResign: number;
  remainingDayWhenResign: number;
  remainingSponsorDay: number;
  remainingTransferDay: number;
  totalSickLeave: number;
  totalSickLeaveUsed: number;
  remainingSickLeave: number;
}
export class ResignHistory extends BaseModel {
  resignHistoryId: string;
  resignFormId: string;
  userId: string;
  suggestedDate: Date;
  comment: string;
  status: ResignStatus;
  statusContent: string;
  fullName: string;
}

export class ResignView extends BaseModel {
  forms: ResignForm[];
  positions: any;
}
