import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { Observable, Subject } from 'rxjs';
import { SearchNewsflash, SearchNewsflashDetail } from '../models';
import { PagingResponse } from '../models/api-response.model';
import {
  Newsflash,
  NewsflashConfirmation,
  NewsflashConfirmationBody,
  NewsflashDetail,
  NewsflashFormValue,
} from '../models/newsflash.models';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class NewsflashService extends BaseService {
  hostUrl = '/api/newsflash';

  readonly shouldGetNewsflashConfirmation$ = new Subject<void>();

  readonly shouldFetchNewsflashs$ = new Subject<void>();

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  createNewsflash(body: NewsflashFormValue): Observable<void> {
    return this.httpClient.postObservable(this.hostUrl, body);
  }

  updateNewsflash(body: NewsflashFormValue): Observable<void> {
    return this.httpClient.putObservable(this.hostUrl, body);
  }

  getNewsflashs(reqOption: SearchNewsflash): Observable<PagingResponse<Newsflash>> {
    return this.httpClient.getObservable(this.hostUrl, reqOption);
  }

  getNewsflashDetail(newsflashId: string, reqOption: SearchNewsflashDetail): Observable<PagingResponse<NewsflashDetail>> {
    const url = `${this.hostUrl}/${newsflashId}`;

    return this.httpClient.postObservable(url, reqOption);
  }

  confirmNewsflashDocument(body: NewsflashConfirmationBody): Observable<void> {
    const url = `${this.hostUrl}/confirmation`;

    return this.httpClient.postObservable(url, body);
  }

  getNewsflashConfirmation(): Observable<NewsflashConfirmation> {
    const url = `${this.hostUrl}/confirmation`;

    return this.httpClient.getObservable(url);
  }

  deleteNewsflash(id: string): Observable<void> {
    const url = `${this.hostUrl}/${id}`;

    return this.httpClient.deleteObservable(url);
  }
}
