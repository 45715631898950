<div class="user-modal-custom">
  <modal-header title="Create Leave" [activeModal]="modal">
  </modal-header>
  
  <modal-body>
    <div class="row justify-content-center">
      <form class="col-12" (ngkSubmit)="onSubmit()" novalidate #frm="ngForm" [ngkForm]="true">
        <div class="form-row m-t-sm">
          <div class="form-group col-xl-12">
            <label class="required">Leave Type</label>
            <select-search name="leave-type" placeholder="Annual type" [(ngModel)]="leaveRequest.leaveType" [data]="leaveTypes" textField="name" keyField="id"
              (ngModelChange)="onChangeLeaveType()" [disabled]="!userAnnualLeave" required>
            </select-search>
          </div>
          <div class="form-group col-xl-12">
            <label>Days</label>
            <br />
            <nz-radio-group name="half" (ngModelChange)="onChangeHalf($event)" [(ngModel)]="leaveRequest.halfDay">
              <label nz-radio [nzValue]="o" *ngFor="let o of halfs">{{ o.name }}</label>
            </nz-radio-group>
          </div>
          <div class="form-group col-xl-12" [ngClass]="{'has-error-2': exceedLimitDay}">
            <div class="form-row">
              <div class="col-6">
                <label class="required">From</label>
                <nz-date-picker [nzFormat]="dateFormat" [(ngModel)]="leaveRequest.fromDate" name="leave-request-from-date" [nzDisabledDate]="disableFromDate"
                  (ngModelChange)="onChangeFromDate($event)"></nz-date-picker>
              </div>
              <div class="col-6" *ngIf="leaveRequest.fromDate">
                <label class="required">To</label>
                <nz-date-picker [nzFormat]="dateFormat" [(ngModel)]="leaveRequest.toDate" name="leave-request-to-date" [nzDisabledDate]="disableToDate"
                  (ngModelChange)="onChangeToDate($event)"></nz-date-picker>
              </div>
            </div>
          </div>
          <div class="form-group col-xl-12" *ngIf="leaveRequest.leaveType && (leaveRequest.leaveType.description || leaveRequest.leaveType.useForOneTime)">
            <label>Attention</label>
            <section>
              <span class="text-warning">In this leave type you can only use one time</span><br>
              <span [innerHTML]="leaveRequest.leaveType.description"></span>
            </section>
          </div>
          <div class="form-group col-xl-12" *ngIf="(tagOptionData && tagOptionData.length > 0); else conditionReason">
            <label class="form-row">Reason</label>
            <nz-radio-group *ngIf="tagOptionData.length <= 3" [ngModelOptions]="{ standalone: true }" (ngModelChange)="onChangeLeaveOptions($event)"
              [ngModel]="leaveRequest.reason">
              <label *ngFor="let item of tagOptionData" nz-radio [nzValue]="item">{{item}}</label>
            </nz-radio-group>
            <nz-select *ngIf="tagOptionData > 3" class="form-select" name="leave-action" [nzMaxMultipleCount]="4" nzPlaceHolder="Reason"
              [(ngModel)]="leaveRequest.reason">
              <nz-option *ngFor="let item of tagOptionData" [nzLabel]="item" [nzValue]="item">
              </nz-option>
            </nz-select>
          </div>
          
          <ng-template #conditionReason>
            <div class="form-group col-xl-12">
              <label [class]="isRequired ? 'required' : ''">Reason</label>
              <textarea name="reason" class="form-control" [(ngModel)]="leaveRequest.reason" [disabled]="!userAnnualLeave" [required]="isRequired"></textarea>
            </div>
          </ng-template>
          
          <ng-template #empty>
            <nz-empty [nzNotFoundContent]="'Waiting...'"></nz-empty>
          </ng-template>
        </div>
        
        <div class="form-group col-12">
          <div class="row justify-content-end p-r-sm">
            <create-update-button (cancel)="modal.close(false)" isCancel="true" content="Submit"></create-update-button>
          </div>
        </div>
      </form>
    </div>
  </modal-body>
</div>
