export enum ActivityTypeEnum {
  COMMENT = 1,
  POLL = 2,
  POST = 3,
  LIKE = 4,
  UNLIKE = 5,
  SURVEY = 6,
  BIRTHDAY = 7,
  SURVEY_ANSWER = 8,
  WORK_ANNIVERSARY = 9
}
