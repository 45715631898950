import { trigger, transition, style, query, animateChild, group, animate } from '@angular/animations';

export const slideInAnimation = trigger('routeAnimations', [
  transition('UserDashboardComponent => DashboardComponent', [
    // query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%' }), { optional: true }),
    // query(':enter', style({ transform: 'translateX(100%)' }), { optional: true }),

    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ]),

    query(':enter', [style({ left: '-100%' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('700ms ease-out', style({ left: '100%' }))], { optional: true }),
      query(':enter', [animate('700ms ease-out', style({ left: '0%' }))], { optional: true }),
    ]),
    query(':enter', animateChild()),
  ]),
]);
