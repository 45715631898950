import { Warehouse, WarehouseDetail } from './warehouse';
import { Product } from './product.model';

export class EmployeeEquipment {
  public id: string;
  public deliveryFormId: string;
  public warehouseItemId: string;
  public productId: string;
  public purchaseRequestNo: string;
  public userId: string;
  public warehouseId: string;
  public equipmentId: string;
  public status: number;
  public quantity: number;
  public remainQuantity: number;
  public updateQuantity: number;
  public newQuantity: number;
  public neededQuantity: number;
  public totalQuantity: number;
  public isSignature: boolean;
  public isUpdate: boolean;
  public isMinus: boolean;
  public qrCode: string;
  public deliveredDate: Date;
  public returnDate: Date;
  public name: string;
  public lastEquipmentName: string;
  public purchaseRequestDetailId: string;
  public warehouse: Warehouse;
  isBroken?: boolean;
  isNew?: boolean;
  product: Product;
  warehouseDetail: WarehouseDetail;
}

export class GetEmployeeEquipment {
  userId: string;
  fullName: string;
  isExpand: boolean;
  employeeEquipments: GetEmployeeEquipmentItem[];
}

export class GetEmployeeEquipmentItem {
  id: string;
  productName: string;
  categoryName: string;
  supllierName: string;
  invocieNo: string;
  invociePrice: number;
  invocieDate: Date | null;
  poNo: string;
  price: number;
  quantity: number;
  totalAmount: number;
  status: number;
  deliveredDate: Date | null;
  returnDate: Date | null;
  qrCode: string;
  employeeEquipmentCode: string;
}
