import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/index';
import { ApiEndpoint } from '@intranet/lib/config/api-endpoint.constant';
import { LeaveTransfer } from '..';

@Injectable({
  providedIn: 'root',
})
export class LeaveTransferService {
  constructor(private httpClient: HttpClientService) { }

  getList(request: any, isLoading: boolean = false) {
    const url = ApiEndpoint.LeaveTransfer.GetList();
    return this.httpClient.getObservable(url, request, isLoading).toPromise();
  }

  getPreviewList(request: any, isLoading: boolean = false) {
    const url = ApiEndpoint.LeaveTransfer.Preview();
    return this.httpClient.getObservable(url, request, isLoading).toPromise();
  }

  update(leaveTransfer: LeaveTransfer) {
    const url = ApiEndpoint.LeaveTransfer.Update(leaveTransfer.id);
    return this.httpClient.putObservable(url, leaveTransfer).toPromise();
  }

  getEmailTemplates() {
    const url = ApiEndpoint.LeaveTransfer.EmailTemplates();
    return this.httpClient.getObservable(url).toPromise();
  }

  generate(request: any) {
    const url = ApiEndpoint.LeaveTransfer.Generate();
    return this.httpClient.postObservable(url, request).toPromise();
  }

  complete(request: any) {
    const url = ApiEndpoint.LeaveTransfer.Complete();
    return this.httpClient.postObservable(url, request).toPromise();
  }

  checkDisplayPopup(): Promise<any> {
    const url = ApiEndpoint.LeaveTransfer.CheckDisplayPopup();
    return this.httpClient.getObservable(url).toPromise();
  }

  confirm(data: any) {
    const url = ApiEndpoint.LeaveTransfer.Confirm();
    return this.httpClient.putObservable(url, data).toPromise();
  }

  getByEmployeeId(employeeId: string, noLoading: boolean = true): Promise<any> {
    const url = ApiEndpoint.LeaveTransfer.Employee();
    return this.httpClient.getObservable(url, { employeeId, noLoading }).toPromise();
  }

  updateExceptionStatus(updateData: any, noLoading: boolean = true): Promise<LeaveTransfer> {
    const url = ApiEndpoint.LeaveTransfer.Exception(noLoading);
    return this.httpClient.putObservable(url, updateData).toPromise();
  }

  export(searchData: any): Promise<any> {
    const url = ApiEndpoint.LeaveTransfer.Export();
    return this.httpClient.getObservable(url, searchData).toPromise();
  }
}
