import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { CommonHelper, Constants, SecurityHelper } from '@intranet/index';
import { NewsflashConfirmation } from '@intranet/lib/data-access/models/newsflash.models';
import { NewsflashService } from '@intranet/lib/data-access/services/newsflash.service';
import { PushMessagingService } from '@intranet/lib/services/push-messaging.service';
import { I18nService, ModalService, ModalSize, TitleService, WebsiteEvent } from '@intranet/lib/settings';
import { MessageOption, NgkMessageService } from '@intranet/lib/settings/services/ngkMessage.service';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NewsflashConfirmationComponent } from 'projects/intranet/hrm-admin/src/app/pages/policys/newsflash/newsflash-confirmation/newsflash-confirmation.component';
import { Subscription, fromEvent } from 'rxjs';
import { filter, startWith, switchMap, throttleTime } from 'rxjs/operators';
import { LoadingAllPageComponent } from './loading-all-page/loading-all-page.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  public loadingAllPageComponent = LoadingAllPageComponent;
  title = 'hrm-admin-page';
  currentHeight: any;
  subscriptions: Subscription[] = [];
  messageData: MessageOption = {};
  filteredUrlPatterns = ['.*[nN]o[lL]oading=true.*'];
  filteredHeaderPatterns = ['IsLoading'];
  showLoading = true;
  isInitDispatchClickOnScroll = false;

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    private router: Router,
    public i18nService: I18nService,
    private titleService: TitleService,
    config: NgbModalConfig,
    private webSiteEvent: WebsiteEvent,
    private ngkMessageService: NgkMessageService,
    private pushMessagingService: PushMessagingService,
    private readonly newsflashService: NewsflashService,
    private readonly modalService: ModalService,
  ) {
    config.backdrop = 'static';
    config.keyboard = true;
    this.i18nService.init();
    this.titleService.init();
    this.subscriptions.push(
      this.ngkMessageService.sourceShowMessage.subscribe(data => {
        if (data) {
          this.messageData = data;
        }
      }),
    );

    if (CommonHelper.isMobileView()) {
      CommonHelper.unsetExpandedFront();
    }

    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        const route = event.state.root.firstChild;
        const res = route.data;
        const style = res?.css || 'adminTheme';
        this.loadStyle(style);
      }

      if (event instanceof NavigationStart) {
        if (this.isShowLoading(event.url)) {
          this.showLoading = false;
          return;
        }

        this.showLoading = true;
      }
    });
  }

  ngOnInit() {
    window.addEventListener('resize', this.handleWindowResize.bind(this));
    this.pushMessagingService.requestPermission();
    this.pushMessagingService.receiveMessage();
    this.getNewsflashConfirmation();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s?.unsubscribe());
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.webSiteEvent.eventChangeHeight.subscribe(() => {
        this.document.body.classList.remove(Constants.HidePopoverOnScrollingClass);
        if (CommonHelper.isMobileView()) {
          this.document.body.classList.add(Constants.HidePopoverOnScrollingClass);
          this.dispatchClickOnScroll();
        }
      }),
    );

    $('#loading').addClass('hidden');
    this.handleWindowResize();
  }

  loadStyle(styleName: string) {
    const html = this.document.getElementsByTagName('html')[0];
    html.attributes['aria-theme'].value = styleName;
  }

  handleWindowResize() {
    const headerHeight = 210;
    let contentHeight = window.innerHeight - headerHeight;
    if (contentHeight <= 0) {
      contentHeight = window.innerHeight;
    }
    this.currentHeight = contentHeight;
    this.webSiteEvent.currentHeight = this.currentHeight;
    this.webSiteEvent.changeHeight(contentHeight);
  }

  private isShowLoading(url: string) {
    return (
      Constants.HideLoadingIncludeUrls.some(u => url.startsWith(u)) &&
      !Constants.HideLoadingExcludeUrls.some(u => url.startsWith(u))
    );
  }

  private dispatchClickOnScroll() {
    if (this.isInitDispatchClickOnScroll) {
      return;
    }

    this.isInitDispatchClickOnScroll = true;
    const sub = fromEvent(window, 'scroll')
      .pipe(throttleTime(1500))
      .subscribe(() => {
        if (this.document.body.classList.contains(Constants.HidePopoverOnScrollingClass)) {
          this.document.body.click();
          this.document.body.dispatchEvent(new KeyboardEvent('keydown', { keyCode: 27 }));

          // Workaround for nz-date-picker
          this.document.querySelectorAll('nz-date-picker input').forEach(e => e.dispatchEvent(new Event('focusout')));
        }
      });

    this.subscriptions.push(sub);
  }

  private getNewsflashConfirmation(): void {
    this.newsflashService.shouldGetNewsflashConfirmation$
      .pipe(
        startWith(() => null),
        filter(() => SecurityHelper.isAuthenticated),
        switchMap(() => this.newsflashService.getNewsflashConfirmation()),
        filter(Boolean),
      )
      .subscribe((res: NewsflashConfirmation) => {
        const modalRef = this.modalService.open(NewsflashConfirmationComponent, {
          size: ModalSize.xl,
          centered: true,
          keyboard: false,
        });

        modalRef.componentInstance.newsflashConfirmation = res;
      });
  }
}
