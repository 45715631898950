export enum BenefitRegistrationTypeEnum {
    None = 1,
    Single = 2,
    Multiple = 3
}

export enum BenefitRegistrationConstEnum {
    NotAllow = 'None',
    SingleRegister = 'Single',
    MultipleRegister = 'Multiple'
}