import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '../../config/constants';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modalService: NgbModal) {}

  open(content: any, options?: ModalOption): NgbModalRef {
    const theme = document.querySelector('#theme');
    const className = theme ? theme.className : '';
    let option: any = {
      windowClass: 'animate__animated animate__bounceInDown',
    };

    if (options) {
      if (options.size) {
        if (options.size === 'sm' || options.size === 'md' || options.size === 'lg' || options.size === 'xl') {
          option.size = options.size;
          option.windowClass += ' restaff';
        } else {
          if (options.noHeader) {
            options.size += ' aluha-no-header';
          }
          option.windowClass += ' ' + options.size;
        }
      }
      option = { ...option, ...options };
    }

    option.windowClass = option.windowClass + ' ' + className;
    const modalRef = this.modalService.open(content, option);
    return modalRef;
  }
}

export const ModalSize = Constants.ModalSize;

export interface ModalOption extends NgbModalOptions {
  noHeader?: boolean;
}
