
export enum CompensationHourTypeEnum {
  Negative = 1,
}

export enum CompensationHourDisplayMode {
  Create,
  Update,
  ViewDetail,
}
