import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Policy } from '../models';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class PolicyService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/policys';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getMenu() {
    const url = this.hostUrl + `/get-menu-list`;
    return this.httpClient.getSync(url);
  }
  getInfomation(id: string) {
    const url = this.hostUrl + `/get-item?id=${id}`;
    return this.httpClient.getSync(url);
  }
  getFileUrl(id: string) {
    if (!id) return '';

    return `${AppConfig.settings.CDN_URL}/cdn/file/download/${id}`;
  }

  getTree(): Observable<NzTreeNodeOptions[]> {
    return this.httpClient.getObservable('/api/policys/tree').pipe(map(res => this.mapPoliciesToTree(res)));
  }

  private mapPoliciesToTree(policies: Policy[]): NzTreeNodeOptions[] {
    return policies.map(({ policyId, title, policyDetails }) => ({
      title,
      key: policyId,
      children: policyDetails.map(({ policyDetailId, title, files }) => ({
        title,
        key: policyDetailId,
        children: files.map(({ fileId, fileName, fileType }) => ({
          title: fileName,
          key: fileId,
          isLeaf: true,
          fileType,
        })),
      })),
    }));
  }
}
