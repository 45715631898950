import { Injectable } from '@angular/core';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { HttpClientService } from '@intranet/index';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectItemService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/projectitem';
  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getDropDown(projectId: string) {
    const url = this.hostUrl + '/get_drop_down?projectId=' + projectId;
    return this.httpClient.getSync(url);
  }

  getProjectItemList(params: any, successCallback: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    this.postByUrl('get_all_item', params, successCallback, failedCallback, completeCallback);
  }

  updateOrder(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    return this.updateUrl('update_order', data, undefined, successCallback, failedCallback, completeCallback);
  }

  updateStatus(data: any, successCallback?: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    return this.updateUrl('update_status', data, undefined, successCallback, failedCallback, completeCallback);
  }
}
