import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgkValidateFormModule, MULTIPLE_LANGUAGE, DEBUG_NGK } from 'ngk-validate-form';
import { TranslateModule } from '@ngx-translate/core';
import { NgkLoadingModule, ngkLoadingAnimationTypes } from 'ngk-loading';
import { ResizableModule } from 'angular-resizable-element';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { MatButtonModule } from '@angular/material/button';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareDirectiveModule } from '../directives';
import { LayoutModule } from '../layouts/layout.module';
import { PageContentComponent, PageHeaderComponent, PageMainComponent } from '../layouts';
import { NgkCurrencyModule, CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ngk-currency';
import { ComponentShareModule } from '../components/component-share.module';
import { NgZorroShareModule } from './ngzorro.module.share';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GridsterModule } from 'angular-gridster2';
import { FileIconComponent } from 'projects/intranet/front/components';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  decimal: '.',
  precision: 0,
  prefix: '',
  suffix: '',
  thousands: '.',
};

@NgModule({
  declarations: [PageContentComponent, PageHeaderComponent, PageMainComponent, FileIconComponent],
  imports: [
    NgbModule,
    DragDropModule,
    NgxChartsModule,
    NgZorroShareModule,
    NgkValidateFormModule.forRoot(),
    TranslateModule.forChild(),
    LayoutModule,
    ShareDirectiveModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxDatatableModule,
    ComponentShareModule,
    ImageCropperModule,
    NgkCurrencyModule,
    EditorModule,
    MatButtonModule,
    NgxSpinnerModule,
    GridsterModule,
    NgkLoadingModule.forRoot({
      backdropBackgroundColour: 'rgba(35, 35, 35, 0.08)',
      animationType: ngkLoadingAnimationTypes.wanderingCubes,
      secondaryColour: 'rgb(221, 140, 0)',
      tertiaryColour: '#f19f08',
      primaryColour: '#1abc9c',
      fullScreenBackdrop: true,
      backDrop: true,
      backCircle: false,
      circleSize: [75, 75],
    }),
    ResizableModule,
    PerfectScrollbarModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  exports: [
    NgkValidateFormModule,
    NgZorroShareModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
    TranslateModule,
    NgkLoadingModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    ComponentShareModule,
    EditorModule,
    DragDropModule,
    NgxChartsModule,
    NgkCurrencyModule,
    MatButtonModule,
    ImageCropperModule,
    ResizableModule,
    GridsterModule,
    PerfectScrollbarModule,
    ShareDirectiveModule,
    PageContentComponent,
    PageHeaderComponent,
    PageMainComponent,
    FileIconComponent,
  ],
  providers: [
    {
      provide: MULTIPLE_LANGUAGE,
      useValue: true,
    },
    {
      provide: DEBUG_NGK,
      useValue: false,
    },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PackageShareModule { }
