import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateHelper } from './dateHelper';

@Injectable({
  providedIn: 'root',
})
export class UrlHelper {
  static getValue(
    router: ActivatedRoute,
    key: string,
    formatDate?: string,
    type?: 'Date' | 'string' | 'number' | 'boolean',
    defauleValue?: any,
  ) {
    if (router.snapshot.queryParamMap.has(key)) {
      const value = router.snapshot.queryParamMap.get(key) as any;

      if (~key.indexOf('DateRange')) return value;

      if (key.indexOf('Date') !== -1 || (type === 'Date' && value)) {
        let date = value ? new Date(value) : defauleValue || new Date();
        if (formatDate) {
          date = DateHelper.formatServer(date, formatDate);
        }
        return date;
      }
      if (key.indexOf('skip') !== -1 || key.indexOf('limit') !== -1 || type === 'number' || (value && !isNaN(value))) {
        // tslint:disable-next-line: radix
        return value ? parseInt(value) : defauleValue || 0;
      }
      if (type === 'boolean' || value === 'true' || value === 'false') {
        return value ? value === 'true' : defauleValue || false;
      }
      return value;
    }
    return undefined;
  }
  static mappingData(router: ActivatedRoute, target: any, formatDate = 'YYYY-MM-DD', shouldCheckExist = true, ignoreKeys = []) {
    const queryParamKeys: any = router.snapshot.queryParamMap.keys;
    if (queryParamKeys && target.isNotNullOrEmpty()) {
      // tslint:disable-next-line: forin
      queryParamKeys.forEach(key => {
        if (ignoreKeys.includes(key)) return;

        if (!shouldCheckExist || target.hasOwnProperty(key)) {
          const value = this.getValue(router, key, formatDate);
          target[key] = value;
        }
      });
    }
    return target;
  }
}
