<ng-container *ngIf="widget">
  <nz-card [nzBordered]="false" [nzLoading]="loading">
    <div class="widget-header">
      <div class="widget-title">
        <ng-container *ngIf="isDetail">
          <span (click)="hideDetail()" class="vector-right-icon btn-link"><img  src="assets/images/front/shortcut/vector-right.svg" ></span>
          {{title}}
        </ng-container>
        <ng-container *ngIf="!isDetail">
          {{widget.data.title}}
        </ng-container>
      </div>
      <div class="widget-control widget-control-sm-fully">
        <div *ngIf="!isDetail && data && data.companyMembers && data.departmentGroups && data.departmentGroups.length > 1">
          <nz-select (ngModelChange)="modelChanged($event)" nzShowSearch nzAllowClear nzPlaceHolder="Select a groups"
            [(ngModel)]="searchData.departmentIds">
            <nz-option *ngFor="let option of data.departmentGroups" [nzLabel]="option.name" [nzValue]="option.id">
            </nz-option>
          </nz-select>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!isDetail">
      <div class="widget-content" *ngIf="data && data.companyMembers">
        <ngx-charts-pie-chart [view]="data.companyMembers.view" [scheme]="data.companyMembers.colorScheme" [results]="data.companyMembers.chartDatas"
          [legendTitle]="data.companyMembers.legendTitle" [legend]="data.companyMembers.showLegend" (select)="onSelect($event)"
          [legendPosition]="data.companyMembers.legendPosition" [labels]="data.companyMembers.showLabels" [doughnut]="data.companyMembers.isDoughnut"
          [arcWidth]="data.companyMembers.arcWidth">
        </ngx-charts-pie-chart>
      </div>
    </ng-container>
    <ng-container *ngIf="isDetail">
      <app-widget-company-detail [(data)]="dataDetail" [(searchData)]="searchData"></app-widget-company-detail>
    </ng-container>
  </nz-card>
</ng-container>
