import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CreateFeedBaseComponent } from '../create-feed-base/create-feed-base.component';
import { Guid } from 'guid-typescript';
import { WallNewsFeedService, NewsFeedTypeEnum } from '@intranet/lib/data-access';
import { FormBuilder } from '@angular/forms';
import { PubSubService } from '@intranet/lib/services/pubsub.service';
import { Bootbox } from '@intranet/index';
import * as moment from 'moment';
@Component({
  selector: 'app-newsfeed-create-multiple-poll',
  templateUrl: './create-multiple-poll.component.html',
  styleUrls: ['../create-feed-base/create-feed-base.component.scss'],
  styles: [],
})
export class CreateMultiplePollComponent extends CreateFeedBaseComponent implements OnInit, AfterViewInit {
  constructor(protected newsFeedService: WallNewsFeedService, protected fb: FormBuilder, protected pubsub: PubSubService) {
    super(newsFeedService, fb, pubsub);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    this.setTitle('Create Poll');
  }

  reset() {
    super.reset();
    if (!this.originalFeed) {
      this.feed.id = Guid.create().toString();
      this.feed.newsFeedTypeId = NewsFeedTypeEnum.POLL;
      this.feed.isShow = true;
      this.feed.allowComment = true;
      this.feed.allowLike = true;
      this.feed.isCreate = true;
      this.feed.pollQuestions = [];

      const questionId = Guid.create().toString();
      this.feed.pollQuestions.push({
        id: questionId,
        content: '',
        htmlContent: '',
        newsFeedId: this.feed.id,
        isMultipleChoice: false,
        options: [
          {
            id: Guid.create().toString(),
            pollQuestionId: questionId,
            title: '',
          },
        ],
      });
    }

    this.modelUpload.entityId = this.feed.id;
    if (this.feed.beginResultDate) {
      this.feed.beginResultDate = moment.utc(this.feed.beginResultDate).toDate();
    }
    if (this.feed.endResultDate) {
      this.feed.endResultDate = moment.utc(this.feed.endResultDate).toDate();
    }
  }

  onAddQuestion() {
    const questionId = Guid.create().toString();
    this.feed.pollQuestions.push({
      id: questionId,
      content: '',
      htmlContent: '',
      newsFeedId: this.feed.id,
      isMultipleChoice: false,
      options: [
        {
          id: Guid.create().toString(),
          pollQuestionId: questionId,
          title: '',
        },
      ],
    });
  }

  onRemoveQuestion(question) {
    Bootbox.confirm().subscribe(res => {
      if (res) {
        this.feed.pollQuestions.splice(this.feed.pollQuestions.indexOf(question), 1);
      }
    });
  }

  onRemoveOption(question, option) {
    Bootbox.confirm().subscribe(res => {
      if (res) {
        question.options.splice(question.options.indexOf(option), 1);
      }
    });
  }

  onAddOption(question) {
    question.options.push({
      id: Guid.create().toString(),
      pollQuestionId: question.id,
      title: '',
    });
  }

  public getServiceMethod(): (
    params: any,
    successCallback?: (params: any) => void,
    failCallback?: (params: any) => void,
    completeCallback?: (params: any) => void,
  ) => void {
    return this.newsFeedService.createPoll.bind(this.newsFeedService);
  }

  public getServiceUpdateMethod(): (
    params: any,
    successCallback?: (params: any) => void,
    failCallback?: (params: any) => void,
    completeCallback?: (params: any) => void,
  ) => void {
    return this.newsFeedService.updatePoll.bind(this.newsFeedService);
  }
}
