import { Position } from '.';
import { BaseModel } from './base-model';
import { PublicityLevel } from './enums/publicity-level.enum';

export class TicketType extends BaseModel {
  name: string;

  positionId?: string;
  position?: Position;
  positionIds?: string[];
  specificUsersList?:string[];
  specificUsers?: string;
  publicityLevel?: PublicityLevel;
  otherEmailsList?: string[];
  otherEmails?: string;
  allowLike: boolean;
  allowComment: boolean;
}

