<div class="rt-panel-item" *ngIf="birthdays && birthdays.length > 0">
  <div class="row align-items-center title-panel">
    <div class="col">
      <p>Today's birthday</p>
    </div>
  </div>
  <div class="rt-panel-item-content">
    <div class="row align-items-center item-birth" *ngFor="let item of birthdays">
      <div class="col">
        <app-avatar [size]="40" [avatarSource]="item?.avatarUrl" [avatarText]="item?.fullName"></app-avatar>
        <span class="btn-link" [routerLink]="[urlConstant.PROFILE_PAGE]"
          [queryParams]="{ employeeId :item?.id}">{{ item.fullName }}</span>

        <button *ngIf="!item.userCount" (click)="sendCelebrate(item)" type="button" class="btn btn-outline-secondary"><img
            src="assets/images/icons/birthday-cake-black-icon.svg" alt="user" /> Send</button>
          <button *ngIf="item.userCount" type="button" class="btn btn-outline-secondary incoming"><img
            src="assets/images/icons/birthday-cake-icon.svg" alt="user" /></button>
            <a href="#" (click)="onShowWishedItems(item)" *ngIf="item.reactionCount" class="wished-badge">{{item.reactionCount}}</a>
      </div>
    </div>
  </div>
