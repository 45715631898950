<div [ngClass]="view != 'details' ? 'rt-panel-item' : ''">
  <div class="row align-items-center title-panel" *ngIf="view != 'details'">
    <div class="col-8">
      <p>Announcements</p>
    </div>
    <div class="col-4 text-right">
      <a href="" class="view-all" [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.ANNOUNCEMENTS)]">View
        all</a>
    </div>
  </div>
  <div class="rt-panel-item-content" *ngIf="!isLoading && (!announcements || announcements.length <= 0)">
    <p class="mt20 mb20">
      <span>There are no announcement at this time.</span>
    </p>
  </div>
  <div class="rt-panel-item-content" *ngIf="!isLoading && announcements && announcements.length > 0">
    <div class="row align-items-center btn-link" *ngFor="let feed of announcements"
      [routerLink]="[urlConstant.mapNewsFeed(urlConstant.NewsFeedUrl.ANNOUNCEMENT_DETAILS), feed.id]">
      <div class="col">
        <div class="announcement" [ngClass]="feed.isImportant ? 'active' : ''">
          <div class="img-notify">
            <img class="user-photo" src="assets/images/front/shortcut/annoucements-active.svg" alt="announcements" />
          </div>
          <p>{{feed.title}}</p>
          <p>{{feed.createdDate | amFromUtc | amDateFormat: "DD MMM, YYYY"}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="rt-panel-item-content" *ngIf="isLoading">
    <nz-skeleton [nzActive]="true">
      <ul class="p-b-none">
        <li class="announcement-item">
          <div>Title</div>
          <p>Short Description</p>
        </li>
      </ul>
    </nz-skeleton>
  </div>
</div>