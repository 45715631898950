import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { PollQuestionResult } from '../models/poll-question-result.model';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { Subject } from 'rxjs';
import { SecurityHelper } from '@intranet/index';

@Injectable({
  providedIn: 'root',
})
export class PollRealtimeService {
  public data: Subject<PollQuestionResult> = new Subject<PollQuestionResult>();

  private hubConnection: signalR.HubConnection;

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${AppConfig.settings.API_URL}/poll`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => SecurityHelper.getStoreAuthen().token,
      })
      .withAutomaticReconnect()
      .build();

    this.hubConnection.start();
  }

  public addTransferResultListener = (callback: (params?: any) => void) => {
    this.hubConnection.on('PollUpdated', data => {
      callback(data);
    });
  }
}
