import { Constants } from '@intranet/lib/config';

export class Page {
  // The number of elements in the page (page size)
  pageSize = Constants.MAX_PAGE_SIZE;
  // The total number of elements
  totalItems = 0;
  // The current page number
  pageNumber = 1;
  // current record
  currentRecord = 0;
  // generate query string
  isGenerate = false;

  constructor(options?: Pagination) {
    if (options) {
      Object.assign(this, options);
    }
  }

  calRecordPaging() {
    const total = this.pageNumber * this.pageSize;
    this.currentRecord = total;
    if (total > this.totalItems) {
      this.currentRecord = this.totalItems;
    }
  }
}

export interface Pagination {
  pageSize?: number;
  totalItems?: number;
  pageNumber?: number;
  currentRecord?: number;
  isGenerate?: boolean;
  [propName: string]: any;
}

export class MetaDataPaging extends Page {
  keyword: string;
  startDate: Date;
  endDate: Date;
  constructor(options?: Pagination) {
    super(options);
  }
}

export class PaginationResponse<T> {
  totalItems: number;
  items: T[];
  pageSize: number;
  pageNumber: number;
}
