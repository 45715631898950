import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { HttpClientService } from '@intranet/index';

@Injectable({
  providedIn: 'root',
})
export class ProjectIterationService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/projectiteration';
  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getListCurrentFuture(projectId: string, successCallback: (params?: any) => void, failedCallback?: (error?: any) => void, completeCallback?: (params?: any) => void) {
    const url = '/get_list_current_future/' + projectId;
    this.getByUrl(url, null, successCallback, failedCallback, completeCallback);
  }

  getListByProjectId(projectId: string) {
    const url = this.hostUrl + '/get_list/' + projectId;
    return this.httpClient.getSync(url);
  }
}
