import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class TemplateEmailService extends BaseService {
  private readonly rootUrl = '/api/template-email';
  hostUrl: string = AppConfig.settings.API_URL + this.rootUrl;

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getByKey(key: any) {
    const url = this.hostUrl + `/get_by_key?key=` + key;
    return this.httpClient.get(url);
  }

  testTemplateEmail(request: any) {
    const url = `${this.rootUrl}/${request?.key}/tests`;
    return this.httpClient.postObservable(url, request).toPromise();
  }
}
