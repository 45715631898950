import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class WidgetSetupService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/widget-setup';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getByKey(key: any) {
    const url = this.hostUrl + `/get-by-key?key=` + key;
    return this.httpClient.get(url);
  }
}
