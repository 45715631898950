import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CDNService } from '@intranet/lib/data-access';
import {
  NewsflashConfirmation,
  NewsflashConfirmationBody,
  NewsflashDocument,
} from '@intranet/lib/data-access/models/newsflash.models';
import { NewsflashService } from '@intranet/lib/data-access/services/newsflash.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-newsflash-confirmation',
  templateUrl: './newsflash-confirmation.component.html',
  styleUrls: ['./newsflash-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewsflashConfirmationComponent implements OnInit {
  newsflashConfirmation: NewsflashConfirmation;

  remindTimeOptions = [1, 4, 8, 24]; // Hour unit

  /**
   * Plural unit config
   */
  textMapping: { [k: string]: string } = {
    '=1': '# hour',
    other: '# hours',
  };

  currentIndex = 1;

  previewPdf: Blob;

  previewMode = false;

  documents: NewsflashDocument[] = [];

  currentDocument: NewsflashDocument;

  currentTotalPages = 0;

  isLoading = false;

  readonly dateFormat = 'dd/MM/yyyy HH:mm';

  private get documentId(): string {
    return this.currentDocument?.id;
  }

  constructor(
    public readonly modalRef: NgbActiveModal,
    private readonly cdnService: CDNService,
    private readonly newsflashService: NewsflashService,
  ) {}

  ngOnInit(): void {
    this.documents = this.newsflashConfirmation?.documents;

    this.removePdfExtensionString();
    this.setNextUnconfirmedIndex();
    this.setCurrentDocument();
    this.getDocument();
  }

  remindMeLater(hour: number): void {
    this.modalRef.close();
  }

  confirm(): void {
    this.isLoading = true;
    const body: NewsflashConfirmationBody = {
      newsflashId: this.newsflashConfirmation.id,
      documentId: this.documentId,
    };

    this.newsflashService
      .confirmNewsflashDocument(body)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => {
        this.currentDocument.isConfirmed = true;
        this.currentDocument.confirmedDate = new Date().toISOString();
        this.currentIndex++;

        if (this.documents.every(d => d.isConfirmed)) {
          this.newsflashService.shouldFetchNewsflashs$.next();

          return this.modalRef.close();
        }
        if (this.currentIndex === this.documents.length) this.setNextUnconfirmedIndex();
        this.setCurrentDocument();
        this.getDocument();
      });
  }

  onIndexChange(index: number): void {
    this.currentIndex = index;
    this.setCurrentDocument();
    this.getDocument();
  }

  onPageChange(page: number): void {
    if (page === this.currentTotalPages) this.currentDocument.hasReadAll = true;
  }

  onPagesLoaded({ pagesCount }): void {
    this.currentTotalPages = pagesCount;
    if (this.currentTotalPages === 1) this.currentDocument.hasReadAll = true;
  }

  private removePdfExtensionString(): void {
    this.documents.forEach(i => (i.name = i.name.replace('.pdf', '')));
  }

  private setCurrentDocument(): void {
    this.currentDocument = this.documents[this.currentIndex];
  }

  private setNextUnconfirmedIndex(): void {
    this.currentIndex = this.documents.findIndex(d => !d.isConfirmed);
  }

  private getDocument(): void {
    this.cdnService.getBlobFileById(this.documentId).then(res => (this.previewPdf = res));
  }
}
