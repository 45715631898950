<ng-container *ngIf="widget">
  <nz-card [nzBordered]="false" [nzLoading]="loading">
    <div class="widget-header">
      <div class="widget-title">{{widget.data.title}}</div>
      <div class="widget-control widget-control-sm-fully" *ngIf="data.isDisplayGroups">
        <div>
          <nz-select  (ngModelChange)="modelDepartmentChanged($event)" nzShowSearch nzAllowClear
          nzPlaceHolder="Select a groups" [(ngModel)]="searchData.departmentIds">
            <nz-option *ngFor="let option of data.departmentGroups" [nzLabel]="option.name" [nzValue]="option.id">
            </nz-option>
          </nz-select>
        </div>
        <div>
          <nz-select nzMode="multiple" (ngModelChange)="modelChanged($event)" nzShowSearch nzAllowClear 
            nzPlaceHolder="Select a users" [(ngModel)]="searchData.userIds">
            <nz-option *ngFor="let option of data.users" [nzLabel]="option.fullName" [nzValue]="option.id">
            </nz-option>
          </nz-select>
        </div>
      </div>
    </div>

    <div class="asset-container">
      <ng-container *ngIf="data?.details?.length > 0 else emptyTemplate">
        <div *ngFor="let item of data.details" class="asset-item">
          <div class="asset-qr-code">
            <img src="data:image/png;base64, {{item.qrCode}}" type="base64" width="70px" height="70px" />
          </div>

          <div class="asset-info-item asset-information">
            <div>
              <span *ngIf="searchData.isDisplayDataManage" class="font-weight-bold">
                {{item.fullName}}
              </span>
              {{item.categoryName}}
            </div>
            <span class="font-weight-bold">{{item.productName}}</span>
          </div>
          
          <div class="asset-info-item asset-create-date">
            <span>{{item.createdDate | date:'dd/MM/yyyy'}}</span>
            <span class="font-weight-bold">{{item.quantity}}</span>
          </div>

        </div>
      </ng-container>
    </div>
  </nz-card>
</ng-container>

<ng-template #emptyTemplate>
  <nz-empty></nz-empty>
</ng-template>