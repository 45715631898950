import { Component, OnInit, OnDestroy } from '@angular/core';
import { SecurityHelper, Constants, UrlConstant, WebsiteEvent, AppInjector, WebHelper, DateHelper } from 'projects/intranet/hrm-lib';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Department, UserModel, COLUMN_TYPE } from '@intranet/lib/data-access';
import { CommonHelper } from '@intranet/lib/helpers';
import { Loading } from '@intranet/lib/settings';

@Component({
  selector: 'base-user-component',
  template: ` NO UI TO BE FOUND HERE! `,
})
export class BaseUserComponent implements OnInit {
  urlConstant = UrlConstant;
  currentUser: UserModel;
  isLogin: boolean;
  constructor() {
    this.getUserInfo();
  }
  ngOnInit() { }
  getUserInfo() {
    const authToken = SecurityHelper.getStoreAuthen();
    if (authToken) {
      this.currentUser = authToken;
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  }

}
