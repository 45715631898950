<nz-alert
  [nzType]="messageData.type"
  nzShowIcon
  *ngIf="messageData.isShow"
  [nzMessage]="messageData.title"
  nzCloseable
  [nzDescription]="messageData.content"
>
</nz-alert>
<router-outlet></router-outlet>
<ngk-loading></ngk-loading>
<ngx-spinner bdColor="rgba(103,103,103,0.6)" size="default" color="#06ee7d" type="ball-fussion" [fullScreen]="true">
  <p style="color: white">Loading...</p>
</ngx-spinner>
<ng-http-loader
  *ngIf="showLoading"
  [debounceDelay]="100"
  [extraDuration]="300"
  [minDuration]="1000"
  [backgroundColor]="'#06C3B7'"
  [opacity]="0.6"
  [filteredUrlPatterns]="filteredUrlPatterns"
  [filteredHeaders]="filteredHeaderPatterns"
>
</ng-http-loader>
