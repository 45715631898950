import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebsiteEvent {
  currentHeight = 0;
  private updateInfoSource = new Subject();
  updateInfoEvent = this.updateInfoSource.asObservable();

  private hiddenSalarySource = new Subject();
  hiddenSalaryEvent = this.hiddenSalarySource.asObservable();

  private reloadPageSource = new Subject();
  realoadPageEvent = this.reloadPageSource.asObservable();
  private sourceEventChangeHeight = new Subject<number>();
  eventChangeHeight = this.sourceEventChangeHeight.asObservable();
  private sourceEventChangeTitle = new BehaviorSubject<string>('');
  eventChangeTitle = this.sourceEventChangeTitle.asObservable();

  private reloadHeaderNotificationSource = new Subject();
  notificationHeaderEvent = this.reloadHeaderNotificationSource.asObservable();

  private reloadHeaderMessageSource = new Subject();
  messageHeaderEvent = this.reloadHeaderMessageSource.asObservable();

  private menuLeftSource = new Subject();
  menuLeftEvent = this.menuLeftSource.asObservable();

  private groupSourceData = new BehaviorSubject<string>('');
  get groupSourceDataEvent(): Observable<string> {
    return this.groupSourceData.asObservable();
  }

  constructor() { }

  hiddenSalary(isHide: boolean) {
    this.hiddenSalarySource.next(isHide);
  }
  emitHeaderNotificationEvent() {
    this.reloadHeaderNotificationSource.next();
  }
  emitHeaderMessageEvent() {
    this.reloadHeaderMessageSource.next();
  }
  emitUpdateInfoEvent() {
    this.updateInfoSource.next();
  }

  emitMenuLeftEvent() {
    this.menuLeftSource.next();
  }
  emitReloadPage(data: any) {
    setTimeout(() => {
      this.reloadPageSource.next(data);
    }, 800);
  }
  changeHeight(height: number) {
    this.sourceEventChangeHeight.next(height);
  }
  setTitle(title: any) {
    this.sourceEventChangeTitle.next(title);
  }
  setGroupSourceDataEvent(data: string) {
    this.groupSourceData.next(data);
  }
}
