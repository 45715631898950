import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Constants, HttpClientService } from '@intranet/index';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { CDNService } from './cdn-server.service';
import { Gift } from '../models/gift.model';

@Injectable({
  providedIn: 'root',
})
export class GiftApproveService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/gift-approve';

  constructor(httpClient: HttpClientService, private cdnService: CDNService) {
    super(httpClient);
  }

  async view(searchData: any, scb?: any, fcb?: any, ccb?: any) {
    return await this.getAll(searchData, scb, fcb, ccb);
  }

  async viewHistory(searchData: any, scb?: any, fcb?: any, ccb?: any) {
    const url = 'view-history';
    return await this.getByUrl(url, searchData, scb, fcb, ccb);
  }

  async getUserPoint(id: string, scb?: any, fcb?: any, ccb?: any) {
    const url = 'user-point';
    return await this.getByUrl(url, { id }, scb, fcb, ccb);
  }

  async approve(request: any) {
    const url = `${this.hostUrl}/approve`;
    return await this.httpClient.postSync(url, request);
  }

  async reject(request: any) {
    const url = `${this.hostUrl}/reject`;
    return await this.httpClient.postSync(url, request);
  }

  async redeemGift(gift: any) {
    const url = this.hostUrl + `/redeem-gift`;
    return await this.httpClient.postSync(url, gift);
  }
}
