import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';
import { Loading } from '@intranet/index';

@Injectable({
  providedIn: 'root',
})
export class DeliveryFormService extends BaseService {
  hostUrl: string = AppConfig.settings.API_URL + '/api/deliveryform';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  getByDeliveryId(id) {
    const url = this.hostUrl + `/${id}`;
    return this.httpClient.getSync(url);
  }

  updateStatus(params?: any, successCallback?: (params?: any) => void) {
    Loading.show();
    const url = 'update-status';
    return this.updateUrl(url, params, undefined, successCallback);
  }

  updateUser(params?: any, successCallback?: (params?: any) => void) {
    Loading.show();
    const url = '';
    return this.updateUrl(url, params, undefined, successCallback);
  }
}
