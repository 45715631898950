import { Pipe, PipeTransform } from '@angular/core';
import {
    Renderer2,
} from '@angular/core';
@Pipe({
    name: 'striphtml'
})

export class StripHtmlPipe implements PipeTransform {
    constructor(
        private renderer: Renderer2,
    ) {
    }
    transform(value: string): any {
        const template = this.renderer.createElement('template');
        template.innerHTML = value;

        return template.content.textContent;
    }
}