<modal-header [title]="headerTemplate" [activeModal]="activeModal">
  <ng-template #headerTemplate>
    <h4 class="modal-title">Histories of {{compensationHour?.description}}</h4>
  </ng-template>
</modal-header>
<modal-body>
  <ngx-datatable #myTable class="material grid-aluha" [rows]="compensationHourRequestHistories" [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50"
    [virtualization]="false" [scrollbarH]="true" rowHeight="auto">
    <ngx-datatable-column *ngFor="let col of columnMapping" prop="{{ col.ColumnName }}" name="{{ col.DisplayName }}" [canAutoResize]="col.AutoResize"
      [sortable]="col.Sortable">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <ng-container [ngSwitch]="col.ColumnName">
          <span *ngSwitchCase="'createdDate'">{{value | date: 'fullDate'}}</span>
          <div *ngSwitchCase="'status'">
            <span [ngClass]="compensationHourUserStatusColorEnum[compensationHourUserStatusEnum[value]]">
            {{compensationHourUserStatusEnum[value]}}
            </span>
          </div>
          <span *ngSwitchDefault>{{value}}</span>
        </ng-container>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</modal-body>
<modal-footer>
  <div class="w-100">
    <div class="d-flex flex-row justify-content-between">
      <div class="form-group ml-auto">
        <div class="float-right">
          <button class="btn btn-cancel" (click)="activeModal.close()"><i class="far fa-times-circle mr5"></i>Cancel</button>
        </div>
      </div>
    </div>
  </div>
</modal-footer>
