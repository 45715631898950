export enum GroupMemberSortEnum {
  JoinedDate = 1,
  Name = 2,
  TotalExperience = 3,
  TechStack = 4,
  Position = 5,
}

export enum GroupMemberSortOrderingEnum {
  Ascending = 1,
  Descending = 2,
}
