<nz-card [nzBordered]="true" [nzTitle]="model.title" *ngIf="model.title">
  <ng-container [ngTemplateOutlet]="uploadTpl"></ng-container>
</nz-card>

<ng-container [ngTemplateOutlet]="uploadTpl" *ngIf="!model.title"></ng-container>

<ng-template #uploadTpl>
  <div class="upload-files-component">
    <div *ngIf="!model.isReadonly"
      [className]="files && files.length < 1 ? 'upload-files' : 'upload-files upload-files-length'">
      <nz-upload *ngIf="!maxFiles || (maxFiles && files.length < maxFiles)" class="upload-data" nzName="uploadFiles"
        nzType="drag" [nzShowUploadList]="false" [nzCustomRequest]="handleUploadFiles" [nzMultiple]="multiple"
        [nzFileType]="limitFileType" [nzDisabled]="disabled">
        <p *ngIf="files && files.length < 1" class="ant-upload-drag-icon">
          <i nz-icon nzType="inbox"></i>
        </p>
        <p [className]="files && files.length < 1 ? 'ant-upload-text' : 'ant-upload-text-length'">Click or drag file to
          this area to upload</p>
      </nz-upload>
    </div>
    <ng-container *ngIf="files && files.length; else emptyFileList">
      <div class="document-container">
        <div class="row gutters" *ngFor="let document of getNoneImages()">
          <div class="col-12 document-item-container">
            <div (click)="click(document)" class="document-item-name">
              <div class="document-icon">
                <i *ngIf="document.fileType === FileExtensionType.IMAGE" class="icon-image" nz-icon nzType="file-image"
                  nzTheme="outline"></i>
                <i *ngIf="document.fileType === FileExtensionType.PDF" class="icon-pdf" nz-icon nzType="file-pdf"
                  nzTheme="outline"></i>
                <i *ngIf="document.fileType === FileExtensionType.EXCEL" class="icon-excel" nz-icon nzType="file-excel"
                  nzTheme="outline"></i>
                <i *ngIf="document.fileType === FileExtensionType.WORD" class="icon-word" nz-icon nzType="file-word"
                  nzTheme="outline"></i>
                <i *ngIf="document.fileType === FileExtensionType.VIDEO" nz-icon nzType="youtube" nzTheme="outline"></i>
                <i *ngIf="document.fileType === FileExtensionType.UNKNOWN" class="icon-unknown" nz-icon
                  nzType="file-unknown" nzTheme="outline"></i>
              </div>
              <div class="document-name">
                <span class="ml5">{{ document.fileName }}</span>
              </div>
            </div>
            <div class="document-item-action">
              <button type="button" class="text-info no-button fs14 pointer"
                *ngIf="document.fileType === FileExtensionType.IMAGE" (click)="preview(document)">
                <i nz-icon nzType="eye" nzTheme="outline"></i>
              </button>
              <button type="button" class="text-primary no-button fs14 pointer" (click)="downloadFile(document)">
                <i nz-icon nzType="download" nzTheme="outline"></i>
              </button>
              <button type="button" *ngIf="document.fileId && !model.isReadonly && document.isPerrmissonDelete"
                nz-popconfirm nzPopconfirmTitle="Are you sure delete this attachment?" nzPopconfirmPlacement="top"
                (nzOnConfirm)="deleteFile(document.fileId)" class="text-danger no-button fs14 pointer">
                <i nz-icon nzType="delete" theme="outline"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row gutters">
          <div class="col-12 d-flex">
            <div *ngFor="let document of getImages()" class="m-1 p-1">
              <div
                style="width: 110px; padding: 5px; border: solid 1px; border-radius: 5px; border: solid 1px #cbd4db; text-align: center;">
                <img width="100" height="100" src="{{getFileUrl(document.fileId)}}" />
                <div style="overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;">
                  <span>{{document.fileName}}</span>
                </div>
                <div>
                  <div class="document-item-action">
                    <button type="button" class="text-info no-button fs14 pointer"
                      *ngIf="document.fileType === FileExtensionType.IMAGE" (click)="preview(document)">
                      <i nz-icon nzType="eye" nzTheme="outline"></i>
                    </button>
                    <button type="button" class="text-primary no-button fs14 pointer" (click)="downloadFile(document)">
                      <i nz-icon nzType="download" nzTheme="outline"></i>
                    </button>
                    <button type="button" *ngIf="document.fileId && !model.isReadonly && document.isPerrmissonDelete"
                      nz-popconfirm nzPopconfirmTitle="Are you sure delete this attachment?" nzPopconfirmPlacement="top"
                      (nzOnConfirm)="deleteFile(document.fileId)" class="text-danger no-button fs14 pointer">
                      <i nz-icon nzType="delete" theme="outline"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nz-modal [nzVisible]="previewVisible" nzWidth="750" [nzContent]="modalContent" [nzFooter]="null"
        (nzOnCancel)="previewVisible = false">
        <ng-template #modalContent>
          <img [src]="previewImage" type="local" class="image-preview" />
        </ng-template>
      </nz-modal>
    </ng-container>
    <ng-template #emptyFileList>
      <nz-empty *ngIf="model.isReadonly" nzNotFoundContent="No Attachment(s)"></nz-empty>
    </ng-template>
  </div>
</ng-template>