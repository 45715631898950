export enum WidgetType {
  NONE = 0,
  BAR = 1,
  COLUMN = 2,
  PIE = 3,
  'None' = WidgetType.NONE,
  'Bar' = WidgetType.BAR,
  'Column' = WidgetType.COLUMN,
  'Pie' = WidgetType.PIE,
}

export enum ComparisonOperator {
  EQUAL = 1,
  LESSTHAN = 2,
  LESSTHANOREQUAL = 4,
  GREATERTHAN = 5,
  GREATERTHANOREQUAL = 6,
  NOTEQUAL = 7,
  CONTAINS = 8, // for strings
  STARTSWITH = 9, // for strings
  ENDSWITH = 10, // for strings
  '=' = ComparisonOperator.EQUAL,
  '<' = ComparisonOperator.LESSTHAN,
  '<=' = ComparisonOperator.LESSTHANOREQUAL,
  '>' = ComparisonOperator.GREATERTHAN,
  '>=' = ComparisonOperator.GREATERTHANOREQUAL,
  '<>' = ComparisonOperator.NOTEQUAL,
  'Contains' = ComparisonOperator.CONTAINS,
  'Starts with' = ComparisonOperator.STARTSWITH,
  'Ends with' = ComparisonOperator.ENDSWITH,
}

export enum ConditionalOperator {
  AND = 1,
  OR = 2,
  'And' = ConditionalOperator.AND,
  'Or' = ConditionalOperator.OR,
}

export enum GroupOperator {
  COUNT = 1,
  SUM = 2,
  'Count' = GroupOperator.COUNT,
  'Sum' = GroupOperator.SUM,
}

export enum SortDashboardField {
  VALUE = 1,
  LABEL = 2,
  'Value' = SortDashboardField.VALUE,
  'Label' = SortDashboardField.LABEL,
}

export enum DataSourceType {
  NONE = 0,
  PROJECT_ITEM_PRIORITY = 1,
  PROJECT_ITEM_TYPE = 2,
  PROJECT_ITEM_STATUS = 3,
  PROJECT_MEMBER = 4,
  PROJECT_ITEM_LIST = 5,
  PROJECT_ITERATION = 6,
}
