export enum BenefitCriteriaTypeEnum {
  YearOfSeniority = 1,
  Position = 2,
  Gender = 3,
  Child = 4,
  ContractType = 5
}

export enum BenefitCriteriaTypeConstEnum {
  YearOfSeniority = 'Year of seniority',
  Position = 'Position',
  Gender = 'Gender',
  Child = 'Child',
  ContractType = 'Contract type'
}
