import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonHelper } from '@intranet/lib/helpers/commonHelper';
@Component({
  selector: 'app-members-view',
  templateUrl: './members-view.component.html',
  styleUrls: ['./members-view.component.scss']
})
export class MembersViewComponent implements OnInit {
  @Input() headers: { value: string, classes: string }[] = [];
  @Input() memberData: any;
  @Input() format: { key: string, classes: string }[] = [];
  @Input() extraInfoForMemberNameTemplate?: TemplateRef<void>;
  @Input() extraInfoColumnName?: string;
  @Input() extraInfoColumnTemplate?: TemplateRef<void>;
  @Input() extraInfoColumnBootstrapWidth = 0;
  @Input() dataMaxHeight = 'calc(100vh - 380px);';
  @Input() dataOverflowYBehavior = 'auto';
  @Input() noDataText = 'No data';
  @Input() isLoading = false;

  @ViewChild('tableMemberView', { static: true }) tableMemberView: ElementRef;

  isShowBoxShadow = false;
  isFirstViewChecked = true;

  ngOnInit() {
    if (CommonHelper.isMobileView()) {
      this.isShowBoxShadow = true;
    }
  }

  handleScrollTable(data: any) {
    const targetElement = data?.target;
    if (targetElement) {
      this.isShowBoxShadow = (targetElement.scrollLeft + targetElement.clientWidth) < this.limitNumberToShowBoxShadow(targetElement.scrollWidth);
    }
  }

  private limitNumberToShowBoxShadow(currentWidth: number) {
    return (currentWidth * 85) / 100;
  }
}
