<ng-container *ngIf="widget">
  <nz-card [nzBordered]="false" [nzLoading]="loading">
    <div class="widget-header">
      <div class="widget-title">{{widget.data.title}}</div>

      <div class="widget-control widget-control-sm-fully" *ngIf="data">
        <div class="date-control">
          <nz-date-picker (ngModelChange)="onChangeFromDate($event)" nzPlaceHolder="From date" nzAllowClear='false' [nzFormat]="dateFormat"
            [ngModel]="searchData.fromDate" [nzShowToday]="true">
          </nz-date-picker>

          <nz-date-picker (ngModelChange)="onChangeToDate($event)" nzPlaceHolder="To date" nzAllowClear='false' [nzFormat]="dateFormat" [ngModel]="searchData.toDate"
            [nzShowToday]="true">
          </nz-date-picker>
        </div>

        <div>
          <label [ngClass]="{'pl-2': searchData.isDisplayDataManage }" nz-checkbox (ngModelChange)="modelMissingHoursChanged($event)"
            [(ngModel)]="searchData.isMissingHours">Missing hours</label>
          <!-- <app-toggle-check-box [ngClass]="{'pl-2': searchData.isDisplayDataManage }"
            title="Missing Hours" (valueChanges)="modelMissingHoursChanged($event)"></app-toggle-check-box> -->
          <nz-select class="widget-dashboard-select" (ngModelChange)="modelChanged($event)" nzShowSearch nzAllowClear nzPlaceHolder="Select a users"
            [(ngModel)]="searchData.userIds" *ngIf="searchData.isDisplayDataManage">
            <nz-option *ngFor="let option of data.users" [nzLabel]="option.fullName" [nzValue]="option.id"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>

    <ng-container *ngIf="data?.listDetails?.length > 0 else emptyTemplate">
      <ng-container *ngIf="data.isDisplayMonth">
        <app-widget-tracking-monthly [(listMonthly)]="data.listDetailMonths"></app-widget-tracking-monthly>
      </ng-container>
      <ng-container *ngIf="!data.isDisplayMonth">
        <app-widget-tracking-weekly [(listWeekly)]="data.listDetails"></app-widget-tracking-weekly>
      </ng-container>
    </ng-container>
  </nz-card>
</ng-container>


<ng-template #emptyTemplate>
  <nz-empty></nz-empty>
</ng-template>


<nz-modal [(nzVisible)]="isVisibleExplanationModal" (nzOnCancel)="closeExplanationModal()" [nzTitle]="dataSelected?.title">
  <ng-container *nzModalContent>
    {{dataSelected?.totalHours}} - {{dataSelected?.totalWorkingSetting}} (Accumulated hours - Standard hours)
  </ng-container>
  <ng-container *nzModalFooter></ng-container>
</nz-modal>
