export class BaseModel {
  id?: string;
  description?: string;
  createdDate?: Date;
  updatedDate?: Date;
  deletedDate?: Date;
  createdBy?: string;
  createdByName?: string;
  updatedBy?: string;
  updatedByName?: string;
  deletedBy?: string;
  deletedByName?: string;
  totalCount?: number;
  rowNumber?: number;
  isDeleted?: boolean;
  isEdit?: boolean | true;
  isDelete?: boolean | true;
  isApprove?: boolean | true;
}
