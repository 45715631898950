import { Component, OnInit, ViewChild, ElementRef, TemplateRef, ContentChild } from '@angular/core';
import { BaseAdminComponent } from '@intranet/lib/components';
import { ModalService, ModalSize } from '@intranet/lib/settings/services/modal.service';
import { NewsFeedTypeEnum, UserService } from '@intranet/lib/data-access';
import { NewsFeeds as NewsFeedsConstant, Constants } from '@intranet/index';
import { CreatePostModalComponent } from './create-post-modal/create-post-modal.component';

@Component({
  selector: 'app-newsfeed-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss'],
  styles: [],
})
export class CreatePostComponent extends BaseAdminComponent implements OnInit {
  public now: Date = new Date();
  constants = NewsFeedsConstant;
  workTime: any = null;
  constructor(private modalService: ModalService, private userService: UserService) {
    super();
    // this.title = '';
    setInterval(() => {
      this.now = new Date();
    }, 1);
  }

  getGreeting() {
    const data = this.constants.GREETING_MESSAGES;

    const hour = this.now.getHours();
    let greeting = '';
    for (const row of data) {
      if (hour >= row[0] && hour <= row[1]) {
        greeting = row[2].toString();
      }
    }

    return `${greeting}, ${this.currentUser?.fullName}!`;

  }
  async ngOnInit() {
    await this.loadWorkTime();

  }

  async openComposeEditor(typeId: NewsFeedTypeEnum) {
    // this.typeId = typeId || NewsFeedsConstant.FEED_TYPES.WALL;
    // this.createFeedModalTemplate.elementRef.nativeElement.
    // this.createFeedModalTemplate.elementRef.nativeElement
    const activeModal = this.modalService.open(CreatePostModalComponent, { windowClass: 'create-post-modal', size: ModalSize.xl });
    activeModal.componentInstance.typeId = typeId;
    activeModal.componentInstance.activeModal = activeModal;
    activeModal.result.then(
      result => { },
      reason => { },
    );
  }
  async loadWorkTime() {
    this.workTime = await this.userService.getWorkTime();
    // console.log('workTime', workTime.startTime);
  }
}
