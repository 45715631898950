import { Component, Input, OnInit } from '@angular/core';
import { UrlConstant } from '@intranet/lib/config';
import { ReviewConstants } from '@intranet/lib/config/review.constants';
import { CreateReviewSummaryDiscuss, ReviewFormAppraiserStatusEnum, ReviewFormAppraiserStatusFormEnum, ReviewFormMemberStatusEnum, ReviewFormMemberStatusFormEnum, ReviewPerformanceSummary, ReviewProgressFormTotal, ReviewSummaryDiscuss, ReviewSummaryDiscussService, ReviewSummaryService, UpdateReviewSummaryDiscuss } from '@intranet/lib/data-access';
import { ReviewFormUserService } from '@intranet/lib/data-access/services/review/review-form.service-user';
import { SecurityHelper } from '@intranet/lib/helpers';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import { Toastr } from '@intranet/lib/settings';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-review-form-discussion-modal',
  templateUrl: './review-form-discussion-modal.component.html',
  styleUrls: ['./review-form-discussion-modal.component.scss']
})
export class ReviewFormDiscussionModalComponent implements OnInit {
  @Input() isClientPage = false;
  @Input() formMemberId: string;
  modalTitleString = '[Summary] ';
  modalTitle = this.modalTitleString;
  activeModal: NgbActiveModal;
  formMemberInfo: any;
  summaryInfo: ReviewPerformanceSummary;
  currentComment: string;
  progressFormTotal: ReviewProgressFormTotal;
  currentUser: any;
  isLoading = false;

  listCommentElementId = 'list-comment';

  get listCommentElement(): HTMLElement {
    return document.getElementById(this.listCommentElementId);
  }

  get comments() {
    return this.summaryInfo?.reviewPerformanceSummaryDiscusses;
  }

  displayTable = {
    parent: this,
    reviewFormAppraiserStatusEnum: ReviewFormAppraiserStatusEnum,
    reviewFormAppraiserStatusFormEnum: ReviewFormAppraiserStatusFormEnum,
    reviewFormMemberStatusEnum: ReviewFormMemberStatusEnum,
    reviewFormMemberStatusFormEnum: ReviewFormMemberStatusFormEnum,
  };

  constructor(modalRef: NgbActiveModal,
    private reviewFormUserService: ReviewFormUserService,
    private reviewSummaryService: ReviewSummaryService,
    private reviewSummaryDiscussService: ReviewSummaryDiscussService) {
    this.activeModal = modalRef;
  }

  ngOnInit() {
    this.currentUser = SecurityHelper.getStoreAuthen();

    if (this.formMemberId) {
      this.getFormMemberInfo();
      this.getDiscussComments().then(() => {
        setTimeout(() => this.scrollListCommentToBottom(), 500);
      });
    }
  }

  async getFormMemberInfo() {
    try {
      const memberInfo = await this.reviewFormUserService.getMemberFormInfo(this.formMemberId);

      if (memberInfo) {
        this.formMemberInfo = memberInfo;
        this.processInfo();

        const fullname = this.formMemberInfo?.user?.fullName;
        const title = this.formMemberInfo.reviewPerformanceForm?.title;
        if (fullname) {
          this.modalTitle = `${this.modalTitle} ${title} for ${fullname}`;
        }
      }
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
  }

  async loadProgressFormTotal() {
    this.progressFormTotal = await this.reviewFormUserService.getProgresForm({
      reviewPerformanceFormMemberId: this.formMemberId
    });
  }

  async getDiscussComments(noLoading: boolean = false) {
    try {
      const summaryInfo = await this.reviewSummaryService.view(this.formMemberId, noLoading);

      if (summaryInfo) {
        this.summaryInfo = summaryInfo;

        this.processComments();

        this.scrollListCommentToBottom();
      }
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
  }

  async createComment() {
    try {
      this.currentComment = this.currentComment?.trim();
      if (!this.summaryInfo?.id) {
        Toastr.error('Could not create new comment.');
        return;
      }

      if (!this.currentComment) {
        Toastr.error('A comment can not be empty.');
        return;
      }

      this.isLoading = true;

      const createCommentData = new CreateReviewSummaryDiscuss(this.summaryInfo.id, this.currentComment);

      await this.reviewSummaryDiscussService.createComment(createCommentData, true);

      this.currentComment = '';

      await this.getDiscussComments(true);
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  async updateComment(commentData: ReviewSummaryDiscuss) {
    try {
      if (!commentData?.isEditable) {
        Toastr.error('Can not update this comment.');
        return;
      }

      const commentValue = commentData.tempComment.trim();
      if (!commentValue) {
        Toastr.error('A comment can not be empty.');
        return;
      }

      this.isLoading = true;

      const updateCommentData = new UpdateReviewSummaryDiscuss(commentData.id, commentValue);

      await this.reviewSummaryDiscussService.updateComment(updateCommentData, true);

      commentData.isEditing = false;

      await this.getDiscussComments(true);
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  cancelUpdate(comment: ReviewSummaryDiscuss) {
    if (comment.isEditing) {
      comment.isEditing = false;
    }
  }

  closePopup() {
    this.activeModal.close();
  }

  openDashboard(employeeId: string) {
    if (employeeId && !this.isClientPage) {
      const url = UrlConstant.PROFILE_PAGE + '?employeeId=' + employeeId;
      window.open(url, '_blank');
    }
  }

  navigateOldReviewForm() {
    const rootUrl = this.isClientPage ? ReviewConstants.ReviewDetailPageClientUrl : ReviewConstants.ReviewDetailPageUrl;

    if (this.formMemberInfo?.reviewPerformanceFormMemberOldId) {
      window.open(rootUrl + `?id=${this.formMemberInfo.reviewPerformanceFormMemberOldId}`, '_blank');
    }
  }

  editComment(commentData: ReviewSummaryDiscuss) {
    if (commentData) {
      commentData.isEditing = commentData.isEditable;
    }
  }

  private processInfo() {
    if (this.formMemberInfo?.reviewPerformanceFormAppraisers?.length > 0) {
      for (const appraiser of this.formMemberInfo.reviewPerformanceFormAppraisers) {
        if (appraiser.departmentGroup && appraiser.user?.teamInfo?.length > 0) {
          appraiser.departmentGroup.positionName = appraiser.user.teamInfo.find(ti => ti.departmentId === appraiser.departmentGroup.id)?.positionName;
        }
      }
    }
  }

  private processComments() {
    const comments = this.summaryInfo?.reviewPerformanceSummaryDiscusses;
    const users = this.summaryInfo?.users;

    if (comments?.length > 0) {
      for (const comment of comments) {
        comment.isEditing = false;
        comment.isEditable = comment.userId && comment.userId === this.currentUser?.id;

        comment.tempComment = comment.comment;

        comment.user = users?.find(u => u.id === comment?.userId);
      }
    }
  }

  private scrollListCommentToBottom() {
    if (this.listCommentElement) {
      setTimeout(() => {
        this.listCommentElement.scroll({
          top: this.listCommentElement.offsetHeight
        });
      }, 300);
    }
  }
}
