<div class="user-modal-custom">
  <modal-header [activeModal]="modal" title="Book a Meeting"></modal-header>
  <modal-body>
    <div class="row justify-content-center">
      <form class="col-12" (ngkSubmit)="submit$.next()" novalidate #frm="ngForm" [ngkForm]="true">
        <div class="form-row">
          <div class="form-group col-12">
            <label class="required">Title</label>
            <input
              type="text"
              class="form-control"
              name="title"
              placeholder="Enter title"
              [(ngModel)]="meetingRoom.title"
              required
            />
          </div>
          <div class="form-group col-xl-6 col-md-6 col-lg-6 col-sm-12">
            <label class="required">From date</label>
            <nz-date-picker
              nzAllowClear="false"
              nzFormat="dd-MM-yyyy HH:mm"
              [(ngModel)]="meetingRoom.fromTime"
              name="from"
              (ngModelChange)="ngModelChangeStart($event)"
              [nzDisabledDate]="disabledStartDate"
              [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValue, nzFormat: 'HH:mm a', nzMinuteStep: 10, nzUse12Hours: false }"
            >
            </nz-date-picker>
          </div>
          <div class="form-group col-xl-6 col-md-6 col-lg-6 col-sm-12">
            <label class="required">To date</label>
            <nz-date-picker
              nzAllowClear="false"
              nzFormat="dd-MM-yyyy HH:mm"
              [(ngModel)]="meetingRoom.toTime"
              name="to"
              [nzDisabledDate]="disabledEndDate"
              [nzDisabledTime]="disabledDateTime"
              [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValue, nzFormat: 'HH:mm a', nzMinuteStep: 10, nzUse12Hours: false }"
            >
            </nz-date-picker>
          </div>

          <!-- Room -->
          <div class="form-group col-xl-12 col-md-12 col-lg-12 col-sm-12 m-b-xxs">
            <label class="required">Group/Department</label>
            <select-search
              name="Group"
              [(ngModel)]="meetingRoom.groupId"
              [data]="departments"
              (ngModelChange)="loadEmployee()"
              textField="name"
              valueField="id"
              placeholder="Select"
              required
            >
            </select-search>
          </div>

          <div class="form-group col-xl-12 col-md-12 col-lg-12 col-sm-12 m-b-xxs">
            <label class="required">Room</label>
            <select-search
              name="room"
              [(ngModel)]="meetingRoom.roomId"
              [data]="rooms"
              textField="name"
              valueField="id"
              placeholder="Select"
              required
            >
            </select-search>
          </div>
          <!-- End Room -->

          <div class="form-group col-12">
            <label class="mr10 m-b-sm">Attendees</label>
            <nz-select
              name="attendees"
              nzMode="multiple"
              nzPlaceHolder="Please select participants"
              [(ngModel)]="members"
              [nzCustomTemplate]="multipleTemplate"
            >
              <nz-option
                *ngFor="let emp of employees"
                [nzLabel]="emp.fullName + ' (' + emp.email + ')'"
                [nzValue]="emp"
                nzCustomContent
              >
                <span> {{ emp.fullName }} ({{ emp.email }})</span>
              </nz-option>
            </nz-select>
            <ng-template #multipleTemplate let-selected>
              <span class="ant-select-selection-item-content fs-13">
                {{ selected.nzLabel }}
              </span>
            </ng-template>
          </div>

          <!-- Repeat -->
          <div class="form-group col-xl-12 col-md-12 col-lg-12 col-sm-12">
            <label>Repeat</label><br />
            <nz-radio-group name="repeat" [(ngModel)]="meetingRoom.occurType" nzButtonStyle="solid">
              <label *ngFor="let item of repeatTypes" nz-radio-button [nzValue]="item.id">{{ item.name }}</label>
            </nz-radio-group>
          </div>

          <div class="form-group col-xl-12 col-md-12 col-lg-12 col-sm-12" *ngIf="meetingRoom.occurType">
            <label>Repeat every</label>
            <nz-input-number
              [nzMin]="1"
              type="text"
              name="step"
              placeholder="Enter Step"
              [(ngModel)]="meetingRoom.step"
              required
              [nzStep]="1"
            ></nz-input-number>
          </div>

          <div class="form-group col-xl-6 col-md-6 col-lg-6 col-sm-12" *ngIf="meetingRoom.occurType">
            <label class="required">End Repeat</label>
            <nz-date-picker
              nzFormat="dd-MM-yyyy"
              [(ngModel)]="meetingRoom.untilDate"
              name="untilDate"
              [nzDisabledDate]="disabledUntilDate"
              required
            >
            </nz-date-picker>
          </div>
          <!-- End Repeat -->

          <div class="form-group col-12">
            <label>Description</label>
            <textarea
              name="description"
              row="2"
              [(ngModel)]="meetingRoom.description"
              class="form-control"
              placeholder="Write your description"
            ></textarea>
          </div>

          <!-- <div class="form-group col-xl-6 col-md-6 col-lg-6 col-sm-12" *ngIf="meetingRoom.isSendMail">
          <label class="required">Alter Before</label>
          <nz-select name="altertime" nzPlaceHolder="Alter time ..." [(ngModel)]="meetingRoom.alertDuration" nzAllowClear="true" nzShowSearch="true">
            <nz-option *ngFor="let item of timeAlters" [nzValue]="item.id" [nzLabel]="item.name"></nz-option>
          </nz-select>
        </div> -->
          <div class="form-group col-xl-6 col-md-6 col-lg-6 col-sm-12">
            <!-- <label *ngIf="meetingRoom.isSendMail">&nbsp;</label><br /> -->
            <label class="label-check" name="sendmail" nz-checkbox [(ngModel)]="meetingRoom.isSendMail">Send Notify Mail</label>
            <label
              class="label-check"
              *ngIf="meetingRoom.id"
              name="onlysendnew"
              nz-checkbox
              [(ngModel)]="meetingRoom.onlySendNewJoiner"
              >Send only new participants</label
            >
          </div>

          <div class="form-group col-12">
            <div class="row justify-content-end p-r-sm">
              <create-update-button [isCancel]="true" [isUpdate]="meetingRoom.id" (cancel)="modal.close(false)">
              </create-update-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </modal-body>
</div>
