import { NgModule } from '@angular/core';
import { HasPermissionDirective } from './has-permission.directive';
import { RemoveHostDirective } from './remove-host.directive';
import { ImgDirective } from './img.directive';
import { HrefPreventDefaultDirective } from './href-prevent-default.directive';
import { RemoveEmptyMenuDirective } from './remove-empty-menu.directive';
import { AgGridResizeDirective } from './ag-grid-resize.directive';

import {
  NgkDatePipe,
  RemainDatePipe,
  RemainDate2Pipe,
  ServerDatePipe,
  FilterUserPipe,
  NgkDatePipe2,
  StatusNamePipe,
  SetDataPipe,
  ColorMatchPipe,
  PercentMatchPipe,
  NumberFormatPipe,
  MinuteToHourPipe,
  CheckRolePipe,
  ThousandSuffixesPipe,
  StripHtmlPipe,
  ShortcodeToHtmlPipe,
  SafeHtmlPipe,
  PluckPipe,
  DateToDayPipe,
  CurrencyFormatPipe,
} from '../pipes';
import { NgxColumnFixedDirective } from './ngx-column-fixed.directive';
import { NumberDirective } from './numbers-only.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { HighlightDirective } from './search-highlight.directive';
import { HasOneOfPermissionsDirective } from './has-permissions.directive';
import { DayOfWeekPipe } from '../pipes/day-of-week.pipe';
import { PurchaseDetailOptionPipe } from '../pipes/purchase-detail-option.pipe';
import { BenefitCriteriaValuePipe } from '../pipes/benefit-criteria-value.pipe';
import { PositiveNumberDirective } from './positive-number.directive';
import { ReviewTemplateViewPipe } from '../pipes/review-template-view.pipe';
import { PastDateByTextPipe } from '../pipes/past-date-by-text.pipe';

@NgModule({
  declarations: [
    HasPermissionDirective,
    RemoveHostDirective,
    ImgDirective,
    HrefPreventDefaultDirective,
    RemoveEmptyMenuDirective,
    AgGridResizeDirective,
    NgkDatePipe,
    RemainDatePipe,
    RemainDate2Pipe,
    ServerDatePipe,
    FilterUserPipe,
    NgkDatePipe2,
    NgxColumnFixedDirective,
    StatusNamePipe,
    SetDataPipe,
    ColorMatchPipe,
    PercentMatchPipe,
    NumberDirective,
    NumberFormatPipe,
    MinuteToHourPipe,
    CheckRolePipe,
    ThousandSuffixesPipe,
    StripHtmlPipe,
    ShortcodeToHtmlPipe,
    SafeHtmlPipe,
    PluckPipe,
    DateToDayPipe,
    ClickOutsideDirective,
    HighlightDirective,
    HasOneOfPermissionsDirective,
    PurchaseDetailOptionPipe,
    DayOfWeekPipe,
    BenefitCriteriaValuePipe,
    CurrencyFormatPipe,
    PositiveNumberDirective,
    ReviewTemplateViewPipe,
    PastDateByTextPipe
  ],
  exports: [
    HasPermissionDirective,
    RemoveHostDirective,
    ImgDirective,
    HrefPreventDefaultDirective,
    RemoveEmptyMenuDirective,
    AgGridResizeDirective,
    NgkDatePipe,
    RemainDatePipe,
    ServerDatePipe,
    RemainDate2Pipe,
    FilterUserPipe,
    NgkDatePipe2,
    NgxColumnFixedDirective,
    StatusNamePipe,
    SetDataPipe,
    ColorMatchPipe,
    PercentMatchPipe,
    NumberDirective,
    NumberFormatPipe,
    MinuteToHourPipe,
    CheckRolePipe,
    ThousandSuffixesPipe,
    StripHtmlPipe,
    ShortcodeToHtmlPipe,
    SafeHtmlPipe,
    PluckPipe,
    DateToDayPipe,
    ClickOutsideDirective,
    HighlightDirective,
    HasOneOfPermissionsDirective,
    PurchaseDetailOptionPipe,
    DayOfWeekPipe,
    BenefitCriteriaValuePipe,
    CurrencyFormatPipe,
    PositiveNumberDirective,
    ReviewTemplateViewPipe,
    PastDateByTextPipe
  ],
  providers: [],
})
export class ShareDirectiveModule { }
