import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ReviewConstants } from '@intranet/lib/config/review.constants';
import { ReviewFormMemberStatusFormEnum } from '@intranet/lib/data-access';

@Component({
  selector: 'app-review-form-members-mobile',
  templateUrl: './review-form-members-mobile.component.html',
  styleUrls: ['./review-form-members-mobile.component.scss']
})
export class ReviewFormMembersMobileComponent implements OnInit {
  @Input() reviewForms: any[] = [];
  @Input() avatarSize: number = 40;
  @Input() actionButtonsTpl: TemplateRef<any> = null;

  formMemberStatusFormDisplay = ReviewConstants.FormMember.StatusForm.Display;
  formMemberStatusFormEnum = ReviewFormMemberStatusFormEnum;

  constructor() { }

  ngOnInit() {
  }

}
