export enum FieldTypeEnum {
  NONE = 0,
  BOOLEAN_DATA = 1,
  INTEGER_DATA = 2,
  TEXT_DATA = 3,
  DATE_DATA = 4,
  TIME_DATA = 5,
  DATE_TIME_DATA = 6,
  INVISIBLE_DATA = 7,
  PRIMARYKEY_DATA = 8,
  FOREIGNKEY_DATA = 9,
  FLOAT_DATA = 10,
}
