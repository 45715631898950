import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constants, UrlConstant } from '@intranet/lib/config';
import { Department, EventCalendar, NewsFeed, NewsFeedTypeEnum, PolicyDetail, SearchWallNewsFeed, UserModel, WallNewsFeedService } from '@intranet/lib/data-access';
import { MeetingBookingRoom } from '@intranet/lib/data-access/models/meeting-booking-room';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit {
  users: UserModel[];
  feeds: NewsFeed[];
  annoucements: NewsFeed[];
  departments: Department[];
  events: EventCalendar[];
  bookings: MeetingBookingRoom[];
  policies: PolicyDetail[];
  keyWord: string;
  searchData: SearchWallNewsFeed = new SearchWallNewsFeed();
  showSearchResult: boolean;
  delayTimer: any;
  urlConstant = UrlConstant;
  isLoading = false;

  constructor(private activatedRoute: ActivatedRoute, private newsFeedService: WallNewsFeedService) {
    this.searchData.pageNumber = 1;
    this.searchData.pageSize = Constants.PAGE_SIZE.FEED_SEARCH;
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.keyword) {
        this.searchData.keyWord = params.keyword.trim();
        this.onSearch();
      }
    });
  }

  ngOnInit() {

  }

  onSearch(): void {
    if (!this.searchData.keyWord || this.searchData.keyWord.length === 0 || this.searchData.keyWord.trim().length === 0) {
      return;
    }

    this.isLoading = true;
    this.newsFeedService.search(this.searchData, (res) => {
      this.isLoading = false;
      if (res && res.newsFeeds && res.users) {
        this.users = res.users;
        this.feeds = res.newsFeeds.filter(item => item.newsFeedTypeId !== NewsFeedTypeEnum.ANNOUNCEMENT);
        this.annoucements = res.newsFeeds.filter(item => item.newsFeedTypeId === NewsFeedTypeEnum.ANNOUNCEMENT);
        this.events = res.events;
        this.bookings = res.bookings;
        this.departments = res.departments;
        this.policies = res.policies;
        this.showSearchResult = true;
      }
    }).catch(() => this.isLoading = false);
  }
}
