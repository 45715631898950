import { Injectable } from '@angular/core';
import { HttpClientService } from '@intranet/lib/http/http-client';
import { Observable } from 'rxjs';
import { ReviewForm } from '../../models/review/review-form.model';
import { SearchReviewFormMember } from '../../models/search.model';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class ReviewFormService extends BaseService {
  hostUrl = '/api/review-form';

  constructor(httpClient: HttpClientService) {
    super(httpClient);
  }

  // Get users who want to clone to
  getDestinationMembers(reviewPerformanceFormMemberId: string): Observable<any> {
    return this.httpClient.getObservable(
      `${this.hostUrl}/clone-members-destination?reviewPerformanceFormMemberId=${reviewPerformanceFormMemberId}`,
    );
  }

  cloneCriteria(payload: any): Observable<void> {
    return this.httpClient.postObservable(`${this.hostUrl}/clone-criteria`, payload);
  }

  getFormById(searchData: SearchReviewFormMember): Promise<ReviewForm> {
    const url = `${this.hostUrl}/get-by-id`;
    return this.httpClient.getObservable(url, searchData).toPromise();
  }

  view(searchData: any): Promise<any> {
    return this.httpClient.getObservable(this.hostUrl, searchData).toPromise();
  }

  create(data: ReviewForm): Promise<ReviewForm> {
    return this.httpClient.postObservable(this.hostUrl, data).toPromise();
  }

  update(data: ReviewForm): Promise<ReviewForm> {
    return this.httpClient.putObservable(this.hostUrl, data).toPromise();
  }

  updateStatus(data: ReviewForm): Promise<ReviewForm> {
    const url = `${this.hostUrl}/status`;
    return this.httpClient.putObservable(url, data).toPromise();
  }

  delete(id: string): Promise<void> {
    const url = `${this.hostUrl}?id=${id}`;
    return this.httpClient.deleteObservable(url).toPromise();
  }

  updateFormMembers(data: any): Promise<void> {
    const url = `${this.hostUrl}/form-members`;
    return this.httpClient.putObservable(url, data).toPromise();
  }

  getMembers(params?: any): Promise<any[]> {
    const url = `${this.hostUrl}/form-members`;
    return this.httpClient.getObservable(url, params).toPromise();
  }

  getAppraisers(formMemberId?: string): Promise<any[]> {
    let url = `${this.hostUrl}/form-appraisers`;

    if (formMemberId) {
      url += `?formMemberId=${formMemberId}`;
    }

    return this.httpClient.getObservable(url).toPromise();
  }

  getPeers(formMemberId: string): Observable<any[]> {
    return this.httpClient.getObservable(`${this.hostUrl}/form-peers?formMemberId=${formMemberId}`);
  }

  rejectFormByMemberId(memberId: string, reason: string): Promise<void> {
    const url = `${this.hostUrl}/reject`;
    return this.httpClient.putObservable(url, { memberId, reason }).toPromise();
  }

  getReviewFormDropdownToCloneRow(request: any) {
    const url = `${this.hostUrl}/clone-dropdowns`;
    return this.httpClient.getObservable(url, request).toPromise();
  }
}
