<div class="create-feed-container poll-detail">
  <form (ngkSubmit)="onSubmit()" #frm="ngForm" [ngkForm]="true">
    <div class="new-postbox">
      <div class="w-100">
        <ng-template *ngIf="!canBack then generalConfiguration"></ng-template>
        <ng-template *ngIf="!canBack then pollConfiguration"></ng-template>
        <ng-template *ngIf="canBack then postConfiguration"></ng-template>
      </div>
    </div>
  </form>
</div>

<ng-template #generalConfiguration>
  <div class="d-flex flex-row">
    <div class="flex-avatar">
      <app-avatar *ngIf="currentUser" [size]="50" [avatarSource]="currentUser?.avatarUrl" [avatarText]="currentUser?.fullName"></app-avatar>
    </div>
    <div class="flex-privacy d-flex flex-column flex-grow-1 ml-2">
      <h5>{{ currentUser?.fullName }}</h5>
      <div class="d-flex flex-row">
        <div class="form-group publicity-level">
          <select-search name="publicity-level" required [(ngModel)]="feed.publicityLevel" [data]="publicityLevels" textField="name" valueField="id"
            placeholder="Publicity level ...">
          </select-search>
        </div>
        <div class="form-group department-groups">
          <nz-select *ngIf="isPrivateGroup()" class="form-select" name="department-groups" nzMode="multiple" [nzMaxMultipleCount]="4" nzPlaceHolder="Group/Department"
            [(ngModel)]="feed.departments">
            <nz-option *ngFor="let item of departmentGroups" [nzLabel]="item.name" [nzValue]="item.id">
            </nz-option>
          </nz-select>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <input nz-input placeholder="What's on your mind, bro?" #title="ngModel" required [(ngModel)]="feed.title" nzSize="large" name="title"
          [ngModelOptions]="{ standalone: true }" />
        <div *ngIf="(title.invalid && (title.dirty || title.touched)) || (formError && formError.title)" class="alert alert-danger">
          What's on your mind, bro?
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col form-group">
      <app-feed-editor #editor [(preview)]="feed.contentHtml" [(content)]="feed.description" [editorConfig]="editorConfig" placeholder="Describe something more"
        [isLoading]="isLoading" [(hashtags)]="feed.hashtags" [(mentions)]="feed.mentions">
      </app-feed-editor>
      <div *ngIf="formError && formError.description" class="alert alert-danger">
        Describe something more
      </div>
    </div>
  </div>
</ng-template>

<ng-template #pollConfiguration>
  <nz-collapse>
    <nz-collapse-panel [nzExtra]="extraTpl" *ngFor="let question of feed.pollQuestions; index as questionIndex" [nzHeader]="'Question #' + (questionIndex + 1)"
      nzActive="true">
      <div class="poll-question">
        <div class="form-group row">
          <div class="col-sm-12">
            <div class="">
              <textarea required rows="4" nz-input [(ngModel)]="question.content" [ngModelOptions]="{ standalone: true }"></textarea>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12">
            <div class="options">
              <label>Options</label>
              <div *ngFor="let item of question.options; index as optionIndex" class="option row">
                <div class="col-12 d-inline-flex mb-2">
                  <div class="type">
                    <label *ngIf="question.isMultipleChoice" nz-checkbox nzDisabled [ngModel]="true" [ngModelOptions]="{ standalone: true }"></label>
                    <label *ngIf="!question.isMultipleChoice" nz-radio nzDisabled [ngModel]="true" [ngModelOptions]="{ standalone: true }"></label>
                  </div>
                  <div class="content">
                    <input required maxlength="2000" [(ngModel)]="item.title" [placeholder]="'Please enter your option #' + (optionIndex + 1)"
                      [ngModelOptions]="{ standalone: true }" class="form-control" />
                  </div>
                  <a class="remove-icon" (click)="onRemoveOption(question, item)">
                  </a>
                </div>
              </div>
              <div class="form-row">
                <div class="option-add col">
                  <a class="add-option" (click)="onAddOption(question)">+ Add Option</a>
                </div>
                <div class="option-add col text-right float-right">
                  <a class="configure-option" nz-popover nzPopoverTrigger="click"
                    [nzPopoverContent]="popoverOptionConfiguration" nzPopoverPlacement="left">Poll Options</a>
                </div>
                <ng-template #popoverOptionConfiguration>
                  <div class="row">
                    <div class="form-group col-12">
                      <label nz-checkbox [(ngModel)]="question.allowNote" name="allowNote" [ngModelOptions]="{ standalone: true }">Allow Note</label>
                    </div>
                    <div class="form-group col-12">
                      <label nz-checkbox [(ngModel)]="question.isMultipleChoice" name="isMultipleChoice" [ngModelOptions]="{ standalone: true }">Multiple Choices</label>
                    </div>
                    <div class="form-group col-12" *ngIf="question.isMultipleChoice">
                      <label for="description">Maximum of votes</label>
                      <nz-input-number [nzPlaceHolder]="'Maximum of votes'" [(ngModel)]="question.maxVote" [nzMax]="question.options.length"
                        [ngModelOptions]="{ standalone: true }" [nzMin]="1" [nzStep]="1">
                      </nz-input-number>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #extraTpl>
        <i class="delete" (click)="onRemoveQuestion(question)"></i>
      </ng-template>
    </nz-collapse-panel>
  </nz-collapse>
  <div class="form-row">
    <div class="col form-group">
      <app-internal-upload-file [model]="modelUpload"></app-internal-upload-file>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col mt-2">
      <div class="option-add">
        <button type="button" class="btn btn-primary btn-add-question w-100" (click)="onAddQuestion()">
          <img src="assets/images/front/shortcut/add.svg" type="local" /> Add Question</button>
      </div>
    </div>

    <div class="form-group col mt-2">
      <div class="option-add">
        <button type="button" class="btn btn-primary btn-configuration w-100" (click)="showPostConfiguration()">
          <img src="assets/images/front/shortcut/setting.svg" type="local" />
          Post Configuration</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #postConfiguration>
  <div class="form-row">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.allowLike" name="allowLike" [ngModelOptions]="{ standalone: true }">Allow
        like</label>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.allowComment" name="allowComment" [ngModelOptions]="{ standalone: true }">Allow comment</label>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.isShow" name="isShow" [ngModelOptions]="{ standalone: true }">Visible</label>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.isSendNotification" name="isSendNotification" [ngModelOptions]="{ standalone: true }">Send Email Notification</label>
    </div>
  </div>

  <div class="form-row" *hasPermission="['wallnewsfeed','createannouncement']">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.isImportant" name="isImportant" [ngModelOptions]="{ standalone: true }">Highlight</label>
    </div>
  </div>

  <div class="form-row" *hasPermission="['wallnewsfeed','createannouncement']">
    <div class="form-group col" *ngIf="feed.isImportant">
      <label for="period">Highlight Begin Date</label>
      <nz-date-picker [nzFormat]="dateFormat" [(ngModel)]="feed.beginHighlightDate" (ngModelChange)="onChangeHighlightBeginDate($event)" [nzShowToday]="true">
      </nz-date-picker>
    </div>

    <div class="form-group col" *ngIf="feed.isImportant">
      <label for="period">Highlight End Date</label>
      <nz-date-picker [nzFormat]="dateFormat" [(ngModel)]="feed.endHighlightDate" (ngModelChange)="onChangeHighlightEndDate($event)"
        [nzDisabledDate]="disableEndHighlighDate" [nzShowToday]="true"></nz-date-picker>
    </div>
  </div>


  <div class="form-row">
    <div class="form-group col">
      <label nz-checkbox [(ngModel)]="feed.allowViewInstanceResult" name="allowViewInstanceResult" [ngModelOptions]="{ standalone: true }">View Instance Results</label>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group  col-md-4">
      <label for="durationChange">Duration of Change</label>
      <nz-input-number [nzPlaceHolder]="'Change result duration (Min)'" [(ngModel)]="feed.durationChange" [ngModelOptions]="{ standalone: true }" [nzMin]="1"
        [nzStep]="1"></nz-input-number>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col">
      <label for="period">Vote Start Date</label>
      <nz-date-picker nzFormat="dd-MM-yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep:10,nzUse12Hours:false  }" [(ngModel)]="feed.beginResultDate"
        (ngModelChange)="onChangeResultBeginDate($event)" [nzShowToday]="true">
      </nz-date-picker>
    </div>
    <div class="form-group col">
      <label for="period">Vote End Date</label>
      <nz-date-picker nzFormat="dd-MM-yyyy HH:mm" [nzShowTime]="{ nzFormat: 'HH:mm' , nzMinuteStep:10,nzUse12Hours:false }" [(ngModel)]="feed.endResultDate"
        (ngModelChange)="onChangeResultEndDate($event)" [nzDisabledDate]="disableEndResultDate" [nzShowToday]="true">
      </nz-date-picker>
    </div>
  </div>
</ng-template>
