<div class="row mb-2">
  <div class="col-12 text-right">
    <button type="button" class="btn btn-white" (click)="openNewModal()"><i class="fas fa-plus mr5"></i>Add Detail</button>
  </div>
</div>
<ngx-datatable #myTable class="material grid-aluha" [rows]="data" [loadingIndicator]="isLoading" [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50"
  [virtualization]="false" [externalSorting]="false" [externalPaging]="false" [scrollbarH]="true" rowHeight="40">
  <ngx-datatable-column *ngFor="let col of columnMapping" prop="{{ col.ColumnName }}" name="{{ col.DisplayName }}" [canAutoResize]="col.AutoResize"
    [sortable]="col.Sortable">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span>{{ value }}</span>
    </ng-template>
  </ngx-datatable-column>
  <!-- Actions -->
  <ngx-datatable-column name="Actions" prop="policyDetailId" fixed [width]="160" [frozenRight]="true">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <button type="button" class="no-button pointer fs16 ml10 text-warning" (click)="editPolicyDetail(value)">
        <i nz-icon noconfirm nzType="edit" theme="outline"></i>
      </button>
      <grid-button confirmTitle="Are you sure delete this item?" (okEvent)="deletePolicyDetail(value)" tooltip="Delete" className="text-danger">
        <i nz-icon confirm nzType="delete" theme="outline"></i>
      </grid-button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
