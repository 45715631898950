import { Directive, HostListener } from '@angular/core';
import { DataTableColumnDirective } from '@swimlane/ngx-datatable';

@Directive({
  selector: 'ngx-datatable-column[fixed]',
})
export class NgxColumnFixedDirective {
  constructor(private column: DataTableColumnDirective) {
    this.column.canAutoResize = false;
    this.column.draggable = true;
    this.column.cellClass = 'text-center';
    this.column.headerClass = 'text-center';
    this.column.sortable = false;
    this.column.resizeable = false;
  }
}
