export class LeaveSetting {
  id: string;

  maximumSellDays: number;
  isPercentSellDay: boolean;
  maximumTransferDays: number;
  isPercentTransferDay: boolean;
  latestMonthUseTransferDate: number;
  isAllowLeaveRequestHalfDay: boolean;

  startDateRegister?: Date;
  endDateRegister?: Date;

  remindBeforeStartDateRegister: number;
  remindAfterStartDateRegister: number;

  remindBeforeEndDateRegister: number;
  remindAfterEndDateRegister: number;

  maximumAnnualLeaveDays: number;
  numberAnnualLeaveCanIncrease: number;
  numberDayIncreaseByNumberAnnualLeave: number;
  numberSickLeaveForYear: number;

  departmentGroupId?: string;

  constructor() { }
}
