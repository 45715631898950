import { Injector } from '@angular/core';

export class AppInjector {
  private static injector: Injector;

  static setInjector(injector: Injector) {
    AppInjector.injector = injector;
  }

  static getInjector(): Injector {
    return AppInjector.injector;
  }

  static getService<T>(type: any) {
    const injector = AppInjector.getInjector();
    // tslint:disable-next-line: deprecation
    const service = injector.get(type) as T;
    return service;
  }
}
