import { ReviewMonthSetting } from './review-month-setting.model';

export class ReviewReminderSettings {
  id: string;
  reminderBeforeEndOfAnnualDay: number;
  reminderBeforeEndOfProbationDay: number;
  reminderBeforeEndOfGoalDay: number;
  dayJoinAnnualReview: number;
  reviewPerformanceMonthSettings: ReviewMonthSetting[];
  expiryTokenToViewByMinutes: number;

  constructor() {
    this.reminderBeforeEndOfAnnualDay = 0;
    this.reminderBeforeEndOfProbationDay = 0;
    this.reminderBeforeEndOfGoalDay = 0;
    this.dayJoinAnnualReview = 0;
    this.reviewPerformanceMonthSettings = [];
    this.expiryTokenToViewByMinutes = 15;
  }
}
