<modal-header [title]="isDetail ? purchaseRequest && purchaseRequest.purchaseRequestNo : 'Create Invoice'" [activeModal]="activeModal"></modal-header>
<modal-body>
  <div class="row gutters" *ngIf="purchaseRequest">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="card">
        <div class="card-body p-0">
          <div class="invoice-container p-t-xxs p-b-sm p-lr-sm">
            <div class="invoice-header">
              <!-- Row start -->
              <div class="row gutters">
                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-8 col-12">
                  <div class="invoice-details">
                    <ng-container *ngIf="purchaseRequest.groupDepartments">
                      <label class="m-r-xxs">Groups:</label>
                      <span>
                        <nz-tag *ngFor="let tag of purchaseRequest.groupDepartments" [nzColor]="'geekblue'">
                          <span *ngIf="tag" [innerHTML]="tag.name || tag.Name"></span>
                      </nz-tag> </span><br />
                    </ng-container>
                    <p><label class="m-r-xxs">Title: </label>{{ purchaseRequest.title }}</p>
                    <div *ngIf="purchaseRequest.description">
                      <label class="m-r-xxs">Note: </label>
                      {{ purchaseRequest.description }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-4 col-12 invoice-details invoice-details-number">
                  <label class="m-r-xxs">Status: </label>
                  <span [innerHTML]="purchaseRequest.purchasePoStatus | status: statusEnum.PRUCHASE_REQUEST_STATUS"></span>
                  <p *ngIf="isDetail && purchaseRequest.reason && purchaseRequest.purchasePoStatus === purchseRequestStatus.Reject">
                    <label class="m-r-xxs">Reason: </label> {{ purchaseRequest.reason }}
                  </p>
                  <div>{{ purchaseRequest.createdDate | ngkDate2 }}</div>
                </div>
              </div>
              <!-- Row end -->
            </div>

            <div class="invoice-body">
              <!-- Row start -->
              <div class="row gutters">
                <div class="col-lg-12 col-md-12 col-sm-12 mb-1">
                  <div class="central-meta item none-boxshadown border height-300" *ngIf="productLists && productLists.length; else empty">
                    <ngx-datatable #myTable class="material grid-aluha" [rows]="productLists" [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50"
                      [scrollbarV]="false" [virtualization]="false" rowHeight="auto">
                      <ngx-datatable-row-detail [rowHeight]="100" #myDetailRow>
                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                          <div *ngIf="row.description">
                            {{ row.description }}
                          </div>
                          <div *ngIf="!row.description">
                            Empty
                          </div>
                        </ng-template>
                      </ngx-datatable-row-detail>
                      <ngx-datatable-column *ngFor="let col of columnMapping" prop="{{ col.ColumnName }}" name="{{ col.DisplayName }}" [canAutoResize]="col.AutoResize"
                        [sortable]="col.Sortable" [width]="col.Witdh">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                          <ng-container [ngSwitch]="col.ColumnName">
                            <div [ngClass]="{ 'aluha-tr-gray fw600': row.hasChildrens }">
                              <div *ngSwitchCase="'name'">
                                <p *ngIf="row.parentId" class="pl30">
                                  <i class="mr5" nz-icon nzType="arrow-right" nzTheme="outline"></i>
                                  {{ value }}
                                </p>
                                <p *ngIf="!row.parentId">
                                  <span>{{ value }}</span>
                                </p>
                              </div>
                              <div *ngSwitchCase="col.ColumnName === 'price' || col.ColumnName === 'totalAmount' ? col.ColumnName : ''">
                                <span>{{ value | number: '1.0' }}</span>
                              </div>
                              <div *ngSwitchCase="'option'">
                                {{value | purchaseDetailOption}}
                              </div>
                              <p *ngSwitchDefault>
                                <span>{{ value }}</span>
                              </p>
                            </div>
                          </ng-container>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                  <ng-template #empty>
                    <nz-empty></nz-empty>
                  </ng-template>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <app-internal-upload-file [model]="modelUpload"></app-internal-upload-file>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 mt-1">
                  <app-purchase-history [purchaseRequestId]="purchaseRequest?.id"></app-purchase-history>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</modal-body>
<modal-footer>
  <div class="flex-fill text-right">
    <button class="btn btn-white" (click)="activeModal.close(false)"><i class="far fa-times-circle mr5"></i> Close</button>
    <button *ngIf="!isDetail" class="btn btn-success m-l-sm" (click)="createInvoice()"><i class="fas fa-plus"></i> Create Invoice</button>
  </div>
</modal-footer>
