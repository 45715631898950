import { Injectable } from '@angular/core';
import { HttpClientService, Constants } from '@intranet/index';
import { MasterData, ZoneService } from '../models';
import { AppConfig } from '@intranet/lib/environments/config/appConfig';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  hostUrl = AppConfig.settings.API_URL + '/api/masterdata/';
  constructor(private httpClient: HttpClientService) { }

  async getGender() {
    const url = this.hostUrl + 'get_gender';
    const data = await this.httpClient.getSync(url);
    return data;
  }
  async getJobType() {
    const url = this.hostUrl + 'get_jobtype';
    const data = await this.httpClient.getSync(url);
    return data;
  }
  async getContractType() {
    const url = this.hostUrl + 'contract_type';
    const data = await this.httpClient.getSync(url);
    return data;
  }
  async getPayrollType() {
    const url = this.hostUrl + 'payroll_type';
    const data = await this.httpClient.getSync(url);
    return data;
  }

  async getDepartmentType(): Promise<MasterData[]> {
    const url = this.hostUrl + 'get_department_type';
    const data = await this.httpClient.getSync(url);
    return data;
  }

  async getLeaveAction(): Promise<MasterData[]> {
    const url = this.hostUrl + 'get_leave_action';
    const data = await this.httpClient.getSync(url);
    return data;
  }

  async getZoneService(): Promise<ZoneService[]> {
    const url = this.hostUrl + 'get-zone-service';
    const data = await this.httpClient.getSync(url);
    return data;
  }
  async getFullEmployees(): Promise<ZoneService[]> {
    const url = this.hostUrl + 'employees';
    const data = await this.httpClient.getSync(url);
    return data;
  }
}
