export class Timekeeper {
  id: string;
  employeeId: string;
  enrollNumber: string;
  name: string;
  fingerIndex: number;
  privelage: number;
  password: string;
  enabled: boolean;
  machine: any;
}
