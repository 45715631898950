<ng-container *ngIf="confirmTitle;else noConfirm">
  <button nz-popconfirm [nzPopconfirmTitle]="confirmTitle" nzPopconfirmPlacement="top" (nzOnConfirm)="ok($event)" nz-tooltip [nzTooltipTitle]="tooltip"
    [class]="classcss">
    <ng-content select="[confirm]"></ng-content>
  </button>
</ng-container>
<ng-template #noConfirm>
  <button (click)="ok($event)" nz-tooltip [nzTooltipTitle]="tooltip" [class]="classcss">
    <ng-content select="[noconfirm]"></ng-content>
  </button>
</ng-template>
