import { Constants } from '@intranet/index';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CDNService, InternalUploadFileDto, InternalUploadFileResponseModel, UserModel, UserService } from '@intranet/lib/data-access';
import { Benefit, BenefitBonus, BenefitMemberRegistration, BenefitMemberRegistrationSearch, BenefitResult } from '@intranet/lib/data-access/models/benefit.model';
import { BenefitUserClaimModalDisplayModeEnum } from '@intranet/lib/data-access/models/enums/benefit.enum';
import { CommonHelper, SecurityHelper } from '@intranet/lib/helpers';
import { ErrorHelper } from '@intranet/lib/helpers/errorHelper';
import { Toastr, WebsiteEvent } from '@intranet/lib/settings';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BenefitMemberRegistrationService } from '@intranet/lib/data-access/services/benefit-member-registration.service';
import { v4 as uuid } from 'uuid';
import { BenefitConstants } from '@intranet/lib/config/benefit.constants';
import { BenefitDataTypeEnum } from '@intranet/lib/data-access/models/enums/benefit-data-type.enum';
import { startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { BenefitRegistrationTypeEnum } from '@intranet/lib/data-access/models/enums/benefit-registration-type.enum';
@Component({
  selector: 'app-benefit-user-claim-modal',
  templateUrl: './benefit-user-claim-modal.component.html',
  styleUrls: ['./benefit-user-claim-modal.component.scss']
})
export class BenefitUserClaimModalComponent implements OnInit, OnDestroy {
  @Input() displayMode: BenefitUserClaimModalDisplayModeEnum = BenefitUserClaimModalDisplayModeEnum.Claim;
  @Input() benefit: Benefit;
  @Input() benefitBonus: BenefitBonus;
  @Input() benefitResult: BenefitResult;


  currentUser: UserModel;

  title: string;

  benefitTitle: string;

  searchHistories: BenefitMemberRegistrationSearch;
  histories: BenefitMemberRegistration[] = [];
  totalHistoryRecords: number = 0;
  registrationId: string = null;

  userNote: string = '';
  userValue: number = null;
  modelUpload: InternalUploadFileDto = new InternalUploadFileDto();

  registrationTypeEnum = BenefitRegistrationTypeEnum;
  displayModeEnum = BenefitUserClaimModalDisplayModeEnum;
  dataTypeEnum = BenefitDataTypeEnum;
  requestStatusDisplayEnum = BenefitConstants.RegistrationStatus.Display;

  viewModeHandler = {
    [BenefitUserClaimModalDisplayModeEnum.View]: () => this.setViewMode(),
    [BenefitUserClaimModalDisplayModeEnum.Claim]: () => this.setClaimMode(),
  };

  isMobileView = CommonHelper.isMobileView();
  subscriptions: Subscription[] = [];

  readonly noteTextAreaAutosize = {
    minRows: 4,
    maxRows: 8,
  };

  formatterMoney = (value: number): string => value ? `${value.toLocaleString('en-US')}` : '';
  parserMoney = (value: string): string => value.replace(',', '');

  constructor(
    public activeModal: NgbActiveModal,
    private cdnService: CDNService,
    private userService: UserService,
    private websiteEvent: WebsiteEvent,
    private benefitMemberRegistrationService: BenefitMemberRegistrationService,
  ) { }

  ngOnInit(): void {
    const resizeEvent = this.websiteEvent.eventChangeHeight
      .pipe(startWith(window.innerHeight))
      .subscribe(this.screenSizeChanged.bind(this));


    this.subscriptions.push(resizeEvent);

    this.init();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private init() {
    this.currentUser = SecurityHelper.getStoreAuthen();

    const hasData = this.currentUser?.id && this.benefit && this.benefitBonus && this.benefitResult?.id;

    if (!hasData) {
      Toastr.error(`Invalid display input.`);
      this.activeModal.close();
    }

    this.benefitTitle = `${this.benefit?.title}`;
    this.setView(this.displayMode);
  }

  async claim() {
    try {

      const request = {
        id: this.registrationId,
        benefitResultId: this.benefitResult.id,
        note: this.userNote,
        value: this.userValue
      };

      const result = await this.userService.registerBenefit(this.currentUser.id, request);

      if (result) {
        Toastr.success(`Registered successfully.`);
        this.closeModal({ data: true });
        return;
      }
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
  }

  async loadHistories() {
    try {
      const histories = await this.benefitMemberRegistrationService.getHistories(this.searchHistories);

      this.histories = histories?.items ?? [];
      // tslint:disable-next-line: no-bitwise
      this.totalHistoryRecords = histories?.totalItems | 0;
    }
    catch (err) {
      ErrorHelper.displayMessage(err);
    }
  }

  async closeModal({ data }: { data?: any; } = {}) {
    if (!data) {
      // await this.deleteAllAttachments();
    }

    this.activeModal.close(data);
  }

  openPolicy() {
    const policyId = this.benefitResult.policyId;
    CommonHelper.openPolicyLink(policyId);
  }

  setView(mode: BenefitUserClaimModalDisplayModeEnum) {
    this.viewModeHandler[mode]?.();
  }

  private setClaimMode() {
    this.displayMode = BenefitUserClaimModalDisplayModeEnum.Claim;
    this.title = `[Claim] Benefit`;
    this.modelUpload.title = undefined;

    if (!this.registrationId) {
      this.registrationId = uuid();
    }

    if (!this.modelUpload.entityId) {
      this.modelUpload.entityId = this.registrationId;
      this.modelUpload.entityType = Constants.ENTITY_TYPE.BENEFIT_REGISTRATION;
    }
  }

  private setViewMode() {
    this.displayMode = BenefitUserClaimModalDisplayModeEnum.View;
    this.title = `[View] Benefit: ${this.benefitTitle}`;

    this.searchHistories = new BenefitMemberRegistrationSearch(this.currentUser.id, this.benefitResult.id);

    this.loadHistories();
  }

  private screenSizeChanged() {
    this.isMobileView = CommonHelper.isMobileView();
  }
}
